import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryStack, VictoryTooltip, VictoryVoronoiContainer, VictoryLabel } from 'victory';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getDayFromDate, isBeforeToday, numericFormatDate } from 'app/utils/date.functions';
import { IconButton, makeStyles, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getNextSunday, getPreviousMonday, updateDate } from 'app/assignment/drawerHistory/DrawerHistory.function';
import { postHistoryBetweenTwoDates } from 'app/redux/actions/Question/History/history.actions';
import { selectGraphicHistoryData } from 'app/redux/selectors/Question/history.selector';

const aggregateSeriesBySameDay = (items,aggregatedList, keyFn) => {
    items.forEach((item) => {
        const key = keyFn(item);
        let aggregatedObject = aggregatedList.find(obj => obj.day === key);
  
        if(!aggregatedObject) {
            aggregatedObject = {
                day: key,
                goodAnswers: 0,
                badAnswers: 0,
            };
            aggregatedList.push(aggregatedObject);
        }
  
        aggregatedObject.goodAnswers += item.goodAnswers;
        aggregatedObject.badAnswers += item.badAnswers;
    });
  
    return aggregatedList;
};


const transformSeriesData = (graphData, questionType) => graphData
    .filter(item => item.dataType === questionType)
    .map((chapter) => ({
        day: getDayFromDate(chapter.date),
        goodAnswers: chapter.goodAnswers,
        badAnswers: chapter.badAnswers,
        result: chapter.result,
    }));


const constructLabel = (badAnswers, goodAnswers) => {
    let label = '';
    if(goodAnswers > 0) {
        label += `${goodAnswers} ✔️, `;
    }

    if(badAnswers > 0) {
        label += `${badAnswers} ❌`;
    }
    return label.replace(/, $/u, '');
};

const daysOfWeek = () => ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
const initializeAggregatedList = () => daysOfWeek().map(day => ({
    day,
    goodAnswers: 0,
    badAnswers: 0,
}));

const aggregateQuestionData = (questions, graphAggregateData) => {
    const aggregatedData = [...graphAggregateData];
    questions.forEach((question) => {
        const { day, result } = question;
        let aggregatedObject = aggregatedData.find(item => item.day === day);
        if(!aggregatedObject) {
            aggregatedObject = {
                day,
                goodAnswers: 0,
                badAnswers: 0,
            };
            aggregatedData.push(aggregatedObject);
        }
        if(result === 101) {
            aggregatedObject.goodAnswers += 1;
        } else {
            aggregatedObject.badAnswers += 1;
        }
    });
  
    return aggregatedData;
};



const useStyles = makeStyles(() => ({
    dateEncadrement: {
        'border': '1px solid black',
        'padding': '5px'
    },
    dateGrid: {
        'display': 'flex',
        'justifyContent': 'center'
    },
    numberElement: {
        'display': 'flex',
        'justifyContent': 'center',
        'fontWeight': 'bold'
    }
}));

export default function BarChart({ studentId, skillId, forceMondayDate, forceSundayDate }) {
    const studentGraphicHistoryData = useSelector(selectGraphicHistoryData) || [];
    const dispatch = useDispatch();
    const classes = useStyles();
    const [mondayDate, setMondayDate] = useState(getPreviousMonday());
    const [sundayDate, setSundayDate] = useState(getNextSunday());
    const [isNextWeekInFuture, setIsNextWeekInFuture] = useState(true);

    const handleShowPreviousWeek = (firstDate, lastDate) => {
        const updatedMonday = updateDate(firstDate, -7);
        const updatedSunday = updateDate(lastDate, -7);
        setMondayDate(updatedMonday);
        setSundayDate(updatedSunday);
        setIsNextWeekInFuture(false);
    };

    const handleShowNextWeek = (firstDate, lastDate) => {
        const updatedMonday = updateDate(firstDate, 7);
        const updatedSunday = updateDate(lastDate, 7);
        setMondayDate(updatedMonday);
        setSundayDate(updatedSunday);

        if(!isBeforeToday(updateDate(updatedMonday, 7).toDateString())) {
            setIsNextWeekInFuture(true);
        }
    };
    useEffect(() => {
        if(studentId) {
            const searchCriteria = {
                userId: studentId,
                fromWhenDate: forceMondayDate?.toISOString() ?? mondayDate.toISOString(),
                toWhenDate: forceSundayDate?.toISOString() ?? sundayDate.toISOString()
            };

            if(skillId) {
                searchCriteria.skillIds = [skillId];
            }

            dispatch(postHistoryBetweenTwoDates(searchCriteria));
        }

    }, [studentId, skillId, mondayDate, forceMondayDate]);

    const result = studentGraphicHistoryData.map((chapter) => ({
        ...chapter,
        day: new Date(chapter.date)
    }));

    const weekData = result.filter((entry) => (entry.day >= (forceMondayDate ?? mondayDate) && entry.day <= (forceSundayDate ?? sundayDate)));

    const chartData = weekData.map((chapter) => ({
        ...chapter,
        day: getDayFromDate(chapter.day)
    }));

    const series = transformSeriesData(chartData, 'SERIE');
    const questions = transformSeriesData(chartData, 'QUESTION');
    let graphAggregateData = initializeAggregatedList();
    // Calculate scores in both series & questions
    graphAggregateData = aggregateSeriesBySameDay(series, graphAggregateData, item => item.day);
    graphAggregateData = aggregateQuestionData(questions, graphAggregateData);
    // Sort data by days from Lundi...
    graphAggregateData.sort((firstData, secondData) => daysOfWeek().indexOf(firstData.day) - daysOfWeek().indexOf(secondData.day));

    return (
        <>
            {!forceSundayDate || !forceMondayDate
                ? <Grid className={classes.dateGrid}>
                    <div>
                        <IconButton aria-label="semaine precedente" onClick={() => handleShowPreviousWeek(mondayDate, sundayDate)}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <span className={classes.dateEncadrement}>
                            {`du ${numericFormatDate(mondayDate)} au ${numericFormatDate(sundayDate)}`}
                        </span>
                        <IconButton aria-label="semaine suivante"
                            onClick={() => handleShowNextWeek(mondayDate, sundayDate)}
                            disabled={isNextWeekInFuture}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </div>
                </Grid>
                : <div style={{ padding: '20px' }}><h3 style={{ marginBottom: '20px', color: '#444' }}>Historique</h3></div>}
            <VictoryChart
                containerComponent={
                    <VictoryVoronoiContainer
                        labelComponent={<VictoryTooltip style={{ fontSize: 7.5 }}/>}
                        labels={({ datum }) => (constructLabel(datum.badAnswers, datum.goodAnswers))}
                    />
                }
                domainPadding={20}>
                <VictoryAxis
                    tickLabelComponent={<VictoryLabel style={{ fontSize: 10 }}/>}
                    tickValues={daysOfWeek()}
                />
                <VictoryAxis
                    dependentAxis
                    tickFormat={(tickValue) => (Number.isInteger(tickValue) ? tickValue : null)}
                    tickLabelComponent={<VictoryLabel style={{ fontSize: 10 }}/>}
                />
                <VictoryStack>
                    <VictoryBar
                        data={graphAggregateData}
                        x="day"
                        y="goodAnswers"
                        style={{ data: { fill: '#009000' } }}
                        labelComponent={<VictoryTooltip />}
                        barWidth={20} />
                    <VictoryBar
                        data={graphAggregateData}
                        x="day"
                        y="badAnswers"
                        style={{ data: { fill: '#e00000' } }}
                        barWidth={20}
                        labelComponent={<VictoryTooltip />}
                    />
                </VictoryStack>
            </VictoryChart>
        </>
    );
};

BarChart.propTypes = {
    studentId: PropTypes.number,
    skillId: PropTypes.number,
    forceSundayDate: PropTypes.object,
    forceMondayDate: PropTypes.object
};