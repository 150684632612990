import axiosClient from './utils/axiosClient';

export const QUESTION_URI = 'questions';

export function getMorningQuestionsApi(userId) {
    return axiosClient.get(`${QUESTION_URI}/user/${userId}/morning`);
}

export function postMorningQuestionsDoneApi(userId) {
    return axiosClient.post(`${QUESTION_URI}/user/${userId}/morning`);
}
