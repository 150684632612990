import React from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { ContextMenuProps } from './ContextMenu.types';
import { CONTEXT_MENU_OPTIONS_WITHOUT_PERMISSION, CONTEXT_MENU_OPTIONS_WITH_PERMISSION } from './ContextMenu.constants';

export const ContextMenu = (props: ContextMenuProps) => {

    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const getActions = () => {
        if(sizeUpMd) {
            return props.permission ? CONTEXT_MENU_OPTIONS_WITH_PERMISSION : null;
        }
        return props.permission
            ? new Map([
                ...CONTEXT_MENU_OPTIONS_WITHOUT_PERMISSION,
                ...CONTEXT_MENU_OPTIONS_WITH_PERMISSION
            ])
            : CONTEXT_MENU_OPTIONS_WITHOUT_PERMISSION;
    };

    return (
        <Menu
            keepMounted
            open={Boolean(props.contextMenu)}
            anchorReference={props.contextMenu?.reference}
            anchorPosition={props.contextMenu?.reference === 'anchorPosition' ? props.contextMenu.position : undefined}
            anchorEl={props.contextMenu?.reference === 'anchorEl' ? props.contextMenu.el : undefined}
            transitionDuration={0}
            onClose={props.handleCloseMenu}
        >
            {Array.from(getActions()?.entries() ?? [])
                .map(([action, config]) => (
                    <Tooltip key={action} title={config.tooltip?.(props) || ''}>
                        <MenuItem
                            disabled={config.disable?.(props)}
                            onClick={() => props.handleMenuAction(action)}
                            data-testid={`action-${action}`}
                        >
                            <ListItemIcon>
                                {config.icon}
                            </ListItemIcon>
                            <ListItemText>{config.text}</ListItemText>
                        </MenuItem>
                    </Tooltip>
                ))
            }
        </Menu>
    );
};
