import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ContentDisplay.scss';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PdfViewer from '../../../../common/pdfViewer/PdfViewer';
import resourceNotFound from 'assets/images/resourceNotFound.png';

export function ContentDisplay(props) {

    const [resourceLocation, setResourceLocation] = React.useState();

    const { resource } = props;

    const cssControl = () => {
        if(isWidthUp('md', props.width)) {
            return 'content-desktop';
        }
        return 'content-mobile';
    };

    useEffect(() => {
        if(resource) {
            if(resource.type === 'YOUTUBE') {
                setResourceLocation(`https://www.youtube.com/embed/${resource.link}`);
            } else if(resource.type === 'PDF') {
                setResourceLocation(`${resource.link}`);
            }
        }
    }, [props.resource]);

    const ressourceDisplay = () => {
        switch (resource.type) {
            case 'YOUTUBE':
                return <iframe
                    className="youtube-video"
                    src={resourceLocation}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    data-testid="youtube-video">
                </iframe>;
            case 'PDF':
                return <PdfViewer url={resourceLocation} startPage={props.resource.startPage} endPage={props.resource.endPage} />;
            default:
                return <div className="unsupported" data-testid="unsupported">Type de resource non pris en charge</div>;
        }
    };

    return (
        <div className={cssControl()} data-testid="content-display">
            <div className="content-display">
                {resource
                    ? ressourceDisplay()
                    : <div className="resource-not-found" data-testid="resource-not-found">
                        <img
                            src={resourceNotFound}
                            alt="Resource non trouvé"
                            height="128px"
                            width="128px"
                        />
                        Pas de ressource
                    </div>
                }
            </div>
        </div>
    );
}

ContentDisplay.propTypes = {
    width: PropTypes.string,
    resource: PropTypes.object
};

export default (withWidth()(ContentDisplay));
