

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

const SkillsLoader = ({
    width = 1366,
    heading = {
        width: 0,
        height: -20
    },
    row = 3,
    column = 60,
    padding = 15,
    borderRadius = 4,
    ...props
}) => {
    const list = [];
    let height = 0;

    let index = 0;

    for(let ii = 1; ii <= row; ii++) {
        for(let jj = 0; jj < column; jj++) {
            index += 1;
            const itemWidth = (width - padding * (column + 1)) / column;

            const xx = padding + jj * (itemWidth + padding);

            const height1 = itemWidth;

            const height2 = 200;

            const space = padding + height2 + padding;

            const y1 = heading.height + padding * 2 + space * (ii - 1);

            const y2 = y1 + height1;

            list.push(<Fragment key={index}>
                <rect x={xx} y={y1} rx={borderRadius} ry={borderRadius} width={itemWidth} height={height2}/>
            </Fragment>);

            if(ii === row) {
                height = y2 + height2;
            }
        }
    }

    return (
        <ContentLoader
            width={width}
            height={height}
            speed={2}
            primaryColor="#d9d3d3"
            secondaryColor="#eae6e6"
            style={{
                flex: 1
            }}
            ariaLabel={false}
            {...props}
            data-testid="skills-loader"
        >
            {list}
        </ContentLoader>
    );
};

SkillsLoader.propTypes = {
    width: PropTypes.string,
    heading: PropTypes.object,
    row: PropTypes.number,
    column: PropTypes.number,
    padding: PropTypes.number,
    borderRadius: PropTypes.number
};

export default SkillsLoader;
