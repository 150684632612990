import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SearchQuestion.scss';
import TextField from '@material-ui/core/TextField';
import { setQuestionsIdFilter } from '../../redux/actions/Question/QuestionsFilters/questionsFilters.actions';
import { selectQuestionsIdFilter } from '../../redux/selectors/Question/QuestionsFilters/questionsFilters.selector';
import { connect } from 'react-redux';

export class SearchQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: 0,
            searchField: props.valueId
        };
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.resetNameFilter && this.props.resetNameFilter) {
            this.setState({ ...this.state, searchField: '' });
            this.props.resetSearchField();
        }
    }

    handleSearchInputChange = (event) => {
        if(event.target.value.length >= process.env.REACT_APP_MINIMUM_AUTO_SEARCH_LENGTH) {
            this.setState({ ...this.state, searchField: event.target.value });
        } else {
            this.setState({ ...this.state, searchField: '' });
        }
    };

    checkNoInput = () => {
        clearTimeout(this.state.timer);
        this.setState({ ...this.state,
            timer: setTimeout(() => {
                const idRegex = /^(#?)(\d?)+$/u;
                if(idRegex.test(this.state.searchField)) {
                    if(this.state.searchField.includes('#')) {
                        this.props.setQuestionsIdFilter(this.state.searchField.substring(1));
                    } else {
                        this.props.setQuestionsIdFilter(this.state.searchField);
                    }
                }
            }, 200) });
    };

    render() {
        return (
            <div className="search" data-testid="search-question">
                <TextField
                    className="search-input"
                    placeholder="Rechercher ..."
                    value={this.state.searchField}
                    onKeyUp={this.checkNoInput}
                    onChange={this.handleSearchInputChange}
                    inputProps={{ 'aria-label': 'Search' }}
                />
            </div>
        );
    }
}

SearchQuestion.propTypes = {
    resetNameFilter: PropTypes.bool,
    resetSearchField: PropTypes.func,
    setQuestionsIdFilter: PropTypes.func,
    valueId: PropTypes.string
};

function mapStateToProps(state) {
    return {
        valueId: selectQuestionsIdFilter(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setQuestionsIdFilter: (idFilter) => dispatch(setQuestionsIdFilter(idFilter))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchQuestion);
