import {
    dailyQuestionsToDo,
    dailyPreferences
} from '../../reducers/Question/dailyQuestions.reducer';
import { dailyQuestionsStore } from '../../rootReducer';

export function selectDailyQuestions(state) {
    return state[dailyQuestionsStore][dailyQuestionsToDo];
}

export function selectDailyPreferences(state) {
    return state[dailyQuestionsStore][dailyPreferences];
}
