/* eslint-disable complexity */

import {
    GET_QUESTION_TO_PREVIEW_BY_ID_SUCCESS,
    RESET_QUESTION_TO_PREVIEW,
    GET_QUESTIONS_PAGINATED_SUCCESS,
    RESET_QUESTIONS_REDUCED,
    GET_QUESTIONS_BY_CHAPTER_ID_SUCCESS,
    SET_QUESTIONS_TO_DISPLAY

} from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';

import {
    GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_SUCCESS,
    PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS,
    GET_QUESTION_TO_EDIT_BY_ID_SUCCESS,
    RESET_MCQ_EDIT,
    RESET_QUESTIONS_RESOLVE,
    DELETE_QUESTION_BY_ID
} from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';

import {
    SET_QUESTION_TO_INACTIVE_SUCCESS,
    SET_QUESTION_TO_INACTIVE_FAILURE,
    EDIT_MULTIPLE_CHOICE_QUESTION_SUCCESS,
    EDIT_MULTIPLE_CHOICE_QUESTION_FAILURE,
    ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS,
    ADD_MULTIPLE_CHOICE_QUESTION_FAILURE,
    RESET_MCQ_MODIFICATION_SAVED,
    REPLACE_CHAPTER_OF_QUESTION_SUCCESS,
    ADD_QUESTIONS_TO_DISPLAY
} from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQSet/MCQSet.actions';

import {
    DELETE_QUESTION_CHAPTER_MODERATORS_SUCCESS
} from 'app/redux/actions/Question/QuestionModeration/questionModeration.actions';
import { GET_MODERATORS_BY_CHAPTER_ID_SUCCESS } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';


export const questionsReducedPage = 'questionsReducedPage';
export const MCQWithoutExplanationStore = 'MCQWithoutExplanationStore';
export const MCQWithExplanationStore = 'MCQWithExplanationStore';
export const MCQEditStore = 'MCQEditStore';
export const MCQModificationSaved = 'MCQModificationSaved';
export const moderatorMap = 'moderatorMap';
export const questionsToDisplay = 'questionsToDisplay';
export const questionsPreview = 'questionsPreview';

// eslint-disable-next-line complexity
export default function multipleChoiceQuestionReducer(state = {
    [questionsReducedPage]: null,
    [MCQWithoutExplanationStore]: [],
    [MCQWithExplanationStore]: null,
    [MCQEditStore]: null,
    [MCQModificationSaved]: null,
    [moderatorMap]: new Map(),
    [questionsToDisplay]: new Map(),
    [questionsPreview]: new Map()
}, action) {
    switch (action.type) {
        case GET_QUESTION_TO_PREVIEW_BY_ID_SUCCESS:
            const newMap = new Map(state[moderatorMap]);
            newMap.set(action.payload.id, action.payload.moderators);
            const questionMap = new Map(state[questionsPreview]);
            questionMap.set(action.payload.id, action.payload);
            return {
                ...state,
                [moderatorMap]: newMap,
                [questionsPreview]: questionMap
            };
        case RESET_QUESTION_TO_PREVIEW:
            return {
                ...state,
                [moderatorMap]: new Map(),
                [questionsPreview]: new Map()
            };
        case GET_QUESTIONS_PAGINATED_SUCCESS:
            const paginatedMap = new Map(state[moderatorMap]);
            action.payload.listOfElements.forEach((question) => {
                paginatedMap.set(question.id, question.moderators);
            });
            return {
                ...state,
                [questionsReducedPage]: action.payload,
                [moderatorMap]: paginatedMap
            };
        case RESET_QUESTIONS_REDUCED:
            return {
                ...state,
                [questionsReducedPage]: null
            };
        case GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_SUCCESS:
            const modmap = new Map(state[moderatorMap]);
            modmap.set(action.payload.id, action.payload.moderators);
            delete action.payload.moderators;
            return {
                ...state,
                [MCQWithExplanationStore]: null,
                [MCQWithoutExplanationStore]: [...state[MCQWithoutExplanationStore], action.payload],
                [moderatorMap]: modmap
            };
        case DELETE_QUESTION_BY_ID:
            state[MCQWithoutExplanationStore].splice(state[MCQWithoutExplanationStore].findIndex((question) => question.id === action.payload), 1);
            return {
                ...state,
                [MCQWithExplanationStore]: null
            };
        case RESET_QUESTIONS_RESOLVE:
            return {
                ...state,
                [MCQWithExplanationStore]: null,
                [MCQWithoutExplanationStore]: []
            };
        case GET_QUESTION_TO_EDIT_BY_ID_SUCCESS:
            return {
                ...state,
                [MCQEditStore]: action.payload
            };
        case RESET_MCQ_EDIT:
            return {
                ...state,
                [MCQEditStore]: null
            };
        case PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS:
            return {
                ...state,
                [MCQWithExplanationStore]: action.payload
            };
        case EDIT_MULTIPLE_CHOICE_QUESTION_SUCCESS:
        case SET_QUESTION_TO_INACTIVE_SUCCESS:
            if(!state[moderatorMap]) {
                return state;
            }
            const mapEdit = new Map(state[moderatorMap]);
            let hasModerators = false;
            mapEdit.forEach((question, _key, _map) => {
                if(_key === action.payload.questionId) {
                    const concat = question.concat(action.payload.moderator);
                    _map.set(action.payload.questionId, concat);
                    hasModerators = true;
                }
            });
            if(!hasModerators) {
                mapEdit.set(action.payload.questionId, [action.payload.moderator]);
            }

            let newQuestionsToDisplay = null;

            // On rentre dans ce bloc uniquement si on est dans la liste des questions par capitre de l'onglet compétences
            if(action.payload.chapterId && state[questionsToDisplay].size !== 0) {
                const newQuestionsToDisplayForOneChapterId = state[questionsToDisplay].get(action.payload.chapterId).filter((question) => question.id !== action.payload.id);
                newQuestionsToDisplay = new Map(state[questionsToDisplay].set(action.payload.chapterId, newQuestionsToDisplayForOneChapterId));
            }
            return {
                ...state,
                [moderatorMap]: mapEdit,
                [MCQModificationSaved]: true,
                [questionsToDisplay]: newQuestionsToDisplay ? newQuestionsToDisplay : state[questionsToDisplay],
                [MCQWithoutExplanationStore]: []
            };
        case ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS:
            if(!state[moderatorMap]) {
                return state;
            }
            const mapAdd = new Map(state[moderatorMap]);
            let hasModeratorsAdd = false;
            mapAdd.forEach((question, _key, _map) => {
                if(_key === action.payload.questionId) {
                    const concat = question.concat(action.payload.moderator);
                    _map.set(action.payload.questionId, concat);
                    hasModeratorsAdd = true;
                }
            });
            if(!hasModeratorsAdd) {
                mapAdd.set(action.payload.questionId, [action.payload.moderator]);
            }
            return {
                ...state,
                [moderatorMap]: mapAdd
            };
        case ADD_MULTIPLE_CHOICE_QUESTION_FAILURE:
            return {
                ...state
            };
        case EDIT_MULTIPLE_CHOICE_QUESTION_FAILURE:
        case SET_QUESTION_TO_INACTIVE_FAILURE:
            return {
                ...state,
                [MCQModificationSaved]: false
            };
        case RESET_MCQ_MODIFICATION_SAVED:
            return {
                ...state,
                [MCQModificationSaved]: null
            };
        case GET_MODERATORS_BY_CHAPTER_ID_SUCCESS:
            const mapQuestions = new Map(state[moderatorMap]);
            action.payload.forEach((question) => {
                mapQuestions.set(question.id, question.moderators);
            });
            return {
                ...state,
                [moderatorMap]: mapQuestions
            };
        case GET_QUESTIONS_BY_CHAPTER_ID_SUCCESS:
            const newQuestionsToDisplayMap = new Map(state[questionsToDisplay]);
            newQuestionsToDisplayMap.set(action.payload.id, action.payload.data);
            const mapChapter = new Map(state[moderatorMap]);
            action.payload.data.forEach((question) => {
                mapChapter.set(question.id, question.moderators);
            });
            return {
                ...state,
                [questionsToDisplay]: newQuestionsToDisplayMap,
                [moderatorMap]: mapChapter
            };
        case REPLACE_CHAPTER_OF_QUESTION_SUCCESS:
            const updatedQuestionsToDisplayMap = new Map(state[questionsToDisplay]);
            const questionToMove = action.payload.question;
            if(updatedQuestionsToDisplayMap.has(action.payload.oldChapterId)) {
                const oldChapterQuestions = updatedQuestionsToDisplayMap.get(action.payload.oldChapterId);
                const index = oldChapterQuestions.map((questionReduced) => questionReduced.id).indexOf(questionToMove.id);
                if(index !== -1) {
                    oldChapterQuestions.splice(index, 1);
                    updatedQuestionsToDisplayMap.set(action.payload.oldChapterId, oldChapterQuestions);
                }
            }
            if(updatedQuestionsToDisplayMap.has(action.payload.newChapterId)) {
                const newChapterQuestions = updatedQuestionsToDisplayMap.get(action.payload.newChapterId);
                newChapterQuestions.push(questionToMove);
                updatedQuestionsToDisplayMap.set(action.payload.newChapterId, newChapterQuestions);
            }
            return {
                ...state,
                [questionsToDisplay]: updatedQuestionsToDisplayMap
            };
        case SET_QUESTIONS_TO_DISPLAY:
            const copyQuestionsToDisplay = new Map(state[questionsToDisplay]);
            action.payload.chapterIds.forEach((chapterId) => {
                if(copyQuestionsToDisplay.get(chapterId)) {
                    const questionResultFound = copyQuestionsToDisplay
                        .get(chapterId)
                        .find((questionResult) => (questionResult.id === action.payload.id));
                    if(questionResultFound) {
                        questionResultFound.result = action.payload.result;
                    }
                }
            });
            return {
                ...state,
                [questionsToDisplay]: copyQuestionsToDisplay
            };
        case ADD_QUESTIONS_TO_DISPLAY:
            const copyQuestion = action.payload.data;
            const newMap2 = new Map(state[questionsToDisplay]);
            copyQuestion.chapterIds.forEach((id) => newMap2.has(id)
                ? newMap2.get(id).push(copyQuestion)
                : newMap2.set(id, [copyQuestion]));
            return {
                ...state,
                [questionsToDisplay]: newMap2
            };
        case DELETE_QUESTION_CHAPTER_MODERATORS_SUCCESS:
            if(!state[moderatorMap]) {
                return state;
            }
            const map2 = new Map(state[moderatorMap]);
            map2.forEach((question, _key, _map) => {
                if(_key === action.payload.questionId) {
                    const filter = question.filter((moderator) => Number(moderator.userId) !== Number(action.payload.moderator.userId));
                    _map.set(_key, filter);
                }
            });
            return {
                ...state,
                [moderatorMap]: map2
            };
        default:
            return state;
    }
}
