import { allChaptersStore, chapterQuestionFilterStore, chaptersQuestionCountStore, chaptersStoreNew, drawerChaptersStore } from 'app/redux/slices/chapters.slice';
import {
    chapterCourses,
    selectedChapterId, studentSelected,
    selectedChaptersSkillView, drawerSelectedChapterId,
    isChapterTreeOpen, isChapterReportingOpen, selectedReportedChapter,
    chapterReporting, optionsChapterDisplay
} from '../../reducers/Chapter/chapter.reducer';
import { RootState, chaptersStore } from '../../rootReducer';
import { Chapter } from 'app/redux/models/Chapter.model';

export function selectReducedChapters(state: RootState) {
    return state[chaptersStoreNew][allChaptersStore];
}

export function selectQuestionCountByChapter(state: RootState) {
    return state[chaptersStoreNew][chaptersQuestionCountStore];
}

export function selectDrawerReducedChapters(state: RootState) {
    return state[chaptersStoreNew][drawerChaptersStore];
}

export function selectChapterCourses(state: RootState) {
    return state[chaptersStore][chapterCourses];
}

export function selectSelectedChapterId(state: RootState) {
    return state[chaptersStore][selectedChapterId];
}

export function selectSelectedChapter(state: RootState) {
    return selectReducedChapters(state).data.find((chapter: Chapter) => chapter.id === selectSelectedChapterId(state));
}

export function selectDrawerSelectedChapter(state: RootState) {
    return state[chaptersStoreNew][drawerChaptersStore].data.find((chapter: Chapter) => chapter.id === state[chaptersStore][drawerSelectedChapterId]);
}

export function selectStudent(state: RootState) {
    return state[chaptersStore][studentSelected];
}

export function selectSelectedChaptersSkillView(state: RootState) {
    return state[chaptersStore][selectedChaptersSkillView];
}

export const selectSelectedChaptersSkillViewPresent = (chapterId: number) => (state: RootState) => Boolean(state[chaptersStore][selectedChaptersSkillView].find((item: any) => item.id === chapterId));

export function selectIsChapterTreeOpen(state: RootState) {
    return state[chaptersStore][isChapterTreeOpen];
}

export function selectIsChapterReportingOpen(state: RootState) {
    return state[chaptersStore][isChapterReportingOpen];
}

export function selectReportedChapter(state: RootState) {
    return state[chaptersStore][selectedReportedChapter];
}

export function selectChapterReporting(state: RootState) {
    return state[chaptersStore][chapterReporting];
}

export function selectOptionsChapterDisplay(state: RootState) {
    return state[chaptersStore][optionsChapterDisplay];
}

export function selectChapterQuestionFilter(state: RootState) {
    return state[chaptersStoreNew][chapterQuestionFilterStore];
}
