import { React, useState } from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getMicroskillCourseById } from 'app/api/microskillApi';
import { useDispatch } from 'react-redux';
import { storeMicroskill } from 'app/redux/actions/Chat/chat.actions';
import MicroskillReportingButton from 'app/microskill/MicroskillReportingButton';
import './CourseContent.scss';
import ContentLoader from 'react-content-loader';

export default function CourseContent(props) {

    const dispatch = useDispatch();

    const [isCourseContentVisible, setCourseContentVisible] = useState(false);

    const [isLoading, setIsLoading] = useState();

    const displayCourse = () =>
    {

        setIsLoading(true);

        getMicroskillCourseById(props.microskill.id).then( (response) => {

            const copyQuestionMicroskill = [...props.questionMicroskill];

            const microskill = { ... props.microskill, course : response.data.course };

            copyQuestionMicroskill.splice(copyQuestionMicroskill.findIndex(mc => mc.id === microskill.id), 1, microskill);

            props.setQuestionMicroskills(copyQuestionMicroskill);

            dispatch(storeMicroskill(microskill));

        }

        ).finally(() => setIsLoading(false));
    };

    const toggleCourseContent = () =>
    {      
        if(!isCourseContentVisible && !props.microskill.course) {

            displayCourse();
        }
        setCourseContentVisible(!isCourseContentVisible);
    };
      
    

    return (
        <>
            <div className='microskill-title-container'>
                <div>
                    <span>{props.microskill.name}</span> 
                    {isCourseContentVisible && (
                        <MicroskillReportingButton
                            microskillId={props.microskill.id}
                        />
                    )}
                </div>
                <IconButton color="primary" onClick={toggleCourseContent}>
                    {isCourseContentVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>

    
            {isLoading ?
                <ContentLoader />
                :
                isCourseContentVisible && 
                (props.microskill?.course ?
                    <div dangerouslySetInnerHTML={{ __html: props.microskill.course }} className="content scrollable" style={{ fontWeight: 'normal' }} />
                    :   <span>Aucun cours associé trouvé.</span>)
            }

        </>
    );
}

CourseContent.propTypes = {
    microskill : PropTypes.object,
    setQuestionMicroskills : PropTypes.func,
    questionMicroskill : PropTypes.array
};
