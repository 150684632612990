import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';

export class SimpleSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || ''
        };
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        });
        this.props.onChange(event.target.value);
    };

    render() {
        return (
            <div data-testid="simple-select">
                <TextField
                    margin="dense"
                    label={this.props.label}
                    type="text"
                    onChange={this.handleChange}
                    fullWidth
                    value={this.state.value}
                    select
                >
                    {this.props.suggestions.map((option) => <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>)}
                </TextField>
            </div>
        );
    }
}

SimpleSelect.propTypes = {
    suggestions: PropTypes.array,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};
