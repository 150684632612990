import {
    GET_PARCOURS_BY_ID_SUCCESS,
    GET_PARCOURS_STATUS_BY_USER_ID_SUCCESS,
    RESET_PARCOURS,
    UPDATE_PARCOURS_ID,
    DELETE_PARCOURS_SUCCESS,
    CREATE_PARCOURS_SUCCESS,
    UPDATE_PARCOURS_SUCCESS,
    DELETE_PARCOURS_SHARE_WITH_USER_SUCCESS,
    SHARE_PARCOURS_WITH_USERS_SUCCESS
} from 'app/redux/actions/Parcours/parcours.actions';

export const parcours = 'parcours';
export const parcoursList = 'parcoursList';
export const parcoursIdToDelete = 'parcoursIdToDelete';
export const parcoursCreated = 'parcoursCreated';
export const parcoursUpdated = 'parcoursUpdated';
export const parcoursShared = 'parcoursShared';
export const parcoursIdToUnshare = 'parcoursIdToUnshare';
export const statusParcours = 'statusParcours';

export default function parcoursReducer(state = {
    [parcours]: null,
    [parcoursList]: [],
    [parcoursIdToDelete]: null,
    [parcoursCreated]: null,
    [parcoursUpdated]: null,
    [parcoursShared]: null,
    [parcoursIdToUnshare]: null,
    [statusParcours]: []
}, action) {
    switch (action.type) {
        case GET_PARCOURS_BY_ID_SUCCESS:
            return {
                ...state,
                [parcours]: action.payload
            };
        case GET_PARCOURS_STATUS_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [statusParcours]: action.payload
            };
        case RESET_PARCOURS:
            return {
                ...state,
                [parcours]: null
            };
        case UPDATE_PARCOURS_ID:
            return {
                ...state,
                [parcours]: action.payload
            };
        case DELETE_PARCOURS_SUCCESS: 
            return {
                ...state,
                [parcoursIdToDelete]: action.payload
            };
        case CREATE_PARCOURS_SUCCESS: 
            return {
                ...state,
                [parcoursCreated]: action.payload
            };
        case UPDATE_PARCOURS_SUCCESS: 
            return {
                ...state,
                [parcoursUpdated]: action.payload
            };
        case SHARE_PARCOURS_WITH_USERS_SUCCESS:
            return {
                ...state,
                [parcoursShared]: action.payload
            };
        case DELETE_PARCOURS_SHARE_WITH_USER_SUCCESS:
            return {
                ...state,
                [parcoursIdToUnshare]: action.payload
            };
        default:
            return state;
    }
}

