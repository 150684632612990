import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './SkillCard.scss';
import { CardActionArea } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCurrentUser } from '../../redux/selectors/User/user.selector';
import {
    selectSkillModeratorsCount,
    selectSkillUsersCount
} from '../../redux/selectors/Skill/SkillSet/skillSet.selector';
import noImgFound from 'assets/images/no-img-found.png';


export class SkillCard extends Component {

    render() {
        const { skill, currentUser, excilyensModeratorNumber, excilyensNumber } = this.props;


        return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Link to={`/skills/${skill.skillName}`} style={{ textDecoration: 'none' }}>
                    <Card className="techcard-card" cy-data="tech-card" data-testid="tech-card">
                        <CardActionArea className="techcard-card-action">
                            <Tooltip title={skill.description
                                ? <div dangerouslySetInnerHTML={{ __html: skill.description }}/>
                                : ''}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                    direction="row"
                                    alignItems="center"
                                    className="grid-card-container"
                                >
                                    <Grid item xs={4}>
                                        {skill.imageUrl
                                            ? <CardMedia
                                                component="img"
                                                className="skill-image"
                                                alt={skill.skillName}
                                                image={skill.imageUrl}
                                                style={{ objectFit: 'contain' }}
                                            >
                                            </CardMedia>
                                            : <CardMedia
                                                className="skill-image"
                                                component="img"
                                                alt="no-img-found"
                                                image={noImgFound}
                                                style={{ objectFit: 'contain' }}
                                            >
                                            </CardMedia>
                                        }
                                    </Grid>

                                    <Grid item xs={8}>
                                        <CardContent className="skill-card-content" style={{ padding: 0 }}>
                                            <Typography variant="h6" noWrap>
                                                {skill.skillName}
                                            </Typography>
                                            <div className="chip-container">
                                                {skill.familyInternalDto && skill.familyInternalDto.name &&
                                                    <Chip label={<Typography variant="inherit" className="chip-item-typography" noWrap>
                                                        {skill.familyInternalDto.name}
                                                    </Typography>}
                                                    size="small" className="chip-item" style={{ cursor: 'pointer' }}/>
                                                }
                                                {skill.familyInternalDtoOptional && skill.familyInternalDtoOptional.name &&
                                                    <Chip label={<Typography variant="inherit" className="chip-item-typography" noWrap>
                                                        {skill.familyInternalDtoOptional.name}
                                                    </Typography>}
                                                    size="small" className="chip-item" style={{ cursor: 'pointer' }}/>
                                                }
                                            </div>
                                            {skill.categoryInternalDto && skill.categoryInternalDto.name &&
                                                <Chip label={<Typography variant="inherit" className="chip-item-typography" noWrap>
                                                    {skill.categoryInternalDto.name}
                                                </Typography>}
                                                size="small" className="chip-item" style={{ cursor: 'pointer' }}/>
                                            }
                                        </CardContent>
                                        <div className="excilyen-moderator-nb">
                                            <Tooltip title="Oxyliens" placement="top">
                                                <div className="left-icon-display">
                                                    <div
                                                        className="nb">{excilyensNumber}
                                                    </div>
                                                    <Icon color="primary">school</Icon>
                                                </div>
                                            </Tooltip>
                                            {
                                                currentUser.roles &&
                                                currentUser.roles.some((role) => role.name === 'ADMIN') &&
                                                <Tooltip title="Modérateurs" placement="top">
                                                    <div className="left-icon-display">
                                                        <div
                                                            className="nb">{excilyensModeratorNumber}
                                                        </div>
                                                        <Icon color="primary">verified_user</Icon>
                                                    </div>
                                                </Tooltip>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        </CardActionArea>
                    </Card>
                </Link>
            </Grid>
        );
    }
}

SkillCard.propTypes = {
    skill: PropTypes.object,
    currentUser: PropTypes.object,
    excilyensModeratorNumber: PropTypes.number,
    excilyensNumber: PropTypes.number
};

const mapStateToProps = (state, props) => ({
    excilyensModeratorNumber: selectSkillModeratorsCount(state, props.skill.id),
    excilyensNumber: selectSkillUsersCount(state, props.skill.id),
    currentUser: selectCurrentUser(state)
});

export default connect(mapStateToProps, null)(SkillCard);
