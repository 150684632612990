import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { Redirect } from 'react-router-dom';
import ResolveSerieDisplay from './ResolveSerieDisplay';

import { useDispatch, useSelector } from 'react-redux';
import { selectSerieToResolve } from 'app/redux/selectors/Question/serie.selector';
import { selectLoading } from 'app/redux/selectors/Loading/loading.selector';
import { getSpacedRepetitionSerieByUserId, GET_SPACED_REPETITION_SERIE_BY_USER_ID, resetSerieToResolve } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { putDailyQuestionsDone } from 'app/redux/actions/Question/DailyQuestions/DailyQuestionsSet/dailyQuestionsSet.actions';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { RETRIEVE_DAILY_QUESTIONS_ERROR } from 'app/Snackbar/NotificationMessages';
import { CircularProgress } from '@material-ui/core';

function ResolveSerieRequestByUserId(props) {

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();
    const dispatch = useDispatch();

    const serieToResolve = useSelector(selectSerieToResolve);
    const isLoading = useSelector(selectLoading)[GET_SPACED_REPETITION_SERIE_BY_USER_ID];

    useEffect(() => {
        dispatch(getSpacedRepetitionSerieByUserId(getCurrentUserId()));
        return () => {
            dispatch(resetSerieToResolve());
        };
    }, [props.date]);

    const handleCloseQuiz = () => {
        dispatch(putDailyQuestionsDone(getCurrentUserId(), props.date));
    };

    return (
        <div data-testid="resolve-serie-display" style={{ height: '100%' }}>
            {isLoading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress size={100} thickness={2}/>
                </div>
                : serieToResolve?.questionAskedList &&
                <ResolveSerieDisplay
                    questionsList={serieToResolve.questionAskedList}
                    nbTotalQuestions={serieToResolve.questionAskedList.length}
                    type={serieToResolve.type}
                    redirect={QUESTIONS_PAGE_URL}
                    onCloseQuiz={handleCloseQuiz}
                />
            }
            {!isLoading && serieToResolve?.questionAskedList && !serieToResolve.questionAskedList.length &&
                dispatch(notificationError(RETRIEVE_DAILY_QUESTIONS_ERROR)) &&
                <Redirect to={QUESTIONS_PAGE_URL} />
            }
        </div>
    );
}

ResolveSerieRequestByUserId.propTypes = {
    date: PropTypes.string.isRequired
};

export default ResolveSerieRequestByUserId;
