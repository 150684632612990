import React from 'react';

export const languageOptions = [
    {
        id: 0,
        value: 'zza',
        label: <img src="https://prod-exciskills-bucket.s3.eu-west-3.amazonaws.com/flags/NONE.png" width="36" height="24"/>
    }, {
        id: 1,
        value: 'eng',
        label: <img src="https://prod-exciskills-bucket.s3.eu-west-3.amazonaws.com/flags/EN.png" width="36" height="24"/>
    }, {
        id: 2,
        value: 'fra',
        label: <img src="https://prod-exciskills-bucket.s3.eu-west-3.amazonaws.com/flags/FR.png" width="36" height="24"/>
    }
];
