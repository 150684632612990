import { Marker, Tooltip } from 'react-leaflet';
import { ClientsMapMarkerIcon } from './ClientsMapMarkerIcon';
import React, { Fragment } from 'react';
import ClientsMapMarkerPopup from './ClientsMapMarkerPopup';
import PropTypes from 'prop-types';

const ClientsMapMarker = (props) => {
    const { clientMarkers } = props;
    const markers = clientMarkers.map((clientMarker, index) => (
        clientMarker.latitude && clientMarker.longitude &&
        <Marker
            key={index}
            position={[
                clientMarker.longitude,
                clientMarker.latitude
            ]}
            icon={ClientsMapMarkerIcon}
        >
            <Tooltip permanent>{clientMarker.name}</Tooltip>
            <ClientsMapMarkerPopup data={clientMarker} />
        </Marker>
    ));

    return <Fragment>{markers}</Fragment>;
};
ClientsMapMarker.propTypes = {
    clientMarkers: PropTypes.array
};
export default ClientsMapMarker;
