import axiosClient from './utils/axiosClient';

export const QUESTION_REPORTING_URI = 'reporting/question';


export function postQuestionReportingApi(questionReporting) {
    return axiosClient.post(`${QUESTION_REPORTING_URI}/reporting`, questionReporting);
}

export function getQuestionsReportingApi(userId, questionId) {
    return axiosClient.get(`${QUESTION_REPORTING_URI}/${questionId}?userId=${userId}`);
}

export function patchQuestionDeniedReportingApi(userId, questionId, reportingId) {
    return axiosClient.patch(`${QUESTION_REPORTING_URI}/reporting/${reportingId}/${userId}/${questionId}/denied/`);
}

export function patchQuestionTreatedReportingApi(userId, questionId, reportingId) {
    return axiosClient.patch(`${QUESTION_REPORTING_URI}/reporting/${reportingId}/${userId}/${questionId}/treated/`);
}
