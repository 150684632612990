import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';
import Prism from 'prism/prism';
import 'prism/prism.css';


export const highlightCode = (isQuestion) => {
    if(isQuestion) {
        document.querySelectorAll('pre code').forEach((elt) => {
            Prism.highlightElement(elt);
        });
    } else {
        hljs.configure({ ignoreUnescapedHTML: true });
        document.querySelectorAll('pre code').forEach((elt) => {
            if(!elt.getElementsByTagName('input').length) {
                hljs.highlightElement(elt);
            }
        });
    }
};

export function StyledText(props) {

    useEffect(() => {
        highlightCode(props.isQuestion);
    }, [props.statement]);

    return (<div
        className = { `${props.isQuestion ? 'highlight' : ''} ${props.className}` }
        dangerouslySetInnerHTML={{ __html: props.statement }}>
    </div>
    );
}

StyledText.propTypes = {
    statement: PropTypes.string,
    className: PropTypes.string.isRequired,
    isQuestion: PropTypes.bool
};
