import { RootState, loadingStore } from 'app/redux/rootReducer';

export const createErrorMessageSelector = (actions: string[]) => (state: RootState) => {
    const errors = actions.map((action) => state.error[action]);
    if(errors && errors[0]) {
        return errors[0];
    }
    return null;
};

export const createLoadingSelector = (actions: string[]) => (state: RootState) => actions.some((action) => (
    state[loadingStore][action]
));

export const createWaiterLoadingSelector = (actions: string[]) => (state: RootState) => actions.some((action) => (
    state[loadingStore][action] ||
    state[loadingStore][action] === undefined
));


/*
 * Return false if there is no value in store and actions are not fetching
 * return false if there is value in store
 * return true if there is no value in store and actions are fetching
 */
export const createLoadingSelectorForStoreValue = (actions: string[], stateSelector: any) => (state: RootState) => {
    if(Array.isArray(stateSelector(state)) && stateSelector(state).length) {
        return false;
    }
    return createLoadingSelector(actions)(state);
};
