import { createTheme, MuiThemeProvider, TablePagination } from '@material-ui/core';
import { getPageQuestionOuverteByPage } from 'app/api/historyApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import QuestionRequestByQuestionHistoryId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByQuestionHistoryId';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { selectAllUser } from 'app/redux/selectors/User/user.selector';
import MaterialTable from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dateDisplay, resultDisplay } from '../History.functions';
import TablePaginationActions from 'app/utils/TablePaginationActions';
import EntretienTitleDraggable from './EntretienTitleDraggable';

export default function EntretienHistory() {
    const currentUserId = AuthenticationContainer.getCurrentUserId();

    const allUsers = useSelector(selectAllUser);

    const skillsReduced = useSelector(selectAllReducedSkills).data;

    const [entretiens, setEntretiens] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [questionsCount, setQuestionsCount] = useState(0);

    const handleChangePage = (event, number) => {
        setPageNumber(number);
    };

    const handleRowsPerPage = (event) => {
        setPageNumber(0);
        setRowsPerPage(event.target.value);
    };

    const style = { 'entretien-history': {
        margin: '5% 1% 1% 3%'
    } };

    const theme = createTheme({
        overrides: {
            MuiTableCell: {
                body: {
                    padding: '2px 8px'
                },
                paddingNone: {
                    padding: '2px 0px 2px 4px',
                    width: '44px'
                }
            }
        }
    });

    useEffect(() => {
        const pageCriteria = {
            offset: pageNumber * rowsPerPage,
            step: rowsPerPage,
            criteria: {
                userId: currentUserId
            }
        };
        getPageQuestionOuverteByPage(pageCriteria)
            .then(({ data }) => {
                setQuestionsCount(data.totalNumberOfElements);
                setEntretiens(data.listOfElements.map((entretien) => ({
                    id: entretien.id,
                    title: entretien.title,
                    skillReduced: skillsReduced.find((skillReduced) => skillReduced.id === entretien.skillId),
                    student: allUsers.find((student) => student.id === entretien.studentId),
                    status: entretien.result,
                    review: entretien.reviewText,
                    grade: entretien.grade,
                    date: entretien.date
                })));
            });
    }, [pageNumber, rowsPerPage]);

    const refreshEntretiens = () => {
        const pageCriteria = {
            offset: pageNumber * rowsPerPage,
            step: rowsPerPage,
            criteria: {
                userId: currentUserId
            }
        };
        getPageQuestionOuverteByPage(pageCriteria)
            .then(({ data }) => {
                setQuestionsCount(data.totalNumberOfElements);
                setEntretiens(data.listOfElements.map((entretien) => ({
                    id: entretien.id,
                    title: entretien.title,
                    skillReduced: skillsReduced.find((skillReduced) => skillReduced.id === entretien.skillId),
                    student: allUsers.find((student) => student.id === entretien.studentId),
                    status: entretien.result,
                    review: entretien.reviewText,
                    grade: entretien.grade,
                    date: entretien.date
                })));
            });
    };

    const entretienColumns = [
        {
            title: 'Question',
            render: (rowData) => (
                <EntretienTitleDraggable
                    id={Number(rowData.title.split('#')[1])}
                    name={rowData.title}
                />
            ),
            cellStyle: {
                width: '25%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '25%'
            }
        },
        {
            title: 'Élève',
            render: (rowData) => (
                <Fragment key={rowData.student?.id}>
                    {rowData.student?.firstName} {rowData.student?.lastName}
                </Fragment>
            )
        },
        {
            title: 'Statut',
            render: (rowData) => (
                resultDisplay(rowData.result)
            )
        },
        {
            title: 'Date',
            render: (rowData) => (
                dateDisplay(rowData.date)
            )
        }
    ];

    return (
        <div style={style['entretien-history']}>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    columns={entretienColumns}
                    data={entretiens}
                    options={{
                        search: false,
                        title: true,
                        toolbar: false,
                        pageSize: rowsPerPage
                    }}
                    detailPanel={[
                        {
                            tooltip: 'Accéder à la question',

                            render: (rowData) => (
                                <QuestionRequestByQuestionHistoryId
                                    questionHistoryId={rowData.id}
                                    isForCoach
                                    refreshEntretiens={refreshEntretiens}
                                />
                            )
                        }
                    ]}
                    localization={{
                        'header': {
                            'actions': ''
                        },
                        'body': {
                            'emptyDataSourceMessage': 'Aucun entretien en attente'
                        }
                    }}
                    components={{
                        Pagination: () => (
                            <TablePagination className="pagination"
                                count={questionsCount}
                                page={pageNumber}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleRowsPerPage}
                                rowsPerPageOptions={[10]}
                                labelRowsPerPage={'Entretiens par page'}
                                backIconButtonText={'Page précédente'}
                                nextIconButtonText={'Page suivante'}
                                labelDisplayedRows={({ from, to, count }) => (`${from}-${to} / ${count}`)}
                                ActionsComponent={TablePaginationActions}

                            />
                        )
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
}
