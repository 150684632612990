/* eslint-disable no-console */
import React, { Component } from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import { setupStore } from './redux/rootReducer';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import SnackbarManager from './Snackbar/SnackbarManager';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AuthenticationContainer from './login/containers/AuthenticationContainer';
import SockJsClient from 'react-stomp';
import { addNotification } from './redux/actions/Notification/userNotifications.actions';

import Login from 'app/login/components/Login';
import Layout from './layout/Layout';
import Routes from './Routes';
import { CandidateWelcome } from './candidate/CandidateWelcome/CandidateWelcome';

export const store = setupStore();

class App extends Component {

    render() {

        const maxSnack = 2;
        const autoHideDuration = 2000;

        return (
            <>
                <DndProvider backend={HTML5Backend}>
                    <Provider store={store}>

                        <SnackbarProvider maxSnack={maxSnack} autoHideDuration={autoHideDuration} >
                            <Router forceRefresh={false}>
                                <Switch>
                                    <Route path={'/login'} render={({ location }) => <Login url={location?.state?.from?.pathname} />} />
                                    <Route path={'/candidat/:uuid'} component={CandidateWelcome}/>
                                    <Route path={'/'} render={(props) => (
                                        AuthenticationContainer.isConnected()
                                            ? <Layout {...props}>
                                                <Routes />
                                            </Layout>
                                            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                                    )} />
                                </Switch>
                            </Router>
                            <SnackbarManager />
                        </SnackbarProvider>
                    </Provider>
                </DndProvider>
                <SockJsClient
                    url={`${process.env.REACT_APP_BACK_END}websocket`}
                    topics={[`/user/${AuthenticationContainer.getCurrentUserId()}/notification`]}
                    onConnect={() => {
                        console.log('connected');
                    } }
                    onDisconnect={() => {
                        console.log('Disconnected');
                    } }
                    onMessage={(msg) => {
                        store.dispatch(addNotification(msg));
                        console.log(msg);
                    } }
                />
            </>
        );
    }
}

export default App;
