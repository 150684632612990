import { Chart } from 'react-google-charts';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectAllExcilyensNumber } from '../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import './NbExcilyensChart.scss';

export class NbExcilyensChart extends Component {

    render() {

        const nbExcilyens = 201;

        const registered = this.props.excilyensNumber;
        const notRegistered = nbExcilyens - registered;

        return (
            <div className="chart">
                <div className="titre"> Nombre de collaborateurs inscrits </div>
                <Chart
                    width= "100%"
                    height={500}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        [
                            'label',
                            'number'
                        ],
                        [
                            'Inscrits',
                            registered
                        ],
                        [
                            'Non inscrits',
                            notRegistered
                        ]
                    ]}
                    options={{
                        pieHole: 0.4
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        );
    }
}


NbExcilyensChart.propTypes = {
    excilyensNumber: PropTypes.number
};

function mapStateToProps(state) {
    return {
        excilyensNumber: selectAllExcilyensNumber(state)
    };
}

export default connect(mapStateToProps)(NbExcilyensChart);
