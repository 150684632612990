import { makeStyles } from '@material-ui/core';

import { QUESTION_TYPE_UNKNOWN } from 'app/Snackbar/NotificationMessages';
import { QuestionApiDtoEnum, QuestionAskedEnum } from 'app/utils/QuestionEnum';
import { getTrouInputId, invalidTrouColor, validTrouColor } from 'app/utils/QuestionUtils';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const useStyles = makeStyles(() => ({
    questionContainer: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        border: '2px solid #ababab',
        minWidth: '360px',
        padding: '20px'
    },
    formControlLabelStyle: {
        border: '2px solid  #e2e1e0',
        borderRadius: '5px',
        boxSizing: 'border-box',
        margin: '3px 0',
        width: '100%'
    },
    formValid: {
        backgroundColor: validTrouColor
    },
    formError: {
        backgroundColor: invalidTrouColor
    },
    labelStyle: {
        whiteSpace: 'pre-line',
        opacity: 1,
        color: '#000',
        wordBreak: 'break-word',
        width: '100%'
    },
    button: {
        marginLeft: '6px'
    },
    terminateButton: {
        marginRight: 'auto'
    },
    showCourseButton: {
        width: '100%'
    },
    editCodeButton: {
        marginBottom: '8px'
    },
    formGroupStyle: {
        width: '100%'
    },
    answerUsersIcons: {
        columnGap: '8px',
        paddingRight: '12px'
    },
    loader: {
        marginLeft: '50px',
        marginRight: '50px'
    }
}));

export const noExplanationMessage = 'Aucune explication n\'a été fournie.';

export function validateQuestionResolverWithCourse(stockedAnswers, receivedAnswers, examMode) {
    stockedAnswers?.forEach((stockElem) => {
        receivedAnswers.forEach((receivedElem) => {
            if(receivedElem.id === stockElem.id) {
                stockElem.label = receivedElem.label;
                if(stockElem.selected && !receivedElem.validAnswer) {
                    stockElem.formControlStyle = false;
                } else if(receivedElem.validAnswer) {
                    stockElem.formControlStyle = true;
                }
            }
        });
        if(!examMode) {
            stockElem.isDisabled = true;
        }
    });
    return stockedAnswers;
}

export function toggleSelectedCheckbox(responses, id) {
    responses.forEach((elem) => {
        if(id === elem.id.toString()) {
            elem.selected = !elem.selected;
        }
    });
    return responses;
}

export const sortAnswerByLabel = (answers) => answers?.sort((elem1, elem2) => elem1.label && elem2.label
    ? elem1.label.localeCompare(elem2.label)
    : 0);

export const displayLabel = (elem) => {
    let label = '';
    if(elem.label) {
        label = label.concat(elem.label).concat(': ');
    }
    label = label.concat(elem.text);
    return label;
};

export function displayExplanation(explanation) {
    if(!explanation) {
        return noExplanationMessage;
    }
    return explanation;
}

const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

export function getInputForQuestionTexteATrouWithOrdinal(ordinal) {
    return document.getElementById(getTrouInputId(ordinal));
}

export function getUserAnswerForQuestionTexteATrouWithOrdinal(ordinal) {
    const answer = getInputForQuestionTexteATrouWithOrdinal(ordinal);
    return answer?.value.trim();
}

function getQuestionClassType(classType) {
    switch (classType) {
        case QuestionAskedEnum.MultipleChoice:
            return QuestionApiDtoEnum.MultipleChoice;

        case QuestionAskedEnum.TexteATrou:
            return QuestionApiDtoEnum.TexteATrou;
        case QuestionAskedEnum.QuestionOuverte:
            return QuestionApiDtoEnum.QuestionOuverte;
        default:
            return undefined;
    }
}

// eslint-disable-next-line max-params
export const createObjectAnswers = (candidateUuid, question, answers, taskId, serieHistoryUpdate, serieTotalQuestionNb, quizUuidFromLeader, fileUrl, textAnswer, parcoursStepContentId) => {
    const selected = [];
    answers?.forEach((elem) => {
        switch (question.class) {
            case QuestionAskedEnum.MultipleChoice:
                const answer = {
                    id: elem.id,
                    selected: Boolean(elem.selected)
                };
                selected.push(answer);
                break;

            case QuestionAskedEnum.TexteATrou:
                selected.push(getUserAnswerForQuestionTexteATrouWithOrdinal(elem.ordinal));
                break;
            case QuestionAskedEnum.QuestionOuverte:
                break;
            default:
                // eslint-disable-next-line no-console
                console.error(QUESTION_TYPE_UNKNOWN);
        }
    });
    const answerObject = {
        id: question.id,
        class: getQuestionClassType(question.class),
        answersSelected: answers ? selected : undefined,
        taskId,
        candidateUuid,
        serieHistoryId: serieHistoryUpdate?.id,
        serieTotalQuestionNb,
        questionHistoryId: serieHistoryUpdate?.questions?.find((questionHistory) => questionHistory.question.id === question.id).id,
        fileUrl,
        textAnswer,
        parcoursStepContentId
    };
    if(candidateUuid) {
        return answerObject;
    }
    answerObject.userId = getCurrentUserId();
    answerObject.isInteractiveQuizLeader = Boolean(quizUuidFromLeader);
    return answerObject;
};

export function validateQuestionTexteATrou(question) {
    question.answers.forEach((answer) => {
        const inputElement = getInputForQuestionTexteATrouWithOrdinal(answer.ordinal);
        if(inputElement) {
            inputElement.style.backgroundColor = answer.acceptedAnswers.includes(inputElement.value)
                ? validTrouColor
                : invalidTrouColor;
            inputElement?.setAttribute('disabled', true);
        }
    });
}
