import {
    putBookmarkApi,
    postBookmarkApi,
    getBookmarkByUserAndSkillApi,
    deleteBookmarkByIdApi
} from 'app/api/bookmarkApi';


export const GET_BOOKMARK_BY_USER_AND_CHAPTER = 'GET_BOOKMARK_BY_USER_AND_CHAPTER';
export const GET_BOOKMARK_BY_USER_AND_CHAPTER_REQUEST = 'GET_BOOKMARK_BY_USER_AND_CHAPTER_REQUEST';
export const GET_BOOKMARK_BY_USER_AND_CHAPTER_SUCCESS = 'GET_BOOKMARK_BY_USER_AND_CHAPTER_SUCCESS';
export const GET_BOOKMARK_BY_USER_AND_CHAPTER_FAILURE = 'GET_BOOKMARK_BY_USER_AND_CHAPTER_FAILURE';

export const PUT_BOOKMARK = 'PUT_GET_BOOKMARK';
export const PUT_BOOKMARK_REQUEST = 'PUT_BOOKMARK_REQUEST';
export const PUT_BOOKMARK_SUCCESS = 'PUT_BOOKMARK_SUCCESS';
export const PUT_BOOKMARK_FAILURE = 'PUT_BOOKMARK_FAILURE';

export const POST_BOOKMARK = 'POST_BOOKMARK';
export const POST_BOOKMARK_REQUEST = 'POST_BOOKMARK_REQUEST';
export const POST_BOOKMARK_SUCCESS = 'POST_BOOKMARK_SUCCESS';
export const POST_BOOKMARK_FAILURE = 'POST_BOOKMARK_FAILURE';

export const DELETE_BOOKMARK_BY_ID = 'DELETE_BOOKMARK_BY_ID';
export const DELETE_BOOKMARK_BY_ID_REQUEST = 'DELETE_BOOKMARK_BY_ID_REQUEST';
export const DELETE_BOOKMARK_BY_ID_SUCCESS = 'DELETE_BOOKMARK_BY_ID_SUCCESS';
export const DELETE_BOOKMARK_BY_ID_FAILURE = 'DELETE_BOOKMARK_BY_ID_FAILURE';

export const getBookmarkUserAndSkill = (userId, skillId) => (dispatch) => {
    dispatch({ type: GET_BOOKMARK_BY_USER_AND_CHAPTER_REQUEST });

    return getBookmarkByUserAndSkillApi(userId, skillId).then((response) => {
        dispatch({
            type: GET_BOOKMARK_BY_USER_AND_CHAPTER_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_BOOKMARK_BY_USER_AND_CHAPTER_FAILURE,
            payload: error,
            error: true
        });
    });
};

export const postBookmark = (bookmark) => (dispatch) => {
    dispatch({ type: POST_BOOKMARK_REQUEST });

    return postBookmarkApi(bookmark).then((response) => {
        dispatch({
            type: POST_BOOKMARK_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: POST_BOOKMARK_FAILURE,
            payload: error,
            error: true
        });
    });
};

export const putBookmark = (bookmark) => (dispatch) => {
    dispatch({ type: PUT_BOOKMARK_REQUEST });

    return putBookmarkApi(bookmark).then((response) => {
        dispatch({
            type: PUT_BOOKMARK_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: PUT_BOOKMARK_FAILURE,
            payload: error,
            error: true
        });
    });
};

export const deleteBookmarkById = (id) => (dispatch) => {
    dispatch({ type: DELETE_BOOKMARK_BY_ID_REQUEST });

    return deleteBookmarkByIdApi(id).then(() => {
        dispatch({ type: DELETE_BOOKMARK_BY_ID_SUCCESS });
    }, (error) => {
        dispatch({
            type: DELETE_BOOKMARK_BY_ID_FAILURE,
            payload: error,
            error: true
        });
    });
};


