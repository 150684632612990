import React from 'react';
import PropTypes from 'prop-types';
import CoachingContent from './CoachingContent';
import { useLocation, withRouter } from 'react-router-dom';

export function Coaching() {

    const location = useLocation();

    const groupId = location?.groupId;

    return (
        <section data-testid="coaching-section">
            <CoachingContent groupId={groupId}/>
        </section>
    );
}

Coaching.propTypes = {
    match: PropTypes.object
};


export default withRouter(Coaching);
