/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import './EditSkill.scss';
import Fab from '@material-ui/core/Fab';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import PropTypes from 'prop-types';
import { EDIT_SKILL, editSkill, GET_SKILL_SUCCESS } from '../../redux/actions/Skill/SkillSet/skillSet.actions';
import { AutoSuggestImpl } from '../../common/selects/AutoSuggestImpl';
import { selectCurrentUser } from '../../redux/selectors/User/user.selector';
import { SimpleSelect } from '../../common/selects/SimpleSelect';
import RichTextEditor from '../../common/richTextEditor/RichTextEditor';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/withWidth';
import Grid from '@material-ui/core/Grid';
import { createErrorMessageSelector, createLoadingSelector } from '../../redux/selectors/APISelector/APISelector';
import { selectCategories, selectFamilies, selectFamilyByName, selectCategoryByName }
    from '../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { getAllCategories, getAllFamilies } from '../../redux/actions/FamilyCategory/familiesCategories.actions';

export function EditSkill(props) {

    const [open, setOpen] = useState(false);
    const [familyName, setFamilyName] = useState(props.skillToEdit.familyInternalDto && props.skillToEdit.familyInternalDto.name);
    const [familyNameOptional, setFamilyNameOptional] = useState(props.skillToEdit.familyInternalDtoOptional && props.skillToEdit.familyInternalDtoOptional.name);
    const [categoryName, setCategoryName] = useState(props.skillToEdit.categoryInternalDto && props.skillToEdit.categoryInternalDto.name);
    const [description, setDescription] = useState(props.skillToEdit.description);
    const [imageUrl, setImageUrl] = useState(props.skillToEdit.imageUrl);

    const [isChangedFamilyName, setIsChangedFamilyName] = useState(false);
    const [isChangedFamilyNameOptional, setIsChangedFamilyNameOptional] = useState(false);
    const [isChangedCategoryName, setIsChangedCategoryName] = useState(false);
    const [isChangedDescription, setIsChangedDescription] = useState(false);
    const [isChangedImageUrl, setIsChangedImageUrl] = useState(false);

    const isEnabled = familyName &&
    familyName.length > 0 &&
    props.skillToEdit.familyInternalDto &&
    props.skillToEdit.familyInternalDto.name &&
    props.skillToEdit.familyInternalDto.name.length > 0;

    const familiesName = props.families.map((familyInternalDto) => familyInternalDto.name);
    const categoriesName = props.categories.map((categoryInternalDto) => categoryInternalDto.name);

    const openModal = () => {
        props.getAllCategories();
        props.getAllFamilies();
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const submit = () => {

        const newFamily = props.selectFamily(familyName)
            ? props.selectFamily(familyName)
            : familyName
                ? {
                    name: familyName
                }
                : null;

        const newFamilyOpt = props.selectFamily(familyNameOptional)
            ? props.selectFamily(familyNameOptional)
            : familyNameOptional
                ? {
                    name: familyNameOptional
                }
                : null;
        const familyOpt = isChangedFamilyNameOptional
            ? newFamilyOpt
            : props.skillToEdit.familyInternalDtoOptional;

        const newCategory = props.selectCategory(categoryName)
            ? props.selectCategory(categoryName)
            : categoryName
                ? {
                    name: categoryName
                }
                : null;

        const skill = {
            id: props.skillToEdit.id,
            skillName: props.skillToEdit.skillName,
            familyInternalDto: isChangedFamilyName ? newFamily : props.skillToEdit.familyInternalDto,
            familyInternalDtoOptional: familyName ? familyOpt : null,
            categoryInternalDto: isChangedCategoryName ? newCategory : props.skillToEdit.categoryInternalDto,
            description: isChangedDescription ? description : props.skillToEdit.description,
            imageUrl: isChangedImageUrl ? imageUrl : props.skillToEdit.imageUrl,
            coveredByExcilys: props.skillToEdit.coveredByExcilys
        };

        props.editSkill(skill);

        setIsChangedFamilyName(false);
        setIsChangedFamilyNameOptional(false);
        setIsChangedCategoryName(false);
        setIsChangedDescription(false);
        setIsChangedImageUrl(false);
    };

    const onChangeFamilyName = (newValue) => {
        setIsChangedFamilyName(true);
        setFamilyName(newValue);
    };

    const onChangeFamilyNameOptional = (newValue) => {
        setIsChangedFamilyNameOptional(true);
        setFamilyNameOptional(newValue);
    };

    const onChangeCategoryName = (newValue) => {
        setIsChangedCategoryName(true);
        setCategoryName(newValue);
    };

    const onChangeDescription = (event, editor) => {
        setIsChangedDescription(true);
        setDescription(editor.getData());
    };

    const onChangeImageUrl = (event) => {
        setIsChangedImageUrl(true);
        setImageUrl(event.target.value);
    };

    useEffect(() => {
        if(!props.isFetching && !props.error) {
            closeModal();
        }
    }, [props.isFetching]);

    return (
        <div data-testid="edit-skill">
            <Fab color="primary" aria-label="Edit" className="edit-button" onClick={openModal}
                id="edit-button" data-testid="edit-button"
            >
                <Edit/>
            </Fab>
            <Dialog disableEnforceFocus={true} maxWidth="md" fullWidth={true}
                fullScreen={!isWidthUp('sm', props.width)}
                open={open} onClose={closeModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Modifier une compétence</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12} data-testid="skill-name">
                            <TextField margin="dense" name="skillName" label="Nom" type="text"
                                fullWidth defaultValue={props.skillToEdit.skillName} disabled/>
                        </Grid>
                        {props.user.roles &&
                        props.user.roles.some((role) => role.name === 'ADMIN')
                            ? <>
                                <Grid item xs={12} sm={4} data-testid="skill-family">
                                    <AutoSuggestImpl
                                        label="Famille"
                                        value={props.skillToEdit.familyInternalDto &&
                                            props.skillToEdit.familyInternalDto.name}
                                        suggestions={familiesName}
                                        onChange={onChangeFamilyName}/>
                                </Grid>
                                {(isEnabled || familyName) &&
                                <Grid item xs={12} sm={4} data-testid="skill-family-option">
                                    <AutoSuggestImpl
                                        label="Famille optionnelle"
                                        value={props.skillToEdit.familyInternalDtoOptional &&
                                            props.skillToEdit.familyInternalDtoOptional.name}
                                        suggestions={familiesName}
                                        onChange={onChangeFamilyName ? onChangeFamilyNameOptional : null}
                                    />
                                </Grid>
                                }
                                <Grid item xs={12} sm={4} data-testid="skill-category">
                                    <AutoSuggestImpl
                                        label="Catégorie"
                                        value={props.skillToEdit.categoryInternalDto &&
                                            props.skillToEdit.categoryInternalDto.name}
                                        suggestions={categoriesName}
                                        onChange={onChangeCategoryName}/>
                                </Grid>
                            </>

                            : <>

                                <Grid item xs={12} sm={4} data-testid="skill-family">
                                    <SimpleSelect
                                        label="Famille"
                                        value={props.skillToEdit.familyInternalDto &&
                                            props.skillToEdit.familyInternalDto.name}
                                        suggestions={[''].concat(familiesName)}
                                        onChange={onChangeFamilyName}/>
                                </Grid>
                                {
                                    (isEnabled || familyName) &&
                                    <Grid item xs={12} sm={4} data-testid="skill-family-option">

                                        <SimpleSelect
                                            label="Famille optionnelle"
                                            value={props.skillToEdit.familyInternalDtoOptional &&
                                                props.skillToEdit.familyInternalDtoOptional.name}
                                            suggestions={[''].concat(familiesName)}
                                            onChange={onChangeFamilyNameOptional}/>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={4} data-testid="skill-category">
                                    <SimpleSelect
                                        label="Catégorie"
                                        value={props.skillToEdit.categoryInternalDto &&
                                            props.skillToEdit.categoryInternalDto.name}
                                        suggestions={[''].concat(categoriesName)}
                                        onChange={onChangeCategoryName}/>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <div className="rich-text-editor-edit-skill">
                        <RichTextEditor onChange={onChangeDescription}
                            data={props.skillToEdit.description}/>
                    </div>
                    <div data-testid="skill-image">
                        <TextField margin="dense" name="imageUrl" label="URL de l'image" type="text"
                            fullWidth defaultValue={props.skillToEdit.imageUrl}
                            onChange={onChangeImageUrl}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary" data-testid="cancel-button">
                        Annuler
                    </Button>
                    <Button onClick={submit} color="primary" disabled={props.isFetching} data-testid="submit-button">
                        Modifier
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

EditSkill.propTypes = {
    skillToEdit: PropTypes.shape({
        id: PropTypes.number,
        skillName: PropTypes.string,
        familyInternalDto: PropTypes.object,
        familyInternalDtoOptional: PropTypes.object,
        categoryInternalDto: PropTypes.object,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        coveredByExcilys: PropTypes.bool
    }),
    user: PropTypes.object,
    families: PropTypes.array,
    categories: PropTypes.array,
    selectFamily: PropTypes.func,
    selectCategory: PropTypes.func,
    editSkill: PropTypes.func,
    getAllFamilies: PropTypes.func,
    getAllCategories: PropTypes.func,
    width: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool
};

const mapStateToProps = (state) => ({
    isFetching: createLoadingSelector([
        EDIT_SKILL,
        GET_SKILL_SUCCESS
    ])(state),
    error: createErrorMessageSelector([EDIT_SKILL])(state),
    user: selectCurrentUser(state),
    categories: selectCategories(state),
    families: selectFamilies(state),
    selectFamily: (familyName) => selectFamilyByName(state, familyName),
    selectCategory: (categoryName) => selectCategoryByName(state, categoryName)
});


export function mapDispatchToProps(dispatch) {
    return {
        editSkill: (skill) => dispatch(editSkill(skill)),
        getAllCategories: () => dispatch(getAllCategories()),
        getAllFamilies: () => dispatch(getAllFamilies())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(EditSkill));
