import React from 'react';
import { IconButton } from '@material-ui/core';
import Dislike from 'assets/images/dislike_1.svg';
import DislikeFull from 'assets/images/dislike_2.svg';
import PropTypes from 'prop-types';
export default function ReportButton({ isReported, setIsReported }) {

    const toggleReportAI = () => {
        setIsReported(isReported);
    };

    
    return (<IconButton id='iconHeaderRight' style={{ marginRight: '50px' }} color='primary' onClick={toggleReportAI}>
        {isReported                
            ? <img src={DislikeFull} alt='Custom Icon' style={{ width: '40px', height: '40px' }} />
            : <img src={Dislike} alt='Custom Icon' style={{ width: '40px', height: '40px' }} />
        }
    </IconButton>);
}

ReportButton.propTypes = {
    isReported:PropTypes.bool,
    setIsReported: PropTypes.func
};
