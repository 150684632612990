import axiosClient from './utils/axiosClient';

export const NOTIFICATION_URI = 'notifications';

export function getNotificationByDestinataireIdApi(id) {
    return axiosClient.get(`${NOTIFICATION_URI}/destinataires/${id}`);
}

export function deleteNotificationByIdApi(id) {
    return axiosClient.delete(`${NOTIFICATION_URI}/${id}`);
}

export function deleteAllNotificationByUserIdApi(id) {
    return axiosClient.delete(`${NOTIFICATION_URI}/all/${id}`);
}
