import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../SidePanel.scss';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import SidePanelProjectsFilterContainer from './SidePanelProjectsFilterContainer';
import { setProjectsClientFilter, setProjetsSkillsFilter, setProjectsExcilyenFilter, setProjectsSectorFilter }
    from '../../../redux/actions/Project/ProjectFilters/projectFilters.actions';
import {
    selectProjectFilteredBySkillAndClientAndUserAndSector
} from '../../../redux/selectors/Project/projectsFilters.selector';

export class SidePanelProjects extends Component {

    render() {
        return (
            <Grid container spacing={1} className="side-panel" data-testid="side-panel-projects">
                <Grid item container spacing={1} className="top">
                    <Grid item xs={12}>
                        <Typography className="sub-title-numbers-project"> {this.props.number} Projets</Typography>
                        <span className="title">Filtres</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span className="reset-filters-button" onClick={this.props.resetFilters} data-testid="side-panel-projects-reset">
                        Réinitialiser les filtres
                        </span>
                    </Grid>
                </Grid>
                <div className="filters-project">
                    <Grid item xs={12}>
                        <SidePanelProjectsFilterContainer client/>
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelProjectsFilterContainer skill/>
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelProjectsFilterContainer excilyen/>
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelProjectsFilterContainer sector/>
                    </Grid>
                </div>
            </Grid>
        );
    }
}

SidePanelProjects.propTypes = {
    resetFilters: PropTypes.func,
    number: PropTypes.number
};

function mapStateToProps(state) {
    return {
        number: selectProjectFilteredBySkillAndClientAndUserAndSector(state).length
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        resetFilters: () => {
            dispatch(setProjectsExcilyenFilter([]));
            dispatch(setProjectsClientFilter(null));
            dispatch(setProjetsSkillsFilter([]));
            dispatch(setProjectsSectorFilter(null));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelProjects);
