import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import {
    addSkillProject,
    deleteSkillProject
} from '../../../../redux/actions/Project/skillProjects.actions';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { selectCurrentProject } from 'app/redux/selectors/Project/projects.selector';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

const animatedComponents = makeAnimated();

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
}));

export default function ProjectStack(props) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const { data: project } = useSelector(selectCurrentProject);
    const projectId = project?.id ?? 0;

    const listSkill = props.listSkill && props.listSkill.map((skill) => ({
        value: skill.id,
        label: skill.skillName
    }));

    const [
        projectSkills,
        setProjectSkills
    ] = useState(listSkill);

    useEffect(() => {
        setProjectSkills(listSkill);
    }, [props]);

    const listSkillCoveredId = props.listSkillCovered && props.listSkillCovered.map((skillCovered) => skillCovered.id);

    const isCovered = (skill) => props.listSkillCovered && listSkillCoveredId.includes(skill.id);

    const listAllSkills = useSelector(selectAllSkills).data;

    const skillSetNotCovered = listAllSkills
        .filter((skill) => !isCovered(skill))
        .map((skill) => ({ value: skill.id, label: skill.skillName }));

    const [
        openEditSkills,
        setOpenEditSkills
    ] = useState(false);

    const findSkillIdToDelete = (currentStack, updatedStack) => {
        if(updatedStack === null) {
            return currentStack[0].value;
        }
        return currentStack.filter((skill) => !updatedStack.includes(skill))[0].value;
    };

    /**
     * Si le skill non couvert par Excilys à créer a le même nom qu'un skill déjà existant dans la stack technique
     * alors il ne faut pas le créer.
     */
    const handleCreateSkill = (value) => {
        const newSkill = listAllSkills.filter((skill) => skill.skillName === value);
        const isInStackTechnic = props.listSkillCovered.map((skill) => skill.skillName).includes(value);
        if((!newSkill || newSkill.length === 0) && !isInStackTechnic &&
            window.confirm('Êtes-vous sûr de vouloir créer cette compétence comme n\'étant pas couverte ?')) {
            dispatch(addSkillProject({
                projectId,
                skillName: value
            }));
        }
    };

    const handleChangeStack = (updatedStack) => {
        const projectSkillsLength =
      projectSkills === null ? 0 : projectSkills.length;
        const updatedStackLength = updatedStack === null ? 0 : updatedStack.length;
        if(updatedStackLength < projectSkillsLength) {
            dispatch(deleteSkillProject({
                projectId,
                skillId: findSkillIdToDelete(projectSkills, updatedStack)
            }));
        } else if(updatedStackLength > projectSkillsLength) {
            dispatch(addSkillProject({
                projectId,
                skillId: updatedStack[updatedStackLength - 1].value
            }));
        }
        setProjectSkills(updatedStack);
    };

    const handleDelete = (chipToDelete) => () => {
        dispatch(deleteSkillProject({
            projectId,
            skillId: chipToDelete.value
        }));
        setProjectSkills((chips) => chips.filter((chip) => chip.value !== chipToDelete.value));
    };

    const isCurrentUserAdmin = useSelector((state) => state.user.currentUser.roles &&
        state.user.currentUser.roles.some((role) => role.name === 'ADMIN'));

    return (
        <div className={classes.root}>
            {isCurrentUserAdmin && props.editable
                ? <Tooltip title="Éditer les compétences">
                    <Fab
                        className="openEditSkillsDialog"
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => setOpenEditSkills(true)}
                    >
                        <EditIcon/>
                    </Fab>
                </Tooltip>
                : <></>
            }
            <Dialog
                disableEnforceFocus={true}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={false}
                aria-labelledby="form-dialog-title"
                open={openEditSkills}
                onClose={() => setOpenEditSkills(false)}
                PaperProps={{ style: { overflowY: 'visible' } }}
            >
                <DialogTitle id="form-dialog-title">Éditer les compétences</DialogTitle>
                <DialogContent style={{ overflowY: 'visible' }}>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Creatable
                                className="basic-multi-select stack-select"
                                classNamePrefix="select"
                                name="project-stack"
                                placeholder="Stack du projet"
                                isSearchable={true}
                                isClearable={false}
                                isMulti={true}
                                backspaceRemovesValue={false}
                                components={animatedComponents}
                                options={skillSetNotCovered}
                                value={projectSkills}
                                onChange={handleChangeStack}
                                onCreateOption={handleCreateSkill}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditSkills(false)} color="primary">
                            Fermer
                    </Button>
                </DialogActions>
            </Dialog>

            {projectSkills &&
        projectSkills.map((skill) => (
            <Chip
                id="chip"
                style={{ cursor: 'pointer' }}
                key={skill.value}
                label={skill.label}
                onClick={() => {
                    window.location.href = `/skills/${skill.label}`;
                }}
                onDelete={isCurrentUserAdmin && props.editable ? handleDelete(skill) : ''}
                className={classes.chip}
            />
        ))}
        </div>
    );
}

ProjectStack.propTypes = {
    listSkill: PropTypes.array,
    editable: PropTypes.bool,
    listSkillCovered: PropTypes.array
};
