
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import ResolveSerieDisplay from './ResolveSerieDisplay';
import { mapDurationToObject } from 'app/utils/date.functions';

import { useDispatch, useSelector } from 'react-redux';
import { selectIsExamMode, selectSerieToResolve } from 'app/redux/selectors/Question/serie.selector';
import { selectSerieHistoryUpdate } from 'app/redux/selectors/Question/history.selector';
import { getSerieBySerieId, resetSerieToResolve } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { postSerieHistoryCandidate, postSerieHistory, resetSerieHistoryId } from 'app/redux/actions/Question/History/history.actions';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    circularProgress: {
        marginLeft: '47%',
        marginTop: '23%'
    }
}));

function ResolveSerieRequestBySerieId(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();

    const serieToResolve = useSelector(selectSerieToResolve);
    const serieHistoryUpdate = useSelector(selectSerieHistoryUpdate);
    const examMode = useSelector(selectIsExamMode);

    const extractParamsUrl = useMemo(() => new URLSearchParams(search), [search]);

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    useEffect(() => {
        dispatch(getSerieBySerieId(props.serieId, props.candidateUuid));
        return () => {
            dispatch(resetSerieToResolve());
            dispatch(resetSerieHistoryId());
        };
    }, [props.serieId]);

    useEffect(() => {
        if(serieToResolve?.questionAskedList?.length > 0) {
            if(props.candidateUuid) {
                dispatch(postSerieHistoryCandidate(props.candidateUuid));
            } else {
                const params = {
                    userId: getCurrentUserId(),
                    serieId: props.serieId,
                    taskId: Number(extractParamsUrl.get('taskId')),
                    questionsId: serieToResolve.questionAskedList.map((question) => question.id),
                    examMode
                };
                dispatch(postSerieHistory(params));
            }
        }
    }, [serieToResolve]);

    return (
        <div data-testid="resolve-serie-request-serie" style={{ height: '100%' }}>
            {serieHistoryUpdate?.id
                ? <ResolveSerieDisplay
                    maxDuration={mapDurationToObject(serieToResolve.maxDuration)}
                    serieId={props.serieId}
                    questionsList={serieToResolve.questionAskedList}
                    nbTotalQuestions={serieToResolve.questionAskedList.length}
                    serieHistoryUpdate={serieHistoryUpdate}
                    redirect={!props.candidateUuid && QUESTIONS_PAGE_URL}
                    candidateUuid={props.candidateUuid}
                    onFinishCandidateSerie={props.onFinishCandidateSerie}
                />
                : <CircularProgress
                    className={classes.circularProgress}
                    size={80}
                />
            }
        </div>
    );
}

ResolveSerieRequestBySerieId.propTypes = {
    serieId: PropTypes.number.isRequired,
    onFinishCandidateSerie: PropTypes.func.isRequired,
    candidateUuid: PropTypes.string
};

export default ResolveSerieRequestBySerieId;
