import { Chapter } from '../models/Chapter.model';
import { StateWithLoading } from '../redux.types';
import { getChapterBySkillAndUserApi } from 'app/api/chapterApi';
import { notificationError } from '../actions/Notification/notifications.actions';
import { RETRIEVE_CHAPTER_ERROR } from 'app/Snackbar/NotificationMessages';
import { createAppSlice, customThunk } from '../redux.helper';
import { getQuestionsCountByChapterIdsApi, searchQuestionsCountByChaptersInSkillApi } from 'app/api/multipleChoiceQuestionApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const chaptersStoreNew = 'chaptersNew';
export const allChaptersStore = 'allChapters';
export const drawerChaptersStore = 'drawerChapters';
export const chaptersQuestionCountStore = 'chaptersQuestionCountStore';
export const chapterQuestionFilterStore = 'chapterQuestionFilter';

interface ChapterState {
    [allChaptersStore]: StateWithLoading<Chapter[]>;
    [drawerChaptersStore]: StateWithLoading<Chapter[]>;
    [chaptersQuestionCountStore]: StateWithLoading<Map<number, number>>;
    [chapterQuestionFilterStore]: string;
}

const initialState: ChapterState = {
    [allChaptersStore]: {
        data: [],
        loading: false,
        error: null
    },
    [drawerChaptersStore]: {
        data: [],
        loading: false,
        error: null
    },
    [chaptersQuestionCountStore]: {
        data: new Map(),
        loading: false,
        error: null
    },
    [chapterQuestionFilterStore]: ''
};

const chapterSlice = createAppSlice({
    name: chaptersStoreNew,
    initialState,
    reducers: (create) => ({
        resetChaptersToDisplay: create.reducer((state) => {
            state[allChaptersStore] = { data: [], loading: false, error: null };
            state[chaptersQuestionCountStore].data.clear();
            state[chaptersQuestionCountStore].loading = false;
            state[chaptersQuestionCountStore].error = null;
            state[chapterQuestionFilterStore] = '';
        }),
        getChaptersToDisplay: customThunk(
            create,
            chaptersStoreNew,
            allChaptersStore,
            {
                request: ({ skillId, userId } : { skillId: number, userId: number }) => (
                    getChapterBySkillAndUserApi(skillId, userId)
                ),
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError(RETRIEVE_CHAPTER_ERROR));
                }
            }
        ),
        addChapters: create.reducer<Chapter[]>((state, { payload }) => {
            payload.forEach((chapter) => {
                state[allChaptersStore].data.push(chapter);
            });
        }),
        updateChapters: create.reducer<Chapter[]>((state, { payload }) => {
            payload.forEach((chapter) => {
                const chapterIndex = state[allChaptersStore].data.findIndex((elem) => elem.id === chapter.id);
                if(chapterIndex > -1) {
                    state[allChaptersStore].data[chapterIndex] = chapter;
                }
            });
        }),
        removeChapter: create.reducer<number>((state, { payload: id }) => {
            const chapterIndex = state[allChaptersStore].data.findIndex((elem) => elem.id === id);
            if(chapterIndex > -1) {
                state[allChaptersStore].data.splice(chapterIndex, 1);
            }
        }),
        getDrawerChaptersToDisplay: customThunk(
            create,
            chaptersStoreNew,
            drawerChaptersStore,
            {
                request: ({ skillId, userId } : { skillId: number, userId: number }) => (
                    getChapterBySkillAndUserApi(skillId, userId)
                ),
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError(RETRIEVE_CHAPTER_ERROR));
                }
            }
        ),
        resetQuestionCount: create.reducer((state) => {
            state[chaptersQuestionCountStore].data.clear();
            state[chaptersQuestionCountStore].loading = false;
            state[chaptersQuestionCountStore].error = null;
        }),
        getQuestionCountByChapterIds: customThunk(
            create,
            chaptersStoreNew,
            chaptersQuestionCountStore,
            {
                request: ({ chapterIds, search } : { chapterIds: number[], search: string }) => (
                    getQuestionsCountByChapterIdsApi(chapterIds, search)
                ),
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError('Désolé, nous n\'avons pas pu récupérer les stats des chapitres. Veuillez réessayer.'));
                },
                fulfilled: (state, action) => {
                    (action.payload as { chapterId: number, countQuestions: number}[]).forEach((elem) => {
                        state[chaptersQuestionCountStore].data.set(elem.chapterId, elem.countQuestions);
                    });
                }
            }
        ),
        searchQuestionCountByChapterBySkillId: customThunk(
            create,
            chaptersStoreNew,
            chaptersQuestionCountStore,
            {
                request: ({ skillId, search } : { skillId: number, search: string }) => (
                    searchQuestionsCountByChaptersInSkillApi(skillId, search, AuthenticationContainer.getCurrentUserId())
                ),
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError('Désolé, nous n\'avons pas pu récupérer les stats des chapitres. Veuillez réessayer.'));
                },
                fulfilled: (state, action) => {
                    state[chaptersQuestionCountStore].data.clear();
                    (action.payload as { chapterId: number, countQuestions: number}[]).forEach((elem) => {
                        state[chaptersQuestionCountStore].data.set(elem.chapterId, elem.countQuestions);
                    });
                }
            }
        ),
        updateFilter: create.reducer<string>((state, { payload }) => {
            state[chapterQuestionFilterStore] = payload;
        }),
        resetFilter: create.reducer<string>((state) => {
            state[chapterQuestionFilterStore] = '';
        })
    })
});

export const chaptersReducer = chapterSlice.reducer;

export const chapterActions = chapterSlice.actions;
