import { OXA } from 'app/redux/reducers/Chat/chat.reducer';

export const ADD_MESSAGE_TO_CHAT = 'ADD_MESSAGE_TO_CHAT';
export const UPDATE_INPUT_TEXT = 'UPDATE_INPUT_TEXT';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const STORE_MICROSKILL = 'STORE_MICROSKILL';
export const STORE_MICROSKILL_LIST = 'STORE_MICROSKILL_LIST';
export const ADD_REMEDIATION_CHOICE = 'ADD_REMEDIATION_CHOICE';

export const addMessageToChat = (message) => (dispatch) => {
    dispatch({ type: ADD_MESSAGE_TO_CHAT, payload: message });
};

export const updateInputText = (text) => (dispatch) => {
    dispatch({ type: UPDATE_INPUT_TEXT, payload: text });
};

export const clearMessage = () => (dispatch) => {
    dispatch({ type: REMOVE_MESSAGE, payload: [] });
};

export const resetChat = () => (dispatch) => {
    dispatch({ type: REMOVE_MESSAGE, payload: [
        {   
            sender: OXA, 
            content: 'Bonjour, je suis Oxa, votre coach virtuel. Je vais vous accompagner tout au long de votre parcours de formation. C\'est parti !' 
        }] });
};

export const storeMicroskill = (microskill) => (dispatch) => {
    dispatch({ type: STORE_MICROSKILL, payload: microskill });
};

export const storeMicroskillList = (microskill) => (dispatch) => {
    dispatch({ type: STORE_MICROSKILL_LIST, payload: microskill });
};

export const storeMicroskillByQuiestionId = (microskill) => (dispatch) => {
    dispatch({ type: STORE_MICROSKILL, payload: microskill });
};

export const addRemediationChoiceToChat = (questionMicroskills) => (dispatch) => {
    dispatch({ type: ADD_REMEDIATION_CHOICE, payload: questionMicroskills });
};
