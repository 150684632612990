import { getListQuestionHistoryGroupedApi } from 'app/api/historyApi';
import PropTypes from 'prop-types';

import React, { useState, useEffect } from 'react';
import HistoryList from '../../historyList/HistoryList';
import { HistoryDisplayEnum } from '../../historyRowHead/HistoryDisplayEnum';

function HistoryListQuestionGrouped(props) {

    const [listQuestionHistoryGrouped, setListQuestionHistoryGrouped] = useState([]);

    useEffect(() => {
        getListQuestionHistoryGroupedApi(props.serieHistoryId, props.groupName, props.id)
            .then((response) => {
                setListQuestionHistoryGrouped(response.data);
            });
    }, []);

    return (
        <HistoryList listContent={listQuestionHistoryGrouped}
            historyDisplayType = {HistoryDisplayEnum.QUESTION}
        />
    );
}

HistoryListQuestionGrouped.propTypes = {
    serieHistoryId: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
};

export default HistoryListQuestionGrouped;
