import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './QuestionOuverteBody.scss';

import SpeechRecognition from 'react-speech-recognition';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import { Tooltip } from '@material-ui/core';

export default function QuestionOuverteAudioRecorder(props) {

    const recorderControls = useAudioRecorder();

    useEffect(() => {
        if(recorderControls.isRecording) {
            props.setTotalText('');
        }
    }, [recorderControls.isRecording]);

    useEffect(() => {
        if(recorderControls.isRecording && !recorderControls.isPaused) {
            props.setIsQuestionOuverteRecording(true);
            SpeechRecognition.startListening();
        } else {
            SpeechRecognition.stopListening();
        }
    }, [recorderControls.isRecording, recorderControls.isPaused]);

    useEffect(() => {
        if(!props.listening && props.transcript) {
            props.setTotalText((prev) => `${prev} ${props.transcript}`);
            if(!recorderControls.isRecording) {
                props.saveAudioTextAnswer(props.totalText);
            }
            SpeechRecognition.startListening();
        }
    }, [props.transcript, props.listening, recorderControls.isRecording, props.totalText]);

    return (
        <Tooltip className="microphone-tooltip" title="Répondez en 2/3 minutes" placement="right">
            <div>
                <AudioRecorder
                    onRecordingComplete={props.addAudioElement}
                    audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true
                    }}
                    downloadFileExtension="mp3"
                    mediaRecorderOptions={{
                        audioBitsPerSecond: 128000
                    }}
                    recorderControls={recorderControls}
                />
            </div>
        </Tooltip>
    );
}

QuestionOuverteAudioRecorder.propTypes = {
    addAudioElement: PropTypes.func,
    totalText: PropTypes.string,
    setTotalText: PropTypes.func,

    transcript: PropTypes.string,
    listening: PropTypes.bool,
    saveAudioTextAnswer: PropTypes.func,
    isQuestionOuverteRecording: PropTypes.bool,
    setIsQuestionOuverteRecording: PropTypes.func
};
