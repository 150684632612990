import { Action, Reducer } from '@reduxjs/toolkit';

export const loadingReducer: Reducer<Record<string, boolean>, Action<string>> = (state = {}, action) => {
    const { type } = action;
    const matches = (/(.*)_(REQUEST|SUCCESS|FAILURE)/u).exec(type);

    // Not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if(!matches) {
        return state;
    }

    const [
        _completeName,
        requestName,
        requestState
    ] = matches;

    if(requestState === 'REQUEST') {
        state[requestName] = true;
    } else {
        delete state[requestName];
    }

    return { ...state };
};
