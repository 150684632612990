import { SET_PAGINATION_QUESTION } from '../../actions/Pagination/pagination.actions';

export const paginationQuestion = 'paginationQuestion';

export default function paginationQuestionReducer(state = {
    [paginationQuestion]: null
}, action) {
    switch (action.type) {
        case SET_PAGINATION_QUESTION:
            return {
                ...state,
                [paginationQuestion]: action.payload
            };
        default:
            return state;
    }
}
