import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import './CoachingMenu.scss';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import CoachingMenuSection from './CoachingMenuSection/CoachingMenuSection';
import { StudentListItem as Student } from './CoachingMenuListItems/StudentListItem/StudentListItem';
import { GroupListItem as Group } from './CoachingMenuListItems/GroupListItem/GroupListItem';
import { CoachListItem as Coach } from './CoachingMenuListItems/CoachListItem/CoachListItem';
import { CoachingRelationListItem as CoachingAskedRelation } from './CoachingMenuListItems/CoachingRelationListItem/CoachingRelationListItem';

import { Divider, Button } from 'antd';
import AddIcon from '@material-ui/icons/Add';
import {
    Grid, Paper, Badge, Tooltip, Fab, TextField,
    DialogTitle, DialogContent, Dialog, DialogActions, Button as ButtonMaterial
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import {
    getStudentsByCoachId, getStudentsAskingRelationByCoachId,
    patchAcceptCoachingAskedRelation, deleteDenyCoachingAskedRelation,
    getCoachesByStudentId, getAskedCoachesByStudentId
} from '../../redux/actions/Coaching/coaching.actions';
import {
    setSelectedGroup,
    getGroupsByCoachId,
    putGroup
} from '../../redux/actions/Group/group.actions';
import {
    selectUserStudents, selectStudentsAskingRelation,
    selectUserCoaches, selectUserAskedCoaches
} from '../../redux/selectors/Coaching/coaching.selector';
import { selectGroups } from '../../redux/selectors/Group/group.selector';

export default function CoachingMenu(props) {

    const dispatch = useDispatch();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const studentList = useSelector(selectUserStudents);
    const coachList = useSelector(selectUserCoaches);
    const studentsAskingRelationList = useSelector(selectStudentsAskingRelation);
    const userAskedCoachesList = useSelector(selectUserAskedCoaches);
    const groups = useSelector(selectGroups);

    const [displayActiveRelationsTab, setDisplayActiveRelationsTab] = useState('Actifs');
    const [filteredStudentList, setFilteredStudentList] = useState(studentList);
    const [filteredCoachList, setFilteredCoachList] = useState(coachList);
    const [filteredUserAskedCoachesList, setFilteredCoachRequestList] = useState(userAskedCoachesList);
    const [filteredStudentAskingCoachList, setFilteredStudentRequestingCoachList] = useState(studentsAskingRelationList);
    const [isPendingStudentRequestCollapsed, setPendingStudentCollapsed] = useState(false);
    const [isPendingCoachRequestCollapsed, setPendingCoachCollapsed] = useState(true);
    const [isActiveStudentCollapsed, setActiveStudentCollapsed] = useState(false);
    const [isActiveCoachCollapsed, setActiveCoachCollapsed] = useState(true);
    const [isGroupCollapsed, setGroupCollapsed] = useState(false);
    const [studentGroups, setStudentGroups] = useState(groups);
    const [isOpen, setIsOpen] = useState(false);

    const handleStudentAskingToBeCoached = (didAccept, studentId, studentFullName) => {
        if(didAccept) {
            dispatch(patchAcceptCoachingAskedRelation(getCurrentUserId(), studentId, studentFullName));
        } else {
            dispatch(deleteDenyCoachingAskedRelation(getCurrentUserId(), studentId));
        }
    };

    const displayStats = (student) => {
        props.onDisplayHistorySelected(student);
    };

    const groupSelected = (group) => {
        dispatch(setSelectedGroup(group));
    };

    const setTabToActifs = () => {
        setDisplayActiveRelationsTab('Actifs');
        props.setIsStudent(true);
    };

    const setTabToWaiting = () => {
        setDisplayActiveRelationsTab('Attentes');
        props.setIsStudent(true);
    };

    const setTabToGroupe = () => {
        setDisplayActiveRelationsTab('Groupes');
        props.setIsStudent(false);
    };

    const handleStudentListFilterChange = (newList) => {
        setFilteredStudentList(newList);
    };

    const handleCoachListFilterChange = (newList) => {
        setFilteredCoachList(newList);
    };

    const handleCoachRequestListFilterChange = (newList) => {
        setFilteredCoachRequestList(newList);
    };

    const handleStudentRequestingCoachListFilterChange = (newList) => {
        setFilteredStudentRequestingCoachList(newList);
    };

    const handleGroupRequestListFilterChange = (newList) => {
        setStudentGroups(newList);
    };

    const handleCollapsePendingStudent = (collapse) => {
        if(!collapse) {
            setPendingCoachCollapsed(true);
        }
        setPendingStudentCollapsed(collapse);
    };

    const handleCollapsePendingCoach = (collapse) => {
        if(!collapse) {
            setPendingStudentCollapsed(true);
        }
        setPendingCoachCollapsed(collapse);
    };

    const handleCollapseActiveStudent = (collapse) => {
        if(!collapse) {
            setActiveCoachCollapsed(true);
        }
        setActiveStudentCollapsed(collapse);
    };

    const handleCollapseActiveCoach = (collapse) => {
        if(!collapse) {
            setActiveStudentCollapsed(true);
        }
        setActiveCoachCollapsed(collapse);
    };

    const handleCollapseGroup = (collapse) => {
        setGroupCollapsed(collapse);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        dispatch(getStudentsByCoachId(getCurrentUserId()));
        dispatch(getStudentsAskingRelationByCoachId(getCurrentUserId()));
        dispatch(getCoachesByStudentId(getCurrentUserId()));
        dispatch(getAskedCoachesByStudentId(getCurrentUserId()));
        dispatch(getGroupsByCoachId(getCurrentUserId()));
        if(props.groupId) {
            props.setIsStudent(false);
            setTabToGroupe();
            groupSelected(studentGroups.find((group) => group.id === props.groupId));
        }
    }, []);

    useEffect(() => {
        setStudentGroups(groups);
    }, [groups]);

    useEffect(() => {
        setFilteredStudentList(studentList);
    }, [studentList]);

    useEffect(() => {
        setFilteredCoachList(coachList);
    }, [coachList]);

    useEffect(() => {
        setFilteredCoachRequestList(userAskedCoachesList);
    }, [userAskedCoachesList]);

    useEffect(() => {
        setFilteredStudentRequestingCoachList(studentsAskingRelationList);
    }, [studentsAskingRelationList]);

    const renderActiveRelationsTab = () => <div>
        <CoachingMenuSection id="studentsSection"
            data={studentList}
            onFilterChange={handleStudentListFilterChange}
            onCollapse={handleCollapseActiveStudent}
            collapsed={isActiveStudentCollapsed}
            title="Mes Élèves"
            emptyDataMessage="Vous n'avez actuellement aucun élève">
            {filteredStudentList && filteredStudentList.map((student) => <Student
                key={student.id}
                student={student}
                onDisplayStats={displayStats} />)
            }
        </CoachingMenuSection>
        <Divider className="dividers" hidden={!(isActiveStudentCollapsed && isActiveCoachCollapsed)} />
        <CoachingMenuSection id="coachsSection"
            data={coachList}
            onFilterChange={handleCoachListFilterChange}
            onCollapse={handleCollapseActiveCoach}
            collapsed={isActiveCoachCollapsed}
            title="Mes Coachs"
            emptyDataMessage="Vous n'avez actuellement aucun coach">
            {filteredCoachList && filteredCoachList.map((coach) => <Coach
                key={coach.id}
                coach={coach} />)
            }
        </CoachingMenuSection>
    </div>;

    const renderPendingRelationsTab = () => <div>
        <CoachingMenuSection id="pendingStudentsSection"
            data={studentsAskingRelationList}
            onFilterChange={handleStudentRequestingCoachListFilterChange}
            onCollapse={handleCollapsePendingStudent}
            collapsed={isPendingStudentRequestCollapsed}
            title="Devenir Coach"
            emptyDataMessage="Aucune demande en cours">
            {filteredStudentAskingCoachList && filteredStudentAskingCoachList.map((student) => <CoachingAskedRelation
                key={student.id}
                student={student}
                isAskingToBeCoached={true}
                handleCoachingRequest={handleStudentAskingToBeCoached} />)
            }
        </CoachingMenuSection>
        <Divider className="dividers" hidden={!(isPendingStudentRequestCollapsed && isPendingCoachRequestCollapsed)} />
        <CoachingMenuSection id="pendingCoachsSection"
            data={userAskedCoachesList}
            onFilterChange={handleCoachRequestListFilterChange}
            onCollapse={handleCollapsePendingCoach}
            collapsed={isPendingCoachRequestCollapsed}
            title="Devenir Élève"
            emptyDataMessage="Aucune demande en cours">
            {filteredUserAskedCoachesList && filteredUserAskedCoachesList.map((coach) => <CoachingAskedRelation
                key={coach.id}
                student={coach}
                isAskingToBeCoached={false} />)
            }
        </CoachingMenuSection>
    </div>;

    const renderGroupRelationsTab = () => <div>
        <CoachingMenuSection id="groupesSection"
            data={groups}
            title="Mes Groupes"
            onFilterChange={handleGroupRequestListFilterChange}
            onCollapse={handleCollapseGroup}
            collapsed={isGroupCollapsed}
            emptyDataMessage="Vous n'avez actuellement aucun groupe"
        >
            {studentGroups && studentGroups.map((groupe) => <Group
                key={groupe.id}
                groupe={groupe}
                groupUser={groupSelected} />)
            }
        </CoachingMenuSection>
        <Tooltip title="Ajouter un groupe">
            <Fab
                size="small"
                color="primary"
                aria-label="add"
            >
                <AddIcon onClick={handleOpen} />
            </Fab>
        </Tooltip>
        <AddToGroupDialog
            isOpen={isOpen}
            onClose={handleClose}
            listGroup={studentGroups}
        />
    </div>;

    return (
        <>
            <Grid container spacing={2} className="relationsNav">
                <Button id="activeRelationsTabButton" className={displayActiveRelationsTab === 'Actifs' ? 'selectedCoachingNavButton' : ''}
                    onClick={setTabToActifs}>
                    Actifs
                </Button>
                <Badge badgeContent={studentsAskingRelationList ? studentsAskingRelationList.length : 0} color="secondary" overlap="rectangular">
                    <Button id="pendingRelationsTabButton" className={displayActiveRelationsTab === 'Attentes' ? 'selectedCoachingNavButton' : ''}
                        onClick={setTabToWaiting}>
                        En attente
                    </Button>
                </Badge>
                <Button id="groupeRelationsTabButton" className={displayActiveRelationsTab === 'Groupes' ? 'selectedCoachingNavButton' : ''}
                    onClick={setTabToGroupe}>
                    Groupes
                </Button>
            </Grid>
            <Paper className="categoriesPaperCard">
                {displayActiveRelationsTab === 'Actifs'
                    ? renderActiveRelationsTab()
                    : displayActiveRelationsTab === 'Attentes'
                        ? renderPendingRelationsTab()
                        : renderGroupRelationsTab()
                }
            </Paper>
        </>
    );
}

CoachingMenu.propTypes = {
    groupId: PropTypes.number,
    onDisplayHistorySelected: PropTypes.func.isRequired,
    setIsStudent: PropTypes.func.isRequired
};

function AddToGroupDialog(props) {

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const [groupName, setGroupName] = useState('');
    const [enableError, setEnableError] = useState(false);
    const dispatch = useDispatch();

    const isValidGroupName = useMemo(
        () => !props.listGroup.some((element) => element.name === groupName) && groupName.trim() !== ''
        , [props.listGroup, groupName]
    );

    const handleOnClick = () => {
        setEnableError(false);
        if(isValidGroupName) {
            const groupCreated = {
                id: '',
                name: groupName.trim()
            };
            dispatch(putGroup(groupCreated, getCurrentUserId()));
            props.onClose();
        } else {
            setEnableError(true);
        }
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
        >
            <DialogTitle>
                Créer un groupe
            </DialogTitle>
            <DialogContent dividers id = "addToGroupDialog">
                <TextField
                    label="Nom du groupe"
                    autoFocus
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    onChange = {(el) => setGroupName(el.target.value)}
                    error = { enableError && !isValidGroupName }
                    helperText= {
                        enableError ? isValidGroupName ? ' ' : 'Nom de groupe invalide' : ' '
                    }
                />
            </DialogContent>
            <DialogActions>
                <ButtonMaterial
                    color="primary"
                    onClick={props.onClose}
                >
                    Annuler
                </ButtonMaterial>
                <ButtonMaterial
                    color="primary"
                    onClick={handleOnClick}
                >
                    Valider
                </ButtonMaterial>
            </DialogActions>
        </Dialog>
    );
}

AddToGroupDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    listGroup: PropTypes.array.isRequired
};
