import {
    GET_TASKS_BY_STUDENT_ID_SUCCESS,
    GET_TASKS_BY_GROUP_ID_SUCCESS,
    GET_TASKS_DETAILS_BY_GROUP_ID_SUCCESS,
    PATCH_TASK_SUCCESS
} from '../../actions/Coaching/Tasks/Task.action';

export const studentTaskListStore = 'studentTaskListStore';
export const nonTreatedGroupTaskList = 'nonTreatedGroupTaskList';
export const allGroupTaskList = 'allGroupTaskList';
export const groupTaskListStoreDetails = 'groupTaskListStoreDetails';

export default function taskReducer(state = {
    [studentTaskListStore]: [],
    [nonTreatedGroupTaskList]: [],
    [allGroupTaskList]: [],
    [groupTaskListStoreDetails]: []
}, action) {
    switch (action.type) {
        case GET_TASKS_BY_STUDENT_ID_SUCCESS:
            return {
                ...state,
                [studentTaskListStore]: action.payload
            };
        case GET_TASKS_BY_GROUP_ID_SUCCESS:
            if(action.payload.treated) {
                return {
                    ...state,
                    [allGroupTaskList]: action.payload.data
                };
            }
            return {
                ...state,
                [nonTreatedGroupTaskList]: action.payload.data
            };

        case GET_TASKS_DETAILS_BY_GROUP_ID_SUCCESS:
            return {
                ...state,
                [groupTaskListStoreDetails]: action.payload
            };
        case PATCH_TASK_SUCCESS:
            const studentTaskList = state[studentTaskListStore].filter((studentTask) => studentTask.id !== action.payload);
            return {
                ...state,
                [studentTaskListStore]: studentTaskList
            };
        default:
            return state;
    }
}
