/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { Plugin } from 'ckeditor5/src/core';
import { viewToModelPositionOutsideModelElement, toWidget, Widget } from 'ckeditor5/src/widget';
import { UpcastWriter } from 'ckeditor5/src/engine';
import TexteatrouCommand from './texteatroucommand';

import '../../theme/texteatrou.css';
import CustomModelMatcher, { texteATrouPattern } from './CustomModelMatcher';
import MnfEmitter from './MnfEmitter';

export default class TexteatrouEditing extends Plugin {
	constructor( props ) {
		super( props );

		this.emitterForm = new MnfEmitter();
		this.emitterCkeditor = new MnfEmitter();

		this.copiedTrouElementList = [];
		this.draggedTrouElementList = [];

		this._setupAnswers = this._setupAnswers.bind( this );
		this._delete = this._delete.bind( this );
		this._ajout = this._ajout.bind( this );
		this._externalDeletion = this._externalDeletion.bind( this );
		this._modification = this._modification.bind( this );
		this._refresh = this._refresh.bind( this );
		this._updateTrousOrdinal = this._updateTrousOrdinal.bind( this );
		this._findTexteATrouElementsInModel = this._findTexteATrouElementsInModel.bind( this );
		this._getTexteATrouSelected = this._getTexteATrouSelected.bind( this );
		this._cut = this._cut.bind( this );
		this._copy = this._copy.bind( this );
		this._paste = this._paste.bind( this );
		this._dragstart = this._dragstart.bind( this );
		this._drop = this._drop.bind( this );
	}

	static get requires() {
		return [ Widget ];
	}
	afterInit() {
		this.emitterForm.listenTo( this.emitterCkeditor, 'ajout', this._ajout );
		this.emitterForm.listenTo( this.emitterCkeditor, 'refresh', this._refresh );

		this.emitterCkeditor.listenTo( this.emitterForm, 'suppression', this._externalDeletion );
		this.emitterCkeditor.listenTo( this.emitterForm, 'modification', this._modification );
		this.emitterCkeditor.listenTo( this.emitterForm, 'setupAnswers', this._setupAnswers );

		// When editor fire suppression, then emitterForm fire suppression
		this.editor.delegate( 'suppression', 'modification', 'setupAnswers' ).to( this.emitterForm );
		this.editor.delegate( 'refresh' ).to( this.emitterCkeditor );
	}
	init() {
		this._defineSchema();
		this._defineConverters();

		this.editor.commands.add( 'texteatrou', new TexteatrouCommand( this.editor ) );

		this.editor.editing.mapper.on(
			'viewToModelPosition',
			viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'texteatrou' ) )
		);

		this.editor.commands.get( 'delete' ).on( 'execute', this._delete, { priority: 'high' } );
		this.editor.commands.get( 'deleteForward' ).on( 'execute', this._delete, { priority: 'high' } );
		this.editor.commands.get( 'enter' ).on( 'execute', this._delete, { priority: 'high' } );
		this.editor.commands.get( 'shiftEnter' ).on( 'execute', this._delete, { priority: 'high' } );
		this.editor.commands.get( 'insertText' ).on( 'execute', this._delete, { priority: 'high' } );
		this.editor.commands.get( 'input' ).on( 'execute', this._delete, { priority: 'high' } );
		this.editor.commands.get( 'undo' ).on( 'execute', () => this._executeRefreshCallback( ), { priority: 'low' } );
		this.editor.commands.get( 'redo' ).on( 'execute', () => this._executeRefreshCallback( ), { priority: 'low' } );

		this.editor.editing.view.document.on( 'copy', this._copy, { priority: 'high' } );
		this.editor.editing.view.document.on( 'cut', this._cut, { priority: 'high' } );
		this.editor.editing.view.document.on( 'dragstart', this._dragstart, { priority: 'high' } );

		this.editor.editing.view.document.on( 'paste', this._delete, { priority: 'high' } );
		this.editor.editing.view.document.on( 'paste', this._paste, { priority: 'low' } );
		this.editor.editing.view.document.on( 'drop', this._drop, { priority: 'low' } );

		// OVERRIDE listener in CODEBLOCK Feature
		// https://github.com/ckeditor/ckeditor5/blob/master/packages/ckeditor5-code-block/src/codeblockediting.ts#L177
		this.editor.plugins.get( 'ClipboardPipeline' ).on( 'inputTransformation', ( evt, data ) => {
			let insertionRange = this.editor.model.createRange( this.editor.model.document.selection.anchor );

			// Use target ranges in case this is a drop.
			if ( data.targetRanges ) {
				insertionRange = this.editor.editing.mapper.toModelRange( data.targetRanges[ 0 ] );
			}

			if ( !insertionRange.start.parent.is( 'element', 'codeBlock' ) ) {
				return;
			}

			const htmlData = data.dataTransfer.getData( 'text/html' );
			const htmlProcessor = this.editor.data.htmlProcessor;
			const newContent = htmlProcessor.toView( htmlData );

			const writer = new UpcastWriter( this.editor.editing.view.document );
			const fragment = writer.createDocumentFragment();
			const nodes = [];
			rawSnippetTextWithTexteATrouToViewDocumentFragment( writer, newContent, nodes );
			writer.appendChild( nodes, fragment );
			data.content = fragment;
			// this.emitterCkeditor.fire( 'refresh' );
		}, { priority: 'high' } );

		// OVERRIDE util function in CODEBLOCK Feature
		// https://github.com/ckeditor/ckeditor5/blob/master/packages/ckeditor5-code-block/src/utils.ts#L119
		function rawSnippetTextWithTexteATrouToViewDocumentFragment( writer, node, nodes ) {
			if ( node.name === 'span' && node.getAttribute( 'class' ) === 'texteatrou' ) {
				const texteatrouTextNode = node.getChild( 0 );
				let answer = '';
				if ( texteatrouTextNode && texteatrouTextNode.data ) {
					answer = texteatrouTextNode.data;
				}
				const idViewElement = node.getAttribute( 'id' );
				const ordinal = idViewElement.split( 'trou_' )[ 1 ];
				nodes.push( writer.createElement(
					'span',
					{ class: 'texteatrou', ordinal: ordinal ? ordinal : 0 },
					[ writer.createText( answer ) ]
				) );
				return;
			} else if ( node.data ) {
				nodes.push( writer.createText( node.data ) );
			}

			if ( node.getChildren ) {
				const children = node.getChildren();
				for ( const child of children ) {
					rawSnippetTextWithTexteATrouToViewDocumentFragment( writer, child, nodes );
				}
			}
		}
	}
	_getTexteATrouSelected() {
		const texteATrouMatcher = new CustomModelMatcher( texteATrouPattern );
		const range = this.editor.model.document.selection.getFirstRange();
		const rangeItems = Array.from( range.getItems() );
		return texteATrouMatcher.match( ...rangeItems );
	}
	_copy() {
		this.copiedTrouElementList = this._getTexteATrouSelected().map( matchedElem => matchedElem.element );
	}
	_cut() {
		this.copiedTrouElementList = this._getTexteATrouSelected().map( matchedElem => matchedElem.element );
		this._delete();
	}
	_paste() {
		if ( this.copiedTrouElementList.length ) {
			this._copyTrousAcceptedAnswersFromList( this.copiedTrouElementList );
			this._refresh();
		}
	}
	_dragstart() {
		this.draggedTrouElementList = this._getTexteATrouSelected().map( matchedElem => matchedElem.element );
	}
	_ajout() {
		this._refresh();
	}
	_drop() {
		if ( this.draggedTrouElementList.length ) {
			this._copyTrousAcceptedAnswersFromList( this.draggedTrouElementList );
			this._refresh();
			this.draggedTrouElementList = [];
		}
	}
	_delete( evt, value ) {
		const texteATrousSelected = this._getTexteATrouSelected();
		if ( texteATrousSelected.length ) {
			const newTrouList = this._updateTrousOrdinalAfterDelete(
				texteATrousSelected.map( trouMatched =>
					trouMatched.element.getAttribute( 'ordinal' )
				)
			);
			this._executeRefreshCallback( newTrouList );
		}
	}
	_refresh() {
		this._updateTrousOrdinal();
		this._executeRefreshCallback();
	}
	_executeRefreshCallback( trouList ) {
		const textTrouConfig = this.editor.config.get( 'textATrou' );
		const refreshCallback = textTrouConfig ? textTrouConfig.refreshCallback : null;
		if ( refreshCallback ) {
			refreshCallback( trouList ?? this._findTexteATrouElementsInModel() );
		} else {
			console.log( 'ERROR: Refresh is undefined' );
		}
	}
	_setupAnswers( _event, answerList ) {
		if ( !answerList ) {
			return;
		}
		const texteATrouList = this._findTexteATrouElementsInModel();
		if ( !texteATrouList ) {
			return; // Should never happen
		}
		texteATrouList.forEach( trouElement => {
			const answer = answerList.find( answer => answer.ordinal == trouElement.getAttribute( 'ordinal' ) );
			if ( !answer?.acceptedAnswers ) {
				return;
			}
			this._changeAcceptedAnswer( trouElement, answer.acceptedAnswers );
		} );
	}
	_findTexteATrouElementsInModel() {
		const rootDocument = this.editor.editing.model.document.getRoot();
		const texteATrouMatcher = new CustomModelMatcher( texteATrouPattern );
		return texteATrouMatcher.match( rootDocument )
			.map( matchedElem => matchedElem.element );
	}
	_changeOrdinal( trouElement, newOrdinal ) {
		this.editor.editing.model.change( writer => {
			writer.setAttribute( 'ordinal', Number( newOrdinal ), trouElement );
		} );
		this.editor.editing.reconvertItem( trouElement );
	}
	_changeAcceptedAnswer( trouElement, newAcceptedAnswers ) {
		this.editor.editing.model.change( writer => {
			writer.setAttribute( 'acceptedAnswers', newAcceptedAnswers.slice(), trouElement );
			this.editor.editing.reconvertItem( trouElement );
		} );
	}
	_updateTrousOrdinal() {
		let ordinal = 1;
		this._findTexteATrouElementsInModel().forEach( trouElement => this._changeOrdinal( trouElement, ordinal++ ) );
	}
	_updateTrousOrdinalAfterDelete( ordinalList ) {
		let ordinal = 1;
		const currentTrouList = this._findTexteATrouElementsInModel();
		const newTrouList = currentTrouList.filter( trouElement => ordinalList.indexOf( trouElement.getAttribute( 'ordinal' ) ) === -1 );
		newTrouList.forEach( trouElement => this._changeOrdinal( trouElement, ordinal++ ) );
		return newTrouList;
	}
	_copyTrousAcceptedAnswersFromList( trouElementList ) {
		const currentTrouList = this._findTexteATrouElementsInModel();
		for ( const backupTrouElement of trouElementList ) {
			const target = currentTrouList.find( trouElement =>
				( trouElement.getAttribute( 'ordinal' ) == backupTrouElement.getAttribute( 'ordinal' ) &&
				trouElement !== backupTrouElement || trouElement.getAttribute( 'ordinal' ) == 0 )
			);
			if ( target ) {
				this._changeAcceptedAnswer( target, backupTrouElement.getAttribute( 'acceptedAnswers' ) );
			}
		}
		return currentTrouList;
	}
	_modification( _eventInfo, { ordinal, newAcceptedAnswers } ) {
		const trouToUpdate = this._findTexteATrouElementsInModel().find( elem => elem.getAttribute( 'ordinal' ) == ordinal );
		this.editor.editing.model.change( writer => {
			writer.setAttribute( 'acceptedAnswers', newAcceptedAnswers.slice(), trouToUpdate );
		} );
		this._refresh();
	}
	_externalDeletion( _eventInfo, { ordinal } ) {
		const trouToDelete = this._findTexteATrouElementsInModel()
			.find( trouElement => trouElement.getAttribute( 'ordinal' ) === ordinal );
		if ( !trouToDelete ) {
			console.log( 'ERROR: no element corresponding to ordinal ', ordinal );
			return;
		}
		this.editor.editing.model.change( writer => {
			writer.remove( trouToDelete );
		} );
	}
	_defineSchema() {
		const schema = this.editor.model.schema;

		schema.register( 'texteatrou', {
			// Behaves like a self-contained inline object (e.g. an inline image)
			// allowed in places where $text is allowed (e.g. in paragraphs).
			// The inline widget can have the same attributes as text (for example linkHref, bold).
			inheritAllFrom: '$inlineObject',

			allowIn: 'codeBlock',

			// The texteatrou can have many types, like date, answer, surname, etc:
			allowAttributes: [ 'ordinal', 'acceptedAnswers' ]
		} );

		schema.addChildCheck( ( context, childDefinition ) => {
			// Note that the context is automatically normalized to a SchemaContext instance and
			// the child to its definition (SchemaCompiledItemDefinition).

			// If checkChild() is called with a context that ends with blockQuote and blockQuote as a child
			// to check, make the checkChild() method return false.
			if ( context.endsWith( 'codeBlock' ) && childDefinition.name == 'texteatrou' ) {
				return true;
			}
		} );
	}
	_defineConverters() {
		const conversion = this.editor.conversion;

		conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: 'span',
				classes: [ 'texteatrou' ]
			},
			model: ( viewElement, { writer: modelWriter } ) => {
				const acceptedAnswers = [ viewElement.getChild( 0 ).data.slice() ];
				// Expected id format : id="trou_[0-9]*"
				const idViewElement = viewElement.getAttribute( 'id' );
				const ordinal = idViewElement ?
					idViewElement.split( 'trou_' )[ 1 ] :
					0;
				return modelWriter.createElement( 'texteatrou', { ordinal, acceptedAnswers } );
			}
		} );

		conversion.for( 'editingDowncast' ).elementToElement( {
			model: 'texteatrou',
			view: ( modelItem, { writer: viewWriter } ) => {
				const widgetElement = createTexteATrouBlockView( modelItem, viewWriter );
				// Enable widget handling on a texteatrou element inside the editing view.
				return toWidget( widgetElement, viewWriter );
			}
		} );

		conversion.for( 'dataDowncast' ).elementToElement( {
			model: 'texteatrou',
			view: ( modelItem, { writer: viewWriter } ) => createTexteATrouBlockView( modelItem, viewWriter )
		} );

		// Helper method for both downcast converters.
		function createTexteATrouBlockView( modelItem, viewWriter ) {
			const acceptedAnswers = modelItem.getAttribute( 'acceptedAnswers' );

			const texteatrouView = viewWriter.createContainerElement( 'span', {
				class: 'texteatrou',
				id: 'trou_'.concat( modelItem.getAttribute( 'ordinal' ) ?? 0 )
			} );

			// Insert the texteatrou acceptedAnswers (as a text).
			const firstAcceptedAnswers = acceptedAnswers[ 0 ] ? acceptedAnswers[ 0 ] : '';
			const innerText = viewWriter.createText( firstAcceptedAnswers );
			viewWriter.insert( viewWriter.createPositionAt( texteatrouView, 0 ), innerText );

			return texteatrouView;
		}
	}
}
