/* eslint-disable complexity */
import React, { MouseEvent, useEffect, useMemo, useRef } from 'react';

import QuestionRequestByChapterId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByChapterId';
import { AssignTypes } from 'app/utils/functions';
import { useDrag, useDrop } from 'react-dnd';

import { TreeItem } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { updateChapter } from 'app/redux/actions/Chapter/chapter.actions';
import { replaceChapterOfQuestion } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQSet/MCQSet.actions';

import { canDropInside, canDropAfter, useStyles } from './ChapterTreeDisplay.functions';
import { TreeItemLabel } from './TreeItemLabel';
import { debounce } from 'lodash';
import TreeItemPreview from './TreeItemPreview';
import { useHistory } from 'react-router-dom';
import { SerieRequestByChapterId } from 'app/serie/SerieRequestByChapterId';
import { chapterActions } from 'app/redux/slices/chapters.slice';
import { SkillReduced } from 'app/redux/models/Skill.model';
import { Chapter } from 'app/redux/models/Chapter.model';
import TreeNode from 'app/utils/treeStructure/TreeNode';
import { SKILL_PAGE_URL } from 'app/Routes';
import { selectChapterQuestionFilter, selectQuestionCountByChapter } from 'app/redux/selectors/Chapter/chapter.selector';

type DropItem = {
    type: AssignTypes.CHAPTER;
    chapter: Chapter;
} | {
    type: AssignTypes.QUESTION;
    chapterId: number;
    question: any;
}

interface ChapterTreeItemProps {
    node: TreeNode;
    skill: SkillReduced;
    userId: number;
    depth: number;
    permission: boolean;
    chaptersValidation: any[];
    onClickQuestion: () => void;
    handleContextMenu: (event: MouseEvent<Element>, node: TreeNode) => void;
    setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
    selectedQuestion: number | null;
    selectedGroup: any;
    pathToBookmark: string;
    bookmark: any;
    isInDrawer?: boolean;
}

export const ChapterTreeItem = (props: ChapterTreeItemProps) => {

    const { node } = props;

    const history = useHistory();

    const classes = useStyles();
    const dispatch = useDispatch();

    const ref = useRef(null);

    const filter = useSelector(selectChapterQuestionFilter);
    const { data: questionCountByChapter } = useSelector(selectQuestionCountByChapter);

    const questionCount = useMemo(
        () => questionCountByChapter.get(node.content.id) ?? 0,
        [questionCountByChapter, node.content.id]
    );

    const isBookmarked = props.pathToBookmark?.startsWith(`/${props.node.content.name.toLowerCase()}/`) || props.bookmark?.chapterId === props.node.content.id;
    let pathChildBookmark = '';

    if(isBookmarked) {
        pathChildBookmark = props.pathToBookmark?.substring(1 + props.node.content.name.length);
    }

    const debouncedToggleExpanded = useRef(debounce((id: number) => {
        props.setExpanded((expanded) => {
            if(expanded.includes(String(id))) {
                return expanded.filter((expandedId) => expandedId !== String(id));
            }
            return [String(id), ...expanded];
        });
    }, 500));

    const handleSelectChapter = () => {
        const path = `${SKILL_PAGE_URL}/${props.skill.name}`;
        if(props.isInDrawer && history.location.pathname !== path) {
            history.push(path);
        }
    };

    const validationState = props.chaptersValidation.find((chapterValidation) => (
        chapterValidation.chapterId === node.content.id
    ))?.chapterValidationState;

    const dropNextToChapter = (itemChapter: Chapter) => {
        const updatedChapter = {
            ...itemChapter,
            parentPath: node.parent?.key,
            position: node.content.position + 1
        };
        dispatch(updateChapter(updatedChapter));
    };

    const dropInsideChapter = (itemChapter: Chapter) => {
        const updatedChapter = {
            ...itemChapter,
            parentPath: node.key,
            position: 1
        };
        dispatch(updateChapter(updatedChapter));
    };

    const [{ isDragging, dragItem }, drag] = useDrag(() => ({
        type: AssignTypes.CHAPTER,
        item: {
            type: AssignTypes.CHAPTER,
            chapter: node.content
        },
        isDragging: (monitor) => monitor.getItem().chapter.id === node.content.id,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            dragItem: monitor.getItem()
        })
    }), [node]);

    // NE SURTOUT JAMAIS INTERCHANGER L'ORDRE DE DÉCLARATION DE CES DEUX FONCTIONS
    const [{ isNextToCurrent, canDropNext }, dropAfter] = useDrop<DropItem, void, any>(() => ({
        accept: AssignTypes.CHAPTER,
        drop(item, monitor) {
            if(!monitor.didDrop() && monitor.canDrop() && item.type === AssignTypes.CHAPTER) {
                dropNextToChapter(item.chapter);
            }
        },
        canDrop: (item) => props.permission && canDropAfter(item, node),
        collect: (monitor) => ({
            isNextToCurrent: monitor.isOver({ shallow: true }),
            canDropNext: monitor.canDrop()
        })
    }), [node]);

    const [{ isInsideCurrent, canDropIn }, dropIn] = useDrop<DropItem, Promise<void>, any>(() => ({
        accept: [AssignTypes.CHAPTER, AssignTypes.QUESTION],
        async drop(item, monitor) {
            if(!monitor.didDrop() && monitor.canDrop()) {
                if(item.type === AssignTypes.CHAPTER) {
                    dropInsideChapter(item.chapter);
                } else if(item.type === AssignTypes.QUESTION) {
                    const oldChapterId = item.chapterId;
                    const newChapterId = node.content.id;

                    await dispatch(replaceChapterOfQuestion(item.question, oldChapterId, newChapterId));
                    dispatch(chapterActions.getQuestionCountByChapterIds({ chapterIds: [oldChapterId, newChapterId], search: filter }));
                }
            }
        },
        canDrop: (item) => props.permission && canDropInside(item, node),
        collect: (monitor) => ({
            isInsideCurrent: monitor.isOver({ shallow: true }),
            canDropIn: monitor.canDrop()
        })
    }), [node]);

    // Supprimer cette fonction si l'expand automatique gêne
    useEffect(() => {
        if(isInsideCurrent) {
            debouncedToggleExpanded.current(node.content.id);
        } else {
            debouncedToggleExpanded.current.cancel?.();
        }
    }, [isInsideCurrent]);

    const handleContextMenu = (event: MouseEvent<Element>) => {
        props.handleContextMenu(event, node);
    };

    dropIn(drag(ref));

    if(filter !== '' && questionCount === 0) {
        return null;
    }

    return (
        <>
            <TreeItem
                classes={{
                    label: classes.label,
                    iconContainer: classes.iconContainer
                }}
                style={{
                    textDecoration: 'none',
                    opacity: isDragging ? 0.5 : 1
                }}
                id={`Chapitre ${node.content.id}`}
                key={node.content.id}
                nodeId={String(node.content.id)}
                cy-data="skill-chapter-item"
                label={
                    <div id="test" onClick={handleSelectChapter}>
                        <TreeItemLabel
                            chapter={node.content}
                            chapterValidationState={validationState}
                            isInsideCurrent={isInsideCurrent && canDropIn}
                            permission={props.permission}
                            depth={props.depth}
                            handleContextMenu={handleContextMenu}
                            drag={ref}
                            selectedGroup={props.selectedGroup}
                            bookmark={props.bookmark}
                            isBookmarked={isBookmarked}
                            skillId={props.skill.id}
                            userId={props.userId}
                            isInDrawer={props.isInDrawer}
                            questionCount={questionCount}
                        />
                    </div>
                }
            >

                <TreeItem
                    nodeId={`Series${node.content.id}`}
                    label="Séries"
                >
                    <SerieRequestByChapterId
                        node={node}
                        chapterId={node.content.id}
                        onClickQuestion={props.onClickQuestion}
                        selectedQuestion={props.selectedQuestion}
                    />
                </TreeItem>
                {props.onClickQuestion && (!isInsideCurrent || dragItem?.type === AssignTypes.QUESTION) &&
                    <TreeItem
                        nodeId={`Questions${node.content.id}`}
                        label="Questions"
                    >
                        <QuestionRequestByChapterId
                            chapterId={node.content.id}
                            skillName={props.skill.name}
                            onClickQuestion={props.onClickQuestion}
                            selectedQuestion={props.selectedQuestion}
                        />
                    </TreeItem>
                }
                {canDropIn && isInsideCurrent && dragItem?.type === AssignTypes.CHAPTER &&
                    <div data-testid={`chapter-${dragItem.chapter.id}-in-preview`}>
                        <TreeItemPreview
                            chapter={dragItem.chapter}
                            depth={props.depth + 1}
                            validationState={validationState}
                            userId={props.userId}
                            skillId={props.skill.id}
                            bookmark={props.bookmark}
                            isBookmarked={isBookmarked}
                        />
                    </div>
                }
                {node.children.map((child) => (
                    <ChapterTreeItem
                        {...props}
                        key={child.content.id}
                        node={child}
                        depth={props.depth + 1}
                        pathToBookmark={pathChildBookmark ?? ''}
                    />
                ))}
            </TreeItem>
            {dragItem && (isNextToCurrent || isInsideCurrent) && canDropNext &&
                <div ref={dropAfter} style={{
                    border: 'dashed red',
                    borderRadius: '5px'
                }}>
                    <div
                        style={{
                            display: isNextToCurrent ? 'block' : 'none',
                            margin: '-3px'
                        }}
                        data-testid={`chapter-${dragItem.chapter.id}-next-preview`}
                    >
                        <TreeItemPreview
                            chapter={dragItem.chapter}
                            depth={props.depth}
                            validationState={validationState}
                            userId={props.userId}
                            skillId={props.skill.id}
                            bookmark={props.bookmark}
                            isBookmarked={isBookmarked}
                        />
                    </div>
                    <div
                        style={{
                            display: isNextToCurrent ? 'none' : 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        data-testid="drop-zone"
                    >
                        Ajouter après
                    </div>
                </div>
            }
        </>
    );
};
