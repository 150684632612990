import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DrawerDailyHistoryDisplay from './DrawerDailyHistoryDisplay';
import { sortHistoryByDate } from './DrawerHistoryDisplay.function';


function DrawerHistoryDisplay(props) {

    const [sortedHistory, setSortedHistory] = useState(new Map());

    useEffect(() => {
        if(props.studentData) {
            setSortedHistory(sortHistoryByDate(props.studentData));
        }
    }, [props.studentData]);

    useEffect(() => {
        if(props.groupData) {
            setSortedHistory(sortHistoryByDate(props.groupData));
        }
    }, [props.groupData]);

    return (
        <>
            {Array.from(sortedHistory?.entries()).map((dateHistory) => <DrawerDailyHistoryDisplay
                badAnswerFilter={props.badAnswerFilter}
                key={dateHistory[0]}
                dateHistory={dateHistory}
                isGroup={props.isGroup}
                onClickQuestion={props.onClickQuestion}
            />)
            }
        </>
    );
}

DrawerHistoryDisplay.propTypes = {
    badAnswerFilter: PropTypes.bool,
    groupData: PropTypes.arrayOf(PropTypes.object),
    isGroup: PropTypes.bool.isRequired,
    studentData: PropTypes.arrayOf(PropTypes.object),
    onClickQuestion: PropTypes.func.isRequired
};

export default DrawerHistoryDisplay;
