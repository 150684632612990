import leaflet from 'leaflet';
import image from './client_location_icon_2.svg';

export const ClientsMapMarkerIcon = leaflet.icon({
    iconUrl: image,
    iconRetinaUrl: image,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    html: 'yeah yeah',
    iconSize: [
        50,
        50
    ],
    className: 'leaflet-icon'
});
