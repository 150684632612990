import {
    seriesToDisplayStore,
    serieToResolve,
    serieWithQuestionReducedStore,
    serieModificationSavedStore,
    seriePageParams,
    questionListToResolve,
    questionIndexToResolve,
    isExamMode
} from 'app/redux/reducers/Question/serie.reducer';
import { seriesStore } from 'app/redux/rootReducer';

export function selectSeriesToDisplay(state) {
    return state[seriesStore][seriesToDisplayStore];
}

export function selectSerieToResolve(state) {
    return state[seriesStore][serieToResolve];
}

export function selectQuestionListToResolve(state) {
    return state[seriesStore][questionListToResolve];
}

export function selectQuestionIndexToResolve(state) {
    return state[seriesStore][questionIndexToResolve];
}

export function selectSerieWithQuestionReduced(state) {
    return state[seriesStore][serieWithQuestionReducedStore];
}

export function selectSerieModificationIsSaved(state) {
    return state[seriesStore][serieModificationSavedStore];
}

export function selectSeriePageParams(state) {
    return state[seriesStore][seriePageParams];
}

export function selectIsExamMode(state) {
    return state[seriesStore][isExamMode];
}
