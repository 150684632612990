

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

export function ModeratorLoader({
    loaderWidth = 1366,
    heading = {
        width: 0,
        height: -20
    },
    itemHeight = 50,
    row = 2,
    column = 4,
    padding = 15,
    borderRadius = 4,
    ...props
}) {
    const list = [];

    let index = 0;

    const itemWidth = (loaderWidth - padding * (column + 1)) / column;
    const verticalSpace = padding * 2 + itemHeight;

    for(let ii = 1; ii <= row; ii++) {

        const corY = heading.height + padding * 2 + verticalSpace * (ii - 1);

        for(let jj = 0; jj < column; jj++) {
            index += 1;

            const corX = padding + jj * (itemWidth + padding);

            list.push(<Fragment key={index}>
                <rect x={corX} y={corY} rx={borderRadius} ry={borderRadius} width={itemWidth} height={itemHeight}/>
            </Fragment>);

        }
    }

    return (
        <ContentLoader
            width={loaderWidth}
            height={verticalSpace * 2}
            speed={2}
            primaryColor="#d9d3d3"
            secondaryColor="#eae6e6"
            style={{
                flex: 1
            }}
            ariaLabel={false}
            data-testid="content-loader"
            {...props}
        >
            {list}
        </ContentLoader>
    );
}

ModeratorLoader.propTypes = {
    loaderWidth: PropTypes.string,
    itemHeight: PropTypes.number,
    heading: PropTypes.object,
    row: PropTypes.number,
    column: PropTypes.number,
    padding: PropTypes.number,
    borderRadius: PropTypes.number
};

export default ModeratorLoader;
