import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducer, selectAllExcilyenSet } from '../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import { MaterialTableImpl } from '../common/MaterialTable/MaterialTableImpl';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { Skill } from 'app/redux/models/Skill.model';
import { skillActions } from 'app/redux/slices/skills.slice';

const SkillStatsTable = () => {
    const dispatch = useDispatch();
    const { data: allSkills, loading } = useSelector(selectAllSkills);
    const allExciliens = useSelector(selectAllExcilyenSet);

    useEffect(() => {
        dispatch(skillActions.getAllSkills());
    }, []);

    const skills = useMemo(
        () => allSkills.filter((skill) => skill.coveredByExcilys)
            .map((skill) => {
                const exciliensBySkill = allExciliens.filter((excilyen: any) => (
                    excilyen.skillDetailedApiDtos
                        .map((element: Skill) => element.skillName)
                        .includes(skill.skillName)
                ));
                return {
                    skillName: skill.skillName,
                    excilyenNumber: exciliensBySkill.length,
                    average: Math.round(exciliensBySkill.reduce(reducer(skill), 0) * 100) / 100
                };
            })
            .sort((skill, skillToCompare) => skill.skillName.localeCompare(skillToCompare.skillName)),
        [allSkills, allExciliens]
    );

    const columns = [
        {
            title: 'Compétence',
            field: 'skillName'
        },
        {
            title: 'Nombre d\'excilyens',
            field: 'excilyenNumber',
            headerStyle: {
                textAlign: 'center',
                paddingLeft: '50px'
            },
            cellStyle: {
                textAlign: 'center'
            }
        },
        {
            title: 'Niveau moyen',
            field: 'average',
            headerStyle: {
                textAlign: 'center',
                paddingLeft: '50px'
            },
            cellStyle: {
                textAlign: 'center'
            }
        }
    ];

    return <MaterialTableImpl title={'Compétences'} isFetching={loading} data={skills} columns={columns} />;
};

export default SkillStatsTable;
