import {
    postMicroskillReportingApi
} from 'app/api/microskillReportingApi';

import { notificationError, notificationSuccess } from '../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    MICROSKILL_REPORTING_CREATED,
    MICROSKILL_REPORTING_ERROR_404,
    MICROSKILL_REPORTING_ERROR
} from 'app/Snackbar/NotificationMessages';


export const POST_MICROSKILL_REPORTING_REQUEST = 'POST_MICROSKILL_REPORTING_REQUEST';
export const POST_MICROSKILL_REPORTING_SUCCESS = 'POST_MICROSKILL_REPORTING_SUCCESS';
export const POST_MICROSKILL_REPORTING_FAILURE = 'POST_MICROSKILL_REPORTING_FAILURE';

// export const GET_MICROSKILL_REPORTING_BY_ID_REQUEST = 'GET_MICROSKILL_REPORTING_BY_ID_REQUEST';
// export const GET_MICROSKILL_REPORTING_BY_ID_SUCCESS = 'GET_MICROSKILL_REPORTING_BY_ID_SUCCESS';
// export const GET_MICROSKILL_REPORTING_BY_ID_FAILURE = 'GET_MICROSKILL_REPORTING_BY_ID_FAILURE';

// export const PATCH_MICROSKILL_REPORTING_DENIED_REQUEST = 'PATCH_MICROSKILL_REPORTING_DENIED_REQUEST';
// export const PATCH_MICROSKILL_REPORTING_DENIED_SUCCESS = 'PATCH_MICROSKILL_REPORTING_DENIED_SUCCESS';
// export const PATCH_MICROSKILL_REPORTING_DENIED_FAILURE = 'PATCH_MICROSKILL_REPORTING_DENIED_FAILURE';

// export const PATCH_MICROSKILL_REPORTING_TRAITED_REQUEST = 'PATCH_MICROSKILL_REPORTING_TRAITED_REQUEST';
// export const PATCH_MICROSKILL_REPORTING_TRAITED_SUCCESS = 'PATCH_MICROSKILL_REPORTING_TRAITED_SUCCESS';
// export const PATCH_MICROSKILL_REPORTING_TRAITED_FAILURE = 'PATCH_MICROSKILL_REPORTING_TRAITED_FAILURE';

// export const getMicroskillsReporting = (userId, microskillId) => (dispatch) => {
//     dispatch({ type: GET_MICROSKILL_REPORTING_BY_ID_REQUEST });

//     return getMicroskillsReportingApi(userId, microskillId).then((response) => {
//         dispatch({
//             type: GET_MICROSKILL_REPORTING_BY_ID_SUCCESS,
//             payload: response.data
//         });
//     }, (error) => {
//         dispatch({
//             type: GET_MICROSKILL_REPORTING_BY_ID_FAILURE,
//             payload: error,
//             error: true
//         });
//         if(error.response && error.response.status === 401) {
//             dispatch(notificationSuccess(EXPIRED_SESSION));
//         } else if(error.response && error.response.status !== 403) {
//             dispatch(notificationError(GET_MICROSKILL_REPORTING_ERROR));
//         }
//     });
// };

export const postMicroskillReporting = (microskillReporting) => (dispatch) => {
    dispatch({ type: POST_MICROSKILL_REPORTING_REQUEST });

    return postMicroskillReportingApi(microskillReporting).then((response) => {
        if(response && response.status === 201) {
            dispatch(notificationSuccess(MICROSKILL_REPORTING_CREATED));
        }
    }, (error) => {
        dispatch({
            type: POST_MICROSKILL_REPORTING_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationSuccess(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(MICROSKILL_REPORTING_ERROR_404));
        } else {
            dispatch(notificationError(MICROSKILL_REPORTING_ERROR));
        }
    });
};

// export const patchMicroskillDeniedReporting = (userId, microskillId, reportingId) => (dispatch) => {
//     dispatch({ type: PATCH_MICROSKILL_REPORTING_DENIED_REQUEST });

//     return patchMicroskillDeniedReportingApi(userId, microskillId, reportingId).then((response) => {
//         dispatch({
//             type: PATCH_MICROSKILL_REPORTING_DENIED_SUCCESS,
//             payload: response.data
//         });
//     }, (error) => {
//         dispatch({
//             type: PATCH_MICROSKILL_REPORTING_DENIED_FAILURE,
//             payload: error,
//             error: true
//         });
//         if(error.response && error.response.status === 401) {
//             dispatch(notificationSuccess(EXPIRED_SESSION));
//         } else {
//             dispatch(notificationError(PATCH_MICROSKILL_REPORTING_ERROR));
//         }
//     });
// };

// export const patchMicroskillTreatedReporting = (userId, microskillId, reportingId) => (dispatch) => {
//     dispatch({ type: PATCH_MICROSKILL_REPORTING_TRAITED_REQUEST });

//     return patchMicroskillTreatedReportingApi(userId, microskillId, reportingId).then((response) => {
//         dispatch({
//             type: PATCH_MICROSKILL_REPORTING_TRAITED_SUCCESS,
//             payload: response.data
//         });
//     }, (error) => {
//         dispatch({
//             type: PATCH_MICROSKILL_REPORTING_TRAITED_FAILURE,
//             payload: error,
//             error: true
//         });
//         if(error.response && error.response.status === 401) {
//             dispatch(notificationSuccess(EXPIRED_SESSION));
//         } else {
//             dispatch(notificationError(PATCH_MICROSKILL_REPORTING_ERROR));
//         }
//     });
// };
