import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createChapter, updateChapter } from 'app/redux/actions/Chapter/chapter.actions';

const nullVerif = [null, ''];

export default function AddEditChapterDialog(props) {

    const {
        open,
        edition,
        skillId,
        chapter,
        position,
        onClose
    } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [validationRate, setValidationRate] = useState(0);
    const [maxQuestion, setMaxQuestion] = useState(0);
    const [nbQuestionRequired, setNbQuestionRequired] = useState(0);
    const [nbQuestionRequiredHelperText, setNbQuestionRequiredHelperText] = useState('');

    useEffect(() => {
        if(edition && chapter) {
            setName(chapter.name);
            setValidationRate(chapter.validationRate);
            setNbQuestionRequired(chapter.nbQuestionRequired);
            const nbQuestionMax = chapter.nbQuestionWithChildren || 0;
            setMaxQuestion(nbQuestionMax);
            setNbQuestionRequiredHelperText((nbQuestionMax > 0)
                ? `Un nombre entre 0 et ${nbQuestionMax}`
                : 'Aucune question disponible pour ce chapitre');
        } else {
            setName('');
            setValidationRate(0);
            setNbQuestionRequired(0);
            setMaxQuestion(0);
            setNbQuestionRequiredHelperText('Aucune question disponible pour ce chapitre');
        }
    }, [open]);

    const FIELDS_NOT_EMPTY = (name && !nullVerif.includes(validationRate && nbQuestionRequired));
    const VALIDATION_RATE_VALIDATION = (validationRate <= 100 && validationRate >= 0);
    const NB_QUESTION_VALIDATION = !edition || (nbQuestionRequired <= maxQuestion && nbQuestionRequired >= 0);
    const FORM_VALIDATION = (FIELDS_NOT_EMPTY && VALIDATION_RATE_VALIDATION && NB_QUESTION_VALIDATION);

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeValidationrate = (event) => {
        setValidationRate(event.target.value);
    };

    const handleChangeNbQuestionRequired = (event) => {
        setNbQuestionRequired(event.target.value);
    };

    function handleCreateChapter() {
        const parentPath = chapter ? `${chapter.parentPath + chapter.name.toLowerCase()}/` : '/';
        const newChapter = {
            id: 0,
            name,
            parentPath,
            position,
            skillId,
            validationRate,
            nbQuestionRequired: 0
        };
        dispatch(createChapter(newChapter));
    }

    const handleUpdateChapter = () => {
        const newChapter = {
            ...chapter,
            name,
            validationRate,
            nbQuestionRequired
        };
        dispatch(updateChapter(newChapter));
    };

    const handleValidate = () => {
        if(edition) {
            handleUpdateChapter();
        } else {
            handleCreateChapter();
        }
        if(chapter) {
            props.openChapter(chapter.id);
        }
        props?.onClose?.();
    };

    return (
        <Dialog
            open={Boolean(open)}
            PaperProps={{
                style: { width: '40%' }
            }}
            onKeyDown={(event) => {
                event.stopPropagation();
                if(event.key === 'Enter' && FIELDS_NOT_EMPTY) {
                    event.preventDefault();
                    handleValidate();
                }
            }}
            data-testId="form"
        >
            <DialogTitle>
                { props.edition ? 'Modifier le chapitre' : 'Nouveau chapitre' }
            </DialogTitle>
            <DialogContent
                dividers
            >
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <TextField
                            label="Nom du chapitre"
                            autoFocus
                            fullWidth
                            helperText="100 caractères maximum"
                            inputProps={{ maxLength: 100 }}
                            value={name}
                            onChange={handleChangeName}
                            data-testid="input-name"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Seuil de validation (%)"
                            fullWidth
                            helperText="un nombre entre 0 et 100"
                            error = {!VALIDATION_RATE_VALIDATION}
                            value={validationRate}
                            onChange={handleChangeValidationrate}
                            data-testid="input-rate"
                        />
                    </Grid>
                    {edition &&
                        <Grid item>
                            <TextField
                                label={'nombre de questions nécessaires pour valider'}
                                fullWidth
                                helperText={nbQuestionRequiredHelperText}
                                error = {!NB_QUESTION_VALIDATION}
                                value={nbQuestionRequired}
                                onChange={handleChangeNbQuestionRequired}
                                data-testid="input-min"
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    data-testid="button-cancel"
                >
                    Annuler
                </Button>
                <Button
                    disabled={!FORM_VALIDATION}
                    color="primary"
                    onClick={handleValidate}
                    data-testid="button-validate"
                >
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEditChapterDialog.propTypes = {
    open: PropTypes.bool,
    edition: PropTypes.bool,
    skillId: PropTypes.number,
    chapter: PropTypes.object,
    position: PropTypes.number,
    openChapter: PropTypes.func,
    onClose: PropTypes.func
};
