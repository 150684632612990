import { DetailedMission } from '../models/Mission.model';
import { StateWithLoading } from '../redux.types';
import { getAllDetailedMissionsByUserIdApi } from 'app/api/missionsApi';
import { notificationError } from '../actions/Notification/notifications.actions';
import { RETRIEVE_DETAILED_MISSIONS_BY_USER_ID_ERROR } from 'app/Snackbar/NotificationMessages';
import { createAppSlice, customThunk } from '../redux.helper';

export const missionsStore = 'missions';
export const allMissionsStore = 'allMissions';

interface MissionState {
    [allMissionsStore]: StateWithLoading<DetailedMission[]>;
}

const initialState: MissionState = {
    [allMissionsStore]: {
        data: [],
        loading: false,
        error: null
    }
};

const missionSlice = createAppSlice({
    name: missionsStore,
    initialState,
    reducers: (create) => ({
        getAllDetailedMissionsByUserId: customThunk(
            create,
            missionsStore,
            allMissionsStore,
            {
                request: (id: number) => getAllDetailedMissionsByUserIdApi(id),
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError(RETRIEVE_DETAILED_MISSIONS_BY_USER_ID_ERROR));
                }
            }
        )
    })
});

export const missionsReducer = missionSlice.reducer;

export const missionActions = missionSlice.actions;
