import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth/withWidth';
import '../createEditQuestion/createEditQuestionForm/CreateEditQuestionForm.scss';
import { selectSerieWithQuestionReduced, selectSerieModificationIsSaved } from '../../redux/selectors/Question/serie.selector';
import CreateEditSerieForm from './CreateEditSerieForm/CreateEditSerieForm';
import { createLoadingSelector } from '../../redux/selectors/APISelector/APISelector';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import {
    getSerieByIdWithQuestionReduced,
    GET_SERIE_BY_ID_WITH_QUESTION_REDUCED,
    resetSerieModificationSaved,
    resetSerieWithQuestionReduced
} from '../../redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { mapDurationToLocalTime } from 'app/utils/date.functions';
import { useLocation } from 'react-router-dom';

export function CreateEditSerie(props) {

    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    const dispatch = useDispatch();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const serieId = Number(query.get('id'));
    const isEditing = props.match.params.mode === 'edit';

    const emptySerie = () => ({
        id: null,
        title: '',
        description: '',
        maxDuration: '',
        creator: {
            id: getCurrentUserId()
        },
        questionReducedList: []
    });

    const [serieToEdit, setSerieToEdit] = useState();

    const serieProvided = useSelector(selectSerieWithQuestionReduced);
    const serieModificationSaved = useSelector(selectSerieModificationIsSaved);
    const isFetchingSerie = useSelector(createLoadingSelector([GET_SERIE_BY_ID_WITH_QUESTION_REDUCED]));

    useEffect(() => {
        dispatch(resetSerieWithQuestionReduced());
        if(serieId) {
            dispatch(getSerieByIdWithQuestionReduced(Number(serieId)));
        }
        return () => dispatch(resetSerieWithQuestionReduced());
    }, []);

    useEffect(() => {
        if(serieProvided.size !== 0 && serieProvided.has(serieId) && !isFetchingSerie) {
            const serie = serieProvided.get(serieId);
            setSerieToEdit({
                ...serie,
                maxDuration: mapDurationToLocalTime(serie.maxDuration)
            });
        }
    }, [serieProvided]);

    useEffect(() => {
        if(serieModificationSaved) {
            dispatch(resetSerieModificationSaved());
        }
    }, [serieModificationSaved]);

    return (
        <section data-testid="create-edit-serie">
            {isEditing || serieId
                ? serieToEdit &&
                <CreateEditSerieForm
                    serie={serieToEdit}
                    isEditing={isEditing}
                />
                : <CreateEditSerieForm
                    serie={emptySerie()}
                    isEditing={isEditing}
                />
            }
        </section>
    );
}

CreateEditSerie.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};


export default (withWidth()(CreateEditSerie));
