
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    List,
    TextField,
    makeStyles,
    Collapse
} from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './CoachingMenuSection.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    subheaderText: {
        color: colors['grey-color-400'],
        fontStyle: 'italic'
    },
    noRequestFound: {
        color: 'rgba(0, 0, 0, 0.85)',
        display: 'inline',
        fontStyle: 'italic',
        paddingLeft: '10px'
    },
    listContent: {
        maxHeight: '60vh',
        overflow: 'auto'
    },
    collapse: {
        paddingTop: '8px'
    },
    filterBar: {
        marginLeft: '-12px',
        width: '100px'
    },
    clickable: {
        cursor: 'pointer'
    },
    clickableToggleCollapse: {
        cursor: 'pointer',
        paddingLeft: '0 !important'
    }
}));

export default function CoachingMenuSection(props) {

    const classes = useStyles();

    const { data, onFilterChange, title, emptyDataMessage } = props;

    const isCollapsed = props.collapsed;

    const [filteredData, setFilteredData] = useState(data);
    const [filteredValue, setFilteredValue] = useState('');

    useEffect(() => {
        onFilterChange(filteredData);
    }, [filteredData]);

    useEffect(() => {
        setFilteredValue('');
    }, [data]);

    useEffect(() => {
        if(filteredValue && filteredValue.trim().length > 0) {
            if(title === 'Mes Groupes') {
                setFilteredData(data.filter((groupStudent) => groupStudent.name.toLowerCase().includes(filteredValue.toLowerCase())));
            } else {
                setFilteredData(data.filter((user) => user.firstName.concat(' ', user.lastName).toLowerCase()
                    .includes(filteredValue.toLowerCase())));
            }
        } else {
            setFilteredData(data);
        }
    }, [data, isCollapsed, filteredValue]);

    const handleCollapse = () => {
        props.onCollapse(!isCollapsed);
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} id="clickable" data-testid="coaching-list-click" className={classes.clickable} onClick={handleCollapse}>
                        <h3>{title}</h3>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} id="clickable">
                        {isCollapsed
                            ? <></>
                            : <TextField value={filteredValue} id="filterBar" name="filterBar" className={classes.filterBar} placeholder="Rechercher"
                                onChange={(event) => setFilteredValue(event.target.value)}
                                data-testid="coaching-list-fliter-open"
                            />
                        }
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} id="clickable" className={classes.clickable} onClick={handleCollapse}>
                        <h4 className={classes.subheaderText}>{ filteredData ? filteredData.length : 0 } résultats</h4>
                    </Grid>
                    { title !== 'Mes Groupes' &&
                        <Grid item xs={1} sm={1} md={1} lg={1} className={classes.clickableToggleCollapse} onClick={handleCollapse}>
                            {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </Grid>
                    }
                </Grid>
            }
            className={classes.root}
            data-testid="coaching-list"
        >
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit className={classes.collapse}>
                <div className={classes.listContent}>
                    {data && data.length > 0
                        ? props.children
                        : <h4 id="noDataMessage" className={classes.subheaderText}>{emptyDataMessage}</h4>}
                </div>
            </Collapse>
        </List>
    );
}

CoachingMenuSection.propTypes = {
    children: PropTypes.node,
    data: PropTypes.array,
    title: PropTypes.string,
    emptyDataMessage: PropTypes.string,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    onFilterChange: PropTypes.func
};
