import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { selectGroup } from 'app/redux/selectors/Group/group.selector';
import PropTypes from 'prop-types';

function AddToGroupButton(props) {

    const dispatch = useDispatch();

    const groupSelected = useSelector(selectGroup);

    const addToGroup = () => {
        dispatch(props.dispatchPersonGroup(props.student.id, groupSelected.id, props.userId));
    };

    const buttonText = useMemo((isInTheGroup) => isInTheGroup ? 'Déjà dans le groupe' : props.addToGroup, [props.isAlreadyInGroup, props.addToGroup]);

    return (<div>
        <Button id="requestGroupButton"
            variant={props.displayAsMenuItem ? 'text' : 'outlined'}
            color={props.displayAsMenuItem ? 'default' : 'primary'}
            onClick={addToGroup}
            aria-label={buttonText}
            className={props.className}
            disabled={props.isAlreadyInGroup}>
            {buttonText}
        </Button>
    </div>
    );

}

AddToGroupButton.propTypes = {
    className: PropTypes.string,
    addToGroup: PropTypes.string.isRequired,
    displayAsMenuItem: PropTypes.bool,
    isAlreadyInGroup: PropTypes.bool.isRequired,
    student: PropTypes.object,
    userId: PropTypes.number,

    dispatchPersonGroup: PropTypes.func
};

export default AddToGroupButton;
