import React, { Card, CardContent, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import './ChapterReportingPane.scss';
import { useEffect, useState } from 'react';
import { selectChapterReporting, selectReportedChapter } from '../../../../../redux/selectors/Chapter/chapter.selector';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { patchChapterReportingDenied, patchChapterReportingTreated } from 'app/redux/actions/Chapter/chapter.actions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { getDetailedExcilyenByIdApi } from 'app/api/excilyenApi';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { RETRIEVE_EXCILYENS_ERROR } from 'app/Snackbar/NotificationMessages';

interface ReportCardProps {
    reporterId: number;
    report: any;
    chapterId: number;
}

function ReportCard(props: ReportCardProps) {

    const dispatch = useDispatch();

    const currentUserId = AuthenticationContainer.getCurrentUserId();

    const reportedChapter = useSelector(selectReportedChapter);
    const [reporter, setReporter] = useState<any>();

    const treatChapterReporting = () => {
        dispatch(patchChapterReportingTreated(currentUserId, props.chapterId, props.report.id, props.report.taskId));
    };

    const denyChapterReporting = () => {
        dispatch(patchChapterReportingDenied(currentUserId, props.chapterId, props.report.id, props.report.taskId));
    };

    useEffect(() => {
        getDetailedExcilyenByIdApi(props.reporterId).then((response) => {
            setReporter(response.data);
        }, (_error) => {
            dispatch(notificationError(RETRIEVE_EXCILYENS_ERROR));
        });
    }, [props.report]);

    return (
        <Card variant="outlined" className="report-card">
            <CardContent>
                <p>Signalé par {reporter?.firstName} {reporter?.lastName}</p>
                <p>Chapitre : {reportedChapter?.name}</p>
                <p>{props.report.description}</p>
            </CardContent>
            <IconButton aria-label="Treat button" onClick={treatChapterReporting} data-testid="treat-button">
                <CheckCircleOutlineIcon className="treat-button" />
            </IconButton>
            <IconButton aria-label="Deny button" onClick={denyChapterReporting} data-testid="deny-button">
                <CancelOutlinedIcon className="deny-button" />
            </IconButton>
        </Card>
    );
}

export interface ChapterReportingPaneProps {
    chapterId: number;
}

export default function ChapterReportingPane(props: ChapterReportingPaneProps) {
    const reportingList: any[] = useSelector(selectChapterReporting);

    return (reportingList.length > 0 &&
        <div className="reporting-pane-container">
            <h3 data-testid="report-pane" style={{ marginLeft: '8px' }}>Commentaires</h3>
            <div className="reporting-cards-container">
                {reportingList.map((item : any) => (
                    <ReportCard key={item.id} report={item} reporterId={item.reporterId} chapterId={props.chapterId} />
                ))}
            </div>
        </div>
    );
}
