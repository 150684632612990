import {
    SET_CLIENTS_SECTOR_FILTER, SET_CLIENTS_USER_FILTER
} from '../../../actions/Client/ClientFilter/clientsFilters.actions';

export const clientSectorFilterStore = 'clientSectorFilter';
export const clientUserFilterStore = 'clientUserFilter';

export default function clientsFilterReducer(
    state = { [clientSectorFilterStore]: null,
        [clientUserFilterStore]: null },
    action
) {
    switch (action.type) {
        case SET_CLIENTS_SECTOR_FILTER:
            return { ...state,
                [clientSectorFilterStore]: action.payload };
        case SET_CLIENTS_USER_FILTER:
            return { ...state,
                [clientUserFilterStore]: action.payload };

        default: return state;
    }
}
