import { Button, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectSkillLevelOfCurrentUser } from 'app/redux/selectors/User/user.selector';
import { sendModeratorRequest } from 'app/api/requestModeratorApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { notificationError, notificationSuccess, notificationWarning } from 'app/redux/actions/Notification/notifications.actions';
import { SEND_MODERATOR_REQUEST_ERROR, SEND_MODERATOR_REQUEST_ERROR_UNABLE_TO_SEND_MAIL, SEND_MODERATOR_REQUEST_ERROR_UNABLE_TO_SEND_MAIL_AND_NOTIFICATION, SEND_MODERATOR_REQUEST_SUCCESS } from 'app/Snackbar/NotificationMessages';
import { useStyles } from '../ChapterHeader.functions';

const MODERATOR_BUTTON = 'Devenir Modérateur';
const ERROR_MESSAGE_UNABLE_TO_SEND_MAIL = 'Unable to send the mail.';
const ERROR_MESSAGE_UNABLE_TO_SEND_MAIL_AND_NOTIFICATION = 'Unable to send the notification.';


function ChapterButtonBecomeModerator(props) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const skillLevelOfCurrentUser = useSelector((state) => selectSkillLevelOfCurrentUser(state, props.skill.id));

    const [hasLevelOnSkill, setHasLevelOnSkill] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setHasLevelOnSkill(skillLevelOfCurrentUser && skillLevelOfCurrentUser > 0);
    }, [skillLevelOfCurrentUser]);

    const handleBecomeModeratorClick = () => {
        setDisabled(true);
        sendModeratorRequest({
            userId: Number(AuthenticationContainer.getCurrentUserId()),
            skillId: props.skill.id
        })
            .then(() => {
                dispatch(notificationSuccess(SEND_MODERATOR_REQUEST_SUCCESS));
            })
            .catch((error) => {
                if(error.response.data === ERROR_MESSAGE_UNABLE_TO_SEND_MAIL_AND_NOTIFICATION) {
                    dispatch(notificationError(SEND_MODERATOR_REQUEST_ERROR_UNABLE_TO_SEND_MAIL_AND_NOTIFICATION));
                } else if(error.response.data === ERROR_MESSAGE_UNABLE_TO_SEND_MAIL) {
                    dispatch(notificationWarning(SEND_MODERATOR_REQUEST_ERROR_UNABLE_TO_SEND_MAIL));
                } else {
                    dispatch(notificationError(SEND_MODERATOR_REQUEST_ERROR));
                }
            });
    };

    return (
        <Tooltip title={hasLevelOnSkill ? '' : 'Vous devez avoir des étoiles pour être modérateur'}>
            <div>
                <Button
                    className={classes.buttonDevenirModerateur}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick = { handleBecomeModeratorClick }
                    disabled = { !hasLevelOnSkill || disabled }
                    data-testid="become-moderator-button"
                >
                    { MODERATOR_BUTTON }
                </Button>
            </div>
        </Tooltip>
    );
}

ChapterButtonBecomeModerator.propTypes = {
    skill: PropTypes.object
};

export default ChapterButtonBecomeModerator;
