import {
    validateQuestionResolverWithCourse
} from '../QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer.functions';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import History from '@material-ui/icons/History';
import Remove from '@material-ui/icons/Remove';
import { getResultColor } from './historyTable/utils';
import colors from 'styles/colors.module.scss';
import { isUndefined } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { optionsTooltip } from 'app/utils/OptionsTooltipEnum';
import { getDateFormated } from 'app/utils/date.functions';

function setIsSelected(questionHistory) {
    questionHistory.question.answers?.forEach((element) => {
        element.selected = false;
        questionHistory.answersSelected.forEach((elemSelected) => {
            if(element.id === elemSelected?.id) {
                element.selected = true;
            }
        });
    });
}

function setTitleAndCreator(questionHistory) {
    questionHistory.title = questionHistory.question.title;
    questionHistory.creator = questionHistory.question.creator;
}

export function setQuestionParameters(questionHistory) {
    const result = { ...questionHistory };
    setIsSelected(result);
    setTitleAndCreator(result);
    validateQuestionResolverWithCourse(result.question.answers, result.question.answers);
    return result;
}

export function setQuestionATrouParameters(questionHistory) {
    const result = { ...questionHistory };
    setTitleAndCreator(result);
    result.question.answers = result.question.answers.map((answer, index) => ({
        ...answer,
        value: result.answersSelected[index]
    }));    
    return result;
};

export function resultDisplay(value) {
    const score = isUndefined(value) ? -2 : value;
    switch (score) {
        case 'TRUE':
        case 101:
            return <DoneIcon aria-label="doneIcon" style={{ color: colors['green-color-700'] }} />;
        case 'FALSE':
        case 0:
            return <CloseIcon aria-label="closeIcon" style={{ color: colors['red-color-400'] }} />;
        case 'PENDING':
        case -2:
            return <History aria-label="pendingIcon" style={{ color: colors['dark-orange'] }}/>;
        case 'NOT_DONE':
        case -1:
            return <Remove aria-label="notDoneIcon" style={{ color: colors['grey-color-400'] }}/>;
        default:
            return <div style={{ color: getResultColor(score) }}>
                {`${score}/100`}
            </div>;
    }
}

export function dateDisplay(date) {
    return date
        ? <Tooltip
            title={new Intl.DateTimeFormat('fr-FR', optionsTooltip).format(new Date(date))}
            placement="left-start">
            <div> {getDateFormated(new Date(date))} </div>
        </Tooltip>
        : <></>;
}
