
import { groups, coaches, students, group, selectedGroupStudentsMap, groupStatsByChapter, selectedGroupStudents } from '../../reducers/Group/group.reducer';
import { groupStore } from '../../rootReducer';

export function selectGroups(state) {
    return state[groupStore][groups];
}

export function selectStudents(state) {
    return state[groupStore][students];
}

export function selectCoaches(state) {
    return state[groupStore][coaches];
}

export function selectGroup(state) {
    return state[groupStore][group];
}

export function selectGroupStudentsMap(state) {
    return state[groupStore][selectedGroupStudentsMap];
}

export function selectGroupStudents(state) {
    return state[groupStore][selectedGroupStudents];
}

export function selectGroupStatsByChapter(state) {
    return state[groupStore][groupStatsByChapter];
}
