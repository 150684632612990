import { QuestionAskedEnum } from './QuestionEnum';

export const trouLiteral = 'trou_';

export const validTrouColor = '#8ccc9c';

export const invalidTrouColor = '#ffb7bc';

export function getTrouInputId(ordinal) {
    return `${trouLiteral}${ordinal}`;
}

export const isQuestionShuffled = (question) => {
    switch (question.class) {
        case QuestionAskedEnum.MultipleChoice:
            return true;
        case QuestionAskedEnum.TexteATrou:
        default:
            return false;
    }
};

export const createElement = (type, props) => {
    const element = document.createElement(type);

    for(const key in props) {
        if(Object.hasOwn(props, key)) {
            element.setAttribute(key, props[key]);
        }
    }

    return element;
};
