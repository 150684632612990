import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UserAddSkill.scss';
import AddSkillTable from '../addSkillTable/AddSkillTable';
import { Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

export class UserAddSkill extends Component {

    render() {

        return (
            <section className="wrapper" data-testid="add-skill-table">
                <div className="array">
                    <AddSkillTable/>
                </div>
                <div className="button-finish">
                    <Button variant="contained" color="primary" component={ Link } to="/users">
                        Terminer
                    </Button>
                </div>
            </section>
        );
    }
}

UserAddSkill.propTypes = {
    match: PropTypes.object
};

export default withRouter(UserAddSkill);
