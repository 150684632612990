
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './SkillContent.scss';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { selectCurrentUser, selectCurrentUserSkillSet } from '../../../redux/selectors/User/user.selector';
import EditSkill from '../../../home/components/EditSkill';
import SkillModerator from '../SkillModerator';
import { Hidden, MuiThemeProvider, Slider } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography/Typography';
import { collapsibleExpertTheme, collapsibleTheme } from './SkillContentTheme';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { getDetailedExcilyenSkills, getExcilyensDetailedBySkillName } from '../../../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import { getCurrentUserById } from '../../../redux/actions/User/user.actions';
import ChapterRequest from './Chapter/ChapterRequest/ChapterRequest';
import SkillExpertContent from '../SkillExpertContent';
import { selectTaskDrawer } from 'app/redux/selectors/Drawer/drawer.selector';
import clsx from 'clsx';

export class SkillContent extends Component {

    state = {
        levelRequired: 3,
        expertsDisplay: localStorage.displayExpertsSection === undefined ? false : localStorage.displayExpertsSection === 'true',
        moderatorsDisplay: localStorage.displayModeratorsSection === undefined ? false : localStorage.displayModeratorsSection === 'true'
    };

    componentDidMount() {
        this.props.getCurrentUserById();
        this.props.getExcilyensDetailedBySkillName(this.props.skill.skillName);
        this.props.getDetailedExcilyenSkills();
    }

    componentDidUpdate(previousProps) {
        if(previousProps.skill !== this.props.skill) {
            this.props.getExcilyensDetailedBySkillName(this.props.skill.skillName);
            this.props.getDetailedExcilyenSkills();
        }
    }

    levelRequiredChange = (event, value) => {
        this.setState({
            levelRequired: value
        });
    };

    expertsDisplayStateChange = (state) => {
        const newState = !state;
        localStorage.setItem('displayExpertsSection', newState);
        this.setState({
            expertsDisplay: newState
        });
    };

    moderatorsDisplayStateChange = (state) => {
        const newState = !state;
        localStorage.setItem('displayModeratorsSection', newState);
        this.setState({
            moderatorsDisplay: newState
        });
    };

    isModerator = () => {
        let currentUserIsFind = false;
        if(this.props.userSkillSet) {
            for(let ii = 0; ii < this.props.userSkillSet.length; ii++) {
                if(this.props.userSkillSet[ii].skillName === this.props.skill.skillName) {
                    currentUserIsFind = this.props.userSkillSet[ii].moderator;
                }
            }
        }
        return currentUserIsFind;
    };


    slider = () => <Slider
        defaultValue={this.levelRequired ? this.levelRequired : 3}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks
        min={1}
        max={5}
        onChange={this.levelRequiredChange}
        data-testid="slider"
    />;

    oxyliens = (user, skill) => <div className="div-accordion">
        <MuiThemeProvider theme={collapsibleExpertTheme}>
            <Accordion id="experts" className="not-expanded" expanded={this.state.expertsDisplay}
                onChange={() => this.expertsDisplayStateChange(this.state.expertsDisplay, 'displayExpertsSection')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    data-testid="experts"
                >
                    <Grid item container direction="row">
                        <Grid item className="section">
                            <Icon className="icon">school</Icon>
                            <Typography className="label">Oxyliens</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <StickyAwareComponent />
                    <div className="excilyen-header">
                        <Hidden only={[
                            'md',
                            'lg',
                            'xl'
                        ]}>
                            <div className="slider-container">
                                Niveau minimum
                                {this.slider()}
                            </div>
                        </Hidden>
                        <Hidden only={[
                            'xs',
                            'sm'
                        ]}>
                            <div className="slider-container-desktop">
                                <div className="slider-level-desktop">
                                    Niveau minimum
                                </div>
                                <div className="slider-desktop">
                                    {this.slider()}
                                </div>
                            </div>
                        </Hidden>
                    </div>
                    <SkillExpertContent
                        user={user}
                        skill={skill}
                        levelRequired={this.state.levelRequired}
                        width={this.props.width}
                    />
                </AccordionDetails>
            </Accordion>
        </MuiThemeProvider>
    </div>;

    cssControl = () => {
        if(isWidthUp('md', this.props.width)) {
            return 'chapters-section-desktop';
        }
        return 'chapters-section-mobile';
    };


    render() {
        const { user, skill } = this.props;
        return (
            <div className={clsx('skill-content')} data-testid="skill-content">
                {(this.isModerator() || (user.roles && user.roles.some((role) => role.name === 'ADMIN'))) &&
                    <EditSkill skillToEdit={skill} width={this.props.width}/>
                }
                <Paper className={this.cssControl()}>

                    <div className="chapter">

                        <ChapterRequest
                            skill={{
                                id: this.props.skill.id,
                                name: this.props.skill.skillName,
                                imageUrl: this.props.skill.imageUrl,
                                familyInternalDto: this.props.skill.familyInternalDto,
                                familyInternalDtoOptional: this.props.skill.familyInternalDtoOptional,
                                categoryInternalDto: this.props.skill.categoryInternalDto
                            }}
                            isModerator={this.isModerator()}
                            permission={(this.isModerator() || (user.roles && user.roles.some((role) => role.name === 'ADMIN')))}
                        />
                    </div>

                </Paper>
                {user.roles && user.roles.some((role) => role.name === 'ADMIN') &&
                    <>
                        <div className="div-accordion">
                            <MuiThemeProvider theme={collapsibleTheme}>
                                <Accordion id="moderators" className="not-expanded" expanded={this.state.moderatorsDisplay}
                                    onChange={() => this.moderatorsDisplayStateChange(this.state.moderatorsDisplay, 'displayModeratorsSection')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        data-testid="skill-moderators"
                                    >
                                        <Grid item container direction="row">
                                            <Grid item className="section">
                                                <Icon className="icon">verified_user</Icon>
                                                <Typography className="label">Modérateurs</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <SkillModerator skill={skill} width={this.props.width} />
                                    </AccordionDetails>
                                </Accordion>
                            </MuiThemeProvider>
                        </div>
                        {this.oxyliens(user, skill)}
                    </>
                }
            </div>
        );
    }
}

const { useEffect } = React;
const ONE = 1;

// eslint-disable-next-line react/prop-types,no-unused-vars
export function StickyAwareComponent({ children, sticky = false, className, ...rest }) {
    const ref = React.createRef();

    useEffect(() => {
        const cachedRef = ref.current,
            observer = new IntersectionObserver((entries) => {
                if(entries[0].intersectionRatio === 0) {
                    document.querySelector('.excilyen-header').classList.add('sticky');
                } else if(entries[0].intersectionRatio === 1) {
                    document.querySelector('.excilyen-header').classList.remove('sticky');
                }
            }, {
                threshold: [ONE]
            });
        observer.observe(cachedRef);

        // eslint-disable-next-line func-names
        return function() {
            observer.unobserve(cachedRef);
        };
    }, []);

    return (
        <div ref={ref} {...rest} data-testid="sticky-aware-component">
            <div className="sentinel-top">
            </div>
        </div>
    );
}

SkillContent.propTypes = {
    skill: PropTypes.shape({
        id: PropTypes.number,
        skillName: PropTypes.string,
        familyInternalDto: PropTypes.object,
        familyInternalDtoOptional: PropTypes.object,
        categoryInternalDto: PropTypes.object,
        description: PropTypes.string,
        imageUrl: PropTypes.string
    }),
    currentUser: PropTypes.object,
    userSkillSet: PropTypes.array,
    user: PropTypes.object,
    taskDrawer: PropTypes.bool,
    width: PropTypes.string,
    getCurrentUserById: PropTypes.func,
    getExcilyensDetailedBySkillName: PropTypes.func,
    getDetailedExcilyenSkills: PropTypes.func
};

function mapStateToProps(state) {
    return {
        userSkillSet: selectCurrentUserSkillSet(state),
        user: selectCurrentUser(state),
        taskDrawer: selectTaskDrawer(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getDetailedExcilyenSkills: () => dispatch(getDetailedExcilyenSkills()),
        getCurrentUserById: () => dispatch(getCurrentUserById()),
        getExcilyensDetailedBySkillName: (skillName) => dispatch(getExcilyensDetailedBySkillName(skillName))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()((SkillContent)));
