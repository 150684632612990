import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ClientDetails.scss';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getClientById } from '../../../../redux/actions/Client/clients.actions';
import { selectClient } from '../../../../redux/selectors/Client/clients.selector';
import ClientDetailsContent from './ClientDetailsContent/ClientDetailsContent';

export class ClientDetails extends Component {

    state = {
        redirect: false
    };

    componentDidMount() {
        this.getClient();
    }

    async getClient() {

        await this.props.getClientById(this.props.match.params.id);
        this.isClientNotNull();
    }

    isClientNotNull() {
        const { client } = this.props;
        const { redirect } = this.state;

        if(!client && !redirect) {
            this.setState({
                redirect: true
            });
        }
    }

    render() {
        const { client } = this.props;
        const { redirect } = this.state;

        return (
            <section className="clients">
                <div className="wrapper">
                    {redirect && <Redirect to={'/projects'}/>}
                    { client &&
                        <ClientDetailsContent client={client}/>
                    }
                </div>
            </section>
        );
    }

}

ClientDetails.propTypes = {
    match: PropTypes.object,
    getClientById: PropTypes.func,
    client: PropTypes.object,
    isClientNotNull: PropTypes.func
};

function mapStateToProps(state) {
    return {
        client: selectClient(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getClientById: (clientId) => dispatch(getClientById(clientId, false))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientDetails));
