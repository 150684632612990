import { getAllCategoriesApi, getAllFamiliesApi } from '../../../api/skillSetApi';
import { notificationError } from '../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_CATEGORIES_ERROR,
    RETRIEVE_FAMILIES_ERROR
} from '../../../Snackbar/NotificationMessages';

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_ALL_CATEGORIES_REQUEST = 'GET_ALL_CATEGORIES_REQUEST';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_FAILURE = 'GET_ALL_CATEGORIES_FAILURE';

export const GET_ALL_FAMILIES = 'GET_ALL_FAMILIES';
export const GET_ALL_FAMILIES_REQUEST = 'GET_ALL_FAMILIES_REQUEST';
export const GET_ALL_FAMILIES_SUCCESS = 'GET_ALL_FAMILIES_SUCCESS';
export const GET_ALL_FAMILIES_FAILURE = 'GET_ALL_FAMILIES_FAILURE';

export const getAllCategories = () => (dispatch) => {
    dispatch({ type: GET_ALL_CATEGORIES_REQUEST });

    return getAllCategoriesApi().then((response) => {
        dispatch({
            type: GET_ALL_CATEGORIES_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_CATEGORIES_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_CATEGORIES_ERROR));
        }
    });
};

export const getAllFamilies = () => (dispatch) => {
    dispatch({ type: GET_ALL_FAMILIES_REQUEST });

    return getAllFamiliesApi().then((response) => {
        dispatch({
            type: GET_ALL_FAMILIES_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_FAMILIES_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_FAMILIES_ERROR));
        }
    });
};
