/**
 * Returns TRUE ifthe first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export function arrayContainsArray(superset: any[], subset: any[]): boolean {
    if(subset.length === 0) {
        return false;
    }
    return subset.every((value) => superset.includes(value));
}

export function arraysIntersection(array1: any[], array2: any[]): any[] {
    if(Array.isArray(array1) && array1.length) {
        return array1.filter((element) => array2.includes(element));
    }
    return array1;
}

export function arraysEqual(array1: any[], array2: any[], compare: any) {
    if(array1 === array2) {
        return true;
    }
    if(array1 === null || array2 === null) {
        return false;
    }
    if(array1.length !== array2.length) {
        return false;
    }
    if(compare === undefined) {
        // eslint-disable-next-line id-length
        for(let i = 0; i < array1.length; ++i) {
            if(array1[i] !== array2[i]) {
                return false;
            }
        }
    } else {
        // eslint-disable-next-line id-length
        for(let i = 0; i < array1.length; ++i) {
            if(!compare(array1[i], array2[i])) {
                return false;
            }
        }
    }
    return true;
}

export enum AssignTypes {
    QUESTION = 'question',
    SERIE = 'serie',
    CHAPTER = 'chapter',
    TASK = 'task',
    PARCOURS = 'parcours',
    PARCOURS_STEP = 'parcoursStep',
    PARCOURS_STEP_CONTENT = 'parcoursStepContent',
}
