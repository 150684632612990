
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    }
}));

export default function TablePaginationActions(props) {

    const classes = useStyles();

    const { count, page, rowsPerPage, onPageChange, onChangePage } = props;

    const pageChange = (event, pageNumber) => {
        if(onPageChange) {
            onPageChange(event, pageNumber);
        } else {
            onChangePage(event, pageNumber);
        }
    };


    const handleFirstPageButtonClick = (event) => {
        pageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        pageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        pageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        pageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton className="firstPageButton"
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton className="backPageButton"
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton className="nextPageButton"
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton className="lastPageButton"
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number,
    onChangePage: PropTypes.func,
    onPageChange: PropTypes.func,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired

};

