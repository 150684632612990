import axiosClient from './utils/axiosClient';

export const DAILY_PREFERENCES_URI = 'preferences/daily';

export function getDailyPreferencesApi(userId) {
    return axiosClient.get(`${DAILY_PREFERENCES_URI}/${userId}`);
}

export function postDailyPreferencesApi(dailyPreference) {
    return axiosClient.post(DAILY_PREFERENCES_URI, dailyPreference);
}
