import React from 'react';
import PropTypes from 'prop-types';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import { format } from 'date-fns';
import './QuestionTablePage.scss';

function TimePickerCustomToolbar(props) {

    const { date, isLandscape, openView, setOpenView, title } = props;

    const handleChangeViewClick = (view) => () => {
        setOpenView(view);
    };

    return (
        <PickerToolbar
            className="toolbar"
            title={title}
            isLandscape={isLandscape}
        >
            <ToolbarButton
                onClick={handleChangeViewClick('hours')}
                variant="h2"
                selected={openView === 'hours'}
                label={format(date, 'HH:00')}
            />
        </PickerToolbar>
    );
}

TimePickerCustomToolbar.propTypes = {
    date: PropTypes.instanceOf(Date),
    isLandscape: PropTypes.bool,
    openView: PropTypes.string,
    setOpenView: PropTypes.func,
    title: PropTypes.string
};

export default TimePickerCustomToolbar;
