import { DetailedMission, Mission } from 'app/redux/models/Mission.model';
import axiosClient from './utils/axiosClient';

export const MISSIONS_SET_URI = 'missions';
export const USER_URI = '/user';

export function postMissionApi(mission: Mission) {
    return axiosClient.post<Mission>(MISSIONS_SET_URI, mission);
}

export function putMissionApi(mission: Mission) {
    return axiosClient.put<Mission>(MISSIONS_SET_URI, mission);
}

export function deleteMissionApi(missionId: number) {
    return axiosClient.delete(`${MISSIONS_SET_URI}/${missionId}`);
}

export function getAllDetailedMissionsByUserIdApi(userId: number) {
    return axiosClient.get<DetailedMission[]>(`${MISSIONS_SET_URI + USER_URI}/${userId}`);
}
