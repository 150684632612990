import { RootState } from 'app/redux/rootReducer';
import { skillsStoreNew, allReducedSkillsStore, allSkillsStore } from 'app/redux/slices/skills.slice';

export function selectAllSkills(state: RootState) {
    return state[skillsStoreNew][allSkillsStore];
}

export function selectAllReducedSkills(state: RootState) {
    return state[skillsStoreNew][allReducedSkillsStore];
}
