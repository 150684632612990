import { DialogActions, DialogTitle, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Popup.scss';

export class DescriptionPopup extends Component {

    state = {
        openModal: false
    };

    openModal = () => {
        this.setState({ openModal: true });
    };

    closeModal = () => {
        this.setState({
            openModal: false
        });
    };

    render() {
        return (
            <i>
                <span className="pointer" id="color" onClick={this.openModal}> ...Lire plus</span>
                <Dialog onClose={this.closeModal} aria-labelledby="simple-dialog-title" open={this.state.openModal}
                    scroll={'paper'}>
                    <DialogTitle> Description </DialogTitle>
                    <Grid id="grid-container-popup">
                        {this.props.description}
                    </Grid>

                    <DialogActions>
                        <Button class="" onClick={this.closeModal} color="primary">
                        Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </i>
        );
    }
}

DescriptionPopup.propTypes = {
    description: PropTypes.string
};
