
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getYoutubeIdFromLink } from './AddChapterResourceForm.function';
import { RESOURCE_BAD_FORMAT } from 'app/Snackbar/NotificationMessages';

import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { createResource } from 'app/redux/actions/Chapter/Resource/resource.actions';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';

export const chapterResourceTypeEnum = {
    YOUTUBE: 'YOUTUBE',
    PDF: 'PDF',
    TEXT: 'TEXT'
};

const useStyles = makeStyles(() => ({
    buttonContainer: {
        marginTop: '16px'
    }
}));

function AddChapterResourceForm(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const emptyChapterResource = {
        id: 0,
        name: '',
        link: '',
        chapterId: props.chapterId,
        type: chapterResourceTypeEnum.YOUTUBE
    };

    const [chapterResource, setChapterResource] = useState(emptyChapterResource);

    const resetChapterResource = () => {
        setChapterResource(emptyChapterResource);
    };

    const handleChapterResource = (event) => {
        const { name, value } = event.target;
        setChapterResource((state) => ({
            ...state,
            [name]: value
        }));
    };

    const linkError = Boolean(chapterResource.link && !getYoutubeIdFromLink(chapterResource.link));

    const isValidForm = (
        chapterResource.name &&
        chapterResource.link &&
        getYoutubeIdFromLink(chapterResource.link)
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if(isValidForm) {
            dispatch(createResource({
                ...chapterResource,
                link: decodeURIComponent(getYoutubeIdFromLink(chapterResource.link))
            }));
        } else {
            dispatch(notificationError(RESOURCE_BAD_FORMAT));
        }
        resetChapterResource();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column">
                <Grid item>
                    <TextField
                        inputProps={{ 'aria-label': 'Nom' }}
                        label="Nom"
                        name="name"
                        margin="normal"
                        fullWidth
                        required
                        autoFocus
                        value={chapterResource.name}
                        onChange={handleChapterResource}
                        cy-data="skill-chapter-add-ressource-name"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        inputProps={{ 'aria-label': 'Lien youtube' }}
                        label="Lien youtube"
                        name="link"
                        error={linkError}
                        margin="normal"
                        fullWidth
                        required
                        value={chapterResource.link}
                        onChange={handleChapterResource}
                        cy-data="skill-chapter-add-ressource-link"
                    />
                </Grid>
                <Grid
                    className={classes.buttonContainer}
                    container
                    justifyContent="flex-end"
                    spacing={1}
                >
                    {props.onCancel &&
                        <Grid item>
                            <Button
                                color="primary"
                                startIcon={<CancelIcon />}
                                onClick={props.onCancel}
                            >
                                Annuler
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            color="primary"
                            type="submit"
                            startIcon={<DoneIcon />}
                            disabled={!isValidForm}
                            cy-data="skill-chapter-add-ressource-confirm"
                        >
                            Ajouter
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

AddChapterResourceForm.propTypes = {
    chapterId: PropTypes.number,
    chapterName: PropTypes.string,
    onCancel: PropTypes.func
};

export default AddChapterResourceForm;
