

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import './SidePanelProjectsFilterContainer.scss';
import { setProjectsClientFilter, setProjetsSkillsFilter, setProjectsExcilyenFilter, setProjectsSectorFilter } from
    '../../../redux/actions/Project/ProjectFilters/projectFilters.actions';
import { selectAllClients } from '../../../redux/selectors/Client/clients.selector';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { selectAllUser } from '../../../redux/selectors/User/user.selector';
import { selectAllSectors } from '../../../redux/selectors/Sector/sector.selector';
import { selectProjectFilterClient, selectProjectFilterSector, selectProjectFilterSkill, selectProjectFilterUser } from
    '../../../redux/selectors/Project/projectsFilters.selector';


export class SidePanelProjectsFilterContainer extends Component {


    handleChange = (value) => {
        if(this.props.client) {
            this.props.setProjectsClientFilter(value);
        } else if(this.props.skill) {
            this.props.setProjetsSkillsFilter(value);
        } else if(this.props.sector) {
            this.props.setProjectsSectorFilter(value);
        } else {
            this.props.setProjectsExcilyenFilter(value);
        }
    };

    render() {
        const allClient = this.props.clients.map((client) => ({ value: client.id,
            label: client.name }));

        const allSkill = this.props.skills.map((skill) => ({ value: skill.id,
            label: skill.skillName }));

        const allUsers = this.props.excilyens.map((excilyen) => ({ value: excilyen.id,
            label: `${excilyen.firstName} ${excilyen.lastName}` }));

        const allSectors = this.props.sectors.map((sector) => ({ value: sector.id,
            label: sector.description }));

        const { client, skill, excilyen, sector } = this.props;
        let className = '';
        let placeHolder = '';
        let name = '';
        let isMulti = false;
        let options = [];
        let selectValue = '';

        const customStyles = {
            control: (base, __) => ({
                ...base,
                boxShadow: 'none'
            })
        };

        if(client) {
            className = 'basic-single';
            placeHolder = 'Clients';
            name = 'clients';
            options = allClient;
            isMulti = false;
            selectValue = this.props.valueClient;
        }
        if(skill) {
            className = 'basic-multi-select';
            placeHolder = 'Compétences';
            name = 'competences';
            options = allSkill;
            isMulti = true;
            selectValue = this.props.valueSkill;
        }
        if(excilyen) {
            className = 'basic-multi-select';
            placeHolder = 'Intervenants';
            name = 'intervenants';
            options = allUsers;
            isMulti = true;
            selectValue = this.props.valueUser;
        }
        if(sector) {
            className = 'basic-single';
            placeHolder = 'Secteurs d\'activité';
            name = 'secteurs d\'activité';
            options = allSectors;
            isMulti = false;
            selectValue = this.props.valueSector;

        }

        return (
            <div className="project-filter" data-testid="side-panel-projects-filter-container">
                <Typography className="sub-title-project">
                    {client && 'Clients'}
                    {skill && 'Compétences'}
                    {excilyen && 'Intervenants'}
                    {sector && 'Secteurs d\'activité'}
                </Typography>
                <div data-testid="side-panel-projects-filter-container-select">
                    <Select
                        onChange= {this.handleChange}
                        className={className}
                        classNamePrefix="select"
                        placeholder={placeHolder}
                        styles={customStyles}
                        isClearable={true}
                        isSearchable={true}
                        name={name}
                        options={options}
                        isMulti={isMulti}
                        value={selectValue}
                        menuPosition="fixed"
                    />
                </div>
            </div>
        );
    }
}

SidePanelProjectsFilterContainer.propTypes = {
    client: PropTypes.bool,
    skill: PropTypes.bool,
    excilyen: PropTypes.bool,
    sector: PropTypes.bool,

    clients: PropTypes.array,
    skills: PropTypes.array,
    excilyens: PropTypes.array,
    sectors: PropTypes.array,

    valueClient: PropTypes.object,
    valueSkill: PropTypes.array,
    valueUser: PropTypes.array,
    valueSector: PropTypes.object,

    setProjectsClientFilter: PropTypes.func,
    setProjetsSkillsFilter: PropTypes.func,
    setProjectsExcilyenFilter: PropTypes.func,
    setProjectsSectorFilter: PropTypes.func
};

function mapStateToProps(state) {
    return {
        clients: selectAllClients(state),
        skills: selectAllSkills(state).data,
        excilyens: selectAllUser(state),
        sectors: selectAllSectors(state),
        valueClient: selectProjectFilterClient(state),
        valueSkill: selectProjectFilterSkill(state),
        valueUser: selectProjectFilterUser(state),
        valueSector: selectProjectFilterSector(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setProjectsClientFilter: (filter) => dispatch(setProjectsClientFilter(filter)),
        setProjetsSkillsFilter: (filter) => dispatch(setProjetsSkillsFilter(filter)),
        setProjectsExcilyenFilter: (filter) => dispatch(setProjectsExcilyenFilter(filter)),
        setProjectsSectorFilter: (filter) => dispatch(setProjectsSectorFilter(filter))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelProjectsFilterContainer);

