import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import './ExcilyenCard.scss';
import ReactStars from 'react-stars';
import { Icon, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RequestCoachButton from '../profile/RequestCoachButton';
import noPhoto from 'assets/images/no-photo.png';
import AddToGroupButton from '../profile/AddToGroupButton';

function ExcilyenCard(props) {

    const [expertLevel, setExpertLevel] = React.useState(0);
    const [moderator, setModerator] = React.useState(false);

    const { excilyen, skill } = props;

    useEffect(() => {
        if(skill && (excilyen.skillLevelApiDtos || excilyen.skillDetailedApiDtos)) {
            const skillInfosFound = excilyen.skillLevelApiDtos
                ? excilyen.skillLevelApiDtos.find((skillLevel) => skillLevel.skillId === skill.id)
                : excilyen.skillDetailedApiDtos.find((skillDetailed) => skillDetailed.id === skill.id);
            if(skillInfosFound) {
                setExpertLevel(skillInfosFound.userLevel);
                setModerator(skillInfosFound.moderator);
            }
        }
    }, []);

    return (
        <div className="excilyen-card" data-testid="excilyen-card">
            <Link to={`/excilyens/${excilyen.id}`} className="expert-card-link" >
                <img
                    alt="MyNeuroFactory expert"
                    className="expert-card-image"
                    src={excilyen.imageUrl ? excilyen.imageUrl : noPhoto}
                    referrerPolicy="no-referrer"
                />
                <div className="name-excilyen-card">
                    <Typography gutterBottom variant="subtitle1" component="h3" noWrap>
                        {`${excilyen.firstName} ${excilyen.lastName}`}
                        {moderator &&
                            <Tooltip title="Modérateur">
                                <Icon id="icon-moderator" className="icon">verified_user</Icon>
                            </Tooltip>
                        }
                    </Typography>
                </div>
                {skill
                    ? <div data-testid="stars">
                        <ReactStars className="stars-submit-difficulty"
                            count={5}
                            size={15}
                            color2={'#ffd700'}
                            half={false}
                            edit={false}
                            value={expertLevel} />
                    </div>
                    : <Typography variant="subtitle2" color="textSecondary" component="p" data-testid="promo">
                        {excilyen.promo}
                    </Typography>
                }
            </Link>
            { props.isForGroupView
                ? <AddToGroupButton
                    addToGroup={props.addToGroup}
                    student={excilyen}
                    group={props.group}
                    isAlreadyInGroup={props.isAlreadyInGroup}
                    userId={props.userId}
                    dispatchPersonGroup={props.dispatchPersonGroup}
                />
                : <RequestCoachButton
                    coachId={excilyen.id}
                    isAlreadyAskedAsCoach={props.isAlreadyAskedAsCoach}
                    isAlreadyCoach={props.isAlreadyCoach}/>
            }
        </div>
    );
}

ExcilyenCard.propTypes = {
    addToGroup: PropTypes.string,
    excilyen: PropTypes.object,
    skill: PropTypes.object,
    isAlreadyAskedAsCoach: PropTypes.bool,
    isAlreadyCoach: PropTypes.bool,
    isForGroupView: PropTypes.bool,
    group: PropTypes.object,
    isAlreadyInGroup: PropTypes.bool,
    userId: PropTypes.number,

    dispatchPersonGroup: PropTypes.func
};

export default ExcilyenCard;
