import React from 'react';
import PropTypes from 'prop-types';

import AddChapterResourceForm from '../AddChapterResourceForm/AddChapterResourceForm';

import {
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';

function AddChapterResourceDialog(props) {

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={props.isOpen}
            onClose={props.onClose}
        >
            <DialogTitle>
                Ajouter une ressource pour le chapitre: {props.chapterName}
            </DialogTitle>
            <DialogContent dividers>
                <AddChapterResourceForm
                    chapterId={props.chapterId}
                    chapterName={props.chapterName}
                    onCancel={props.onClose}
                />
            </DialogContent>
        </Dialog>
    );
}

AddChapterResourceDialog.propTypes = {
    chapterId: PropTypes.number,
    chapterName: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default AddChapterResourceDialog;
