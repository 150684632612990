import { GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_SUCCESS } from 'app/redux/actions/ChapterValidation/ChapterValidation.actions';

export const chapterValidation = 'chapterValidation';

export default function chapterValidationReducer(state = {
    [chapterValidation]: []
}, action) {
    switch (action.type) {
        case GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_SUCCESS:
            return {
                ...state,
                [chapterValidation]: action.payload
            };
        default:
            return state;
    }
}
