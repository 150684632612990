import React from 'react';
import PropTypes from 'prop-types';
import { MultipleChoiceQuestionForm } from './MultipleChoiceQuestionForm';
import { QuestionATrouForm } from './QuestionATrou/QuestionATrouForm';
import { executeCallbackOnQuestionType } from '../CreateEditQuestionForm.functions';
import { QuestionOuverteForm } from './QuestionOuverte/QuestionOuverteForm';

export function CreateEditQuestionBody(props) {

    const mcqForm = () => <MultipleChoiceQuestionForm
        data-testid="multiple-choice-question-form"
        mcq={props.question}
        setMcq={props.setQuestion}
    />;
    const questionATrouForm = () => <QuestionATrouForm
        data-testid="question-texte-a-trou-form"
        questionATrou={props.question}
        setQuestionATrou={props.setQuestion}
    />;
    const questionOuverteForm = () => <QuestionOuverteForm
        data-testid="question-ouverte-form"
        questionOuverte={props.question}
        setQuestionOuverte={props.setQuestion}
    />;

    return executeCallbackOnQuestionType(
        props.question.class,
        {
            mcqCallback: mcqForm,
            questionATrouCallback: questionATrouForm,
            questionOuverteCallback: questionOuverteForm
        }
    );
}

CreateEditQuestionBody.propTypes = {
    question: PropTypes.object.isRequired,
    setQuestion: PropTypes.func.isRequired
};
