import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../skill/components/SkillContent/Chapter/ChapterReportingButton/ChapterReportingButton.scss';

import ReportIcon from '@material-ui/icons/Report';
import {
    RadioGroup, Radio,
    BottomNavigation, BottomNavigationAction,
    Button, Dialog, DialogContent, DialogTitle,
    IconButton, TextField, FormLabel, makeStyles, FormControl,
    FormControlLabel,
    Tooltip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { postMicroskillReporting } from 'app/redux/actions/Microskill/Reporting/microskillReporting.actions';

const useStyles = makeStyles(() => ({
    button: {
        marginLeft: 'auto'
    },
    textFieldContainerWithOption: {
        width: '50%'
    },
    textFieldContainerWithoutOption: {
        width: '100%'
    }
}));

export default function MicroskillReportingButton(props) {

    const dispatch = useDispatch();

    const classes = useStyles();

    const errorEnum = {
        INVALID: 0,
        BAD_DISPLAY: 1,
        OTHER: 2
    };

    const errorType = [
        'Cours non valide',
        'Cours mal affiché',
        'Autre problème'
    ];

    const microskillInvalidOption = [
        'la formulation du cours',
        'le code lié au cours'
    ];

    const microskillDisplayedOption = [
        'Problème dans l\'affichage du cours',
        'Problème dans l\'affichage du code dans le cours',
        'Faute d\'orthographe dans le cours'
    ];

    const currentUser = useSelector(selectCurrentUser);

    const [isOpen, setIsOpen] = useState(false);
    const [errorValue, setErrorValue] = useState(null);
    const [optionSelected, setOptionSelected] = useState(null);
    const [commentary, setCommentary] = useState('');

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setErrorValue(null);
        setOptionSelected(null);
        setCommentary('');
    };

    const handleChangeErrorType = (event, newValue) => {
        setErrorValue(newValue);
        setOptionSelected(null);
    };

    const handleChangeRadio = (event) => {
        setOptionSelected(event.currentTarget.value);
    };

    const handleChangeCommentary = (event) => {
        setCommentary(event.target.value);
    };

    const validReport = () => {
        let description = null;
        if(optionSelected) {
            if(errorValue === errorEnum.INVALID) {
                description = `Erreur dans ${optionSelected}`;
            } else {
                description = optionSelected;
            }
        }
        if(commentary) {
            if(description) {
                description += `\n${commentary.trim()}`;
            } else {
                description = commentary.trim();
            }
        }
        const microskillReporting = {
            description,
            microskillId: props.microskillId,
            reporterId: currentUser.id,
            status: 'PENDING'
        };
        dispatch(postMicroskillReporting(microskillReporting));
        handleClose();
    };

    const getOptions = () => {
        switch (errorValue) {
            case errorEnum.INVALID:
                return microskillInvalidOption;
            case errorEnum.BAD_DISPLAY:
                return microskillDisplayedOption;
            default:
                return [];
        }
    };

    const isValidCommentaryForOtherError = () => (
        commentary && commentary.trim()
    );

    const isValidCommentaryForOption = () => (
        !commentary || commentary.trim()
    );

    const isDisabled = () => (
        (errorValue !== errorEnum.OTHER &&
            (optionSelected === null || !isValidCommentaryForOption())) ||
        (errorValue === errorEnum.OTHER && !isValidCommentaryForOtherError())
    );

    return (
        <>
            <Tooltip title="Signaler un problème">
                <IconButton
                    className="reporting-button"
                    aria-label="reporting"
                    color="secondary"
                    onClick={handleOpen}
                >
                    <ReportIcon fontSize="large"/>
                </IconButton>
            </Tooltip>
            <Dialog
                className="reporting-dialog"
                maxWidth="md"
                fullWidth
                fullScreen={false}
                onClose={handleClose}
                aria-labelledby="simple-dialog-reporting"
                open={isOpen}
                scroll={'body'}
                PaperProps={{ style: { overflowY: 'visible' } }}
            >
                <DialogTitle
                    id="simple-dialog-title">
                    Reporter un problème
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{ overflowY: 'visible' }}
                >
                    <BottomNavigation
                        value={errorValue}
                        showLabels
                        onChange={handleChangeErrorType}
                        aria-labelledby="testError"
                    >
                        {errorType.map((error, index) => (
                            <BottomNavigationAction
                                key={index}
                                label={error}
                                icon={<ReportIcon />}
                            />
                        ))}
                    </BottomNavigation>
                    {errorValue !== null &&
                        <div className="container" data-testid="microskill-reporting-commentary">
                            {errorValue !== errorEnum.OTHER &&
                                <FormControl className={classes.textFieldContainerWithOption}>
                                    {errorValue === errorEnum.INVALID &&
                                        <FormLabel>Erreur dans</FormLabel>
                                    }
                                    <RadioGroup
                                        value={optionSelected}
                                        onChange={handleChangeRadio}
                                    >
                                        {getOptions().map((option, index) => (
                                            <FormControlLabel
                                                key={index}
                                                label={option}
                                                value={option}
                                                control={<Radio color="primary"
                                                    aria-labelledby="optionError" />}
                                            />
                                        ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            }
                            <TextField
                                className={
                                    errorValue === errorEnum.OTHER
                                        ? classes.textFieldContainerWithoutOption
                                        : classes.textFieldContainerWithOption
                                }
                                fullWidth multiline
                                label="Commentaire"
                                helperText={
                                    `Commentaire ${errorValue === errorEnum.OTHER ? 'obligatoire' : 'optionnel'}`
                                }
                                variant="filled"
                                minRows="6"
                                onChange={handleChangeCommentary}
                            />
                        </div>
                    }
                    <div className="buttonDialogContainer">
                        <Button
                            color="primary"
                            onClick={validReport}
                            disabled={isDisabled()}
                            aria-label="valid"
                        >
                            Valider
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

MicroskillReportingButton.propTypes = {
    microskillId: PropTypes.number.isRequired,
    skillName: PropTypes.string.isRequired
};
