import { questionTypeEnum } from 'app/utils/QuestionTypeEnum';
import { executeCallbackOnQuestionType } from '../CreateEditQuestionForm.functions';
import { atLeastOneMcqAnswerValid, mcqAnswerIsValid } from './MultipleChoiceQuestionForm.functions';
import { atLeastOneQuestionATrouAnswer, questionATrouAnswerIsValid } from './QuestionATrou/QuestionATrou.functions';

// Statement Validity
export function statementExist(statement) {
    return statement?.trim().length > 0;
}

export function displayStatementHelperText(statement) {
    return statementExist(statement) ? '' : 'Requis. ';
}

export function displayStatementTooltips(statement) {
    const helperText = displayStatementHelperText(statement);
    return helperText ? 'Énoncé: '.concat(helperText) : '';
}

// Answer Validity

export function displayAnswerHelperText(answerTargeted, questionClass) {
    let helpText = '';
    const answerIsValid = executeCallbackOnQuestionType(
        questionClass,
        {
            mcqCallback: () => mcqAnswerIsValid(answerTargeted),
            questionATrouCallback: () => questionATrouAnswerIsValid(answerTargeted),
            questionOuverteCallback: () => true
        }
    );
    if(!answerIsValid) {
        helpText = executeCallbackOnQuestionType(
            questionClass,
            {
                mcqCallback: () => 'Requis. ',
                questionATrouCallback: () => 'Les champs doivent être remplis sans doublon. ',
                questionOuverteCallback: () => 'Aucune réponse requise. '
            }
        );
    }
    return helpText;
}

export function displayAnswserTooltips(answerTargeted, questionClass) {
    let helperText = displayAnswerHelperText(answerTargeted, questionClass);
    const answerLabel = executeCallbackOnQuestionType(questionClass, { mcqCallback: () => answerTargeted.label, questionATrouCallback: () => answerTargeted.ordinal, questionOuverteCallback: () => answerTargeted.answers });

    if(helperText) {
        helperText = 'Réponse '.concat(answerLabel, ': ', helperText);
    }
    return helperText;
}

// Global Answers Validity
const mcqMinimumNumberPossibilities = 2;
const qatMinimumNumberPossibilities = 1;

export const mcqHelperTextAtLeastOneAnswerValid = () => 'Il faut au minimum une bonne réponse.';
export const questionATrouHelperTextAtLeastOneAnswerValid = () => 'Il faut au minimum un trou.';
export const atLeastOneQuestionOuverteAnswerValid = () => 'Tout va bien.';

export function enoughAnswers(question) {
    switch (question.class) {
        case questionTypeEnum.MultipleChoiceQuestionType:
            return question?.answers?.length >= mcqMinimumNumberPossibilities;
        case questionTypeEnum.QuestionTexteATrouType:
            return question?.answers?.length >= qatMinimumNumberPossibilities;
        case questionTypeEnum.QuestionOuverteType:
            return true;
        default:
            // Should never happen
            return false;
    }
}

export function displayValidAnswersTooltips(question) {
    let helpText = '';
    const atLeastOneAnswerValid = executeCallbackOnQuestionType(
        question.class,
        {
            mcqCallback: () => atLeastOneMcqAnswerValid(question),
            questionATrouCallback: () => atLeastOneQuestionATrouAnswer(question),
            questionOuverteCallback: () => true
        }
    );
    if(!atLeastOneAnswerValid) {
        helpText = executeCallbackOnQuestionType(
            question.class,
            {
                mcqCallback: mcqHelperTextAtLeastOneAnswerValid,
                questionATrouCallback: questionATrouHelperTextAtLeastOneAnswerValid,
                questionOuverteCallback: atLeastOneQuestionOuverteAnswerValid
            }
        );
    }
    return helpText;
}
