
export function handleChangeEditor(editor, setQuestionOuverte) {
    setQuestionOuverte((prevQuesionOuverte) => ({
        ...prevQuesionOuverte,
        statement: editor.getData()
    }));
}

export function handleChangeExplanation(editor, setQuestionOuverte) {
    setQuestionOuverte((prevState) => ({
        ...prevState,
        explanation: editor.getData()
    }));
}
