import { IconButton } from '@material-ui/core';
import { React, useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import CoursesList from './CoursesList';


export default function CourseUnderStandardQuestion(props) {

    const [isCourseContentVisible, setCourseContentVisible] = useState(false);

    const toggleCoursesNames = () => {
        if(!isCourseContentVisible && !props.questionMicroskills) {
            props.displayCoursesNames(props.questionId, props.setQuestionMicroskills);
        }
        setCourseContentVisible(!isCourseContentVisible);
    };

    return (
        <div className={'message oxa course-under-question'}>
            <div className="header" />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span className="content bold" >
                    Afficher le cours
                </span>
                <IconButton color="primary" onClick={toggleCoursesNames}>
                    {isCourseContentVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>
            {isCourseContentVisible && props.questionMicroskills &&
                <>
                    <CoursesList 
                        questionMicroskills = {props.questionMicroskills}
                        setQuestionMicroskills = {props.setQuestionMicroskills} />
                </>
            }
        </div >
    );
}

CourseUnderStandardQuestion.propTypes = {
    questionId: PropTypes.number,
    questionMicroskills: PropTypes.array,
    setQuestionMicroskills: PropTypes.func,
    displayCoursesNames : PropTypes.func,
    className: PropTypes.string
};
