import axiosClient from './utils/axiosClient';

export const CHAPTER_REPORTING_URI = 'chapter/reporting';


export function postChapterReportingApi(chapterReporting) {
    return axiosClient.post(CHAPTER_REPORTING_URI, chapterReporting);
}

export function getChapterReportingByChapterIdApi(chapterId) {
    return axiosClient.get(`${CHAPTER_REPORTING_URI}/chapter/${chapterId}`);
}

export function getChapterReportingCountsBySkillIdApi(skillId) {
    return axiosClient.get(`${CHAPTER_REPORTING_URI}/skill/${skillId}`);
}

export function patchChapterTreatedReportingApi(userId, chapterId, reportingId, taskId) {
    let url = `${CHAPTER_REPORTING_URI}/reporting/${reportingId}/treated?userId=${userId}&chapterId=${chapterId}`;

    if(taskId !== null) {
        url += `&taskId=${taskId}`;
    }

    return axiosClient.patch(url);
}

export function patchChapterDeniedReportingApi(userId, chapterId, reportingId, taskId) {
    
    let url = `${CHAPTER_REPORTING_URI}/reporting/${reportingId}/denied?userId=${userId}&chapterId=${chapterId}`;

    if(taskId !== null) {
        url += `&taskId=${taskId}`;
    }

    return axiosClient.patch(url);
}
