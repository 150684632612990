import { questionTypeEnum } from 'app/utils/QuestionTypeEnum';
import { enoughAnswers, statementExist } from './body/CreateEditQuestionBody.functions';
import { atLeastOneMcqAnswerValid, mcqAnswerIsValid } from './body/MultipleChoiceQuestionForm.functions';
import { atLeastOneQuestionATrouAnswer, questionATrouAnswerIsValid } from './body/QuestionATrou/QuestionATrou.functions';
import { selectSkillIsValid } from './header/CreateEditQuestionHeader.functions';

// Find the greatestKey set among every answers of an MCQ
export function newGreatestKey(mcq) {
    if(mcq.answers.length >= 2) {
        return 1 + Math.max(...mcq.answers.map((answer) => isNaN(answer.key) ? -1 : answer.key));
    }
    return 0;
}

// Setup unique key for every answers in MCQ
export function initMcqKey(mcq) {
    const copy = { ...mcq };
    copy.answers.forEach((awnser) => {
        awnser.key = newGreatestKey(mcq);
    });
    return copy;
}

export function setMcqWithIdKey(mcq) {
    if(mcq.answers) {
        mcq.answers.forEach((elem) => {
            elem.key = elem.id;
        });
    }
    return mcq;
}

export function executeCallbackOnQuestionType(questionType, { mcqCallback, questionATrouCallback, questionOuverteCallback }) {
    switch (questionType) {
        case questionTypeEnum.MultipleChoiceQuestionType:
            return mcqCallback();
        case questionTypeEnum.QuestionTexteATrouType:
            return questionATrouCallback();
        case questionTypeEnum.QuestionOuverteType:
            return questionOuverteCallback();
        default:
            // Should never happen
            return mcqCallback();
    }
}

// Global Validity
export function formIsValid(question) {
    let atLeastOneAnswerValid = false;
    let answersAreValid = false;
    const mcqCallback = () => {
        atLeastOneAnswerValid = atLeastOneMcqAnswerValid(question);
        answersAreValid = question.answers.filter((answer) => !mcqAnswerIsValid(answer)).length === 0;
    };
    const questionATrouCallback = () => {
        atLeastOneAnswerValid = atLeastOneQuestionATrouAnswer(question);
        answersAreValid = question.answers.filter((answer) => !questionATrouAnswerIsValid(answer)).length === 0;
    };
    const questionOuverteCallback = () => {
        atLeastOneAnswerValid = () => true;
        answersAreValid = () => true;
    };
    executeCallbackOnQuestionType(question.class, { mcqCallback, questionATrouCallback, questionOuverteCallback });

    return answersAreValid &&
            atLeastOneAnswerValid &&
            enoughAnswers(question) &&
            statementExist(question.statement) &&
            selectSkillIsValid(question.skillId);
}

// Global Validity
export function questionIsValid(question) {
    return statementExist(question.statement) &&
            selectSkillIsValid(question.skillId);
}
