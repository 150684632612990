
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './AddEditMission.scss';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/withWidth';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import { MuiThemeProvider, Tooltip, Fab } from '@material-ui/core';
import Select from 'react-select';
import { buttonsTheme } from '../../Project/ProjectDetails/ProjectDetailsContent/ProjectDetailsContentTheme';
import { selectCurrentUser, selectAllUser } from '../../../../redux/selectors/User/user.selector';
import { addMission, editMission } from '../../../../redux/actions/Mission/MissionSet/missionSet.actions';
import { selectCurrentProject } from '../../../../redux/selectors/Project/projects.selector';
import RichTextEditor from '../../../../common/richTextEditor/RichTextEditor';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';


export class AddEditMission extends Component {

    constructor() {
        super();
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const initialState = {
            open: false,
            id: '',
            startDate: null,
            endDate: null,
            description: '',
            listSkill: [],
            listIntervenants: [],
            isIntervenantsEmpty: true,
            isSkillEmpty: true,
            isStartDateEmpty: true,
            areDatesIncoherent: false
        };
        return initialState;
    };

    openModal = () => {
        const listIntervenants = [];
        if(this.props.missionToEdit && !this.props.trueIfAddAndFalseIfEdit) {
            this.props.missionToEdit.listUser.forEach((intervenant) => {
                listIntervenants.push({ value: intervenant.id,
                    label: `${intervenant.firstName} ${intervenant.lastName}` });
            });

            const listSkill = [];
            this.props.missionToEdit.listSkill.forEach((elementListSkill) => {
                listSkill.push({ value: elementListSkill.id,
                    label: elementListSkill.skillName });
            });

            const startDate = this.props.missionToEdit.startDate ? this.props.missionToEdit.startDate.slice(0, 10) : null;
            const endDate = this.props.missionToEdit.endDate ? this.props.missionToEdit.endDate.slice(0, 10) : null;
            this.setState({
                open: true,
                id: this.props.missionToEdit.id,
                startDate,
                endDate,
                description: this.props.missionToEdit.description,
                listSkill,
                listIntervenants,
                isIntervenantsEmpty: listIntervenants.length === 0,
                isSkillEmpty: listSkill.length === 0,
                isStartDateEmpty: !this.props.missionToEdit.startDate,
                areDatesIncoherent: startDate !== null && endDate !== null && startDate > endDate
            });
        } else {
            listIntervenants.push({ value: this.props.user.id,
                label: `${this.props.user.lastName} ${this.props.user.firstName}` });
            this.setState({
                open: true,
                listIntervenants,
                isIntervenantsEmpty: false
            });
        }
    };

    closeModal = () => {
        this.setState(this.getInitialState());
    };

    intervenantToAdd = () => {
        const result = [];

        this.state.listIntervenants.forEach((intervenant) => {
            this.props.excilyens.forEach((excilyen) => {
                if(intervenant.value === excilyen.id) {
                    result.push(excilyen);
                }
            });
        });
        return result;
    };

    skillToAdd = () => {
        const result = [];

        this.state.listSkill.forEach((elementListSkill) => {
            this.props.allSkills.filter((skill) => skill.coveredByExcilys).forEach((elementAllSkills) => {
                if(elementListSkill.value === elementAllSkills.id) {
                    result.push(elementAllSkills);
                }
            });
        });
        return result;
    };

    validation = () => {
        if(!this.state.isIntervenantsEmpty && !this.state.isSkillEmpty && !this.state.isStartDateEmpty && !this.state.areDatesIncoherent) {
            const mission = {
                projectId: this.props.project.id,
                id: this.props.missionToEdit ? this.props.missionToEdit.id : 0,
                listUser: this.intervenantToAdd(),
                startDate: this.state.startDate ? new Date(this.state.startDate) : null,
                endDate: this.state.endDate ? new Date(this.state.endDate) : null,
                description: this.state.description,
                listSkill: this.skillToAdd()
            };

            if(this.props.trueIfAddAndFalseIfEdit) {
                this.props.addMission(mission);
            } else {
                this.props.editMission(mission);
            }
            this.closeModal();
        }
    };

    onChangeIntervenants = (newValue) => {
        let isIntervenantsEmpty = true;
        if(newValue && Object.keys(newValue).length !== 0) {
            isIntervenantsEmpty = false;
        }
        this.setState({
            listIntervenants: newValue,
            isIntervenantsEmpty
        });
    };

    onChangeCompetences = (newValue) => {
        let isSkillEmpty = true;
        if(newValue && Object.keys(newValue).length !== 0) {
            isSkillEmpty = false;
        }
        this.setState({
            listSkill: newValue,
            isSkillEmpty
        });
    };

    onChangeStartDate = (event) => {

        if(event.target.value) {

            const startDate = new Date(event.target.value);

            if(startDate && this.state.endDate && startDate > new Date(this.state.endDate)) {
                this.setState({
                    startDate,
                    areDatesIncoherent: true,
                    isStartDateEmpty: false
                });
            } else {
                this.setState({
                    startDate,
                    areDatesIncoherent: false,
                    isStartDateEmpty: false
                });
            }
        } else {
            this.setState({
                startDate: null,
                areDatesIncoherent: false,
                isStartDateEmpty: true
            });
        }
    };

    onChangeEndDate = (event) => {

        if(event.target.value) {

            const endDate = new Date(event.target.value);

            if(endDate && new Date(this.state.startDate) > endDate) {
                this.setState({
                    endDate,
                    areDatesIncoherent: true
                });
            } else {
                this.setState({
                    endDate,
                    areDatesIncoherent: false
                });
            }
        } else {
            this.setState({
                endDate: null,
                areDatesIncoherent: false
            });
        }
    };

    onChangeDescription = (event, editor) => {
        this.setState({
            description: editor.getData()
        });
    };

    render() {
        const listExcilyensOrderedByLastname = this.props.excilyens.sort((excilyenA, excilyenB) => (
            excilyenA.lastName.localeCompare(excilyenB.lastName)));
        const listAllUsers = listExcilyensOrderedByLastname.map((excilyen) => ({ value: excilyen.id,
            label: `${excilyen.lastName} ${excilyen.firstName}` }));

        const listAllSkill = this.props.allSkills.filter((skill) => skill.coveredByExcilys).map((skill) => ({ value: skill.id,
            label: skill.skillName }));
        return (
            <>
                {this.props.trueIfAddAndFalseIfEdit
                    ? <div className="button-add-resource">
                        <Tooltip title="Ajouter une mission">
                            <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={this.openModal}
                            >
                                <AddIcon/>
                            </Fab>
                        </Tooltip>
                    </div>
                    : <MuiThemeProvider theme={buttonsTheme}>
                        <Tooltip title={
                            this.props.user.roles &&
                            this.props.user.roles.some((role) => role.name === 'ADMIN') &&
                            !(this.props.missionToEdit.listUser.map((user) => user.email).includes(this.props.user.email))
                                ? ''
                                : 'Vous devez être admin ou le contributeur pour modifier cette mission'
                        }>
                            <span>
                                <Button className="button-edit" color="primary" onClick={this.openModal}
                                    disabled={this.props.user.roles && this.props.user.roles.some((role) => role.name === 'ADMIN')
                                        ? false
                                        : !(this.props.missionToEdit.listUser.map((user) => user.email).includes(this.props.user.email))}>
                                    <Icon>edit</Icon>
                                </Button>
                            </span>
                        </Tooltip>
                    </MuiThemeProvider>
                }

                <Dialog maxWidth="md" fullWidth={true} fullScreen={!isWidthUp('sm', this.props.width)}
                    disableEnforceFocus={true}
                    open={this.state.open} onClose={this.closeModal} aria-labelledby="form-dialog-title" >
                    <DialogTitle>
                        {this.props.trueIfAddAndFalseIfEdit ? 'Créer une nouvelle mission' : 'Modifier la mission'}</DialogTitle>
                    <DialogContent>
                        <Select
                            className="formElements"
                            onChange= {this.onChangeIntervenants}
                            placeholder={'Intervenants *'}
                            isClearable={false}
                            isSearchable={true}
                            backspaceRemovesValue={false}
                            name={'intervenants'}
                            options={listAllUsers}
                            isMulti={true}
                            value={this.state.listIntervenants}
                            maxMenuHeight={212}
                        />
                        <Select
                            className="formElements"
                            onChange= {this.onChangeCompetences}
                            placeholder={'Compétences *'}
                            isClearable={true}
                            isSearchable={true}
                            backspaceRemovesValue={false}
                            name={'competences'}
                            options={listAllSkill}
                            isMulti={true}
                            value={this.state.listSkill}
                            maxMenuHeight={156}
                        />
                        <TextField label="Date de début"
                            className="formElements"
                            type="date"
                            required
                            defaultValue={this.state.startDate}
                            fullWidth
                            onChange={this.onChangeStartDate}
                            error={this.state.isStartDateEmpty}
                            helperText={this.state.isStartDateEmpty ? 'La date de début est obligatoire' : ''}
                            InputLabelProps={{
                                shrink: true
                            }} />
                        <TextField label="Date de fin"
                            className="formElements"
                            type="date"
                            defaultValue={this.state.endDate}
                            fullWidth
                            onChange={this.onChangeEndDate}
                            error={this.state.areDatesIncoherent}
                            helperText={this.state.areDatesIncoherent ? 'La date de fin ne peut être antérieure à la date de début' : ''}
                            InputLabelProps={{
                                shrink: true
                            }} />
                        <RichTextEditor data={this.state.description} onChange={this.onChangeDescription}/>
                    </DialogContent>

                    <DialogActions>
                        <Button className="cancel-button" onClick={this.closeModal} color="primary">
                            Annuler
                        </Button>
                        <Button className="add-edit-submit-button" onClick={this.validation}
                            disabled={
                                this.state.isIntervenantsEmpty ||
                                this.state.isSkillEmpty ||
                                this.state.isStartDateEmpty ||
                                this.state.areDatesIncoherent}
                            color="primary">

                            {this.props.trueIfAddAndFalseIfEdit ? 'Ajouter' : 'Modifier'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

AddEditMission.propTypes = {
    width: PropTypes.string,

    trueIfAddAndFalseIfEdit: PropTypes.bool,
    missionToEdit: PropTypes.object,

    user: PropTypes.object,
    excilyens: PropTypes.array,
    allSkills: PropTypes.array,
    project: PropTypes.object,
    addMission: PropTypes.func,
    editMission: PropTypes.func
};

function mapStateToProps(state) {
    return {
        user: selectCurrentUser(state),
        excilyens: selectAllUser(state),
        allSkills: selectAllSkills(state).data,
        project: selectCurrentProject(state).data
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addMission: (project) => dispatch(addMission(project)),
        editMission: (project) => dispatch(editMission(project))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(AddEditMission));
