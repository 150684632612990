import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './Statistics.scss';
import { getCurrentUserById, GET_CURRENT_USER } from '../redux/actions/User/user.actions';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../redux/selectors/User/user.selector';
import { createLoadingSelectorForStoreValue } from '../redux/selectors/APISelector/APISelector';
import LastConnectionTable from './LastConnectionTable';
import SkillStatsTable from './SkillStatsTable';
import NbExcilyensChart from './NbExcilyensChart';
import Grid from '@material-ui/core/Grid';
import { getAllExcilyenSet } from '../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import SkillPerExcilyen from './SkillPerExcilyen';
import SkillNumberByFamilies from './SkillNumberByFamilies';
import { notificationError } from '../redux/actions/Notification/notifications.actions';
import { REDIRECT_EXCILYENS_ACCESS } from '../Snackbar/NotificationMessages';

export class StatisticsContent extends Component {

    state = {
        redirect: false
    };

    componentDidMount() {
        this.props.getCurrentUserById();
        this.props.getAllExcilyenSet();
    }

    componentDidUpdate(prevProps) {
        if(!this.props.isFetching && this.props.isFetching !== prevProps.isFetching) {
            this.props.getAllExcilyenSet();
            this.isUserAdmin();
        }
    }

    isUserAdmin() {
        const { user } = this.props;
        if(!(user && user.roles && user.roles.some((role) => role.name === 'ADMIN'))) {
            this.props.notificationError();
            this.setState({ redirect: true });
        }
    }

    render() {
        return (
            <>
                {this.state.redirect ? <Redirect to={'/skills'}/> : <></>}
                {!this.props.isFetching &&
                <div className="statistics-content">
                    <Grid container >
                        <Grid item xs={12} className="title">
                                Statistiques
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="table">
                                <LastConnectionTable/>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="table">
                                <SkillStatsTable/>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="table">
                                <NbExcilyensChart/>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="table">
                                <SkillPerExcilyen/>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="table">
                                <SkillNumberByFamilies/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                }
            </>
        );
    }
}

StatisticsContent.propTypes = {
    user: PropTypes.object,
    isFetching: PropTypes.bool,
    getCurrentUserById: PropTypes.func,
    getAllExcilyenSet: PropTypes.func,
    notificationError: PropTypes.func
};

function mapStateToProps(state) {
    return {
        user: selectCurrentUser(state),
        isFetching: createLoadingSelectorForStoreValue([GET_CURRENT_USER], selectCurrentUser)(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getCurrentUserById: () => dispatch(getCurrentUserById()),
        getAllExcilyenSet: () => dispatch(getAllExcilyenSet()),
        notificationError: () => dispatch(notificationError(REDIRECT_EXCILYENS_ACCESS))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsContent);
