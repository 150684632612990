import {
    GET_ALL_SKILLS_STATS_SUCCESS,
    GET_SKILL_REQUEST, GET_SKILL_SUCCESS,
    RESET_CURRENT_SKILL
} from '../../../actions/Skill/SkillSet/skillSet.actions';

export const skillStore = 'currentSkill';
export const allSkillStatsStore = 'allSkillStatsStore';

export default function skillSetReducer(state = {
    [allSkillStatsStore]: [],
    [skillStore]: null
}, action) {
    switch (action.type) {
        case GET_ALL_SKILLS_STATS_SUCCESS:
            return {
                ...state,
                [allSkillStatsStore]: action.payload
            };
        case GET_SKILL_REQUEST:
            const skillName = state[skillStore] && state[skillStore].skillName;
            const requestedSkillName = action.payload.requestedSkillName || action.payload.eraseDataWhileLoading;
            const skill = skillName === requestedSkillName
                ? state[skillStore]
                : null;
            return {
                ...state,
                [skillStore]: skill
            };
        case GET_SKILL_SUCCESS:
            return {
                ...state,
                [skillStore]: action.payload
            };
        case RESET_CURRENT_SKILL:
            return {
                ...state,
                [skillStore]: null
            };
        default:
            return state;
    }
}
