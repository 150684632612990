import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Progress } from 'antd';
import { DescriptionPopup } from 'app/project/components/Popup/DescriptionPopup';
import { sliceTitle } from 'app/question/QuestionTable/QuestionTable.functions';
import QuestionDetailRequestByQuestionId from 'app/question/QuestionResolver/QuestionRequest/QuestionDetailRequestByQuestionId';
import { getAnswerHistoryGroupByTaskIdAndQuestionIdApi } from 'app/api/historyApi';

function GroupHistoriqueQuestionRow(props) {

    const [open, setOpen] = useState(false);
    const showMaxLetters = 44;

    const [imagesWithAnswersSelected, setImagesWithAnswersSelected] = useState(props.students?.map((user) => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        imageUrl: user.imageUrl,
        selectedAnswerIds: []
    })));

    useEffect(() => {
        if(open) {
            getAnswerHistoryGroupByTaskIdAndQuestionIdApi(props.taskId, props.row.questionId)
                .then(
                    (response) => {
                        const data = response.data;
                        setImagesWithAnswersSelected((prevImages) => 
                            prevImages.map((prevImage) => {
                                const selectedAnswerIds = [...prevImage.selectedAnswerIds];
                                data.forEach((studentWithAnswer) => {
                                    if(studentWithAnswer.studentId === prevImage.id) {
                                        selectedAnswerIds.push(studentWithAnswer.answerId);
                                    }
                                });
                                return {
                                    ...prevImage,
                                    selectedAnswerIds,
                                };
                            })
                        );
                    }
                );
        }
    }, [open]);

    const resultGlobal = () => Math.round(props.row.goodAnswers / (props.row.goodAnswers + props.row.badAnswers + props.row.questionsNotDone) * 100);

    const details = () => (
        <TableCell align="center">
            {(props.row.goodAnswers + props.row.badAnswers + props.row.questionsNotDone) || !props.taskId
                ? <Tooltip title={`${props.row.goodAnswers} correctes / ${
                    props.row.badAnswers} incorrectes/ ${
                    props.row.questionsNotDone} non faites`}>
                    <div>
                        <Progress
                            percent={(props.row.goodAnswers + props.row.badAnswers) /
                                    (props.row.goodAnswers + props.row.badAnswers + props.row.questionsNotDone) * 100}
                            success={{ percent: resultGlobal() }}
                            trailColor="#A9A9A9"
                            strokeColor="#FF0000"
                            showInfo={false}
                        />
                    </div>
                </Tooltip>
                : <div>Tache non commencée</div>
            }
        </TableCell>);

    const collapse = () => (
        <TableCell>
            {props.row.title &&
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                </IconButton>
            }
        </TableCell>
    );

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                { collapse() }
                <TableCell component={'th'} scope="row">
                    { props.row.title.length > showMaxLetters
                        ? <>
                            { sliceTitle(props.row.title, showMaxLetters) }
                            <DescriptionPopup description={ props.row.title }/>
                        </>
                        : props.row.title
                    }
                </TableCell>
                { details() }
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div aria-label="expandSerie">
                            <QuestionDetailRequestByQuestionId
                                questionId={props.row.questionId}
                                imagesWithAnswersSelected={imagesWithAnswersSelected}
                            />
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

GroupHistoriqueQuestionRow.propTypes = {
    row: PropTypes.object.isRequired,
    studentId: PropTypes.number,
    taskId: PropTypes.number,
    students: PropTypes.array
};

export default GroupHistoriqueQuestionRow;
