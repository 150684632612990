import React from 'react';
import PropTypes from 'prop-types';

import DialogBase from './DialogBase';
import { Button } from '@material-ui/core';

export default function SimpleDialog(props) {

    const {
        confirmLabel,
        handleConfirm,
        cancelLabel,
        handleCancel,
        ...other
    } = props;

    return (
        <DialogBase
            {...other}
            actions={
                <>
                    <Button onClick={handleConfirm}>
                        {confirmLabel || 'Valider'}
                    </Button>
                    <Button onClick={handleCancel}>
                        {cancelLabel || 'Annuler'}
                    </Button>
                </>
            }
        />
    );
}

SimpleDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    confirmLabel: PropTypes.string,
    handleConfirm: PropTypes.func.isRequired,
    cancelLabel: PropTypes.string,
    handleCancel: PropTypes.func,
    dialogProps: PropTypes.shape({
        root: PropTypes.object,
        title: PropTypes.object,
        content: PropTypes.object,
        text: PropTypes.object,
        actions: PropTypes.object
    })
};

