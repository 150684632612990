import {
    GET_ALL_EXCILYENS_SUCCESS,
    GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_SUCCESS,
    GET_EXCILYEN_REQUEST,
    GET_EXCILYEN_SUCCESS,
    GET_DETAILED_EXCILYENS_SKILLS_SUCCESS,
    GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_SUCCESS
} from '../../../actions/Excilyen/ExcilyenSet/excilyenSet.actions';

export const excilyenStore = 'excilyen';
export const excilyensSetBySkillStore = 'excilyensSetBySkillStore';
export const allExcilyensSetStore = 'allExcilyensSetStore';
export const detailedExcilyensSkillsStore = 'detailedExcilyensSkillsStore';


export default function excilyenReducer(state = {
    [excilyenStore]: null,
    [excilyensSetBySkillStore]: [],
    [allExcilyensSetStore]: [],
    [detailedExcilyensSkillsStore]: []
}, action) {
    switch (action.type) {
        case GET_EXCILYEN_REQUEST:
            return {
                ...state,
                [excilyenStore]: null
            };
        case GET_EXCILYEN_SUCCESS:
            return {
                ...state,
                [excilyenStore]: action.payload
            };
        case GET_ALL_EXCILYENS_SUCCESS:
            return {
                ...state,
                [allExcilyensSetStore]: action.payload
            };
        case GET_DETAILED_EXCILYENS_SKILLS_SUCCESS:
            return {
                ...state,
                [detailedExcilyensSkillsStore]: action.payload
            };
        case GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_SUCCESS:
            return {
                ...state,
                [excilyensSetBySkillStore]: action.payload
            };
        case GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_SUCCESS:
            return {
                ...state,
                [excilyensSetBySkillStore]: action.payload
            };
        default:
            return state;
    }
}
