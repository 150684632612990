import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../SidePanel.scss';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SidePanelFilterContainer from './SidePanelSkillFilterContainer';
import Hidden from '@material-ui/core/Hidden/Hidden';
import { setCategoryFilter, setFamilyFilter, setSearchFilter } from '../../../redux/actions/Filter/filter.actions';
import Search from '../../../common/search/Search';
import { Typography } from '@material-ui/core';
import {
    selectSkillsFilteredByFamiliesAndCategoriesAndByName,
    selectSearchString
} from '../../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { smallScreenBreakpoints } from 'app/utils/smallScreenBreakpoints';


export class SidePanelSkill extends Component {

    render() {
        const { search, number } = this.props;
        return (
            <Grid container spacing={1} className="side-panel" data-testid="side-panel-skill">
                <Grid item container spacing={1} className="top">
                    <Grid item xs={12}>
                        <Typography className="sub-title-numbers"> {number} Compétences</Typography>
                        <span className="title">Filtres</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span className="reset-filters-button" onClick={this.props.resetFilters} data-testid="side-panel-skill-reset">
                        Réinitialiser les filtres
                        </span>
                    </Grid>
                    <Hidden only={smallScreenBreakpoints}>
                        <Grid item xs={12}>
                            <Search value={search} onSearchSubmit={this.props.setSearchFilter} />
                        </Grid>
                    </Hidden>
                </Grid>
                <div className="filters">
                    <Grid item xs={12}>
                        <SidePanelFilterContainer family/>
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelFilterContainer category/>
                    </Grid>
                </div>
            </Grid>
        );
    }
}

SidePanelSkill.propTypes = {
    resetFilters: PropTypes.func,
    setSearchFilter: PropTypes.func,
    search: PropTypes.string,
    number: PropTypes.number
};

function mapStateToProps(state) {
    return {
        search: selectSearchString(state),
        number: selectSkillsFilteredByFamiliesAndCategoriesAndByName(state).length
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetFilters: () => {
            dispatch(setSearchFilter(''));
            dispatch(setCategoryFilter([]));
            dispatch(setFamilyFilter([]));
        },
        setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelSkill);
