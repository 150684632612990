import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { IconButton, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import { FilterList, Search } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    menuItem: {
        maxHeight: '24px',
        padding: '0 16px'
    },
    paddingLeftCrop: {
        paddingLeft: 0
    }
}));

function FilterSearchMenu(props) {

    const classes = useStyles();

    const [anchor, setAnchor] = useState(null);

    const handleClose = () => {
        setAnchor(null);
    };

    const handleOpen = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleMenuFilter = () => {
        handleClose();
        props.toggleOpenLeftDrawer();
    };

    const handleMenuSearch = () => {
        handleClose();
        props.handleDisplaySearchChange();
    };

    return (
        <>
            <IconButton className="more-options-button" color="inherit"
                aria-label="Menu"
                onClick={handleOpen}
                data-testid="test-open">
                <MoreVertIcon />
            </IconButton>
            <Menu className="menu"
                open={anchor !== null}
                anchorEl={anchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onClose={handleClose}
            >
                <MenuItem
                    className={classes.menuItem}
                    onClick={handleMenuFilter}
                    data-testid="test-reset"
                >
                    <IconButton color="inherit" className={classes.paddingLeftCrop}>
                        <FilterList />
                    </IconButton>
                    Filtrer
                </MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    onClick={handleMenuSearch}
                    data-testid="test-disconnect"
                >
                    <IconButton color="inherit" className={classes.paddingLeftCrop}>
                        <Search />
                    </IconButton>
                    Rechercher
                </MenuItem>
            </Menu>
        </>

    );
}

FilterSearchMenu.propTypes = {
    toggleOpenLeftDrawer: PropTypes.func,
    handleDisplaySearchChange: PropTypes.func
};


export default FilterSearchMenu;
