import axiosClient from './utils/axiosClient';

export const CLIENT_URI = 'clients';

export function getAllClientsApi() {
    return axiosClient.get(CLIENT_URI);
}

export function getClientByIdApi(id) {
    return axiosClient.get(`${CLIENT_URI}/${id}`);
}
