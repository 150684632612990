import React from 'react';
import QuestionEditor from 'app/common/richTextEditor/QuestionEditor';
import PropTypes from 'prop-types';
import { handleChangeEditor, handleChangeExplanation } from './QuestionOuverteForm.functions';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';

export function QuestionOuverteForm({ questionOuverte, setQuestionOuverte }) {

    const placeholderConfigExplanation = 'Insérez l\'explication';
    const placeholderConfigUrlVideo = 'Insérer l\'url de la vidéo';

    const useStyles = makeStyles(() => ({
        icon: {
            color: 'red',
            fontSize: '2rem'
        },
        textField: {
            backgroundColor: 'white',
            marginBottom: '1%'
        }
    }));
    const classes = useStyles();


    /*
     * Attention, si jamais setQuestionOuverte change lors de la vie du composant, ce sera pas prit en compte ici.
     * Si il y en a besoin, il faudra modifier questionouverte.js dans le ckeditor pour qu'il prenne setQuestionOuverte en param
     */
    return (
        <div data-testid="question-ouverte-form">
            <TextField className={classes.textField}
                label="Url de vidéo"
                fullWidth sx={{ lg: 1 }} variant="outlined"
                value={questionOuverte && questionOuverte.urlVideo}
                InputProps={{ startAdornment:
                    <InputAdornment position="start">
                        <YouTubeIcon className={classes.icon}/>
                    </InputAdornment> }}
                onChange={(_event) => setQuestionOuverte({ ...questionOuverte, urlVideo: _event.target.value })}
                placeholder={placeholderConfigUrlVideo}
            />

            <QuestionEditor
                aria-label="question-ouverte-statement-editor"
                className="statementQuestionEditor"
                data={questionOuverte.statement}
                onChange={(_event, editor) => handleChangeEditor(editor, setQuestionOuverte)}
                placeholder={ 'Insérez l\'énoncé' }
            />

            <QuestionEditor
                className="explanationEditor"
                data={questionOuverte.explanation}
                onChange={(_event, editor) => handleChangeExplanation(editor, setQuestionOuverte)}
                placeholder={placeholderConfigExplanation}
            />
        </div>
    );
}

QuestionOuverteForm.propTypes = {
    questionOuverte: PropTypes.object.isRequired,
    setQuestionOuverte: PropTypes.func.isRequired
};
