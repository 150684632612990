import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip, IconButton } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';

import { selectSkillLevelOfCurrentUser, selectIsSkillFavoriteOfCurrentUser } from 'app/redux/selectors/User/user.selector';
import { addUserSkill, patchUserSkillFavorite, removeUserSkill } from 'app/redux/actions/User/UserSkills/userSkill.actions';

function SkillFavoriteButton({ userId, skillId, dataTestId = 'skill-favorite-test-id' }) {
    const dispatch = useDispatch();
    const currentSkillFavoriteState = useSelector((state) => selectIsSkillFavoriteOfCurrentUser(state, skillId));
    const currentSkillUserLevel = useSelector((state) => selectSkillLevelOfCurrentUser(state, skillId));

    const handleClick = () => {
        if(currentSkillFavoriteState === undefined) {
            dispatch(addUserSkill({
                userId,
                skillId,
                favorite: true
            }));
        } else if(currentSkillFavoriteState === true && !currentSkillUserLevel > 0) {
            dispatch(removeUserSkill({ userId, skillId }));
        } else {
            dispatch(patchUserSkillFavorite(userId, skillId, { favorite: !currentSkillFavoriteState }));
        }
    };

    return (
        <div data-testid={dataTestId}>
            <Tooltip title={currentSkillFavoriteState ? 'Retirer des favoris' : 'Ajouter aux favoris'}>
                <IconButton style={{ padding: 4 }} onClick={handleClick} color="secondary">
                    {currentSkillFavoriteState ? <Favorite /> : <FavoriteBorder />}
                </IconButton>
            </Tooltip>
        </div>

    );
}

SkillFavoriteButton.propTypes = {
    userId: PropTypes.number.isRequired,
    skillId: PropTypes.number.isRequired,
    dataTestId: PropTypes.string
};

export default SkillFavoriteButton;
