import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import CoachingHistory from '../CoachingDisplay/CoachingHistory';
import CoachingGroup from '../CoachingDisplay/CoachingGroup/CoachingGroup';
import './HistoryView.scss';
import CoachingMenu from '../CoachingMenu/CoachingMenu';

export default function HistoryView(props) {

    const [isStudent, setIsStudent] = useState(true);
    const [displayedStudent, setDisplayedStudent] = useState();

    return (
        <div className="historyView">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <CoachingMenu onDisplayHistorySelected={setDisplayedStudent} setIsStudent={setIsStudent} groupId={props.groupId}/>
                </Grid>
                <Grid item xs={12} md={8}>
                    {isStudent
                        ? <CoachingHistory displayedStudent = {displayedStudent} />
                        : <CoachingGroup />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

HistoryView.propTypes = {
    groupId: PropTypes.number
};
