import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CoachingContent.scss';

import { SpeedDial, SpeedDialIcon } from '@material-ui/lab';

import { useStyles } from './UseStyles';
import HistoryView from './HistoryView/HistoryView';
import StatisticsView from './StatisticsView/StatisticsView';

const actionEnum = {
    PARCOURS_VIEW: 'Parcours',
    HISTORY_VIEW: 'Historique',
    STATISTICS_VIEW: 'Statistiques'
};

export default function CoachingContent(props) {

    const classes = useStyles();

    const [elementToDisplay, setElementToDisplay] = useState(actionEnum.HISTORY_VIEW);

    const switchView = () => {
        if(elementToDisplay === actionEnum.HISTORY_VIEW) {
            setElementToDisplay(actionEnum.STATISTICS_VIEW);
        } else {
            setElementToDisplay(actionEnum.HISTORY_VIEW);
        }
    };

    const showContent = () => {
        switch (elementToDisplay) {
            case actionEnum.HISTORY_VIEW:
                return <HistoryView groupId={props.groupId}/>;
            case actionEnum.STATISTICS_VIEW:
            default:
                return <StatisticsView/>;
        }
    };

    return (
        <>
            {showContent()}
            <SpeedDial
                ariaLabel="SpeedDial coaching view"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                onClick={switchView}
            />
        </>
    );
}

CoachingContent.propTypes = {
    groupId: PropTypes.number
};
