import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExcilyensContent from './ExcilyensContent';
import { withRouter } from 'react-router-dom';
import './Excilyens.scss';

export class Excilyens extends Component {

    render() {
        return (
            <section className="excilyen-section" data-testid="excilyens">
                <ExcilyensContent/>
            </section>
        );
    }
}

Excilyens.propTypes = {
    match: PropTypes.object
};

export default withRouter(Excilyens);
