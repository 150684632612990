import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { textATrouEditorConfig } from './TextATrouEditor.functions';

export default function TextATrouEditor(props) {

    return (
        <CKEditor
            editor={Editor}
            data={props.data ?? ''}
            onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                props.addEmitterCallback(editor);
                editor.fire('setupAnswers', props.answerList);
            } }
            config={{
                ...textATrouEditorConfig,
                placeholder: 'Insérez l\'énoncé',
                textATrou: {
                    refreshCallback: props.refreshCallback
                }
            }}
            onChange={props.onChange}
            cy-data="create-question-statement"
            {...props} />
    );
}

TextATrouEditor.propTypes = {
    data: PropTypes.string,
    answerList: PropTypes.object,

    onChange: PropTypes.func,
    addEmitterCallback: PropTypes.func,
    refreshCallback: PropTypes.func
};
