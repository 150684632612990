import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestionHistory } from 'app/redux/selectors/Question/history.selector';
import { getQuestionHistoryById } from 'app/redux/actions/Question/History/history.actions';
import { QuestionContainer } from '../QuestionResolverWithCourse/QuestionContainer/QuestionContainer';
import { setQuestionATrouParameters, setQuestionParameters } from '../../history/History.functions';
import { PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { isQuestionShuffled } from 'app/utils/QuestionUtils';
import QuestionOuverteReview from 'app/question/history/entretienHistory/QuestionOuverteReview';
import { QuestionEnum } from 'app/utils/QuestionEnum';

function QuestionRequestByQuestionHistoryId(props) {

    const dispatch = useDispatch();
    const questionHistory = useSelector(selectQuestionHistory);

    const [questionToDisplay, setQuestionToDisplay] = useState(null);

    useEffect(() => {
        dispatch(getQuestionHistoryById(props.questionHistoryId));
    }, [props.questionHistoryId]);

    useEffect(() => {
        const question = questionHistory.get(props.questionHistoryId);
        if(questionHistory && question) {
            if(question.question.class === QuestionEnum.TexteATrou) {
                setQuestionToDisplay(setQuestionATrouParameters(question).question);
            } else {
                setQuestionToDisplay({ ...setQuestionParameters(question).question,
                    fileUrl: question.fileUrl,
                    textAnswer: question.textAnswer,
                    reviewText: question.reviewText,
                    reviewGrade: question.grade,
                    questionStatus: question.result });
            }
        }
    }, [questionHistory]);

    useEffect(() => {
        dispatch({
            type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS,
            payload: questionToDisplay
        });
    }, [questionToDisplay]);

    return (
        <>
            {questionToDisplay &&
                <QuestionContainer
                    questionAsked={questionToDisplay}
                    disableAnswers
                    hideReturnButton
                    disableCourse
                    isInHistory
                    shuffleAnswers={isQuestionShuffled(questionToDisplay)}
                />
            }
            {questionToDisplay && props.isForCoach &&
                <QuestionOuverteReview
                    questionHistoryId={props.questionHistoryId}
                    reviewText={questionToDisplay.reviewText}
                    reviewGrade={questionToDisplay.reviewGrade}
                    refreshEntretiens={props.refreshEntretiens}
                />
            }
        </>
    );
}

QuestionRequestByQuestionHistoryId.propTypes = {
    questionHistoryId: PropTypes.number,
    isForCoach: PropTypes.bool,
    refreshEntretiens: PropTypes.func
};

export default QuestionRequestByQuestionHistoryId;
