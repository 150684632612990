import {
    parcoursCurrentStep,
    messages,
    inputText,
    parcoursId,
    beginButton,
    microskills
} from '../../reducers/Chat/chat.reducer';
import { chatStore } from '../../rootReducer';

export function selectCurrentStep(state) {
    return state[chatStore][parcoursCurrentStep];
}

export function selectMessages(state) {
    return state[chatStore][messages];
}

export function selectInputText(state) {
    return state[chatStore][inputText];
}

export function selectParcoursId(state) {
    return state[chatStore][parcoursId];
}

export function selectBeginButton(state) {
    return state[chatStore][beginButton];
}

export function selectMicroskills(state) {
    return state[chatStore][microskills];
}
