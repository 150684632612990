import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectCheckedPromos } from '../../../redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import { setPromoFilter } from '../../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import { Checkbox } from '@material-ui/core';
import { selectExcilyensNumberByPromo } from '../../../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import Chip from '@material-ui/core/Chip';

export class SidePanelExcilyensPromoEntry extends Component {

    changeHandler = (event) => {
        let filters = this.props.areChecked;
        filters = event.target.checked
            ? filters.concat(this.props.promo)
            : filters.filter((oldFilter) => oldFilter !== this.props.promo);
        this.props.setPromoFilter(filters);
    };

    render() {
        return (
            <div className="checkbox-container" data-testid="side-panel-excilyens-promo-entry">
                <label>
                    <Checkbox
                        color="default"
                        className="checkbox"
                        checked={this.props.isChecked}
                        onChange={this.changeHandler}
                        data-testid="side-panel-excilyens-promo-entry-checkbox"
                    />
                    <span>{this.props.promo}
                        <Chip size="small" style={{
                            marginLeft: '2px',
                            height: '15px'
                        }} label={this.props.number}/>
                    </span>
                </label>
            </div>
        );
    }
}

SidePanelExcilyensPromoEntry.propTypes = {
    promo: PropTypes.number.isRequired,
    areChecked: PropTypes.array,
    isChecked: PropTypes.bool,
    number: PropTypes.number,
    setPromoFilter: PropTypes.func
};

const mapStateToProps = (state, props) => ({
    areChecked: selectCheckedPromos(state),
    isChecked: selectCheckedPromos(state)
        .includes(props.promo),
    number: selectExcilyensNumberByPromo(props.promo)(state).length
});

export function mapDispatchToProps(dispatch) {
    return {
        setPromoFilter: (filters) => dispatch(setPromoFilter(filters))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelExcilyensPromoEntry);
