import { SET_PROJECTS_CLIENT_FILTER, SET_PROJECTS_SKILLS_FILTER, SET_PROJECTS_EXCILYENS_FILTER, SET_PROJECTS_SECTOR_FILTER }
    from '../../../actions/Project/ProjectFilters/projectFilters.actions';

export const clientFilterStore = 'client';
export const projectSkillFilterStore = 'skills';
export const excilyenStore = 'excilyen';
export const sectorFilterStore = 'sector';

export default function projectsFiltersReducer(
    state = { [clientFilterStore]: null,
        [projectSkillFilterStore]: [],
        [excilyenStore]: [],
        [sectorFilterStore]: null }
    , action
) {
    switch (action.type) {
        case SET_PROJECTS_EXCILYENS_FILTER:
            return { ...state,
                [excilyenStore]: action.payload };
        case SET_PROJECTS_SKILLS_FILTER:
            return { ...state,
                [projectSkillFilterStore]: action.payload };
        case SET_PROJECTS_CLIENT_FILTER:
            return { ...state,
                [clientFilterStore]: action.payload };
        case SET_PROJECTS_SECTOR_FILTER:
            return { ...state,
                [sectorFilterStore]: action.payload };
        default:
            return state;
    }
}
