import { notificationError } from '../../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_QUESTION_ERROR,
    RETRIEVE_QUESTION_ERROR_404,
    POST_ANSWER_RETRIEVE_QUESTION_ERROR
} from '../../../../../Snackbar/NotificationMessages';

import {
    getQuestionByIdWithoutExplanationApi,
    putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi,
    getQuestionByIdApi
} from '../../../../../api/multipleChoiceQuestionApi';

export const GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_REQUEST = 'GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_REQUEST';
export const GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_SUCCESS = 'GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_SUCCESS';
export const GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_FAILURE = 'GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_FAILURE';

export const DELETE_QUESTION_BY_ID = 'DELETE_QUESTION_BY_ID';

export const GET_QUESTION_TO_EDIT_BY_ID_REQUEST = 'GET_QUESTION_TO_EDIT_BY_ID_REQUEST';
export const GET_QUESTION_TO_EDIT_BY_ID_SUCCESS = 'GET_QUESTION_TO_EDIT_BY_ID_SUCCESS';
export const GET_QUESTION_TO_EDIT_BY_ID_FAILURE = 'GET_QUESTION_TO_EDIT_BY_ID_FAILURE';

export const PUT_ANSWER_RETRIEVE_QUESTION_BY_ID = 'PUT_ANSWER_RETRIEVE_QUESTION_BY_ID';
export const PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_REQUEST = 'PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_REQUEST';
export const PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS = 'PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS';
export const PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_FAILURE = 'PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_FAILURE';

export const RESET_MCQ_EDIT = 'RESET_MCQ_EDIT';
export const RESET_QUESTIONS_RESOLVE = 'RESET_QUESTIONS_RESOLVE';

export const getQuestionByIdWithoutExplanation = (id) => (dispatch) => {
    dispatch({ type: GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_REQUEST });

    return getQuestionByIdWithoutExplanationApi(id).then((response) => {
        dispatch({
            type: GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTION_WITHOUT_EXPLANATION_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR));
        }
    });
};
export const deleteQuestionById = (id) => (dispatch) => {
    dispatch({
        type: DELETE_QUESTION_BY_ID,
        payload: id
    });
};

export const getQuestionByIdEdit = (id) => (dispatch) => {
    dispatch({ type: GET_QUESTION_TO_EDIT_BY_ID_REQUEST });

    return getQuestionByIdApi(id).then((response) => {
        dispatch({
            type: GET_QUESTION_TO_EDIT_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTION_TO_EDIT_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR));
        }
    });
};

export const putAnswerRetrieveQuestionByIdWithExplanation = (answersSelected, candidateUuid) => (dispatch) => {
    dispatch({ type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_REQUEST });

    return putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi(answersSelected, candidateUuid).then((response) => {
        dispatch({
            type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(POST_ANSWER_RETRIEVE_QUESTION_ERROR));
        }
    });
};

export const resetQuestionToEdit = () => (dispatch) => {
    dispatch({ type: RESET_MCQ_EDIT });
};

export const resetQuestionsResolve = () => (dispatch) => {
    dispatch({ type: RESET_QUESTIONS_RESOLVE });
};
