import React, { useEffect, useState } from 'react';
import './AddSkill.scss';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import { Add } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import { ADD_SKILL, addSkill } from '../../redux/actions/Skill/SkillSet/skillSet.actions';
import PropTypes from 'prop-types';
import { AutoSuggestImpl } from '../../common/selects/AutoSuggestImpl';
import RichTextEditor from '../../common/richTextEditor/RichTextEditor';
import { isWidthUp } from '@material-ui/core/withWidth';
import withWidth from '@material-ui/core/withWidth/withWidth';
import Grid from '@material-ui/core/Grid';
import { createErrorMessageSelector, createLoadingSelector } from '../../redux/selectors/APISelector/APISelector';
import { selectCategories, selectFamilies, selectFamilyByName, selectCategoryByName }
    from '../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

export function AddSkill(props) {

    const [open, setOpen] = useState(false);
    const [skillName, setSkillName] = useState(null);
    const [familyName, setFamilyName] = useState('');
    const [familyNameOptional, setFamilyNameOptional] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [description, setDescription] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [disabledByName, setDisabledByName] = useState(true);
    const [coveredByExcilys, setCoveredByExcilys] = useState(true);

    const isEnabled = familyName && familyName.length > 0;

    const familiesName = props.families.map((familyInternalDto) => familyInternalDto.name);
    const categoriesName = props.categories.map((categoryInternalDto) => categoryInternalDto.name);

    const resetState = () => {
        setOpen(false);
        setSkillName(null);
        setFamilyName('');
        setFamilyNameOptional('');
        setCategoryName('');
        setDescription(null);
        setImageUrl(null);
        setDisabledByName(true);
        setCoveredByExcilys(true);
    };

    const closeModal = () => {
        resetState();
    };

    const openModal = () => {
        setOpen(true);
    };

    const submit = () => {
        const family = props.selectFamily(familyName)
            ? props.selectFamily(familyName)
            : familyName
                ? {
                    name: familyName
                }
                : null;
        const familyOpt = props.selectFamily(familyNameOptional)
            ? props.selectFamily(familyNameOptional)
            : familyNameOptional
                ? {
                    name: familyNameOptional
                }
                : null;
        const category = props.selectCategory(categoryName)
            ? props.selectCategory(categoryName)
            : categoryName
                ? {
                    name: categoryName
                }
                : null;

        const skill = {
            skillName,
            familyInternalDto: family,
            familyInternalDtoOptional: familyName ? familyOpt : null,
            categoryInternalDto: category,
            description,
            imageUrl,
            coveredByExcilys
        };
        props.addSkill(skill);
    };

    const onChangeSkillName = (event) => {
        let disabledByNameEvent = true;
        if(event.target.value !== '') {
            disabledByNameEvent = false;
        }
        setSkillName(event.target.value);
        setDisabledByName(disabledByNameEvent);
    };

    const onChangeDescription = (event, editor) => {
        setDescription(editor.getData());
    };

    const onChangeImageUrl = (event) => {
        setImageUrl(event.target.value || null);
    };

    useEffect(() => {
        if(!props.isFetching) {
            if(!props.error) {
                resetState();
                closeModal();
            }
        }
    }, [props.isFetching]);

    return (
        <div data-testid="add-skill">
            <Fab color="primary" aria-label="Add" className="add-button" onClick={openModal}
                id="add-button" data-testid="add-button">
                <Add/>
            </Fab>
            <Dialog disableEnforceFocus={true} maxWidth="md" fullWidth={true}
                fullScreen={!isWidthUp('sm', props.width)}
                open={open} onClose={closeModal}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ajouter une compétence</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12} data-testid="skill-name">
                            <TextField margin="dense" id="skillName" name="skillName" label="Nom" type="text"
                                required fullWidth
                                defaultValue={skillName} onChange={onChangeSkillName}/>
                        </Grid>
                        <Grid item xs={12} sm={4} data-testid="skill-family">
                            <AutoSuggestImpl
                                label="Famille"
                                id="skillFamily"
                                value={familyName}
                                suggestions={familiesName}
                                onChange={setFamilyName}/>
                        </Grid>
                        {isEnabled &&
                        <Grid item xs={12} sm={4} data-testid="skill-family-option">
                            <AutoSuggestImpl
                                label="Famille Optionnelle"
                                value={familyNameOptional}
                                suggestions={familiesName}
                                onChange={setFamilyName ? setFamilyNameOptional : null}/>
                        </Grid>
                        }

                        <Grid item xs={12} sm={4} data-testid="skill-category">
                            <AutoSuggestImpl
                                label="Catégorie"
                                value={categoryName}
                                suggestions={categoriesName}
                                onChange={setCategoryName}/>
                        </Grid>
                    </Grid>
                    <div className="rich-text-editor-add-skill" data-testid="skill-description">
                        <RichTextEditor onChange={onChangeDescription}/>
                    </div>

                    <TextField margin="dense" name="imageUrl" label="URL de l'image" type="text"
                        fullWidth defaultValue={imageUrl} onChange={onChangeImageUrl}
                        data-testid="skill-image"/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary" data-testid="cancel-button">
                        Annuler
                    </Button>
                    <Button className="submit-button" onClick={submit}
                        disabled={props.isFetching || disabledByName}
                        color="primary"
                        data-testid="submit-button">
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

AddSkill.propTypes = {
    width: PropTypes.string,
    categories: PropTypes.array,
    families: PropTypes.array,
    selectFamily: PropTypes.func,
    selectCategory: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    addSkill: PropTypes.func
};

const mapStateToProps = (state) => ({
    categories: selectCategories(state),
    families: selectFamilies(state),
    selectFamily: (familyName) => selectFamilyByName(state, familyName),
    selectCategory: (categoryName) => selectCategoryByName(state, categoryName),
    isFetching: createLoadingSelector([ADD_SKILL])(state) || selectAllSkills(state).loading,
    error: createErrorMessageSelector([ADD_SKILL])(state)
});

export function mapDispatchToProps(dispatch) {
    return {
        addSkill: (skill) => dispatch(addSkill(skill))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(AddSkill));
