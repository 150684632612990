import {
    MCQWithExplanationStore,
    MCQWithoutExplanationStore,
    MCQEditStore,
    questionsReducedPage,
    MCQModificationSaved,
    moderatorMap,
    questionsToDisplay
} from 'app/redux/reducers/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.reducer';
import { questionsStore } from 'app/redux/rootReducer';
import { questionsPreview } from '../../../reducers/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.reducer';

export function selectQuestionToPreview(state) {
    return state[questionsStore][questionsPreview];
}

export function selectQuestionsReducedPage(state) {
    return state[questionsStore][questionsReducedPage];
}

export function selectQuestionWithoutExplanation(state) {
    return state[questionsStore][MCQWithoutExplanationStore];
}

export function selectQuestionWithExplanation(state) {
    return state[questionsStore][MCQWithExplanationStore];
}

export function selectQuestionEdit(state) {
    return state[questionsStore][MCQEditStore];
}

export function selectQuestionModificationIsSaved(state) {
    return state[questionsStore][MCQModificationSaved];
}

export function selectQuestionToDisplay(state) {
    return state[questionsStore][questionsToDisplay];
}

export function selectModerators(state) {
    return state[questionsStore][moderatorMap];
}
