import { postMissionApi, putMissionApi, deleteMissionApi } from '../../../../api/missionsApi';
import { notificationError, notificationSuccess, notificationWarning } from '../../Notification/notifications.actions';
import { ADD_MISSION_ERROR, UPDATE_MISSION_ERROR, EXPIRED_SESSION,
    MISSION_ADDED, MISSION_ALREADY_EXIST, MISSION_UPDATED,
    MISSION_DELETED } from '../../../../Snackbar/NotificationMessages';
import { projectsActions } from 'app/redux/slices/projects.slice';

export const ADD_MISSION = 'ADD_MISSION';
export const ADD_MISSION_REQUEST = 'ADD_MISSION_REQUEST';
export const ADD_MISSION_SUCCESS = 'ADD_MISSION_SUCCESS';
export const ADD_MISSION_FAILURE = 'ADD_MISSION_FAILURE';

export const EDIT_MISSION = 'EDIT_MISSION';
export const EDIT_MISSION_REQUEST = 'EDIT_MISSION_REQUEST';
export const EDIT_MISSION_SUCCESS = 'EDIT_MISSION_SUCCESS';
export const EDIT_MISSION_FAILURE = 'EDIT_MISSION_FAILURE';

export const SET_FAMILIES_SKILLS_NUMBER = 'SET_FAMILIES_SKILLS_NUMBER';

export const addMission = (project) => (dispatch) => {
    dispatch({ type: ADD_MISSION_REQUEST });

    return postMissionApi(project).then(() => {
        dispatch({
            type: ADD_MISSION_SUCCESS
        });
        dispatch(projectsActions.getProjectById(project.projectId));
        dispatch(projectsActions.getAllProjects());
        dispatch(notificationSuccess(MISSION_ADDED));
    }, (error) => {
        dispatch({
            type: ADD_MISSION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(MISSION_ALREADY_EXIST));
        } else {
            dispatch(notificationError(ADD_MISSION_ERROR));
        }
    });
};

export const editMission = (project) => (dispatch) => {
    dispatch({ type: EDIT_MISSION_REQUEST });

    return putMissionApi(project).then(() => {
        dispatch({
            type: EDIT_MISSION_SUCCESS
        });
        dispatch(projectsActions.getProjectById(project.projectId));
        dispatch(projectsActions.getAllProjects());
        dispatch(notificationSuccess(MISSION_UPDATED));
    }, (error) => {
        dispatch({
            type: EDIT_MISSION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(MISSION_ALREADY_EXIST));
        } else {
            dispatch(notificationError(UPDATE_MISSION_ERROR));
        }
    });
};

export const deleteMission = (missionToDelete) => (dispatch) => {
    dispatch({ type: EDIT_MISSION_REQUEST });

    return deleteMissionApi(missionToDelete.idMission).then(() => {
        dispatch({ type: EDIT_MISSION_SUCCESS });
        dispatch(projectsActions.getProjectById(missionToDelete.idProject));
        dispatch(projectsActions.getAllProjects());
        dispatch(notificationSuccess(MISSION_DELETED));
    }, (error) => {
        dispatch({
            type: EDIT_MISSION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(MISSION_ALREADY_EXIST));
        } else {
            dispatch(notificationError(UPDATE_MISSION_ERROR));
        }
    });
};

