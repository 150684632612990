/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
    getQuestionsDisplayPaginated, GET_QUESTIONS_PAGINATED
} from '../../redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';
import './QuestionTable.scss';
import { Link } from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { selectModerators, selectQuestionsReducedPage } from '../../redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { colorStatIndication } from './QuestionTable.functions';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createLoadingSelector } from '../../redux/selectors/APISelector/APISelector';
import TablePaginationActions from '../../utils/TablePaginationActions';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import Tooltip from '@material-ui/core/Tooltip';
import { getDateFormated } from '../../utils/date.functions';
import { selectPaginationQuestion } from '../../redux/selectors/Pagination/pagination.selector';
import { setPaginationQuestionAction } from '../../redux/actions/Pagination/pagination.actions';
import { selectQuestionsChaptersFilter, selectQuestionsCreatorsFilter, 
    selectQuestionsSkillsFilter, selectQuestionsIdFilter, selectQuestionsAiGeneratedFilter } from '../../redux/selectors/Question/QuestionsFilters/questionsFilters.selector';
import { useDrag } from 'react-dnd';
import { AssignTypes } from 'app/utils/functions';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { optionsTooltip } from 'app/utils/OptionsTooltipEnum';
import { QuestionModeration } from '../QuestionModeration/QuestionModeration';


export function QuestionTable(props) {

    const dispatch = useDispatch();
    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const pageWithQuestionReduced = useSelector(selectQuestionsReducedPage);
    const moderatorMap = useSelector(selectModerators);
    const isFetchingQuestionReduced = useSelector(createLoadingSelector([GET_QUESTIONS_PAGINATED]));
    const paginationData = useSelector(selectPaginationQuestion);
    const skillsFilter = useSelector(selectQuestionsSkillsFilter);
    const creatorsFilter = useSelector(selectQuestionsCreatorsFilter);
    const chaptersFilter = useSelector(selectQuestionsChaptersFilter);
    const idFilter = useSelector(selectQuestionsIdFilter);
    const aiGeneratedFilter = useSelector(selectQuestionsAiGeneratedFilter);

    const [dataTable, setDataTable] = useState();
    const [questionsCount, setQuestionCount] = useState(0);
    const [mobileMenu, setMobileMenu] = useState(null);
    const [criteria, setCriteria] = useState({
        orderBy: null,
        orderDirection: 'asc'
    });
    const [paginationParams, setPaginationParams] = useState({
        pageNumber: (paginationData && paginationData.pageNumber) ? paginationData.pageNumber : 0,
        rowPerPage: (paginationData && paginationData.pageNumber) ? paginationData.rowPerPage : 10
    });

    useEffect(() => {
        if(pageWithQuestionReduced && pageWithQuestionReduced.step) {
            setDataTable(pageWithQuestionReduced.listOfElements);
            setQuestionCount(pageWithQuestionReduced.totalNumberOfElements);
        }
    }, [pageWithQuestionReduced]);

    useEffect(() => {
        const localCriteria = {
            column: criteria.orderBy,
            criteria: {
                authorIds: creatorsFilter && creatorsFilter.map((userReduced) => userReduced.value),
                skillIds: skillsFilter && skillsFilter.map((skillReduced) => skillReduced.value),
                chapterIds: chaptersFilter && chaptersFilter.map((chapter) => chapter.value),
                questionId: idFilter,
                aiGenerated : aiGeneratedFilter,
                userId: getCurrentUserId()
            },
            offset: paginationParams.pageNumber * paginationParams.rowPerPage,
            order: criteria.orderDirection,
            step: paginationParams.rowPerPage
        };
        dispatch(getQuestionsDisplayPaginated(localCriteria));
        dispatch(setPaginationQuestionAction(paginationParams));
    }, [paginationParams]);

    useEffect(() => {
        if(pageWithQuestionReduced) {
            setPaginationParams({ ...paginationParams, pageNumber: 0 });
            dispatch(getQuestionsDisplayPaginated({
                ...pageWithQuestionReduced,
                criteria: {
                    authorIds: creatorsFilter && creatorsFilter.map((userReduced) => userReduced.value),
                    skillIds: skillsFilter && skillsFilter.map((skillReduced) => skillReduced.value),
                    chapterIds: chaptersFilter && chaptersFilter.map((chapter) => chapter.value),
                    questionId: idFilter,
                    aiGenerated : aiGeneratedFilter,
                    userId: getCurrentUserId()
                },
                listOfElements: null,
                totalNumberOfElements: null
            }));
        }
    }, [skillsFilter, creatorsFilter, chaptersFilter, idFilter, aiGeneratedFilter]);

    const handleClick = (event) => {
        setMobileMenu(event.currentTarget);
    };

    const handleClose = () => {
        setMobileMenu(null);
    };

    const columnsForMobile = [
        {
            title: 'Id',
            field: 'id',

            render: (rowData) => (
                <Link
                    className="columnIdLink"
                    key={rowData.id}
                    to={`questions/resolve/${rowData.id}`}
                    style={{ textDecoration: 'none' }}
                >
                    {rowData.id}
                </Link>
            ),
            cellStyle: {
                width: '60%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '60%'
            },
            sorting: true
        },
        {
            title: 'Compétence',
            field: 'skill',

            render: (rowData) => (
                <Fragment key={rowData.id}>
                    {rowData.skillReduced
                        ? rowData.skillReduced.name
                        : undefined
                    }
                </Fragment>
            ),
            cellStyle: {
                width: '60%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '60%'
            },
            sorting: true
        },
        {
            title: 'Historique',
            field: 'history',

            render: (rowData) => <>
                {rowData && rowData.lastAttemptDate && rowData.lastAttemptDate !== null
                    ? <Fragment key={rowData.id}>
                        <font color={colorStatIndication(rowData.numberOfSuccess, rowData.numberOfAttempts)}><b> {rowData.numberOfSuccess} </b></font>
                        succès sur <b> {rowData.numberOfAttempts} </b> {rowData.numberOfAttempts > 1 ? 'tentatives' : 'tentative'} : <br />
                        <Tooltip
                            title={new Intl.DateTimeFormat('fr-FR', optionsTooltip).format(new Date(rowData.lastAttemptDate))}
                            placement="left-start">
                            <div><i>Dernière tentative : {getDateFormated(new Date(rowData.lastAttemptDate))}</i> </div>
                        </Tooltip>
                    </Fragment>
                    : <Fragment key={rowData.id} />}
            </>,
            cellStyle: {
                width: '20%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '20%'
            },
            sorting: true
        },
        {
            title: 'Action',
            field: 'action',

            render: (rowData) => <>
                <IconButton
                    id={rowData.id}
                    className="actionButton"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    elevation={1}
                    id="simple-menu"
                    anchorEl={mobileMenu}
                    keepMounted
                    open={Boolean(mobileMenu)}
                    onClose={handleClose}
                >
                    <MenuItem>
                        <Link
                            className="columnEditButton"
                            data-testid="link-edit"
                            to={`${QUESTIONS_PAGE_URL}/edit?id=${Number.parseInt(mobileMenu?.id, 10)}`}
                        >
                            Edition
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link
                            className="columnDuplicateButton"
                            data-testid="link-duplicate"
                            to={`${QUESTIONS_PAGE_URL}/create?id=${Number.parseInt(mobileMenu?.id, 10)}`}
                        >
                            Duplication
                        </Link>
                    </MenuItem>
                </Menu>
            </>,
            cellStyle: {
                width: '10%',
                padding: '0px 2px',
                horizontalAlign: 'center'
            },
            headerStyle: {
                width: '10%'
            },
            sorting: false
        }
    ];

    const columnsForDesktop = [
        {
            title: 'Id',
            field: 'id',

            render: (rowData) => (
                <>
                    <QuestionTitleDraggable
                        id={rowData.id}
                        name={`${rowData?.skillReduced.name}#${rowData.id}`}
                    />
                    { moderatorMap && <QuestionModeration
                        moderators={moderatorMap.get(rowData.id)}
                        questionId={rowData.id}
                    />
                    }
                </>

            ),
            cellStyle: {
                width: '25%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '25%'
            },
            sorting: true
        },
        {
            title: 'Compétence',
            field: 'skill',

            render: (rowData) => <Fragment key={rowData.id}>
                {rowData.skillReduced
                    ? rowData.skillReduced.name
                    : undefined
                }
            </Fragment>,
            cellStyle: {
                width: '25%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '25%'
            },
            sorting: true
        },
        {
            title: 'Historique',
            field: 'history',

            render: (rowData) => <>
                {rowData && rowData.lastAttemptDate && rowData.lastAttemptDate !== null
                    ? <Fragment key={rowData.id}>
                        <font color={colorStatIndication(rowData.numberOfSuccess, rowData.numberOfAttempts)}><b> {rowData.numberOfSuccess} </b></font>
                        succès sur <b> {rowData.numberOfAttempts} </b> {rowData.numberOfAttempts > 1 ? 'tentatives' : 'tentative'} : <br />
                        <Tooltip
                            title={new Intl.DateTimeFormat('fr-FR', optionsTooltip).format(new Date(rowData.lastAttemptDate))}
                            placement="left-start"
                            className="displayStatData">
                            <div><i>Dernière tentative : {getDateFormated(new Date(rowData.lastAttemptDate))}</i> </div>
                        </Tooltip>
                    </Fragment>
                    : <Fragment key={rowData.id} />}
            </>,
            cellStyle: {
                width: '35%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '25%'
            },
            sorting: true
        },
        {
            title: 'Anomalies',
            field: 'anomalie',

            render: (rowData) => (
                rowData.anomalies),
            cellStyle: {
                width: '12,5%'
            },
            headerStyle: {
                width: '25%'
            },
            sorting: true
        },
        {
            title: 'Auteur',
            field: 'creator',

            render: (rowData) => <Fragment key={rowData.id}>
                {rowData.creatorFirstName} {rowData.creatorLastName}
            </Fragment>,
            cellStyle: {
                width: '15%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '15%'
            },
            sorting: true
        },
        {
            title: 'Edition',
            field: 'Edit',

            render: (rowData) => (
                <Link
                    className="columnEditButton"
                    data-testid="link-edit"
                    to={`${QUESTIONS_PAGE_URL}/edit?id=${rowData.id}`}
                >
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                </Link>
            ),
            cellStyle: {
                width: '12,5%',
                padding: '2px 2px'
            },
            headerStyle: {
                width: '12,5%'
            },
            sorting: false
        },
        {
            title: 'Duplication',
            field: 'Duplicate',

            render: (rowData) => <Link
                className="columnDuplicateButton"
                data-testid="link-duplicate"
                to={`${QUESTIONS_PAGE_URL}/create?id=${rowData.id}`}
            >
                <IconButton key={rowData.id}>
                    <FileCopyIcon/>
                </IconButton>
            </Link>,
            cellStyle: {
                width: '12,5%',
                padding: '2px 2px'
            },
            headerStyle: {
                width: '12,5%'
            },
            sorting: false
        }
    ];

    const sortBy = (orderedColumnId, orderDirection) => {
        if(orderedColumnId < 0) {
            return;
        }
        let fixedDirection = orderDirection;
        if(criteria.orderBy === columnsForDesktop[orderedColumnId].field) {
            fixedDirection = criteria.orderDirection === 'asc' ? 'desc' : 'asc';
        } else {
            fixedDirection = 'asc';
        }
        setCriteria({
            orderBy: columnsForDesktop[orderedColumnId].field,
            orderDirection: fixedDirection
        });
        const localCriteria = {
            column: criteria.orderBy,
            criteria: {
                authorIds: creatorsFilter && creatorsFilter.map((userReduced) => userReduced.value.id),
                skillIds: skillsFilter && skillsFilter.map((skillReduced) => skillReduced.value),
                userId: getCurrentUserId(),
                aiGenerated: aiGeneratedFilter
            },
            offset: paginationParams.pageNumber * paginationParams.rowPerPage,
            order: criteria.orderDirection,
            step: paginationParams.rowPerPage
        };
        dispatch(getQuestionsDisplayPaginated(localCriteria));
    };

    const columnControl = () => {
        if(isWidthUp('md', props.width)) {
            return columnsForDesktop;
        }
        return columnsForMobile;

    };

    const handleChangePage = (event, number) => {
        setPaginationParams({ ...paginationParams, pageNumber: number });
    };

    const handleRowsPerPage = (event) => {
        setPaginationParams({ pageNumber: 0, rowPerPage: event.target.value });
    };

    return (
        <section>
            <div className="serie" data-testid="list-questions">
                <MaterialTable
                    className="Table"
                    key={paginationParams.rowPerPage}
                    columns={columnControl()}
                    data={dataTable}
                    title="Liste des QCM"
                    isLoading={(isFetchingQuestionReduced) && !dataTable}
                    options={{
                        search: true,
                        title: true,
                        toolbar: false,
                        pageSize: paginationParams.rowPerPage
                    }}
                    localization={{
                        'header': {
                            'actions': ''
                        },
                        'body': {
                            'emptyDataSourceMessage': ((isFetchingQuestionReduced) && !dataTable)
                                ? 'En attente de chargement...'
                                : 'Aucune question trouvée'
                        },
                        'toolbar': {
                            'searchPlaceholder': 'Rechercher',
                            'searchTooltip': 'Rechercher'
                        }
                    }}
                    onOrderChange={(orderedColumnId, orderDirection) => sortBy(orderedColumnId, orderDirection)}
                    components={{
                        Pagination: () => (
                            <TablePagination className="pagination"
                                count={questionsCount}
                                page={paginationParams.pageNumber}
                                rowsPerPage={paginationParams.rowPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleRowsPerPage}
                                rowsPerPageOptions={[10, 20]}
                                labelRowsPerPage={'Questions par page'}
                                backIconButtonText={'Page précédente'}
                                nextIconButtonText={'Page suivante'}
                                labelDisplayedRows={({ from, to, count }) => (`${from}-${to} / ${count}`)}
                                ActionsComponent={TablePaginationActions}
                            />
                        )
                    }}
                />
            </div>
        </section>
    );
}

QuestionTable.propTypes = {
    width: PropTypes.string,
    isFetching: PropTypes.bool
};
export default (withWidth()(QuestionTable));

export function QuestionTitleDraggable(props) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.QUESTION,
        item: {
            type: AssignTypes.QUESTION,
            id: props.id,
            name: props.name
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging())
        })

    }), [props.id]);
    return (
        <Link
            className="columnIdLink"
            key={props.id}
            to={`${QUESTIONS_PAGE_URL}/resolve/${props.id}`}
            ref={drag}
            style={
                {
                    textDecoration: 'none',
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move'
                }
            }
        >
            {props.id}
        </Link>
    );
}

QuestionTitleDraggable.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
};
