export const SET_CLIENTS_SECTOR_FILTER = 'SET_CLIENTS_SECTOR_FILTER';

export function setClientsSectorFilter(filter) {
    return {
        type: SET_CLIENTS_SECTOR_FILTER,
        payload: filter
    };
}

export const SET_CLIENTS_USER_FILTER = 'SET_CLIENTS_USER_FILTER';

export function setClientsExcilyenFilter(filter) {
    return {
        type: SET_CLIENTS_USER_FILTER,
        payload: filter
    };
}
