import { deleteProjectApi, postProjectApi, putProjectApi } from '../../../../api/projectsSetApi';
import { notificationError, notificationSuccess, notificationWarning } from '../../Notification/notifications.actions';
import {
    ADD_PROJECT_ERROR,
    UPDATE_PROJECT_ERROR,
    EXPIRED_SESSION,
    PROJECT_ADDED,
    PROJECT_ALREADY_EXIST,
    PROJECT_UPDATED,
    PROJECT_DELETED,
    DELETE_PROJECT_ERROR
} from '../../../../Snackbar/NotificationMessages';

import { projectsActions } from 'app/redux/slices/projects.slice';

export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const EDIT_PROJECT = 'EDIT_PROJECT';
export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const SET_FAMILIES_SKILLS_NUMBER = 'SET_FAMILIES_SKILLS_NUMBER';

export const addProject = (project) => (dispatch) => {
    dispatch({ type: ADD_PROJECT_REQUEST });

    return postProjectApi(project).then(() => {
        dispatch({
            type: ADD_PROJECT_SUCCESS
        });
        dispatch(projectsActions.getAllProjects());
        dispatch(notificationSuccess(PROJECT_ADDED));
    }, (error) => {
        dispatch({
            type: ADD_PROJECT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(PROJECT_ALREADY_EXIST));
        } else {
            dispatch(notificationError(ADD_PROJECT_ERROR));
        }
    });
};

export const editProject = (project) => (dispatch) => {
    dispatch({ type: EDIT_PROJECT_REQUEST });

    return putProjectApi(project).then(() => {
        dispatch({
            type: EDIT_PROJECT_SUCCESS
        });
        dispatch(projectsActions.getProjectById(project.id));
        dispatch(projectsActions.getAllProjects());
        dispatch(notificationSuccess(PROJECT_UPDATED));
    }, (error) => {
        dispatch({
            type: EDIT_PROJECT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(PROJECT_ALREADY_EXIST));
        } else {
            dispatch(notificationError(UPDATE_PROJECT_ERROR));
        }
    });
};

export const deleteProject = (projectId) => (dispatch) => {
    dispatch({ type: DELETE_PROJECT_REQUEST });

    return deleteProjectApi(projectId).then(() => {

        dispatch({
            type: DELETE_PROJECT_SUCCESS

        });

        dispatch(notificationSuccess(PROJECT_DELETED));
        dispatch(projectsActions.getAllProjects());
        if(window.location.pathname === `/projects/${projectId}`) {
            window.location.href = '/projects';
        }

    }, (error) => {
        dispatch({
            type: DELETE_PROJECT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_PROJECT_ERROR));
        }
    });
};
