import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { isWidthUp } from '@material-ui/core/withWidth';

export class MaterialTableImpl extends Component {

    onChangeRowsPerPage = (pageSize) => localStorage.setItem('displayMaterialTableSkillsPageSize', pageSize);

    render() {
        const { title, isFetching, columns, data, defaultResultNumberRow } = this.props;

        const theme = createTheme({
            overrides: {
                MuiTableSortLabel: {
                    icon: {
                        width: '20px',
                        height: '25px'
                    }
                }
            }
        });

        return <MuiThemeProvider theme={theme}>
            <MaterialTable
                components={{
                    Toolbar: (props) => <div className="array-header">
                        <MTableToolbar {...props} />
                    </div>
                }}
                title={title}
                columns={columns}
                data={data ? data : []}
                isLoading={isFetching}
                options={{
                    column: 'sorting',
                    padding: 'dense',
                    thirdSortClick: false,
                    pageSize: localStorage.displayMaterialTableSkillsPageSize === undefined
                        ? defaultResultNumberRow
                        : Number.parseInt(localStorage.displayMaterialTableSkillsPageSize, 10),
                    searchFieldStyle: isWidthUp('sm', this.props.width)
                        ? {
                            marginRight: '60px'
                        }
                        : {
                            marginRight: '60px',
                            marginTop: '10px'
                        }
                }}
                localization={{
                    'body': {
                        'emptyDataSourceMessage': this.props.isFetching ? 'En attente de chargement...' : 'Pas de résultat.'
                    },
                    'header': {
                        'actions': ''
                    },
                    'pagination': {
                        'labelDisplayedRows': '{from}-{to} / {count}',
                        'labelRowsSelect': '',
                        'nextTooltip': 'Page suivante',
                        'previousTooltip': 'Page précédente',
                        'firstTooltip': 'Première page',
                        'lastTooltip': 'Dernière page'
                    },
                    'toolbar': {
                        'searchPlaceholder': 'Rechercher',
                        'searchTooltip': 'Rechercher'
                    }
                }}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
        </MuiThemeProvider>;
    }
}

MaterialTableImpl.propTypes = {
    data: PropTypes.array,
    isFetching: PropTypes.bool,
    columns: PropTypes.array,
    title: PropTypes.string,
    defaultResultNumberRow: PropTypes.number,
    width: PropTypes.string
};

MaterialTableImpl.defaultProps = {
    defaultResultNumberRow: 10
};
