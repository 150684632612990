import axiosClient from './utils/axiosClient';
import AuthenticationContainer from '../login/containers/AuthenticationContainer';

export const CLIENTS_SET_URI = 'clients';

export function postClientApi(client) {
    return axiosClient.post(`${CLIENTS_SET_URI}?userEmail=${AuthenticationContainer.getEmail()}`, client);
}

export function putClientApi(client) {
    return axiosClient.put(CLIENTS_SET_URI, client);
}
