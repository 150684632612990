import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import './SidePanelQuestionsFilterContainer.scss';
import { setQuestionsCreatorsFilter, setQuestionsSkillsFilter, setQuestionsChaptersFilter, resetQuestionsChaptersFilter } from
    '../../../redux/actions/Question/QuestionsFilters/questionsFilters.actions';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { selectReducedChapters } from '../../../redux/selectors/Chapter/chapter.selector';
import { selectQuestionsCreatorsFilter, selectQuestionsSkillsFilter, selectQuestionsChaptersFilter } from
    '../../../redux/selectors/Question/QuestionsFilters/questionsFilters.selector';
import { selectAllUser } from '../../../redux/selectors/User/user.selector';
import AuthenticationContainer from '../../../login/containers/AuthenticationContainer';
import { chapterActions } from 'app/redux/slices/chapters.slice';

export class SidePanelQuestionsFilterContainer extends Component {

    getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    handleChange = (value) => {
        if(this.props.skill) {
            this.props.setQuestionsSkillsFilter(value);
            if(value && value.length) {
                this.props.getChapterBySkillId(value[0].value, this.getCurrentUserId());
            } else {
                this.props.resetQuestionsChaptersFilter();
            }
        }
        if(this.props.user) {
            this.props.setQuestionsCreatorsFilter(value);
        }
        if(this.props.chapter) {
            this.props.setQuestionsChaptersFilter(value);
        }
    };

    render() {

        const allSkills = this.props.skills
            .map((skillReduced) => ({ label: skillReduced.name, value: skillReduced.id }))
            .sort((s1, s2) => s1.label.localeCompare(s2.label));

        const allUsers = this.props.users
            .map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.id }))
            .sort((s1, s2) => s1.label.localeCompare(s2.label));

        const allChaptersByAllSelectedSkillId = this.props.chapters
            .map((chapter) => ({ label: chapter.name, value: chapter.id }))
            .sort((s1, s2) => s1.label.localeCompare(s2.label));

        const { skill, user, chapter } = this.props;
        let className = '';
        let placeHolder = '';
        let name = '';
        let isMulti = false;
        let options = [];
        let disabled = true;
        let selectValue = '';

        const customStyles = {
            control: (base, __) => ({
                ...base,
                boxShadow: 'none'
            })
        };

        if(skill) {
            className = 'basic-multi-select';
            placeHolder = 'Compétences';
            name = 'competences';
            options = allSkills;
            isMulti = true;
            disabled = false;
            selectValue = this.props.valueSkills;
        }

        if(user) {
            className = 'basic-multi-select';
            placeHolder = 'Auteurs';
            name = 'créateurs';
            options = allUsers;
            isMulti = true;
            disabled = false;
            selectValue = this.props.valueUsers;
        }

        if(chapter) {
            className = 'basic-multi-select';
            placeHolder = 'Chapitres';
            name = 'chapitres';
            options = allChaptersByAllSelectedSkillId;
            isMulti = true;
            disabled = !this.props.valueSkills || !this.props.valueSkills.length;
            selectValue = this.props.valueChapters;
        }

        return (
            <div className="question-filter" data-testid="side-panel-question-filter-container">
                <Typography className="sub-title-question">
                    {skill && 'Compétences'}
                    {user && 'Auteurs'}
                    {chapter && 'Chapitres'}
                </Typography>
                <div data-testid="side-panel-question-filter-container-form">
                    <Select
                        onChange={this.handleChange}
                        className={className}
                        classNamePrefix="select"
                        placeholder={placeHolder}
                        styles={customStyles}
                        isClearable={true}
                        isSearchable={true}
                        name={name}
                        options={options}
                        isMulti={isMulti}
                        isDisabled={disabled}
                        value={selectValue}
                        menuPosition="fixed"
                    />
                </div>
            </div>
        );
    }
}

SidePanelQuestionsFilterContainer.propTypes = {
    skill: PropTypes.bool,
    user: PropTypes.bool,
    chapter: PropTypes.bool,

    skills: PropTypes.array,
    users: PropTypes.array,
    chapters: PropTypes.array,

    valueSkills: PropTypes.array,
    valueUsers: PropTypes.array,
    valueChapters: PropTypes.array,

    setQuestionsSkillsFilter: PropTypes.func,
    setQuestionsCreatorsFilter: PropTypes.func,
    setQuestionsChaptersFilter: PropTypes.func,
    getChapterBySkillId: PropTypes.func,
    resetQuestionsChaptersFilter: PropTypes.func
};

function mapStateToProps(state) {
    return {
        skills: selectAllReducedSkills(state).data,
        valueSkills: selectQuestionsSkillsFilter(state),

        users: selectAllUser(state),
        valueUsers: selectQuestionsCreatorsFilter(state),

        chapters: selectReducedChapters(state).data,
        valueChapters: selectQuestionsChaptersFilter(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setQuestionsSkillsFilter: (filter) => dispatch(setQuestionsSkillsFilter(filter)),
        setQuestionsCreatorsFilter: (filter) => dispatch(setQuestionsCreatorsFilter(filter)),
        setQuestionsChaptersFilter: (filter) => dispatch(setQuestionsChaptersFilter(filter)),
        getChapterBySkillId: (skillId, userId) => dispatch(chapterActions.getChaptersToDisplay({ skillId, userId })),
        resetQuestionsChaptersFilter: () => dispatch(resetQuestionsChaptersFilter())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelQuestionsFilterContainer);
