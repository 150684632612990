import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidePanelEntry from './SidePanelSkillEntry';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import '../SidePanel.scss';
import PropTypes from 'prop-types';
import { selectCategories, selectFamilies } from '../../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';

export class SidePanelSkillFilterContainer extends Component {

    render() {
        const { family, category } = this.props;
        let entries = [];

        if(family) {
            entries = this.props.families.map((familyName, index) => <Grid item xs={12} key={index}>
                <SidePanelEntry family label={familyName.name}/>
            </Grid>);
        }
        if(category) {
            entries = this.props.categories.map((categoryName, index) => <Grid item xs={12} key={index}>
                <SidePanelEntry category label={categoryName.name}/>
            </Grid>);
        }

        return (
            <Grid container className="filter-box" data-testid="side-panel-skill-filter-container">
                <Typography className="sub-title">
                    {category && 'Catégorie'}
                    {family && 'Famille'}
                </Typography>
                {entries}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        categories: selectCategories(state),
        families: selectFamilies(state)
    };
}

SidePanelSkillFilterContainer.propTypes = {
    family: PropTypes.bool,
    category: PropTypes.bool,
    families: PropTypes.array,
    categories: PropTypes.array
};

SidePanelSkillFilterContainer.defaultProps = {
    family: false,
    category: false
};

export default connect(mapStateToProps, null)(SidePanelSkillFilterContainer);
