import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Viewport from 'react-viewport-list';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';

import 'highlight.js/styles/a11y-dark.css';
import './ChapterResourcesSummaryDisplay.scss';

import { selectReducedChapters, selectSelectedChapterId } from 'app/redux/selectors/Chapter/chapter.selector';
import { ChapterDescriptionDisplay } from '../ChapterDescriptionDisplay/ChapterDescriptionDisplay';
import ContentRequest from '../../Content/ContentRequest';

import { CircularProgress } from '@material-ui/core';
import SkillHeader from '../../SkillHeader';

function ChapterResourcesSummaryDisplay(props) {
    const { data: chapters } = useSelector(selectReducedChapters);
    const selectedChapterId = useSelector(selectSelectedChapterId);

    const [sortedChapters, setSortedChapters] = useState([]);
    const [resourceIndex, setResourceIndex] = useState(-1);
    const [activeResources, setActiveResources] = useState([]);
    const [editingChapter, setEditingChapter] = useState('');

    const viewportRef = useRef(null);

    function sortChapters(array, chapterlist, parentPath) {
        const parentChapters = chapterlist.filter((chapter) => (
            chapter.parentPath === parentPath));

        if(parentPath === '/') {
            for(const chapter of parentChapters) {
                array.push(chapter);
                sortChapters(array, chapterlist, `${chapter.parentPath + chapter.name.toLowerCase()}/`);
            }
        } else {
            const filteredChapters = chapterlist.filter((chapter) => (
                chapter.parentPath.includes(parentPath)));
            for(const chapter of parentChapters) {
                array.push(chapter);
                sortChapters(array, filteredChapters, `${chapter.parentPath + chapter.name.toLowerCase()}/`);
            }
        }
    }

    function handleEditionChange(chapterName) {
        setEditingChapter(chapterName);
    }

    useEffect(() => {
        const array = [];
        sortChapters(array, chapters, '/');
        setSortedChapters(array);
    }, [chapters]);

    useEffect(() => {
        if(sortedChapters.length) {
            const index = sortedChapters.findIndex((chapter) => chapter.id === selectedChapterId);
            viewportRef.current.scrollToIndex(index);
        }
    }, [selectedChapterId]);


    useEffect(() => {
        hljs.configure({ ignoreUnescapedHTML: true });
        document.querySelectorAll('pre code').forEach((elt) => {
            hljs.highlightElement(elt);
        });
    }, [props.chapter]);

    return (resourceIndex === -1
        ? (sortedChapters.length
            ? <ChapterScrollContainer
                skill={props.skill}
                sortedChapters={sortedChapters}
                initialIndex={sortedChapters.findIndex((chapter) => chapter.id === selectedChapterId)}
                editingChapter={editingChapter}
                setActiveResources={setActiveResources}
                setResourceIndex={setResourceIndex}
                handleEditionChange={handleEditionChange}
                viewportRef={viewportRef}
                permission={props.permission}
                bookmark={props.bookmark}
                isModerator={props.isModerator}
                sizeUpMd={props.sizeUpMd}
                reportNumber={props.reportNumber}
            />
            : <div className="centered">
                <CircularProgress/>
            </div>)
        : <ContentRequest
            resourceList={activeResources}
            skillResourceIndex={resourceIndex}
            returnToSummary={() => {
                setResourceIndex(-1);
            }}
        />);

}

ChapterResourcesSummaryDisplay.propTypes = {
    chapter: PropTypes.object.isRequired,
    skill: PropTypes.object.isRequired,
    permission: PropTypes.bool.isRequired,
    bookmark: PropTypes.object,
    isModerator: PropTypes.bool,
    sizeUpMd: PropTypes.bool,
    reportNumber: PropTypes.object
};

export default ChapterResourcesSummaryDisplay;


function ChapterScrollContainer(props) {
    const ref = useRef(null);
    return (
        <div className="scroll-container" ref={ref}>
            {props.sizeUpMd &&
                <SkillHeader
                    skill={props.skill}
                    isModerator={props.isModerator}
                />
            }
            <Viewport
                ref={props.viewportRef}
                viewportRef={ref}
                items={props.sortedChapters}
                initialIndex={props.initialIndex}
                overflowAnchor={'none'}
            >
                {(item) => (
                    <div key={item.id}>
                        <ChapterDescriptionDisplay
                            skill={props.skill}
                            chapter={item}
                            editingChapter={props.editingChapter}
                            setActiveResources={props.setActiveResources}
                            setResourceIndex={props.setResourceIndex}
                            onEditionChange={props.handleEditionChange}
                            permission={props.permission}
                            bookmark={props.bookmark}
                            reportCount={props.reportNumber[item?.id] ?? 0}
                        />
                    </div>
                )}
            </Viewport>
        </div>
    );

}

ChapterScrollContainer.propTypes = {
    sortedChapters: PropTypes.array.isRequired,
    setActiveResources: PropTypes.func.isRequired,
    setResourceIndex: PropTypes.func.isRequired,
    initialIndex: PropTypes.number,
    editingChapter: PropTypes.string.isRequired,
    handleEditionChange: PropTypes.func.isRequired,
    skill: PropTypes.object.isRequired,
    viewportRef: PropTypes.object,
    permission: PropTypes.bool.isRequired,
    bookmark: PropTypes.object,
    isModerator: PropTypes.bool,
    sizeUpMd: PropTypes.bool,
    reportNumber: PropTypes.object
};

