import { React } from 'react';
import PropTypes from 'prop-types';
import CourseContent from './CourseContent';

export default function CoursesList(props) {

    const coursesPresent = props.questionMicroskills.length > 0;

    return (

        <>
  
            {coursesPresent ? 
                props.questionMicroskills.map( (mc, key) =>
                    <div className="content bold" key = {key}>

                        <CourseContent microskill = {props.questionMicroskills[key]}
                            setQuestionMicroskills = {props.setQuestionMicroskills}
                            questionMicroskill = {props.questionMicroskills} />

                    </div> ) : 
                <div className="content scrollable" >
               Pas de cours disponibles
                </div>}
            
        </>
       
    );

}

CoursesList.propTypes = {
    questionMicroskills: PropTypes.array,
    setQuestionMicroskills : PropTypes.func
};