import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './ChapterRessourceTable.scss';
import 'highlight.js/styles/a11y-dark.css';
import resourceNotFound from 'assets/images/resourceNotFound.png';
import AddChapterResourceDialog from '../AddChapterResources/AddChapterResourceDialog/AddChapterResourceDialog';
import { chapterResourceTypeEnum } from '../AddChapterResources/AddChapterResourceForm/AddChapterResourceForm';

import {
    IconButton,
    Link, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    makeStyles
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import YouTubeIcon from '@material-ui/icons/YouTube';

const ROWS_PER_PAGE_SMALL = 5;
const ROWS_PER_PAGE_MEDIUM = 10;
const ROWS_PER_PAGE_LARGE = 25;

const rowsPerPageOptions = [ROWS_PER_PAGE_SMALL, ROWS_PER_PAGE_MEDIUM, ROWS_PER_PAGE_LARGE];


const useStyles = makeStyles(() => ({
    icon: {
        color: 'red',
        fontSize: '2rem'
    },

    tableContainer: {
        width: '60%',
        marginLeft: '20%',
        border: '1px solid black',
        borderRadius: '20px',
        borderCollapse: 'separate'
    },

    table: {
        borderTop: '1px solid black'
    },

    tableHeadCell: {
        textAlign: 'center',
        fontSize: '1.3rem'
    },

    tableCellType: {
        textAlign: 'center',
        width: '100px',
        borderRight: '1px solid black',
        padding: '3px'
    },

    tableCellName: {
        fontSize: '2rem',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '15px',
        lineHeight: '1'
    },

    addButton: {
        marginLeft: 'auto'
    }
}));

export function ChapterResourceTable(props) {

    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_MEDIUM);

    function getResourceIcon(resource) {
        switch (resource.type) {
            case chapterResourceTypeEnum.YOUTUBE:
                return <YouTubeIcon className={classes.icon} />;
            case chapterResourceTypeEnum.PDF:
                return <PictureAsPdfIcon className={classes.icon} />;
            case chapterResourceTypeEnum.TEXT:
                return <TextFormatIcon />;
            default:
                return <QuestionAnswerIcon />;
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeResourceIndex = (index) => () => {
        props.onChangeResourceIndex(index);
    };

    return (
        <TableContainer className={classes.tableContainer} component={Paper} cy-data="skill-chapter-ressources-table">
            <ChapterResourcesTableToolbar
                chapterId={props.chapterId}
                chapterName={props.chapterName}
            />
            {props.resourceList.length
                ? <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={`${classes.tableHeadCell} ${classes.tableCellType}`}>Type</TableCell>
                            <TableCell className={classes.tableHeadCell}>Nom</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.resourceList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((resource, index) => (
                            <TableRow key={resource.id}>
                                <TableCell className={classes.tableCellType}>
                                    {getResourceIcon(resource)}
                                </TableCell>
                                <TableCell className={classes.tableCellName}>
                                    <Link
                                        variant="h6"
                                        onClick={handleChangeResourceIndex(page * rowsPerPage + index)}
                                    >
                                        {resource.name}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={props.resourceList.length}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                labelRowsPerPage="Resources par page"
                                backIconButtonText="Page précédente"
                                nextIconButtonText="Page suivante"
                                labelDisplayedRows={({ from, to, count }) => (
                                    `${from}-${to} / ${count === -1 ? `plus que ${to}` : count}`
                                )}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                : <ChapterResourceNotFound />
            }
        </TableContainer>
    );
}

ChapterResourceTable.propTypes = {
    chapterId: PropTypes.number,
    chapterName: PropTypes.string,
    resourceList: PropTypes.array.isRequired,
    onChangeResourceIndex: PropTypes.func.isRequired
};

function ChapterResourcesTableToolbar({ chapterId, chapterName }) {

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Toolbar>
                <Typography variant="h6">
                    Ressources du chapitre: {chapterName}
                </Typography>
                <Tooltip title="Ajouter une ressource">
                    <IconButton className={classes.addButton} onClick={handleOpen} cy-data="skill-chapter-add-ressource">
                        <AddCircleIcon
                            color="primary"
                            fontSize="large"
                        />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <AddChapterResourceDialog
                chapterId={chapterId}
                chapterName={chapterName}
                isOpen={isOpen}
                onClose={handleClose}
            />
        </>
    );
}

ChapterResourcesTableToolbar.propTypes = {
    chapterId: PropTypes.number,
    chapterName: PropTypes.string
};

function ChapterResourceNotFound() {
    return (
        <div className="chapter-resource-not-found">
            <img
                src={resourceNotFound}
                alt="Resource non trouvé"
                height="128px"
                width="128px"
            />
            Pas de ressource
        </div>
    );
}

