import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { setCategoryFilter, setFamilyFilter, setSearchFilter } from '../../redux/actions/Filter/filter.actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './NavbarLargeScreen.scss';
import {
    setExcilyensSearchFilter,
    setExcilyensSkillsFilter,
    setPromoFilter
} from '../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import { selectCurrentUser } from '../../redux/selectors/User/user.selector';
import { resetQuestionSelectedByStudent } from 'app/redux/actions/Question/question.actions';

export class NavbarLargeScreen extends Component {

    render() {

        const { user } = this.props;

        return (
            <Grid data-testid="navbar-nav" id="navbar-nav" item>
                {
                    user.roles &&
                    user.roles.some((role) => role.name === 'RECRUTEUR') &&
                    <NavLink activeClassName="active" className="navbar-button" to="/recrutement" onClick={this.props.resetSearchAndFilters}>
                        <Button>
                            Recrutement
                        </Button>
                    </NavLink>
                }
                {
                    user.roles &&
                    user.roles.some((role) => role.name === 'ADMIN') &&
                    <NavLink activeClassName="active" className="navbar-button" to="/stats" onClick={this.props.resetSearchAndFilters}>
                        <Button>
                            Statistiques
                        </Button>
                    </NavLink>
                }
                <NavLink activeClassName="active" className="navbar-button" to="/coaching" onClick={this.props.resetSearchAndFilters}>
                    <Button>
                        Coaching
                    </Button>
                </NavLink>
                <NavLink activeClassName="active" className="navbar-button" to="/questions" onClick={this.props.resetSearchAndFilters}>
                    <Button cy-data="questions-btn">
                        Questions
                    </Button>
                </NavLink>
                <NavLink activeClassName="active" className="navbar-button" to="/projects" onClick={this.props.resetSearchAndFilters}>
                    <Button>
                        Projets
                    </Button>
                </NavLink>
                <NavLink activeClassName="active" className="navbar-button" to="/skills" onClick={this.props.resetSearchAndFilters}>
                    <Button>
                        Compétences
                    </Button>
                </NavLink>
                <NavLink activeClassName="active" className="navbar-button" to="/excilyens" onClick={this.props.resetSearchAndFilters}>
                    <Button>
                        Oxyliens
                    </Button>
                </NavLink>
            </Grid>
        );
    }
}

NavbarLargeScreen.propTypes = {
    user: PropTypes.object,
    resetSearchAndFilters: PropTypes.func
};

function mapStateToProps(state) {
    return {
        user: selectCurrentUser(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        resetSearchAndFilters: () => {
            dispatch(setSearchFilter(''));
            dispatch(setCategoryFilter([]));
            dispatch(setFamilyFilter([]));
            dispatch(setPromoFilter([]));
            dispatch(setExcilyensSearchFilter(''));
            dispatch(setExcilyensSkillsFilter([]));
            dispatch(resetQuestionSelectedByStudent());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarLargeScreen);
