import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { selectSelectedChaptersSkillView } from 'app/redux/selectors/Chapter/chapter.selector';

const useStyles = makeStyles(() => ({
    statisticsCellRoot: {
        color: 'white',
        border: '1px solid white'
    },
    tableContainerRoot: {
        overflowX: 'auto',
        maxHeight: '90%'
    },
    tableRoot: {
        tableLayout: 'fixed',
        overflowX: 'auto'
    },
    groupCellRoot: {
        textAlign: 'center',
        fontSize: '1.1rem'
    }
}));

const BAKGROUND_COLOR = '#fafafa';

export default function ChapterGroupStats(props) {

    const classes = useStyles();

    const selectedChapters = useSelector(selectSelectedChaptersSkillView);

    const [students, setStudents] = useState(props.studentList);
    const [chapters, setChapters] = useState([]);
    const [questionOrSerieHistoryData, setQuestionOrSerieHistoryData] = useState(new Map());

    const chooseColor = (stats) => {
        if(stats.questionsAnswered === 0 || stats.questionsAnswered < stats.nbQuestionRequired) {
            return '#CCC';
        } else if(!stats.validated) {
            return 'red';
        }
        return 'green';
    };

    const infobulleContent = (student, statistics) => {
        const statisticsToPrint = {
            'Questions correctes': statistics.corrects,
            'Questions totales distinctes réalisées': statistics.questionsAnswered,
            'Questions distinctes nécessaires pour valider le chapitre': statistics.nbQuestionRequired,
            'Seuil de validation du chapitre': statistics.validationRate
        };

        return (<>
            <Typography
                variant="h5"
                style={{ fontWeight: 'bold', color: 'white' }}
            >
                {student.firstName} {student.lastName}
            </Typography>

            {Object.keys(statisticsToPrint).map((field) => (
                <React.Fragment
                    key={field}
                >
                    <Typography
                        variant="body1"
                        style={{ color: 'white' }}
                    >
                        {field} :
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{
                            color: 'white',
                            fontWeight: 'bold'
                        }}
                    >
                        { statisticsToPrint[field] }
                    </Typography>
                </React.Fragment>
            ))}

            <Typography
                variant="body1"
                style={{ color: 'white',
                    fontWeight: 'bold',
                    textAlign: 'center' }}
            >
                Pourcentage : {Math.round(statistics.questionsAnswered === 0 ? 0 : 100 * statistics.corrects / statistics.questionsAnswered)}%
            </Typography>
        </>
        );
    };

    useEffect(() => {
        setStudents(props.studentList);
    }, [props.studentList]);

    useEffect(() => {
        setChapters(selectedChapters);
    }, [selectedChapters]);

    useEffect(() => {
        setQuestionOrSerieHistoryData(props.questionOrSerieHistoryData);
    }, [props.questionOrSerieHistoryData]);

    return <>
        <TableContainer
            component={Paper}
            classes={{ root: classes.tableContainerRoot }}
        >
            <Table stickyHeader aria-label="simple table" classes={{ root: classes.tableRoot }}>
                <colgroup>
                    <col style={{ width: '200px' }} />
                    {students.map((chapter) => (
                        <col key={chapter.id} style={{ width: '100px' }} />
                    ))}
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ position: 'sticky', left: '0', background: BAKGROUND_COLOR, zIndex: '3' }}
                        >
                            Chapitres
                        </TableCell>
                        {
                            students.map((student, index) => (
                                <TableCell key={index}>
                                    { `${student.firstName} ${student.lastName}` }
                                </TableCell>))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        chapters.map((chapter) => (
                            <TableRow key={chapter.id}>
                                <TableCell style={{ position: 'sticky', left: '0', background: BAKGROUND_COLOR }}>
                                    { chapter.name }
                                </TableCell>
                                {
                                    students.map((student, index) => (
                                        (questionOrSerieHistoryData.get(chapter.id) && questionOrSerieHistoryData.get(chapter.id)[student.id])
                                            ? <Tooltip key={index} title={infobulleContent(student, questionOrSerieHistoryData.get(chapter.id)[student.id])}>
                                                <TableCell
                                                    style={{ backgroundColor: chooseColor(questionOrSerieHistoryData.get(chapter.id)[student.id]) }}
                                                    classes={{
                                                        root: classes.statisticsCellRoot
                                                    }}
                                                    data-testid={`${student.firstName} ${student.lastName} ${chapter.name}`}
                                                />
                                            </Tooltip>
                                            : null
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

ChapterGroupStats.propTypes = {
    studentList: PropTypes.array.isRequired,
    questionOrSerieHistoryData: PropTypes.object.isRequired
};
