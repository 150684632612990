import { OPEN_DRAWER, CLOSE_DRAWER } from 'app/redux/actions/Drawer/drawer.actions';

export const taskDrawer = 'taskDrawer';

export default function drawerReducer(state = { [taskDrawer]: false }, action) {

    switch (action.type) {
        case OPEN_DRAWER:
            return {
                ...state,
                [taskDrawer]: true
            };
        case CLOSE_DRAWER:
            return {
                ...state,
                [taskDrawer]: false
            };
        default:
            return state;
    }
}
