import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { dateFormatter } from '../../../utils/date.functions';


export default function TaskSelector(props) {

    const taskOnChange = (newValue) => {
        if(newValue && newValue.id) {
            props.setSelectedTask(newValue);
        } else {
            props.setSelectedTask(null);
        }
    }; 

    const taskOptionLabel = (option) => {
        if(option.creationDate) {
            return `${option.name}, ${dateFormatter(option.creationDate)}`;
        }
        
        return option.name;
    };

    if(props.tasks === undefined || props.tasks.length === 0) {
        return <div>{'Aucune tache n\'est assignée à ce groupe !'}</div>;
    }

    return (
        <div className="taskSelect">
            <Autocomplete
                options={props.tasks}
                value={props.selectedTask}
                onChange= {(_event, newValue) => taskOnChange(newValue)}
                getOptionLabel={(option) => taskOptionLabel(option)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField
                    {...params}
                    label="Tâches"
                    variant="outlined"
                />}
            />
        </div>
    );
}

TaskSelector.propTypes = {
    selectedTask: PropTypes.object,
    setSelectedTask: PropTypes.func.isRequired,
    groupId: PropTypes.number,
    tasks: PropTypes.array
};
