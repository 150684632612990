import { selectGroupGraphicHistoryData } from 'app/redux/selectors/Question/history.selector';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    postGroupHistoryBetweenTwoDates
} from 'app/redux/actions/Question/History/history.actions';
import { getAllStudentsOfGroupByGroupIdApi } from '../../api/groupApi';
import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { detailedFormatDate, isBeforeToday, numericFormatDate } from '../../utils/date.functions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getNextSunday, getPreviousMonday, updateDate } from '../../assignment/drawerHistory/DrawerHistory.function';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles(() => ({
    tableDate: {
        padding: '10px',
        borderBottom: '1px solid #ddd',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        position: 'sticky',
        left: '0px',
        background: 'white',
        borderCollapse: 'collapse'
    },
    dateEncadrement: {
        'border': '1px solid black',
        'padding': '5px'
    },
    dateGrid: {
        'display': 'flex',
        'justifyContent': 'center',
        'position': 'sticky',
        'left': '0px',
    },
    numberElement: {
        'display': 'flex',
        'justifyContent': 'center',
        'fontWeight': 'bold'
    }
}));

export default function GroupBarChart({ groupId, skillId, forceMondayDate, forceSundayDate }) {

    const groupGraphicHistoryData = useSelector(selectGroupGraphicHistoryData) || [];
    const dispatch = useDispatch();
    const classes = useStyles();
    const [tableData, setTableDate] = useState({});
    const [students, setStudents] = useState([]);

    const [daysWeek, setDaysWeek] = useState([]);
    const [mondayDate, setMondayDate] = useState(getPreviousMonday());
    const [sundayDate, setSundayDate] = useState(getNextSunday());
    const [isNextWeekInFuture, setIsNextWeekInFuture] = useState(true);

    function calculateGoodBadAnswers(currentValue, lastValue) {
        const { dataType, result, goodAnswers, badAnswers } = currentValue;
        const lastGoodBadAnswers = lastValue ?? {
            goodAnswers: 0,
            badAnswers: 0,
            totalAnswers: 0,
        };

        if(dataType === 'QUESTION') {
            return {
                goodAnswers: lastGoodBadAnswers.goodAnswers + (result === 101 ? 1 : 0),
                badAnswers: lastGoodBadAnswers.badAnswers + (result === 101 ? 0 : 1),
                totalAnswers: lastGoodBadAnswers.totalAnswers + 1,
            };
        }

        if(dataType === 'SERIE') {
            return {
                goodAnswers: lastGoodBadAnswers.goodAnswers + goodAnswers,
                badAnswers: lastGoodBadAnswers.badAnswers + badAnswers,
                totalAnswers: lastGoodBadAnswers.totalAnswers + 1,
            };
        }

        return lastGoodBadAnswers;
    }

    function getPercentToShow(tableDataByDate, student, type) {
        const fullName = student.firstName + student.lastName;
        if(type === 'goodAnswers') {
            return `${(tableDataByDate[fullName]?.goodAnswers ?? 0) / tableDataByDate.maxTotalAnswers*100}px`;
        }
        if(type === 'badAnswers') {
            return `${(tableDataByDate[fullName]?.badAnswers ?? 0) / tableDataByDate.maxTotalAnswers*100}px`;
        }
        return '';
    }

    function constructLabel(tableDataByDate, student) {
        let label = '';
        const fullName = student.firstName + student.lastName;
        const goodAnswers = tableDataByDate[fullName]?.goodAnswers ?? 0;
        if(goodAnswers > 0) {
            label += `${goodAnswers} ✔️, `;
        }

        const badAnswers = tableDataByDate[fullName]?.badAnswers ?? 0;
        if(badAnswers > 0) {
            label += `${badAnswers} ❌`;
        }
        return label.replace(/, $/u, '');
    }

    const handleShowPreviousWeek = (firstDate, lastDate) => {
        const updatedMonday = updateDate(firstDate, -7);
        const updatedSunday = updateDate(lastDate, -7);
        setMondayDate(updatedMonday);
        setSundayDate(updatedSunday);
        setIsNextWeekInFuture(false);
    };

    const handleShowNextWeek = (firstDate, lastDate) => {
        const updatedMonday = updateDate(firstDate, 7);
        const updatedSunday = updateDate(lastDate, 7);
        setMondayDate(updatedMonday);
        setSundayDate(updatedSunday);

        if(!isBeforeToday(updateDate(updatedMonday, 7).toDateString())) {
            setIsNextWeekInFuture(true);
        }
    };

    useEffect(() => {
        if(groupId) {
            const searchCriteria = {
                groupId,
                fromWhenDate: forceMondayDate?.toISOString() ?? mondayDate.toISOString(),
                toWhenDate: forceSundayDate?.toISOString() ?? sundayDate.toISOString()
            };

            if(skillId) {
                searchCriteria.skillIds = [skillId];
            }

            dispatch(postGroupHistoryBetweenTwoDates(searchCriteria));
        }
    }, [groupId, skillId, mondayDate, forceMondayDate]);

    useEffect(() => {
        getAllStudentsOfGroupByGroupIdApi(groupId).then((response) => {
            setStudents(response.data);
        });
    }, [groupId]);

    useEffect(() => {
        setTableDate(groupGraphicHistoryData.reduce((acc, currentValue) => {
            const fullName = currentValue.firstName + currentValue.lastName;
            const dateString = currentValue.date.split('T')[0];

            if(!acc[dateString]) {
                acc[dateString] = { date: dateString, maxTotalAnswers: 0 };
            }

            const newData = calculateGoodBadAnswers(currentValue, acc[dateString][fullName]);

            acc[dateString][fullName] = newData;
            if(newData.totalAnswers > acc[dateString].maxTotalAnswers) {
                acc[dateString].maxTotalAnswers = newData.totalAnswers;
            }

            return acc;
        }, {}));
    }, [groupGraphicHistoryData]);

    useEffect(() => {
        if((mondayDate && sundayDate) || (forceMondayDate && forceSundayDate)) {
            const dates = [];
            const currentDate = cloneDeep(forceMondayDate ?? mondayDate);
            const endDate = cloneDeep(forceSundayDate ?? sundayDate);

            for(let date = currentDate; date.getTime() <= endDate.getTime(); date.setDate(date.getDate() + 1)){
                const formattedDate = `${date.getFullYear()}-${date.getMonth() < 9 ? `0${  date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${  date.getDate()}` : date.getDate()}`;
                dates.push(formattedDate);
            }
            setDaysWeek(dates);
        }
    }, [mondayDate, forceMondayDate]);

    return (
        <>
            {!forceSundayDate || !forceMondayDate
                ? <Grid className={classes.dateGrid}>
                    <div>
                        <IconButton aria-label="semaine precedente"
                            onClick={() => handleShowPreviousWeek(mondayDate, sundayDate)}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <span className={classes.dateEncadrement}>
                            {`du ${numericFormatDate(mondayDate)} au ${numericFormatDate(sundayDate)}`}
                        </span>
                        <IconButton aria-label="semaine suivante"
                            onClick={() => handleShowNextWeek(mondayDate, sundayDate)}
                            disabled={isNextWeekInFuture}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </div>
                </Grid>
                : <div style={{ padding: '20px', position: 'sticky', left: 0 }}><h3 style={{ marginBottom: '20px', color: '#444' }}>Historique</h3></div>}
            <div style={students?.length < 10 ? { padding: '20px', boxSizing: 'content-box' } : { padding: '20px', width: '500px' } }>
                <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'separate' }}>
                    <thead>
                        <tr>
                            <th className={classes.tableDate}>Date</th>
                            {students.map(student =>
                                <th key={student.id} style={{
                                    padding: '10px',
                                    borderBottom: '1px solid #ddd',
                                    borderRight: '1px solid #ddd',
                                    textAlign: 'center'
                                }}>
                                    <span>{student.firstName}</span>
                                    <br/>
                                    <span>{student.lastName}</span>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {daysWeek.sort().map(date => (
                            <tr key={date}>
                                <td className={classes.tableDate}><strong>{detailedFormatDate(new Date(date))}</strong></td>
                                {students.map(student =>
                                    <td key={student.id} style={{
                                        height: '100px',
                                        borderBottom: '1px solid #ddd',
                                        borderRight: '1px solid #ddd'
                                    }}>
                                        { tableData?.[date] &&
                                        <Tooltip title={constructLabel(tableData[date], student)}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'end',
                                                height: '100px'
                                            }}>
                                                <div style={{
                                                    background: 'red',
                                                    height: getPercentToShow(tableData[date], student, 'badAnswers'),
                                                    stroke: 'red',
                                                    strokeWidth: 0
                                                }}></div>
                                                <div style={{
                                                    background: 'green',
                                                    height: getPercentToShow(tableData[date], student, 'goodAnswers'),
                                                    stroke: 'green',
                                                    strokeWidth: 0
                                                }}></div>
                                            </div>
                                        </Tooltip>
                                        }
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

GroupBarChart.propTypes = {
    skillId: PropTypes.number,
    groupId: PropTypes.number,
    forceMondayDate: PropTypes.object,
    forceSundayDate: PropTypes.object
};