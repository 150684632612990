import {
    GET_QUESTION_REPORTING_BY_ID_SUCCESS,
    PATCH_QUESTION_REPORTING_TRAITED_SUCCESS,
    PATCH_QUESTION_REPORTING_DENIED_SUCCESS,
    RESET_QUESTION_REPORTING
} from '../../../actions/Question/QuestionReporting/questionReporting.actions';

export const questionReportingByQuestionStore = 'questionReportingByQuestionStore';

export default function questionReportingReducer(state = {
    [questionReportingByQuestionStore]: []
}, action) {
    switch (action.type) {
        case GET_QUESTION_REPORTING_BY_ID_SUCCESS:
            return {
                ...state,
                [questionReportingByQuestionStore]: action.payload
            };
        case RESET_QUESTION_REPORTING:
            return {
                ...state,
                [questionReportingByQuestionStore]: []
            };
        case PATCH_QUESTION_REPORTING_DENIED_SUCCESS:
        case PATCH_QUESTION_REPORTING_TRAITED_SUCCESS:
            return {
                ...state,
                questionReportingByQuestionStore: state[questionReportingByQuestionStore]
                    .filter((report) => report.id !== action.payload.id)
            };
        default:
            return state;
    }
}
