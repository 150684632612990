import React from 'react';
import PropTypes from 'prop-types';

import './Questions.scss';
import { withRouter } from 'react-router-dom';
import QuestionTablePage from './QuestionTablePage/QuestionTablePage';

export function Questions() {

    return (
        <section data-testid="questions">
            <QuestionTablePage />
        </section>
    );
}

Questions.propTypes = {
    match: PropTypes.object.isRequired
};


export default withRouter(Questions);
