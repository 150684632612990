/* eslint-disable complexity */
/* eslint-disable no-loop-func */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './SkillExpertContent.scss';
import { connect } from 'react-redux';
import ExcilyenCard from '../../common/excilyenCard/ExcilyenCard';
import { selectExcilyensDetailedBySkill } from '../../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import { patchUserSkillModerator } from '../../redux/actions/User/UserSkills/userSkill.actions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Tooltip, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import ExcilyensLoader from '../../excilyens/components/ExcilyensLoader';
import { GET_ALL_EXCILYENS } from '../../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import { createLoadingSelectorForStoreValue } from '../../redux/selectors/APISelector/APISelector';
import { selectUserAskedCoaches, selectUserCoaches } from '../../redux/selectors/Coaching/coaching.selector';
import { getAskedCoachesByStudentId, getCoachesByStudentId } from '../../redux/actions/Coaching/coaching.actions';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';


export class SkillExpertContent extends Component {

    // eslint-disable-next-line class-methods-use-this
    currentUserId = () => AuthenticationContainer.getCurrentUserId();

    componentDidMount() {
        this.props.getAskedCoachesByStudentId(this.currentUserId());
        this.props.getCoachesByStudentId(this.currentUserId());
    }

    nameModerator = (user, skill) => {
        const map = {
            moderator: true
        };
        this.props.patchUserSkillModerator(user.id, skill.id, map);
    };

    handleOnCardClick = (id) => {
        const url = `/excilyens/${id}`;
        this.props.history.push(url);
    };

    render() {
        const users = this.props.allExcilyens;
        const mapUsers = new Map([
            [
                1,
                []
            ],
            [
                2,
                []
            ],
            [
                3,
                []
            ],
            [
                4,
                []
            ],
            [
                5,
                []
            ]
        ]);
        let index = 0;
        let isModerator = false;
        let userLevel = 0;
        let newItems = [];
        let countDisplayed = 0;

        if(users && users.length > 0) {

            users.forEach((element) => {

                element.skillDetailedApiDtos.forEach((skill) => {
                    if(skill.skillName === this.props.skill.skillName) {
                        isModerator = skill.moderator;
                        userLevel = skill.userLevel;

                        userLevel = userLevel >= 1 && userLevel <= 5 ? userLevel : 1;
                        mapUsers.get(userLevel).push([
                            element,
                            isModerator
                        ]);
                    }
                    if(userLevel === null) {
                        userLevel = 1;
                    }
                });
            });

            for(let ii = 1; ii < 6; ii++) {
                mapUsers.get(ii).sort((user1, user2) => user2[0].firstName.localeCompare(user1[0].firstName));
            }

            for(const [
                // eslint-disable-next-line no-unused-vars
                key,
                // eslint-disable-next-line no-unused-vars
                value
            ] of mapUsers) {
                if(key >= this.props.levelRequired) {
                    value.forEach((element) => {
                        index += 1;
                        countDisplayed += 1;
                        newItems.push(<Grid className="fadeIn" item xs={12} sm={4} md={4} lg={2} xl={2} key={index}>
                            <ExcilyenCard excilyen={element[0]}
                                skill={this.props.skill} key={index}
                                isAlreadyAskedAsCoach={Boolean(this.props.askedCoachs) && this.props.askedCoachs
                                    .filter((askedCoach) => askedCoach.id === element[0].id).length > 0}
                                isAlreadyCoach={Boolean(this.props.userCoachs) && this.props.userCoachs
                                    .filter((coach) => coach.id === element[0].id).length > 0}/>
                            {this.props.user.roles.some((role) => role.name === 'ADMIN') &&
                                <Tooltip title={ element[1]
                                    ? 'Cet utilisateur est déjà un modérateur'
                                    : '' }>
                                    <span>
                                        <Button disabled = {element[1]} color="primary" id="name-moderator" data-testid="name-moderator" onClick={() => {
                                            this.nameModerator(element[0], this.props.skill);
                                        }}>
                                            <Icon>person_add</Icon> Nommer modérateur
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        </Grid>);
                    });
                } else {
                    value.forEach((element) => {
                        index += 1;
                        newItems.push(<Grid className="fadeOut" item xs={12} sm={4} md={4} lg={2} xl={2} key={index}>
                            <ExcilyenCard excilyen={element[0]} skill={this.props.skill} key={index}
                                isAlreadyAskedAsCoach={Boolean(this.props.askedCoachs) && this.props.askedCoachs
                                    .filter((askedCoach) => askedCoach.id === element[0].id).length > 0}
                                isAlreadyCoach={Boolean(this.props.userCoachs) && this.props.userCoachs
                                    .filter((coach) => coach.id === element[0].id).length > 0}/>
                            {this.props.user.roles && this.props.user.roles.some((role) => role.name === 'ADMIN') &&
                                <Tooltip title={ element[1]
                                    ? 'Cet utilisateur est déjà un modérateur'
                                    : '' }>
                                    <span>
                                        <Button disabled = {element[1]} color="primary" id="name-moderator" data-testid="name-moderator" onClick={() => {
                                            this.nameModerator(element[0], this.props.skill);
                                        }}>
                                            <Icon>person_add</Icon> Nommer modérateur
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        </Grid>);
                    });
                }
            }
        }
        newItems = newItems.reverse();
        let elementsPerLine = 0;

        switch (this.props.width) {
            case 'xs':
                elementsPerLine = 1;
                break;
            case 'sm':
                elementsPerLine = 3;
                break;
            case 'md':
                elementsPerLine = 3;
                break;
            case 'lg':
                elementsPerLine = 6;
                break;
            default:
                elementsPerLine = 6;
        }

        let loader = null;
        switch (this.props.width) {
            case 'xl':
                loader = <ExcilyensLoader/>;
                break;
            case 'lg':
                loader = <ExcilyensLoader padding={30}/>;
                break;
            case 'md':
                loader = <ExcilyensLoader column={5} padding={30}/>;
                break;
            case 'sm':
                loader = <ExcilyensLoader column={4} padding={30}/>;
                break;
            case 'xs':
                loader = <ExcilyensLoader column={2} padding={70}/>;
                break;
            default:
                loader = <ExcilyensLoader/>;
        }

        return (

            <AnimateHeight
                height={this.props.isFetchingExcilyens
                    ? 270
                    : countDisplayed === 0
                        ? 70
                        : this.props.user.roles && this.props.user.roles.some((role) => role.name === 'ADMIN')
                            ? 330 * (countDisplayed % elementsPerLine === 0
                                ? countDisplayed / elementsPerLine
                                : Math.floor(countDisplayed / elementsPerLine) + 1)
                            : 270 * (countDisplayed % elementsPerLine === 0
                                ? countDisplayed / elementsPerLine
                                : Math.floor(countDisplayed / elementsPerLine) + 1)
                }
                duration={1000}
                className="AnimateHeight"
                contentClassName="Content"
                data-testid="animate-height"
            >

                {this.props.isFetchingExcilyens
                    ? loader
                    : (countDisplayed > 0
                        ? <Grid container direction="row" item xs={12} sm={12} md={12} lg={12}>
                            {newItems}
                        </Grid>
                        : <Typography id="no-expert" variant="subtitle1" component="h3" data-testid="no-expert" noWrap>
                            Aucun excilyen de ce niveau
                        </Typography>
                    )}

            </AnimateHeight>
        );
    }
}

SkillExpertContent.propTypes = {
    levelRequired: PropTypes.number,
    history: PropTypes.object,
    width: PropTypes.string,
    skill: PropTypes.shape({
        skillName: PropTypes.string,
        familyName: PropTypes.string,
        familyNameOptional: PropTypes.string,
        categoryName: PropTypes.string,
        description: PropTypes.string,
        level: PropTypes.number,
        imageUrl: PropTypes.string
    }),
    user: PropTypes.shape({
        email: PropTypes.string,
        promo: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile: PropTypes.string,
        roles: PropTypes.array,
        imageUrl: PropTypes.string
    }),
    userCoachs: PropTypes.array,
    askedCoachs: PropTypes.array,
    allExcilyens: PropTypes.array,
    getUserDetailedBySkillSet: PropTypes.func,
    patchUserSkillModerator: PropTypes.func,
    isFetchingExcilyens: PropTypes.bool,
    getCoachesByStudentId: PropTypes.func,
    getAskedCoachesByStudentId: PropTypes.func
};

const mapStateToProps = (state) => ({
    allExcilyens: selectExcilyensDetailedBySkill(state),
    isFetchingExcilyens: createLoadingSelectorForStoreValue([GET_ALL_EXCILYENS], selectExcilyensDetailedBySkill)(state),
    userCoachs: selectUserCoaches(state),
    askedCoachs: selectUserAskedCoaches(state)
});

export function mapDispatchToProps(dispatch) {
    return {
        patchUserSkillModerator: (email, skillName, map) => dispatch(patchUserSkillModerator(email, skillName, map)),
        getCoachesByStudentId: (userId) => dispatch(getCoachesByStudentId(userId)),
        getAskedCoachesByStudentId: (userId) => dispatch(getAskedCoachesByStudentId(userId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SkillExpertContent));
