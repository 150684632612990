
// Give the date of the last Monday
export function getPreviousMonday() {
    const date = new Date();
    // Day equals to 7 if it's Sunday
    const day = date.getDay() || 7;
    const prevMondayDate = day === 1 ? date.getDate() : date.getDate() + 1 - day;
    const prevMonday = new Date(date.getFullYear(), date.getMonth(), prevMondayDate, 0, 0, 0, 0);
    return prevMonday;
}

// Give the date of the Next Sunday
export function getNextSunday() {
    const nextSunday = new Date(getPreviousMonday().getFullYear(), getPreviousMonday().getMonth(), getPreviousMonday().getDate() + 6, 23, 59, 59, 59);
    return nextSunday;
}

// Update a date with the date, it set methode and the number to add
export function updateDate(initialDate, increment) {
    const newValue = new Date(initialDate);
    newValue.setDate(initialDate.getDate() + increment);
    return newValue;
}
