
import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import { Divider } from 'antd';
import { scroller } from 'react-scroll';
// https://www.npmjs.com/package/react-scroll
export function GroupListItem(props) {


    const scrollToSection = () => {
        scroller.scrollTo('detailsPaperCard', {
            duration: 1100,
            delay: 50,
            isDynamic: true,
            offset: -76,
            smooth: 'easeInOutQuart',
            to: 'detailsPaperCard'
        });
    };

    const displayGroupMember = () => {
        scrollToSection();
        props.groupUser(props.groupe);
    };

    return (
        <div>
            <ListItem id="studentCollapsible" button onClick={displayGroupMember}>
                <ListItemIcon >
                    < GroupIcon/>
                </ListItemIcon>
                <ListItemText primary={`${props.groupe.name} `}/>
            </ListItem>
            <Divider style={{ margin: '0px' }}/>
        </div>
    );
}

GroupListItem.propTypes = {
    groupe: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
    }),
    groupUser: PropTypes.func.isRequired
};
