import { questionTypeEnum } from 'app/utils/QuestionTypeEnum';

function encodeHTML(text) {
    const element = document.createElement('div');
    element.textContent = text;
    return element.innerHTML;
}

export const spanStatement = (id, firstAcceptedAnswer) => `<span class="texteatrou" id="trou_${id}">${encodeHTML(firstAcceptedAnswer)}</span>`;
export const inputStatement = (id) => `<input class="champTrou" id="trou_${id}"/>`;
export const inputRegex = /<input[^>]*id="trou_(\d+)"[^>]*>/gu;

export function fillInStatement(statement, answers)
{
    let newStatement = statement;
    const matches = statement.match(inputRegex);

    if(matches) {
        for(let match = 0; match < matches.length; match++) {
            newStatement = statement.replace(matches[match], answers[match]);
        }
    }
    
    return newStatement;
}

export function parseQuestionStatementInputToSpan(questionStatement, firstAcceptedAnswerList) {
    let ordinal = 0;
    const replacedStatement = questionStatement.replace(inputRegex, (match, id) => {
        ordinal++;
        const foundAnswer = firstAcceptedAnswerList.find((elem) => elem.ordinal === ordinal);
        const firstAcceptedAnswer = foundAnswer ? foundAnswer.acceptedAnswer : '';
        return spanStatement(id, firstAcceptedAnswer);
    });

    return replacedStatement;

}

export function parseQuestionStatementSpanToInput(questionStatement) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(questionStatement, 'text/html');
    const spans = doc.querySelectorAll('span.texteatrou');

    spans.forEach((span) => {
        const ordinal = span.getAttribute('id').replace('trou_', '');
        const input = document.createElement('input');
        input.className = 'champTrou';
        input.id = `trou_${ordinal}`;
        input.autoComplete = 'off';
        span.parentNode.replaceChild(input, span);
    });

    return doc.body.innerHTML;
}

/*
 * Cette fonction retourne la liste des mots acceptés dans l'ordre avec leurs ordinaux
 */
export function getFirstAcceptedAnswerList(question) {
    if(question.class === questionTypeEnum.QuestionTexteATrouType) {
        return question.answers.map((answer) => ({ ordinal: answer.ordinal, acceptedAnswer: answer.acceptedAnswers[0] }));
    }
    return null;
}

/*
 * Cette fonction retourne la liste des mots acceptés dans l'ordre sans leurs ordinaux
 */
export function getFirstAcceptedAnswerStringList(question) {
    if(question.class === questionTypeEnum.QuestionTexteATrouType) {
        return question.answers.map((answer) => (answer.acceptedAnswers[0]));
    }
    return null;
}

/*
 * Cette fonction permet de parser l'intitulé d'une question a trou pour supprimer les champs <input />
 * correspondants aux trous et les adapter au ckeditor avec des balises <span />
 */
export function parseQuestionATrou(question) {
    const firstAcceptedAnswerList = getFirstAcceptedAnswerList(question);
    return {
        ...question,
        statement: parseQuestionStatementInputToSpan(question.statement, firstAcceptedAnswerList)
    };
}

export function mapTrouWithNewOrdinal(trou, index) {
    return { ...trou, ordinal: index + 1 };
}

export function questionATrouAnswerAcceptedIsValid(acceptedAnswer) {
    return (acceptedAnswer !== '' && acceptedAnswer?.length > 0);
}

export function questionATrouAnswerIsValid(answerTargeted) {
    if(!(answerTargeted?.acceptedAnswers?.length)) {
        return false;
    }
    const duplicatedvalues = answerTargeted.acceptedAnswers.filter((currentValue, currentIndex) => answerTargeted.acceptedAnswers.indexOf(currentValue) !== currentIndex);
    if(duplicatedvalues.length > 0) {
        return false;
    }
    const validAcceptedAnswers = answerTargeted.acceptedAnswers.filter((acceptedAnswer) => questionATrouAnswerAcceptedIsValid(acceptedAnswer));
    return validAcceptedAnswers.length === answerTargeted.acceptedAnswers.length;
}

export function atLeastOneQuestionATrouAnswer(questionATrou) {
    return questionATrou?.answers?.length > 0;
}
