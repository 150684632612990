import React from 'react';
import PropTypes from 'prop-types';

import { Chip, Icon, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';

import noImgFound from 'assets/images/no-img-found.png';

import SkillLevelRating from 'app/common/skillLevelRating/SkillLevelRating';
import SkillFavoriteButton from 'app/skill/components/SkillContent/SkillFavorite';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';

import './SkillHeader.scss';

function SkillHeader({ skill, isModerator }) {
    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const user = useSelector(selectCurrentUser);

    return (
        <div className="skill-header">
            <div className="left-header" id="left-header">
                <img
                    className="skill-icon"
                    alt={skill.name}
                    src={skill.imageUrl ? skill.imageUrl : noImgFound}
                />

                <div className="skill-header-title">
                    {skill.name}
                    {isModerator &&
                        <Tooltip title="Vous êtes modérateur sur cette compétence">
                            <Icon
                                id="icon-moderator"
                                className="icon"
                                data-testid="icon-moderator"
                            >
                                verified_user
                            </Icon>
                        </Tooltip>
                    }
                </div>

                <SkillFavoriteButton userId={user.id ?? 0} skillId={skill.id}/>

                {sizeUpMd &&
                    <>
                        <div className="field">Famille</div>
                        {skill?.familyInternalDto?.name &&
                            <Chip
                                className="field-chip"
                                size="small"
                                label={skill.familyInternalDto.name}
                            />
                        }

                        {skill?.familyInternalDtoOptional?.name &&
                            <Chip
                                className="field-chip"
                                size="small"
                                label={skill.familyInternalDtoOptional.name}
                            />
                        }

                        <div className="field">Catégorie</div>
                        {skill?.categoryInternalDto?.name &&
                            <Chip
                                className="field-chip"
                                size="small"
                                label={skill.categoryInternalDto.name}
                            />
                        }
                    </>
                }
            </div>


            <div className="right-header" id="right-header">
                <div className="field align-right star-rater" style={{ margin: 0 }}>
                    {sizeUpMd && 'Mon niveau :'}
                    <SkillLevelRating
                        user={user}
                        skillName={skill.name}
                        skillId={skill.id}
                    />
                </div>

            </div>

        </div>
    );
}

SkillHeader.propTypes = {
    skill: PropTypes.object.isRequired,
    isModerator: PropTypes.bool.isRequired
};

export default SkillHeader;
