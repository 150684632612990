import axiosClient from './utils/axiosClient';

export const SKILL_PROJECTS_SET_URI = 'skillprojects';

export function postSkillProjectsApi(skillProject) {
    return axiosClient.post(SKILL_PROJECTS_SET_URI, skillProject);
}

export function deleteSkillProjectsApi(skillProject) {
    return axiosClient.delete(SKILL_PROJECTS_SET_URI, {
        data: skillProject
    });
}
