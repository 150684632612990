import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getNextParcoursHistoryByUserId } from 'app/redux/actions/Parcours/parcours.actions';
import { selectParcours } from 'app/redux/selectors/Parcours/parcours.selector';
import '../Message.scss';
import oxa from 'assets/images/Logochatbot.svg';

export default function MessageChapter(props) {
    const dispatch = useDispatch();
    const parcoursId = useSelector(selectParcours);

    const [isCheckButtonVisible, setIsCheckButtonVisible] = useState(true);

    function sendResponse() {
        setIsCheckButtonVisible(false);
        dispatch(getNextParcoursHistoryByUserId(parcoursId));
    }

    function addClassesToCodeBlocks(html) {
        return html.replace(
            /<code class="(language-[^"]+)">/gu,
            '<code class="$1 hljs" data-highlighted="yes">'
        );
    }

    const contentWithClasses = addClassesToCodeBlocks(props.message.content?.header || '');

    return (
        <div className="message oxa">
            <img id="avatar" src={oxa} />
            <div className="header" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                    dangerouslySetInnerHTML={{ __html: contentWithClasses }}
                    className="content bold course-content"
                ></div>
                {isCheckButtonVisible && (
                    <IconButton
                        style={{
                            bottom: '0',
                            right: '0',
                            position: 'absolute',
                            color: '#0076ba',
                            transform: 'translate(50%, 50%)',
                        }}
                        onClick={sendResponse}
                    >
                        <CheckCircleIcon style={{ fontSize: '2.3rem' }} />
                    </IconButton>
                )}
            </div>
        </div>
    );
}

MessageChapter.propTypes = {
    message: PropTypes.string
};
