import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Collapse,
    IconButton,
    InputLabel,
    List,
    ListSubheader,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Progress } from 'antd';

import DrawerHistoryGroupDisplay from './DrawerHistoryGroupDisplay';
import DrawerHistoryQuestionDisplay from './DrawerHistoryQuestionDisplay';
import DrawerHistorySerieDisplay from './DrawerHistorySerieDisplay';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        color: 'black',
        overflow: 'auto',
        maxHeight: 300,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 'normal',
        zIndex: 0,
        justifyContent: 'flex-end'
    },
    listSection: {
        backgroundColor: theme.palette.background.paper,
        marginLeft: 25,
        zIndex: 0
    },
    ul: {
        backgroundColor: 'inherit',
        marginLeft: 25,
        padding: 0
    },
    date: {
        padding: '12px'
    },
    line: {
        flex: '1',
        height: '3px',
        backgroundColor: '#000'
    }
}));

function DrawerDailyHistoryDisplay(props) {

    const classes = useStyles();

    const [openDailyHistory, setOpenDailyHistory] = useState(false);

    const [row, setRow] = useState({
        badAnswers: 0,
        goodAnswers: 0,
        questionsNotDone: 0
    });

    const [total, setTotal] = useState(0);
    const [active, setActive] = useState(0);
    const [nonActive, setNonActive] = useState(0);
    const [percentActive, setPercentActive] = useState(0);

    const handleOpenDailyHistory = () => {
        setOpenDailyHistory(!openDailyHistory);
    };

    useEffect(() => {
        if(props.dateHistory) {
            const row2 = {
                badAnswers: 0,
                goodAnswers: 0,
                questionsNotDone: 0
            };
            props.dateHistory[1].forEach((dateHistory) => {
                if(dateHistory.dataType === 'QUESTION') {
                    if(dateHistory.result && dateHistory.result > 70) {
                        row2.goodAnswers += 1;
                    } else {
                        row2.badAnswers += 1;
                    }
                } else {
                    row2.badAnswers += dateHistory.badAnswers;
                    row2.goodAnswers += dateHistory.goodAnswers;
                    row2.questionsNotDone += dateHistory.questionsNotDone;
                }
            });
            setRow(row2);
        }
        if(props.isGroup) {
            const group = props.dateHistory[1];
            const activeCount = group.filter((person) => person.timeFirstQuestionAnswered !== null).length;
            const nonActiveCount = group.filter((person) => person.timeFirstQuestionAnswered === null).length;
            const totalCount = activeCount + nonActiveCount;
            const percentActiveCount = totalCount > 0 ? (activeCount / totalCount) * 100 : 0;

            setActive(activeCount);
            setNonActive(nonActiveCount);
            setTotal(totalCount);
            setPercentActive(percentActiveCount);
        }

    }, [props.dateHistory, props.isGroup]);


    const resultGlobal = (data) => Math.round(data.goodAnswers / (data.goodAnswers + data.badAnswers + data.questionsNotDone) * 100);

    const showDate = props.dateHistory ? props.dateHistory[0] : 'Unknow';
    const showHistory = props.dateHistory ? props.dateHistory[1] : [];

    return (
        <>
            <ListSubheader className={classes.root} key={showDate}>
                <h3 className={classes.date}> {showDate} </h3>
                <div>
                    {props.isGroup && props.dateHistory
                        ? <><InputLabel>{total} personnes</InputLabel>
                            <Tooltip title={`${active} actif(s) / ${nonActive} inactif(s)`}>
                                <div>
                                    <Progress
                                        percent={100}
                                        success={{ percent: percentActive }}
                                        trailColor="#A9A9A9"
                                        strokeColor="#FF0000"
                                        showInfo={false}
                                    />
                                </div>
                            </Tooltip></>
                        : <><InputLabel>{row.goodAnswers + row.badAnswers} questions</InputLabel>
                            <Tooltip title={`${row.goodAnswers} correctes / ${row.badAnswers} incorrectes`}>
                                <div>
                                    <Progress
                                        percent={(row.goodAnswers + row.badAnswers) /
                                        (row.goodAnswers + row.badAnswers + row.questionsNotDone) * 100}
                                        success={{ percent: resultGlobal(row) }}
                                        trailColor="#A9A9A9"
                                        strokeColor="#FF0000"
                                        showInfo={false} />
                                </div>
                            </Tooltip></>
                    }
                </div>

                <IconButton
                    aria-label={`déplier l'historique du ${showDate}`}
                    onClick={handleOpenDailyHistory}
                    data-testid="expand-result-question-or-serie"
                >
                    {openDailyHistory ? <ExpandLess/> : <ExpandMore/>}
                </IconButton>
            </ListSubheader>
            <Collapse in={openDailyHistory}>
                <List className={classes.listSection} subheader={<li />}>
                    {showHistory.map((rowData) => (
                        props.isGroup
                            ? <DrawerHistoryGroupDisplay
                                className={classes.question}
                                key={`group${rowData.id}:${rowData.date}`}
                                groupHistory={rowData}
                                onClickQuestion={props.onClickQuestion}
                            />
                            : rowData.dataType === 'SERIE'
                                ? <DrawerHistorySerieDisplay
                                    badAnswerFilter={props.badAnswerFilter}
                                    className={classes.ul}
                                    key={`serie${rowData.id}:${rowData.date}`}
                                    serieHistory={rowData}
                                    onClickQuestion={props.onClickQuestion}
                                />
                                : (!props.badAnswerFilter || (props.badAnswerFilter && rowData.result !== 1))
                                    ? <DrawerHistoryQuestionDisplay
                                        className={classes.question}
                                        key={`question${rowData.id}:${rowData.date}`}
                                        questionHistory={rowData}
                                        onClickQuestion={props.onClickQuestion}
                                    />
                                    : <div data-testid="empty-block-question"></div>
                    ))
                    }
                </List>
            </Collapse>
        </>
    );
}

DrawerDailyHistoryDisplay.propTypes = {
    badAnswerFilter: PropTypes.bool,
    dateHistory: PropTypes.array.isRequired,
    isGroup: PropTypes.bool.isRequired,
    taskId: PropTypes.number,
    onClickQuestion: PropTypes.func.isRequired
};

export default DrawerDailyHistoryDisplay;
