
import PropTypes from 'prop-types';
import React, { Component, forwardRef, Fragment } from 'react';
import './MissionsTable.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import MaterialTable, { MTableToolbar } from 'material-table';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { ArrowDownward } from '@material-ui/icons';
import AddEditMission from '../AddEditMission/AddEditMission';
import { Popup } from '../../Popup/Popup';
import { MissionDetailsPopup } from '../../Popup/MissionDetailsPopup';
import { selectCurrentProject } from '../../../../redux/selectors/Project/projects.selector';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';
import { getAllUsers } from '../../../../redux/actions/User/user.actions';
import { deleteMission } from '../../../../redux/actions/Mission/MissionSet/missionSet.actions';
import { DescriptionPopup } from '../../Popup/DescriptionPopup';

export class MissionsTable extends Component {

    state = {
        open: false
    };

    componentDidMount() {
        this.props.getAllUsers();
    }

    openModal = () => {
        this.setState({
            open: true
        });
    };

    closeModal = () => {
        this.setState({
            open: false
        });
    };

    onChangeRowsPerPage = (pageSize) => localStorage.setItem('displayMaterialTableRessourcesPageSize', pageSize);

    displayDate = (dateParams) => {
        if(!dateParams) {
            return <p>Non renseignée</p>;
        }
        const date = new Date(dateParams);
        return <p>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
        </p>;
    };

    deleteMission = (idMission) => {
        const missionToDelete = {
            idMission,
            idProject: this.props.project.id
        };

        this.props.deleteMission(missionToDelete);
    };

    displayExcilyens = (intervenants) => (intervenants.length > 0)
        ? [
            this.displayFirstExcilyens(intervenants),
            (intervenants.length > 2)
                ? this.displayOtherExcilyensInPopup(intervenants)
                : <></>
        ]
        : <></>;

    displayFirstExcilyens = (intervenants) => {
        const sub = intervenants.slice(0, 2);
        return sub.map((user) => sub.length - 1 === sub.indexOf(user)
            ? <Fragment key={user.id}>
                <Link
                    key={user.id}
                    to={`/excilyens/${user.id}`}
                    style={{ textDecoration: 'none' }}
                >
                    {user.firstName} {user.lastName}
                </Link>
            </Fragment>
            : <Fragment key={user.id}>
                <Link
                    key={user.id}
                    to={`/excilyens/${user.id}`}
                    style={{ textDecoration: 'none' }}
                >
                    {user.firstName} {user.lastName}
                </Link>,{' '}
            </Fragment>);
    };

    displayOtherExcilyensInPopup = (intervenants) => <Popup intervenants data={intervenants} number={intervenants.length - 2} />;

    /*
     * Soit on détermine l'index du dernier espace possible de la description qui respecte la longueur maximale autorisée, et l'on tronque la string
     * soit on coupe la description au milieu d'un mot s'il n'y a pas d'espace ou si le premier mot est supérieur à la longueur maximale autorisée
     */
    sliceDescription = (description, maxLength) => {

        if(description.indexOf(' ', 0) !== -1 && description.indexOf(' ', 0) < maxLength - 1) {
            let spaceNumber = 1;
            while(description.indexOf(' ', spaceNumber) !== -1 && description.indexOf(' ', spaceNumber) < maxLength - 1) {
                spaceNumber++;
            }
            return description.slice(0, spaceNumber - 1);
        }
        return description.slice(0, maxLength - 1);
    };

    displayDescription = (description) => {
        let descriptionDisplay = description;

        const maxLength = 44;

        if(descriptionDisplay.length > maxLength) {
            descriptionDisplay = this.sliceDescription(descriptionDisplay, maxLength);

            return (
                <p>
                    <Tooltip title="Description">
                        <>
                            {descriptionDisplay}
                        </>
                    </Tooltip>
                    <DescriptionPopup description={description}/>
                </p>
            );
        }
        return (
            <Tooltip title="Description">
                <div className="description" dangerouslySetInnerHTML = {{ __html: description }}/>
            </Tooltip>
        );
    };

    displayStack = (stack) => {
        const stackCopy = [...stack];
        if(stackCopy.length > 4) {
            const others = stackCopy.length - 3;
            stackCopy.splice(3);
            return (
                <p>
                    {
                        stackCopy.map((skill) => ((stackCopy.length - 1) === stackCopy.indexOf(skill))
                            ? (<Fragment key={skill.id}>
                                <Link
                                    key={skill.id}
                                    to={`/skills/${skill.skillName}`}
                                    style={{ textDecoration: 'none' }}
                                >{skill.skillName}
                                </Link>
                                <Popup stack data={stack} number={others}/>
                            </Fragment>)
                            : (<Fragment key={skill.id}>
                                <Link
                                    key={skill.id}
                                    to={`/skills/${skill.skillName}`}
                                    style={{ textDecoration: 'none' }}
                                >{skill.skillName}</Link>,{' '}
                            </Fragment>))
                    }
                </p>
            );
        }
        return (
            <p>
                {
                    stackCopy.map((skill) => ((stackCopy.length - 1) === stackCopy.indexOf(skill))
                        ? (<Link
                            key={skill.id}
                            to={`/skills/${skill.skillName}`}
                            style={{ textDecoration: 'none' }}>{skill.skillName}
                        </Link>)
                        : (<Fragment key={skill.id}>
                            <Link
                                key={skill.id}
                                to={`/skills/${skill.skillName}`}
                                style={{ textDecoration: 'none' }}
                            >{skill.skillName}
                            </Link>,{' '}
                        </Fragment>))
                }
            </p>
        );
    };

    sortStartDate = (dataA, dataB) => {
        if(dataA.startDate === null) {
            return -1;
        }
        if(dataB.startDate === null) {
            return 1;
        }
        return dataA.startDate > dataB.startDate ? 1 : -1;
    };

    sortEndDate = (dataA, dataB) => {
        if(dataA.endDate === null) {
            return -1;
        }
        if(dataB.endDate === null) {
            return 1;
        }
        return dataA.endDate > dataB.endDate ? 1 : -1;
    };

    render() {

        const actionsColumn = {
            title: 'Actions',
            sorting: false,
            show: true,
            cellStyle: {
                textAlign: 'center',
                width: isWidthUp('md', this.props.width) ? '210px' : '80px'
            },
            headerStyle: {
                textAlign: 'center',
                width: isWidthUp('md', this.props.width) ? '210px' : '80px'
            },
            render: (rowData) => <div className="actions">
                <span className="actions-buttons">
                    <AddEditMission trueIfAddAndFalseIfEdit={false} missionToEdit={rowData}/>
                    {this.props.user.roles && this.props.user.roles.some((role) => role.name === 'ADMIN') &&
                        <Button
                            className="button-delete-mission"
                            color="primary"
                            onClick={() => {
                                if(window.confirm('Êtes-vous sûr de vouloir supprimer cette mission ?')) {
                                    this.deleteMission(rowData.id);
                                }
                            }}>
                            <Icon>delete</Icon>
                        </Button>
                    }
                </span>
                {isWidthDown('sm', this.props.width) && <MissionDetailsPopup mission={rowData}/>}
            </div>
        };

        const columnsForDesktop = [
            {
                title: 'Intervenants',
                field: 'intervenants',
                sorting: false,
                render: (rowData) => this.displayExcilyens(rowData.listUser),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(800px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(800px)'
                }
            },
            {
                title: 'Début',
                field: 'debut',
                customSort: (dataA, dataB) => this.sortStartDate(dataA, dataB),
                render: (rowData) => this.displayDate(rowData.startDate),
                cellStyle: {
                    width: 100,
                    textAlign: 'left',
                    padding: '5'
                },
                headerStyle: {
                    width: 100,
                    textAlign: 'left',
                    padding: '5'
                }
            },
            {
                title: 'Fin',
                field: 'fin',
                customSort: (dataA, dataB) => this.sortEndDate(dataA, dataB),
                render: (rowData) => this.displayDate(rowData.endDate),
                cellStyle: {
                    width: 100,
                    textAlign: 'left',
                    padding: '5'
                },
                headerStyle: {
                    width: 100,
                    textAlign: 'left',
                    padding: '5'
                }
            },
            {
                title: 'Description',
                field: 'description',
                sorting: false,
                render: (rowData) => this.displayDescription(rowData.description),
                cellStyle: {
                    width: 1200,
                    minWidth: 300,
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    width: 1200,
                    minWidth: 300,
                    textAlign: 'left'
                }
            },
            {
                title: 'Stack',
                field: 'stack',
                sorting: false,
                render: (rowData) => this.displayStack(rowData.listSkill),
                cellStyle: {
                    width: 'calc(500px)',
                    textAlign: 'left'
                },
                headerStyle: {
                    width: 'calc(500px)',
                    textAlign: 'left'
                }
            },
            actionsColumn
        ];

        const columnsForTablet = [
            {
                title: 'Intervenants',
                field: 'Intervenants',
                sorting: false,
                render: (rowData) => this.displayExcilyens(rowData.listUser),
                cellStyle: {
                    width: 250,
                    textAlign: 'left'
                },
                headerStyle: {
                    width: 250,
                    textAlign: 'left'
                }
            },
            {
                title: 'Description',
                field: 'description',
                sorting: false,
                render: (rowData) => this.displayDescription(rowData.description),
                cellStyle: {
                    width: 600,
                    minWidth: 200,
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    width: 600,
                    minWidth: 200,
                    textAlign: 'left'
                }
            },
            {
                title: 'Stack',
                field: 'stack',
                sorting: false,
                render: (rowData) => this.displayStack(rowData.listSkill),
                cellStyle: {
                    width: 250,
                    textAlign: 'left'
                },
                headerStyle: {
                    width: 250,
                    textAlign: 'left'
                }
            },
            actionsColumn
        ];

        const columnsForMobile = [
            {
                title: 'Intervenants',
                field: 'Intervenants',
                sorting: false,
                render: (rowData) => this.displayExcilyens(rowData.listUser),
                cellStyle: {
                    width: 250,
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    width: 250,
                    textAlign: 'left'
                }
            },
            {
                title: 'Stack',
                field: 'stack',
                sorting: false,
                render: (rowData) => this.displayStack(rowData.listSkill),
                cellStyle: {
                    width: 250,
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    width: 250,
                    textAlign: 'left'
                }
            },
            actionsColumn
        ];


        const tableIcons = { SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>) };

        const theme = createTheme({
            overrides: {
                MuiTableSortLabel: {
                    icon: {
                        width: '20px',
                        height: '25px'
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: '5px 10px 5px 10px'
                    },
                    sizeSmall: {
                        padding: '5px 10px 5px 10px'
                    }
                },
                MuiButton: {
                    text: {
                        padding: 0
                    },
                    root: {
                        minWidth: '40px'
                    }
                }
            }
        });

        return (
            <>
                <MuiThemeProvider theme={theme}>
                    <MaterialTable
                        components={{
                            Toolbar: (props) => <div style={{ backgroundColor: '#e8eaf5' }}>
                                <MTableToolbar {...props} />
                            </div>,
                            Action: () => <AddEditMission trueIfAddAndFalseIfEdit={true}
                                currentProject={this.props.project}/>
                        }}
                        title=""
                        columns={isWidthUp('md', this.props.width)
                            ? columnsForDesktop
                            : (isWidthDown('xs', this.props.width)
                                ? columnsForMobile
                                : columnsForTablet)
                        }
                        data={this.props.project.listMission.map((row) => ({ ...row }))}
                        localization={{
                            'body': {
                                'emptyDataSourceMessage': 'Pas de résultat.'
                            },
                            'header': {
                                'actions': ''
                            },
                            'pagination': {
                                'labelDisplayedRows': '{from}-{to} / {count}',
                                'labelRowsSelect': '',
                                'nextTooltip': 'Page suivante',
                                'previousTooltip': 'Page précédente',
                                'firstTooltip': 'Première page',
                                'lastTooltip': 'Dernière page'
                            },
                            'toolbar': {
                                'searchPlaceholder': 'Rechercher',
                                'searchTooltip': 'Rechercher'
                            }
                        }}
                        actions={[
                            {
                                icon: 'add',
                                isFreeAction: true,
                                onClick: this.openModal
                            }
                        ]}
                        options={{
                            thirdSortClick: false,
                            headerStyle: {
                                zIndex: 0
                            },
                            pageSize: localStorage.displayMaterialTableRessourcesPageSize === undefined
                                ? 10
                                : Number.parseInt(localStorage.displayMaterialTableRessourcesPageSize, 10)

                        }}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                        icons={tableIcons}
                    />
                </MuiThemeProvider>
            </>
        );
    }
}

MissionsTable.propTypes = {
    width: PropTypes.string,
    currentUserIsModerator: PropTypes.bool,
    user: PropTypes.object,
    project: PropTypes.object,
    getAllUsers: PropTypes.func,
    deleteMission: PropTypes.func
};

function mapStateToProps(state) {
    return {
        user: selectCurrentUser(state),
        project: selectCurrentProject(state).data
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getAllUsers: () => dispatch(getAllUsers()),
        deleteMission: (idProject) => dispatch(deleteMission(idProject))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(MissionsTable));
