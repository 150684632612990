import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import './CoachListItem.scss';

export function CoachListItem(props) {

    return (
        <div data-testid="coach-list-item">
            <ListItem id="coachItem" className="coaching-item-link">
                <Link to={`/excilyens/${props.coach.id}`} style={{ color: 'inherit',
                    textDecoration: 'none' }}>
                    <ListItemAvatar >
                        <Avatar src={`${props.coach.imageUrl}`} />
                    </ListItemAvatar>
                </Link>
                <Link to={`/excilyens/${props.coach.id}`} style={{ color: 'inherit',
                    textDecoration: 'none' }}>
                    <ListItemText primary={`${props.coach.firstName} ${props.coach.lastName}`} secondary={`${props.coach.promo}`} />
                </Link>
            </ListItem>
            <Divider className="itemDivider"/>
        </div>
    );
}

CoachListItem.propTypes = {
    coach: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        promo: PropTypes.number.isRequired,
        imageUrl: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
    })
};
