import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ChapterCourseDisplay from './ChapterCourseDisplay';

import { useDispatch, useSelector } from 'react-redux';
import { selectChapterCourses } from 'app/redux/selectors/Chapter/chapter.selector';
import { getChapterCoursesByIds, resetChapterCourses } from 'app/redux/actions/Chapter/chapter.actions';
import { Container } from '@material-ui/core';

import './ChapterCourseRequest.scss';

function ChapterCourseRequest({ chapterIds }) {

    const dispatch = useDispatch();

    const chapterCourses = useSelector(selectChapterCourses);

    useEffect(() => {
        if(chapterIds?.length) {
            dispatch(getChapterCoursesByIds(chapterIds));
        }
        return () => {
            dispatch(resetChapterCourses());
        };
    }, [chapterIds]);

    return (
        <Container className="chapterContainer">
            {chapterCourses.map((chapterCourse) => (
                <ChapterCourseDisplay
                    key={chapterCourse.id}
                    chapterCourse={chapterCourse}
                />
            ))}
        </Container>
    );
}

ChapterCourseRequest.propTypes = {
    chapterIds: PropTypes.array.isRequired
};

export default ChapterCourseRequest;
