import axiosClient from './utils/axiosClient';

export const MICROSKILL_REPORTING_URI = 'reporting/microskill';


export function postMicroskillReportingApi(microskillReporting) {
    return axiosClient.post(`${MICROSKILL_REPORTING_URI}/reporting`, microskillReporting);
}

// export function getMicroskillsReportingApi(userId, microskillId) {
//     return axiosClient.get(`${MICROSKILL_REPORTING_URI}/${microskillId}?userId=${userId}`);
// }

// export function patchMicroskillDeniedReportingApi(userId, reportingId) {
//     return axiosClient.patch(`${MICROSKILL_REPORTING_URI}/reporting/${reportingId}/denied?userId=${userId}`);
// }

// export function patchMicroskillTreatedReportingApi(userId, reportingId) {
//     return axiosClient.patch(`${MICROSKILL_REPORTING_URI}/reporting/${reportingId}/treated?userId=${userId}`);
// }
