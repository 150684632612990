import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationContainer from '../../../login/containers/AuthenticationContainer';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useDispatch, useSelector } from 'react-redux';
import {
    patchTreatedQuestionReporting,
    patchDeniedQuestionReporting
} from '../../../redux/actions/Question/QuestionReporting/questionReporting.actions';
import { selectStudentTask } from 'app/redux/selectors/Task/task.selector';
import { patchTask } from 'app/redux/actions/Coaching/Tasks/Task.action';


const useStyles = makeStyles(() => ({
    questionReportingCard: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid #ababab',
        padding: '20px',
        marginTop: '20px',
        fontWeight: 'normal'
    },
    questionReportingContainer: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid #ababab',
        padding: '20px',
        fontWeight: 'bold',
        flexBasis: '35%'
    },
    validate: {
        color: '#008000'
    },
    deny: {
        color: '#f00'
    }
}));

function QuestionReportingDisplay(props) {

    const listTaskStudent = useSelector(selectStudentTask);

    const dispatch = useDispatch();
    const classes = useStyles();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const handleValidate = (report) => {
        if(listTaskStudent) {
            const taskFound = listTaskStudent.find((element) => (element.questionReportedId === report.questionId));
            if(taskFound) {
                dispatch(patchTask(taskFound.id));
                listTaskStudent.splice(listTaskStudent.indexOf(taskFound), 1);
            }
        }
        dispatch(patchTreatedQuestionReporting(getCurrentUserId(), report.questionId, report.id));
    };

    const handleClose = (report) => {
        if(listTaskStudent) {
            const taskFound = listTaskStudent.find((element) => (element.questionReportedId === report.questionId));
            if(taskFound) {
                dispatch(patchTask(taskFound.id));
                listTaskStudent.splice(listTaskStudent.indexOf(taskFound), 1);
            }
        }
        dispatch(patchDeniedQuestionReporting(getCurrentUserId(), report.questionId, report.id));
    };

    const content = props.questionsReporting.map((report) => (
        <Card
            key={report.reporterId}
            className={classes.questionReportingCard}
            value={report.description}
        >
            <span>
                {report.reporterName}
            </span>
            {report.description.split('\n').map((item, idx) => (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            ))}
            <div>
                <Button data-testid="validate-button" onClick={() => handleValidate(report)}>
                    <CheckCircleOutlineIcon className={classes.validate} />
                </Button>
                <Button data-testid="deny-button" onClick={() => handleClose(report)}>
                    <HighlightOffIcon className={classes.deny} />
                </Button>
            </div>
        </Card>
    ));

    return (
        props.questionsReporting.length
            ? <Container className={classes.questionReportingContainer}>
                <span>Commentaires</span>
                {content}
            </Container>
            : null
    );
}

QuestionReportingDisplay.propTypes = {
    questionsReporting: PropTypes.array.isRequired
};

export default QuestionReportingDisplay;
