import React, { useState } from 'react';
import '../CoachingContent.scss';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import History from '../../question/history/History';
import Icon from '@material-ui/core/Icon';

export default function CoachingHistory(props) {
    const [displayGraph, setDisplayGraph] = useState(false);


    return (
        <>
            <Paper name="detailsPaperCard" className="detailsPaperCard">
                {props.displayedStudent && props.displayedStudent.id
                    ? <>
                        <div className="header coaching-history">
                            <h3>Historique de {
                                [
                                    props.displayedStudent.firstName,
                                    props.displayedStudent.lastName
                                ].join(' ')}
                            </h3>
                            <button className="graphButton" color="primary" onClick={() => setDisplayGraph(!displayGraph)}>
                                { displayGraph
                                    ? <Icon>format_list_bulleted_icon</Icon>
                                    : <Icon>insert_chart</Icon>
                                }
                            </button>
                        </div>
                        <History displayGraph={displayGraph} id={props.displayedStudent.id} />
                       
                    </>
                    : <div id="noStudentSelected">Veuillez sélectionner un élève</div>
                }
            </Paper>

        </>
    );
}

CoachingHistory.propTypes = {
    displayedStudent: PropTypes.object
};
