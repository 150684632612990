export const optionsTooltipHours = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
};

export const optionsTooltip = {
    ...optionsTooltipHours,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    second: 'numeric'
};
