import axiosClient from './utils/axiosClient';

export const USERSKILL_SET_URI = 'userskill';

export function getUserSkillsByUserIdApi(userId) {
    return axiosClient.get(`${USERSKILL_SET_URI}/user/${userId}`);
}

export function postUserSkillApi(userSkill) {
    return axiosClient.post(USERSKILL_SET_URI, userSkill);
}

export function patchUserSkillApi(userId, skillId, map) {
    return axiosClient.patch(
        USERSKILL_SET_URI,
        map,
        {
            params: {
                userId,
                skillId
            }
        }
    );
}

export function deleteUserSkillApi(userSkill) {
    return axiosClient.delete(
        USERSKILL_SET_URI,
        { data: userSkill }
    );
}

export function isModeratorApi(userId, skillId) {
    return axiosClient.get(`${USERSKILL_SET_URI}/isModerator/${userId}/${skillId}`);
}
