import React from 'react';
import PropTypes from 'prop-types';

import ResolveSerieRequestBySerieId from 'app/serie/ResolveSerie/ResolveSerieRequestBySerieId';
import ResolveSerieRequestBySerieHistoryId from 'app/serie/ResolveSerie/ResolveSerieRequestBySerieHistoryId';
import Layout from 'app/layout/Layout';

function CandidateSerie(props) {
    return (
        <Layout>
            {props.firstConnection
                ? <ResolveSerieRequestBySerieId
                    onFinishCandidateSerie={props.onFinishCandidateSerie}
                    candidateUuid={props.uuid}
                />
                : <ResolveSerieRequestBySerieHistoryId
                    onFinishCandidateSerie={props.onFinishCandidateSerie}
                    candidateUuid={props.uuid}
                />
            }
        </Layout>
    );
}

CandidateSerie.propTypes = {
    onFinishCandidateSerie: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    firstConnection: PropTypes.bool.isRequired
};

export default CandidateSerie;
