
import { Plugin } from 'ckeditor5/src/core';
import TexteatrouEditing from './texteatrouediting';
import TexteatrouUI from './texteatrouui';

export default class Texteatrou extends Plugin {
	static get requires() {
		return [ TexteatrouEditing, TexteatrouUI ];
	}
}
