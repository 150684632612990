import axiosClient from './utils/axiosClient';

export const GROUPS_URI = 'groups';

export function getAllStudentsOfGroupByGroupIdApi(id) {
    return axiosClient.get(`${GROUPS_URI}/${id}/students`);
}

export function getAllStudentsOfGroupByGroupIdsApi(groupIds) {
    return axiosClient.get(`${GROUPS_URI}/students?groupIds=${groupIds}`);
}

export function getAllCoachesOfGroupByGroupIdApi(id) {
    return axiosClient.get(`${GROUPS_URI}/${id}/coaches`);
}

export function getGroupsByCoachIdApi(coach) {
    return axiosClient.get(`${GROUPS_URI}/coaches/${coach}`);
}

export function isMainCoachApi(groupId, coachId) {
    return axiosClient.get(`${GROUPS_URI}/${groupId}/isSuperCoach/${coachId}`);
}

export function postStudentInStudentGroupsApi(studentId, groupId) {
    return axiosClient.post(`${GROUPS_URI}/${groupId}/students/${studentId}`, {});
}

export function postCoachInStudentGroupsApi(coachId, groupId, userId) {
    return axiosClient.post(`${GROUPS_URI}/${groupId}/coaches/${coachId}`, userId);
}

export function putGroupsApi(group, coachId) {
    return axiosClient.put(`${GROUPS_URI}/${coachId}`, group);
}

export function updateArchivedgroupsApi(groupId, coachId) {
    return axiosClient.patch(`${GROUPS_URI}/archived/${groupId}`, coachId);
}

export function deleteGroupByIdApi(groupId) {
    return axiosClient.delete(`${GROUPS_URI}/${groupId}`);
}

export function deleteStudentInStudentGroupsApi(groupId, studentId) {
    return axiosClient.delete(`${GROUPS_URI}/${groupId}/students/${studentId}`);
}

export function deleteCoachInStudentGroupsApi(groupId, coachId) {
    return axiosClient.delete(`${GROUPS_URI}/${groupId}/coaches/${coachId}`);
}
