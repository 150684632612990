import React, { useState } from 'react';

import { Tab } from '@material-ui/core';
import {
    TabContext,
    TabList,
    TabPanel
} from '@material-ui/lab';

import TableStats from 'app/stats/TableStats/TableStats';
import { useStyles } from '../UseStyles';

export default function StatisticsView() {
    const classes = useStyles();

    const statisticsViewEnum = {
        PARCOURS_VIEW: 'Parcours',
        TABLE_VIEW: 'Tableau'
    };
    const [currentView, setCurrentView] = useState(statisticsViewEnum.TABLE_VIEW);
    return (
        <div>
            <TabContext value={currentView}>
                <header className={classes.tabsPosition} >
                    <TabList onChange={(event, newValue) => setCurrentView(newValue)} >
                        <Tab label="Vue Tableau" value={statisticsViewEnum.TABLE_VIEW} />
                        {/* A GARDER POUR QUAND LA VUE SERA FONCTIONNELLE */}
                        {/* <Tab label="Vue Parcours" value={statisticsViewEnum.PARCOURS_VIEW} /> */}
                    </TabList>
                </header>
                <TabPanel value={statisticsViewEnum.TABLE_VIEW}>
                    <TableStats/>
                </TabPanel>
            </TabContext>
        </div>
    );
}
