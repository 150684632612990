import axiosClient from './utils/axiosClient';

export const MICROSKILL_URI = 'microskills';

export function getMicroskillCourseById(microskillId) {
    return axiosClient.get(`${MICROSKILL_URI}/${microskillId}/course`);
}

export function getMicroskillIdByRemediationQuestionId(questionId) {
    return axiosClient.get(`${MICROSKILL_URI}/question/${questionId}/remediation`);
}

export function getMicroskillsIdsByQuestionId(questionId) {
    return axiosClient.get(`${MICROSKILL_URI}/question/${questionId}`);
}

export function getMicroskillsNames(microskillsIds) {
    const params = new URLSearchParams();
    microskillsIds.forEach(id => params.append('ids', id));
    return axiosClient.get(`${MICROSKILL_URI}/names`, { params });

}

export function getMicroskillChildrenNamesByMicroskillId(microskillId) {
    return axiosClient.get(`${MICROSKILL_URI}/${microskillId}/children/names`);
}