import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './QuestionFooter.scss';
import { useSelector } from 'react-redux';
import { PUT_ANSWER_RETRIEVE_QUESTION_BY_ID } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { createLoadingSelector } from 'app/redux/selectors/APISelector/APISelector';
import { Button, LinearProgress, Tooltip } from '@material-ui/core';
import QuestionLanguageSelector from './QuestionLanguageSelector';
import { displayExplanation, useStyles } from '../QuestionContainer.functions';
import { StyledText } from 'app/utils/StyledText';
import { QuestionAskedEnum } from 'app/utils/QuestionEnum';

// eslint-disable-next-line complexity
export default function QuestionFooter(props) {

    const classes = useStyles();

    const isFetchingQuestionSubmission = useSelector(createLoadingSelector([PUT_ANSWER_RETRIEVE_QUESTION_BY_ID]));

    const hasSuccessfullyAnswered = () =>
        (props.hasAnsweredWithCourse || props.hasAnsweredWithoutCourse)
        && (props.questionType !== QuestionAskedEnum.QuestionOuverte || !props.hasValidationFailed);

    const isInvalidQuestionOuverteAnswer = () =>
        props.questionType === QuestionAskedEnum.QuestionOuverte
        && !(props.audioBlobUrl && props.textAnswer.trim().length !== 0);

    const toggleShowExplanation = () => {
        props.setIsDisplayingExplanation((state) => (!state));
    };

    const toggleShowReview = () => {
        props.setIsDisplayingReview((state) => (!state));
    };

    const handleEndSerie = () => {
        if(props.onEndSerieClick) {
            props.onEndSerieClick();
        }
    };

    const [showContextMenuAI, setShowContextMenuAI] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ xPos: 0, yPos: 0 });
    const [selectedTextToSend, setSelectedTextToSend] = useState();
    const [review, setReview] = useState('');
    const containerRef = useRef(null);

    const isAiExplanationAvailable = () =>
        !props.isExamMode &&
        !props.disableCourse &&
        props.questionType === QuestionAskedEnum.MultipleChoice;

    const handleContextHelpMenu = (event) => {

        const selectedText = window.getSelection().toString()
            .trim();

        if(selectedText) {
            setSelectedTextToSend(selectedText);

            event.preventDefault();

            const posX = event.pageX;
            const posY = event.pageY;

            setShowContextMenuAI(true);
            setContextMenuPosition({ xPos: posX, yPos: posY });
        }
    };

    const handleContextMenu = (event) => {
        if(isAiExplanationAvailable()) {
            handleContextHelpMenu(event);
        }
    };

    const handleRefMenu = () =>
        isAiExplanationAvailable() ? null : containerRef;

    const handleMenuItemClick = () => {
        setShowContextMenuAI(false);
        props.onShowAiHelp(true, selectedTextToSend);
        props.setIsFetchingAiResponse(true);
    };

    const handleOutsideClick = (event) => {
        if(containerRef.current && !containerRef.current.contains(event.target)) {
            setShowContextMenuAI(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if(props.explanation) { return; }
        props.setIsDisplayingExplanation(false);
    }, [props.questionId, props.explanation]);

    useEffect(() => {
        const questionState = props.questionState === 'PENDING' ? '<p>Cette Review a été effectuée par une IA</p>' : '';
        const reviewGrade = props.reviewGrade ? `<p>${props.reviewGrade}/100</p>` : '<p>Pas de note assignée</p>';
        const reviewText = props.reviewText ? `${props.reviewText}` : '<p>Pas de commentaire assigné</p>';
        setReview(questionState + reviewGrade + reviewText);
    }, [props.reviewText, props.reviewGrade, props.questionState]);

    return (
        <>
            { !props.isCandidateSerie && (props.hasAnsweredWithoutCourse || props.hasAnsweredWithCourse) && props.questionLanguage.acronym === 'zza' &&
                <QuestionLanguageSelector
                    questionId={ props.questionId }
                    questionLanguage={ props.questionLanguage }
                    onLanguageChange={ props.onLanguageChange }
                />
            }

            <div className="buttonContainer">

                {(!props.hideReturnButton && !props.isInteractiveQuizQuestion) &&
                    <Button
                        className={classes.terminateButton}
                        data-testid="close-button"
                        variant="outlined"
                        onClick={handleEndSerie}
                    >
                        {props.isInSerie ? 'terminer la série' : 'retour'}
                    </Button>
                }

                {!props.isExamMode && props.reviewText &&
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={`${classes.button} showExplanationButton`}
                            onClick={toggleShowReview}
                            data-testid="show-review-button"
                        >
                            {props.isDisplayingReview ? 'cacher le commentaire' : 'voir le commentaire'}
                        </Button>
                    </>
                }

                {!props.isExamMode && (props.hasAnsweredWithCourse || props.hasAnsweredWithoutCourse) && !props.isCandidateSerie &&
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={`${classes.button} showExplanationButton`}
                            onClick={toggleShowExplanation}
                            data-testid="show-explanation-button"
                        >
                            {props.isDisplayingExplanation ? 'cacher l\'explication' : 'voir l\'explication'}
                        </Button>

                        {!props.disableCourse && !props.isInteractiveQuizQuestion &&
                            <Tooltip
                                title={props.chapterIds?.length
                                    ? ''
                                    : 'Aucun cours associé à cette question'
                                }
                                placement="top"
                                data-testid="tooltip"
                            >
                                <span>
                                    <Button
                                        className={`${classes.button} showCourseButton`}
                                        color="primary"
                                        variant="outlined"
                                        disabled={!props.chapterIds?.length}
                                        onClick={props.onShowChapterCourses}
                                    >
                                        {props.isShowChapterCourse ? 'Cacher' : 'Consulter'} le cours
                                    </Button>
                                </span>
                            </Tooltip>
                        }
                    </>
                }

                {props.isInSerie &&
                    <>
                        {(props.questionIndex > 0 && !props.isInteractiveQuizLeader) &&
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                onClick={props.onPreviousQuestion}
                                data-testid="previous-question-button"
                            >
                                précédent
                            </Button>
                        }
                        {!props.isLastQuestion &&
                            <Button
                                className={`${classes.button} nextQuestionButton`}
                                variant="outlined"
                                color="primary"
                                onClick={props.onNextQuestion}
                                data-testid="next-question-button"
                                disabled={props.isInteractiveQuizLeader && !props.isLeaderDisplayingAnswers}
                            >
                                suivant
                            </Button>
                        }
                    </>
                }

                {!props.hideValidateButton && !hasSuccessfullyAnswered() && !props.isInteractiveQuizLeader &&
                    <Button
                        className={`${classes.button} validateButton`}
                        variant="outlined"
                        onClick={props.onValidateCandidateQuestion}
                        color="primary"
                        disabled={isFetchingQuestionSubmission || isInvalidQuestionOuverteAnswer()}
                        data-testid="validate-button"
                    >
                        { props.hasValidationFailed ? 'Réessayer' : 'Valider'}
                    </Button>
                }
            </div>

            {!props.isExamMode && props.isDisplayingExplanation &&
                <div
                    ref={() => handleRefMenu()}
                    onContextMenu={(event) => handleContextMenu(event)}>
                    <StyledText statement={displayExplanation(props.explanation)} className="statementStyle" isQuestion/>
                    {showContextMenuAI && (
                        <div
                            style={{
                                position: 'fixed',
                                top: `${contextMenuPosition.yPos}px`,
                                left: `${contextMenuPosition.xPos}px`,
                                zIndex: 1000
                            }}
                        >
                            <Button
                                ref={(buttonRef) => (containerRef.current = buttonRef)}
                                variant="contained"
                                color="primary"
                                onClick={handleMenuItemClick}
                                disabled={props.isFetchingAiResponse}>
                                Expliquer via IA
                            </Button>
                        </div>
                    )}
                </div>
            }

            {props.isDisplayingExplanation && props.isDisplayingReview &&
                <hr/>
            }

            {isFetchingQuestionSubmission &&
                <LinearProgress
                    style={{ marginTop: '8px' }}
                />
            }

            {!props.isExamMode && props.isDisplayingReview && props.reviewText !== null &&
                <StyledText statement={displayExplanation(review)} className="statementStyle" isQuestion/>
            }
        </>
    );
}

QuestionFooter.propTypes = {
    redirect: PropTypes.string,
    explanation: PropTypes.string,
    chapterIds: PropTypes.array,

    hasAnsweredWithoutCourse: PropTypes.bool,
    hasAnsweredWithCourse: PropTypes.bool,
    isInSerie: PropTypes.bool,
    isCandidateSerie: PropTypes.bool,
    questionIndex: PropTypes.number,
    questionId: PropTypes.number,
    questionLanguage: PropTypes.object,
    isLastQuestion: PropTypes.bool,
    hideReturnButton: PropTypes.bool,
    hideValidateButton: PropTypes.bool,
    disableCourse: PropTypes.bool,
    isShowChapterCourse: PropTypes.bool,
    isInteractiveQuizQuestion: PropTypes.bool,
    isInteractiveQuizLeader: PropTypes.bool,
    isLeaderDisplayingAnswers: PropTypes.bool,
    reviewText: PropTypes.string,
    reviewGrade: PropTypes.number,
    questionState: PropTypes.string,

    onNextQuestion: PropTypes.func,
    onPreviousQuestion: PropTypes.func,
    onLanguageChange: PropTypes.func.isRequired,
    onValidateCandidateQuestion: PropTypes.func.isRequired,
    hasValidationFailed: PropTypes.bool,
    onShowChapterCourses: PropTypes.func,
    onEndSerieClick: PropTypes.func,
    isDisplayingExplanation: PropTypes.bool.isRequired,
    setIsDisplayingExplanation: PropTypes.func.isRequired,
    isDisplayingReview: PropTypes.bool.isRequired,
    setIsDisplayingReview: PropTypes.func.isRequired,

    onShowAiHelp: PropTypes.func,
    isExamMode: PropTypes.bool,
    setIsFetchingAiResponse: PropTypes.func,
    isFetchingAiResponse: PropTypes.bool,
    audioBlobUrl: PropTypes.string,
    textAnswer: PropTypes.string,
    questionType: PropTypes.string
};
