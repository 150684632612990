import { allProjectsStore, currentProjectStore, projectsStore } from 'app/redux/slices/projects.slice';
import { RootState } from '../../rootReducer';

export function selectAllProjects(state: RootState) {
    return state[projectsStore][allProjectsStore];
}

export function selectCurrentProject(state: RootState) {
    return state[projectsStore][currentProjectStore];
}
