import React from 'react';
import PropTypes from 'prop-types';

import { QuestionResult } from './QuestionResult';

import { List } from '@material-ui/core';

function QuestionResultList({ questionHistoryList }) {
    return (
        <List>
            {questionHistoryList.map((questionHistory) => (
                <QuestionResult
                    key={questionHistory.id}
                    questionHistory={questionHistory}
                />
            ))}
        </List>
    );
}

QuestionResultList.propTypes = {
    questionHistoryList: PropTypes.array.isRequired
};

export default QuestionResultList;
