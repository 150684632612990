import Autosuggest from 'react-autosuggest';
import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';

export function renderInputComponent(inputProps) {
    const {
        inputRef = () => {
        }, ref, ...other
    } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                }
            }}
            margin="dense" label={inputProps.label} type="text"
            {...other}
        />
    );
}

export function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion, query);
    const parts = parse(suggestion, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part) => <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                    {part.text}
                </span>)}
            </div>
        </MenuItem>
    );
}

export function getSuggestions(value, suggestions) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
        ? []
        : suggestions.filter((suggestion) => {


            const keep =
                count < 5 && suggestion && suggestion.slice(0, inputLength).toLowerCase() === inputValue;

            if(keep) {
                count += 1;
            }

            return keep;
        });
}

export function getSuggestionValue(suggestion) {
    return suggestion;
}

export class AutoSuggestImpl extends Component {

    constructor(props) {
        super(props);

        /*
         * Autosuggest is a controlled component.
         * This means that you need to provide an input value
         * and an onChange handler that updates this value (see below).
         * Suggestions also need to be provided to the Autosuggest,
         * and they are initially empty because the Autosuggest is closed.
         */
        this.state = {
            value: this.props.value || '',
            suggestions: []
        };
    }

    handleChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        this.props.onChange(newValue);
    };

    /*
     * Autosuggest will call this function every time you need to update suggestions.
     * You already implemented this logic above, so just use it.
     */
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.props.suggestions)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;
        const { label } = this.props;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            value,
            onChange: this.handleChange,
            label
        };
        const autosuggestProps = {
            renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.onSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion
        };
        // Finally, render it!
        return (
            <div data-testid="autosuggest">
                <Autosuggest
                    {...autosuggestProps}
                    theme={{
                        container: {
                            position: 'relative'
                        },
                        suggestionsContainerOpen: {
                            position: 'absolute',
                            zIndex: 1,
                            left: 0,
                            right: 0
                        },
                        suggestionsList: {
                            margin: 0,
                            padding: 0,
                            listStyleType: 'none'
                        },
                        suggestion: {
                            display: 'block'
                        }
                    }}
                    inputProps={inputProps}
                    renderSuggestionsContainer={(options) => <Paper {...options.containerProps} square>
                        {options.children}
                    </Paper>
                    }
                />
            </div>
        );
    }
}

AutoSuggestImpl.propTypes = {
    label: PropTypes.string,
    suggestions: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.string
};
