import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

import { useDispatch, useSelector } from 'react-redux';
import { selectReducedChapters } from '../../../redux/selectors/Chapter/chapter.selector';
import { createChapterTree } from 'app/utils/treeStructure/TreeChapter.logic';

import { TreeItem, TreeView } from '@material-ui/lab';
import { FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { chapterActions } from 'app/redux/slices/chapters.slice';
import TreeChapter from 'app/utils/treeStructure/TreeChapter';
import { Skill } from 'app/redux/models/Skill.model';
import { Chapter } from 'app/redux/models/Chapter.model';
import TreeNode from 'app/utils/treeStructure/TreeNode';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '1.2em',
        fontWeight: 500,
        lineHeight: '1.75'
    },
    iconContainer: {
        fontSize: '30px'
    }
}));

const LOADING = 'LOADING';
export const CHAPTERS_NOT_FOUND = 'CHAPTERS_NOT_FOUND';

export const MESSAGES = {
    [LOADING]: '',
    [CHAPTERS_NOT_FOUND]: 'Il n\'y a aucun chapitre dans cette compétence.'
};

interface ChapterCheckboxSelectorProps {
    skill: Skill;
    selectedChapters: Chapter[];
    handleCheckingChapters(chapter: Chapter): void;
}

export default function ChapterCheckboxSelector({ skill, selectedChapters, handleCheckingChapters }: ChapterCheckboxSelectorProps) {

    const getCurrentUserId = () => Number.parseInt(AuthenticationContainer.getCurrentUserId() ?? '0', 10);

    const { data: chapters } = useSelector(selectReducedChapters);

    const [chapterTree, setChapterTree] = useState<TreeChapter | null>(null);
    const [searching, setSearching] = useState<keyof typeof MESSAGES>(LOADING);

    const classes = useStyles();
    const dispatch = useDispatch();

    async function dispatchChapters(mySkill: Skill) {
        if(mySkill) {
            await dispatch(chapterActions.getChaptersToDisplay({ skillId: mySkill.id, userId: getCurrentUserId() }));
            if(chapters) {
                setSearching(CHAPTERS_NOT_FOUND);
            }
        }
    }

    useEffect(() => {
        setChapterTree(createChapterTree(chapters));
    }, [chapters]);

    useEffect(() => {
        dispatchChapters(skill);
    }, [skill]);

    function RenderTree({ node }: { node: TreeNode }) {

        return (
            <TreeItem
                classes={{
                    label: classes.label,
                    iconContainer: classes.iconContainer
                }}
                id={`Chapitre ${node.content.id}`}
                key={node.content.id}
                nodeId={String(node.content.id)}
                label={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedChapters.some((reducedChapter) => node.content.id === reducedChapter.id)}
                                onChange={() => handleCheckingChapters(node.content)}
                                data-testid="checkbox"
                            />
                        }
                        label={node.content.name}
                    />
                }
            >
                {node.children.length
                    ? <>
                        {Array.isArray(node.children)
                            ? node.children.map((child) => (
                                <RenderTree
                                    key={child.content.id}
                                    node={child}
                                />
                            ))
                            : null
                        }
                    </>
                    : null
                }
            </TreeItem>
        );
    }

    RenderTree.propTypes = {
        node: PropTypes.object.isRequired
    };

    return (
        (chapterTree && chapterTree.root.children.length > 0)
            ? <>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    data-testid="tree-view"
                >
                    {chapterTree.root.children.map((chapterNode) => (
                        <RenderTree
                            key={chapterNode.content.id}
                            node={chapterNode}
                        />
                    ))}
                </TreeView>
            </>
            : <>
                <Typography
                    color="primary"
                    aria-label={searching}
                >
                    {MESSAGES[searching]}
                </Typography>
            </>
    );
}
