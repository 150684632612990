const defaultScoreData = (userId) => ({
    userId,
    correctAnswers: 0,
    score: 0,
    imageUrl: ''
});

export function calculateUserScores(quizResults, usersInRoom, nbTotalQuestions) {
    const userScores = usersInRoom.map(user => defaultScoreData(user.id));
    for(const userResult of quizResults) {
        const userId = userResult.userId;
        const correctAnswer = userResult.correctAnswer;
        let userScore = userScores.find((score) => score.userId === userId);
        if(!userScore) {
            userScore = defaultScoreData(userId);
            userScores.push(userScore);
        }
        if(correctAnswer) {
            userScore.correctAnswers++;
        }
    }

    for(const user of userScores) {
        user.score = ((user.correctAnswers / nbTotalQuestions) * 100).toFixed(0);
        const matchingUser = usersInRoom.find((roomUser) => roomUser.id === user.userId);
        if(matchingUser) {
            user.imageUrl = matchingUser.imageUrl;
        }
    }
    userScores.sort((userA, userB) => userB.score - userA.score);
    return userScores;
}

export function getPodiumPosition(userId, userScores) {

    let previousScore = null;
    let podiumPosition = 0;

    // Position réelle, en tenant compte des ex aequo
    for(const [index, user] of userScores.entries()) {
        if(user.score !== previousScore) {
            podiumPosition = index + 1;
        }

        if(user.userId === userId) {
            switch (podiumPosition) {
                case 1:
                    return '🥇';
                case 2:
                    return '🥈';
                case 3:
                    return '🥉';
                default:
                    return '';
            }
        }

        previousScore = user.score;
    }

    // Retourne une chaîne vide si l'utilisateur n'est pas trouvé
    return '';
}

export function getAverageScoreWithQuestionHistories(questionsHistories) {
    return !questionsHistories || questionsHistories.length === 0
        ? 0
        : questionsHistories.map((questionHistory) => {
            if(questionHistory.class === 'QuestionOuverteStatDisplayApi' &&
                questionHistory.grade > 0) {
                return questionHistory.grade / 100;
            } else if(questionHistory.class === 'QuestionOuverteStatDisplayApi' &&
            questionHistory.result > 0) {
                return questionHistory.result / 100;
            } else if(questionHistory.class === 'QuestionStatDisplayApi' &&
                questionHistory.result === 'TRUE') {
                return 1;
            }
            return 0;
        }).reduce((total, value) => total + value, 0);
}
