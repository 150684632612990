import {
    createParcoursApi,
    deleteParcoursApi,
    getNextParcoursHistoryByUserIdApi,
    getParcoursByIdApi,
    getParcoursHistoryByUserIdApi,
    getRemediationByUserIdByQuestionIdApi,
    updateParcoursApi,
    updateUserParcoursApi,
    getParcoursStatusByUserId,
    deleteShareEditionWithUserApi,
    shareEditionWithUsersApi,
    getRemediationByUserIdAndMicroskillsIdsApi
} from 'app/api/parcoursApi';

import { notificationError, notificationSuccess } from '../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    GET_PARCOURS_BY_ID_ERROR,
    GET_NEXT_PARCOURS_ELEMENT_ERROR,
    GET_PARCOURS_HISTORY_BY_USER_ID_ERROR,
    CREATE_PARCOURS_ERROR,
    UPDATE_PARCOURS_ERROR,
    UPDATE_USER_PARCOURS_ERROR,
    DELETE_PARCOURS_ERROR,
    GET_REMEDIATION_ELEMENT_ERROR,
    PARCOURS_ADDED_SUCCESS,
    PARCOURS_UPDATED_SUCCESS,
    PARCOURS_DELETED_SUCCESS,
    PARCOURS_SHARED_DELETED_SUCCESS,
    PARCOURS_SHARED_SUCCESS,
    SHARE_PARCOURS_ERROR
} from 'app/Snackbar/NotificationMessages';

export const GET_PARCOURS_BY_ID_REQUEST = 'GET_PARCOURS_BY_ID_REQUEST';
export const GET_PARCOURS_BY_ID_SUCCESS = 'GET_PARCOURS_BY_ID_SUCCESS';
export const GET_PARCOURS_BY_ID_FAILURE = 'GET_PARCOURS_BY_ID_FAILURE';

export const RESET_PARCOURS = 'RESET_PARCOURS';

export const GET_PARCOURS_FOLLOWED_REQUEST = 'GET_PARCOURS_FOLLOWED_REQUEST';
export const GET_PARCOURS_FOLLOWED_SUCCESS = 'GET_PARCOURS_FOLLOWED_SUCCESS';
export const GET_PARCOURS_FOLLOWED_FAILURE = 'GET_PARCOURS_FOLLOWED_FAILURE';

export const GET_NEXT_PARCOURS_ELEMENT_SUCCESS = 'GET_NEXT_PARCOURS_ELEMENT_SUCCESS';
export const GET_NEXT_PARCOURS_ELEMENT_FAILURE = 'GET_NEXT_PARCOURS_ELEMENT_FAILURE';

export const GET_PARCOURS_HISTORY_BY_USER_ID_REQUEST = 'GET_PARCOURS_HISTORY_BY_USER_ID_REQUEST';
export const GET_PARCOURS_HISTORY_BY_USER_ID_SUCCESS = 'GET_PARCOURS_HISTORY_BY_USER_ID_SUCCESS';
export const GET_PARCOURS_HISTORY_BY_USER_ID_FAILURE = 'GET_PARCOURS_HISTORY_BY_USER_ID_FAILURE';

export const GET_PARCOURS_STATUS_BY_USER_ID_REQUEST = 'GET_PARCOURS_STATUS_BY_USER_ID_REQUEST';
export const GET_PARCOURS_STATUS_BY_USER_ID_SUCCESS = 'GET_PARCOURS_STATUS_BY_USER_ID_SUCCESS';
export const GET_PARCOURS_STATUS_BY_USER_ID_FAILURE = 'GET_PARCOURS_STATUS_BY_USER_ID_FAILURE';

export const CREATE_PARCOURS_REQUEST = 'CREATE_PARCOURS_REQUEST';
export const CREATE_PARCOURS_SUCCESS = 'CREATE_PARCOURS_SUCCESS';
export const CREATE_PARCOURS_FAILURE = 'CREATE_PARCOURS_FAILURE';

export const UPDATE_PARCOURS_REQUEST = 'UPDATE_PARCOURS_REQUEST';
export const UPDATE_PARCOURS_SUCCESS = 'UPDATE_PARCOURS_SUCCESS';
export const UPDATE_PARCOURS_FAILURE = 'UPDATE_PARCOURS_FAILURE';

export const UPDATE_USER_PARCOURS_REQUEST = 'UPDATE_USER_PARCOURS_REQUEST';
export const UPDATE_USER_PARCOURS_SUCCESS = 'UPDATE_USER_PARCOURS_SUCCESS';
export const UPDATE_USER_PARCOURS_FAILURE = 'UPDATE_USER_PARCOURS_FAILURE';

export const GET_REMEDIATION_ELEMENT_SUCCESS = 'GET_REMEDIATION_ELEMENT_SUCCESS';
export const GET_REMEDIATION_ELEMENT_FAILURE = 'GET_REMEDIATION_ELEMENT_FAILURE';

export const UPDATE_PARCOURS_ID = 'UPDATE_PARCOURS_ID';

export const DELETE_PARCOURS_REQUEST = 'DELETE_PARCOURS_REQUEST';
export const DELETE_PARCOURS_SUCCESS = 'DELETE_PARCOURS_SUCCESS';
export const DELETE_PARCOURS_FAILURE = 'DELETE_PARCOURS_FAILURE';

export const ADD_BEGIN_BUTTON = 'ADD_BEGIN_BUTTON';
export const REMOVE_BEGIN_BUTTON = 'REMOVE_BEGIN_BUTTON';

export const DELETE_PARCOURS_SHARE_WITH_USER_REQUEST = 'DELETE_PARCOURS_SHARE_WITH_USER_REQUEST';
export const DELETE_PARCOURS_SHARE_WITH_USER_SUCCESS = 'DELETE_PARCOURS_SHARE_WITH_USER_SUCCESS';
export const DELETE_PARCOURS_SHARE_WITH_USER_FAILURE = 'DELETE_PARCOURS_SHARE_WITH_USER_FAILURE';

export const SHARE_PARCOURS_WITH_USERS_REQUEST = 'SHARE_PARCOURS_WITH_USERS_REQUEST';
export const SHARE_PARCOURS_WITH_USERS_SUCCESS = 'SHARE_PARCOURS_WITH_USERS_SUCCESS';
export const SHARE_PARCOURS_WITH_USERS_FAILURE = 'SHARE_PARCOURS_WITH_USERS_FAILURE';

export const getParcoursById = (id) => (dispatch) => {
    dispatch({ type: GET_PARCOURS_BY_ID_REQUEST });

    return getParcoursByIdApi(id).then((response) => {
        dispatch({
            type: GET_PARCOURS_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_PARCOURS_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_PARCOURS_BY_ID_ERROR));
        }
    });
};

export const resetParcours = () => (dispatch) => {
    dispatch({ type: RESET_PARCOURS });
};

export const getNextParcoursHistoryByUserId = (parcoursId) => (dispatch) =>
    getNextParcoursHistoryByUserIdApi(parcoursId).then((response) => {
        dispatch({
            type: GET_NEXT_PARCOURS_ELEMENT_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_NEXT_PARCOURS_ELEMENT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_NEXT_PARCOURS_ELEMENT_ERROR));
        }
    });

export const getRemediationByUserIdByQuestionId = (parcoursId, questionId) => (dispatch) =>
    getRemediationByUserIdByQuestionIdApi(parcoursId, questionId).then((response) => {
        dispatch({
            type: GET_REMEDIATION_ELEMENT_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_REMEDIATION_ELEMENT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_REMEDIATION_ELEMENT_ERROR));
        }
    });

export const getRemediationByMicroskillsIds = (parcoursId, microskillIds) => (dispatch) =>
    getRemediationByUserIdAndMicroskillsIdsApi(parcoursId, microskillIds).then((response) => {
        dispatch({
            type: GET_REMEDIATION_ELEMENT_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_REMEDIATION_ELEMENT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_REMEDIATION_ELEMENT_ERROR));
        }
    });

export const getParcoursHistoryByUserId = (parcoursId) => (dispatch) => {
    dispatch({ type: GET_PARCOURS_HISTORY_BY_USER_ID_REQUEST });

    return getParcoursHistoryByUserIdApi(parcoursId).then((response) => {
        dispatch({
            type: GET_PARCOURS_HISTORY_BY_USER_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_PARCOURS_HISTORY_BY_USER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_PARCOURS_HISTORY_BY_USER_ID_ERROR));
        }
    });
};

export const createParcours = (parcours) => (dispatch) => {
    dispatch({ type: CREATE_PARCOURS_REQUEST });

    return createParcoursApi(parcours).then((response) => {
        dispatch({
            type: CREATE_PARCOURS_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(PARCOURS_ADDED_SUCCESS));
    }, (error) => {
        dispatch({
            type: CREATE_PARCOURS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(CREATE_PARCOURS_ERROR));
        }
    });
};

export const updateParcours = (parcours) => (dispatch) => {
    dispatch({ type: UPDATE_PARCOURS_REQUEST });

    return updateParcoursApi(parcours).then((response) => {
        dispatch({
            type: UPDATE_PARCOURS_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(PARCOURS_UPDATED_SUCCESS));
    }, (error) => {
        dispatch({
            type: UPDATE_PARCOURS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(UPDATE_PARCOURS_ERROR));
        }
    });
};

export const updateUserParcours = (parcoursId) => (dispatch) => {
    dispatch({ type: UPDATE_USER_PARCOURS_REQUEST });

    return updateUserParcoursApi(parcoursId).then((response) => {
        dispatch({
            type: UPDATE_USER_PARCOURS_SUCCESS,
            payload: response
        });
    }, (error) => {
        dispatch({
            type: UPDATE_USER_PARCOURS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(UPDATE_USER_PARCOURS_ERROR));
        }
    });
};

export const deleteParcours = (parcoursId) => (dispatch) => {
    dispatch({ type: DELETE_PARCOURS_REQUEST });

    return deleteParcoursApi(parcoursId).then(() => {
        dispatch({
            type: DELETE_PARCOURS_SUCCESS,
            payload: parcoursId
        });
        dispatch(notificationSuccess(PARCOURS_DELETED_SUCCESS));
    }, (error) => {
        dispatch({
            type: DELETE_PARCOURS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_PARCOURS_ERROR));
        }
    });
};

export const updateParcoursId = (parcoursId) => (dispatch) => {
    dispatch({ type: UPDATE_PARCOURS_ID, payload: parcoursId });

};

export const getStatusByUserId = (userId) => (dispatch) => {
    dispatch({ type: GET_PARCOURS_STATUS_BY_USER_ID_REQUEST });

    return getParcoursStatusByUserId(userId).then((response) => {
        dispatch({
            type: GET_PARCOURS_STATUS_BY_USER_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_PARCOURS_STATUS_BY_USER_ID_FAILURE,
            payload: error,
            error: true
        });
    });
};

export const deleteShareEditionWithUser = (editor) => (dispatch) => {
    dispatch({ type: DELETE_PARCOURS_SHARE_WITH_USER_REQUEST });

    return deleteShareEditionWithUserApi(editor).then(() => {
        dispatch({
            type: DELETE_PARCOURS_SHARE_WITH_USER_SUCCESS,
            payload: editor.parcoursId
        });
        dispatch(notificationSuccess(PARCOURS_SHARED_DELETED_SUCCESS));
    }, (error) => {
        dispatch({
            type: DELETE_PARCOURS_SHARE_WITH_USER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_PARCOURS_ERROR));
        }
    });
};

export const shareEditionWithUsers = (editors, parcours) => (dispatch) => {
    dispatch({ type: SHARE_PARCOURS_WITH_USERS_REQUEST });

    return shareEditionWithUsersApi(editors).then(() => {
        dispatch({
            type: SHARE_PARCOURS_WITH_USERS_SUCCESS,
            payload: { ...parcours, editors }
        });
        dispatch(notificationSuccess(PARCOURS_SHARED_SUCCESS));
    }, (error) => {
        dispatch({
            type: SHARE_PARCOURS_WITH_USERS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(SHARE_PARCOURS_ERROR));
        }
    });
};

export const resetParcoursSharedIdToDelete = (dispatch) => {
    dispatch({
        type: DELETE_PARCOURS_SHARE_WITH_USER_SUCCESS,
        payload: null
    });
};
