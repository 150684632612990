import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './Search.scss';
import TextField from '@material-ui/core/TextField';

class Search extends Component {

    handleSearchInputChange = (event) => {
        if(event.target.value.length >= process.env.REACT_APP_MINIMUM_AUTO_SEARCH_LENGTH) {
            this.props.onSearchSubmit(event.target.value);
        } else {
            this.props.onSearchSubmit('');
        }
    };

    render() {
        return (
            <div className="search" data-testid="search">
                <TextField
                    className="search-input"
                    placeholder="Rechercher ..."
                    value={this.props.value}
                    onChange={this.handleSearchInputChange}
                    inputProps={{ 'aria-label': 'Search' }}
                />
            </div>
        );
    }
}

Search.propTypes = {
    onSearchSubmit: PropTypes.func,
    value: PropTypes.string
};

export default Search;
