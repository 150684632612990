/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import '../../QuestionResolverWithCourse.scss';

import { MultipleChoiceQuestionAnswers } from './MultipleChoiceQuestion/MultipleChoiceQuestionAnswers';
import { QuestionStatement } from './MultipleChoiceQuestion/QuestionStatement';
import QuestionEditCodeBody from './MultipleChoiceQuestion/QuestionEditCodeBody';
import { Button } from '@material-ui/core';
import { useStyles } from '../QuestionContainer.functions';
import { QuestionAskedEnum, QuestionEnum } from 'app/utils/QuestionEnum';
import { createElement, getTrouInputId, invalidTrouColor, validTrouColor } from 'app/utils/QuestionUtils';
import QuestionOuverteAnswers from './QuestionOuverte/QuestionOuverteAnswers';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { Alert, AlertTitle } from '@material-ui/lab';
import './QuestionOuverte/QuestionOuverteBody.scss';

export default function QuestionBody(props) {

    const classes = useStyles();
    const answers = props.isAnswersDisabled
        ? props.answers.map((answer) => ({ ...answer, isDisabled: true }))
        : props.answers;

    const [isShownAnswers, setIsShownAnswers] = useState(false);
    const [editCodeAnswers, setEditCodeAnswers] = useState(false);
    const [answeredStatement, setAnsweredStatement] = useState('');
    const [correctedStatement, setCorrectedStatement] = useState('');
    const [isQuestionOuverteRecording, setIsQuestionOuverteRecording] = useState(false);

    const getCurrentUserName = () => AuthenticationContainer.getEmail().split('@')[0];

    const inputRegex = /<input[^>]*>/ug;

    const hasCode = (/<pre><code.*?>/u).test(props.statement);

    const addAudioElement = (blob) => {
        const formData = new FormData();
        formData.append('responseFile', new File([blob], 'test.mp3'));
        formData.append('name', getCurrentUserName());

        const url = URL.createObjectURL(blob);
        props.setAudioBlobUrl(url);
        props.saveAudioBlobUrl(url);
    };

    function saveAnsweredStatement() {
        const newStatement = props.statement;
        let index = 0;
        setAnsweredStatement(newStatement.replace(
            inputRegex,
            () => createElement('input', {
                class: 'champTrou',
                readonly: true,
                id: `${getTrouInputId(index)}`,
                style: `background-color:${props.answers[index].acceptedAnswers.includes(props.answers[index].value) ? validTrouColor : invalidTrouColor}`,
                value: `${props.answers[index++].value}`
            }).outerHTML
        ));
    }

    function saveCorrectedStatement() {
        const newStatement = props.statement;
        let index = 0;
        setCorrectedStatement(newStatement.replace(inputRegex, () => {
            if(props.answers[index].acceptedAnswers.length > 1) {

                const select = createElement('select', {
                    class: 'champTrou',
                    readonly: true,
                    style: `background-color:${validTrouColor};
                    ${props.answers[index].acceptedAnswers.includes(props.answers[index].value) ? '' : 'border-color: red'}`,
                    value: `${props.answers[index].acceptedAnswers[0]}`
                });

                props.answers[index++].acceptedAnswers.forEach((answer) => {
                    const option = createElement('option', {
                        value: `${answer}`,
                        innerText: `${answer}`
                    });
                    option.textContent = `${answer}`;
                    select.appendChild(option);

                });

                return select.outerHTML;

            }

            return createElement('input', {
                class: 'champTrou',
                readonly: true,
                id: `${getTrouInputId(index)}`,
                style: `background-color:${validTrouColor};
                ${props.answers[index].acceptedAnswers.includes(props.answers[index].value) ? '' : 'border-color: red'}`,
                value: `${props.answers[index++].acceptedAnswers[0]}`
            }).outerHTML;

        }));
    }

    useEffect(() => {
        setAnsweredStatement('');
        setCorrectedStatement('');
        if([QuestionEnum.TexteATrou, QuestionAskedEnum.TexteATrou].includes(props.questionType) && props.answers.length > 0 && 'acceptedAnswers' in props.answers[0]) {
            saveAnsweredStatement();
            saveCorrectedStatement();
        }
    }, [props.answers, props.statement]);

    useEffect(() => {
        setEditCodeAnswers(false);
    }, [props.statement]);

    const toggleShowHideAnswers = () => setIsShownAnswers(!isShownAnswers);
    const toggleEditCodeAnswers = () => setEditCodeAnswers(!editCodeAnswers);

    const showHideAnswersButtonLabel = () => isShownAnswers
        ? 'Cacher les réponses'
        : 'Voir les réponses';

    const editCodeButtonLabel = () => editCodeAnswers
        ? 'Voir la question'
        : 'Tester le code';

    return (
        <div>
            {editCodeAnswers
                ? <QuestionEditCodeBody
                    statement={props.statement}
                    className="statementStyle"
                />
                : <QuestionStatement
                    statement={(props.isDisplayingExplanation && correctedStatement)
                        ? correctedStatement
                        : ((props.answered && answeredStatement)
                            ? answeredStatement
                            : props.statement)
                    }
                    videoStatement={props.videoStatement}
                    className="statementStyle"
                    questionAsked={props.questionAsked}
                    onShowAiHelp={props.onShowAiHelp}
                    isExamMode={props.isExamMode}
                    setAiHelpContent={props.setAiHelpContent}
                    setIsFetchingAiResponse={props.setIsFetchingAiResponse}
                    isFetchingAiResponse={props.isFetchingAiResponse}
                    disableCourse={props.disableCourse}
                    questionType={props.questionAsked.class}
                    isTranslationMode={props.isTranslationMode}
                />
            }
            {(props.answered && hasCode && props.questionAsked?.skill?.name === 'Java') &&
                <Button
                    data-testid="display-code-button"
                    className={classes.editCodeButton}
                    variant="contained"
                    color="primary"
                    onClick={toggleEditCodeAnswers}
                >
                    { editCodeButtonLabel() }
                </Button>}

            {props.hasCollapseAnswersButton && (props.questionType === QuestionAskedEnum.MultipleChoice || props.questionType === QuestionEnum.MultipleChoice) &&
                <Button
                    data-testid="display-answer-button"
                    className={classes.seeAnswersButton}
                    variant="contained"
                    color="primary"
                    onClick={toggleShowHideAnswers}
                >
                    { showHideAnswersButtonLabel() }
                </Button>
            }

            {(!props.hasCollapseAnswersButton || isShownAnswers) && (props.questionType === QuestionAskedEnum.MultipleChoice || props.questionType === QuestionEnum.MultipleChoice) &&
                <MultipleChoiceQuestionAnswers
                    answers={answers}
                    onToggleCheckbox={props.onToggleCheckbox}
                    isCandidateSerie={props.isCandidateSerie}
                    isInteractiveQuizQuestion={props.isInteractiveQuizQuestion}
                    isCurrentInteractiveQuizQuestionValidated={props.isCurrentInteractiveQuizQuestionValidated}
                    imagesWithAnswersSelected={props.imagesWithAnswersSelected}
                    boxChecked={props.boxChecked}
                />
            }

            { (props.questionType === QuestionAskedEnum.QuestionOuverte || props.questionType === QuestionEnum.QuestionOuverte) &&
                <div>
                    <Alert severity="info" className="tutorial">
                        <AlertTitle>
                            {'Tutoriel Question Ouverte'}
                        </AlertTitle>
                        <div>Afin de répondre à une question ouverte vous devez démarrer un enregistrement. Votre réponse sera automatiquement retranscrie à l&rsquo;écrit dans la zone de texte.
                            Après avoir répondu vous pouvez éditer le texte afin de corriger des erreurs potentielles avant de valider votre réponse.
                            L&rsquo;IA vous fournira ensuite une note et un commentaire afin de vous aider à vous améliorer.
                        </div>
                    </Alert>
                    <QuestionOuverteAnswers
                        answered={props.answered}
                        audioBlobUrl={props.audioBlobUrl}
                        setAudioBlobUrl={props.setAudioBlobUrl}
                        addAudioElement={addAudioElement}
                        isSendingFile={props.isSendingFile}
                        isHistory={props.isHistory}
                        textAnswer={props.textAnswer}
                        setTextAnswer={props.setTextAnswer}
                        totalText={props.totalText}
                        setTotalText={props.setTotalText}
                        saveAudioTextAnswer={props.saveAudioTextAnswer}
                        isQuestionOuverteRecording={isQuestionOuverteRecording}
                        setIsQuestionOuverteRecording={setIsQuestionOuverteRecording}
                    />
                </div>
            }
        </div>

    );
}

QuestionBody.propTypes = {
    isDisplayingExplanation: PropTypes.bool.isRequired,
    isAnswersDisabled: PropTypes.bool,
    isCandidateSerie: PropTypes.bool,
    isInteractiveQuizQuestion: PropTypes.bool,
    isCurrentInteractiveQuizQuestionValidated: PropTypes.bool,
    hasCollapseAnswersButton: PropTypes.bool,
    answered: PropTypes.bool,
    isSendingFile: PropTypes.bool,
    isHistory: PropTypes.bool,

    statement: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    imagesWithAnswersSelected: PropTypes.array,
    audioBlobUrl: PropTypes.string,
    setAudioBlobUrl: PropTypes.func,
    textAnswer: PropTypes.string,
    setTextAnswer: PropTypes.func,
    totalText: PropTypes.string,
    setTotalText: PropTypes.func,
    questionAsked: PropTypes.object,
    boxChecked: PropTypes.bool,
    videoStatement: PropTypes.string,

    onToggleCheckbox: PropTypes.func.isRequired,
    saveAudioBlobUrl: PropTypes.func,
    saveAudioTextAnswer: PropTypes.func,
    onShowAiHelp: PropTypes.func,
    isExamMode: PropTypes.bool,
    setAiHelpContent: PropTypes.func,
    setIsFetchingAiResponse: PropTypes.func,
    isFetchingAiResponse: PropTypes.bool,
    disableCourse: PropTypes.bool,
    isTranslationMode: PropTypes.bool
};
