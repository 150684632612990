
import { Component } from 'react';
import axios from 'axios';

export const GOOGLE_ENDPOINT_TOKEN = 'https://www.googleapis.com/oauth2/v4/token';
export const EXCILYS_DOMAIN = 'excilys.com';
export const OXYL_DOMAIN = 'oxyl.fr';

async function retrieveRefreshToken(body) {
    const response = await axios.post(GOOGLE_ENDPOINT_TOKEN, body);
    return response;
}

async function retrieveAccessToken(body) {
    const response = await axios.post(GOOGLE_ENDPOINT_TOKEN, body);
    return response;
}

async function retrieveUserProfile(token) {
    const response = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`);
    return response;
}

class AuthenticationContainer extends Component {

    static connect = async(googleUser) => {
        const code = googleUser.code;
        const body = {
            code,
            'client_id': '729076081721-lbcpra451loguclrl09lfn0uhukrls0v.apps.googleusercontent.com',
            'client_secret': 'wgj4EW_fUGOcJRjgTUBrC1bd',
            'redirect_uri': process.env.REACT_APP_REDIRECT_URI,
            'grant_type': 'authorization_code'
        };

        const responseForRefresh = await retrieveRefreshToken(body);
        const token = responseForRefresh.data.id_token;

        const googleUserRetrieved = await retrieveUserProfile(token);

        if(googleUserRetrieved.data.hd !== EXCILYS_DOMAIN && googleUserRetrieved.data.hd !== OXYL_DOMAIN) {
            return;
        }
        const email = googleUserRetrieved.data.email;
        const familyName = googleUserRetrieved.data.family_name;
        const givenName = googleUserRetrieved.data.given_name;
        const profilePicture = googleUserRetrieved.data.picture;

        localStorage.setItem('token', token);
        localStorage.setItem('expiration', Math.floor(Date.now() / 1000) + 3600);
        localStorage.setItem('refresh_token', responseForRefresh.data.refresh_token);
        localStorage.setItem('email', email);
        localStorage.setItem('familyName', familyName);
        localStorage.setItem('givenName', givenName);
        localStorage.setItem('profile_picture', profilePicture);
    };

    static refreshToken = async(refreshToken) => {
        const body = {
            'refresh_token': refreshToken,
            'client_id': '729076081721-lbcpra451loguclrl09lfn0uhukrls0v.apps.googleusercontent.com',
            'client_secret': 'wgj4EW_fUGOcJRjgTUBrC1bd',
            'grant_type': 'refresh_token'
        };

        const responseForAccessToken = await retrieveAccessToken(body);
        const token = responseForAccessToken.data.id_token;

        localStorage.setItem('token', token);
        localStorage.setItem('expiration', Math.floor(Date.now() / 1000) + 3600);
    };

    static checkTokenAboutToExpireAndRefresh = async() => {
        const expiration = parseInt(localStorage.getItem('expiration'), 10);
        const refreshToken = localStorage.getItem('refresh_token');
        if(Math.floor(Date.now() / 1000) >= expiration - 2400) {
            await AuthenticationContainer.refreshToken(refreshToken);
        }
    };

    static disconnect = () => {
        delete localStorage.token;
        delete localStorage.email;
        delete localStorage.profile_picture;
        delete localStorage.refresh_token;
        delete localStorage.displayExpertsSection;
        delete localStorage.displayModeratorsSection;
        delete localStorage.displayMaterialTableRessourcesPageSize;
        delete localStorage.displayMaterialTableSkillsPageSize;
        delete localStorage.givenName;
        delete localStorage.expiration;
        delete localStorage.familyName;
        delete localStorage.userId;
        delete localStorage.displayQcmSection;
    };

    static isConnected = () => localStorage.getItem('token') !== null &&
        Math.floor(Date.now() / 1000) < parseInt(localStorage.getItem('expiration'), 10);

    static getEmail = () => localStorage.getItem('email');

    static getFamilyName = () => localStorage.getItem('familyName');

    static getGivenName = () => localStorage.getItem('givenName');

    static getToken = () => localStorage.getItem('token');

    static getRefreshToken = () => localStorage.getItem('refresh_token');

    static getProfilePicture = () => localStorage.getItem('profile_picture');

    static getCurrentUserId = () => localStorage.getItem('userId');

    render() {
        return null;
    }
}

export default AuthenticationContainer;
