import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MuiThemeProvider, Chip } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { selectProjectFilteredBySkillAndClientAndUserAndSector } from '../../../../redux/selectors/Project/projectsFilters.selector';
import { projectsActions } from 'app/redux/slices/projects.slice';
import { MaterialTableImpl } from '../../../../common/MaterialTable/MaterialTableImpl';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';
import { Popup } from '../../Popup/Popup';
import { selectAllProjects } from 'app/redux/selectors/Project/projects.selector';

export class ProjectsTable extends Component {

    componentDidMount() {
        this.props.getAllProjects();
        this.props.setCurrentProject();
    }

    getAllIntervenantsFromMissions = (missions) => {
        const allIntervenants = missions.flatMap((mission) => mission.listUser);
        const allIntervenantsId = allIntervenants.map((intervenant) => intervenant.id);
        return allIntervenants.filter((intervenant, index) => allIntervenantsId.indexOf(intervenant.id) === index);
    };

    displayExcilyens = (missions) => {
        const intervenants = missions && this.getAllIntervenantsFromMissions(missions);
        return (intervenants && intervenants.length > 0)
            ? [
                this.displayFirstExcilyens(intervenants),
                (intervenants && intervenants.length > 2)
                    ? this.displayOtherExcilyensInPopup(intervenants)
                    : <></>
            ]
            : <></>;
    };

    displayFirstExcilyens = (intervenants) => {
        const sub = intervenants.slice(0, 2);
        return sub.map((user) => sub.length - 1 === sub.indexOf(user)
            ? <Fragment key={user.id}>
                {user.firstName} {user.lastName}
            </Fragment>
            : <Fragment key={user.id}>
                {user.firstName} {user.lastName},{' '}
            </Fragment>);
    };

    displayOtherExcilyensInPopup = (intervenants) => <Popup intervenants data={intervenants} number={intervenants.length - 2} />;

    displayStack = (stack) => {
        const stackCopy = [...stack];
        if(stackCopy.length > 4) {
            const others = stack.length - 3;
            stackCopy.splice(3);
            return (
                <p>
                    {stackCopy.map((skill) => stackCopy.length - 1 === stackCopy.indexOf(skill)
                        ? (
                            <Fragment key={skill.id}>
                                {skill.skillName}
                                <Popup stack data={stack} number={others} />
                            </Fragment>
                        )
                        : (
                            <Fragment key={skill.id}>
                                {skill.skillName},{' '}
                            </Fragment>
                        ))}
                </p>
            );
        }
        return (
            <p>
                {stackCopy.map((skill) => stackCopy.length - 1 === stackCopy.indexOf(skill)
                    ? (
                        <Fragment key={skill.id}>
                            {skill.skillName}
                        </Fragment>
                    )
                    : (
                        <Fragment key={skill.id}>
                            {skill.skillName},{' '}
                        </Fragment>
                    ))}
            </p>
        );
    };

    displaySector = (sectorParam) => {
        if(!sectorParam) {
            return <p>Non renseigné</p>;
        }
        return <p>{sectorParam.description}</p>;
    };

    displayDate = (dateParams) => {
        if(!dateParams) {
            return <p>N/A</p>;
        }
        const date = new Date(dateParams);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        return (
            <p>
                {day}/{month}/{date.getFullYear()}
            </p>
        );
    };

    render() {
        const themeChipToValidate = createTheme({
            overrides: {
                MuiChip: {
                    root: {
                        'background-color': '#f49434',
                        'color': 'white',
                        'text-decoration': 'inherit',
                        'text-align': 'center',
                        'height': isWidthUp('sm', this.props.width) ? '20px' : '35px',
                        'line-height': isWidthUp('sm', this.props.width) ? '150%' : '115%',
                        'min-width': isWidthUp('sm', this.props.width) ? '0' : '90px',
                        'font-size': isWidthUp('sm', this.props.width) ? '90%' : '83%',
                        'margin-top': isWidthUp('sm', this.props.width) ? '0' : '2px'
                    },
                    label: {
                        'cursor': 'pointer',
                        'padding-left': '6px',
                        'padding-right': '6px'
                    }
                },
                MuiTypography: {
                    root: {
                        textAlign: 'left'
                    }
                }
            }
        });

        const themeChipToValidateAdmin = createTheme({
            overrides: {
                MuiChip: {
                    root: {
                        'background-color': '#f44336',
                        'color': 'white',
                        'height': '20px',
                        'text-decoration': 'inherit'
                    },
                    label: {
                        'cursor': 'pointer',
                        'padding-left': '6px',
                        'padding-right': '6px'
                    }
                },
                MuiTableSortLabel: {
                    icon: {
                        width: '20px',
                        height: '25px'
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: '5px 10px 5px 10px'
                    },
                    sizeSmall: {
                        padding: '5px 10px 5px 10px'
                    }
                },
                MuiTypography: {
                    root: {
                        textAlign: 'left'
                    }
                }
            }
        });

        let columns = [
            {
                title: 'Date de début',
                field: 'startDate',
                defaultSort: 'desc',
                render: (rowData) => this.displayDate(rowData.startDate),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                }
            }
        ];
        const columnsMiddle = {
            title: 'Client',
            field: 'client.name',
            render: (rowData) => <p>{rowData.client && rowData.client.name}</p>,
            cellStyle: {
                maxWidth: 'calc(100px)',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                textAlign: 'left',
                width: 'calc(100px)',
                padding: '10'
            }
        };
        const columnsPost = [
            {
                title: 'Projet',
                field: 'name',
                render: (rowData) => !rowData.validated && (this.props.user.roles && !this.props.user.roles.some((role) => role.name === 'ADMIN'))
                    ? (
                        <Fragment key={rowData.id}>
                            {rowData.name}
                            {!rowData.validated && (
                                <MuiThemeProvider theme={themeChipToValidate}>
                                    <div>
                                        <Chip label="En attente de validation" />
                                    </div>
                                </MuiThemeProvider>
                            )}
                        </Fragment>
                    )
                    : (
                        <Fragment key={rowData.id}>
                            <Link
                                key={rowData.id}
                                to={`/projects/${rowData.id}`}
                                style={{ textDecoration: 'none' }}
                            >
                                {rowData.name}
                                {!rowData.validated && (
                                    <MuiThemeProvider theme={themeChipToValidateAdmin}>
                                        <div>
                                            <Chip label="À valider" />
                                        </div>
                                    </MuiThemeProvider>
                                )}
                            </Link>
                        </Fragment>
                    ),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(500px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(500px)'
                }
            },
            {
                title: 'Intervenants',
                field: 'listUser',
                sorting: false,
                render: (rowData) => this.displayExcilyens(rowData.listMission),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(1000px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(1000px)'
                }
            },
            {
                title: 'Stack',
                field: 'listSkill',
                sorting: false,
                render: (rowData) => this.displayStack(rowData.listSkill),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(800px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(800px)'
                }
            },
            {
                title: 'Date de fin',
                field: 'endDate',
                render: (rowData) => this.displayDate(rowData.endDate),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                }
            }
        ];
        if(!this.props.projectsFromClientDetails) {
            columns.push(columnsMiddle);
        }
        columns = columns.concat(columnsPost);
        const columnsForMobile =
        [
            {
                title: 'Projet',
                field: 'name',
                render: (rowData) => !rowData.validated && (this.props.user.roles && !this.props.user.roles.some((role) => role.name === 'ADMIN'))
                    ? (
                        <Fragment key={rowData.id}>
                            {rowData.name}
                            {!rowData.validated && (
                                <MuiThemeProvider theme={themeChipToValidate}>
                                    <div>
                                        <Chip
                                            label={[
                                                'En attente',
                                                <br key="br"/>,
                                                'de validation'
                                            ]}
                                        />
                                    </div>

                                </MuiThemeProvider>
                            )}
                        </Fragment>
                    )
                    : (
                        <Fragment key={rowData.id}>
                            <Link
                                key={rowData.id}
                                to={`/projects/${rowData.id}`}
                                style={{ textDecoration: 'none' }}
                            >
                                {rowData.name}
                                {!rowData.validated && (
                                    <MuiThemeProvider theme={themeChipToValidateAdmin}>
                                        <div>
                                            <Chip label="À valider" />
                                        </div>
                                    </MuiThemeProvider>
                                )}
                            </Link>
                        </Fragment>
                    ),
                cellStyle: {
                    maxWidth: 'calc(120px)',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(120px)',
                    padding: '10'
                }
            },
            {
                title: 'Intervenants',
                field: 'listMission.listUser',
                sorting: false,
                render: (rowData) => this.displayExcilyens(rowData.listMission),
                cellStyle: {
                    maxWidth: 'calc(120px)',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(120px)',
                    padding: '10'
                }
            },
            {
                title: 'Stack',
                field: 'listSkill',
                sorting: false,
                render: (rowData) => this.displayStack(rowData.listSkill),
                cellStyle: {
                    maxWidth: 'calc(120px)',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                }
            }
        ];

        const tableData = this.props.projectsFromClientDetails ? this.props.projectsFromClientDetails : this.props.allProjects;

        return (
            <>
                <MaterialTableImpl
                    title={''}
                    isFetching={this.props.isFetching}
                    data={tableData.map((row) => ({ ...row }))}
                    columns={isWidthUp('sm', this.props.width) ? columns : columnsForMobile}
                    width={this.props.width}
                />
            </>
        );
    }
}

ProjectsTable.propTypes = {
    allProjects: PropTypes.array,
    getAllProjects: PropTypes.func,
    setCurrentProject: PropTypes.func,
    user: PropTypes.object,
    width: PropTypes.string,
    projectsFromClientDetails: PropTypes.array,
    isFetching: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        allProjects: selectProjectFilteredBySkillAndClientAndUserAndSector(state),
        user: selectCurrentUser(state),
        isFetching: selectAllProjects(state).loading
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getAllProjects: () => dispatch(projectsActions.getAllProjects()),
        setCurrentProject: () => dispatch(projectsActions.setCurrentProject(null))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ProjectsTable));
