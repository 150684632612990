export function getYoutubeIdFromLink(link) {
    if(!link || link.length <= 0) {
        return null;
    }
    let newLink = link.split('v=')[1];
    if(!newLink || newLink.length <= 0) {
        return null;
    }
    if(newLink.indexOf('&') < 0) {
        return newLink.length === 11 ? newLink : null;
    }
    newLink = newLink.split('&')[0];
    return newLink.length === 11 ? newLink : null;
}
