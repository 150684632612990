import React, { Component } from 'react';
import './FormCreateUser.scss';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { postUser, getCurrentUser } from '../../redux/actions/User/user.actions';
import RichTextEditor from '../../common/richTextEditor/RichTextEditor';

const options = [];
export const MIN_YEAR = 2002;
export const MAX_YEAR = new Date().getFullYear();

export function isValidForm(promo) {
    if(promo) {
        return (
            promo >= MIN_YEAR && promo <= MAX_YEAR
        );
    }
    return false;
}

export class FormCreateUser extends Component {

    state = {
        promo: '',
        profile: ''
    };

    constructor(props) {
        super(props);
        for(let year = MAX_YEAR; year >= MIN_YEAR; year--) {
            options.push(year);
        }
    }

    onChangePromo = (event) => {
        this.setState({
            ...this.state,
            promo: event.target.value
        });
    };

    onChangeProfile = (event, editor) => {
        this.setState({
            ...this.state,
            profile: editor.getData()
        });
    };

    handleOnNextButtonClick = () => {
        if(isValidForm(this.state.promo)) {
            this.props.handleNext();
            const date = new Date();
            const user = {
                email: AuthenticationContainer.getEmail(),
                firstName: AuthenticationContainer.getGivenName(),
                lastName: AuthenticationContainer.getFamilyName(),
                imageUrl: AuthenticationContainer.getProfilePicture(),
                promo: this.state.promo,
                description: this.state.profile,
                lastConnectionDateTime: date.toISOString().substring(0, 23)
            };
            this.props.postUser(user);
        } else {
            alert('Veuillez compléter tous les champs obligatoires');
        }
    };

    render() {
        return (
            <div className="form-create-user" data-testid="form-create-user">
                <TextField
                    margin="dense"
                    label="Prénom"
                    type="text"
                    fullWidth
                    disabled
                    value={AuthenticationContainer.getGivenName()}/>

                <TextField
                    margin="dense"
                    label="Nom"
                    type="text"
                    fullWidth
                    disabled
                    value={AuthenticationContainer.getFamilyName()}/>

                <TextField
                    data-testid="promo-oxyl"
                    margin="dense"
                    label="Promo Oxyl"
                    fullWidth
                    required
                    onChange={this.onChangePromo}
                    value={this.state.promo}
                    select>
                    {options.map((option, key) => <MenuItem data-testid="menu-item" key={key} value={option}>
                        {option}
                    </MenuItem>)}
                </TextField>

                <div className="rich-text-editor-create-user" data-testid="rich-text-editor">
                    <RichTextEditor onChange={this.onChangeProfile}/>
                </div>

                <div className="container-step-button">
                    <Button
                        data-testid="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.handleOnNextButtonClick}>
                        Suivant
                    </Button>
                </div>
            </div>
        );
    }
}

FormCreateUser.propTypes = {
    postUser: PropTypes.func,
    handleNext: PropTypes.func,
    getCurrentUser: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        postUser: (user) => dispatch(postUser(user)),
        getCurrentUser: () => dispatch(getCurrentUser())
    };
}

export default connect(null, mapDispatchToProps)(FormCreateUser);
