import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './ChapterDescriptionDisplay.scss';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { selectChapterResources } from 'app/redux/selectors/Chapter/Resource/resource.selector';
import { useSelector } from 'react-redux';
import ChapterDescription from './ChapterDescription/ChapterDescription';


export function ChapterDescriptionDisplay(props) {

    const user = useSelector(selectCurrentUser);
    const resources = useSelector(selectChapterResources);

    const [showResources, setShowResources] = useState(false);
    const [resourceList, setResourceList] = useState([]);

    useEffect(() => {
        setResourceList(resources.filter((resource) => resource.chapterId === props.chapter.id));
    }, [resources]);

    const onChangeResourceIndex = (index) => {
        props.setActiveResources(resourceList);
        props.setResourceIndex(index);
    };

    return (
        <div className="chapter-description-display">
            <ChapterDescription chapter={props.chapter}
                showResources={showResources}
                setShowResources={setShowResources}
                resourceList={resourceList}
                onChangeResourceIndex={onChangeResourceIndex}
                editingChapter={props.editingChapter}
                onEditionChange={props.onEditionChange}
                user={user}
                skill={props.skill}
                permission={props.permission}
                bookmark={props.bookmark}
                reportCount={props.reportCount}
            />
        </div>
    );
}

ChapterDescriptionDisplay.propTypes = {
    chapter: PropTypes.object.isRequired,
    setActiveResources: PropTypes.func.isRequired,
    setResourceIndex: PropTypes.func.isRequired,
    editingChapter: PropTypes.string.isRequired,
    onEditionChange: PropTypes.func.isRequired,
    skill: PropTypes.object.isRequired,
    permission: PropTypes.bool.isRequired,
    bookmark: PropTypes.object,
    reportCount: PropTypes.number
};

