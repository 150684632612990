import React from 'react';
import {
    Checkbox, Collapse,
    IconButton, Paper,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead, TableRow,
    useMediaQuery, useTheme
} from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import QuestionRequestByQuestionId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByQuestionId';

export function CreateEditSerieFormSelectedQuestions(props) {

    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const cssControlTable = () => {
        if(sizeUpMd) {
            return 'table-desktop';
        }
        return 'table-mobile';
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (event) => {
        if(!event.destination) {
            return;
        }
        const sorted = {
            id: props.serie.id,
            title: props.serie.title,
            description: props.serie.description,
            creator: props.serie.creator,
            questionReducedList: reorder(props.serie.questionReducedList, event.source.index, event.destination.index)
        };
        props.setSerie(sorted);
    };

    return (
        <div className={cssControlTable()}>
            <Paper className={'classes.paper'} data-testid="table-selected-question" >
                <TableContainer key={props.serie.questionReducedList.length}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="Serie">
                            {(provide) => (
                                <Table
                                    className={'classes.table'}
                                    aria-label="simple table"
                                    {...provide.droppableProps}
                                    ref={provide.innerRef}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell>Id</TableCell>
                                            <TableCell>Compétence</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.serie.questionReducedList.map((rowData, index) => (
                                            <Draggable
                                                key={rowData.id}
                                                draggableId={`${rowData.id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <React.Fragment
                                                        key={`${rowData.id}selectedFragment`}
                                                    >
                                                        <TableRow
                                                            key={`${rowData.id}selectedRow`}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <IconButton aria-label="expand row"
                                                                    size="small"
                                                                    onClick={() => props.setOpenSelectedPreview(rowData.id)}>
                                                                    {props.openSelected.get(rowData.id)
                                                                        ? <KeyboardArrowDownIcon />
                                                                        : <KeyboardArrowRightIcon />
                                                                    }
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked
                                                                    data-testid="check-box-selected-question"
                                                                    onChange={() => props.removeSelectedQuestion(rowData)}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                onClick={() => props.removeSelectedQuestion(rowData)}
                                                                role="checkbox"
                                                            >
                                                                {rowData.id}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                onClick={() => props.removeSelectedQuestion(rowData)}
                                                                role="checkbox"
                                                            >
                                                                { rowData.skillReduced
                                                                    ? rowData.skillReduced.name
                                                                    : (rowData.skill
                                                                        ? rowData.skill.name
                                                                        : undefined
                                                                    )
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key={`${rowData.id}selectedCollapse`}>
                                                            <TableCell
                                                                className={'MuiTableCell-paddingNone'}
                                                                colSpan={6}
                                                            >
                                                                <Collapse
                                                                    in={props.openSelected.get(rowData.id)}
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <QuestionRequestByQuestionId
                                                                        questionId={rowData.id}
                                                                        collapseAnswers
                                                                        disableAnswers
                                                                        hideFooter
                                                                    />
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provide.placeholder}
                                    </TableBody>
                                </Table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </TableContainer>
            </Paper>
        </div>
    );
}

CreateEditSerieFormSelectedQuestions.propTypes = {
    serie: PropTypes.object.isRequired,
    openSelected: PropTypes.instanceOf(Map).isRequired,
    setSerie: PropTypes.func.isRequired,
    removeSelectedQuestion: PropTypes.func.isRequired,
    setOpenSelectedPreview: PropTypes.func.isRequired
};
