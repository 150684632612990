// General
export const EXPIRED_SESSION = 'Votre session a expiré. Veuillez vous déconnecter puis réessayer.';
export const WELCOME_MESSAGE = 'Bienvenue sur My Neuro Factory';
export const LOGIN_FAILED_MESSAGE = 'La connexion a échoué. Veuillez réessayer.';
export const RETRIEVE_USER_INFOS_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer les informations de l\'utilisateur. Veuillez réessayer.';
export const RETRIEVE_EXCILIENS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les oxyliens. Veuillez réessayer.';
export const UNAUTHORIZED = 'Désolé, vous n\'avez pas les droits pour cette action';

// Registration
export const REGISTER_SUCCESS = 'Inscription réussie !';
export const ALREADY_REGISTERED = 'Vous êtes déjà inscrit.';
export const REGISTER_ERROR = 'L\'inscription n\'a pas aboutie. Veuillez réessayer.';
export const REGISTRATION_NEEDED = 'Veuillez vous inscrire';

// UserSkill
export const PATCH_USER_SKILL_FAVORITE_ERROR = 'La compétence n\'a pas pu être mise à jour';

// Skill
export const SKILL_ADDED = 'La compétence a bien été ajoutée !';
export const SKILL_UPDATED = 'La compétence a bien été mise à jour !';
export const SKILL_ALREADY_EXIST = 'Cette compétence existe déjà !';
export const SKILL_ALREADY_IN_PROJECT_STACK = 'Cette compétence a déjà été attribuée au projet !';
export const SKILL_NOT_IN_PROJECT_STACK = 'Cette compétence n\'a pas été attribuée au projet !';
export const SKILLS_NEED_TO_BE_SET = 'Veuillez renseigner vos compétences';
export const ADD_SKILL_ERROR = 'Désolé, nous n\'avons pas pu ajouter cette compétence. Veuillez réessayer.';
export const ADD_SKILL_TO_PROJECT_SUCCESS = 'La compétence a bien été ajoutée au projet !';
export const ADD_SKILL_TO_PROJECT_ERROR = 'Désolé, nous n\'avons pas pu ajouter cette compétence au projet. Veuillez réessayer.';
export const DELETE_SKILL_FROM_PROJECT_SUCCESS = 'La compétence a bien été retirée du projet !';
export const DELETE_SKILL_FROM_PROJECT_ERROR = 'Désolé, nous n\'avons pas pu retirer cette compétence du projet. Veuillez réessayer.';
export const RETRIEVE_SKILL_ERROR = 'Désolé, nous n\'avons pas pu récupérer la compétence. Veuillez réessayer.';
export const RETRIEVE_SKILLS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les compétences. Veuillez réessayer.';
export const RETRIEVE_CATEGORIES_ERROR = 'Désolé, nous n\'avons pas pu récupérer les catégories. Veuillez réessayer.';
export const RETRIEVE_FAMILIES_ERROR = 'Désolé, nous n\'avons pas pu récupérer les familles. Veuillez réessayer.';
export const UPDATE_SKILL_ERROR_404 = 'Désolé, La compétence que vous voulez mettre à jour n\'existe pas.';
export const UPDATE_SKILL_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour cette compétence. Veuillez réessayer.';

// Project
export const PROJECT_ADDED = 'Le projet a bien été ajouté !';
export const PROJECT_UPDATED = 'Le projet a bien été mis à jour !';
export const PROJECT_DELETED = 'Le projet a bien été supprimé !';
export const PROJECT_ALREADY_EXIST = 'Ce projet existe déjà !';
export const ADD_PROJECT_ERROR = 'Désolé, nous n\'avons pas pu ajouter ce projet. Veuillez réessayer.';
export const RETRIEVE_PROJECT_ERROR = 'Désolé, nous n\'avons pas pu récupérer le projet. Veuillez réessayer.';
export const RETRIEVE_PROJECTS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les projets. Veuillez réessayer.';
export const RETRIEVE_PROJECT_NOT_EXIST = 'Désolé, le projet que vous cherchez n\'existe pas.';
export const UPDATE_PROJECT_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour ce projet. Veuillez réessayer.';
export const DELETE_PROJECT_ERROR = 'Désolé, nous n\'avons pas pu supprimer ce projet. Veuillez réessayer.';
export const UPDATE_PROJECT_ERROR_404 = 'Désolé, le projet que vous voulez mettre à jour n\'existe pas.';

// Mission
export const MISSION_ADDED = 'La mission a bien été ajoutée !';
export const MISSION_UPDATED = 'La mission a bien été mise à jour !';
export const MISSION_DELETED = 'La mission a bien été supprimée !';
export const MISSION_ALREADY_EXIST = 'Cette mission existe déjà !';
export const ADD_MISSION_ERROR = 'Désolé, nous n\'avons pas pu ajouter cette mission. Veuillez réessayer.';
export const RETRIEVE_MISSION_ERROR = 'Désolé, nous n\'avons pas pu récupérer la mission. Veuillez réessayer.';
export const RETRIEVE_MISSIONS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les missions. Veuillez réessayer.';
export const UPDATE_MISSION_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour cette mission. Veuillez réessayer.';
export const DELETE_MISSION_ERROR = 'Désolé, nous n\'avons pas pu supprimer cette mission. Veuillez réessayer.';
export const UPDATE_MISSION_ERROR_404 = 'Désolé, la mission que vous voulez mettre à jour n\'existe pas.';

// Client
export const CLIENT_ADDED = 'Le client a bien été ajouté !';
export const CLIENT_UPDATED = 'Le client a bien été mis à jour !';
export const CLIENT_ALREADY_EXIST = 'Ce client existe déjà !';
export const ADD_CLIENT_ERROR = 'Désolé, nous n\'avons pas pu ajouter ce client. Veuillez réessayer.';
export const RETRIEVE_CLIENT_ERROR = 'Désolé, nous n\'avons pas pu récupérer le client. Veuillez réessayer.';
export const RETRIEVE_CLIENTS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les clients. Veuillez réessayer.';
export const UPDATE_CLIENT_ERROR_404 = 'Désolé, le client que vous voulez mettre à jour n\'existe pas.';
export const UPDATE_CLIENT_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour ce client. Veuillez réessayer.';

// Missions By User
export const RETRIEVE_DETAILED_MISSIONS_BY_USER_ID_ERROR = 'Les missions de l\'utilisateur n\'ont pas pu être récupérées.';

// Resource
export const RESOURCE_BAD_FORMAT = 'La ressource n\'a pas le bon format. Veuillez réessayer.';
export const RESOURCE_ADDED = 'La ressource a bien été ajoutée !';
export const ADD_RESOURCE_ERROR = 'Désolé, nous n\'avons pas pu ajouter cette ressource. Veuillez réessayer.';

// Excilyen
export const EXCILYEN_USER_SKILL_REMOVED = 'La compétence a bien été supprimée de votre profil !';
export const EXCILYEN_USER_SKILL_ADDED = 'La compétence a bien été ajoutée à votre profil !';
export const UPDATED_EXCILYEN_LEVEL = 'Votre niveau sur cette compétence a bien été mis à jour !';
export const DELETED_EXCILYEN_LEVEL = 'Votre niveau sur cette compétence a bien été supprimé !';
export const EXCILYEN_LEVEL_UPDATE_ERROR = 'Désolé, nous n\'avons pas pu modifier votre niveau sur cette compétence. Veuillez réessayer.';
export const EXCILYEN_LEVEL_ADD_ERROR = 'Désolé, nous n\'avons pas pu vous ajouter cette compétence à votre profil. Veuillez réessayer.';
export const EXCILYEN_LEVEL_DELETE_ERROR = 'Désolé, nous n\'avons pas pu supprimer votre niveau sur cette compétence. Veuillez réessayer.';
export const EXCILYEN_SET_MODERATOR = 'L\'oxylien est désormais modérateur !';
export const EXCILYEN_REMOVE_MODERATOR = 'L\'oxylien n\'est plus modérateur';
export const EXCILYEN_SET_MODERATOR_ERROR = 'Désolé, nous n\'avons pas pu désigner cet oxylien modérateur. Veuillez réessayer.';
export const RETRIEVE_EXCILYENS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les oxyliens. Veuillez réessayer.';
export const RETRIEVE_USER_MODERATOR_STATE_ERROR = 'Désolé, nous n\'avons pas pu récupérer votre statut pour la compétence. Veuillez réessayer.';

// Access rights
export const REDIRECT_EXCILYENS_ACCESS_PROJECT = 'Désolé, vous n\'avez pas les droits suffisants pour accéder à ce projet.';
export const REDIRECT_EXCILYENS_ACCESS = 'Désolé, vous n\'avez pas les droits suffisants pour accéder à cette page.';

// Sectors
export const RETRIEVE_SECTORS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les secteurs d\'activité. Veuillez réessayer.';
export const RETRIEVE_SECTOR_ERROR = 'Désolé, nous n\'avons pas pu récupérer le secteur d\'activité. Veuillez réessayer.';

// Questions

export const QUESTION_ADDED = 'La question a bien été ajoutée !';
export const QUESTION_UPDATED = 'La question a bien été mise à jour !';
export const QUESTION_MOVED = 'La question a bien été déplacée !';
export const QUESTION_DELETED = 'La question a bien été supprimée !';
export const QUESTION_ALREADY_PRESENT_IN_SUBCHAPTER = 'Impossible, la question est déjà présente dans un sous-chapitre ou un chapitre parent !';
export const QUESTION_TYPE_UNKNOWN = 'Le type de la question est inconnu';
export const QUESTION_GENERATED = 'La question a bien été générée !';


export const ADD_QUESTION_ERROR = 'Désolé, nous n\'avons pas pu ajouter cette question. Veuillez réessayer.';
export const RETRIEVE_QUESTION_ERROR = 'Désolé, nous n\'avons pas pu récupérer la question. Veuillez réessayer.';
export const POST_ANSWER_RETRIEVE_QUESTION_ERROR = 'Désolé, nous n\'avons pas pu envoyer votre réponse. Veuillez réessayer.';
export const RETRIEVE_QUESTIONS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les questions. Veuillez réessayer.';
export const RETRIEVE_QUESTION_ERROR_404 = 'Désolé, la question que vous cherchez n\'existe pas.';
export const UPDATE_QUESTION_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour la question. Veuillez réessayer.';
export const UPDATE_QUESTION_ERROR_404 = 'Désolé, la question que vous voulez mettre à jour n\'existe pas.';
export const DELETE_QUESTION_ERROR = 'Désolé, nous n\'avons pas pu supprimer cette question. Veuillez réessayer.';
export const GENERATE_QUESTION_ERROR = 'Désolé, nous n\'avons pas pu générer de question. Veuillez réessayer.';
export const GET_EXPLANATION_ERROR = 'Désolé, nous n\'avons pas pu générer l\'explication. Veuillez réessayer.';

export const RUN_CODE_QUESTION_ERROR = 'Une erreur est survenue en tentant d\'exécuter le code de cette question.';
export const FILE_TOO_LARGE_ERROR = 'Votre audio est trop lourd, veuillez recommencer l\'enregistrement';
export const QUESTION_VALIDATION_ERROR = 'Une erreur est survenue lors de la validation des réponses.';
export const AI_CALL_ERROR = 'Une erreur est survenue lors de l\'appel à l\'IA.';

export const DAILY_QUESTIONS_ENDED = 'La série quotidienne a bien été effectuée !';
export const PUT_DAILY_QUESTIONS_ERROR = 'Désolé, nous n\'avons pas pu créer ou mettre à jour la série quotidienne que vous venez d\'effectuer. Veuillez réessayer.';
export const RETRIEVE_DAILY_QUESTIONS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les séries quotidiennes. Veuillez réessayer.';

export const DAILY_PREFERENCES_UPDATED = 'Les préférences liées aux questions quotidiennes ont été mises à jour !';
export const GET_DAILY_PREFERENCES_ERROR = 'Désolé, nous n\'avons pas pu récupérer les préférences de l\'utilisateur.';
export const POST_DAILY_PREFERENCES_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour les préférences de l\'utilisateur.';

export const QUESTION_REPORTING_CREATED = 'Votre signalement a bien été envoyé !';
export const QUESTION_REPORTING_ERROR_404 = 'Désolé, vous ne pouvez pas faire de signalement sur cette question.';
export const QUESTION_REPORTING_ERROR = 'Désolé, nous n\'avons pas pu transmettre votre signalement. Veuillez réessayer.';
export const GET_QUESTION_REPORTING_ERROR = 'Désolé, impossible de récupérer les signalements de cette question.';
export const PATCH_QUESTION_REPORTING_ERROR = 'Désolé, impossible de traiter ce signalement.';

export const QUESTION_DELETED_ERROR = 'La question a déjà été supprimée';

export const RETRIEVE_MORNING_QUESTIONS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les questions du matin. Veuillez réessayer.';
export const RETRIEVE_MORNING_QUESTIONS_DONE_ERROR = 'Désolé, nous n\'avons pas pu enregistrer la fin des questions quotidiennes. Veuillez réessayer.';

// QuestionModeration
export const ADD_QUESTION_MODERATION_ERROR = 'Désolé, impossible de marquer cette question comme vérifiée';
export const DELETE_QUESTION_MODERATION_ERROR = 'Désolé, impossible de marquer cette question comme non vérifiée';
// Question Lists

export const SERIE_ADDED = 'La série de questions a bien été ajoutée !';
export const SERIE_UPDATED = 'La série de questions a bien été mise à jour !';
export const SERIE_ALREADY_EXISTS = 'Cette série de questions existe déjà !';

export const RETRIEVE_SERIE_ERROR = 'Désolé, nous n\'avons pas pu récupérer la série de questions. Veuillez réessayer.';
export const RETRIEVE_SERIES_ERROR = 'Désolé, nous n\'avons pas pu récupérer les séries de questions. Veuillez réessayer.';
export const RETRIEVE_SERIE_ERROR_404 = 'Désolé, la série de questions que vous cherchez n\'existe pas.';

export const ADD_SERIE_ERROR = 'Désolé, nous n\'avons pas pu ajouter cette série de questions. Veuillez réessayer.';
export const UPDATE_SERIE_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour la série de questions. Veuillez réessayer.';
export const UPDATE_SERIE_ERROR_404 = 'Désolé, la série de questions que vous voulez mettre à jour n\'existe pas.';

export const GET_SERIE_CHAPTER_ERROR = 'Désolé, il n\'y a pas de questions liées à ce chapitre.';

export const ADD_SERIE_HISTORY_ERROR = 'Désolé, la série de questions que vous venez d\'effectuer n\'a pas été prise en compte. Veuillez réessayer';

// Chapters

export const RETRIEVE_CHAPTER_ERROR = 'Désolé, nous n\'avons pas pu récupérer les chapitres. Veuillez réessayer.';
export const CHAPTER_ADDED = 'Le chapitre a été créé avec succès.';
export const CHAPTER_PASTED = 'Le chapitre a été copié collé avec succés';
export const CHAPTER_UPDATE = 'Le chapitre a été modifié avec succès.';
export const CHAPTER_DELETED = 'Le chapitre a été supprimé avec succès.';
export const DELETE_CHAPTER_WITH_QUESTION = 'Désolé, nous n\'avons pas pu supprimer le chapitre, des questions lui sont associées.';
export const DELETE_CHAPTER_ERROR = 'Désolé, nous n\'avons pas réussi à supprimer le chapitre. Veuillez réessayer.';
export const GET_CHAPTER_RESOURCES_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer la ressource du chapitre.';
export const GET_CHAPTER_RESOURCES_ERROR_404 = 'Désolé, la ressource du chapitre que vous demandez n\'existe pas.';
export const POST_CREATE_CHAPTER_ERROR = 'Désolé, nous n\'avons pas pu ajouter le chapitre. Veuillez réessayer.';
export const POST_PASTE_CHAPTER_ERROR = 'Désolé, nous n\'avons pas pu copier coller le chapitre. Veuillez réessayer.';
export const PUT_UPDATE_CHAPTER_ERROR = 'Désolé, nous n\'avons pas pu modifier le chapitre. Veuillez réessayer.';
export const PATCH_CHAPTER_HEADER_UPDATED = 'L\'entête du chapitre a été modifié avec succès.';
export const PATCH_CHAPTER_HEADER_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour l\'entête du chapitre.';
export const PATCH_CHAPTER_HEADER_ERROR_404 = 'Désolé, le chapitre que vous essayez de mettre à jour n\'existe pas.';
export const GET_CHAPTER_COURSES_BY_IDS_ERROR = 'Désolé, nous n\'avons pas pu récupérer le cours';
export const GENERATE_CHAPTER_STATEMENT = 'Désolé, nous n\'avons pas pu générérer le cours.';

// Chapter Validation
export const RETRIEVE_CHAPTER_VALIDATIONS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les validations des chapitres. Veuillez réessayer.';

// Chapter Reporting
export const CHAPTER_REPORTING_CREATED = 'Votre signalement a bien été envoyé !';
export const CHAPTER_REPORTING_ERROR_404 = 'Désolé, vous ne pouvez pas faire de signalement sur ce chapitre.';
export const CHAPTER_REPORTING_ERROR = 'Désolé, nous n\'avons pas pu transmettre votre signalement. Veuillez réessayer.';
export const RETRIEVE_CHAPTER_REPORTING_ERROR = 'Désolé, nous n\'avons pas pu récupérer les signalements. Veuillez réessayer.';


// History

export const POST_HISTORY_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer l\'historique.';
export const GET_HISTORY_COUNT_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer le nombre d\'éléments de l\'historique.';
export const GET_SERIE_HISTORY_CANDIDATE_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer le résultat du candidat.';

// Coaching

export const studentAddedSuccess = (nomÉlève) => `Vous êtes désormais le coach de ${nomÉlève}.`;
export const ADDED_REQUESTED_COACH = 'Votre demande de coach a bien été envoyée !';

export const ASKING_COACHING_ERROR = 'Désolé, nous n\'avons pas pu envoyer votre demande de coaching. Veuillez réessayer.';

export const GET_STUDENTS_BY_COACH_ID_ERROR = 'Désolé, nous n\'avons pas pu récupérer vos élèves. Veuillez réessayer.';
export const GET_COACHES_BY_STUDENT_ID_ERROR = 'Désolé, nous n\'avons pas pu récupérer vos coachs. Veuillez réessayer.';

export const GET_USER_ASKED_COACHES_ERROR = 'Désolé, nous n\'avons pas pu récupérer vos demandes de coachs. Veuillez réessayer.';
export const GET_STUDENTS_ASKING_RELATION_ERROR = 'Désolé, nous n\'avons pas pu récupérer vos invitations à devenir coach. Veuillez réessayer.';

export const UPDATE_COACHING_ASKED_RELATION_ERROR = 'Désolé, l\'état de la demande de coaching n\'a pas pu être mis à jour.';

// Chapter stats

export const GET_CHAPTERS_STATS_TO_DISPLAY_ERROR = 'Désolé, nous n\'avons pas pu récupérer vos statistiques. Veuillez réessayer.';
export const GET_CHAPTERS_STATS_TO_DISPLAY_403 = 'Désolé, vous n\'êtes pas autorisé à récupérer ces statistiques.';
export const GET_CHAPTERS_STATS_TO_DISPLAY_404 = 'Désolé, nous n\'avons pas pu trouver les statistiques demandées.';

// Task
const ERROR_TASK = 'Désolé, nous n\'avons pas pu récupérer les tâches.';
export const GET_TASKS_BY_STUDENT_ID_ERROR = ERROR_TASK;
export const GET_TASKS_BY_GROUP_ID_ERROR = ERROR_TASK;

export const DELETE_TASK_BY_ID = 'La tâche a bien été supprimée.';
export const DELETE_TASK_BY_ID_ERROR = 'Désolé, impossible de supprimer la tâche.';
export const DELETE_TASK_BY_ID_ERROR_ALREADY_SUPPRESSED = 'La tâche n\'existe deja plus';

export const PATCH_TASK_BY_ID_ERROR = 'Désolé, impossible de traiter la tâche.';
export const PATCH_TASK_BY_ID = 'La tâche a bien été traitée.';

export const POST_TASKS_TO_STUDENT_SUCCESS = 'La tâche a bien été assignée.';
export const POST_TASKS_TO_STUDENT_ERROR = 'Désolé, impossible d\'assigner la tâche.';
export const ADD_TASK_TO_LIST_WARNING_DUPLICATE = 'La question n\'a pas été ajoutée car elle est déjà dans la liste.';

// Parcours
export const RETRIEVE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas pu récupérer le parcours. Veuillez réessayer.';
export const RETRIEVE_MANY_PARCOURS_ERROR = 'Désolé, nous n\'avons pas pu récupérer les parcours. Veuillez réessayer.';
export const RETRIEVE_PARCOURS_STATUS_ERROR = 'Désolé, nous n\'avons pas pu récupérer le statut du parcours. Veuillez réessayer.';
export const POST_CREATE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas pu créer le parcours. Veuillez réessayer.';
export const PATCH_UPDATE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas pu modifier le parcours. Veuillez réessayer.';
export const PATCH_LIST_UPDATE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas pu modifier la liste de parcours. Veuillez réessayer.';
export const UPDATE_LIST_PARCOURS_ALREADY_ERROR = 'Ce parcours existe déjà !';
export const UPDATE_LIST_PARCOURS_ELEMENT_ALREADY_ERROR = 'Cet élement existe déjà !';
export const POST_DELETE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas pu supprimer le parcours. Veuillez réessayer.';
export const PARCOURS_ADDED_SUCCESS = 'Le parcours a été créé avec succès.';
export const PARCOURS_DELETED_SUCCESS = 'Le parcours a été supprimé avec succès.';
export const PARCOURS_UPDATED_SUCCESS = 'Le parcours a été modifié avec succès.';
export const PARCOURS_SHARED_SUCCESS = 'Le parcours a été partagé avec succès.';
export const PARCOURS_SHARED_DELETED_SUCCESS = 'Le parcours partagé a été enlevé avec succès.';
export const PARCOURS_LIST_UPDATED_SUCCESS = 'La liste de parcours a été modifiée avec succès.';
export const GET_PARCOURS_BY_ID_ERROR = 'Désolé, impossible de récupérer le parcours choisi.';
export const GET_ALL_PARCOURS_TO_DISPLAY_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer les parcours.';
export const GET_PARCOURS_FOLLOWED_ERROR = 'Désolé, impossible de récupérer les parcours de l\'utilisateur.';
export const GET_NEXT_PARCOURS_ELEMENT_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer le prochain élément du parcours.';
export const GET_PARCOURS_HISTORY_BY_USER_ID_ERROR = 'Désolé, impossible de récupérer l\'historique.';
export const CREATE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas réussi à créer le parcours.';
export const UPDATE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas réussi à mettre à jour le parcours.';
export const UPDATE_USER_PARCOURS_ERROR = 'Désolé, nous n\'avons pas réussi à mettre à jour le parcours de l\'utilisateur.';
export const DELETE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas réussi à supprimer le parcours.';
export const SHARE_PARCOURS_ERROR = 'Désolé, nous n\'avons pas réussi à partager le parcours.';

// Group

export const GET_ALL_GROUPS_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer les groupes.';
export const GET_ALL_STUDENTS_OF_GROUP_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer les élèves du groupe.';
export const PATCH_ARCHIVED_GROUP_BY_COACH_ID_ERROR = 'Désolé, nous n\'avons pas réussi à archiver le groupe.';
export const PATCH_ARCHIVED_GROUP_BY_COACH_ID_UPDATE = 'Le groupe a bien été archivé.';

// Notifications

export const RETRIEVE_NOTIFICATION_BY_DESTINATAIRE_ERROR = 'Nous n\'avons pas pu récupérer vos notifications';
export const DELETE_NOTIFICATION_ERROR = 'Erreur lors de la suppression de la notification';

// Question Stat

export const GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer les résultats obtenus aux questions réalisées.';

// Candidate

export const CANDIDATE_BAD_FORMAT = 'Le candidat n\'a pas le bon format. Veuillez réessayer.';
export const PATCH_CANDIDATE = 'Le candidat a été modifié avec succès.';
export const PATCH_CANDIDATE_ERROR = 'Désolé, nous n\'avons pas pu modifier le candidat. Veuillez réessayer.';

export const CREATE_CANDIDATE = 'Le candidat a été créé avec succès.';
export const CREATE_CANDIDATE_ERROR = 'Désolé, nous n\'avons pas pu créer le candidat. Veuillez réessayer.';
export const CREATE_CANDIDATE_MAIL_ERROR = 'Erreur dans l\'envoi du mail au candidat.';

// Groups

export const GET_STUDENT_BY_GROUP_ID_ERROR = 'Désolé, nous n\'avons pas pu récupérer les élèves de ce groupe.';

export const DELETE_GROUP_ERROR = 'Désolé, nous n\'avons pas pu supprimer ce groupe.';
export const DELETE_GROUP = 'Votre groupe a bien été supprimé.';

export const DELETE_STUDENT_IN_GROUP_ERROR = 'Désolé, nous n\'avons pas pu supprimer cet élève du groupe.';
export const DELETE_STUDENT_IN_GROUP = 'Votre élève a bien été supprimé du groupe.';

export const DELETE_COACH_IN_GROUP_ERROR = 'Désolé, nous n\'avons pas pu supprimer ce coach du groupe.';
export const DELETE_COACH_IN_GROUP = 'Votre coach a bien été supprimé du groupe.';

export const POST_STUDENT_IN_GROUP_ERROR = 'Désolé, nous n\'avons pas pu ajouter cet élève au groupe.';

export const POST_COACH_IN_GROUP_ERROR = 'Désolé, nous n\'avons pas pu ajouter ce coach au groupe.';

export const PUT_GROUP_ERROR = 'Désolé, nous n\'avons pas pu mettre à jour ce groupe.';
export const PUT_GROUP_EXIST = 'Désolé, ce groupe existe déjà !';

export const GET_GROUP_BY_ID_ERROR = 'Désolé, nous n\'avons pas pu récupérer ce groupe.';
export const GET_ALL_COACHES_OF_GROUP_ERROR = 'Désolé, nous n\'avons pas réussi à récupérer les coachs du groupe.';
export const GET_GROUP_BY_COACH_ID_ERROR = 'Désolé, nous n\'avons pas pu récupérer les groupes.';

// Moderator Request

export const SEND_MODERATOR_REQUEST_ERROR = 'Réessayer plus tard.';
export const SEND_MODERATOR_REQUEST_ERROR_UNABLE_TO_SEND_MAIL = 'Votre demande est prise en compte mais nous n\'avons pas réussi à envoyer le mail.';
export const SEND_MODERATOR_REQUEST_ERROR_UNABLE_TO_SEND_MAIL_AND_NOTIFICATION = 'Désolé, nous n\'avons pas réussi à prendre en compte votre demande.';
export const SEND_MODERATOR_REQUEST_SUCCESS = 'Votre demande a été envoyée.';

export const END_OF_CANDIDATE_SERIE = 'Votre test à bien été pris en compte.';


// IA Report

export const GET_TRADUCTION_REQUEST_ERROR = 'Désolé, le service d\' IA est temporairement indisponible.';
export const AI_REVIEW_ERROR = 'Une erreur est survenue lors de l\'appel à l\'IA.';

// Review Entretien

export const PATCH_QUESTION_OUVERTE_HISTORY_REVIEW_SUCCES = 'L\'évaluation a bien été envoyée.';
export const PATCH_QUESTION_OUVERTE_HISTORY_REVIEW_ERROR = 'Désolé nous n\'avons pas pu mettre à jour l\'évaluation. Veuillez réessayer.';

// Chatbot

export const SAVE_ANSWER_HISTORY_ERROR = 'Désolé, nous avons rencontrés une erreur lors de l\'envoi de votre réponse.';
export const GET_REMEDIATION_ELEMENT_ERROR = 'Désolé, nous n\'avons pas pu récupérer de question. Veuillez réessayer.';
export const GET_CHATBOT_ERROR = 'Désolé, impossible de vous répondre pour le moment';
export const GET_CHATBOT_TIMEOUT_ERROR = 'Temps d\'attente dépassé, impossible de vous répondre pour le moment';

// Microskill

export const MICROSKILL_REPORTING_CREATED = 'Votre signalement a bien été envoyé !';
export const MICROSKILL_REPORTING_ERROR_404 = 'Désolé, vous ne pouvez pas faire de signalement sur ce microskill.';
export const MICROSKILL_REPORTING_ERROR = 'Désolé, nous n\'avons pas pu transmettre votre signalement. Veuillez réessayer.';
// export const GET_MICROSKILL_REPORTING_ERROR = 'Désolé, impossible de récupérer les signalements de ce microskill.';
// export const PATCH_MICROSKILL_REPORTING_ERROR = 'Désolé, impossible de traiter ce signalement.';
// export const RETRIEVE_MICROSKILL_REPORTING_ERROR = 'Désolé, nous n\'avons pas pu récupérer les signalements. Veuillez réessayer.';
