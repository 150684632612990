/* eslint-disable id-length */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { selectAllExcilyenSet } from '../../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import { connect } from 'react-redux';
import { Paper,
    withWidth,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    FormControl,
    FormLabel } from '@material-ui/core';
import './UserStatistics.scss';
import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer
} from 'recharts';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

export class UserStatistics extends Component {

    constructor() {
        super();
        this.state = {
            userLine: true,
            promoLine: true,
            excilyensLine: true,
            valueChoixSkill: 'Toutes',
            valueChoixCategorie: 'Toutes'
        };
    }

    formatageData = (familyName) => {
        let skillDisplay = [];
        if(this.state.valueChoixCategorie === 'Toutes') {
            if(this.state.valueChoixSkill === 'Toutes') {
                skillDisplay = this.props.allSkills.filter((skill) => skill.coveredByExcilys && skill.familyInternalDto !== null &&
                skill.familyInternalDto.name === familyName);
            } else {
                skillDisplay = this.props.userSkills.filter((skill) => skill.familyName === familyName);
            }
        } else if(this.state.valueChoixSkill === 'Toutes') {
            skillDisplay = this.props.allSkills.filter((skill) => skill.coveredByExcilys && skill.familyInternalDto !== null &&
                skill.familyInternalDto.name === familyName &&
                skill.categoryInternalDto.name === this.state.valueChoixCategorie);
        } else {
            skillDisplay = this.props.userSkills.filter((skill) => skill.familyName === familyName &&
            skill.categoryName === this.state.valueChoixCategorie);
        }

        return skillDisplay.map((skill) => ({
            subject: skill.skillName,
            fullMark: 5,
            user: this.getUserLevel(skill.skillName),
            promo: this.getPromoLevel(skill.skillName),
            excilyens: this.getExciliensLevel(skill.skillName)
        }));
    };

    getUserLevel = (skillName) => {
        const skillUser = this.props.userSkills.find((skill) => skill.skillName === skillName);
        if(skillUser !== undefined) {
            return skillUser.userLevel;
        }
        return 0;
    };

    getPromoLevel = (skillName) => {
        let levelExcilyen = 0;
        const filterExcilyenByPromo = this.props.excilyens.filter((excilyen) => excilyen.promo === this.props.user.promo);

        const nbOfExcilyen = filterExcilyenByPromo.length;

        const filterExcilyenByPromoAndSkill = filterExcilyenByPromo.filter((excilyen) => excilyen.skillDetailedApiDtos
            .find((skill) => skill.skillName === skillName));
        filterExcilyenByPromoAndSkill.forEach((excilien) => excilien.skillDetailedApiDtos.forEach((skill) => {
            if(skill.skillName === skillName) {
                levelExcilyen += skill.userLevel;
            }
        }));
        return parseFloat((levelExcilyen / nbOfExcilyen).toFixed(2), 10);
    };

    getExciliensLevel = (skillName) => {
        let levelExcilyen = 0;
        const nbOfExcilyen = this.props.excilyens.length;
        const filterExcilyenSkill = this.props.excilyens.filter((excilyen) => excilyen.skillDetailedApiDtos
            .find((skill) => skill.skillName === skillName));
        filterExcilyenSkill.map((excilien) => excilien.skillDetailedApiDtos.forEach((skill) => {
            if(skill.skillName === skillName) {
                levelExcilyen += skill.userLevel;
            }
        }));
        return parseFloat((levelExcilyen / nbOfExcilyen).toFixed(2), 10);
    };

    onClick = (event, data) => {
        switch (data) {
            case 0:
                this.setState({ userLine: !this.state.userLine });
                break;
            case 1:
                this.setState({ promoLine: !this.state.promoLine });
                break;
            case 2:
                this.setState({ excilyensLine: !this.state.excilyensLine });
                break;
            default:
                break;
        }
    };

    customTick = ({ payload, x, y, textAnchor, stroke, radius }) => {
        let rotate = 0;
        let alignText = textAnchor;
        if(payload.coordinate <= -90) {
            rotate = -180 - payload.coordinate;
            alignText = 'end';
        } else {
            rotate -= payload.coordinate;
            alignText = 'start';
        }

        return (
            <g className="recharts-layer recharts-polar-angle-axis-tick">
                <text
                    radius={radius}
                    stroke={stroke}
                    x={x}
                    y={y}
                    className="recharts-text recharts-polar-angle-axis-tick-value"
                    textAnchor={alignText}
                    style={{ transformOrigin: `${x}px ${y}px`,
                        transform: `rotate(${rotate}deg)` }}
                >
                    <tspan x={x} dy="0em">
                        {payload.value}
                    </tspan>
                </text>
            </g>
        );
    };

    handleOnChangeRadio = (event) => {
        this.setState({ valueChoixSkill: event.target.value });
    };

    handleOnChangeSelect = (event) => {
        this.setState({ valueChoixCategorie: event.target.value });
    };

    render() {
        const user = `${this.props.user.firstName} ${this.props.user.lastName}`;
        const promo = `Promo ${this.props.user.promo}`;
        const height = this.props.width === 'xs' ? 500 : 800;
        const categorieSkill = [];
        this.props.allSkills.filter((skill) => skill.coveredByExcilys).forEach((skill) => {
            if(skill.categoryInternalDto && !categorieSkill.includes(skill.categoryInternalDto.name) &&
                skill.familyInternalDto && skill.familyInternalDto.name === this.props.familyName) {
                categorieSkill.push(skill.categoryInternalDto.name);
            }
        });

        return (
            <div className="table" data-testid="user-statistics">
                <Paper>
                    <div className="titre"> {this.props.familyName} </div>
                    <FormControl fullWidth >
                        <RadioGroup row aria-label="position" name="position" value="Compétence(s) possédée(s)" onChange={this.handleOnChangeRadio}>
                            <FormControlLabel
                                value="Toutes"
                                control={<Radio color="primary" />}
                                label="Toutes"
                                labelPlacement="start"
                                data-testid="skill-all"
                            />
                            <FormControlLabel
                                value="skillUser"
                                control={<Radio color="primary" />}
                                label="Compétence(s) possédée(s)"
                                labelPlacement="start"
                                data-testid="skill-user"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl >
                        <FormLabel className="option" >
                            Catégories sélectionnées :
                        </FormLabel>
                        <Select
                            native={true}
                            className="option"
                            value={this.state.valueChoixCategorie}
                            onChange={this.handleOnChangeSelect}
                            data-testid="select-skill">
                            <option value="Toutes">Toutes</option>
                            {categorieSkill.map((categorie, key) => <option key={key} value={categorie}>
                                {categorie}</option>)}
                        </Select>
                    </FormControl>
                    <ResponsiveContainer className="container" width="100%" height={height}>
                        <RadarChart outerRadius={250} data={this.formatageData(this.props.familyName)} className="grid">
                            <Legend onClick={this.onClick} />
                            <PolarGrid />
                            <PolarAngleAxis dataKey="subject" tick={this.customTick}/>
                            <PolarRadiusAxis angle={90} domain={[
                                0,
                                5
                            ]} />
                            <Radar name={user} dataKey="user" strokeWidth={this.state.userLine ? 2 : 0} stroke="#F01818" fillOpacity={0}
                                legendType="circle" dot={true} data-testid="user-legend"/>
                            <Radar name={promo} dataKey="promo" strokeWidth={this.state.promoLine ? 2 : 0} stroke="#000000" fillOpacity={0}
                                legendType="circle" dot={true} data-testid="promo-legend"/>
                            <Radar name="Oxyliens" dataKey="excilyens" strokeWidth={this.state.excilyensLine ? 2 : 0}
                                stroke="#36A2E0" fillOpacity={0} legendType="circle" dot={true} data-testid="oxyliens-legend"/>
                        </RadarChart>
                    </ResponsiveContainer>
                </Paper>
            </div>
        );
    }
}

UserStatistics.propTypes = {
    familyName: PropTypes.string,
    userSkills: PropTypes.array,
    excilyens: PropTypes.array,
    allSkills: PropTypes.array,
    width: PropTypes.string,
    user: PropTypes.object
};

function mapStateToProps(state) {
    return {
        excilyens: selectAllExcilyenSet(state),
        allSkills: selectAllSkills(state).data
    };
}

export default connect(mapStateToProps)(withWidth()(UserStatistics));
