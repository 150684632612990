import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectHistorySerieGrouped } from 'app/redux/selectors/Question/history.selector';
import { getListHistorySerieGroupedBySerieHistoryId } from 'app/redux/actions/Question/History/history.actions';

import './HistoryListGroupSerie.scss';
import HistoryRowGroupSerie from './historyRowGroupSerie/HistoryRowGroupSerie';
import HistoryPagination from '../historyPagination/HistoryPagination';

function HistoryListGroupSerie(props) {

    const dispatch = useDispatch();

    const historySerieGrouped = useSelector(selectHistorySerieGrouped);

    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(5);

    useEffect(() => {
        if(!historySerieGrouped.get(props.serieHistoryId)) {
            dispatch(getListHistorySerieGroupedBySerieHistoryId(props.serieHistoryId, props.groupName));
        }
    }, []);

    const handleChangePage = (event, number) => {
        setPage(number);
    };

    const handleRowsPerPage = (event) => {
        setRowPerPage(event.target.value);
    };

    return (
        historySerieGrouped?.get(props.serieHistoryId)?.length === 0
            ? <div className="emptyListQuestions">
                <strong>Aucun historique enregistr&eacute;</strong>
            </div>
            : <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow key={'history_serie_grouped_head'}>
                            <TableCell width={'5%'}/>
                            <TableCell width={'70%'}>{props.groupName === 'skill' ? 'Compétence' : 'Chapitre'}</TableCell>
                            <TableCell align="center" width={'15%'}>Nb de questions</TableCell>
                            <TableCell align="center" width={'10%'}>R&eacute;sultat</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historySerieGrouped?.get(props.serieHistoryId)?.map((row, index) => (index + 1) > ((page + 1) * rowPerPage - rowPerPage) &&
                                (index + 1) <= ((page + 1) * rowPerPage)
                            ? <HistoryRowGroupSerie
                                key={`group_serie_${row.id}`}
                                {...props}
                                row={row}
                            />
                            : <></>)
                        }
                    </TableBody>
                </Table>
                <HistoryPagination
                    totalElement={historySerieGrouped?.get(props.serieHistoryId)?.length}
                    pageNumber={page}
                    rowPerPage={rowPerPage}
                    rowPerPageOptions={[5, 10, 20]}
                    handleChangePage={handleChangePage}
                    handleRowsPerPage={handleRowsPerPage}
                />
            </TableContainer>
    );

}

HistoryListGroupSerie.propTypes = {
    groupName: PropTypes.string.isRequired,
    serieHistoryId: PropTypes.number
};

export default HistoryListGroupSerie;
