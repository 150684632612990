import {
    getUserSkillsByUserIdApi,
    postUserSkillApi,
    patchUserSkillApi,
    deleteUserSkillApi
} from '../../../../api/userSkillApi';
import { notificationSuccess, notificationError } from '../../Notification/notifications.actions';
import { getCurrentUserById } from '../user.actions';
import { getExcilyensDetailedBySkillId } from '../../Excilyen/ExcilyenSet/excilyenSet.actions';
import {
    EXCILYEN_USER_SKILL_ADDED,
    DELETED_EXCILYEN_LEVEL,
    EXCILYEN_USER_SKILL_REMOVED,
    EXCILYEN_LEVEL_ADD_ERROR,
    EXCILYEN_LEVEL_DELETE_ERROR,
    EXCILYEN_LEVEL_UPDATE_ERROR,
    EXCILYEN_REMOVE_MODERATOR,
    EXCILYEN_SET_MODERATOR,
    EXCILYEN_SET_MODERATOR_ERROR,
    EXPIRED_SESSION,
    RETRIEVE_USER_MODERATOR_STATE_ERROR,
    UPDATED_EXCILYEN_LEVEL,
    PATCH_USER_SKILL_FAVORITE_ERROR
} from '../../../../Snackbar/NotificationMessages';

export const GET_USERSKILLS_BY_USER_ID_SUCCESS = 'GET_USERSKILLS_BY_USER_ID_SUCCESS';
export const GET_USERSKILLS_BY_USER_ID_REQUEST = 'GET_USERSKILLS_BY_USER_ID_REQUEST';
export const GET_USERSKILLS_BY_USER_ID_FAILURE = 'GET_USERSKILLS_BY_USER_ID_FAILURE';

export const ADD_USER_SKILL = 'ADD_USER_SKILL';
export const ADD_USER_SKILL_SUCCESS = 'ADD_USER_SKILL_SUCCESS';
export const ADD_USER_SKILL_REQUEST = 'ADD_USER_SKILL_REQUEST';
export const ADD_USER_SKILL_FAILURE = 'ADD_USER_SKILL_FAILURE';

export const PATCH_USER_SKILL_LEVEL = 'PATCH_USER_SKILL_LEVEL';
export const PATCH_USER_SKILL_LEVEL_SUCCESS = 'PATCH_USER_SKILL_LEVEL_SUCCESS';
export const PATCH_USER_SKILL_LEVEL_REQUEST = 'PATCH_USER_SKILL_LEVEL_REQUEST';
export const PATCH_USER_SKILL_LEVEL_FAILURE = 'PATCH_USER_SKILL_LEVEL_FAILURE';

export const PATCH_USER_SKILL_FAVORITE_REQUEST = 'PATCH_USER_SKILL_FAVORITE_REQUEST';
export const PATCH_USER_SKILL_FAVORITE_SUCCESS = 'PATCH_USER_SKILL_FAVORITE_SUCCESS';
export const PATCH_USER_SKILL_FAVORITE_FAILURE = 'PATCH_USER_SKILL_FAVORITE_FAILURE';

export const PATCH_USER_SKILL_MODERATOR = 'PATCH_USER_SKILL_MODERATOR';
export const PATCH_USER_SKILL_MODERATOR_SUCCESS = 'PATCH_USER_SKILL_MODERATOR_SUCCESS';
export const PATCH_USER_SKILL_MODERATOR_REQUEST = 'PATCH_USER_SKILL_MODERATOR_REQUEST';
export const PATCH_USER_SKILL_MODERATOR_FAILURE = 'PATCH_USER_SKILL_MODERATOR_FAILURE';

export const REMOVE_USER_SKILL = 'REMOVE_USER_SKILL';
export const REMOVE_USER_SKILL_SUCCESS = 'REMOVE_USER_SKILL_SUCCESS';
export const REMOVE_USER_SKILL_REQUEST = 'REMOVE_USER_SKILL_REQUEST';
export const REMOVE_USER_SKILL_FAILURE = 'REMOVE_USER_SKILL_FAILURE';

export const getUserSkillsByUserId = (userId) => (dispatch) => {
    dispatch({ type: GET_USERSKILLS_BY_USER_ID_REQUEST });

    return getUserSkillsByUserIdApi(userId).then((response) => {
        dispatch({
            type: GET_USERSKILLS_BY_USER_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_USERSKILLS_BY_USER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_USER_MODERATOR_STATE_ERROR));
        }
    });
};

export const addUserSkill = (userSkill) => (dispatch) => {
    dispatch({ type: ADD_USER_SKILL_REQUEST });

    let completeUserSkill = userSkill;

    if(!userSkill.level) {
        completeUserSkill = {
            ...userSkill,
            level: 0
        };
    } else if(!userSkill.favorite) {
        completeUserSkill = {
            ...userSkill,
            favorite: false
        };
    }

    return postUserSkillApi(completeUserSkill).then((response) => {
        dispatch({
            type: ADD_USER_SKILL_SUCCESS,
            payload: response.data
        });
        if(!userSkill.favorite) {
            dispatch(notificationSuccess(EXCILYEN_USER_SKILL_ADDED));
        }
    }, (error) => {
        dispatch({
            type: ADD_USER_SKILL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(EXCILYEN_LEVEL_ADD_ERROR));
        }
    });
};

export const patchUserSkillLevel = (userId, skillId, map) => (dispatch) => {
    dispatch({ type: PATCH_USER_SKILL_LEVEL_REQUEST });

    return patchUserSkillApi(userId, skillId, map).then((response) => {
        dispatch({
            type: PATCH_USER_SKILL_LEVEL_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(UPDATED_EXCILYEN_LEVEL));
    }, (error) => {
        dispatch({
            type: PATCH_USER_SKILL_LEVEL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(EXCILYEN_LEVEL_UPDATE_ERROR));
        }
    });
};

export const patchUserSkillFavorite = (userId, skillId, map) => (dispatch) => {
    dispatch({ type: PATCH_USER_SKILL_FAVORITE_REQUEST });

    return patchUserSkillApi(userId, skillId, map).then((response) => {
        dispatch({
            type: PATCH_USER_SKILL_FAVORITE_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: PATCH_USER_SKILL_FAVORITE_FAILURE,
            error
        });
        dispatch(notificationError(error.response && error.response.status === 401 ? EXPIRED_SESSION : PATCH_USER_SKILL_FAVORITE_ERROR));
    });
};

export const patchUserSkillModerator = (userId, skillId, map) => (dispatch) => {
    dispatch({ type: PATCH_USER_SKILL_MODERATOR_REQUEST });

    return patchUserSkillApi(userId, skillId, map).then(() => {
        dispatch({
            type: PATCH_USER_SKILL_MODERATOR_SUCCESS
        });
        dispatch(getCurrentUserById());
        dispatch(getExcilyensDetailedBySkillId(skillId));
        dispatch(notificationSuccess(map.moderator ? EXCILYEN_SET_MODERATOR : EXCILYEN_REMOVE_MODERATOR));
    }, (error) => {
        dispatch({
            type: PATCH_USER_SKILL_MODERATOR_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(EXCILYEN_SET_MODERATOR_ERROR));
        }
    });
};

export const removeUserSkillLevel = (userId, skillId) => (dispatch) => {
    dispatch({ type: PATCH_USER_SKILL_LEVEL_REQUEST });

    return patchUserSkillApi(userId, skillId, { level: 0 }).then((response) => {
        dispatch({
            type: PATCH_USER_SKILL_LEVEL_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(DELETED_EXCILYEN_LEVEL));
    }, (error) => {
        dispatch({
            type: PATCH_USER_SKILL_LEVEL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(EXCILYEN_LEVEL_DELETE_ERROR));
        }
    });
};

export const removeUserSkill = (userSkill) => (dispatch) => {
    dispatch({ type: REMOVE_USER_SKILL_REQUEST });

    return deleteUserSkillApi(userSkill).then(() => {
        dispatch({
            type: REMOVE_USER_SKILL_SUCCESS,
            payload: userSkill
        });
        dispatch(notificationSuccess(EXCILYEN_USER_SKILL_REMOVED));
    }, (error) => {
        dispatch({
            type: REMOVE_USER_SKILL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(EXCILYEN_LEVEL_DELETE_ERROR));
        }
    });
};
