import { Project } from 'app/redux/models/Project.model';
import axiosClient from './utils/axiosClient';

export const PROJECTS_URI = 'projects';

export function getAllProjectsApi() {
    return axiosClient.get<Project[]>(PROJECTS_URI);
}

export function getProjectByIdApi(id: number) {
    return axiosClient.get<Project>(`${PROJECTS_URI}/${id}`);
}
