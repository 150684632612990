import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const placeholderConfig = 'Insérez l\'énoncé';

const toolbarConfig = {
    viewportTopOffset: 64,
    items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'link',
        'codeBlock',
        '|',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo'
    ]
};

const codeBlockConfig = {
    languages: [
        { language: 'plaintext',
            label: 'Plain text' },
        { language: 'css',
            label: 'CSS' },
        { language: 'html',
            label: 'HTML' },
        { language: 'java',
            label: 'Java' },
        { language: 'javascript',
            label: 'JavaScript' },
        { language: 'typescript',
            label: 'TypeScript' },
        { language: 'xml',
            label: 'XML' }
    ]
};

export function QuestionEditor(props) {

    return (

        <CKEditor
            editor={Editor}
            data={props.data ? props.data : ''}
            config={{
                placeholder: props.placeholder ?? placeholderConfig,
                toolbar: toolbarConfig,
                codeBlock: codeBlockConfig
            }}
            onChange={props.onChange}
            cy-data="create-question-statement"
            disabled={props.disabled ?? false}
        />

    );

}

QuestionEditor.propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

export default QuestionEditor;
