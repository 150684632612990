export const SET_PROJECTS_CLIENT_FILTER = 'SET_PROJECTS_CLIENT_FILTER';
export const SET_PROJECTS_EXCILYENS_FILTER = 'SET_PROJECTS_EXCILYENS_FILTER';
export const SET_PROJECTS_SKILLS_FILTER = 'SET_PROJECTS_SKILLS_FILTER';
export const SET_PROJECTS_SECTOR_FILTER = 'SET_PROJECTS_SECTOR_FILTER';

export function setProjectsClientFilter(filter) {
    return {
        type: SET_PROJECTS_CLIENT_FILTER,
        payload: filter
    };
}

export function setProjetsSkillsFilter(filter) {
    return {
        type: SET_PROJECTS_SKILLS_FILTER,
        payload: filter
    };
}

export function setProjectsExcilyenFilter(filter) {
    return {
        type: SET_PROJECTS_EXCILYENS_FILTER,
        payload: filter
    };
}

export function setProjectsSectorFilter(filter) {
    return {
        type: SET_PROJECTS_SECTOR_FILTER,
        payload: filter
    };
}

