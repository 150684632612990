import { GET_ALL_CLIENTS_SUCCESS, GET_CLIENT_BY_ID_SUCCESS } from '../../actions/Client/clients.actions';

export const allClientSetStore = 'allClientSet';
export const clientStore = 'client';

export default function clientsReducer(state = {
    [allClientSetStore]: [],
    [clientStore]: null
}, action) {
    switch (action.type) {
        case GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                [allClientSetStore]: action.payload
            };
        case GET_CLIENT_BY_ID_SUCCESS:
            return {
                ...state,
                [clientStore]: action.payload
            };
        default:
            return state;
    }
}


