
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AddEditProject.scss';
import Fab from '@material-ui/core/Fab';
import { Add, Edit } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import PropTypes from 'prop-types';
import Select from 'react-select';
import RichTextEditor from '../../../../common/richTextEditor/RichTextEditor';
import { isWidthUp } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { getAllClients } from '../../../../redux/actions/Client/clients.actions';
import { selectAllClients } from '../../../../redux/selectors/Client/clients.selector';
import { addProject, editProject, ADD_PROJECT, EDIT_PROJECT } from '../../../../redux/actions/Project/ProjectSet/projectsSet.actions';
import { allProjectsStore, currentProjectStore } from 'app/redux/slices/projects.slice';
import { createErrorMessageSelector, createLoadingSelector } from '../../../../redux/selectors/APISelector/APISelector';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';

export class AddEditProject extends Component {

    constructor() {
        super();
        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.props.getAllClients();
    }

    componentDidUpdate(prevProps) {
        if(!this.props.isFetchingAddProject && this.props.isFetchingAddProject !== prevProps.isFetchingAddProject && !this.props.errorAdd) {
            this.closeModal();
        }
        if(!this.props.isFetchingEditProject && this.props.isFetchingEditProject !== prevProps.isFetchingEditProject && !this.props.errorEdit) {
            this.closeModal();
        }
    }

    getInitialState = () => {
        const initialState = {
            open: false,
            name: '',
            clientId: 0,
            startDate: null,
            endDate: null,
            stack: [],
            listMission: [],
            description: '',
            disabledByName: true,
            disabledByClient: true,
            disabledByDescription: false,
            nameTooLong: false
        };
        return initialState;
    };

    closeModal = () => {
        this.setState(this.getInitialState());
    };

    openModal = () => {
        this.setState({ open: true });
        const initialState = {
            id: this.props.projectToEdit && this.props.projectToEdit.id,
            user: this.props.user,
            name: this.props.projectToEdit && this.props.projectToEdit.name,
            clientId: this.props.projectToEdit && this.props.projectToEdit.client.id,
            startDate: this.props.projectToEdit && this.props.projectToEdit.startDate,
            endDate: this.props.projectToEdit && this.props.projectToEdit.endDate,
            stack: this.props.projectToEdit && this.props.projectToEdit.listSkill,
            listMission: this.props.projectToEdit && this.props.projectToEdit.listMission,
            description: this.props.projectToEdit && this.props.projectToEdit.description,
            disabledByName: !(this.props.projectToEdit && this.props.projectToEdit.name),
            disabledByClient: !(this.props.projectToEdit && this.props.projectToEdit.client.name),
            disabledByDescription: false,
            nameTooLong: false
        };
        this.setState(initialState);
    };

    submitAdd = () => {
        const client = this.getClientById(this.state.clientId, this.props.clients);
        if(client) {
            delete client.tableData;
        }
        const project = {
            name: this.state.name,
            client,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            description: this.state.description,
            listSkill: this.state.selectedStack,
            validated: false,
            creator: { id: this.props.user.id }
        };
        this.props.addProject(project);
    };

    submitEdit = () => {
        const client = this.getClientById(this.state.clientId, this.props.clients);
        const listMission = this.cleanedListMission(this.props.projectToEdit);
        const project = {
            id: this.state.id,
            creator: this.props.projectToEdit && { id: this.props.projectToEdit.creator.id },
            client,
            name: this.state.name,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            description: this.state.description,
            listSkill: this.props.projectToEdit && this.props.projectToEdit.listSkill,
            validated: this.props.projectToEdit && this.props.projectToEdit.validated,
            listMission
        };
        this.props.editProject(project);
    };

    cleanedListMission = (project) => {
        const listMission = [];
        if(project) {
            project.listMission.forEach((mission) => {
                const copy = {
                    id: mission.id,
                    projectId: mission.projectId,
                    description: mission.description,
                    listSkill: mission.listSkill,
                    listUser: mission.listUser,
                    startDate: mission.startDate,
                    endDate: mission.endDate
                };
                listMission.push(copy);
            });
        }
        return listMission;
    };

    onChangeClient = (client) => {
        let disabledByClient = true;
        if(client && client.value !== 0) {
            disabledByClient = false;
        }
        this.setState({
            ...this.state,
            clientId: client && client.value,
            disabledByClient
        });
    };


    onChangeProjectName = (event) => {
        let disabledByName = true;
        let nameTooLong = false;
        if(event.target.value !== '') {
            if(event.target.value.length < 51) {
                disabledByName = false;
            } else {
                nameTooLong = true;
            }
        }
        this.setState({
            ...this.state,
            name: event.target.value,
            disabledByName,
            nameTooLong
        });
    };

    onChangeDescription = (event, editor) => {
        let disabledByDescription = true;
        if(editor.getData().length < 65535) {
            disabledByDescription = false;
        }
        this.setState({
            ...this.state,
            description: editor.getData(),
            disabledByDescription
        });
    };

    getClientById = (clientId, clients) => clients.filter((client) => client.id === clientId)[0];

    render() {
        const trueIfAddAndFalseIfEdit = this.props.trueIfAddAndFalseIfEdit;
        const allClient = this.props.clients.map((client) => ({ value: client.id,
            label: client.name }));
        return (
            <div>
                {trueIfAddAndFalseIfEdit
                    ? <Fab color="primary" size="small" aria-label="Add" className="add-project-button" onClick={this.openModal}
                        id="add-project-button">
                        <Add/>
                    </Fab>
                    : <Fab color="primary" aria-label="Edit" className="edit-project-button" onClick={this.openModal}
                        id="edit-project-button"
                    >
                        <Edit/>
                    </Fab>}
                <Dialog maxWidth="md" fullWidth={true} disableEnforceFocus={true}
                    fullScreen={!isWidthUp('sm', this.props.width)}
                    open={this.state.open} onClose={this.closeModal}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{trueIfAddAndFalseIfEdit ? 'Créer un nouveau projet' : 'Modifier le projet'}</DialogTitle>
                    <Divider variant="middle" light={true}/>
                    <DialogContent style={{ overflowY: 'visible' }}>
                        <Grid container className="padd" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" id="projectName" name="projectName" label="Nom du projet"
                                    type="text" required fullWidth error={this.state.nameTooLong}
                                    helperText={this.state.nameTooLong ? 'La longueur du nom ne doit pas dépasser 50 caractères' : ''}
                                    defaultValue={this.props.projectToEdit && this.props.projectToEdit.name}
                                    onChange={this.onChangeProjectName}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    required
                                    margin="dense"
                                    fullWidth
                                    onChange= {this.onChangeClient}
                                    className="client-select"
                                    name="clients"
                                    placeholder="Client *"
                                    isClearable={true}
                                    isSearchable={true}
                                    options={allClient}
                                    defaultValue={this.props.projectToEdit
                                        ? { value: this.props.projectToEdit.client.id,
                                            label: this.props.projectToEdit.client.name }
                                        : undefined}
                                >
                                </Select>
                            </Grid>
                        </Grid>
                        <div className="rich-text-editor-add-project">
                            <RichTextEditor data={this.props.projectToEdit && this.props.projectToEdit.description}
                                onChange={this.onChangeDescription}/>
                            <p className={ this.state.disabledByDescription ? 'error-text-shown' : 'error-text-hidden' }>
                                La longueur de la description ne doit pas dépasser 65535 caractères.
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                            Annuler
                        </Button>
                        <Button className="submit-button" onClick={trueIfAddAndFalseIfEdit ? this.submitAdd : this.submitEdit}
                            disabled={ this.props.isFetchingAddProject ||
                                this.props.isFetchingEditProject ||
                                this.state.disabledByName ||
                                this.state.disabledByClient ||
                                this.state.disabledByDescription}
                            color="primary">
                            {trueIfAddAndFalseIfEdit ? 'Ajouter' : 'Modifier'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AddEditProject.propTypes = {
    projectToEdit: PropTypes.object,
    width: PropTypes.string,
    addProject: PropTypes.func,
    isFetchingAddProject: PropTypes.bool,
    isFetchingEditProject: PropTypes.bool,
    trueIfAddAndFalseIfEdit: PropTypes.bool,
    editProject: PropTypes.func,
    getAllClients: PropTypes.func,
    clients: PropTypes.array,
    errorAdd: PropTypes.object,
    errorEdit: PropTypes.object,
    user: PropTypes.object,
    getCurrentUserById: PropTypes.func
};

function mapStateToProps(state) {
    return {
        clients: selectAllClients(state),
        user: selectCurrentUser(state),
        isFetchingAddProject: createLoadingSelector([
            ADD_PROJECT,
            allProjectsStore
        ])(state),
        errorAdd: createErrorMessageSelector([ADD_PROJECT])(state),
        isFetchingEditProject: createLoadingSelector([
            ADD_PROJECT,
            currentProjectStore
        ])(state),
        errorEdit: createErrorMessageSelector([EDIT_PROJECT])(state)

    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addProject: (project) => dispatch(addProject(project)),
        editProject: (project) => dispatch(editProject(project)),
        getAllClients: () => dispatch(getAllClients())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(AddEditProject));
