
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidePanel from '../../components/sidePanel/skill/SidePanelSkill';
import './HomeContent.scss';
import SkillCard from '../../common/skillCard/SkillCard';
import AddSkill from './AddSkill';
import { getAllSkillsStats } from '../../redux/actions/Skill/SkillSet/skillSet.actions';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden/Hidden';
import { selectCurrentUser } from '../../redux/selectors/User/user.selector';
import { selectDisplayLeftDrawer } from '../../redux/selectors/Display/displaySelector';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { setDisplayLeftDrawer } from '../../redux/actions/Display/display.actions';
import SkillsLoader from './SkillsLoader';
import { selectSkillsFilteredByFamiliesAndCategoriesAndByName } from '../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { getAllCategories, getAllFamilies } from '../../redux/actions/FamilyCategory/familiesCategories.actions';
import { selectTaskDrawer } from 'app/redux/selectors/Drawer/drawer.selector';
import { withStyles } from '@material-ui/core/styles';
import { skillActions } from 'app/redux/slices/skills.slice';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

export const styles = () => ({
    skillGrid: {
        marginLeft: '228px'
    }
});

export class HomeContent extends Component {

    state = {
        hideNavbar: [
            'xs',
            'sm'
        ]
    };

    componentDidMount() {
        this.props.getSkillSet();
        this.props.getSkillsStats();
        this.props.getAllCategories();
        this.props.getAllFamilies();
    }

    toggleCloseLeftDrawer = () => {
        this.props.setDisplayLeftDrawer(false);
    };

    toggleOpenLeftDrawer = () => {
        this.props.setDisplayLeftDrawer(true);
    };

    render() {
        const { isFetching, width, user, classes } = this.props;
        const techCards = this.props.filteredSkillSet
            .sort((skill1, skill2) => skill1.skillName.localeCompare(skill2.skillName))
            .map((skill, index) => <SkillCard
                skill={skill}
                key={index}
            />);

        let loader = null;
        switch (width) {
            case 'xl':
                loader = <SkillsLoader column={6} width="2000"/>;
                break;
            case 'lg':
                loader = <SkillsLoader column={4}/>;
                break;
            case 'md':
                loader = <SkillsLoader column={3}/>;
                break;
            case 'sm':
                loader = <SkillsLoader column={2} width="1000"/>;
                break;
            case 'xs':
                loader = <SkillsLoader column={1} width="500"/>;
                break;
            default:
                loader = <SkillsLoader/>;
        }

        return (
            <div data-testid="home-content">
                <Grid container>
                    <Hidden only={this.state.hideNavbar}>
                        <Grid item md={3} lg={2} id="skill-filter" className="desktop-side-panel-skill">
                            <div className="filter">
                                <SidePanel/>
                            </div>
                        </Grid>
                    </Hidden>
                    <Grid id ="skill-grid" item xs={12} md={9} lg={10} className= { this.props.taskDrawer ? classes.skillGrid : '' } >
                        <Grid container id="fix-horizontal-scroll" direction="row" alignItems="center"
                            className="home-tech-cards" spacing={2}
                            data-testid="home-tech-cards">
                            {isFetching ? loader : techCards}
                        </Grid>
                    </Grid>
                </Grid>
                {
                    user.roles &&
                    user.roles.some((role) => role.name === 'ADMIN') &&
                    <AddSkill/>
                }
                <Hidden mdUp>
                    <SwipeableDrawer
                        open={this.props.displayLeftDrawer}
                        anchor="left"
                        onOpen={this.toggleOpenLeftDrawer}
                        onClose={this.toggleCloseLeftDrawer}
                    >
                        <div id="leftDrawer">
                            <IconButton onClick={this.toggleCloseLeftDrawer} className="drawer-icon" data-testid="drawer-icon">
                                <Icon>chevron_left</Icon>
                            </IconButton>
                            <SidePanel/>
                        </div>
                    </SwipeableDrawer>
                </Hidden>
            </div>
        );
    }
}

HomeContent.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string,
        promo: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile: PropTypes.string,
        skills: PropTypes.array,
        roles: PropTypes.array
    }),
    displayLeftDrawer: PropTypes.bool,
    setDisplayLeftDrawer: PropTypes.func,
    isFetching: PropTypes.bool,
    taskDrawer: PropTypes.bool,
    filteredSkillSet: PropTypes.array,
    getSkillSet: PropTypes.func,
    getSkillsStats: PropTypes.func,
    getAllFamilies: PropTypes.func,
    getAllCategories: PropTypes.func,
    width: PropTypes.string,
    classes: PropTypes.object
};

function mapStateToProps(state) {
    return {
        filteredSkillSet: selectSkillsFilteredByFamiliesAndCategoriesAndByName(state),
        user: selectCurrentUser(state),
        displayLeftDrawer: selectDisplayLeftDrawer(state),
        isFetching: selectAllSkills(state).loading,
        taskDrawer: selectTaskDrawer(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getSkillSet: () => dispatch(skillActions.getAllSkills()),
        getSkillsStats: () => dispatch(getAllSkillsStats()),
        setDisplayLeftDrawer: (display) => dispatch(setDisplayLeftDrawer(display)),
        getAllCategories: () => dispatch(getAllCategories()),
        getAllFamilies: () => dispatch(getAllFamilies())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeContent));
