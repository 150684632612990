import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import PropTypes from 'prop-types';
import ClientsMapMarker from './ClientsMapMarker';
import { selectClientFilteredBySectorAndUser } from '../../../../redux/selectors/Client/ClientFilter/clientsFilter.selector';
import { getAllClients } from '../../../../redux/actions/Client/clients.actions';
import { connect } from 'react-redux';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const mapStyles = {
    width: '100%',
    height: '75vh',
    zIndex: '1'
};
const mapStylesMobile = {
    width: '100%',
    height: '500px',
    zIndex: '1'
};
export class ClientsMap extends Component {


    componentDidMount() {
        this.props.getAllClients();
    }

    constructor(props) {
        super(props);
        this.state = {
            currentLocation: { lat: 48.8534,
                lng: 2.3488 },
            zoom: 11
        };
    }

    render() {

        const { currentLocation, zoom } = this.state;

        return (
            <Map center={currentLocation} zoom={zoom} style={isWidthUp('sm', this.props.width) ? mapStyles : mapStylesMobile}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ClientsMapMarker clientMarkers={this.props.allClients}/>
            </Map>
        );
    }
}

ClientsMap.propTypes = {
    allClients: PropTypes.array,
    getAllClients: PropTypes.func,
    width: PropTypes.string
};
function mapStateToProps(state) {
    return {
        allClients: selectClientFilteredBySectorAndUser(state)
    };
}
export function mapDispatchToProps(dispatch) {
    return {
        getAllClients: () => dispatch(getAllClients())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ClientsMap));
