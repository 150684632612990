import React, { useState, useImperativeHandle } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../Message.scss';
import {
    useStyles
} from '../../../../question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer.functions';
import PropTypes from 'prop-types';
import attentionIcon from 'assets/images/attention.svg';
import QuestionReportingDialog from 'app/question/Buttons/QuestionReportingButton/QuestionReportingDialog';
import { getTrouInputId } from 'app/utils/QuestionUtils';
import { Alert } from '@material-ui/lab';

export default function QuestionATrouDisplay(props) {
    const classes = useStyles();
    
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    useImperativeHandle(props.answerRef, () => ({
        getAnswers() {
            const answersSelected = [];
            props.question.answers.forEach(trou => {
                answersSelected.push(
                    document.getElementById(
                        getTrouInputId(trou.ordinal))?.value.trim());
            });
            return answersSelected;
        }
    }));

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }} className={props.isLoading ? 'blurEffect' : ''}>
                <div className="content bold"> #{props.question.id} </div>
                { props.isAnswered &&
                    <Alert severity="info" className="remediation">
                        <div>
                            Passez la souris sur la case de la réponse pour voir les réponses justes acceptées.
                        </div>
                    </Alert>
                }
                <div 
                    id='statement'
                    dangerouslySetInnerHTML={{ __html: props.questionStatement }}
                    className="content bold"
                />
                <IconButton id="iconHeaderRight" style={{ marginRight: '50px' }} color="primary" onClick={handleOpen}>
                    <img src={attentionIcon} alt="Custom Icon"/>
                </IconButton>
            </div>
            <QuestionReportingDialog questionId={props.question.id} isOpen={isOpen} setIsOpen={setIsOpen}/>
            {props.isLoading &&
                    <div className="loaderContainer">
                        <CircularProgress className={classes.loader}/>
                    </div>
            }
        </>
    );
}

QuestionATrouDisplay.propTypes = {
    answerRef: PropTypes.object,
    isLoading: PropTypes.bool,
    question: PropTypes.object,
    questionStatement: PropTypes.string,
    isAnswered: PropTypes.bool
};
