import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    desktopRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    mobileRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    title: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap'
    },
    titleText: {
        marginBottom: '0px'
    },
    studentSelect: {
        marginLeft: '8px',
        minWidth: '170px',
        width: '170px'
    },
    buttonDevenirModerateur: {
        boxShadow: '3px 3px 3px gray'
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));
