import { React, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getNextParcoursHistoryByUserId } from 'app/redux/actions/Parcours/parcours.actions';
import { selectParcours } from 'app/redux/selectors/Parcours/parcours.selector';
import PropTypes from 'prop-types';

import '../Message.scss';
import oxa from 'assets/images/Logochatbot.svg';
import { CircularProgress, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { localLlmServiceApi } from '../../../../api/localLlmApi';
import { notificationError } from '../../../../redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, GET_TRADUCTION_REQUEST_ERROR } from '../../../../Snackbar/NotificationMessages';
import tradIcon from '../../../../../assets/images/traduction-icon.svg';
import {
    useStyles
} from '../../../../question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer.functions';
import hljs from 'highlight.js';

export function MessageContenu(props) {
    const dispatch = useDispatch();
    const parcoursId = useSelector(selectParcours);
    const classes = useStyles();

    const [translatedMessage, setTranslatedMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTranslationMode, setIsTranslationMode] = useState(false);

    const [isCheckButtonVisible, setIsCheckButtonVisible] = useState(true);

    function sendResponse() {
        setIsCheckButtonVisible(false);
        dispatch(getNextParcoursHistoryByUserId(parcoursId));
    }

    useEffect(() => {
        hljs.highlightAll();
    }, []);

    useEffect(() => {
        if(!isTranslationMode || translatedMessage) {
            return;
        }
        setIsLoading(true);
        let contentMessage = '`';
        if(props.message.content.header) {
            contentMessage += `${props.message.content.header}\``;
        }
        contentMessage += `${props.message.content}\``;

        localLlmServiceApi.translate(props.questionId, contentMessage)
            .then((response) => {
                const translatedData = response.data.split('`')
                    .map((data) => data.trim())
                    .filter((answerText) => answerText !== '')
                    .shift();
                setTranslatedMessage(translatedData);
            })
            .catch((error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                }
                if(error.response && error.response.status === 500) {
                    dispatch(notificationError(GET_TRADUCTION_REQUEST_ERROR));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isTranslationMode]);

    return (
        <div className="message oxa">
            <img id="avatar" src={oxa} />
            <div className="header-chat-bot" />
            <div style={{ display: 'flex', alignItems: 'center' }} className={isLoading ? 'blurEffect' : ''}>

                {isTranslationMode

                    ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div dangerouslySetInnerHTML={{ __html: translatedMessage === null ? props.message.content : translatedMessage }} className="content bold" />
                    </div>

                    : (typeof props.message.content === 'string')
                        ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div dangerouslySetInnerHTML={{ __html: props.message.content.replace(/\n/gu, '<br>') }} className="content bold" />
                        </div>
                        : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div dangerouslySetInnerHTML={{ __html: 'Il n&lsquo;y a pas de cours disponible' }} className="content bold" />
                            { isCheckButtonVisible && (
                                <IconButton style={{ bottom: '0', right: '0', position: 'absolute', color: '#0076ba', transform: 'translate(50%, 50%)' }} onClick={sendResponse}>
                                    <CheckCircleIcon style={{ fontSize: '2.3rem' }} />
                                </IconButton>
                            )}
                        </div>
                }

                {props.parcoursStarted &&
                    <IconButton id="iconHeaderRight" title="Traduire cette explication" color="primary" onClick={() => setIsTranslationMode(!isTranslationMode)}>
                        <img src={tradIcon} alt="Custom Icon" />
                    </IconButton>
                }

            </div>

            {isLoading &&
                <div className="loaderContainer">
                    <CircularProgress className={classes.loader} />
                </div>}

        </div>
    );
}

MessageContenu.propTypes = {
    message: PropTypes.object,
    parcoursStarted: PropTypes.bool,
    firstParcoursId: PropTypes.number,
    questionId: PropTypes.number
};

export default MessageContenu;
