import axiosClient from './utils/axiosClient';

export const HISTORIES_URI = 'histories';

export function getQuestionHistoryByIdApi(questionHistoryId) {
    return axiosClient.get(`${HISTORIES_URI}/questions/${questionHistoryId}`);
}

export function getHistoryByUserIdApi(userId, size = 10, offset = 0) {
    const params = {
        params: {
            size,
            offset
        }
    };
    return axiosClient.get(`${HISTORIES_URI}/users/${userId}/parcours`, params);
}

export function postHistoryPaginatedApi(page) {
    return axiosClient.post(`${HISTORIES_URI}/paginated`, page);
}
export function postGraphicHistoryApi(searchCriteria) {
    return axiosClient.post(`${HISTORIES_URI}/graph`, searchCriteria);
}

export function postGroupGraphicHistoryApi(searchCriteria) {
    return axiosClient.post(`${HISTORIES_URI}/graph/group`, searchCriteria);
}

export function postSerieHistoryApi(params) {
    return axiosClient.post(`${HISTORIES_URI}/series`, params);
}

export function postSerieHistoryApiCandidate(candidateUuid) {
    return axiosClient.post(`${HISTORIES_URI}/series/candidate/${candidateUuid}`, {});
}

export function getQuestionsHistoryBySerieHistoryIdApi(serieHistoryId) {
    return axiosClient.get(`${HISTORIES_URI}/series/display/${serieHistoryId}`);
}

export function getParcoursStepHistoryByParcoursIdApi(parcoursId, userId) {
    return axiosClient.get(`${HISTORIES_URI}/parcours/${parcoursId}/users/${userId}`);
}

export function getParcoursStepContentByParcoursStepIdApi(parcoursStepId, userId) {
    return axiosClient.get(`${HISTORIES_URI}/parcours/parcoursSteps/${parcoursStepId}/users/${userId}`);
}

export function getQuestionsHistoryByQuestionIdAndUserIdApi(questionId, userId) {
    return axiosClient.get(`${HISTORIES_URI}/questions/list/${questionId}/${userId}`);
}

export function getQuestionsStatDisplayBySerieHistoryIdApi(serieHistoryId) {
    return axiosClient.get(`${HISTORIES_URI}/questions/stat/${serieHistoryId}`);
}

export function getListHistorySerieGroupedBySerieHistoryIdApi(serieHistoryId, groupName) {
    return axiosClient.get(`${HISTORIES_URI}/series/display/${serieHistoryId}/${groupName}`);
}

export function getListQuestionHistoryGroupedApi(serieHistoryId, groupName, id) {
    return axiosClient.get(`${HISTORIES_URI}/series/display/${serieHistoryId}/${groupName}/${id}`);
}

export function getSerieHistoryCandidateByCandidateIdApi(candidateId) {
    return axiosClient.get(`${HISTORIES_URI}/series/candidate/${candidateId}`);
}

export function getUnfinishedSeries(userId) {
    return axiosClient.get(`${HISTORIES_URI}/series/unfinished/${userId}`);
}

export function getPageQuestionOuverteByPage(pageCriteria) {
    return axiosClient.post(`${HISTORIES_URI}/questionOuverte/coach`, pageCriteria);
}

export function patchPendingSerieIsDoneApi(serieHistoryId, userId) {
    return axiosClient.patch(`${HISTORIES_URI}/series/pending/isDone/${serieHistoryId}/${userId}`);
}

export function patchPendingSerieCandidateIsDoneApi(serieHistoryId, candidateUuid) {
    return axiosClient.patch(`${HISTORIES_URI}/series/candidate/pending/isDone/${serieHistoryId}/${candidateUuid}`);
}

export function getPendingSerieCandidateExistsApi(candidateUuid) {
    return axiosClient.get(`${HISTORIES_URI}/series/candidate/pending/exists/${candidateUuid}`);
}

export function patchPendingSerieDurationApi(serieResume) {
    return axiosClient.patch(`${HISTORIES_URI}/series/pending/duration`, serieResume);
}

export function patchPendingSerieDurationCandidateApi(serieResume, candidateUuid) {
    return axiosClient.patch(`${HISTORIES_URI}/series/candidate/pending/duration/${candidateUuid}`, serieResume);
}

// Reviews
export function patchQuestionOuverteHistoryReviewApi(review) {
    return axiosClient.patch(`${HISTORIES_URI}/questionOuverte/review`, review);
}

export function getHistoryGroupByTaskIdApi(taskId) {
    return axiosClient.get(`${HISTORIES_URI}/histories/group/tasks/${taskId}`);
}

export function getAnswerHistoryGroupByTaskIdAndQuestionIdApi(taskId, questionId) {
    return axiosClient.get(`${HISTORIES_URI}/histories/group/tasks/${taskId}/questions/${questionId}`);
}
