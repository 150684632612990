import React from 'react';
import PropTypes from 'prop-types';

import QuestionEditCode from './QuestionEditCode';

export function QuestionEditCodeBody(props) {

    const parsedStatement = props.statement.replace(/(<pre><code.*?>)|(<\/code><\/pre>)/gu, '~~code~~').split('~~code~~');

    const textBlock = (statement, index) => (<div data-testid="edit-code-text" dangerouslySetInnerHTML={{ __html: statement }} key={index}/>);

    const codeBlock = (statement, index) => (<div key={index}><QuestionEditCode code={statement} /></div>);

    return (
        <div data-testid="edit-code-body">
            { parsedStatement.map((el, index) => index % 2 === 0
                ? textBlock(el, index)
                : codeBlock(el, index))
            }
        </div>
    );
}


QuestionEditCodeBody.propTypes = {
    statement: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
};

export default QuestionEditCodeBody;
