import axiosClient from './utils/axiosClient';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const PROJECTS_SET_URI = 'projects';

export function postProjectApi(project) {
    return axiosClient.post(`${PROJECTS_SET_URI}?userEmail=${AuthenticationContainer.getEmail()}`, project);
}

export function putProjectApi(project) {
    return axiosClient.put(PROJECTS_SET_URI, project);
}

export function deleteProjectApi(projectId) {
    return axiosClient.delete(`${PROJECTS_SET_URI}/${projectId}`);
}
