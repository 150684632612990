/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ChapterDisplay.scss';
import 'allotment/dist/style.css';
import { ChapterTreeDisplay } from '../ChapterTree/ChapterTreeDisplay';

import ChapterContent from '../ChapterContent/ChapterContent';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    closeChapterTree,
    openChapterTree,
    setOptionsChapterDisplay,
    setSelectedChapterId,
    setStudentSelectedStore
} from 'app/redux/actions/Chapter/chapter.actions';
import {
    selectChapterReporting,
    selectOptionsChapterDisplay,
    selectSelectedChapter,
    selectSelectedChaptersSkillView,
    selectStudent
} from 'app/redux/selectors/Chapter/chapter.selector';
import { selectHistorySameQuestion } from 'app/redux/selectors/Question/history.selector';
import {
    getQuestionsHistoryByQuestionIdAndUserId,
    resetQuestionsHistoryByQuestionId
} from 'app/redux/actions/Question/History/history.actions';
import { setQuestionParameters } from 'app/question/history/History.functions';
import AddEditChapterDialog from 'app/components/Dialog/AddEditChapterDialog';
import { selectBookmark } from 'app/redux/selectors/Bookmark/bookmark.selector';
import { getChapterValidationBySkillIdAndUserId } from 'app/redux/actions/ChapterValidation/ChapterValidation.actions';
import { getBookmarkUserAndSkill } from 'app/redux/actions/Bookmark/bookmark.actions';
import { selectGroupStatsByChapter, selectStudents } from 'app/redux/selectors/Group/group.selector';
import ChapterGroupStats from './ChapterGroupStats';
import { getGroupsStatsByChapterId, getGroupsStatsByProfile, getStudentByGroupsId } from 'app/redux/actions/Group/group.actions';
import { selectQuestionSelectedByStudent } from 'app/redux/selectors/Question/question.selector';
import { getChapterReportingCountsBySkillIdApi } from 'app/api/chapterReportingApi';
import { OPTIONS } from 'app/common/userLearning/constants';
import ChapterDesktop from './ChapterDesktop';
import ChapterMobile from './ChapterMobile';
import UserLearning from 'app/common/userLearning/UserLearning';
import ChapterCoaching from 'app/common/userLearning/options/ChapterCoaching';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectCurrentSkill } from 'app/redux/selectors/Skill/SkillSet/skillSet.selector';
import { getSkillReducedByQuestionIdApi } from 'app/api/skillSetApi';
import { SKILL_PAGE_URL } from 'app/Routes';
import { getNextSunday, getPreviousMonday } from '../../../../../assignment/drawerHistory/DrawerHistory.function';
import { resetQuestionSelectedByStudent, setQuestionSelectedByStudent,setQuestionSelectedHistories,setQuestionSelectedHistory } from 'app/redux/actions/Question/question.actions';

const optionsDrawer = [
    {
        label: OPTIONS.CHAPTERS_STUDENT_GROUP,
        value: 1
    },
    {
        label: OPTIONS.TRAVAIL_A_FAIRE_DE,
        value: 2
    },
    {
        label: OPTIONS.TRAVAIL_FAIT_PAR,
        value: 3
    },
    {
        label: OPTIONS.PARCOURS,
        value: 4
    }
];

function ChapterDisplay(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));


    const bookmark = useSelector(selectBookmark);
    const historySameQuestion = useSelector(selectHistorySameQuestion);
    const questionSelectedByStudentFromStore = useSelector(selectQuestionSelectedByStudent);
    const selectedQuestion = questionSelectedByStudentFromStore?.questionId ?? null;
    const reportingList = useSelector(selectChapterReporting);
    const selectedChapter = useSelector(selectSelectedChapter);
    const selectedChapters = useSelector(selectSelectedChaptersSkillView);
    const statData = useSelector(selectGroupStatsByChapter);
    const studentList = useSelector(selectStudents);
    const optionsChapterDisplay = useSelector(selectOptionsChapterDisplay);
    const currentSkill = useSelector(selectCurrentSkill);
    const currentStudentSelected = useSelector(selectStudent);

    const getCurrentUserFirstName = () => AuthenticationContainer.getGivenName();
    const getCurrentUserId = () => Number(AuthenticationContainer.getCurrentUserId());
    const getCurrentUserLastName = () => AuthenticationContainer.getFamilyName();

    const [answerToDisplay, setAnswerToDisplay] = useState();
    const [chapters, setChapters] = useState([]);
    const [optionSelected, setOptionSelected] = useState(optionsDrawer[optionsChapterDisplay]);
    const [dialogProps, setDialopProps] = useState();

    const [displayGroupTab, setDisplayGroupTab] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [getStats, setGetStats] = useState(true);
    const [groupSelected, setGroupSelected] = useState(null);

    const [isAddingQuestion, setIsAddingQuestion] = useState(false);
    const [questionHistoryData, setQuestionHistoryData] = useState(statData);
    const [reportNumber, setReportNumber] = useState({});
    const [selectedAttemptIndex, setSelectedAttemptIndex] = useState(0);
    const [mondayDate, setMondayDate] = useState(getPreviousMonday());
    const [sundayDate, setSundayDate] = useState(getNextSunday());

    const [showEditQuestion, setShowEditQuestion] = useState();
    const [studentSelected, setStudentSelected] = useState(currentStudentSelected ?? {
        name: `${getCurrentUserFirstName()} ${getCurrentUserLastName()}`,
        id: getCurrentUserId(),
        type: 'student'
    });
    const [students, setStudents] = useState(studentList);

    const [type, setType] = useState('student');

    const [filterBySkill, setFilterBySkill] = useState(false);

    useEffect(() => {
        if(studentSelected) {
            dispatch(getBookmarkUserAndSkill(getCurrentUserId(), props.skill.id));
        }
    }, [props.chapters.data]);

    // HEADER

    useEffect(() => {
        if(studentSelected) {
            dispatch(getBookmarkUserAndSkill(studentSelected.id, props.skill.id));
        } else {
            dispatch(setStudentSelectedStore({
                name: `${getCurrentUserFirstName()} ${getCurrentUserLastName()}`,
                id: getCurrentUserId()
            }));
        }
    }, []);

    useEffect(() => {
        if(studentSelected) {
            dispatch(setStudentSelectedStore(studentSelected));
            dispatch(getChapterValidationBySkillIdAndUserId(props.skill.id, studentSelected.id));
        }
    }, [studentSelected]);

    useEffect(() => {
        if(groupSelected) {
            dispatch(getStudentByGroupsId(groupSelected.id));
        }
    }, [groupSelected]);

    // QUESTION HISTORY

    useEffect(() => {
        if(historySameQuestion?.length > 0 && studentSelected?.id !== getCurrentUserId()) {
            const newAnswerToDisplay = setQuestionParameters(historySameQuestion[0]).question;
            setAnswerToDisplay(newAnswerToDisplay);
        } else {
            setAnswerToDisplay();
        }
    }, [historySameQuestion]);

    useEffect(() => {
        dispatch(resetQuestionsHistoryByQuestionId());
        setSelectedAttemptIndex(null);
        const userId = questionSelectedByStudentFromStore?.studentId ?? studentSelected?.id;
        if(questionSelectedByStudentFromStore?.questionId && userId) {
            dispatch(getQuestionsHistoryByQuestionIdAndUserId(questionSelectedByStudentFromStore.questionId, userId));
        }
    }, [questionSelectedByStudentFromStore?.questionId]);

    // GROUP STATS

    useEffect(() => {
        setStudents(studentList);
    }, [studentList]);

    useEffect(() => {
        setQuestionHistoryData(statData);
    }, [statData]);

    useEffect(() => {
        if(selectedChapters.length && students?.length) {
            dispatch(getGroupsStatsByProfile(students.map((student) => student.id), selectedChapters.map((chapter) => chapter.id)));
        }
    }, [students]);

    useEffect(() => {
        if(getStats && chapters.length < selectedChapters.length && !questionHistoryData?.get(selectedChapters?.slice(-1).at(0)?.id)) {
            dispatch(getGroupsStatsByChapterId(students.map((student) => student.id), selectedChapters.slice(-1).at(0).id));
        }
        setChapters(selectedChapters);
        setGetStats(true);
    }, [selectedChapters]);

    useEffect(() => {
        if(props.isModerator) {
            getChapterReportingCountsBySkillIdApi(props.skill.id).then((res) => setReportNumber(res.data));
        }
    }, [props.isModerator, reportingList]);


    // CONTENT STATE

    const resetTree = () => {
        dispatch(setSelectedChapterId({ chapterId: 0, skillId: props.skill.id }));
        setExpanded([]);
    };

    const handleQuestionHistorySelect = (question) => {
        if(currentSkill?.id !== question.skillId) {
            dispatch(setOptionsChapterDisplay(2));
            dispatch(setStudentSelectedStore(studentSelected));
            getSkillReducedByQuestionIdApi(question.id).then((response) => {
                const url = `${SKILL_PAGE_URL}/${response.data.name}`;
                history.push(url);
            });
        }
    };

    const handleSelect = (_event, nodeId) => {
        if(!String(nodeId).startsWith('Questions') && !String(nodeId).startsWith('Series')) {
            dispatch(setSelectedChapterId({ chapterId: Number(nodeId), skillId: props.skill.id }));
            setIsAddingQuestion(false);
            dispatch(resetQuestionSelectedByStudent());
            dispatch(setQuestionSelectedByStudent({ questionId: null, studentId: null }));
        }
        setShowEditQuestion(undefined);
    };

    const handleClickQuestion = (questionId) => {
        setShowEditQuestion(undefined);
        dispatch(setQuestionSelectedByStudent({ questionId, studentId: studentSelected?.id }));
        dispatch(setQuestionSelectedHistory(null));
        dispatch(setQuestionSelectedHistories([]));
    };

    const handleCreateQuestion = () => {
        setIsAddingQuestion(true);
        dispatch(resetQuestionSelectedByStudent());
    };

    const handleOnEditQuestion = (questionId) => {
        setIsAddingQuestion(false);
        dispatch(resetQuestionSelectedByStudent());
        setShowEditQuestion(questionId);
    };

    const handleOnClickHistoryResult = (indexHistory) => {
        dispatch(setQuestionSelectedHistory(null));
        setSelectedAttemptIndex(indexHistory);
        if(historySameQuestion?.length > indexHistory) {
            const newAnswerToDisplay = setQuestionParameters(historySameQuestion[indexHistory]).question;
            setAnswerToDisplay(newAnswerToDisplay);
        } else {
            setAnswerToDisplay();
        }
    };

    const closeHistory = () => {
        setAnswerToDisplay();
    };

    // DIALOG

    const openDialog = (dProps) => {
        setDialopProps({
            ...dProps,
            open: true
        });
    };

    const openChapter = (chapterId) => {
        if(!expanded.find((cId) => cId === String(chapterId))) {
            setExpanded((state) => [
                String(chapterId),
                ...state
            ]);
        }
    };

    const handleCloseDialog = () => {
        setDialopProps();
    };

    // OPTIONS

    const handleChangeOptionDisplay = (option) => {
        setOptionSelected(option);
        const index = option.value - 1;
        dispatch(setOptionsChapterDisplay(index));
    };

    const isChapterSelected = () => optionSelected.label === OPTIONS.CHAPTERS_STUDENT_GROUP;

    const handleDateUpdated = (newMondayDate, newSundayDate) => {
        setMondayDate(newMondayDate);
        setSundayDate(newSundayDate);
    };

    // COMPONENTS

    const chapterCoaching =
        <ChapterCoaching
            chapters={props.chapters}
            displayGroupTab={displayGroupTab}
            groupSelected={groupSelected}
            openDialog={openDialog}
            permission={props.permission}
            resetTree={resetTree}
            setDataWithProfile={() => setGetStats(false)}
            setDisplayGroupTab={() => setDisplayGroupTab((prev) => !prev)}
            skill={props.skill}
            type={type}
            userId={getCurrentUserId()}
        />;

    const chapterContent =
        <ChapterContent
            isAddingQuestion={isAddingQuestion}
            setIsAddingQuestion={setIsAddingQuestion}
            showEditQuestion={showEditQuestion}
            setShowEditQuestion={setShowEditQuestion}
            skill={props.skill}
            chapterSelected={selectedChapter}
            permission={props.permission}
            selectedQuestion={selectedQuestion}
            handleOnEditQuestion={handleOnEditQuestion}
            onFinishEditing={handleClickQuestion}
            questionAnswer={answerToDisplay}
            questionHistory={historySameQuestion}
            handleOnClickHistoryResult={handleOnClickHistoryResult}
            handleOnClickBackButton={closeHistory}
            selectedAttemptIndex={selectedAttemptIndex}
            setSelectedAttemptIndex={setSelectedAttemptIndex}
            bookmark={bookmark}
            isModerator={props.isModerator}
            sizeUpMd={sizeUpMd}
            reportNumber={reportNumber}
        />;

    const chapterGroupStats =
        <ChapterGroupStats
            studentList={studentList}
            questionOrSerieHistoryData={questionHistoryData}
        />;

    const requiredUserLearningProps = {
        studentSelected,
        optionList: optionsDrawer,
        optionSelected,
        isInteractiveQuiz: false,
        fromTaskDrawer: false,
        userId: getCurrentUserId()
    };

    const chapterHeader =
        <div className="chapter-header">
            <UserLearning
                chapterCoaching={chapterCoaching}
                type="chapter"
                groupSelected={groupSelected}
                handleChangeDrawerOptionDisplay={handleChangeOptionDisplay}
                options={requiredUserLearningProps}
                setGroupSelected={setGroupSelected}
                setStudentSelected={setStudentSelected}
                onClickQuestionHistory={handleQuestionHistorySelect}
                setType={setType}
                handleDateUpdated={handleDateUpdated}
                handleFilterBySkill={(value) => setFilterBySkill(value)}
                skillId={props.skill.id}
                filterBySkill={filterBySkill}
            />
        </div>;

    const chapterTreeDisplay =
        <ChapterTreeDisplay
            bookmark={bookmark}
            chapters={props.chapters}
            expanded={expanded}
            handleCreateQuestion={handleCreateQuestion}
            onClickQuestion={handleClickQuestion}
            onNodeSelect={handleSelect}
            openDialog={openDialog}
            permission={props.permission}
            selectedGroup={groupSelected}
            selectedUserId={studentSelected?.id}
            selectedQuestion={selectedQuestion}
            setExpanded={setExpanded}
            skill={props.skill}
            userId={getCurrentUserId()}
        />;

    return (
        <>
            <AddEditChapterDialog
                {...dialogProps}
                openChapter={openChapter}
                onClose={handleCloseDialog}
            />
            {sizeUpMd
                ? <ChapterDesktop
                    chapterContent={chapterContent}
                    chapterGroupStats={chapterGroupStats}
                    chapterHeader={chapterHeader}
                    chapterTreeDisplay={chapterTreeDisplay}
                    displayGroupTab={displayGroupTab}
                    groupSelected={groupSelected}
                    handleChapterTreeClose={props.handleChapterTreeClose}
                    handleChapterTreeOpen={props.handleChapterTreeOpen}
                    isChapterSelected={isChapterSelected()}
                    selectedQuestion={selectedQuestion}
                    reportingList={reportingList}
                    skillId={props.skill.id}
                    studentSelected={studentSelected}
                    filterBySkill={filterBySkill}
                    displayGraph={optionSelected.label === OPTIONS.TRAVAIL_FAIT_PAR}
                    mondayDate={mondayDate}
                    sundayDate={sundayDate}
                />
                : <ChapterMobile
                    chapterContent={chapterContent}
                    chapterGroupStats={chapterGroupStats}
                    chapterHeader={chapterHeader}
                    chapterTreeDisplay={chapterTreeDisplay}
                    displayGroupTab={displayGroupTab}
                    groupSelected={groupSelected}
                    isModerator={props.isModerator}
                    skill={props.skill}
                />
            }
        </>
    );
}

ChapterDisplay.propTypes = {
    chapters: PropTypes.object.isRequired,
    skill: PropTypes.object.isRequired,
    permission: PropTypes.bool,
    isModerator: PropTypes.bool,
    handleChapterTreeOpen: PropTypes.func,
    handleChapterTreeClose: PropTypes.func,
    mondayDate: PropTypes.object,
    sundayDate: PropTypes.object,
    displayGraph: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
    return {
        handleChapterTreeOpen: () => {
            dispatch(openChapterTree());
        },
        handleChapterTreeClose: () => {
            dispatch(closeChapterTree());
        }
    };
}

export default connect(null, mapDispatchToProps)(ChapterDisplay);
