
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { skillActions } from 'app/redux/slices/skills.slice';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChapterCheckboxSelector from 'app/components/Selectors/ChapterCheckboxSelector/ChapterCheckboxSelector';
import { TextField } from '@material-ui/core';

export default function CompetenceChapterSelector({ selectedSkill, setSelectedSkill, selectedChapters, setSelectedChapters }) {

    const listAllSkill = useSelector(selectAllReducedSkills).data;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(skillActions.getAllReducedSkills());
    }, []);

    const handleCheckingChapters = (chapter) => {
        let newSelectedChapters = [...selectedChapters];
        const found = newSelectedChapters.some((selectedChapter) => selectedChapter.id === chapter.id);
        if(found) {
            newSelectedChapters = newSelectedChapters.filter((selectedChapter) => selectedChapter.id !== chapter.id);
        } else {
            newSelectedChapters.push(chapter);
        }
        setSelectedChapters(newSelectedChapters);
    };

    return (
        <div className="skillSelect" data-testid="competence-chapter-selector">
            <Autocomplete
                options={listAllSkill}
                value={selectedSkill}
                onChange= {(event, newValue) => {
                    setSelectedSkill(newValue);
                    setSelectedChapters([]);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField
                    {...params}
                    label="Compétence"
                    variant="outlined"
                />}
            />

            {selectedSkill !== null &&
                <ChapterCheckboxSelector
                    skill={selectedSkill}
                    selectedChapters={selectedChapters}
                    handleCheckingChapters={handleCheckingChapters}
                />
            }
        </div>
    );
}

CompetenceChapterSelector.propTypes = {
    selectedSkill: PropTypes.object,
    setSelectedSkill: PropTypes.func.isRequired,
    selectedChapters: PropTypes.array,
    setSelectedChapters: PropTypes.func.isRequired
};
