import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, IconButton, Box, Tooltip } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { AssignTypes } from 'app/utils/functions';
import ParcoursStep from './ParcoursStep';
import ParcoursStepContent from './ParcoursStepContent';
import DeleteZone from './DeleteZone';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { UPDATE_LIST_PARCOURS_ELEMENT_ALREADY_ERROR } from 'app/Snackbar/NotificationMessages';
import { isUndefined } from 'lodash';
function CreateParcoursForm(props) {
    const dispatch = useDispatch();
    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();
    const [expanded, setExpanded] = useState([]);

    const emptyParcours = () => ({
        id: null,
        name: '',
        creatorId: getCurrentUserId(),
        parcoursSteps: [
            {
                id: null,
                name: '',
                position: 1,
                parcoursStepContents: [
                ],
                examMode: false
            }
        ]

    });

    const [parcours, setParcours] = useState(emptyParcours);
    const [isFormValid, setIsFormValid] = useState(false);

    const isFormCorrect = () => (
        parcours.name !== '' &&
        parcours.parcoursSteps.length > 0 &&
        parcours.parcoursSteps.every((step) => step.name !== '') &&
        parcours.parcoursSteps[0].parcoursStepContents.length > 0
    );


    const handleParcoursName = (newParcoursName) => {
        setParcours((previousParcours) => ({
            ...previousParcours,
            name: newParcoursName
        }));
    };

    const handleParcoursStepName = (key, newParcoursStepName) => {
        const newParcours = {
            ...parcours,
            parcoursSteps: parcours.parcoursSteps.map((step, index) => {
                if(index === key) {
                    return { ...step, name: newParcoursStepName };
                }
                return step;
            }),
        };

        setParcours(newParcours);
    };

    const handleAddNewParcoursStep = () => {
        setParcours((previousParcours) => ({
            ...previousParcours,
            parcoursSteps: [...previousParcours.parcoursSteps,
                {
                    id: null,
                    name: '',
                    position: parcours.parcoursSteps.length + 1,
                    parcoursStepContents: [
                    ],
                    examMode:false
                }
            ]
        }));
        setExpanded([...expanded, true]);
    };

    const arrayMove = (array, oldIndex, newIndex, elementToMove) => {
        if(oldIndex === -1) {
            array.splice(newIndex, 0, elementToMove);
        } else {
            array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
        }
        return array;
    };

    const updatePositionParcoursSteps = () => {
        setParcours((prevParcours) => {
            const newParcoursSteps = [...prevParcours.parcoursSteps];
            newParcoursSteps.forEach((step) => { step.position = newParcoursSteps.indexOf(step) + 1; });
            return { ...prevParcours, parcoursSteps: newParcoursSteps };
        });
    };

    const updatePositionParcoursStepContent = (oldStep, newStep) => {
        setParcours((prevParcours) => {
            const newParcoursSteps = [...prevParcours.parcoursSteps];
            newParcoursSteps.forEach((step) => {
                if(step.position === newStep || (oldStep !== newStep && step.position === oldStep)) {
                    step.parcoursStepContents.forEach((stepContent) => { stepContent.position = step.parcoursStepContents.indexOf(stepContent) + 1; });
                }
            });

            return { ...prevParcours, parcoursSteps: newParcoursSteps };
        });
    };

    const createNewItem = (item, position) => {
        let contentId = null;
        let name = '';
        let type = item.type;
        switch (item.type) {
            case AssignTypes.CHAPTER:
                contentId = item.chapter.id;
                name = item.chapter.name;
                break;
            case AssignTypes.PARCOURS_STEP_CONTENT:
                contentId = item.stepContent?.contentId;
                name = item.stepContent?.name;
                type = item.stepContent?.type;
                break;
            default:
                contentId = item.id;
                name = item.name;
                break;
        }
        return {
            contentId,
            name,
            position,
            type
        };
    };

    const deleteItem = (itemArray, index) => {
        itemArray.splice(index, 1);
    };

    const switchSteps = (index, item) => {
        setParcours((prevParcours) => {
            const newParcoursSteps = [...prevParcours.parcoursSteps.filter(el => !isUndefined(el))];
            const movedParcoursSteps = arrayMove(newParcoursSteps, item.index, index);
            return { ...prevParcours, parcoursSteps: movedParcoursSteps };
        });
        updatePositionParcoursSteps();
        setExpanded(prevExpanded => {
            const newExpanded = [...prevExpanded];
            arrayMove(newExpanded, item.index, index);
            return newExpanded;
        });
    };

    const checkIfElementExists = (index, item) => parcours.parcoursSteps[index].parcoursStepContents.find(stepContent => {
        let id = item.id;
        let type = item.type;
        if(item.type === AssignTypes.CHAPTER) {
            id = item.chapter.id;
        }
        else if(item.type === AssignTypes.PARCOURS_STEP_CONTENT) {
            if(stepContent === item.stepContent) {
                return false;
            }
            id = item.stepContent.contentId;
            type = item.stepContent.type;
        }
        return stepContent.contentId === id && stepContent.type === type;
    });

    const switchContents = (index, item, oldStepIndex, newStepIndex) => {
        setParcours((prevParcours) => {
            const oldParcoursStepContents = item.step?.parcoursStepContents;
            const isSameStep = oldStepIndex === newStepIndex;
            if(!isSameStep) {
                deleteItem(oldParcoursStepContents, item.stepContentIndex);
            }
            return {
                ...prevParcours, parcoursSteps: prevParcours.parcoursSteps.map((step) => {
                    if(step.position === newStepIndex) {
                        if(isSameStep) {
                            step.parcoursStepContents = arrayMove(oldParcoursStepContents, item.stepContentIndex, index, item.stepContent);
                        } else {
                            step.parcoursStepContents = arrayMove(step.parcoursStepContents, -1, index, item.stepContent);
                        }
                    }
                    return step;
                })
            };
        });
    };

    const addContentOnStep = (index, item) => {
        setParcours((prevParcours) => {
            const newParcoursSteps = [...prevParcours.parcoursSteps];
            const targetStep = newParcoursSteps[index];
            if(targetStep) {
                const newItem = createNewItem(item, targetStep.parcoursStepContents.length + 1);
                targetStep.parcoursStepContents = [...targetStep.parcoursStepContents, newItem];
                newParcoursSteps[index] = targetStep;
            }
            if(item.type === AssignTypes.PARCOURS_STEP_CONTENT) {
                const oldStepContents = item.step.parcoursStepContents;
                const stepContentIndexToDelete = oldStepContents.indexOf(item.stepContent);
                deleteItem(oldStepContents, stepContentIndexToDelete);
                const oldParcoursStep = { ...item.step, parcoursStepContents: oldStepContents };
                newParcoursSteps.map((step) => {
                    if(step.position === oldParcoursStep.position) {
                        return oldParcoursStep;
                    }
                    return step;
                });
            }
            return { ...prevParcours, parcoursSteps: newParcoursSteps };
        });
    };

    const handleOnDropStep = (index, item) => {
        if(checkIfElementExists(index, item)) {
            dispatch(notificationError(UPDATE_LIST_PARCOURS_ELEMENT_ALREADY_ERROR));
        }
        else if(item.type === AssignTypes.PARCOURS_STEP) {
            switchSteps(index, item);
        }
        else if(item.type === AssignTypes.CHAPTER || item.type === AssignTypes.SERIE || item.type === AssignTypes.QUESTION || item.type === AssignTypes.PARCOURS_STEP_CONTENT) {
            addContentOnStep(index, item);
        }
    };

    const handleOnDropContent = (index, item, newStep) => {
        const newStepIndex = newStep.position;
        let oldStepIndex = newStep.position;
        if(checkIfElementExists(newStepIndex - 1, item)) {
            dispatch(notificationError(UPDATE_LIST_PARCOURS_ELEMENT_ALREADY_ERROR));
        }
        else if(item.type === AssignTypes.PARCOURS_STEP_CONTENT) {
            oldStepIndex = item.step.position;
            switchContents(index, item, oldStepIndex, newStepIndex);
        }
        else if(item.type === AssignTypes.CHAPTER || item.type === AssignTypes.SERIE || item.type === AssignTypes.QUESTION) {
            setParcours((prevParcours) => {
                const newItem = {
                    contentId: item.type === AssignTypes.CHAPTER ? item.chapter.id : item.id,
                    name: item.type === AssignTypes.CHAPTER ? item.chapter.name : item.name,
                    position: -1,
                    type: item.type
                };
                return {
                    ...prevParcours, 
                    parcoursSteps: prevParcours.parcoursSteps.map((step) => {
                        if(step.position === newStepIndex) {
                            step.parcoursStepContents = arrayMove(step.parcoursStepContents, -1, index, newItem);
                        }
                        return step;
                    })
                };

            });
        }    
        updatePositionParcoursStepContent(oldStepIndex, newStepIndex);
    };

    const handleDropDelete = (item) => {
        if(item.type === AssignTypes.PARCOURS_STEP) {
            setParcours((prevParcours) => {
                const newParcoursSteps = [...prevParcours.parcoursSteps];
                deleteItem(newParcoursSteps, item.index);
                return {
                    ...prevParcours, parcoursSteps: newParcoursSteps
                };

            });
            updatePositionParcoursSteps();
        } else if(item.type === AssignTypes.PARCOURS_STEP_CONTENT) {
            setParcours((prevParcours) => {
                const newParcoursStepContents = item.step.parcoursStepContents;
                deleteItem(newParcoursStepContents, item.stepContentIndex);
                return {
                    ...prevParcours, parcoursSteps: prevParcours.parcoursSteps.map((step) => {
                        if(step.position === item.step.position) {
                            step.parcoursStepContents = newParcoursStepContents;
                        }
                        return step;
                    })
                };

            });
            updatePositionParcoursStepContent(item.step.position, item.step.position);
        }
    };

    const handleSaveParcours = () => {
        props.createNewParcours(parcours);
        props.handleCloseForm();
    };

    const handleDuplicateParcours = () => {
        props.createNewParcours(parcours);
        setParcours((prevParcours) => {
            const duplicateName = `${prevParcours.name}(copie)`;
            return { ...prevParcours, name: duplicateName };
        });
    };

    const toggleExpandedByIndex = (index) => {
        setExpanded(prevExpanded => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    useEffect(() => {
        if(Object.keys(props.parcoursForEdit).length > 0) {
            setParcours(props.parcoursForEdit);
            setExpanded(props.parcoursForEdit?.parcoursSteps?.map(() => false));
        }
    }, []);
    useEffect(() => {
        setIsFormValid(isFormCorrect());
    }, [parcours]);

    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            marginLeft: '1.5rem'
        }}>
            <TextField
                label="Nouveau parcours"
                data-testid="parcours-creation-field"
                name="create-parcours-textfield"
                inputProps={{ maxLength: 100 }}
                margin="normal" QuestionDisplayDraggable
                required
                value={parcours.name}
                onChange={(event) => handleParcoursName(event.target.value)}
                autoComplete='off'
            />
            {parcours.parcoursSteps.map((parcoursStep, index) => (
                <div key={index}>
                    <ParcoursStep
                        key={index}
                        parcoursStep={parcoursStep}
                        index={index}
                        onDrop={handleOnDropStep}
                        handleParcoursStepName={handleParcoursStepName}
                        expand={expanded[index]}
                        toggleExpand={() => toggleExpandedByIndex(index)}
                    />
                    {expanded[index] && parcoursStep?.parcoursStepContents?.map((stepContent, stepContentIndex) => (
                        <ParcoursStepContent
                            key={stepContentIndex}
                            parcoursStep={parcoursStep}
                            parcoursStepContent={stepContent}
                            onDrop={handleOnDropContent}
                            stepContentIndex={stepContentIndex}
                        />
                    ))}
                </div>
            ))}
            <div style={{ position: 'sticky', bottom: 0, background: 'white' }}>
                <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <Tooltip title={'Ajouter une étape'}>
                        <IconButton
                            color="primary"
                            aria-label="add"
                            onClick={handleAddNewParcoursStep}
                        >
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={'Dupliquer ce parcours'}>
                        <IconButton
                            color="primary"
                            aria-label="duplicate"
                            onClick={handleDuplicateParcours}
                            disabled={!isFormValid}
                        >
                            <FileCopyIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={'Enregistrer ce parcours'}>
                        <IconButton
                            color="primary"
                            aria-label="save"
                            onClick={handleSaveParcours}
                            disabled={!isFormValid}
                        >
                            <SaveIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={'Fermer le formulaire'}>
                        <IconButton
                            color="primary"
                            aria-label="close"
                            onClick={() => props.handleCloseForm()}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
                <DeleteZone
                    handleDropDelete={handleDropDelete}
                >
                </DeleteZone>
            </div>
        </Box>
    );
}

CreateParcoursForm.propTypes = {
    setIsCreateParcoursToggled: PropTypes.func.isRequired,
    createNewParcours: PropTypes.func,
    handleCloseForm: PropTypes.func,
    parcoursForEdit: PropTypes.object,
};

export default CreateParcoursForm;
