import { Command } from 'ckeditor5/src/core';

export default class TexteatrouCommand extends Command {
	execute( { value } ) {
		const editor = this.editor;
		const selection = editor.model.document.selection;
		const acceptedAnswers = [ value ? value : '' ];

		editor.model.change( writer => {
			const texteatrou = writer.createElement( 'texteatrou', {
				...Object.fromEntries( selection.getAttributes() ), // A supprimer
				ordinal: 0,
				acceptedAnswers
			} );

			editor.model.insertObject( texteatrou, null, null, { setSelection: 'on' } );
		} );
		this.editor.fire( 'refresh' );
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;

		const isAllowed = model.schema.checkChild( selection.focus.parent, 'texteatrou' );

		this.isEnabled = isAllowed;
	}
}
