export function sliceTitle(title, maxLength) {
    if(!title) {
        return '';
    }
    if(title.indexOf(' ', 0) !== -1 && title.indexOf(' ', 0) < maxLength - 1) {
        let spaceNumber = 1;
        while(title.indexOf(' ', spaceNumber) !== -1 && title.indexOf(' ', spaceNumber) < maxLength - 1) {
            spaceNumber++;
        }
        return title.slice(0, spaceNumber - 1);
    }
    return title.slice(0, maxLength - 1);
}

export function colorStatIndication(success, attempt) {
    if((success / attempt) * 100 >= 75) {
        return 'green';
    } else if((success / attempt) * 100 >= 40) {
        return 'orange';
    }
    return 'red';
}
