import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ReactAudioPlayer from 'react-audio-player';
import { useSpeechRecognition } from 'react-speech-recognition';
import { CircularProgress, TextareaAutosize } from '@material-ui/core';
import QuestionOuverteAudioRecorder from './QuestionOuverteAudioRecorder';

export default function QuestionOuverteAnswers(props) {

    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    function useDebounce(value, delay) {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    const debouncedTotalText = useDebounce(props.totalText, 400);

    useEffect(() => {
        props.setTotalText(props.textAnswer || '');
    }, [props.textAnswer]);

    useEffect(() => {
        if(debouncedTotalText !== props.textAnswer) {
            props.setTextAnswer(debouncedTotalText);
            props.saveAudioTextAnswer(debouncedTotalText);
        }
    }, [debouncedTotalText]);

    return (
        <>
            { props.isSendingFile
                ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
                : <>
                    { !props.answered &&
                        <div style={{ padding: '0px 8px 8px 8px', marginLeft: '8px' }}>
                            <QuestionOuverteAudioRecorder
                                addAudioElement={props.addAudioElement}
                                totalText={props.totalText}
                                setTotalText={props.setTotalText}
                                transcript={transcript}
                                listening={listening}
                                saveAudioTextAnswer={props.saveAudioTextAnswer}
                                isQuestionOuverteRecording={props.isQuestionOuverteRecording}
                                setIsQuestionOuverteRecording={props.setIsQuestionOuverteRecording}
                            />
                        </div>
                    }
                    { props.audioBlobUrl &&
                        <div style={{ padding: '0px 8px 8px 8px', marginLeft: '8px' }}>
                            <ReactAudioPlayer
                                src={props.audioBlobUrl}
                                controls
                            />
                        </div>
                    }
                </>
            }
            <div style={{ width: '90%', marginLeft: '16px' }}>
                { props.isHistory
                    ? <>{ props.textAnswer &&
                        <TextareaAutosize
                            style={{ width: '100%' }}
                            value={props.textAnswer}
                            disabled
                        />
                    }</>
                    : <> { browserSupportsSpeechRecognition
                        ? <TextareaAutosize
                            style={{ width: '100%' }}
                            value={props.totalText}
                            onChange={(event) => {
                                props.setTotalText(event.target.value);
                            }}
                            disabled={props.answered}
                        />
                        : <span>{'Votre navigateur n\'est pas compatible avec la reconnaissance vocale.'}</span>
                    }
                    </>
                }
            </div>
        </>
    );
}

QuestionOuverteAnswers.propTypes = {
    answered: PropTypes.bool.isRequired,
    isSendingFile: PropTypes.bool,
    isHistory: PropTypes.bool,

    audioBlobUrl: PropTypes.string,
    textAnswer: PropTypes.string,
    setTextAnswer: PropTypes.func,
    totalText: PropTypes.string,
    setTotalText: PropTypes.func,

    addAudioElement: PropTypes.func.isRequired,
    saveAudioTextAnswer: PropTypes.func,
    listening: PropTypes.bool,
    isQuestionOuverteRecording: PropTypes.bool,
    setIsQuestionOuverteRecording: PropTypes.func
};
