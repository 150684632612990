export const TAB_INDEX = 'TAB_INDEX';
export const QUESTION_SELECTED = 'QUESTION_SELECTED';
export const QUESTION_SELECTED_HISTORY = 'QUESTION_SELECTED_HISTORY';
export const QUESTION_SELECTED_HISTORIES = 'QUESTION_SELECTED_HISTORIES';
export const RESET_QUESTION_SELECTED = 'RESET_QUESTION_SELECTED';

export const setTabIndex = (tabIndex) => (dispatch) => {
    dispatch({
        type: TAB_INDEX,
        payload: tabIndex
    });
};

export const setQuestionSelectedByStudent = (questionSelectedByStudent) => (dispatch) => {
    dispatch({
        type: QUESTION_SELECTED,
        payload: questionSelectedByStudent
    });
};

export const resetQuestionSelectedByStudent = () => (dispatch) => {
    dispatch({
        type: RESET_QUESTION_SELECTED,
    });
};

export const setQuestionSelectedHistory = (questionSelectedHistory) => (dispatch) => {
    dispatch({
        type: QUESTION_SELECTED_HISTORY,
        payload: questionSelectedHistory
    });
};

export const setQuestionSelectedHistories = (questionSelectedHistories) => (dispatch) => {
    dispatch({
        type: QUESTION_SELECTED_HISTORIES,
        payload: questionSelectedHistories
    });
};
