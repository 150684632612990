import axiosClient from './utils/axiosClient';
import { USER_DETAILED_SKILL_URI, USER_DETAILED_SKILLS_URI, USER_SKILL_URI, USER_URI } from './userApi';

export const USERSKILL_SET_URI = 'userskill/';

export function getExcilyenSetApi() {
    return axiosClient.get(USER_SKILL_URI);
}

export function getDetailedExcilyenSkillsApi() {
    return axiosClient.get(USERSKILL_SET_URI + USER_DETAILED_SKILLS_URI);
}

export function getDetailedExcilyenByIdApi(id) {
    return axiosClient.get(`${USER_URI}/${id}/detailed`);
}

export function getExcilyenDetailedBySkillNameApi(skillName) {
    return axiosClient.get(`${USER_DETAILED_SKILL_URI}/skillName/${skillName}`);
}

export function getExcilyenDetailedBySkillIdApi(id) {
    return axiosClient.get(`${USER_DETAILED_SKILL_URI}/id/${id}`);
}
