
import { GET_ALL_CATEGORIES_SUCCESS, GET_ALL_FAMILIES_SUCCESS } from '../../actions/FamilyCategory/familiesCategories.actions';
import { SET_FAMILIES_SKILLS_NUMBER } from '../../actions/Skill/SkillSet/skillSet.actions';

export default function skillSetReducer(state = {
    allCategories: [],
    allFamilies: []
}, action) {
    switch (action.type) {
        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                allCategories: action.payload
            };
        case GET_ALL_FAMILIES_SUCCESS:
            return {
                ...state,
                allFamilies: action.payload
            };
        case SET_FAMILIES_SKILLS_NUMBER:
            return {
                ...state,
                familiesSkillsNumber: action.payload
            };
        default:
            return state;
    }
}
