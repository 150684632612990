import React from 'react';

import './CoachingGroup.scss';

import { Grid, Paper } from '@material-ui/core';
import CoachingGroupStudents from './CoachingGroupStudents';
import CoachingGroupHeader from './CoachingGroupHeader';
import { selectGroup } from 'app/redux/selectors/Group/group.selector';
import { useSelector } from 'react-redux';
import CoachingGroupHistorique from './CoachingGroupHistorique/CoachingGroupHistorique';
import CoachingGroupGraph from './CoachingGroupGraph';

export default function CoachingGroup() {

    const group = useSelector(selectGroup);

    return (
        <>{ group && group.id && group.name
            ? <Grid container direction="column" alignItems="stretch" spacing={2}>
                <Grid item >
                    <CoachingGroupHeader />
                </Grid>
                <Grid item >
                    <CoachingGroupStudents />
                </Grid>
                <Grid item >
                    <CoachingGroupHistorique
                        groupName={group.name}
                        groupId={group.id}/>
                </Grid>
                <Grid item>
                    <CoachingGroupGraph
                        groupId={group.id}/>
                </Grid>
            </Grid>
            : <Paper className="detailsPaperCard">
                <div id="noStudentSelected">Veuillez sélectionner un Groupe</div>
            </Paper>
        }
        </>
    );
}
