import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, withRouter } from 'react-router-dom';
import QuestionRequestByQuestionHistoryId from '../QuestionResolver/QuestionRequest/QuestionRequestByQuestionHistoryId';
import { COACHING_PAGE_URL } from 'app/Routes';

export function QuestionHistory(props) {

    const questionHistoryId = Number(props.match.params.id);

    return (
        <div>
            {(questionHistoryId && questionHistoryId > 0)
                ? <QuestionRequestByQuestionHistoryId
                    questionHistoryId={questionHistoryId}
                />
                : <Redirect to={COACHING_PAGE_URL} />
            }
        </div>
    );
}

QuestionHistory.propTypes = {
    match: PropTypes.object
};

export default withRouter(QuestionHistory);
