export const SET_QUESTIONS_ID_FILTER = 'SET_QUESTIONS_ID_FILTER';

export function setQuestionsIdFilter(idFilter) {
    return {
        type: SET_QUESTIONS_ID_FILTER,
        payload: idFilter
    };
}

export const SET_QUESTIONS_SKILLS_FILTER = 'SET_QUESTIONS_SKILLS_FILTER';

export function setQuestionsSkillsFilter(skillsFilter) {
    return {
        type: SET_QUESTIONS_SKILLS_FILTER,
        payload: skillsFilter
    };
}

export const SET_QUESTIONS_CREATORS_FILTER = 'SET_QUESTIONS_CREATORS_FILTER';

export function setQuestionsCreatorsFilter(creatorsFilter) {
    return {
        type: SET_QUESTIONS_CREATORS_FILTER,
        payload: creatorsFilter
    };
}

export const SET_QUESTIONS_CHAPTERS_FILTER = 'SET_QUESTIONS_CHAPTERS_FILTER';

export function setQuestionsChaptersFilter(chaptersFilter) {
    return {
        type: SET_QUESTIONS_CHAPTERS_FILTER,
        payload: chaptersFilter
    };
}

export const SET_QUESTIONS_AI_GENERATED_FILTER = 'SET_QUESTIONS_AI_GENERATED_FILTER';

export function setQuestionsAiGeneratedFilter(chaptersFilter) {
    return {
        type: SET_QUESTIONS_AI_GENERATED_FILTER,
        payload: chaptersFilter
    };
}

export const RESET_QUESTIONS_CHAPTERS_FILTER = 'RESET_QUESTIONS_CHAPTERS_FILTER';

export function resetQuestionsChaptersFilter() {
    return {
        type: RESET_QUESTIONS_CHAPTERS_FILTER
    };
}
