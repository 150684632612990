/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import './DialogHelp.scss';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import tutoFirstConnectionStars from 'assets/images/tuto-first-connection-stars.png';
import tutoFirstConnectionDelete from 'assets/images/tuto-first-connection-delete.png';
import tutoFirstConnectionSearch from 'assets/images/tuto-first-connection-search.png';
import tutoFirstConnectionPagination from 'assets/images/tuto-first-connection-pagination.png';

class DialogHelp extends Component {

    state = {
        openDialog: true
    };

    handleOpen = () => {
        this.setState({
            ...this.state,
            openDialog: true
        });
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            openDialog: false
        });
    };

    render() {

        return <div className="button-help" data-testid="dialog-help">
            <Button variant="contained" color="primary" onClick={this.handleOpen} data-testid="open-dialog-button">
                <Icon id="icon-help">help_outline</Icon>
                Aide
            </Button>

            <Dialog aria-labelledby="dialog-title" open={this.state.openDialog} onClose={this.handleClose}>
                <DialogTitle id="dialog-title">Comment remplir ses compétences</DialogTitle>

                <DialogContent>
                    <div data-testid="dialog-help-content">
                        <DialogContentText id="dialog-description">
                            Pour indiquer votre niveau sur une compétence, il suffit de sélectionner le nombre d'étoiles correspondant à votre
                            niveau sur la ligne de la compétence en question. Pour obtenir de l'aide cliquez sur l'icône point d'interrogation.
                        </DialogContentText>
                        <img src={tutoFirstConnectionStars} alt="stars" />

                        <DialogContentText id="dialog-description">
                            Pour supprimer votre niveau sur une compétence, il suffit de cliquer sur l'icône en forme de poubelle.
                        </DialogContentText>
                        <img src={tutoFirstConnectionDelete} alt="delete" />

                        <DialogContentText id="dialog-description">
                            Vous pouvez chercher une compétence grâce à la barre de recherche.
                        </DialogContentText>
                        <img src={tutoFirstConnectionSearch} alt="search" />

                        <DialogContentText id="dialog-description">
                            Ou alors grâce à la pagination.
                        </DialogContentText>
                        <img src={tutoFirstConnectionPagination} alt="pagination" />

                        <DialogContentText id="dialog-description">
                            Enfin, si vous n'avez pas le temps de remplir vos compétences maintenant vous pourrez le faire plus tard sur la
                            page d'une compétence.
                        </DialogContentText>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" data-testid="close-dialog-button">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

        </div>;
    }
}

export default DialogHelp;
