import axiosClient from './utils/axiosClient';

export const COACH_PROFILE_URI = 'coach/profile';

export function getAllProfileApi(coachId, skillId) {
    return axiosClient.get(`${COACH_PROFILE_URI}?coachId=${coachId}&skillId=${skillId}`);
}

export function createCoachProfileApi(profile) {
    return axiosClient.post(COACH_PROFILE_URI, profile);
}

export function updateCoachProfileApi(profile) {
    return axiosClient.put(COACH_PROFILE_URI, profile);
}
