import React, { useState } from 'react';
import { patchLanguageMultipleChoiceQuestion } from 'app/api/multipleChoiceQuestionsSetApi';
import { languageOptions } from 'app/utils/LanguageUtils';
import { Select, Button } from 'antd';
import PropTypes from 'prop-types';

export default function QuestionLanguageSelector(props) {

    const [language, setLanguage] = useState(props.questionLanguage.acronym);

    const handleChange = (lang) => {
        setLanguage(lang);
    };

    const handleSendData = () => {
        patchLanguageMultipleChoiceQuestion(
            props.questionId,
            {
                acronym: language
            }
        ).then((response) => {
            props.onLanguageChange(response.data.language);
        });
    };

    return (<div className="language-container">
        <label className="language-label">
            Aucune langue n&apos;a été sélectionnée, veuillez en sélectionner une :
        </label>
        <Select className="language-selector"
            onChange={handleChange}
            placeholder={'Langue'}
            showSearch
            name={'langue'}
            options={languageOptions}
            value={language}
        />
        <Button
            shape="round"
            variant="outlined"
            onClick={handleSendData}
            disabled={ language === 'zza' }
        >
            Changer
        </Button>
    </div>);
}

QuestionLanguageSelector.propTypes = {
    questionId: PropTypes.number,
    questionLanguage: PropTypes.object,
    onLanguageChange: PropTypes.func.isRequired
};
