
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelectorForStoreValue } from '../redux/selectors/APISelector/APISelector';
import { GET_ALL_EXCILYENS } from '../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import { selectAllExcilyenSet } from '../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import { MaterialTableImpl } from '../common/MaterialTable/MaterialTableImpl';
import { Link } from '@material-ui/core';

export class LastConnectionTable extends Component {

    // eslint-disable-next-line class-methods-use-this
    displayDate = (dateParams) => {

        if(dateParams === null) {
            return <p>Non renseignée</p>;
        }
        const date = new Date(dateParams);
        return <p>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
        </p>;
    };

    render() {
        const { isFetching, excilyenSet } = this.props;
        const excilyens = excilyenSet || [];
        const excilyensSort = excilyens.sort((excilyen, excilyenToCompare) => (
            new Date(excilyenToCompare.lastConnectionDateTime) - new Date(excilyen.lastConnectionDateTime)));

        const columns = [
            {
                title: 'Excilyen',
                field: 'firstName',
                render: (rowData) => <Link href={`/excilyens/${rowData.id}`}>
                    {rowData.firstName} {rowData.lastName}</Link>


            },
            {
                title: 'Dernière connexion le',
                field: 'lastConnectionDateTime',
                render: (rowData) => this.displayDate(rowData.lastConnectionDateTime)
            },
            {
                title: 'Nombre de skills',
                field: 'skillDetailedApiDtos',
                headerStyle: {
                    textAlign: 'center',
                    paddingLeft: '50px'
                },
                cellStyle: {
                    textAlign: 'center'
                },
                render: (rowData) => <p>{rowData.skillDetailedApiDtos.length}</p>
            }

        ];

        return <MaterialTableImpl title={'Informations'} isFetching={isFetching} data={excilyensSort} columns={columns}/>;
    }
}

LastConnectionTable.propTypes = {
    excilyenSet: PropTypes.array,
    isFetching: PropTypes.bool
};

const mapStateToProps = (state) => ({
    excilyenSet: selectAllExcilyenSet(state),
    isFetching: createLoadingSelectorForStoreValue([GET_ALL_EXCILYENS], selectAllExcilyenSet)(state)
});

export default connect(mapStateToProps)(LastConnectionTable);
