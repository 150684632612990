import axios from 'axios';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

const client = axios.create({
    baseURL: process.env.REACT_APP_BACK_END
});

client.interceptors.request.use(async(config) => {
    await AuthenticationContainer.checkTokenAboutToExpireAndRefresh();
    config.headers = {
        'Authorization': `Bearer ${AuthenticationContainer.getToken()}`,
        'Content-Type': 'application/json'
    };
    return config;
});

export default client;
