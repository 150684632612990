/* eslint-disable max-lines */
import {
    RETRIEVE_CHAPTER_ERROR,
    CHAPTER_ADDED,
    CHAPTER_UPDATE,
    EXPIRED_SESSION,
    POST_CREATE_CHAPTER_ERROR,
    PUT_UPDATE_CHAPTER_ERROR,
    PATCH_CHAPTER_HEADER_ERROR,
    PATCH_CHAPTER_HEADER_ERROR_404,
    PATCH_CHAPTER_HEADER_UPDATED,
    CHAPTER_PASTED,
    POST_PASTE_CHAPTER_ERROR,
    GET_CHAPTER_COURSES_BY_IDS_ERROR,
    CHAPTER_DELETED,
    DELETE_CHAPTER_WITH_QUESTION,
    DELETE_CHAPTER_ERROR,
    RETRIEVE_CHAPTER_REPORTING_ERROR
} from '../../../Snackbar/NotificationMessages';
import { notificationError, notificationSuccess, notificationWarning } from '../Notification/notifications.actions';
import {
    deleteChapterApi,
    getChapterCoursesByIdsApi,
    patchChapterHeaderApi,
    postChapterApi,
    postPasteChapterApi,
    putChapterApi
} from '../../../api/chapterApi';
import { getChapterReportingByChapterIdApi, patchChapterDeniedReportingApi, patchChapterTreatedReportingApi } from '../../../api/chapterReportingApi';
import { chapterActions } from 'app/redux/slices/chapters.slice';

const getChapterDto = (chapter) => ({
    id: chapter.id,
    name: chapter.name,
    parentPath: chapter.parentPath,
    skillId: chapter.skillId,
    position: chapter.position,
    validationRate: chapter.validationRate,
    nbQuestionRequired: chapter.nbQuestionRequired,
    header: chapter.header
});

export const GET_CHAPTERS_TO_DISPLAY = 'GET_CHAPTERS_TO_DISPLAY';
export const GET_CHAPTERS_TO_DISPLAY_REQUEST = 'GET_CHAPTERS_TO_DISPLAY_REQUEST';

export const GET_CHAPTER_COURSES_BY_IDS_REQUEST = 'GET_CHAPTER_COURSES_BY_IDS_REQUEST';
export const GET_CHAPTER_COURSES_BY_IDS_SUCCESS = 'GET_CHAPTER_COURSES_BY_IDS_SUCCESS';
export const GET_CHAPTER_COURSES_BY_IDS_FAILURE = 'GET_CHAPTER_COURSES_BY_IDS_FAILURE';

export const RESET_CHAPTER_COURSES = 'RESET_CHAPTER_COURSES';

export const POST_CREATE_CHAPTER_REQUEST = 'POST_CREATE_CHAPTER_REQUEST';
export const POST_CREATE_CHAPTER_SUCCESS = 'POST_CREATE_CHAPTER_SUCCESS';
export const POST_CREATE_CHAPTER_FAILURE = 'POST_CREATE_CHAPTER_FAILURE';

export const POST_PASTE_CHAPTER_REQUEST = 'POST_PASTE_CHAPTER_REQUEST';
export const POST_PASTE_CHAPTER_SUCCESS = 'POST_PASTE_CHAPTER_SUCCESS';
export const POST_PASTE_CHAPTER_FAILURE = 'POST_PASTE_CHAPTER_FAILURE';

export const PUT_UPDATE_CHAPTER_REQUEST = 'PUT_UPDATE_CHAPTER_REQUEST';
export const PUT_UPDATE_CHAPTER_PARENT_PATH_REQUEST = 'PUT_UPDATE_CHAPTER_PARENT_PATH_REQUEST';
export const PUT_UPDATE_CHAPTER_FAILURE = 'PUT_UPDATE_CHAPTER_FAILURE';
export const PUT_UPDATE_CHAPTER_PARENT_PATH_FAILURE = 'PUT_UPDATE_CHAPTER_PARENT_PATH_FAILURE';

export const DELETE_CHAPTER_REQUEST = 'DELETE_CHAPTER_REQUEST';
export const DELETE_CHAPTER_FAILURE = 'DELETE_CHAPTER_FAILURE';

export const PATCH_CHAPTER_HEADER_REQUEST = 'PATCH_CHAPTER_HEADER_REQUEST';
export const PATCH_CHAPTER_HEADER_SUCCESS = 'PATCH_CHAPTER_HEADER_SUCCESS';
export const PATCH_CHAPTER_HEADER_FAILURE = 'PATCH_CHAPTER_HEADER_FAILURE';

export const SET_STUDENT_SELECTED = 'SET_STUDENT_SELECTED';

export const SET_SELECTED_CHAPTER = 'SET_SELECTED_CHAPTER';

export const ADD_SELECTED_CHAPTER_SKILL_VIEW_ID = 'ADD_SELECTED_CHAPTER_SKILL_VIEW_ID';
export const ADD_SELECTED_CHAPTER_SKILL_VIEW_LIST = 'ADD_SELECTED_CHAPTER_SKILL_VIEW_LIST';
export const REMOVE_ALL_CHAPTERS_IDS = 'REMOVE_ALL_CHAPTERS_IDS';
export const SET_DRAWER_SELECTED_CHAPTER = 'SET_DRAWER_SELECTED_CHAPTER';

export const OPEN_CHAPTER_TREE = 'OPEN_CHAPTER_TREE';
export const CLOSE_CHAPTER_TREE = 'CLOSE_CHAPTER_TREE';
export const OPEN_CHAPTER_REPORTING = 'OPEN_CHAPTER_REPORTING';
export const CLOSE_CHAPTER_REPORTING = 'CLOSE_CHAPTER_REPORTING';

export const SET_SELECTED_REPORTED_CHAPTER = 'SET_SELECTED_REPORTED_CHAPTER';

export const GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_REQUEST = 'GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_REQUEST';
export const GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_FAILURE = 'GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_FAILURE';
export const GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_SUCCESS = 'GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_SUCCESS';

export const PATCH_CHAPTER_REPORTING_TREATED_REQUEST = 'PATCH_CHAPTER_REPORTING_TREATED_REQUEST';
export const PATCH_CHAPTER_REPORTING_TREATED_FAILURE = 'PATCH_CHAPTER_REPORTING_TREATED_FAILURE';
export const PATCH_CHAPTER_REPORTING_TREATED_SUCCESS = 'PATCH_CHAPTER_REPORTING_TREATED_SUCCESS';

export const PATCH_CHAPTER_REPORTING_DENIED_REQUEST = 'PATCH_CHAPTER_REPORTING_DENIED_REQUEST';
export const PATCH_CHAPTER_REPORTING_DENIED_FAILURE = 'PATCH_CHAPTER_REPORTING_DENIED_FAILURE';
export const PATCH_CHAPTER_REPORTING_DENIED_SUCCESS = 'PATCH_CHAPTER_REPORTING_DENIED_SUCCESS';

export const SET_SELECTED_OPTIONS_CHAPTER_DISPLAY = 'SET_SELECTED_OPTIONS_CHAPTER_DISPLAY';

export const DELETE_TASK_FROM_CHAPTER_REPORTING = 'DELETE_TASK_FROM_CHAPTER_REPORTING';

export const getChapterCoursesByIds = (chapterIds) => (dispatch) => {
    dispatch({ type: GET_CHAPTER_COURSES_BY_IDS_REQUEST });

    return getChapterCoursesByIdsApi(chapterIds).then((response) => {
        dispatch({
            type: GET_CHAPTER_COURSES_BY_IDS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CHAPTER_COURSES_BY_IDS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(RETRIEVE_CHAPTER_ERROR));
        } else {
            dispatch(notificationError(GET_CHAPTER_COURSES_BY_IDS_ERROR));
        }
    });
};

export const resetChapterCourses = () => ({ type: RESET_CHAPTER_COURSES });

export const createChapter = (chapter) => (dispatch) => {
    dispatch({ type: POST_CREATE_CHAPTER_REQUEST });

    return postChapterApi(getChapterDto(chapter)).then((response) => {
        dispatch({
            type: POST_CREATE_CHAPTER_SUCCESS,
            payload: response.data
        });
        dispatch(chapterActions.addChapters([response.data]));
        dispatch(notificationSuccess(CHAPTER_ADDED));
    }, (error) => {
        dispatch({
            type: POST_CREATE_CHAPTER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(POST_CREATE_CHAPTER_ERROR));
        } else {
            dispatch(notificationError(POST_CREATE_CHAPTER_ERROR));
        }
    });
};

export const pasteChapter = (chapter) => (dispatch) => {
    dispatch({ type: POST_PASTE_CHAPTER_REQUEST });

    Reflect.deleteProperty(chapter, 'chapterValidationState');

    return postPasteChapterApi(getChapterDto(chapter)).then((response) => {
        dispatch({
            type: POST_PASTE_CHAPTER_SUCCESS,
            payload: response.data
        });
        dispatch(chapterActions.addChapters(response.data));
        dispatch(notificationSuccess(CHAPTER_PASTED));
    }, (error) => {
        dispatch({
            type: POST_PASTE_CHAPTER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(POST_PASTE_CHAPTER_ERROR));
        } else {
            dispatch(notificationError(POST_PASTE_CHAPTER_ERROR));
        }
    });
};

export const updateChapter = (chapter) => (dispatch) => {
    dispatch({ type: PUT_UPDATE_CHAPTER_REQUEST });

    return putChapterApi(getChapterDto(chapter)).then((response) => {
        dispatch(chapterActions.updateChapters(response.data));
        dispatch(notificationSuccess(CHAPTER_UPDATE));
    }, (error) => {
        dispatch({
            type: PUT_UPDATE_CHAPTER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(PUT_UPDATE_CHAPTER_FAILURE));
        } else {
            dispatch(notificationError(PUT_UPDATE_CHAPTER_ERROR));
        }
    });
};

export const deleteChapter = (chapter) => (dispatch) => {
    dispatch({ type: DELETE_CHAPTER_REQUEST });

    return deleteChapterApi(getChapterDto(chapter)).then(() => {
        dispatch(chapterActions.removeChapter(chapter.id));
        dispatch(notificationSuccess(CHAPTER_DELETED));
    }, (error) => {
        dispatch({
            type: DELETE_CHAPTER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 406) {
            dispatch(notificationError(DELETE_CHAPTER_WITH_QUESTION));
        } else {
            dispatch(notificationError(DELETE_CHAPTER_ERROR));
        }
    });
};

export const patchChapterHeader = (id, header) => (dispatch) => {
    dispatch({ type: PATCH_CHAPTER_HEADER_REQUEST });

    return patchChapterHeaderApi(id, header).then((response) => {
        dispatch({
            type: PATCH_CHAPTER_HEADER_SUCCESS,
            payload: response.data
        });
        dispatch(chapterActions.updateChapters([response.data]));
        dispatch({
            type: SET_SELECTED_CHAPTER,
            payload: id
        });
        dispatch(notificationSuccess(PATCH_CHAPTER_HEADER_UPDATED));
    }, (error) => {
        dispatch({
            type: PATCH_CHAPTER_HEADER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 400) {
            dispatch(notificationError(PATCH_CHAPTER_HEADER_ERROR));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(PATCH_CHAPTER_HEADER_ERROR_404));
        } else {
            dispatch(notificationError(PATCH_CHAPTER_HEADER_ERROR));
        }
    });
};

export const setStudentSelectedStore = (student) => ({
    type: SET_STUDENT_SELECTED,
    payload: student
});

export const setSelectedChapterId = ({ chapterId, skillId }) => (dispatch) => {
    window.localStorage.setItem(`bookmark-${skillId}`, chapterId);
    dispatch({
        type: SET_SELECTED_CHAPTER,
        payload: chapterId
    });
};

export const addChapterToSkillView = (chapter) => ({
    type: ADD_SELECTED_CHAPTER_SKILL_VIEW_ID,
    payload: chapter
});

export const addChaptersToSkillView = (chapters) => ({
    type: ADD_SELECTED_CHAPTER_SKILL_VIEW_LIST,
    payload: chapters
});

export const resetChapterSkillView = () => ({
    type: REMOVE_ALL_CHAPTERS_IDS
});

export const setDrawerSelectedChapterId = (chapterId) => ({
    type: SET_DRAWER_SELECTED_CHAPTER,
    payload: chapterId
});

export const openChapterTree = () => ({ type: OPEN_CHAPTER_TREE });


export const closeChapterTree = () => ({ type: CLOSE_CHAPTER_TREE });


export const openChapterReporting = () => ({ type: OPEN_CHAPTER_REPORTING });


export const closeChapterReporting = () => ({ type: CLOSE_CHAPTER_REPORTING });


export const getChapterReportingByChapterId = (chapterId) => (dispatch) => {
    dispatch({ type: GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_REQUEST });

    return getChapterReportingByChapterIdApi(chapterId).then((response) => {
        dispatch({
            type: GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_FAILURE,
            payload: error,
            error: true
        });
        dispatch(notificationError(RETRIEVE_CHAPTER_REPORTING_ERROR));
    });
};

export const setSelectedReportedChapter = (chapter) => ({
    type: SET_SELECTED_REPORTED_CHAPTER,
    payload: chapter
});

export const patchChapterReportingTreated = (userId, chapterId, reportingId, taskId) => (dispatch) => {

    dispatch({ type: PATCH_CHAPTER_REPORTING_TREATED_REQUEST });

    return patchChapterTreatedReportingApi(userId, chapterId, reportingId, taskId).then((_response) => {
        dispatch({
            type: PATCH_CHAPTER_REPORTING_TREATED_SUCCESS,
            payload: reportingId
        });
    }, (error) => {
        dispatch({
            type: PATCH_CHAPTER_REPORTING_TREATED_FAILURE,
            payload: error,
            error: true
        });
        dispatch(notificationError(RETRIEVE_CHAPTER_REPORTING_ERROR));
    });
};

export const patchChapterReportingDenied = (userId, chapterId, reportingId, taskId) => (dispatch) => {

    dispatch({ type: PATCH_CHAPTER_REPORTING_DENIED_REQUEST });

    return patchChapterDeniedReportingApi(userId, chapterId, reportingId, taskId).then((_response) => {
        dispatch({
            type: PATCH_CHAPTER_REPORTING_DENIED_SUCCESS,
            payload: reportingId
        });
    }, (error) => {
        dispatch({
            type: PATCH_CHAPTER_REPORTING_DENIED_FAILURE,
            payload: error,
            error: true
        });
        dispatch(notificationError(RETRIEVE_CHAPTER_REPORTING_ERROR));
    });
};

export const setOptionsChapterDisplay = (optionsChapter) => ({
    type: SET_SELECTED_OPTIONS_CHAPTER_DISPLAY,
    payload: optionsChapter
});

export const deleteTaskFromChapterReporting = (taskId) => ({
    type: DELETE_TASK_FROM_CHAPTER_REPORTING,
    payload: taskId
});

