import axiosClient from './utils/axiosClient';

export const CHAPTERS_URI = 'chapters';


export function getChapterBySkillAndParentPathApi(parentPath, skillId) {
    return axiosClient.get(`${CHAPTERS_URI}/skills/${skillId}/path?parentPath=${parentPath}`);
}

export function postChapterApi(chapter) {
    return axiosClient.post(CHAPTERS_URI, chapter);
}

export function postPasteChapterApi(chapter) {
    return axiosClient.post(`${CHAPTERS_URI}/paste`, chapter);
}

export function putChapterApi(chapter) {
    return axiosClient.put(CHAPTERS_URI, chapter);
}

export function deleteChapterApi(chapter) {
    return axiosClient.delete(CHAPTERS_URI, {
        data: chapter
    });
}

export function patchChapterHeaderApi(id, header) {
    return axiosClient.patch(`${CHAPTERS_URI}/${id}/header`, header);
}

export function getChapterBySkillAndUserApi(skillId, userId) {
    return axiosClient.get(`${CHAPTERS_URI}/skills/${skillId}/users/${userId}`);
}

export function postResourceApi(resource) {
    return axiosClient.post(`${CHAPTERS_URI}/resources`, resource);
}

export function getChapterResourcesApi(chapterId) {
    return axiosClient.get(`${CHAPTERS_URI}/${chapterId}/resources`);
}

export function getChapterCoursesByIdsApi(chapterIds) {
    return axiosClient.post(`${CHAPTERS_URI}/courses`, chapterIds);
}
