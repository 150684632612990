import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getSerieByIdWithQuestionReduced, resetSerieWithQuestionReduced } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { resetQuestionsReduced } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';
import { selectSerieWithQuestionReduced } from 'app/redux/selectors/Question/serie.selector';
import { selectModerators } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { QuestionModeration } from 'app/question/QuestionModeration/QuestionModeration';
import { QuestionTitleDraggable } from 'app/question/QuestionTable/QuestionTable';
import HistoryPagination from 'app/question/history/historyTable/historyPagination/HistoryPagination';

function QuestionTableInSerie(props) {

    const dispatch = useDispatch();

    const moderatorMap = useSelector(selectModerators);
    const serieWithQuestionReduced = useSelector(selectSerieWithQuestionReduced);

    const [dataTable, setDataTable] = useState();
    const [pageData, setPageData] = useState({
        pageNumber: 0,
        rowPerPage: 5,
        totalElement: 0
    });

    const handleChangePage = (event, number) => {
        setPageData((prevState) => ({
            ...prevState,
            pageNumber: number
        }));
    };

    const handleRowsPerPage = (event) => {
        setPageData((prevState) => ({
            ...prevState,
            pageNumber: 0,
            rowPerPage: event.target.value
        }));
    };

    useEffect(() => {
        if(props.serieId) {
            dispatch(getSerieByIdWithQuestionReduced(props.serieId));
        }
        return () => {
            dispatch(resetQuestionsReduced());
        };
    }, []);

    useEffect(() => {
        if(serieWithQuestionReduced && serieWithQuestionReduced.get(props.serieId) && Boolean(!dataTable)) {
            setDataTable(serieWithQuestionReduced.get(props.serieId).questionReducedList);
            dispatch(resetSerieWithQuestionReduced());
        }

    }, [serieWithQuestionReduced]);

    return (
        <Paper className="tablePaper">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow key={'header'}>
                            <TableCell>Id</TableCell>
                            <TableCell>Compétence</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataTable?.length
                                ? dataTable?.map((row, index) => (index + 1) > ((pageData.pageNumber + 1) * pageData.rowPerPage - pageData.rowPerPage) &&
                                (index + 1) <= ((pageData.pageNumber + 1) * pageData.rowPerPage)
                                    ? <TableRow key={row?.id}>
                                        <TableCell>
                                            <QuestionTitleDraggable
                                                id={row?.id}
                                                name={`${row?.skill?.name}#${row?.id}`}
                                            />
                                            { moderatorMap && <QuestionModeration
                                                moderators={moderatorMap.get(row?.id)}
                                                questionId={row?.id}
                                            />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {row?.skill?.name}
                                        </TableCell>
                                    </TableRow>
                                    : <></>)
                                : <div className="emptyListQuestions">
                                    <strong>Aucune question enregistr&eacute;e</strong>
                                </div>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <HistoryPagination
                totalElement={dataTable?.length}
                pageNumber={pageData.pageNumber}
                rowPerPage={pageData.rowPerPage}
                rowPerPageOptions={[5, 10, 20]}
                handleChangePage={handleChangePage}
                handleRowsPerPage={handleRowsPerPage}
            />
        </Paper>
    );

}

QuestionTableInSerie.propTypes = {
    serieId: PropTypes.number
};

export default QuestionTableInSerie;
