import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

export default function DialogBase(props) {
    const {
        title,
        content,
        actions,
        dialogProps,
        ...other
    } = props;

    return (
        <Dialog {...other} {...dialogProps?.root}>
            <DialogTitle {...dialogProps?.title}>
                {title}
            </DialogTitle>
            <DialogContent {...dialogProps?.content}>
                {typeof content === 'string'
                    ? <DialogContentText {...dialogProps?.text}>
                        {content}
                    </DialogContentText>
                    : content
                }
            </DialogContent>
            <DialogActions {...dialogProps?.actions}>
                {actions}
            </DialogActions>
        </Dialog>
    );
}

DialogBase.propTypes = {
    title: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    actions: PropTypes.object.isRequired,
    dialogProps: PropTypes.shape({
        root: PropTypes.object,
        title: PropTypes.object,
        content: PropTypes.object,
        text: PropTypes.object,
        actions: PropTypes.object
    })
};
