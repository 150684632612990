// ~~~~~~~~ MAPPING SKILLS ~~~~~~~~

export function mapSkillsReducedToSkillsSelect(skillsReducedArray) {
    if(!skillsReducedArray || skillsReducedArray.length === 0) {
        return [];
    }
    return skillsReducedArray
        .map((skillReduced) => ({ label: skillReduced.name, value: skillReduced.id }))
        .sort((s1, s2) => s1.label.localeCompare(s2.label));
}

// ~~~~~~~ Gestion des dates ~~~~~~~

export function toLocalTimeIsoString(date) {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num) => {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };

    const offset = `${dif}${pad(tzo / 60)}:${pad(tzo % 60)}` === '+00:00' ? '' : `${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;

    return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}${offset}`;
}

export function mapReminderJSDateToLocalTime(ISODate) {
    const date = new Date(ISODate);

    date.setSeconds(0);
    date.setMilliseconds(0);

    /*
     * Le format de date à envoyer au back est du type 10:30:15.917'TimeZone'
     * Lorsque l'on fait toIsoString, cela renvoie "2021-09-06T10:30:00.000'TimeZone'"
     * La méthode split('T')[1] renvoie bien la deuxième partie (LocalTime)
     */

    return toLocalTimeIsoString(date);

}

export function mapReminderLocalTimeToJSDate(backReminderLocalTime) {

    /*
     * Le format de date que le back envoie est du type 10:30:15.917Z
     *
     * /!\ La méthode setHours modifie la référence et renvoie le nombre de ms entre
     * le java EPOCH et la date mise à jour avec setHours
     */
    const [hours, minutes] = backReminderLocalTime.split(':');
    const reminderFormatted = new Date(Date.now());
    reminderFormatted.setHours(Number(hours) - reminderFormatted.getTimezoneOffset() / 60, minutes);
    return reminderFormatted;
}

