import { PayloadAction, Reducer } from '@reduxjs/toolkit';

interface Error {
    message: string,
    status: number | undefined
}

export const errorReducer: Reducer<Record<string, Error | null>, PayloadAction<any>> = (state = {}, action) => {
    const { type, payload } = action;
    const matches = (/(.*)_(REQUEST|FAILURE)/u).exec(type);

    // Not a *_REQUEST / *_FAILURE actions, so we ignore them
    if(!matches) {
        return state;
    }

    const [
        _completeName,
        requestName,
        requestState
    ] = matches;

    if(requestState === 'FAILURE') {
        state[requestName] = {
            message: payload.message,
            status: payload.response?.status
        };
    } else {
        delete state[requestName];
    }
    return state;
};
