import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactStars from 'react-stars';
import './SkillLevelRating.scss';
import { SkillLevelHelpDialog } from './SkillLevelHelpDialog';

import IconButton from '@material-ui/core/IconButton/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Icon from '@material-ui/core/Icon/Icon';

import { connect } from 'react-redux';
import { selectLoading } from 'app/redux/selectors/Loading/loading.selector';
import {
    selectCurrentUser,
    selectCurrentUserSkillSet,
    selectSkillLevelOfCurrentUser,
    selectIsSkillFavoriteOfCurrentUser
} from '../../redux/selectors/User/user.selector';
import {
    addUserSkill,
    removeUserSkillLevel,
    patchUserSkillLevel,
    removeUserSkill,
    PATCH_USER_SKILL_LEVEL
} from '../../redux/actions/User/UserSkills/userSkill.actions';

export class SkillLevelRating extends Component {

    userHasThisSkill = () => this.props.userSkillSet?.some((element) => (element.id === this.props.skillId));

    handleOnStarClick = (rateValue) => {
        const userSkill = {
            userId: this.props.user.id,
            skillId: this.props.skillId,
            level: rateValue
        };
        const map = {
            level: rateValue
        };

        if(this.userHasThisSkill()) {
            if(this.props.userLevel !== rateValue) {
                this.props.patchUserSkillLevel(userSkill.userId, userSkill.skillId, map);
            }
        } else {
            this.props.addUserSkill(userSkill);
        }
    };

    handleDeleteSkillLevel = () => {
        if(this.props.isLoadingRemove) {
            return;
        }
        if(this.props.isAUserFavoriteSkill) {
            this.props.removeUserSkillLevel(this.props.user.id, this.props.skillId);
        } else {
            this.props.removeUserSkill({
                userId: this.props.user.id,
                skillId: this.props.skillId
            });
        }
    };

    render() {
        return (
            <span data-testid="level-rating" style={{ display: 'flex', alignItems: 'center' }}>
                <ReactStars className="skill-level"
                    count={5}
                    size={this.props.starSize}
                    color2={'#ffd700'}
                    half={false}
                    edit={true}
                    value={this.props.userLevel}
                    onChange={(rateValue) => this.handleOnStarClick(rateValue)}/>

                <SkillLevelHelpDialog/>

                {this.props.userLevel > 0 &&
                <Tooltip title="Supprimer" aria-label="supprimer">
                    <IconButton id="delete-skill" data-testid="delete-stars" onClick={this.handleDeleteSkillLevel}>
                        <Icon>delete_forever</Icon>
                    </IconButton>
                </Tooltip>
                }
            </span>
        );
    }
}

SkillLevelRating.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        email: PropTypes.string,
        promo: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile: PropTypes.string,
        skills: PropTypes.array,
        admin: PropTypes.bool
    }),
    userLevel: PropTypes.number,
    starSize: PropTypes.number,
    skillName: PropTypes.string,
    skillId: PropTypes.number,
    userSkillSet: PropTypes.array,
    addUserSkill: PropTypes.func,
    patchUserSkillLevel: PropTypes.func,
    removeUserSkillLevel: PropTypes.func,
    removeUserSkill: PropTypes.func,
    isAUserFavoriteSkill: PropTypes.bool,
    isLoadingRemove: PropTypes.bool
};

SkillLevelRating.defaultProps = {
    starSize: 25
};

function mapStateToProps(state, props) {
    return {
        user: selectCurrentUser(state),
        userSkillSet: selectCurrentUserSkillSet(state),
        userLevel: selectSkillLevelOfCurrentUser(state, props.skillId),
        isAUserFavoriteSkill: selectIsSkillFavoriteOfCurrentUser(state, props.skillId),
        isLoadingRemove: selectLoading(state)[PATCH_USER_SKILL_LEVEL]
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addUserSkill: (userSkill) => dispatch(addUserSkill(userSkill)),
        patchUserSkillLevel: (email, skillName, map) => dispatch(patchUserSkillLevel(email, skillName, map)),
        removeUserSkillLevel: (userId, skillId) => dispatch(removeUserSkillLevel(userId, skillId)),
        removeUserSkill: (userSkill) => dispatch(removeUserSkill(userSkill))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillLevelRating);
