
import { Plugin } from 'ckeditor5/src/core';
import { ButtonView } from 'ckeditor5/src/ui';

import AddtrouIcon from '../../theme/icons/addtrou.svg';

function getSelectedText( selection ) {
	let value = '';
	const range = selection.getFirstRange();

	for ( const item of range.getItems() ) {
		value = value.concat( item.data ? item.data : '' ); // return the selected text
	}
	return value;
}
export default class TexteatrouUI extends Plugin {
	init() {
		const editor = this.editor;
		const t = editor.t;

		// Register the button in the editor's UI component factory.
		editor.ui.componentFactory.add( 'texteatrou', () => {
			const button = new ButtonView();

			button.set( {
				label: t( 'TextATrou' ),
				icon: AddtrouIcon,
				tooltip: true,
				withText: true
			} );

			this.listenTo( button, 'execute', () => {
				const selection = editor.model.document.selection;
				const selectedText = getSelectedText( selection );

				editor.execute( 'texteatrou', { value: selectedText } );
				editor.editing.view.focus();
			} );

			return button;
		} );
	}
}
