import axiosClient from './utils/axiosClient';

export const QUESTION_MODERATION_URI = 'moderation/question';


export function postQuestionModerationApi(questionModeration) {
    return axiosClient.post(QUESTION_MODERATION_URI, questionModeration);
}

export function deleteQuestionModerationApi(questionModeration) {
    return axiosClient.delete(QUESTION_MODERATION_URI, {
        data: questionModeration
    });
}
