import React from 'react';
import PropTypes from 'prop-types';

import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    score: {
        color: 'inherit',
        alignItems: 'center',
        display: 'flex'
    },
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    circleBottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    circleTop: {
        position: 'absolute',
        left: 0
    }
}));

const getColor = (value) => {
    if(value < 50) {
        return 'red';
    } else if(value < 65) {
        return 'darkorange';
    }
    return 'green';
};

function CircularProgressWithLabel(props) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress
                className={classes.circleBottom}
                size={props.size}
                variant="determinate"
                value={100}
            />
            <CircularProgress
                className={classes.circleTop}
                style={{ color: getColor(props.value) }}
                size={props.size}
                variant="determinate"
                value={props.value}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    className={classes.score}
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    style={{ fontSize: props.fontSize }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </div>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    fontSize: PropTypes.string.isRequired
};

export default CircularProgressWithLabel;
