import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QuestionContainer } from '../QuestionResolverWithCourse/QuestionContainer/QuestionContainer';
import { getQuestionByIdApi } from 'app/api/multipleChoiceQuestionApi';

function QuestionDetailRequestByQuestionId(props) {

    const [questionToDisplay, setQuestionToDisplay] = useState(null);

    useEffect(() => {
        getQuestionByIdApi(props.questionId).then((response) => {
            const question = response.data;
            question.answers.forEach((answer) => {
                if(answer.validAnswer) {
                    answer.formControlStyle = answer.validAnswer;
                }
            });
            setQuestionToDisplay(question);
        });
    }, [props.questionId]);

    return (
        <>
            {questionToDisplay &&
                <QuestionContainer
                    questionAsked={questionToDisplay}
                    disableAnswers
                    hideReturnButton
                    disableCourse
                    isInHistory
                    imagesWithAnswersSelected={props.imagesWithAnswersSelected}
                />
            }
        </>
    );
}

QuestionDetailRequestByQuestionId.propTypes = {
    questionId: PropTypes.number,
    imagesWithAnswersSelected: PropTypes.array
};

export default QuestionDetailRequestByQuestionId;
