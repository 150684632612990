import React from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';

import './ResolveSerieDialog.scss';

const useStyles = makeStyles(() => ({
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
}));

function EndSerieConfirmationDialog(props) {

    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                <span className="dialog-title">
                    Quitter la série en cours
                </span>
            </DialogTitle>

            <DialogContent className={classes.content}>
                Vous êtes sur le point de quitter la série en cours.
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={props.handleEndSerieCancelClick}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={props.handleEndSerieConfirmClick}
                >
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EndSerieConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleEndSerieCancelClick: PropTypes.func.isRequired,
    handleEndSerieConfirmClick: PropTypes.func.isRequired
};

export default EndSerieConfirmationDialog;
