
export const getResultColor = (result) => {
    const res = Number(result);
    if(res < 50) {
        return 'red';
    } else if(res < 65) {
        return 'darkorange';
    }
    return 'green';
};
