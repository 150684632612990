export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';
export const GET_NOTIFICATIONS_BY_DESTINATAIRE_SUCCESS = 'GET_NOTIFICATIONS_BY_DESTINATAIRE_SUCCESS';
export const DELETE_NOTIFICATION_BY_ID_SUCCESS = 'DELETE_NOTIFICATION_BY_ID_SUCCESS';
export const DELETE_ALL_NOTIFICATION_BY_USER_ID_SUCCESS = 'DELETE_ALL_NOTIFICATION_BY_USER_ID_SUCCESS';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';

export const notificationsByDestinataireStore = 'notificationsByDestinataire';

export default function notificationsReducer(state = {
    [notificationsByDestinataireStore]: []
}, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_BY_DESTINATAIRE_SUCCESS:
            return {
                ...state,
                [notificationsByDestinataireStore]: action.payload
            };
        case DELETE_NOTIFICATION_BY_ID_SUCCESS:
            return {
                ...state,
                [notificationsByDestinataireStore]: state[notificationsByDestinataireStore]
                    .filter((notification) => notification.id !== action.payload)
            };
        case DELETE_ALL_NOTIFICATION_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [notificationsByDestinataireStore]: state[notificationsByDestinataireStore]
                    .filter((notification) => notification.destinataire.id !== action.payload)
            };
        case NEW_NOTIFICATION:
            const newNotifications = state[notificationsByDestinataireStore].slice();
            newNotifications.push(action.payload);
            return {
                ...state,
                [notificationsByDestinataireStore]: newNotifications
            };
        default:
            return state;
    }
}
