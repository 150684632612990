import PropTypes from 'prop-types';
import  React, { useEffect, useState } from 'react';

import CircularProgressWithLabel from 'app/components/ProgressBar/CircularProgressWithLabel';
import { Link } from 'react-router-dom';
import './ResolveSerieDialog.scss';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles
} from '@material-ui/core';

import SimplePagination from 'app/components/Pagination/SimplePagination';
import {
    GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID,
    getQuestionsStatDisplayBySerieHistoryId,
    setQuestionsStatForCurrentDaily
} from 'app/redux/actions/Question/History/history.actions';
import { selectInitialQuestionList, selectQuestionStatList } from 'app/redux/selectors/Question/history.selector';
import { useDispatch, useSelector } from 'react-redux';
import { calculateUserScores, getPodiumPosition } from './SerieScore.function';
import QuestionsResult from './QuestionsResult';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createLoadingSelector } from 'app/redux/selectors/APISelector/APISelector';

const useStyles = makeStyles(() => ({
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    leaderUserScore: {
        fontSize: '32px',
        columnGap: '4px'
    },
    userScore: {
        fontSize: '16px',
        columnGap: '8px',
        maxWidth: '100px'
    },
    leaderBoard: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px'
    },
    outLeaderBoard: {
        maxHeight: '480px',
        rowGap: '8px'
    },
    scoreContent: {
        rowGap: '8px'
    },
    medal: {
        fontSize: '68px'
    },
    leaderBoardUserScorePercentage: {
        padding: '0 12px'
    },
    userScorePercentage: {
        padding: 0
    }
}));

const imageStyle = {
    borderRadius: '50%',
    height: '80px',
    width: '80px'
};

const smallImageStyle = {
    borderRadius: '50%',
    height: '40px',
    width: '40px'
};

function SerieScore(props) {

    const ITEMS_PER_PAGE = 20;

    const classes = useStyles();
    const dispatch = useDispatch();

    const questionsStatList = useSelector(selectQuestionStatList);
    const initialQuestionList = useSelector(selectInitialQuestionList);
    const isFetchingQuestionStats = useSelector(createLoadingSelector([GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID]));

    const [userScores, setUserScores] = useState([]);
    const [paginatedUserScores, setPaginatedUserScores] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [nbPages, setNbPages] = useState(1);

    const fetchQuestionsStatsDisplayBySerieHistoryId = () => {
        if(!props.candidateUuid && props.serieHistoryId) {
            dispatch(getQuestionsStatDisplayBySerieHistoryId(props.serieHistoryId));
        }
    };

    useEffect(() => {
        fetchQuestionsStatsDisplayBySerieHistoryId();
    }, [props.serieHistoryId]);

    useEffect(() => {
        if(!props.candidateUuid && props.type === 'DAILY') {
            dispatch(setQuestionsStatForCurrentDaily(initialQuestionList));
        }
    }, [props.type]);

    useEffect(() => {
        if(props.quizUuidFromLeader) {
            const userScoresPodium = calculateUserScores(props.quizResults, props.usersInRoom, props.nbTotalQuestions);
            userScoresPodium.map((elem) => {
                elem.medal = getPodiumPosition(elem.userId, userScoresPodium);
                return elem;
            });

            const outOfLeaderBoardItems = userScoresPodium.filter((user) => user.medal === '').length;
            setNbPages(Math.ceil(1 + outOfLeaderBoardItems / ITEMS_PER_PAGE));
            setUserScores(userScoresPodium);
        }
    }, [props.quizResults]);

    useEffect(() => {
        let paginatedUserScoresComputed = [];
        if(currentPage === 1) {
            paginatedUserScoresComputed = userScores.filter((score) => score.medal !== '');
        } else {
            const filteredUserScoresNoMedal = userScores.filter((score) => score.medal === '');
            paginatedUserScoresComputed = filteredUserScoresNoMedal.slice(
                (currentPage - 2) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE
            );
        }
        setPaginatedUserScores(paginatedUserScoresComputed);

    }, [currentPage, userScores]);

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
        >
            {props.quizUuidFromLeader
                ? <>
                    <DialogTitle>
                        <span className="result-title" data-testid="result-title-id">
                            Les scores
                        </span>
                    </DialogTitle>
                    <DialogContent className={classes.content} dividers>
                        <Grid container direction="column" alignItems="center"
                            className={classes.scoreContent}
                        >
                            { currentPage === 1
                                ? <Grid container justifyContent="center" item className={classes.leaderBoard}>
                                    {paginatedUserScores.map((user) => (
                                        <Grid item
                                            key={user.userId}
                                            className={classes.leaderUserScore}
                                            container justifyContent="center" alignItems="center"
                                        >
                                            <Grid item className={classes.medal}>
                                                <span>{user.medal}</span>
                                            </Grid>
                                            <Grid item>
                                                <img src={user.imageUrl} alt={`User ${user.userId}`}
                                                    style={imageStyle}
                                                />
                                            </Grid>
                                            <Grid item className={classes.leaderBoardUserScorePercentage}>
                                                <span>{`${user.score}%`}</span>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                                : <Grid item className={classes.outLeaderBoard}
                                    container direction="column" wrap="wrap"
                                >
                                    {paginatedUserScores.map((user) => (
                                        <Grid item
                                            key={user.userId}
                                            className={classes.userScore}
                                            container alignItems="center"
                                        >
                                            <Grid item>
                                                <span>•</span>
                                            </Grid>
                                            <Grid item>
                                                <img src={user.imageUrl} alt={`User ${user.userId}`}
                                                    style={smallImageStyle}
                                                />
                                            </Grid>
                                            <Grid item className={classes.userScorePercentage}>
                                                <span>{`${user.score}%`}</span>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                            {nbPages !== 1 &&
                                <SimplePagination
                                    page={currentPage}
                                    nbPages={nbPages}
                                    setPage={setCurrentPage}
                                    nextPageMessage="Résultats suivants"
                                    previousPageMessage="Résultats précédents"
                                />
                            }
                        </Grid>
                    </DialogContent>
                </>
                : <>
                    <DialogTitle>
                        <span className="result-title">
                            Votre score
                        </span>
                    </DialogTitle>
                    <DialogContent className={classes.content} dividers>
                        {isFetchingQuestionStats
                            ? <CircularProgress/> 
                            : <>
                                <CircularProgressWithLabel
                                    value={props.averageScore / props.nbTotalQuestions * 100}
                                    size={100}
                                    fontSize='1.2em'
                                />
                                {questionsStatList?.length > 0 && props.quizResults?.length === 0 &&
                                    <QuestionsResult
                                        questionsStatList={questionsStatList}/>
                                }
                            </>
                        }
                    </DialogContent>
                </>
            }

            <DialogActions>
                <Link to={props.redirect}>
                    <Button color="primary">
                        Quitter
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
}

SerieScore.propTypes = {
    averageScore: PropTypes.number,
    candidateUuid: PropTypes.string,
    nbTotalQuestions: PropTypes.number,
    quizResults: PropTypes.array,
    quizUuidFromLeader: PropTypes.string,
    redirect: PropTypes.string.isRequired,
    serieHistoryId: PropTypes.number,
    type: PropTypes.string,
    usersInRoom: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string
    }))
};

export default SerieScore;
