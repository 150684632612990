
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Checkbox, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectQuestionOrSerieHistoryData } from '../../../redux/selectors/Question/history.selector';
import '../SidePanel.scss';

export default function FilterPanelHistory(props) {

    const [totalNumberOfElements, setTotalNumberOfElements] = useState();

    const questionOrSerieHistoryData = useSelector(selectQuestionOrSerieHistoryData);

    useEffect(() => {
        if(questionOrSerieHistoryData) {
            setTotalNumberOfElements(questionOrSerieHistoryData.totalNumberOfElements);
        }
    }, [questionOrSerieHistoryData]);

    const badAnswerFilterChangeHandler = () => {
        props.setBadAnswerFilter(!props.badAnswerFilter);
    };

    return (
        <Grid container direction="row" spacing={1} alignItems="center" className="side-panel top" data-testid="filter-panel-history">
            <Grid item >
                <Typography className="sub-title-numbers"> {totalNumberOfElements} Réponses</Typography>
            </Grid>
            <Grid item >
                <div className="checkbox-container">
                    <Checkbox
                        color="default"
                        id="badAnswerCheckbox"
                        className="checkbox"
                        checked={props.badAnswerFilter}
                        onChange={badAnswerFilterChangeHandler}
                        data-testid="bad-answer-checkbox"
                    />
                    <span>Erreurs uniquement</span>
                </div>

            </Grid>
        </Grid>
    );
}

FilterPanelHistory.propTypes = {
    badAnswerFilter: PropTypes.bool,
    setBadAnswerFilter: PropTypes.func
};
