import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import PlayChapterQuestionsDisplay from './PlayChapterQuestionsDisplay';
import { selectReducedChapters } from 'app/redux/selectors/Chapter/chapter.selector';
import { getQuestionsCountByChapterIdApi } from 'app/api/multipleChoiceQuestionApi';

function PlayChapterQuestionsRequest(props) {

    const { data: chapters } = useSelector(selectReducedChapters);
    const [nbMaxQuestion, setNbMaxQuestion] = useState(0);

    useEffect(() => {
        const getChapter = () => chapters.find((chapter1) => chapter1.id === props.chapterId);
        const chapter = getChapter();
        if(!chapter) {
            return;
        }

        getQuestionsCountByChapterIdApi(chapter.id, props.withChildren, props.language).then((response) => {
            setNbMaxQuestion(response.data);
        });
    }, [props.withChildren, props.language, props.anchorEl]);

    return (
        <PlayChapterQuestionsDisplay
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            onValidate={props.onValidate}
            onValidateInteractiveQuiz={props.onValidateInteractiveQuiz}
            nbQuestions={props.nbQuestions}
            chapterId={props.chapterId}
            onChangeNbQuestions={props.onChangeNbQuestions}
            withChildren={props.withChildren}
            onChangeWithChildren={props.onChangeWithChildren}
            sameQuestions={props.sameQuestions}
            setSameQuestions={props.setSameQuestions}
            isRandomSerie={props.isRandomSerie}
            setIsRandomSerie={props.setIsRandomSerie}
            nbMaxQuestions={nbMaxQuestion}
            language={props.language}
            onChangeLanguage={props.onChangeLanguage}
            isExamMode={props.isExamMode}
            onSetExamMode={props.onSetExamMode}
            quizUuid={props.quizUuid}
            maxDuration={props.maxDuration}
            onChangeMaxDuration={props.onChangeMaxDuration}
        />
    );
}

PlayChapterQuestionsRequest.propTypes = {
    headerTitle: PropTypes.string,
    anchorEl: PropTypes.object,
    chapterId: PropTypes.number.isRequired,
    nbQuestions: PropTypes.string.isRequired,
    withChildren: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValidateInteractiveQuiz: PropTypes.func,
    onChangeNbQuestions: PropTypes.func.isRequired,
    onChangeWithChildren: PropTypes.func.isRequired,
    sameQuestions: PropTypes.bool,
    setSameQuestions: PropTypes.func,
    isRandomSerie: PropTypes.bool,
    setIsRandomSerie: PropTypes.func,
    language: PropTypes.string,
    onChangeLanguage: PropTypes.func.isRequired,
    isExamMode: PropTypes.bool,
    onSetExamMode: PropTypes.func,
    quizUuid: PropTypes.string,
    maxDuration: PropTypes.string,
    onChangeMaxDuration: PropTypes.func
};

export default PlayChapterQuestionsRequest;
