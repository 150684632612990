import {
    GET_BOOKMARK_BY_USER_AND_CHAPTER_SUCCESS,
    POST_BOOKMARK_SUCCESS,
    PUT_BOOKMARK_SUCCESS,
    DELETE_BOOKMARK_BY_ID_SUCCESS
} from 'app/redux/actions/Bookmark/bookmark.actions';

export const bookmarks = 'bookmarks';

export default function bookmarkReducer(state = {
    bookmarks: null
}, action) {
    switch (action.type) {
        case GET_BOOKMARK_BY_USER_AND_CHAPTER_SUCCESS:
            if(action.payload) {
                return {
                    ...state,
                    bookmarks: action.payload
                };
            }
            return {
                ...state
            };

        case POST_BOOKMARK_SUCCESS:
            return {
                ...state,
                bookmarks: action.payload
            };
        case PUT_BOOKMARK_SUCCESS:
            return {
                ...state,
                bookmarks: action.payload
            };
        case DELETE_BOOKMARK_BY_ID_SUCCESS:
            return {
                ...state,
                bookmarks: null
            };

        default:
            return state;
    }
}

