import React from 'react';
import PropTypes from 'prop-types';
import './ChapterTreeDisplay.scss';

import { TreeItem } from '@material-ui/lab';

import { useStyles } from './ChapterTreeDisplay.functions';
import { TreeItemLabel } from './TreeItemLabel';

export default function TreeItemPreview(props) {

    const classes = useStyles();

    return (
        <TreeItem
            classes={{
                label: classes.label,
                iconContainer: classes.iconContainer
            }}
            style={{
                textDecoration: 'none',
                color: 'green'
            }}
            id={`Chapitre ${props.chapter.id}`}
            key={props.chapter.id}
            nodeId={`preview-${props.chapter.id}`}
            data-testid={`chapter-${props.chapter.id}-preview`}
            label={
                <TreeItemLabel
                    chapter={props.chapter}
                    chapterValidationState={props.validationState}
                    depth={props.depth}
                    userId={props.userId}
                    skillId={props.skillId}
                    isBookmarked={props.isBookmarked}
                    bookmark={props.bookmark}
                />
            }
        />
    );
}

TreeItemPreview.propTypes = {
    chapter: PropTypes.object.isRequired,
    skillId: PropTypes.number.isRequired,
    depth: PropTypes.number,
    validationState: PropTypes.string,
    userId: PropTypes.number.isRequired,
    isBookmarked: PropTypes.bool.isRequired,
    bookmark: PropTypes.object.isRequired
};
