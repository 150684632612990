import { createSelector } from 'reselect';
import { selectDetailedExcilyensSkillsSet } from '../ExcilyenSet/excilyenSet.selector';
import { promoFilterStore, searchExcilyenStore, excilyenSkillFilterStore }
    from '../../../reducers/Excilyen/ExcilyenFilter/excilyensFilters.reducer';
import { arraysIntersection } from '../../../../utils/functions';
import { excilyensFilterStore } from '../../../rootReducer';

export const selectPromos = createSelector(
    [selectDetailedExcilyensSkillsSet],
    (excilyens) => {
        if(excilyens) {
            return [...new Set(excilyens.map((element) => element.promo))].sort()
                .reverse();
        }
        return [];
    }
);

export const selectCheckedPromos = (state) => state[excilyensFilterStore][promoFilterStore];

export const selectExcilyenSearchString = (state) => state[excilyensFilterStore][searchExcilyenStore];

export const selectFilterSkills = (state) => state[excilyensFilterStore][excilyenSkillFilterStore];

export const selectExcilyensFilteredByPromo = createSelector(
    [
        selectCheckedPromos,
        selectDetailedExcilyensSkillsSet
    ],
    (checkedPromos, excilyens) => {
        if(Array.isArray(checkedPromos) && checkedPromos.length) {
            return excilyens.filter((excilyen) => checkedPromos.includes(excilyen.promo));
        }
        return excilyens;
    }
);

export function hasRequiredLevel(excilyen, skillFilters) {
    const requirements = skillFilters
        .map((filteredSkill) => excilyen.skillLevelApiDtos
            .some((excilyenSkill) => excilyenSkill.skillId === filteredSkill.id && excilyenSkill.userLevel >= filteredSkill.level));
    return requirements.every((requirement) => requirement === true);
}

export const selectExcilyensFilteredBySkill = createSelector(
    [
        selectFilterSkills,
        selectDetailedExcilyensSkillsSet
    ],
    (skills, excilyens) => {
        if(Array.isArray(skills) && skills.length) {
            return excilyens.filter((excilyen) => hasRequiredLevel(excilyen, skills));
        }
        return excilyens;
    }
);

export const selectExcilyensFilteredByName = createSelector(
    [
        selectExcilyenSearchString,
        selectDetailedExcilyensSkillsSet
    ],
    (searchString, excilyens) => {
        if(searchString.length < process.env.REACT_APP_MINIMUM_AUTO_SEARCH_LENGTH) {
            return excilyens;
        }
        return excilyens.filter((excilyen) => excilyen.firstName.toLowerCase()
            .includes(searchString.toLowerCase()) || excilyen.lastName.toLowerCase()
            .includes(searchString.toLowerCase()));
    }
);


export const selectExcilyensFilteredBySkillsAndPromos = createSelector(
    [
        selectExcilyensFilteredBySkill,
        selectExcilyensFilteredByPromo
    ],
    arraysIntersection
);

export const selectExcilyensFilteredBySkillsAndPromosAndByName = createSelector(
    [
        selectExcilyensFilteredBySkillsAndPromos,
        selectExcilyensFilteredByName
    ],
    arraysIntersection
);
