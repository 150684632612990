import { rooms } from 'app/redux/reducers/InteractiveQuiz/interactiveQuizRoom.reducer';
import { interactiveQuizRoomStore } from 'app/redux/rootReducer';

export const selectRoomById = (uuid) => (state) => state[interactiveQuizRoomStore][rooms].find((room) => room?.uuid === uuid);

export const selectCurrentQuestionIdRoomByUuid = (uuid) => (state) => state[interactiveQuizRoomStore][rooms].find((room) => room?.uuid === uuid)?.currentQuestionId;

export const selectUsersRoomByUuid = (uuid) => (state) => state[interactiveQuizRoomStore][rooms].find((room) => room?.uuid === uuid)?.users;

export const selectIsQuizStartedRoomByUuid = (uuid) => (state) => state[interactiveQuizRoomStore][rooms].find((room) => room?.uuid === uuid)?.isQuizStarted;

export const selectHostIdRoomByUuid = (uuid) => (state) => state[interactiveQuizRoomStore][rooms].find((room) => room?.uuid === uuid)?.hostId;

export const selectSerieParamsRoomByUuid = (uuid) => (state) => state[interactiveQuizRoomStore][rooms].find((room) => room?.uuid === uuid)?.serieParams;
