import { getChapterValidationBySkillIdAndUserIdApi } from 'app/api/chapterValidationApi';
import { EXPIRED_SESSION, RETRIEVE_CHAPTER_VALIDATIONS_ERROR } from 'app/Snackbar/NotificationMessages';
import { notificationError } from '../Notification/notifications.actions';

export const GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_REQUEST = 'GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_REQUEST';
export const GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_SUCCESS = 'GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_SUCCESS';
export const GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_FAILURE = 'GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_FAILURE';


export const getChapterValidationBySkillIdAndUserId = (skillId, userId) => (dispatch) => {
    dispatch({ type: GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_REQUEST });

    return getChapterValidationBySkillIdAndUserIdApi(skillId, userId).then((response) => {
        dispatch({
            type: GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CHAPTER_VALIDATIONS_BY_SKILL_ID_AND_USER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_CHAPTER_VALIDATIONS_ERROR));
        }
    });
};
