import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../SidePanel.scss';
import SearchQuestion from '../../../common/search/SearchQuestion';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import SidePanelQuestionsFilterContainer from './SidePanelQuestionsFilterContainer';
import { setQuestionsChaptersFilter, setQuestionsCreatorsFilter, setQuestionsSkillsFilter,
    setQuestionsIdFilter, setQuestionsAiGeneratedFilter } from '../../../redux/actions/Question/QuestionsFilters/questionsFilters.actions';
import { selectQuestionsReducedPage } from '../../../redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import SidePanelQuestionsAiGeneratedFilter from './SidePanelQuestionsAiGeneratedFilter';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';

export class SidePanelQuestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resetNameFilter: false,
            resetIdFilter: false,
            resetAiGeneratedFilter: false
        };
    }
    
    resetSearchFieldId = () => {
        this.setState({ resetIdFilter: false });
    };

    resetSearchFieldName = () => {
        this.setState({ resetNameFilter: false });
    };

    handleClickResetFilters = () => {
        this.setState({ resetNameFilter: true });
        this.setState({ resetIdFilter: true });
        this.setState({ resetAiGeneratedFilter: true });
        this.props.resetFilters();
    };

    render() {
        const { number } = this.props;

        return (
            <Grid container spacing={1} className="side-panel" data-testid="side-panel-question">
                <Grid item xs={12}>
                    <Typography className="sub-title-numbers-question"> {number ? number : 0} Questions</Typography>
                    <span className="title">Filtres</span>
                </Grid>
                <Grid item xs={12}>
                    <span className="reset-filters-button" onClick={this.handleClickResetFilters} data-testid="side-panel-question-reset">
                    Réinitialiser les filtres
                    </span>
                </Grid>
                <div className="filters-question">
                    <Grid item xs={12}>
                        <Typography className="sub-title-question">
                            Id
                        </Typography>
                        <SearchQuestion
                            resetNameFilter={this.state.resetIdFilter}
                            resetSearchField={this.resetSearchFieldId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelQuestionsFilterContainer skill/>
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelQuestionsFilterContainer user/>
                    </Grid>
                    <Grid item xs={12}>
                        <SidePanelQuestionsFilterContainer chapter/>
                    </Grid>
                    {this.props.currentUser?.roles?.find((role) => role.name === 'ADMIN') && 
                        <Grid>
                            <SidePanelQuestionsAiGeneratedFilter />
                        </Grid>
                    }
                </div>
            </Grid>
        );
    }
}

SidePanelQuestions.propTypes = {
    resetFilters: PropTypes.func,
    number: PropTypes.number,
    setQuestionIdFilter: PropTypes.func,
    currentUser: PropTypes.object,
    setQuestionsAiGeneratedFilter: PropTypes.func
};

function mapStateToProps(state) {
    return {
        number: selectQuestionsReducedPage(state) && selectQuestionsReducedPage(state).totalNumberOfElements,
        currentUser: selectCurrentUser(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        resetFilters: () => {
            dispatch(setQuestionsIdFilter(''));
            dispatch(setQuestionsSkillsFilter([]));
            dispatch(setQuestionsCreatorsFilter([]));
            dispatch(setQuestionsChaptersFilter([]));
            dispatch(setQuestionsAiGeneratedFilter([]));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelQuestions);
