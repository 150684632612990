import { ADD_ROOM,
    ADD_PARTICIPANT,
    SET_CURRENT_QUESTION,
    REMOVE_PARTICIPANT,
    SET_QUIZ_STARTED,
    RESET_ROOMS } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';

export const rooms = 'rooms';

const initialState = {
    [rooms]: []
};

export default function interactiveQuizRoomReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_ROOM:
            return {
                ...state,
                rooms: [...state.rooms, action.roomInfos]
            };
        case ADD_PARTICIPANT: {
            const updateRooms = state.rooms.map((room) => {
                if(room.uuid === action.participantInfos.uuid) {
                    const users = room.users ? [...room.users] : [];
                    users.push({ id: action.participantInfos.id, imageUrl: action.participantInfos.imageUrl,
                        firstName: action.participantInfos.firstName, lastName: action.participantInfos.lastName });
                    return {
                        ...room,
                        users
                    };
                }
                return room;
            });
            return {
                ...state,
                rooms: updateRooms
            };
        }
        case REMOVE_PARTICIPANT: {
            const updateRooms = state.rooms.map((room) => {
                if(room.uuid === action.participantInfos.uuid) {
                    let users = room.users ? [...room.users] : [];
                    users = users.filter((user) => user.id !== action.participantInfos.id);
                    return {
                        ...room,
                        users
                    };
                }
                return room;
            });
            return {
                ...state,
                rooms: updateRooms
            };
        }

        case SET_CURRENT_QUESTION: {
            const updateRooms = state.rooms.map((room) => {
                if(room.uuid === action.currentQuestionInfos.uuid) {
                    return {
                        ...room,
                        currentQuestionId: Number(action.currentQuestionInfos.questionId)
                    };
                }
                return room;
            });
            return {
                ...state,
                rooms: updateRooms
            };
        }

        case SET_QUIZ_STARTED: {
            const updateRooms = state.rooms.map((room) => {
                if(room.uuid === action.currentQuestionInfos.uuid) {
                    return {
                        ...room,
                        currentQuestionId: Number(action.currentQuestionInfos.questionId),
                        isQuizStarted: true
                    };
                }
                return room;
            });
            return {
                ...state,
                rooms: updateRooms
            };
        }

        case RESET_ROOMS: {
            return {
                rooms: []
            };
        }

        default:
            return state;
    }
}
