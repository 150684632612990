import { questionsFiltersStore } from '../../../rootReducer';
import { questionsSkillsFilterStore, questionsCreatorsFilterStore, questionsChaptersFilterStore,
    questionsIdFilterStore, questionsAiGeneratedFilterStore } from '../../../reducers/Question/QuestionsFilters/questionsFilters.reducer';

export const selectQuestionsSkillsFilter = (state) => state[questionsFiltersStore][questionsSkillsFilterStore];

export const selectQuestionsCreatorsFilter = (state) => state[questionsFiltersStore][questionsCreatorsFilterStore];

export const selectQuestionsChaptersFilter = (state) => state[questionsFiltersStore][questionsChaptersFilterStore];

export const selectQuestionsIdFilter = (state) => state[questionsFiltersStore][questionsIdFilterStore];

export const selectQuestionsAiGeneratedFilter = (state) => state[questionsFiltersStore][questionsAiGeneratedFilterStore];
