import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HomeContent from './HomeContent';
import { withRouter } from 'react-router-dom';

export class Home extends Component {

    render() {
        return (
            <div data-testid="home">
                <HomeContent/>
            </div>
        );
    }
}

Home.propTypes = {
    match: PropTypes.object
};

export default withRouter(Home);
