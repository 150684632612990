import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import { Autocomplete, TreeItem } from '@material-ui/lab';
import ParcoursStepTreeItem from './ParcoursStepTreeItem';
import { AssignTypes } from 'app/utils/functions';
import { getParcoursByIdApi, getStepByParcoursIdApi } from 'app/api/parcoursApi';
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, GET_PARCOURS_BY_ID_ERROR } from 'app/Snackbar/NotificationMessages';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
import ShareIcon from '@material-ui/icons/Share';
import { deleteParcours, deleteShareEditionWithUser, shareEditionWithUsers } from 'app/redux/actions/Parcours/parcours.actions';
import { getUsersNotEditorsForParcoursApi } from 'app/api/userApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { ParcoursTypeEnum } from 'app/utils/ParcoursTypeEnum';

function ParcoursTreeItem(props) {

    const dispatch = useDispatch();

    const userId = AuthenticationContainer.getCurrentUserId();

    const [stepContent, setStepContent] = useState(new Map());
    const [isLoading, setIsLoading] = useState(false);
    const [steps, setSteps] = useState();
    const [currentStep, setCurrentStep] = useState({});
    const [currentStepContent, setCurrentStepContent] = useState({});
    const [needRefresh, setNeedRefresh] = useState(props.parcours?.needRefresh);

    const [menuState, setMenuState] = useState({ anchorEl: null, openMenuParcoursId: null });
    const open = Boolean(menuState.anchorEl);

    const [userOptions, setUserOptions] = useState([]);
    const [openShare, setOpenShare] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleUserSelectChange = (event, newValue) => {
        setSelectedUsers(newValue);
    };

    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.PARCOURS,
        item: {
            type: AssignTypes.PARCOURS,
            parcours: props.parcours,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            dragItem: monitor.getItem()
        })
    }), [props.parcours]);

    const handleClick = () => {
        setIsLoading(true);
        getStepByParcoursIdApi(props.parcours.id).then((response) => {
            const sortedSteps = response.data.sort((prevStep, nextStep) => prevStep.position - nextStep.position);
            setSteps(sortedSteps);
            props.setParcoursClickListById(props.parcours.id, true);
            setIsLoading(false);},
        (error) => {
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(GET_PARCOURS_BY_ID_ERROR));
            }
        });
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setMenuState({ anchorEl: event.currentTarget, openMenuParcoursId: props.parcours.id });
    };

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setMenuState({ anchorEl: null, openMenuParcoursId: null });
    };

    const handleShare = (event, state) => {
        event.stopPropagation();
        if(state) {
            getUsersNotEditorsForParcoursApi(props.parcours.id).then(response => {
                setUserOptions(response.data);
            });
        } else {
            setSelectedUsers([]);
        }
        setOpenShare(state);
    };

    const handleEdit = (event) => {
        handleMenuClose(event);
        const parcoursDisplay = props.parcours;
        getParcoursByIdApi(parcoursDisplay.id).then((response) => {
            const parcours = response.data;
            const parcoursToEdit = {
                ...parcours,
                parcoursSteps: parcours.parcoursSteps?.map((step) => ({
                    ...step,
                    parcoursStepContents: step.parcoursStepContents?.map((content) => ({
                        ...content,
                        name: (content.type).toLowerCase() === AssignTypes.QUESTION ? `${content.skillName}#${content.name}` : content.name,
                        type: (content.type).toLowerCase()
                    })),
                })),
            };
            props.setParcoursForEdit(parcoursToEdit);
        });
    };

    const handleDuplicateParcours = (event) => {
        handleMenuClose(event);
        const parcoursDisplay = props.parcours;
        getParcoursByIdApi(parcoursDisplay.id).then((response) => {
            const parcours = response.data;
            const newParcours = {
                ...parcours,
                id: null,
                name: `${parcours.name}(copie)`,
                parcoursSteps: parcours.parcoursSteps?.map((step) => ({
                    ...step,
                    id: null,
                    parcoursStepContents: step.parcoursStepContents?.map((content) => ({
                        ...content,
                        id: null,
                        name: (content.type).toLowerCase() === AssignTypes.QUESTION ? `${content.skillName}#${content.name}` : content.name,
                        type: (content.type).toLowerCase()
                    })),
                })),
            };
            props.setParcoursForEdit(newParcours);
        });
    };

    const handleDeleteParcours = (event) => {
        dispatch(deleteParcours(props.parcours.id));
        handleMenuClose(event);
    };

    const handleDeleteShareEditionWithUser = (event) => {
        const editor = { userId, parcoursId: props.parcours.id };
        dispatch(deleteShareEditionWithUser(editor));
        handleMenuClose(event);
    };

    const handleDelete = (event) => {
        if(props.parcoursType === ParcoursTypeEnum.SHARED) {
            handleDeleteShareEditionWithUser(event);
        } else {
            handleDeleteParcours(event);
        }
    };

    const shareParcours = () => {
        const editors = selectedUsers.map(selectedUser => ({
            userId: selectedUser.id,
            parcoursId: props.parcours.id
        }));
        dispatch(shareEditionWithUsers(editors, props.parcours));
        setOpenShare(false);
        setSelectedUsers([]);
        setMenuState({ anchorEl: null, openMenuParcoursId: null });
    };

    const displayStatus = () => {
        const currentStatus = props.statusParcours;
        const progressStatus = (currentStatus?.stepContent / currentStatus?.nbStepContent) * 100;
        return <div>{Math.round(progressStatus) || 0}%</div>;
    };

    const displayMenu = () => (<>
        <IconButton
            color="primary"
            aria-label="more"
            onClick={(evt) => handleMenuClick(evt)}
        >
            <MoreHorizIcon />
        </IconButton>
        {menuState.openMenuParcoursId === props.parcours.id && (
            <Menu
                id="simple-menu"
                anchorEl={menuState.anchorEl}
                open={open}
                onClose={(event) => handleMenuClose(event)}
            >
                <MenuItem onClick={(event) => handleEdit(event)}>
                    <CreateIcon
                        color="primary"
                        aria-label="edit"
                        style={{ marginRight: '10px' }} />
                        Editer
                </MenuItem>
                <MenuItem onClick={(event) => handleDuplicateParcours(event)}>
                    <FileCopyIcon
                        color="primary"
                        aria-label="duplicate"
                        style={{ marginRight: '10px' }} />
                        Dupliquer
                </MenuItem>
                { props.parcoursType === ParcoursTypeEnum.CREATED && <MenuItem onClick={(event) => handleShare(event, true)}>
                    <ShareIcon
                        color="primary"
                        aria-label="share"
                        style={{ marginRight: '10px' }}
                    />
                        Partager
                </MenuItem> }
                <MenuItem onClick={(event) => handleDelete(event)}>
                    <CloseIcon
                        color="primary"
                        aria-label="delete"
                        style={{ marginRight: '10px' }}
                    />
                        Supprimer
                </MenuItem>
            </Menu>
        )}</>
    );

    const parcoursTitleComponent = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '25px', alignItems: 'center' }}>
            <label>{ props.parcours.name }</label>
            { props.parcoursType === ParcoursTypeEnum.CREATED || props.parcoursType === ParcoursTypeEnum.SHARED
                ? displayMenu()
                : props.isSelectedGroup 
                    ? null
                    : displayStatus() 
            }
        </div>
    );

    useEffect (() => {
        if(props.parcoursUpdated && props.parcours.id === props.parcoursUpdated.id) {
            setStepContent(new Map());
        }
    }, [props.parcoursUpdated]);
    
    return (
        <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, width: '100%' }}>
            <Dialog    
                open={openShare}
                onClose={(event) => handleShare(event, false)}
                aria-labelledby="form-share-parcours">
                <DialogContent>
                    <Autocomplete
                        multiple
                        options={userOptions}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                        freeSolo
                        value={selectedUsers}
                        onChange={handleUserSelectChange}
                        renderTags={(value, getTagProps) =>
                            value.map((option, ind) => (
                                <Chip variant="outlined" label={`${option.firstName} ${option.lastName}`} {...getTagProps({ ind })} key={option.id} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Partager avec" placeholder="Ajouter un utilisateur" style={{ minWidth: '400px' }}/>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleShare(event, false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => shareParcours()} color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            <TreeItem
                nodeId={props.parcours.id.toString()}
                label={parcoursTitleComponent()}
                onClick={() => handleClick(props.parcours.id)}
            >
                {isLoading &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={50} thickness={2}/>
                    </div>}
                {steps?.map((step) => (
                    <ParcoursStepTreeItem
                        key={step.id}
                        parcoursId={props.parcours.id}
                        step={step}
                        stepContent={stepContent.get(step.id)}
                        setStepContent={setStepContent}
                        statusParcours={props.statusParcours}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        currentStepContent={currentStepContent}
                        setCurrentStepContent={setCurrentStepContent}
                        parcoursType={props.parcoursType}
                        needRefresh = {needRefresh}
                        setNeedRefresh = {setNeedRefresh}
                    />
                ))}
            </TreeItem>
        </div>
    );
}

ParcoursTreeItem.propTypes = {
    parcours: PropTypes.object.isRequired,
    statusParcours: PropTypes.object,
    selectedStudentId: PropTypes.number,
    parcoursType: PropTypes.string,
    setParcoursForEdit: PropTypes.func,
    isClick: PropTypes.bool,
    setParcoursClickListById: PropTypes.func,
    parcoursUpdated: PropTypes.object,
    isSelectedGroup: PropTypes.bool
};

export default ParcoursTreeItem;
