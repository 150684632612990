import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './Chrono.scss';

function Chrono({
    isRunning,
    maxDuration,
    setMaxDuration
}) {

    const tick = () => {
        setMaxDuration((state) => {
            let newSeconds = state.seconds - 1;
            let newMinutes = state.minutes;
            let newHours = state.hours;
            if(newSeconds < 0) {
                newSeconds = 59;
                newMinutes -= 1;
                if(newMinutes < 0) {
                    newMinutes = 59;
                    newHours -= 1;
                }
            }
            return {
                seconds: newSeconds,
                minutes: newMinutes,
                hours: newHours
            };
        });
    };

    useEffect(() => {
        let timer = null;
        if(isRunning) {
            timer = setInterval(tick, 1000);
        } else {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [isRunning]);

    const zeroPad = (value) => (
        value < 10 ? `0${value}` : value
    );

    return (
        <div className="chrono-serie">
            {`${zeroPad(maxDuration.hours)}:${zeroPad(maxDuration.minutes)}:${zeroPad(maxDuration.seconds)}`}
        </div>
    );
}

Chrono.propTypes = {
    isRunning: PropTypes.bool.isRequired,
    maxDuration: PropTypes.object.isRequired,
    setMaxDuration: PropTypes.func.isRequired
};

export default Chrono;
