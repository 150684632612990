export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';

export default function notificationsReducer(state = [], action) {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return [
                ...state,
                action.params
            ];
        case CLEAR_NOTIFICATION:
            return state.filter((element) => element.key !== action.key);
        case CLEAR_ALL_NOTIFICATIONS:
            return [];
        default:
            return state;
    }
}
