/* eslint-disable complexity */

import {
    RESET_SERIE_HISTORY,
    POST_QUESTION_OR_SERIE_HISTORY_PAGINATED_BY_USER_ID_SUCCESS,
    POST_PARCOURS_HISTORY_PAGINATED_BY_USER_ID_SUCCESS,
    RESET_HISTORY_PAGINATED,
    GET_QUESTION_HISTORY_BY_ID_SUCCESS,
    RESET_QUESTION_HISTORY,
    POST_SERIE_HISTORY_SUCCESS,
    RESET_SERIE_HISTORY_ID,
    RESET_HISTORY_SERIE_GROUPED,
    GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_SUCCESS,
    GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS,
    RESET_QUESTION_HISTORY_BY_QUESTION_ID,
    RESET_DAILY_QUESTION_SERIE,
    SET_DAILY_QUESTION_SERIE,
    SET_QUESTIONS_STAT_FOR_CURRENT_DAILY,
    GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_SUCCESS,
    GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_SUCCESS,
    GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_SUCCESS,
    RESET_SERIE_HISTORY_CANDIDATE,
    POST_HISTORY_PAGINATED_BY_GROUP_ID_SUCCESS,
    POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_SUCCESS,
    POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_SUCCESS,
    GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_SUCCESS,
    GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_SUCCESS
} from '../../actions/Question/History/history.actions';

export const serieHistory = 'serieHistory';
export const parcoursHistory = 'parcoursHistory';
export const questionHistory = 'questionHistory';
export const questionOrSerieHistoryData = 'historyData';
export const parcoursHistoryData = 'parcoursHistoryData';
export const groupHistoryData = 'groupHistoryData';
export const serieHistoryUpdate = 'serieHistoryId';
export const historySameQuestion = 'listHistorySameQuestion';
export const initialQuestionList = 'initialQuestionList';
export const questionStatList = 'questionStatList';
export const historySerieGrouped = 'historySerieGrouped';
export const serieHistoryCandidate = 'serieHistoryCandidate';
export const graphicHistoryData= 'graphicHistoryData';
export const groupGraphicHistoryData= 'groupGraphicHistoryData';

export default function historyReducer(state = {
    [serieHistory]: new Map(),
    [parcoursHistory]: new Map(),
    [questionHistory]: new Map(),
    [questionOrSerieHistoryData]: null,
    [parcoursHistoryData]: null,
    [groupHistoryData]: null,
    [serieHistoryUpdate]: null,
    [historySameQuestion]: [],
    [initialQuestionList]: [],
    [questionStatList]: [],
    [historySerieGrouped]: new Map(),
    [serieHistoryCandidate]: null,
    [graphicHistoryData]: null,
    [groupGraphicHistoryData]: null
}, action) {
    switch (action.type) {
        case GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_SUCCESS:
            const mapSerieHistory = new Map(state[serieHistory]);
            mapSerieHistory.set(action.payload.id, action.payload.data);
            return {
                ...state,

                [serieHistory]: mapSerieHistory
            };
        case GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_SUCCESS:
            const mapParcoursHistory = new Map(state[parcoursHistory]);
            const data = action.payload.data;
            data.forEach(parcoursStep => {
                parcoursStep.listQuestions = [];
            });
            mapParcoursHistory.set(action.payload.id, data);


            return {
                ...state,
                [parcoursHistory]: mapParcoursHistory
            };
        case GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_SUCCESS:

            const mapParcoursHistoryWithListQuestions = new Map(state[parcoursHistory]);

            mapParcoursHistoryWithListQuestions.get(action.payload.parcoursId).find(parcoursSteps => parcoursSteps.id === action.payload.parcoursStepId).listQuestions = action.payload.data;

            return {
                ...state,
                [parcoursHistory]: mapParcoursHistoryWithListQuestions
            };
        case RESET_SERIE_HISTORY:
            return {
                ...state,
                [serieHistory]: new Map()
            };
        case GET_QUESTION_HISTORY_BY_ID_SUCCESS:
            const mapQuestionHistory = new Map(state[questionHistory]);
            mapQuestionHistory.set(action.payload.id, action.payload);
            return {
                ...state,
                [questionHistory]: mapQuestionHistory
            };
        case RESET_QUESTION_HISTORY:
            return {
                ...state,
                [questionHistory]: new Map()
            };
        case POST_QUESTION_OR_SERIE_HISTORY_PAGINATED_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [questionOrSerieHistoryData]: action.payload
            };
        case POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [graphicHistoryData]: action.payload };

        case POST_PARCOURS_HISTORY_PAGINATED_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [parcoursHistoryData]: action.payload
            };
        case POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_SUCCESS:
            return {
                ...state,
                [groupGraphicHistoryData]: action.payload
            };
        case RESET_HISTORY_PAGINATED:
            return {
                ...state,
                [questionOrSerieHistoryData]: null
            };
        case POST_HISTORY_PAGINATED_BY_GROUP_ID_SUCCESS:
            return {
                ...state,
                [groupHistoryData]: action.payload
            };
        case POST_SERIE_HISTORY_SUCCESS:
            return {
                ...state,
                [serieHistoryUpdate]: action.payload
            };
        case RESET_SERIE_HISTORY_ID:
            return {
                ...state,
                [serieHistoryUpdate]: null
            };
        case GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS:
            return {
                ...state,
                [historySameQuestion]: action.payload
            };
        case RESET_QUESTION_HISTORY_BY_QUESTION_ID:
            return {
                ...state,
                [historySameQuestion]: []
            };
        case RESET_DAILY_QUESTION_SERIE:
            return {
                ...state,
                [initialQuestionList]: []
            };
        case SET_DAILY_QUESTION_SERIE:
            return {
                ...state,
                [initialQuestionList]: action.payload
            };
        case SET_QUESTIONS_STAT_FOR_CURRENT_DAILY:
            return {
                ...state,
                [questionStatList]: action.payload
            };
        case GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_SUCCESS:
            return {
                ...state,
                [questionStatList]: action.payload
            };
        case GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_SUCCESS:
            const mapSerieHistoryGroup = new Map(state[historySerieGrouped]);
            mapSerieHistoryGroup.set(action.payload.id, action.payload.data);
            return {
                ...state,
                [historySerieGrouped]: mapSerieHistoryGroup
            };
        case RESET_HISTORY_SERIE_GROUPED:
            return {
                ...state,
                [historySerieGrouped]: new Map()
            };
        case GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_SUCCESS:
            return {
                ...state,
                [serieHistoryCandidate]: action.payload
            };
        case RESET_SERIE_HISTORY_CANDIDATE:
            return {
                ...state,
                [serieHistoryCandidate]: null
            };
        default:
            return state;
    }
}
