import {
    serieHistory,
    parcoursHistory,
    questionHistory,
    questionOrSerieHistoryData,
    parcoursHistoryData,
    groupHistoryData,
    serieHistoryUpdate,
    historySameQuestion,
    questionStatList,
    initialQuestionList,
    historySerieGrouped,
    serieHistoryCandidate,
    graphicHistoryData,
    groupGraphicHistoryData
} from '../../reducers/Question/history.reducer';
import { historyStore } from '../../rootReducer';

export function selectSerieHistory(state) {
    return state[historyStore][serieHistory];
}

export function selectParcoursHistory(state) {
    return state[historyStore][parcoursHistory];
}


export function selectQuestionHistory(state) {
    return state[historyStore][questionHistory];
}

export function selectQuestionOrSerieHistoryData(state) {
    return state[historyStore][questionOrSerieHistoryData];
}

export function selectGraphicHistoryData(state) {
    return state[historyStore][graphicHistoryData];
}
export function selectParcoursHistoryData(state) {
    return state[historyStore][parcoursHistoryData];
}

export function selectGroupGraphicHistoryData(state) {
    return state[historyStore][groupGraphicHistoryData];
}

export function selectGroupHistoryData(state) {
    return state[historyStore][groupHistoryData];
}

export function selectSerieHistoryUpdate(state) {
    return state[historyStore][serieHistoryUpdate];
}

export function selectHistorySameQuestion(state) {
    return state[historyStore][historySameQuestion];
}

export function selectQuestionStatList(state) {
    return state[historyStore][questionStatList];
}

export function selectInitialQuestionList(state) {
    return state[historyStore][initialQuestionList];
}

export function selectHistorySerieGrouped(state) {
    return state[historyStore][historySerieGrouped];
}

export function selectSerieHistoryCandidate(state) {
    return state[historyStore][serieHistoryCandidate];
}
