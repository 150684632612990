import { notificationError, notificationSuccess } from '../../Notification/notifications.actions';
import {
    GET_CHAPTER_RESOURCES_ERROR,
    GET_CHAPTER_RESOURCES_ERROR_404,
    RESOURCE_ADDED,
    EXPIRED_SESSION,
    ADD_RESOURCE_ERROR
} from '../../../../Snackbar/NotificationMessages';
import { getChapterResourcesApi, postResourceApi } from '../../../../api/chapterApi';

export const GET_CHAPTER_RESOURCES_REQUEST = 'GET_CHAPTER_RESOURCES_REQUEST';
export const GET_CHAPTER_RESOURCES_SUCCESS = 'GET_CHAPTER_RESOURCES_SUCCESS';
export const GET_CHAPTER_RESOURCES_FAILURE = 'GET_CHAPTER_RESOURCES_FAILURE';

export const POST_CREATE_RESOURCE_REQUEST = 'POST_CREATE_RESOURCE_REQUEST';
export const POST_CREATE_RESOURCE_SUCCESS = 'POST_CREATE_RESOURCE_SUCCESS';
export const POST_CREATE_RESOURCE_FAILURE = 'POST_CREATE_RESOURCE_FAILURE';

export const getChapterResources = (chapterId) => (dispatch) => {
    dispatch({ type: GET_CHAPTER_RESOURCES_REQUEST });

    return getChapterResourcesApi(chapterId).then((response) => {
        dispatch({
            type: GET_CHAPTER_RESOURCES_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CHAPTER_RESOURCES_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(GET_CHAPTER_RESOURCES_ERROR_404));
        } else {
            dispatch(notificationError(GET_CHAPTER_RESOURCES_ERROR));
        }
    });
};

export const createResource = (resource) => (dispatch) => {
    dispatch({ type: POST_CREATE_RESOURCE_REQUEST });

    return postResourceApi(resource).then((response) => {
        dispatch({
            type: POST_CREATE_RESOURCE_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(RESOURCE_ADDED));
    }, (error) => {
        dispatch({
            type: POST_CREATE_RESOURCE_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(ADD_RESOURCE_ERROR));
        }
    });
};
