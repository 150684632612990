import { getAllSkillsStatsApi, getSkillApi, postSkillApi, putSkillApi } from '../../../../api/skillSetApi';
import { notificationError, notificationSuccess, notificationWarning } from '../../Notification/notifications.actions';
import {
    ADD_SKILL_ERROR,
    EXPIRED_SESSION,
    RETRIEVE_SKILLS_ERROR,
    SKILL_ADDED,
    SKILL_ALREADY_EXIST,
    SKILL_UPDATED,
    UPDATE_SKILL_ERROR,
    UPDATE_SKILL_ERROR_404
} from '../../../../Snackbar/NotificationMessages';
import { getAllFamilies, getAllCategories } from '../../FamilyCategory/familiesCategories.actions';
import { skillActions } from 'app/redux/slices/skills.slice';

export const GET_ALL_SKILLS_STATS = 'GET_ALL_SKILLS_STATS';
export const GET_ALL_SKILLS_STATS_REQUEST = 'GET_ALL_SKILLS_STATS_REQUEST';
export const GET_ALL_SKILLS_STATS_SUCCESS = 'GET_ALL_SKILLS_STATS_SUCCESS';
export const GET_ALL_SKILLS_STATS_FAILURE = 'GET_ALL_SKILLS_STATS_FAILURE';

export const ADD_SKILL = 'ADD_SKILL';
export const ADD_SKILL_REQUEST = 'ADD_SKILL_REQUEST';
export const ADD_SKILL_SUCCESS = 'ADD_SKILL_SUCCESS';
export const ADD_SKILL_FAILURE = 'ADD_SKILL_FAILURE';

export const EDIT_SKILL = 'EDIT_SKILL';
export const EDIT_SKILL_REQUEST = 'EDIT_SKILL_REQUEST';
export const EDIT_SKILL_SUCCESS = 'EDIT_SKILL_SUCCESS';
export const EDIT_SKILL_FAILURE = 'EDIT_SKILL_FAILURE';

export const GET_SKILL = 'GET_SKILL';
export const GET_SKILL_REQUEST = 'GET_SKILL_REQUEST';
export const GET_SKILL_SUCCESS = 'GET_SKILL_SUCCESS';
export const GET_SKILL_FAILURE = 'GET_SKILL_FAILURE';

export const GET_SKILL_BY_ID = 'GET_SKILL_BY_ID';
export const GET_SKILL_BY_ID_REQUEST = 'GET_SKILL_BY_ID_REQUEST';
export const GET_SKILL_BY_ID_SUCCESS = 'GET_SKILL_BY_ID_SUCCESS';
export const GET_SKILL_BY_ID_FAILURE = 'GET_SKILL_BY_ID_FAILURE';

export const SET_FAMILIES_SKILLS_NUMBER = 'SET_FAMILIES_SKILLS_NUMBER';

export const RESET_CURRENT_SKILL = 'RESET_CURRENT_SKILL';

export function requestSkill(requestedSkillName, eraseDataWhileLoading) {
    return {
        type: GET_SKILL_REQUEST,
        payload: { requestedSkillName,
            eraseDataWhileLoading }
    };
}

export const getAllSkillsStats = () => (dispatch) => {
    dispatch({ type: GET_ALL_SKILLS_STATS_REQUEST });

    return getAllSkillsStatsApi().then((response) => {
        dispatch({
            type: GET_ALL_SKILLS_STATS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_SKILLS_STATS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SKILLS_ERROR));
        }
    });
};

export const getSkill = (skillName, eraseDataWhileLoading = true) => (dispatch) => {
    dispatch(requestSkill(skillName, eraseDataWhileLoading));

    return getSkillApi(skillName).then((response) => {
        dispatch({
            type: GET_SKILL_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_SKILL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SKILLS_ERROR));
        }
    });
};

export const addSkill = (skill) => (dispatch) => {
    dispatch({ type: ADD_SKILL_REQUEST });

    return postSkillApi(skill).then(() => {
        dispatch({
            type: ADD_SKILL_SUCCESS
        });
        dispatch(skillActions.getAllSkills());
        dispatch(notificationSuccess(SKILL_ADDED));
        dispatch(getAllFamilies());
        dispatch(getAllCategories());
    }, (error) => {
        dispatch({
            type: ADD_SKILL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(SKILL_ALREADY_EXIST));
        } else {
            dispatch(notificationError(ADD_SKILL_ERROR));
        }
    });
};

export const editSkill = (skill, eraseDataWhileLoading = true) => (dispatch) => {
    dispatch({ type: EDIT_SKILL_REQUEST });

    return putSkillApi(skill).then(() => {
        dispatch(getSkill(skill.skillName, eraseDataWhileLoading));
        dispatch(notificationSuccess(SKILL_UPDATED));
        dispatch({
            type: EDIT_SKILL_SUCCESS
        });
    }, (error) => {
        dispatch({
            type: EDIT_SKILL_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationWarning(UPDATE_SKILL_ERROR_404));
        } else {
            dispatch(notificationError(UPDATE_SKILL_ERROR));
        }
    });
};

export const resetCurrentSkill = () => ({ type: RESET_CURRENT_SKILL });
