
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import colors from 'styles/colors.module.scss';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Refresh from '@material-ui/icons/Refresh';
import { IconButton, makeStyles, Grid, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import {
    resetHistoryPaginated,
    resetQuestionHistory,
    resetSerieHistory
} from '../../redux/actions/Question/History/history.actions';
import { selectQuestionOrSerieHistoryData, selectGroupHistoryData } from '../../redux/selectors/Question/history.selector';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import DrawerHistoryDisplay from './DrawerHistoryDisplay';
import {
    getPreviousMonday,
    getNextSunday,
    updateDate
} from './DrawerHistory.function';
import { isBeforeToday, numericFormatDate } from 'app/utils/date.functions';
import '../../components/sidePanel/SidePanel.scss';
import DrawerHistoryTaskCreation from './DrawerHistoryTaskCreation';
import { postHistoryPaginatedApi } from 'app/api/historyApi';


const useStyles = makeStyles(() => ({
    dateEncadrement: {
        'border': '1px solid black',
        'padding': '5px'
    },
    dateGrid: {
        'display': 'flex',
        'justifyContent': 'center'
    },
    numberElement: {
        'display': 'flex',
        'justifyContent': 'center',
        'fontWeight': 'bold'
    }
}));

function DrawerHistory(props) {

    const dispatch = useDispatch();
    const classes = useStyles();

    const studentHistoryData = useSelector(selectQuestionOrSerieHistoryData);
    const groupHistoryData = useSelector(selectGroupHistoryData);

    const [groupHistory, setGroupHistory] = useState(null);
    const [isBadAnswerFilter, setBadAnswerFilter] = useState(false);
    const [mondayDate, setMondayDate] = useState(getPreviousMonday());
    const [studentHistory, setStudentHistory] = useState(null);
    const [sundayDate, setSundayDate] = useState(getNextSunday());
    const [isNextWeekInFuture, setIsNextWeekInFuture] = useState(true);

    const [filterBySkill, setFilterBySkill] = useState(false);

    const getUserId = (props.selectedStudentId) ? () => props.selectedStudentId : () => AuthenticationContainer.getCurrentUserId();

    const pageData = {
        pageNumber: 0,
        rowPerPage: 300,
        totalElement: 0
    };

    const badAnswerFilterChangeHandler = () => {
        setBadAnswerFilter(!isBadAnswerFilter);
    };

    const handleShowNextWeek = (firstdate, lastDate) => {
        const updatedMonday = updateDate(firstdate, 7);
        const updatedSunday = updateDate(lastDate, 7);
        setMondayDate(updatedMonday);
        setSundayDate(updatedSunday);

        if(!isBeforeToday(updateDate(updatedMonday, 7).toDateString())) {
            setIsNextWeekInFuture(true);
        }
        
        props.handleDateUpdated?.(updatedMonday, updatedSunday);
    };

    const handleShowPreviousWeek = (firstdate, lastDate) => {
        const updatedMonday = updateDate(firstdate, -7);
        const updatedSunday = updateDate(lastDate, -7);
        setMondayDate(updatedMonday);
        setSundayDate(updatedSunday);
        setIsNextWeekInFuture(false);

        props.handleDateUpdated?.(updatedMonday, updatedSunday);
    };

    const handleFilterBySkill = (event) => {
        props.handleFilterBySkill?.(event.target.checked);
        setFilterBySkill(event.target.checked);
    };

    const buildPage = () => {
        const page = {
            criteria: {
                fromWhenDate: mondayDate,
                toWhenDate: sundayDate,
                ...(props.skillId && filterBySkill && {
                    skillIds: [props.skillId]
                })
            },
            offset: 0,
            step: pageData.rowPerPage,
            historyType: 'QUESTION'
        };

        return page;
    };

    const fetchHistory = () => {
        const page = buildPage();
        if(props.selectedGroupId) {
            page.criteria.groupId = props.selectedGroupId;
            postHistoryPaginatedApi(page).then((response) => {
                setGroupHistory(response.data);
            });
        } else if(props.selectedStudentId) {
            page.criteria.userId = getUserId();
            if(isBadAnswerFilter) {
                page.criteria.badAnswer = isBadAnswerFilter;
            }
            postHistoryPaginatedApi(page).then((response) => {
                setStudentHistory(response.data);
            });
        }
    };

    const disableColor = () => {
        if(isBadAnswerFilter) {
            return colors['red-color-400'];
        }
        return colors['grey-color-400'];
    };

    useEffect(() => () => {
        dispatch(resetHistoryPaginated());
        dispatch(resetQuestionHistory());
        dispatch(resetSerieHistory());
        setStudentHistory(studentHistoryData);
        setGroupHistory(groupHistoryData);
    }, []);

    useEffect(() => {
        fetchHistory();
    }, [props.selectedStudentId, props.selectedGroupId, mondayDate, filterBySkill]);

    return (
        <>
            <DrawerHistoryTaskCreation {...props} />
            <div className="tasksList">
                <Grid className={classes.dateGrid}>
                    <div>
                        <IconButton aria-label="semaine precedente" onClick={() => handleShowPreviousWeek(mondayDate, sundayDate)} >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <span className={classes.dateEncadrement}>
                            {`du ${numericFormatDate(mondayDate)} au ${numericFormatDate(sundayDate)}`}
                        </span>
                        <IconButton aria-label="semaine suivante"
                            onClick={() => handleShowNextWeek(mondayDate, sundayDate)}
                            disabled={isNextWeekInFuture}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <Tooltip title="Rafraichir l'historique">
                            <IconButton onClick={() => fetchHistory()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Grid>
                { !props.selectedGroupId && <Grid item id="history-filter" >
                    <div>
                        <IconButton
                            title="afficher les erreurs"
                            data-testid="bad-answer-filter-button"
                            onClick={badAnswerFilterChangeHandler}
                        >
                            <CloseRoundedIcon
                                aria-label="afficher les erreurs"
                                style={{ color: disableColor() }}/>
                        </IconButton>
                        <span >Afficher uniquement les mauvaises réponses</span>
                    </div>
                </Grid>
                }
                {props.handleFilterBySkill
                    ? <Grid item id="filter-by-skill">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleFilterBySkill}
                                    color="primary"
                                />
                            }
                            label="Filtrer par la compétence"
                        />
                    </Grid>
                    : <></>}


                <Grid container direction="column" className="history" alignItems="center">
                    <Grid item className="desktop-side-panel-history">
                        <DrawerHistoryDisplay
                            badAnswerFilter={isBadAnswerFilter}
                            className="historyTable"
                            groupData={groupHistory?.listOfElements}
                            isGroup={Boolean(props.selectedGroupId)}
                            studentData={studentHistory?.listOfElements}
                            onClickQuestion={props.onClickQuestion}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

DrawerHistory.propTypes = {
    selectedStudentId: PropTypes.number,
    selectedGroupId: PropTypes.number,
    selectedGroup: PropTypes.object,
    onClickQuestion: PropTypes.func.isRequired,
    handleDateUpdated: PropTypes.func,
    handleFilterBySkill: PropTypes.func,
    skillId: PropTypes.string
};

export default DrawerHistory;
