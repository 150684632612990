import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Statistics.scss';
import StatisticsContent from './StatisticsContent';
import { selectCurrentUser } from '../redux/selectors/User/user.selector';
import { getCurrentUserById } from '../redux/actions/User/user.actions';

export class Statistics extends Component {

    componentDidMount() {
        this.props.getCurrentUserById();
    }

    render() {

        const currentUser = this.props.currentUser;

        return (
            <section className="statistics">
                <div>
                    <StatisticsContent user={currentUser}/>
                </div>
            </section>
        );
    }
}

Statistics.propTypes = {
    match: PropTypes.object,
    currentUser: PropTypes.object,
    getCurrentUserById: PropTypes.func
};

function mapStateToProps(state) {
    return {
        currentUser: selectCurrentUser(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCurrentUserById: () => dispatch(getCurrentUserById())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Statistics));
