import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentDisplay from './ContentDisplay';

import { IconButton, Tooltip } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function ContentRequest(props) {

    const [resource, setResource] = React.useState();
    const [resourceIndex, setResourceIndex] = React.useState(props.skillResourceIndex);

    useEffect(() => {
        if(resourceIndex < props.resourceList?.length) {
            setResource(props.resourceList[resourceIndex]);
        }
    }, [resourceIndex, props.resourceList]);

    const handlePrevious = () => {
        const nextIndex = resourceIndex - 1;
        if(nextIndex >= 0) {
            setResourceIndex(nextIndex);
        } else {
            setResourceIndex(props.resourceList.length - 1);
        }
    };

    const handleNext = () => {
        const nextIndex = resourceIndex + 1;
        if(nextIndex < props.resourceList.length) {
            setResourceIndex(nextIndex);
        } else {
            setResourceIndex(0);
        }
    };

    return (
        <div className="chapter-resource-content" data-testid="chapter-resource-content">
            {!props.displaySkillResources && props.resourceList && props.resourceList.length > 0 &&
                <div className="ressource-nav-bar" data-testid="ressource-nav-bar">
                    <Tooltip title="Revenir à la liste des ressources du chapitre">
                        <IconButton id="returnToResourcesList"
                            onClick={props.returnToSummary}
                            aria-label="Liste des ressources">
                            <KeyboardReturnIcon></KeyboardReturnIcon>
                        </IconButton>
                    </Tooltip>
                    <div className="change-ressource-bar">
                        <Tooltip title="Ressource précédente" aria-label="Ressource précédente">
                            <IconButton id="previousResourceButton"
                                onClick={handlePrevious}
                                aria-label="Ressource précédente"
                                data-testid="previous-resource-button">
                                <NavigateBeforeIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <span>Ressource {resourceIndex + 1} sur {props.resourceList.length}</span>
                        <Tooltip title="Ressource suivante" aria-label="Ressource suivante">
                            <IconButton id="nextResourceButton"
                                onClick={handleNext}
                                aria-label="Ressource suivante"
                                data-testid="next-resource-button">
                                <NavigateNextIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            }
            <div id="resource-content" className="chapter-resource-content">
                <ContentDisplay resource={resource} />
            </div>
        </div>
    );
}

ContentRequest.propTypes = {
    resourceList: PropTypes.array,
    chapterId: PropTypes.number,
    displaySkillResources: PropTypes.bool,
    skillResourceIndex: PropTypes.number,
    returnToSummary: PropTypes.func,
    isModerator: PropTypes.bool
};

export default ContentRequest;
