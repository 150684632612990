/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ParcoursTreeItem from './ParcoursTreeItem';
import CreateParcoursForm from './CreateParcoursForm';
import {
    updateUserParcoursApi,
    updateGroupParcoursApi,
    getAllParcoursDisplayByCreatorIdApi,
    getAllFollowedParcoursDisplayByUserIdOrGroupIdApi,
    getParcoursStatusByUserId,
    getEditionSharedParcoursDisplayByUserIdApi,
} from 'app/api/parcoursApi';
import { 
    notificationError, 
    notificationSuccess, 
} from 'app/redux/actions/Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    PATCH_LIST_UPDATE_PARCOURS_ERROR,
    PARCOURS_LIST_UPDATED_SUCCESS,
    UPDATE_LIST_PARCOURS_ALREADY_ERROR,
    RETRIEVE_MANY_PARCOURS_ERROR,
    RETRIEVE_PARCOURS_STATUS_ERROR
} from 'app/Snackbar/NotificationMessages';
import DropZone from './DropZone';
import { typeOfId } from 'app/utils/TypeOfIdEnum';
import { selectParcoursCreated, selectParcoursIdToDelete, selectParcoursIdToUnshare, selectParcoursShared, selectParcoursUpdated } from 'app/redux/selectors/Parcours/parcours.selector';
import { createParcours, updateParcours, getStatusByUserId, resetParcoursSharedIdToDelete } from 'app/redux/actions/Parcours/parcours.actions';
import { TreeView } from '@material-ui/lab';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { ParcoursTypeEnum } from 'app/utils/ParcoursTypeEnum';

function Parcours(props) {
    const dispatch = useDispatch();

    const userId = AuthenticationContainer.getCurrentUserId();

    const parcoursIdToDelete = useSelector(selectParcoursIdToDelete);
    const parcoursCreated = useSelector(selectParcoursCreated);
    const parcoursUpdated = useSelector(selectParcoursUpdated);
    const parcoursShared = useSelector(selectParcoursShared);
    const parcoursIdToUnshare = useSelector(selectParcoursIdToUnshare);

    const [statusParcours, setStatusParcours] = useState([]);

    const [parcoursList, setParcoursList] = useState([]);
    const [isParcoursClickList, setParcoursClickList] = useState([]);
    const [parcoursForEdit, setParcoursForEdit] = useState(null);
    const [expanded, setExpanded] = useState([]);

    const isCreateParcoursToggled = Boolean(parcoursForEdit);
    
    const handleToggle = (event, nodes) => {
        setExpanded(nodes);
    };

    const setParcoursClickListById = (parcoursId, status) => {
        const newParcoursClickList = isParcoursClickList.map(parcoursClick => {
            if(parcoursClick.id === parcoursId) {
                return {
                    ...parcoursClick,
                    isClick: status
                };
            }

            return parcoursClick;
        });

        setParcoursClickList(newParcoursClickList);
    };

    const createNewParcours = (parcours) => {
        const mappedCreatedParcours = {
            ...parcours,
            parcoursSteps: parcours.parcoursSteps.map(step => ({
                ...step,
                parcoursStepContents: step.parcoursStepContents.map(content => {
                    const { ...restContent } = content;
                    restContent.type = restContent.type.toUpperCase();
                    return restContent;
                })
            }))
        };
        dispatch(createParcours(mappedCreatedParcours));
        props.setParcoursType(ParcoursTypeEnum.CREATED);
        
    };

    const editParcours = (parcours) => {
        const mappedUpdatedParcours = {
            ...parcours,
            parcoursSteps: parcours.parcoursSteps.map(step => ({
                ...step,
                parcoursStepContents: step.parcoursStepContents.map(content => {
                    const { ...restContent } = content;
                    restContent.type = restContent.type.toUpperCase();
                    return restContent;
                })
            }))
        };
        dispatch(updateParcours(mappedUpdatedParcours));
    };

    const postParcours = (parcours) => {
        if(parcours.id === null) {
            createNewParcours(parcours);
        } else {
            editParcours(parcours);
        }
    };

    const handleCloseForm = () => {
        setParcoursForEdit(null);
    };

    const handleCreateParcoursClick = () => {
        setParcoursForEdit({});
    };

    const pushNewParcoursInClickList = (parcoursId) => {
        const newParcoursClickList = isParcoursClickList;
        newParcoursClickList.push({ 
            id: parcoursId,
            isClick: false
        });
        setParcoursClickList(newParcoursClickList);
    };

    const handleOnDropZone = (item, userIdToDrop, groupId, dropParcoursList) => {
        if(dropParcoursList.map(parcours => parcours.id).includes(item.parcours.id)) {
            dispatch(notificationError(UPDATE_LIST_PARCOURS_ALREADY_ERROR));
        }
        else if(userIdToDrop){
            updateUserParcoursApi(item.parcours.id, userIdToDrop).then(() => {
                setParcoursList(prevList => [...prevList, item.parcours]);
                dispatch(getStatusByUserId(userIdToDrop));
                pushNewParcoursInClickList(item.parcours.id);
                dispatch(notificationSuccess(PARCOURS_LIST_UPDATED_SUCCESS));
            }, (error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(PATCH_LIST_UPDATE_PARCOURS_ERROR));
                }
            });
        }
        else if(groupId){
            updateGroupParcoursApi(item.parcours.id, groupId).then(() => {
                setParcoursList(prevList => [...prevList, item.parcours]);
                pushNewParcoursInClickList(item.parcours.id);
                dispatch(notificationSuccess(PARCOURS_LIST_UPDATED_SUCCESS));
            }, (error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(PATCH_LIST_UPDATE_PARCOURS_ERROR));
                }
            });    
        }
    };

    const getAllFollowedParcoursByUserId = () => {
        setParcoursList([]);
        setExpanded([]);
        getAllFollowedParcoursDisplayByUserIdOrGroupIdApi(props.selectedStudentId, typeOfId.USER).then((response) => {
            setParcoursList(response.data);
            setParcoursClickList(response.data.map(parcours => ({
                id: parcours.id,
                isClick: false
            })));
            getParcoursStatusByUserId(props.selectedStudentId).then((responseStatus) => {
                setStatusParcours(responseStatus.data);
            }, (error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(RETRIEVE_PARCOURS_STATUS_ERROR));
                }
            });
        }, (error) => {
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(RETRIEVE_MANY_PARCOURS_ERROR));
            }
        });
    };

    const getAllParcoursByCreatorId = () => {
        setParcoursList([]);
        setExpanded([]);
        getAllParcoursDisplayByCreatorIdApi().then((response) => {
            setParcoursList(response.data);
            setParcoursClickList(response.data.map(parcours => ({
                id: parcours.id,
                isClick: false
            })));
        }, (error) => {
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(RETRIEVE_MANY_PARCOURS_ERROR));
            }
        });
    };

    const getAllFollowedParcoursDisplayByGroupId= () => {
        setParcoursList([]);
        setExpanded([]);
        getAllFollowedParcoursDisplayByUserIdOrGroupIdApi(props.selectedGroupId, typeOfId.GROUP).then((response) => {
            setParcoursList(response.data);
            setParcoursClickList(response.data.map(parcours => ({
                id: parcours.id,
                isClick: false
            })));
            setStatusParcours([]);
        }, (error) => {
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(RETRIEVE_MANY_PARCOURS_ERROR));
            }
        });
    };

    const getEditionSharedParcoursByUserId = () => {
        setParcoursList([]);
        getEditionSharedParcoursDisplayByUserIdApi().then((response) => {
            setParcoursList(response.data);
        }, (error) => {
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(RETRIEVE_MANY_PARCOURS_ERROR));
            }
        });
    };

    const handleHeaderDisplay = () => {
        switch (props.parcoursType) {
            case ParcoursTypeEnum.ASSIGNED: 
                return <DropZone
                    handleDrop={handleOnDropZone}
                    userId={props.selectedStudentId}
                    groupId={props.selectedGroupId}
                    parcoursList={parcoursList}
                >
                </DropZone>;
            case ParcoursTypeEnum.CREATED:
                return <div style={{ textAlign: 'center' }}>
                    <Button className="create-parcours-button"
                        color="primary"
                        variant="contained"
                        aria-label="Creation parcours"
                        onClick={handleCreateParcoursClick}
                        disabled={isCreateParcoursToggled}
                        style={{ margin: '10px' }}
                    >
                    Nouveau parcours
                    </Button>
                </div>;
            default: 
                return null;
        }
    };

    useEffect(() => {
        handleCloseForm();
        props.setParcoursType(ParcoursTypeEnum.ASSIGNED);
        if(props.parcoursType === ParcoursTypeEnum.ASSIGNED){
            if(props.selectedStudentId) {
                getAllFollowedParcoursByUserId();
            }
        }
    }, [props.selectedStudentId]);

    useEffect(() => {
        handleCloseForm();
        props.setParcoursType(ParcoursTypeEnum.ASSIGNED);
        if(props.parcoursType === ParcoursTypeEnum.ASSIGNED){
            if(props.selectedGroupId) {
                getAllFollowedParcoursDisplayByGroupId();
            }
        }        
    }, [props.selectedGroupId]);

    useEffect(() => {
        if(props.selectedStudentId && props.parcoursType !== ParcoursTypeEnum.INITIAL) {
            switch (props.parcoursType) {
                case ParcoursTypeEnum.ASSIGNED: 
                    getAllFollowedParcoursByUserId();
                    break;
                case ParcoursTypeEnum.CREATED:
                    getAllParcoursByCreatorId();
                    break;
                case ParcoursTypeEnum.SHARED:
                    getEditionSharedParcoursByUserId();
                    break;
                default:
            }
        }
        else if(props.selectedGroupId && props.parcoursType !== ParcoursTypeEnum.INITIAL){
            getAllFollowedParcoursDisplayByGroupId();
        }
    }, [props.parcoursType]);

    useEffect(() => {
        if(parcoursIdToDelete) {
            const newParcoursList = parcoursList.filter(parcours => parcours.id !== parcoursIdToDelete);
            setParcoursList(newParcoursList);
        }
    }, [parcoursIdToDelete]);

    useEffect(() => {
        if(parcoursCreated && parcoursCreated.creatorId === props.selectedStudentId && 
            props.parcoursType === ParcoursTypeEnum.CREATED) {
            pushNewParcoursInClickList(parcoursCreated.id);
            const newParcoursList = parcoursList;
            newParcoursList.push(parcoursCreated);
            setParcoursList(newParcoursList);
        }
    }, [parcoursCreated]);

    useEffect(() => {
        if(parcoursUpdated) {
            setExpanded([]);
            setParcoursClickListById(parcoursUpdated.id, false);
            const newParcoursList = parcoursList.map(parcours => {
                if(parcours.id === parcoursUpdated.id) {
                    parcoursUpdated.needRefresh = true;
                    return parcoursUpdated;
                }
                
                return parcours;
            });
            setParcoursList(newParcoursList);
            getParcoursStatusByUserId(props.selectedStudentId).then((responseStatus) => {
                setStatusParcours(responseStatus.data);
            }, (error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(RETRIEVE_PARCOURS_STATUS_ERROR));
                }
            });
        }
    }, [parcoursUpdated]);

    useEffect(() => {
        if(parcoursShared && props.parcoursType === ParcoursTypeEnum.SHARED && 
            parcoursShared.editors.map(editor => editor.userId).includes(Number(userId))) {
            pushNewParcoursInClickList(parcoursShared.id);
            const newParcoursList = parcoursList;
            newParcoursList.push(parcoursShared);
            setParcoursList(newParcoursList);       
        }
    }, [parcoursShared]);

    useEffect(() => {
        if(parcoursIdToUnshare && props.parcoursType === ParcoursTypeEnum.SHARED) {
            const newParcoursList = parcoursList.filter(parcours => parcours.id !== parcoursIdToUnshare);
            setParcoursList(newParcoursList);
            dispatch(resetParcoursSharedIdToDelete);
        }
    }, [parcoursIdToUnshare]);

    return (
        <div>
            {handleHeaderDisplay()}

            {isCreateParcoursToggled && <CreateParcoursForm
                setIsCreateParcoursToggled={handleCreateParcoursClick}
                createNewParcours={postParcours}
                handleCloseForm={handleCloseForm}
                parcoursForEdit={parcoursForEdit}
            />}

            <TreeView
                className="ParcoursTreeView"
                defaultCollapseIcon={<ExpandMoreIcon style={{ fontSize: '2rem' }} />}
                defaultExpandIcon={<ChevronRightIcon style={{ fontSize: '2rem' }} />}
                expanded={expanded}
                onNodeToggle={handleToggle}
            >
                {parcoursList?.map((parcours) => (
                    <div
                        key={parcours.id}
                        style={{ margin: '0 10px', display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}
                    >
                        <ParcoursTreeItem
                            parcours={parcours}
                            statusParcours={statusParcours.find(status => status.id === parcours.id)}
                            selectedStudentId={props.selectedStudentId}
                            parcoursType={props.parcoursType}
                            key={parcours.id}
                            setParcoursForEdit={setParcoursForEdit}
                            isClick={isParcoursClickList.find(parcoursClick => parcoursClick.id === parcours.id)?.isClick}
                            setParcoursClickListById={setParcoursClickListById}
                            parcoursUpdated={parcoursUpdated}
                            isSelectedGroup={Boolean(props.selectedGroupId)}
                        />
                    </div>
                ))}
            </TreeView>
        </div>
    );
}

Parcours.propTypes = {
    parcoursType: PropTypes.string.isRequired,
    setParcoursType: PropTypes.func.isRequired,
    selectedStudentId: PropTypes.number,
    selectedGroupId: PropTypes.number
};

export default Parcours;
