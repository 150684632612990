
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import colors from 'styles/colors.module.scss';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';
import { History } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import QuestionRequestByQuestionHistoryId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByQuestionHistoryId';
import { isUndefined } from 'lodash';
import { dateDisplay } from '../../History.functions';
import { getResultColor } from '../utils';

function HistorySerieOrParcoursStepQuestion(props) {

    const [open, setOpen] = useState(false);

    function resultDisplay(value) {
        const score = isUndefined(value) ? -2 : value;
        switch (score) {
            case 'TRUE':
            case 101:
            case 1:
                return <DoneIcon aria-label="doneIcon" style={{ color: colors['green-color-700'] }} />;
            case 'FALSE':
            case 0:
                return <CloseIcon aria-label="closeIcon" style={{ color: colors['red-color-400'] }} />;
            case 'PENDING':
            case -2:
                return <History aria-label="pendingIcon" style={{ color: colors['dark-orange'] }}/>;
            case 'NOT_DONE':
            case -1:
                return <RemoveIcon aria-label="notDoneIcon" style={{ color: colors['grey-color-400'] }}/>;
            default:
                return <div style={{ color: getResultColor(score) }}>
                    {`${score}/100`}
                </div>;
        }
    }

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>{props.row.title}</TableCell>
                <TableCell align="center">
                    {
                        resultDisplay(props.row.grade ?? props.row.result)
                    }
                </TableCell>
                <TableCell>{dateDisplay(props.row?.date)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <QuestionRequestByQuestionHistoryId questionHistoryId={props.row.id} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

HistorySerieOrParcoursStepQuestion.propTypes = {
    row: PropTypes.object.isRequired
};

export default HistorySerieOrParcoursStepQuestion;
