import AuthenticationContainer from '../containers/AuthenticationContainer';
import { GET_CURRENT_USER, getCurrentUser, patchUser } from '../../redux/actions/User/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import './Login.scss';
import { selectCurrentUser, selectCurrentUserRegistered } from '../../redux/selectors/User/user.selector';
import PropTypes from 'prop-types';
import { notificationError } from '../../redux/actions/Notification/notifications.actions';
import { LOGIN_FAILED_MESSAGE } from '../../Snackbar/NotificationMessages';
import { createLoadingSelector } from '../../redux/selectors/APISelector/APISelector';
import logo from 'assets/images/logo-exciskills-alpha.png';
import React, { useEffect } from 'react';


export default function Login(props: { url: string }) {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectCurrentUser);
    const isFetching = useSelector(createLoadingSelector([GET_CURRENT_USER]));
    const registered = useSelector(selectCurrentUserRegistered);

    const handleSuccess = async(googleUser: any) => {
        await AuthenticationContainer.connect(googleUser);
        dispatch(getCurrentUser());
    };

    const handleFailure = () => {
        dispatch(notificationError(LOGIN_FAILED_MESSAGE));
    };

    /*
     * You  can't have both auth-code flow (working with code & refresh token for google identity) and
     * base login button from google (which only work with JWT token)
     * This a workaround to allow graphical coherence with prefered identification methods
     */
    const login = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: handleFailure,
        flow: 'auth-code'
    });


    useEffect(() => {
        if(!isFetching) {
            if(registered) {
                const date = new Date();
                const updates = {
                    lastConnectionDateTime: date.toISOString().substring(0, 23),
                    imageUrl: AuthenticationContainer.getProfilePicture()
                };
                dispatch(patchUser(user.id, updates));
                if(AuthenticationContainer.isConnected()) {
                    history.push(props.url || '/chat');
                }
            } else if(registered === false) {
                history.push('/first-connection');
            }
        }
    }, [isFetching, registered, user]);

    return (
        <div className="login-wrapper" data-testid="login-form">
            <div className="login-content">
                <img className="logo-oxyl"
                    src={logo}
                    alt="logo-oxyl"/>
                <div className="google-button-container" data-testid="test-container">
                    <a
                        onClick={login}
                        className="google-button-cover"
                        data-testid="test-connexion"
                    />
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleFailure}
                    />
                </div>
            </div>
        </div>
    );
}

Login.propTypes = {
    url: PropTypes.string
};

