import { postClientApi, putClientApi } from '../../../../api/clientSetApi';
import { notificationError, notificationSuccess, notificationWarning } from '../../Notification/notifications.actions';
import {
    ADD_CLIENT_ERROR,
    UPDATE_CLIENT_ERROR,
    EXPIRED_SESSION,
    CLIENT_ADDED,
    CLIENT_ALREADY_EXIST,
    CLIENT_UPDATED
} from '../../../../Snackbar/NotificationMessages';

import { getAllClients, getClientById } from '../clients.actions';

export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';

export const EDIT_CLIENT = 'EDIT_CLIENT';
export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';

export const addClient = (client) => (dispatch) => {
    dispatch({ type: ADD_CLIENT_REQUEST });

    return postClientApi(client).then(() => {
        dispatch({
            type: ADD_CLIENT_SUCCESS
        });
        dispatch(getAllClients());
        dispatch(notificationSuccess(CLIENT_ADDED));
    }, (error) => {
        dispatch({
            type: ADD_CLIENT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(CLIENT_ALREADY_EXIST));
        } else {
            dispatch(notificationError(ADD_CLIENT_ERROR));
        }
    });
};

export const editClient = (client) => (dispatch) => {
    dispatch({ type: EDIT_CLIENT_REQUEST });

    return putClientApi(client).then(() => {
        dispatch({
            type: EDIT_CLIENT_SUCCESS
        });
        dispatch(getClientById(client.id));
        dispatch(getAllClients());
        dispatch(notificationSuccess(CLIENT_UPDATED));
    }, (error) => {
        dispatch({
            type: EDIT_CLIENT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(CLIENT_ALREADY_EXIST));
        } else {
            dispatch(notificationError(UPDATE_CLIENT_ERROR));
        }
    });
};
