import { SET_CATEGORY_FILTER, SET_FAMILY_FILTER, SET_SEARCH_FILTER } from '../../actions/Filter/filter.actions';

export const familyFilterStore = 'families';
export const categoryFilterStore = 'categories';
export const searchSkillStore = 'search';

export default function filtersReducer(state = {
    [searchSkillStore]: '',
    [familyFilterStore]: [],
    [categoryFilterStore]: []
}, action) {
    switch (action.type) {
        case SET_SEARCH_FILTER:
            return { ...state,
                [searchSkillStore]: action.payload };
        case SET_FAMILY_FILTER:
            return { ...state,
                [familyFilterStore]: action.payload };
        case SET_CATEGORY_FILTER:
            return { ...state,
                [categoryFilterStore]: action.payload };
        default:
            return state;
    }
}
