import { GET_USERSKILLS_BY_USER_ID_SUCCESS } from '../../actions/User/UserSkills/userSkill.actions';

export const userSkillsFromCurrentUser = 'UserSkills from current User';

export default function userSkillReducer(state = {
    [userSkillsFromCurrentUser]: []
}, action) {
    switch (action.type) {
        case GET_USERSKILLS_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [userSkillsFromCurrentUser]: action.payload
            };
        default:
            return state;
    }
}
