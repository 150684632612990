const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';

export function enqueueSnackbarAction(params) {
    return {
        type: ENQUEUE_SNACKBAR,
        params
    };
}

function notificationGeneric(message, variant) {
    return enqueueSnackbarAction({
        key: new Date().getTime() + Math.random(),
        message,
        options: {
            variant
        }
    });
}

export function notificationError(message) {
    return notificationGeneric(message, 'error');
}

export function notificationWarning(message) {
    return notificationGeneric(message, 'warning');
}

export function notificationSuccess(message) {
    return notificationGeneric(message, 'success');
}

export function clearNotification(key) {
    return {
        type: CLEAR_NOTIFICATION,
        key
    };
}

export function clearAllNotifications() {
    return {
        type: CLEAR_ALL_NOTIFICATIONS
    };
}
