import React, { useEffect, useState } from 'react';
import {
    Collapse,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Checkbox
} from '@material-ui/core';
import '../CoachingGroup.scss';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TaskSelector from '../../../../components/Selectors/TaskSelector/TaskSelector';
import PropTypes from 'prop-types';
import CoachingGroupHistoriqueTable from './CoachingGroupHistoriqueTable';
import CoachingGroupHistoriqueQuestionTable from './CoachingGroupHistoriqueQuestionTable';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllGroupTaskList } from '../../../../redux/selectors/Task/task.selector';
import { getTasksByGroupId } from '../../../../redux/actions/Coaching/Tasks/Task.action';
import { getDetailedTasksByGroupIdApi } from 'app/api/taskApi';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getHistoryGroupByTaskIdApi } from 'app/api/historyApi';

const useStyles = makeStyles(() => ({
    clickable: {
        cursor: 'pointer'
    },
    clickableToggleCollapse: {
        cursor: 'pointer',
        paddingLeft: '0 !important'
    },
    ToggleCollapse: {
        paddingTop: '15px'
    },
    taskSelector: {
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingBottom: '15px'
    },
    table: {
        paddingTop: '15px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '30px'
    }
}));

export default function CoachingGroupHistorique(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isStudentChecked, setIsStudentChecked] = useState(true);
    const [statsGroupByQuestion, setStatsGroupByQuestion] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);

    const tasksDetails = useSelector(selectAllGroupTaskList);

    const flipViewStudentQuestion = () => {
        setIsStudentChecked(!isStudentChecked);
    };

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    function dispatchTasks(idGroup) {
        if(idGroup) {
            dispatch(getTasksByGroupId(idGroup, true));
        }
    }

    const getTaskDetails = (task) => {
        if(task && task.id !== null && props.groupId !== null) {
            if(isStudentChecked) {
                getDetailedTasksByGroupIdApi(props.groupId, task.id).then((response) => {
                    const newSelectedTask = response.data;
                    if(task.creationDate) {
                        newSelectedTask.creationDate = task.creationDate;
                    }
                    setSelectedTask(response.data);
                });
            } else {
                getHistoryGroupByTaskIdApi(task.id)
                    .then((response) => {
                        setStatsGroupByQuestion(response.data);
                    });
            }
        } else {
            setIsStudentChecked(true);
            setSelectedTask(null);
        }

    };

    useEffect(() => {
        setIsCollapsed(true);
        dispatchTasks(props.groupId);
        setSelectedTask(null);
    }, [props.groupId]);

    return (
        <Paper style={{ marginTop: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={11} sm={11} md={11} lg={11} id="clickable" className={classes.clickable}
                    onClick={handleCollapse}>
                    <h3 className="groupSpacing">Historique</h3>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} className={classes.clickableToggleCollapse}
                    onClick={handleCollapse}>
                    <div className={classes.ToggleCollapse}>
                        {isCollapsed ? <ExpandMore/> : <ExpandLess/>}
                    </div>
                </Grid>
            </Grid>
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit className={classes.collapse}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <div className={classes.taskSelector}>
                            <TaskSelector
                                selectedTask={selectedTask}
                                setSelectedTask={getTaskDetails}
                                groupId={props.groupId}
                                tasks={tasksDetails}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} style= {{ display: 'flex', alignItems: 'center' }}>
                        {selectedTask !== null &&
                        <>
                            <div className="refreshButton">
                                <IconButton onClick={() => getTaskDetails(selectedTask)}>
                                    <RefreshIcon/>
                                </IconButton>
                            </div>

                            <div style= {{ alignItems: 'center' }}>
                                Grouper par:
                                <Checkbox
                                    color="default"
                                    id="checkStudent"
                                    className="checkboxStudent"
                                    onChange={flipViewStudentQuestion}
                                    checked={isStudentChecked}
                                    disabled={false}
                                />
                                Élève

                                <Checkbox
                                    color="default"
                                    id="checkQuestion"
                                    className="checkboxQuestion"
                                    onChange={flipViewStudentQuestion}
                                    checked={!isStudentChecked}
                                    disabled={false}
                                />
                                Question
                            </div>
                        </>
                        }
                    </Grid>
                    {selectedTask !== null && (isStudentChecked
                        ? <Grid className={classes.table} item xs={12} sm={12} md={12} lg={12}>
                            <div className={classes.table}>
                                <CoachingGroupHistoriqueTable
                                    groupId={props.groupId}
                                    selectedTask={selectedTask}/>
                            </div>
                        </Grid>
                        : <Grid className={classes.table} item xs={12} sm={12} md={12} lg={12}>
                            <div className={classes.table}>
                                <CoachingGroupHistoriqueQuestionTable
                                    groupId={props.groupId}
                                    groupName={props.groupName}
                                    selectedTask={selectedTask}
                                    statsGroupByQuestion={statsGroupByQuestion}
                                    setStatsGroupByQuestion={setStatsGroupByQuestion}/>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Collapse>
        </Paper>
    );
}

CoachingGroupHistorique.propTypes = {
    groupId: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired
};
