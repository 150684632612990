import React from 'react';
import PropTypes from 'prop-types';
import './Layout.scss';
import { createPortal } from 'react-dom';

export default function LayoutModal(props) {
    const modalContainer = document.getElementById('layout-modal-container');

    const handleClickOutside = (event) => {
        if(event.target && event.target.id === 'outer-layout-modal') {
            props.closeAction();
        }

    };

    return (
        <>
            {   props.opened &&
                createPortal(
                    <div id="outer-layout-modal" className="layout-modal-container" onClick={(event) => handleClickOutside(event)}>
                        {props.children}
                    </div>,
                    modalContainer
                )
            }
        </>
    );
};

LayoutModal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    opened: PropTypes.bool,
    closeAction: PropTypes.func
};