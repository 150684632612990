import React, { useState, useEffect } from 'react';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';

import { useDispatch, useSelector } from 'react-redux';
import { getDailyQuestionsToDo } from '../../redux/actions/Question/DailyQuestions/DailyQuestionsGet/dailyQuestionsGet.actions';
import { selectDailyQuestions } from '../../redux/selectors/Question/dailyQuestions.selector';
import { getNotificationByDestinataireId, deleteNotificationById,
    deleteAllNotificationByUserId } from '../../redux/actions/Notification/userNotifications.actions';
import { getNotificationsByDestinataireId } from '../../redux/selectors/Notification/userNotification.selector';
import { format } from 'date-fns';

function getNotificationTextQuestionNotification(notification) {
    switch (notification.type) {
        case 'NEW_QUESTION':
            return 'Une nouvelle question vient d\'être créée sur une compétence dont vous êtes modérateur';
        case 'QUESTION_UPDATED':
            return 'Une question dont vous êtes modérateur a été mise à jour';
        case 'NEW_REPORT':
            return 'Un problème a été signalé sur une question dont vous êtes modérateur';
        case 'REPORT_TREATED':
            return 'Votre signalement a été traité';
        default:
            return '';
    }
}

function getNotificationTextChapterNotification(notification) {
    switch (notification.type) {
        case 'NEW_REPORT':
            return 'Un problème a été signalé sur un chapitre dont vous êtes modérateur';
        case 'REPORT_TREATED':
            return 'Votre signalement a été traité';
        default:
            return '';
    }
}

function getNotificationTextCoachingNotification(notification) {
    switch (notification.type) {
        case 'COACHING_REQUEST':
            return `${notification.student.firstName} ${notification.student.lastName} vous a envoyé une demande de coaching`;
        case 'COACHING_ACCEPTED':
            return `${notification.coach.firstName} ${notification.coach.lastName} a accepté votre demande de coaching`;
        default:
            return '';
    }
}
function getNotificationTextModerationNotification(notification) {
    switch (notification.type) {
        case 'MODERATION_REQUEST':
            return `${notification.user.firstName} ${notification.user.lastName} demande à être modérateur ${notification.skillName}`;
        case 'MODERATION_ACCEPTED':
            return `Vous avez été désigné modérateur de ${notification.skillName}`;
        default:
            return '';
    }
}

function getNotificationTextRecruitmentNotification(notification) {
    switch (notification.type) {
        case 'CANDIDATE_TEST_DONE':
            return `Le candidat ${notification.candidate.firstName} ${notification.candidate.lastName} a fini son test de recrutement`;
        case 'CANDIDATE_VALIDATED':
            switch (notification.candidate.status) {
                case 'NEXT_STEP':
                    return `Le candidat ${notification.candidate.firstName} ${notification.candidate.lastName} peut passer à l'étape suivante`;
                case 'REJECTED':
                    return `Le candidat ${notification.candidate.firstName} ${notification.candidate.lastName} est disqualifié`;
                default:
                    return '';
            }
        default:
            return '';

    }
}

function getNotificationTextMicroskillNotification(notification) {
    switch (notification.type) {
        case 'NEW_REPORT':
            return 'Un problème a été signalé sur un microskill dont vous êtes modérateur';
        default:
            return '';
    }
}

export function getNotificationText(notification) {
    switch (notification.class) {
        case 'ModerationNotification':
            return getNotificationTextModerationNotification(notification);
        case 'QuestionNotification':
            return getNotificationTextQuestionNotification(notification);
        case 'ChapterNotification':
            return getNotificationTextChapterNotification(notification);
        case 'CoachingNotification':
            return getNotificationTextCoachingNotification(notification);
        case 'RecruitmentNotification':
            return getNotificationTextRecruitmentNotification(notification);
        case 'CoachGroupNotification':
            return 'Vous avez été ajouté en tant que coach à un groupe';
        case 'MicroskillNotification':
            return getNotificationTextMicroskillNotification(notification);
        default:
            return '';
    }
}

function getNotificationUrlQuestionNotification(notification) {
    switch (notification.type) {
        case 'NEW_QUESTION':
            return `/questions/resolve/${notification.questionId}`;
        case 'QUESTION_UPDATED':
            return `/questions/resolve/${notification.questionId}`;
        case 'NEW_REPORT':
            return `/questions/edit?id=${notification.questionId}`;
        case 'REPORT_TREATED':
            return `/questions/resolve/${notification.questionId}`;
        default:
            return '';
    }
}

function getNotificationUrlChapterNotification(notification) {
    switch (notification.type) {
        case 'NEW_REPORT':
        case 'REPORT_TREATED':
            return `/skills/${notification.skillName}`;
        default:
            return '';
    }
}

function getNotificationUrlCoachingNotification(notification) {
    switch (notification.type) {
        case 'COACHING_REQUEST':
            return '/coaching';
        case 'COACHING_ACCEPTED':
            return `/excilyens/${notification.coach.id}`;
        default:
            return '';
    }
}

function getNotificationUrlRecruitmentNotification(notification) {
    switch (notification.type) {
        case 'CANDIDATE_TEST_DONE':
        case 'CANDIDATE_VALIDATED':
            return '/recrutement';
        default:
            return '';

    }
}

function getNotificationUrlMicroskillNotification(notification) {
    switch (notification.type) {
        case 'NEW_REPORT':
        default:
            return '';
    }
}

export function getNotificationUrl(notification) {
    switch (notification.class) {
        case 'ModerationNotification':
            return `/skills/${notification.skillName}`;
        case 'QuestionNotification':
            return getNotificationUrlQuestionNotification(notification);
        case 'ChapterNotification':
            return getNotificationUrlChapterNotification(notification);
        case 'CoachGroupNotification':
            return '/coaching';
        case 'CoachingNotification':
            return getNotificationUrlCoachingNotification(notification);
        case 'RecruitmentNotification':
            return getNotificationUrlRecruitmentNotification(notification);
        case 'MicroskillNotification':
            return getNotificationUrlMicroskillNotification(notification);
        default:
            return '';
    }
}

function getNotificationUrlProps(notification) {
    switch (notification.class) {
        case 'RecruitmentNotification':
            return notification.candidate;
        case 'CoachGroupNotification':
            return notification.groupId;
        default:
            return null;
    }
}

function ClocheNotification() {

    const useStyles = makeStyles(() => ({
        itemsStyle: {
            'color': 'black',
            'backgroundColor': '#d3d7f1',
            'margin': '5px',
            'whiteSpace': 'pre-wrap',
            'fontSize': '0.93em',

            '@media screen and (max-width: 599px)': {
                fontSize: '0.8em'
            }
        },
        lessPadding: {
            padding: '6px'
        }
    }));
    const classes = useStyles();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();
    const dispatch = useDispatch();
    const dailyQuestionList = useSelector(selectDailyQuestions);
    const notificationsByDestinataire = useSelector(getNotificationsByDestinataireId);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        dispatch(getDailyQuestionsToDo(getCurrentUserId()));
        dispatch(getNotificationByDestinataireId(getCurrentUserId()));
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const deleteNotification = (notificationId) => {
        dispatch(deleteNotificationById(notificationId));
    };

    const deleteAllNotification = (userId) => {
        dispatch(deleteAllNotificationByUserId(userId));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const formatDate = (dateStr) => format(new Date(dateStr), 'dd-MM-yyyy');


    const NotificationDeleteButton = withStyles({
        root: {
            backgroundColor: 'transparent'
        }
    })(IconButton);

    return (
        <>
            <IconButton
                className={classes.lessPadding}
                aria-label="notifications"
                color={(dailyQuestionList && dailyQuestionList.length) || (notificationsByDestinataire && notificationsByDestinataire.length)
                    ? 'inherit'
                    : 'default'}
                onClick={handleClick}
                size="large"
            >
                <Badge badgeContent={(dailyQuestionList && dailyQuestionList.length) ||
                (notificationsByDestinataire && notificationsByDestinataire.length)
                    ? dailyQuestionList?.length + notificationsByDestinataire?.length
                    : 0} color="secondary" overlap="rectangular">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            {(dailyQuestionList || notificationsByDestinataire) &&
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock
                >
                    {(!notificationsByDestinataire?.length && !dailyQuestionList?.length)
                        ? <MenuItem className={classes.itemsStyle}>
                            Vous n&apos;avez pas de notification.
                        </MenuItem>
                        : <div style={{ height: '25px' }}>
                            <a href="#" style={{ marginRight: '10px',
                                textDecoration: 'underline',
                                float: 'right' }} onClick={() => deleteAllNotification(getCurrentUserId())}> Supprimer toutes les notifications </a>
                        </div>
                    }
                    {(notificationsByDestinataire?.length > 0) &&
                        notificationsByDestinataire.map((notification) => (
                            <div key={notification.id}>
                                <div style={{ display: 'inline-block' }}>
                                    <Link
                                        className="link-serie"
                                        key={notification.id}
                                        to={{
                                            pathname: getNotificationUrl(notification),
                                            candidate: getNotificationUrlProps(notification),
                                            groupId: getNotificationUrlProps(notification)
                                        }}
                                        onClick={() => deleteNotification(notification.id)} style={{ display: 'inline-block' }}
                                    >
                                        <MenuItem className={classes.itemsStyle}>
                                            {getNotificationText(notification)}
                                        </MenuItem>
                                    </Link>
                                    <NotificationDeleteButton
                                        className="deleteNotification"
                                        onClick={() => deleteNotification(notification.id)}
                                        aria-label="supprime la notification"
                                        style={{ backgroundColor: 'transparent' }}
                                        disableRipple>
                                        <HighlightOffIcon />
                                    </NotificationDeleteButton></div>

                            </div>
                        ))
                    }
                    {(dailyQuestionList?.length > 0) &&
                        dailyQuestionList.map((date, id) => (
                            <Link className="link-serie" key={id} to={`/questions/daily/${formatDate(date)}`}>

                                <MenuItem className={classes.itemsStyle} data-testid="notification">
                                    Votre série quotidienne du {formatDate(date)} est disponible.
                                    <br />
                                    Cliquez ici pour accéder à la série.
                                </MenuItem>

                            </Link>
                        ))
                    }
                </Menu>
            }
        </>
    );
}

export default ClocheNotification;
