import React from 'react';
import PropTypes from 'prop-types';
import { AssignTypes } from 'app/utils/functions';
import { useDrop } from 'react-dnd';
import colors from 'styles/colors.module.scss';

function DropZone(props) {    
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: [AssignTypes.PARCOURS],
        drop: (item) => {
            props.handleDrop(item, props.userId, props.groupId, props.parcoursList);
        },
        canDrop: () => true,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
    }
    ), [props.userId, props.parcoursList]);

    const dropStyle = {
        opacity: isOver ? 0.5 : 1,
        backgroundColor: isOver ? colors['drop-zone-parcours'] : 'white',
        cursor: 'move',
        border: `1px dashed ${canDrop ? 'green' : 'orange'}`,
        padding: '8px',
        width: '70%',
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <div style={{ display: 'flex' }}>
            <div ref={(node) => drop(node)} style={{ ...dropStyle }}>
                Déposez un parcours
            </div>
        </div>        
    );
}

DropZone.propTypes = {
    handleDrop: PropTypes.func,
    userId: PropTypes.number,
    groupId: PropTypes.number,
    parcoursList: PropTypes.array
};

export default DropZone;
