import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import './SidePanelQuestionsFilterContainer.scss';
import { setQuestionsAiGeneratedFilter } from
    '../../../redux/actions/Question/QuestionsFilters/questionsFilters.actions';
import { Checkbox } from 'antd';
import { selectQuestionsAiGeneratedFilter } from 'app/redux/selectors/Question/QuestionsFilters/questionsFilters.selector';

function SidePanelQuestionsAiGeneratedFilter(props) {
    const checked = useSelector(selectQuestionsAiGeneratedFilter);

    const handleClickAiGeneratedFilter = () => {
        props.setQuestionsAiGeneratedFilter(!checked);
    };

    return (
        <div className="question-filter" data-testid="side-panel-question-filter-container">
            <Typography className="sub-title-question">
                    Type
            </Typography>
            <div>
                <Checkbox 
                    onClick={ handleClickAiGeneratedFilter }
                    checked={ checked }
                />
                <label style={{ marginLeft:'10px' }} htmlFor="ai_generated">IA en attente de revue</label>
            </div>
        </div>
    );
}

SidePanelQuestionsAiGeneratedFilter.propTypes = {
    setQuestionsAiGeneratedFilter: PropTypes.func
};

function mapStateToProps(state) {
    return {
        isAiGenerated: selectQuestionsAiGeneratedFilter(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setQuestionsAiGeneratedFilter: (filter) => dispatch(setQuestionsAiGeneratedFilter(filter))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelQuestionsAiGeneratedFilter);
