
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isBefore } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Dialog, DialogActions,
    DialogContent, DialogContentText, FormControlLabel,
    DialogTitle, Grid, TextField, Tooltip, makeStyles, Checkbox } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { mapSkillsReducedToSkillsSelect, mapReminderLocalTimeToJSDate,
    mapReminderJSDateToLocalTime } from './DailyQuestionSettings.functions';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/withWidth';
import TimePickerCustomToolbar from './TimePickerCustomToolbar';
import { selectDailyPreferences } from '../../redux/selectors/Question/dailyQuestions.selector';
import { getDailyPreferences, postDailyPreferences } from '../../redux/actions/Question/DailyQuestions/DailyPreferences/dailyPreferences.actions';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import './QuestionTablePage.scss';
import { skillActions } from 'app/redux/slices/skills.slice';
import { languageOptions } from 'app/utils/LanguageUtils';

const animatedComponents = makeAnimated();

const useStyles = makeStyles(() => ({
    dailyQuestionsSettingsButton: {
        minWidth: '0px',
        padding: '6px 8px',
        marginLeft: '1px',
        borderRadius: '0px 8px 8px 0px'
    }
}));

function DailyQuestionSettings(props) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const allReducedSkills = mapSkillsReducedToSkillsSelect(useSelector(selectAllReducedSkills).data);
    const dailyPreferencesApiDTO = useSelector(selectDailyPreferences);

    const newDefaultReminders = (hour) => {
        const defaultReminder = new Date();
        defaultReminder.setHours(hour, 0);
        return defaultReminder;
    };

    const [newDailyPreferences, setNewDailyPreferences] = useState({
        firstReminder: newDefaultReminders(12),
        secondReminder: newDefaultReminders(18),
        questionsSkills: [],
        numberOfQuestions: 5,
        sendReminders: true,
        language: languageOptions[0]
    });

    const [cachedDailyPreferences, setCachedDailyPreferences] = useState({
        ...newDailyPreferences
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const resetDailyPreferencesFromCached = () => {
        setNewDailyPreferences({
            ...cachedDailyPreferences
        });
    };

    const openDialog = () => {
        resetDailyPreferencesFromCached();
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const submitDailyQuestionsSettings = () => {
        const userDailyPreferenceToAddOrUpdate = {
            userApiDto: { id: Number(getCurrentUserId()) },
            firstReminder: mapReminderJSDateToLocalTime(newDailyPreferences.firstReminder),
            secondReminder: mapReminderJSDateToLocalTime(newDailyPreferences.secondReminder),
            skillFilters: newDailyPreferences.questionsSkills
                ? newDailyPreferences.questionsSkills.map((skillSelect) => ({
                    skillId: skillSelect.value
                }))
                : [],
            numberOfQuestions: newDailyPreferences.numberOfQuestions,
            sendReminders: newDailyPreferences.sendReminders,
            languageApiDto: { acronym: newDailyPreferences.language.value,
                linkPictureFlag: newDailyPreferences.language.label.props.src }
        };

        if(dailyPreferencesApiDTO && dailyPreferencesApiDTO.id) {
            // Cas de mise à jour
            dispatch(postDailyPreferences({ ...userDailyPreferenceToAddOrUpdate,
                id: dailyPreferencesApiDTO.id }));
        } else {
            // Cas d'ajout
            dispatch(postDailyPreferences(userDailyPreferenceToAddOrUpdate));
        }
        closeDialog();
    };

    const isFirstBeforeSecond = () => (
        isBefore(newDailyPreferences.secondReminder, newDailyPreferences.firstReminder)
    );

    const displayFirstReminderHelperText = () => {
        let helpText = '';
        if(isFirstBeforeSecond()) {
            helpText = helpText.concat('Le premier rappel doit être avant le second rappel');
        }
        if(newDailyPreferences.firstReminder.getHours() < 7) {
            if(helpText === '') {
                helpText = helpText.concat('Le premier rappel doit être après 7h');
            } else {
                helpText = helpText.concat(' et après 7h');
            }
        }
        if(helpText !== '') {
            helpText = helpText.concat('.');
        }
        return helpText;
    };

    const displaySecondReminderHelperText = () => {
        let helpText = '';
        if(isFirstBeforeSecond()) {
            helpText = helpText.concat('Le second rappel doit être après le premier rappel');
        }
        if(newDailyPreferences.secondReminder.getHours() > 23) {
            if(helpText === '') {
                helpText = helpText.concat('Le second rappel doit être avant 23h');
            } else {
                helpText = helpText.concat(' et avant 23h');
            }
        }
        if(helpText !== '') {
            helpText = helpText.concat('.');
        }
        return helpText;
    };

    const isNumberOfQuestionsIncorrect = () => newDailyPreferences.numberOfQuestions < 5 || newDailyPreferences.numberOfQuestions > 100;

    const isFirstReminderIncorrect = () => (isFirstBeforeSecond() || newDailyPreferences.firstReminder.getHours() < 7);

    const isSecondReminderIncorrect = () => (isFirstBeforeSecond() || newDailyPreferences.secondReminder.getHours() > 23);

    useEffect(() => {
        dispatch(getDailyPreferences(getCurrentUserId()));
        dispatch(skillActions.getAllReducedSkills());
    }, []);

    useEffect(() => {
        if(dailyPreferencesApiDTO) {
            if(dailyPreferencesApiDTO.id) {
                setCachedDailyPreferences((prev) => ({
                    ...prev,
                    firstReminder: mapReminderLocalTimeToJSDate(dailyPreferencesApiDTO.firstReminder),
                    secondReminder: mapReminderLocalTimeToJSDate(dailyPreferencesApiDTO.secondReminder),
                    numberOfQuestions: dailyPreferencesApiDTO.numberOfQuestions,
                    sendReminders: dailyPreferencesApiDTO.sendReminders,
                    language: { value: dailyPreferencesApiDTO.languageApiDto.acronym,
                        label: <img src={dailyPreferencesApiDTO.languageApiDto.linkPictureFlag} width="36" height="24"/> }
                }));
            }
        }
    }, [dailyPreferencesApiDTO]);

    useEffect(() => {
        if(allReducedSkills && allReducedSkills.length !== 0 &&
            dailyPreferencesApiDTO && dailyPreferencesApiDTO.skillFilters && dailyPreferencesApiDTO.skillFilters.length !== 0 &&
            newDailyPreferences.questionsSkills && newDailyPreferences.questionsSkills.length === 0) {
            let dailyQuestionsSkillsCopy = dailyPreferencesApiDTO.skillFilters.flatMap((skill) => skill.skillId);
            dailyQuestionsSkillsCopy = dailyQuestionsSkillsCopy.flatMap((skillId) => allReducedSkills
                .filter((skillReduced) => skillReduced.value === skillId));
            setNewDailyPreferences((prev) => ({
                ...prev,
                questionsSkills: [...dailyQuestionsSkillsCopy]
            }));
        }
    }, [allReducedSkills]);

    return (
        <>
            <Tooltip
                title="Paramétrer les séries quotidiennes">
                <Button className={classes.dailyQuestionsSettingsButton}
                    aria-label="Paramétrage série quotidienne"
                    color="primary"
                    variant="contained"
                    onClick={openDialog}>
                    <Settings/>
                </Button>
            </Tooltip>
            <Dialog
                onClose={closeDialog}
                open={isDialogOpen}
                aria-labelledby="simple-dialog-title"
                scroll={'body'}
                disableEnforceFocus={true}
                maxWidth="md"
                fullWidth={true}
                fullScreen={false}
                PaperProps={{ style: { overflowY: 'visible' } }}>
                <DialogTitle className="dialogDailySettingsTitle">
                    Paramètres de la série quotidienne
                </DialogTitle>
                <DialogContent dividers style={{ overflowY: 'visible', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Grid container justifyContent="flex-start" spacing={4}>
                        <Grid item xs={9}>
                            <DialogContentText>Thèmes de la série</DialogContentText>
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="daily-preferences-skills"
                                placeholder="Compétences"
                                noOptionsMessage={() => 'Aucune compétence trouvée.'}
                                isSearchable={true}
                                isClearable={false}
                                isMulti={true}
                                backspaceRemovesValue={false}
                                components={animatedComponents}
                                options={allReducedSkills}
                                value={newDailyPreferences.questionsSkills}
                                onChange={(updatedSkillsPreferences) => setNewDailyPreferences((prev) => ({
                                    ...prev,
                                    questionsSkills: updatedSkillsPreferences
                                }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DialogContentText>Langue des questions</DialogContentText>
                            <Select className="selectLanguage"
                                dropdownClassName="selectLanguage"
                                name={'langue'}
                                options={languageOptions}
                                placeholder={'Langue'}
                                showSearch
                                value={newDailyPreferences.language}
                                onChange={(updatedLanguage) => setNewDailyPreferences((prev) => ({
                                    ...prev,
                                    language: updatedLanguage
                                }))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    label="Activer l'envoi de mails de rappel"
                                    checked={newDailyPreferences.sendReminders}
                                    onChange={() => setNewDailyPreferences((prev) => ({
                                        ...prev,
                                        sendReminders: !prev.sendReminders
                                    }))}
                                />
                            }
                            label="Activer l'envoi de mails de rappel"
                        />
                    </Grid>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12} sm={4} style={{ marginBottom: '10px' }}>
                            <TextField
                                type="number"
                                id="Nombre de questions"
                                name="Nombre de questions"
                                label={isWidthUp('sm', props.width) ? 'Nombre de questions (entre 5 et 100)' : 'Nombre de questions (5 à 100)'}
                                aria-label="Nombre de questions (entre 5 et 100)"
                                value={newDailyPreferences.numberOfQuestions}
                                InputProps={{ inputProps: { min: 5, max: 100 } }}
                                fullWidth
                                onChange={(event) => setNewDailyPreferences((prev) => ({
                                    ...prev,
                                    numberOfQuestions: event.target.value
                                }))}
                                error={isNumberOfQuestionsIncorrect()}/>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} sm={4}>
                                <TimePicker
                                    id="Premier rappel"
                                    name="Premier rappel"
                                    label="Premier rappel"
                                    aria-label="Heure premier rappel"
                                    InputLabelProps={{ shrink: true }}
                                    ampm={false}
                                    cancelLabel="Annuler"
                                    okLabel="Valider"
                                    fullWidth
                                    views={['hours']}
                                    minutesStep={60}
                                    value={newDailyPreferences.firstReminder}
                                    ToolbarComponent={TimePickerCustomToolbar}
                                    onChange={(time) => setNewDailyPreferences((prev) => ({
                                        ...prev,
                                        firstReminder: time
                                    }))}
                                    error={isFirstReminderIncorrect()}
                                    helperText={displayFirstReminderHelperText()}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TimePicker
                                    id="Second rappel"
                                    name="Second rappel"
                                    label="Second rappel"
                                    aria-label="Heure second rappel"
                                    InputLabelProps={{ shrink: true }}
                                    ampm={false}
                                    cancelLabel="Annuler"
                                    okLabel="Valider"
                                    fullWidth
                                    views={['hours']}
                                    minutesStep={60}
                                    value={newDailyPreferences.secondReminder}
                                    ToolbarComponent={TimePickerCustomToolbar}
                                    onChange={(time) => setNewDailyPreferences((prev) => ({
                                        ...prev,
                                        secondReminder: time
                                    }))}
                                    error={isSecondReminderIncorrect()}
                                    helperText={displaySecondReminderHelperText()}/>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Annuler
                    </Button>
                    <Button
                        color="primary"
                        disabled={isFirstReminderIncorrect() || isSecondReminderIncorrect() || isNumberOfQuestionsIncorrect()}
                        onClick={submitDailyQuestionsSettings}>
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

DailyQuestionSettings.propTypes = {
    width: PropTypes.string
};

export default (withWidth()(DailyQuestionSettings));

