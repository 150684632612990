import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, IconButton, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import HistoryIcon from '@material-ui/icons/History';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Select from 'react-select';

import { createCoachProfileApi, getAllProfileApi, updateCoachProfileApi } from 'app/api/coachProfileApi';

import { addChaptersToSkillView } from 'app/redux/actions/Chapter/chapter.actions';
import { getGroupsStatsByProfile } from 'app/redux/actions/Group/group.actions';

import { selectQuestionCountByChapter, selectSelectedChaptersSkillView } from 'app/redux/selectors/Chapter/chapter.selector';
import { selectStudents } from 'app/redux/selectors/Group/group.selector';

import ChapterButtonBecomeModerator from 'app/skill/components/SkillContent/Chapter/ChapterDisplay/ChapterHeader/ChapterButtonBecomeModerator/ChapterButtonBecomeModerator';
import ChapterCoachProfileDialog from 'app/skill/components/SkillContent/Chapter/ChapterDisplay/ChapterHeader/ChapterCoachProfileDialog';
import { chapterActions } from 'app/redux/slices/chapters.slice';
import { createChapterTree } from 'app/utils/treeStructure/TreeChapter.logic';
import { getQuestionsDoneAndGoodAnswers } from 'app/redux/actions/ChapterStats/chapterStats.actions';

const useStyles = makeStyles(() => ({
    actions: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    studentSelect: {
        marginLeft: '8px',
        minWidth: '170px',
        width: '170px',
        position: 'relative',
        zIndex: 2
    },
    searchFieldContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 0
    },
    searchField: {
        flex: 1,
        zIndex: 0
    },
    loader: {
        marginLeft: '8px'
    }
}));

function ChapterCoaching(props) {

    const { data: chapters } = props.chapters;
    const noProfile = { id: 0, name: '<No profile selected>' };

    const dispatch = useDispatch();
    const classes = useStyles();

    const selectedChapters = useSelector(selectSelectedChaptersSkillView);
    const studentGroupList = useSelector(selectStudents);
    const { loading: isSearching } = useSelector(selectQuestionCountByChapter);

    const [isOpen, setIsOpen] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(noProfile);
    const [searchStatement, setSearchStatement] = useState('');
    const [chapterTree, setChapterTree] = useState(null);

    useEffect(() => {
        getAllProfileApi(props.userId, props.skill.id).then((response) => setProfileList([noProfile, ...response.data]));
    }, []);

    useEffect(() => {
        setChapterTree(createChapterTree(chapters));
    }, [chapters]);

    useEffect(() => {
        if(props.type === 'student') {
            setSelectedProfile(noProfile);
        }
    }, [props.type]);

    const handleOpenDialog = () => {
        props.openDialog({
            skillId: props.skill.id,
            position: props.chapters.data.filter((chap) => chap.parentPath === '/').length + 1
        });
    };

    const handleProfileCreate = (newProfile) => {
        createCoachProfileApi(newProfile).then((response) => {
            setProfileList((prev) => [...prev.filter((profile) => profile.id !== selectedProfile.id), response.data, noProfile]);
            setSelectedProfile(response.data);
        });
    };

    const handleProfileSave = () => {
        if(selectedProfile.id) {
            const profileToUpdate = { ...selectedProfile, chapters: selectedChapters };
            updateCoachProfileApi(profileToUpdate).then((response) => setProfileList((prev) => [...prev.filter((profile) => profile.id !== selectedProfile.id), response.data]));
        } else {
            setIsOpen(true);
        }
    };

    const getQuestionsByStatement = () => {
        if(searchStatement) {
            dispatch(chapterActions.searchQuestionCountByChapterBySkillId({ skillId: props.skill.id, search: searchStatement }));
        } else {
            const rootChapterIds = chapterTree.root.children.map((chapter) => chapter.content.id);
            if(rootChapterIds.length) {
                dispatch(getQuestionsDoneAndGoodAnswers(rootChapterIds, props.userId, ''));
            }
        }
        dispatch(chapterActions.updateFilter(searchStatement));
    };  
    const handleStatementSearch = (event) => {
        setSearchStatement(event.target.value);
    }; 

    const historyTooltipText = () => props.displayGroupTab ? 'Voir la vue Cours' : 'Voir la vue Historique';

    return (
        <>
            { props.groupSelected &&
                <>
                    <Select
                        className={classes.studentSelect}
                        onChange={(profile) => {
                            setSelectedProfile(profile);
                            if(profile.id) {
                                props.setDataWithProfile();
                                dispatch(getGroupsStatsByProfile(
                                    studentGroupList.map((user) => user.id),
                                    profile.chapters.map((chapter) => chapter.id)
                                ));
                                dispatch(addChaptersToSkillView(profile.chapters));
                            }
                        }}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        options={profileList}
                        value={selectedProfile}
                    >
                    </Select>
                    <IconButton
                        onClick={handleProfileSave}
                    >
                        <SaveIcon />
                    </IconButton>
                </>
            }
            {!props.permission &&
                <ChapterButtonBecomeModerator
                    skill={props.skill}
                />
            }
            <div className={classes.actions} style={{ margin: '8px' }}>
                <div className={classes.searchFieldContainer}>
                    <TextField 
                        id="outlined-search" 
                        label="Rechercher une question" 
                        type="search" 
                        variant="outlined" 
                        margin="dense" 
                        onChange={handleStatementSearch}
                        className={classes.searchField}
                    /> 
                    {
                        isSearching ?
                            <div>
                                <CircularProgress className={classes.loader}/>
                            </div>
                            :
                            <IconButton style={{ marginTop: '4px' }} onClick={getQuestionsByStatement}>
                                <SearchIcon/> 
                            </IconButton>
                    }
                </div>
                <div style={{ marginTop: '4px' }}>
                    {props?.type === 'group' &&
                        <Tooltip title={ historyTooltipText() }>
                            <IconButton
                                onClick={() => props.setDisplayGroupTab(!props.displayGroupTab)}
                            >
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {props.permission &&
                        <Tooltip title="Ajouter un chapitre">
                            <IconButton
                                onClick={handleOpenDialog}
                            >
                                <AddCircleOutline />
                            </IconButton>
                        </Tooltip>
                    }

                    <Tooltip title="Fermer tous les chapitres">
                        <IconButton
                            onClick={props.resetTree}
                        >
                            <IndeterminateCheckBoxIcon />
                        </IconButton>
                    </Tooltip></div>
            </div>
            <ChapterCoachProfileDialog
                addNewProfile={(profile) => handleProfileCreate(profile)}
                chapters={selectedChapters}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                profileList={profileList}
                skillId={props.skill.id}
            />
        </>
    );
}

ChapterCoaching.propTypes = {
    chapters: PropTypes.object,
    displayGroupTab: PropTypes.bool,
    groupSelected: PropTypes.object,
    openDialog: PropTypes.func,
    permission: PropTypes.bool,
    resetTree: PropTypes.func,
    setDataWithProfile: PropTypes.func,
    setDisplayGroupTab: PropTypes.func,
    skill: PropTypes.object,
    type: PropTypes.string,
    userId: PropTypes.number
};

export default ChapterCoaching;
