import axiosClient from './utils/axiosClient';

export const QUESTION_URI = 'questions';

export function getQuestionsDisplayPaginatedApi(paginationCriteria) {
    return axiosClient.post(`${QUESTION_URI}/paginated`, paginationCriteria);
}

export function getQuestionByIdWithoutExplanationApi(questionId) {
    return axiosClient.get(`${QUESTION_URI}/asked/${questionId}`);
}

export function getQuestionsCountByChapterIdApi(chapterId, withChildren, language) {
    return axiosClient.get(`${QUESTION_URI}/chapter/${chapterId}/count?withChildren=${withChildren}&language=${language}`);
}

export function getQuestionsCountByChapterIdsApi(chapterIds, searchStatement) {
    return axiosClient.get(`${QUESTION_URI}/countByChapter?chapterIds=${chapterIds}&search=${searchStatement}`);
}

export function searchQuestionsCountByChaptersInSkillApi(skillId, searchStatement, userId) {
    return axiosClient.get(`${QUESTION_URI}/countByChapter/skills/${skillId}/users/${userId}?search=${searchStatement}`);
}

// Requete pour récupérer un qcm complet après avoir envoyé la liste des réponses sélectionnées
export function putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi(answersSelected, candidateUuid) {
    if(candidateUuid) {
        return axiosClient.put(
            `${QUESTION_URI}/questionResponse/candidate/${candidateUuid}`,
            answersSelected
        );
    }
    return axiosClient.put(`${QUESTION_URI}/questionResponse/question`, answersSelected);
}

export function putAnswerRetrieveQuestionHistoryByIdWithExplanationApi(answersSelected) {
    return axiosClient.put(`${QUESTION_URI}/questionResponse/history`, answersSelected);
}

export function getQuestionByIdApi(questionId) {
    return axiosClient.get(`${QUESTION_URI}/${questionId}`);
}

export function getQuestionsByChapterIdAndUserIdApi(chapterId, userId, searchStatement) {
    return axiosClient.get(`${QUESTION_URI}/chapter/${chapterId}?userId=${userId}&search=${searchStatement}`);
}
