import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import './TreeItemLabel.scss';

import ChapterValidationState from '../ChapterValidationState/ChapterValidationState';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { useStyles } from './ChapterTreeDisplay.functions';
import { Checkbox, IconButton, ListItemText, useMediaQuery, useTheme } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import BookmarkButton from '../BookmarkButton';
import { useDispatch, useSelector } from 'react-redux';
import { addChapterToSkillView } from 'app/redux/actions/Chapter/chapter.actions';
import { selectSelectedChaptersSkillViewPresent, selectStudent } from 'app/redux/selectors/Chapter/chapter.selector';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { selectChapterStats } from 'app/redux/selectors/ChapterStats/chapterStats.selector';
import { Chapter } from 'app/redux/models/Chapter.model';

interface TreeItemLabelProps {
    chapter: Chapter;
    skillId: number;
    chapterValidationState?: string;
    isInsideCurrent?: boolean;
    permission?: boolean;
    selectedGroup?: any;
    depth: number;
    handleContextMenu(event: MouseEvent<Element>): void;
    drag?: any;
    userId: number;
    isBookmarked: boolean;
    bookmark: any;
    isInDrawer?: boolean;
    questionCount: number;
}

export const TreeItemLabel = (props: TreeItemLabelProps) => {

    const isViewed = useSelector(selectSelectedChaptersSkillViewPresent(props.chapter.id));
    const studentSelected = useSelector(selectStudent);

    const [isChecked, setIsChecked] = useState(isViewed);

    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const defineStyle = (depth: number) => {
        switch (depth) {
            case 1:
                return 900;
            case 2:
                return 450;
            case 3:
                return 400;
            case 4:
                return 300;
            default:
                return 100;
        }
    };

    const handleButtonClick = () => {
        dispatch(addChapterToSkillView(props.chapter));
    };

    useEffect(() => {
        setIsChecked(isViewed);
    }, [isViewed]);

    const [percentGoodAnswer, setPercentGoodAnswer] = useState(0);


    const chaptersStats = useSelector(selectChapterStats);
    const userId = parseInt(AuthenticationContainer.getCurrentUserId() ?? '0', 10);
    const stats = useMemo(() => chaptersStats?.get(studentSelected?.id ?? userId)?.get(props.chapter.id), [chaptersStats?.get(studentSelected?.id ?? userId)?.get(props.chapter.id)]);

    useEffect(() => {
        if(chaptersStats?.size !== 0 && stats?.questionsAnswered !== 0) {
            const percentGoodAnswerMCQ = Math.round(stats?.corrects / stats?.questionsAnswered * 100);
            if(stats?.averageGrade > 0) {
                setPercentGoodAnswer(stats.averageGrade);
            } else if(stats?.corrects > 0) {
                setPercentGoodAnswer(percentGoodAnswerMCQ);
            } else {
                setPercentGoodAnswer(0);
            }
        } else {
            setPercentGoodAnswer(0);
        }
    }, [chaptersStats]);

    return (
        <div
            className="tree-item-label"
            ref={props.drag}
            style={{ cursor: 'move' }}
            onContextMenu={props.handleContextMenu}
            data-testid={`chapter-${props.chapter.id}-label`}
        >
            <div style={{ display: 'inline-flex' }}>
                <ChapterValidationState validation={props.chapterValidationState} />
            </div>
            <ListItemText
                primaryTypographyProps={{
                    noWrap: true,
                    style: {
                        fontWeight: defineStyle(props.depth),
                        textOverflow: 'ellipsis',
                        paddingRight: '15%'
                    }
                }}
                primary={props.chapter.name}
            />
            {stats && !props.isInDrawer && !props.selectedGroup &&
                <div className="percentage-reponses">
                    {stats?.questionsAnswered}/
                    {props.questionCount} ({percentGoodAnswer}%)
                </div>
            }
            {props.isInsideCurrent &&
                <PlaylistAddIcon className={classes.dropInChapterIcon} />
            }

            <div>
                {props.selectedGroup
                    ? <Checkbox
                        checked={isChecked}
                        onChange={handleButtonClick}
                        color="primary"
                        style={{ padding: 4 }}
                    />
                    : <BookmarkButton
                        chapterName={props.chapter.name}
                        chapterId={props.chapter.id}
                        skillId={props.skillId}
                        isBookmarked={props.isBookmarked}
                        bookmark={props.bookmark}
                    />
                }
                {(!sizeUpMd || props.permission) &&
                    <IconButton edge="end" onClick={props.handleContextMenu} style={{ padding: 4, marginRight: 12 }} >
                        <MoreVert/>
                    </IconButton>
                }
            </div>
        </div>
    );
};
