import React, { useEffect, useState } from 'react';
import './TasksDrawer.scss';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Drawer, IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { closeDrawer } from 'app/redux/actions/Drawer/drawer.actions';
import { getGroupsByCoachId } from 'app/redux/actions/Group/group.actions';
import { getStudentsByCoachId } from 'app/redux/actions/Coaching/coaching.actions';

import { selectSerieToResolve } from 'app/redux/selectors/Question/serie.selector';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import QuestionListSummary from 'app/serie/ResolveSerie/QuestionListSummary/QuestionListSummary';
import UserLearning from 'app/common/userLearning/UserLearning';
import { drawerWidth } from 'app/layout/Layout';

import { OPTIONS } from 'app/common/userLearning/constants';
import { selectCurrentSkill } from 'app/redux/selectors/Skill/SkillSet/skillSet.selector';
import { setOptionsChapterDisplay, setStudentSelectedStore } from 'app/redux/actions/Chapter/chapter.actions';
import { getSkillReducedByQuestionIdApi } from 'app/api/skillSetApi';
import { SKILL_PAGE_URL } from 'app/Routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const optionListNonAdmin = [
    {
        label: OPTIONS.TRAVAIL_A_FAIRE_DE,
        value: 1
    },
    {
        label: OPTIONS.TRAVAIL_FAIT_PAR,
        value: 2
    },
    {
        label: OPTIONS.LISTE_DES_COMPETENCES,
        value: 3
    },
    {
        label: OPTIONS.PARCOURS,
        value: 5
    }
];

const optionListInSerie = [
    ...optionListNonAdmin,
    {
        label: OPTIONS.SERIE_EN_COURS,
        value: 4
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: `${drawerWidth}px`,
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    }
}));

function TaskDrawer(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const getCurrentUserFirstName = () => AuthenticationContainer.getGivenName();
    const getCurrentUserId = () => Number(AuthenticationContainer.getCurrentUserId());
    const getCurrentUserLastName = () => AuthenticationContainer.getFamilyName();

    const serieToResolve = useSelector(selectSerieToResolve);
    const currentSkill = useSelector(selectCurrentSkill);

    const [drawerOptionDisplay, setDrawerOptionDisplay] = useState(optionListNonAdmin[0]);
    const [groupSelected, setGroupSelected] = useState(null);
    const [optionList, setOptionList] = useState(optionListNonAdmin);
    const [studentSelected, setStudentSelected] = useState({
        name: `${getCurrentUserFirstName()} ${getCurrentUserLastName()}`,
        id: getCurrentUserId(),
        type: 'student'
    });

    useEffect(() => {
        if(!props.isCandidateHeader) {
            dispatch(getStudentsByCoachId(getCurrentUserId()));
            dispatch(getGroupsByCoachId(getCurrentUserId()));
        }
    }, []);

    useEffect(() => {
        if(serieToResolve) {
            setOptionList(optionListInSerie);
            setDrawerOptionDisplay(optionListInSerie[4]);
        } else {
            setOptionList(optionListNonAdmin);
            setDrawerOptionDisplay(optionListNonAdmin[0]);
        }
    }, [serieToResolve]);

    const handleQuestionHistorySelect = (question) => {
        if(currentSkill?.id !== question.skillId) {
            dispatch(setOptionsChapterDisplay(2));
            getSkillReducedByQuestionIdApi(question.id).then((response) => {
                const url = `${SKILL_PAGE_URL}/${response.data.name}`;
                history.push(url);
            });
        }
    };

    const handleChangeDrawerOptionDisplay = (option) => {
        if(option?.label === OPTIONS.LISTE_DES_COMPETENCES) {
            dispatch(setStudentSelectedStore(studentSelected));
        }
        setDrawerOptionDisplay(option);
    };

    const handleTaskDrawerClose = () => {
        dispatch(closeDrawer());
    };

    const chevronLeft =
        <IconButton
            data-testid="close-button"
            edge="end"
            onClick={handleTaskDrawerClose}
        >
            <ChevronLeft />
        </IconButton>;

    const requiredUserLearningProps = {
        studentSelected,
        optionList,
        optionSelected: drawerOptionDisplay,
        isInteractiveQuiz: props.isInteractiveQuiz,
        userId: getCurrentUserId(),
        fromTaskDrawer: true
    };

    return (
        <>
            <Drawer
                variant="persistent"
                anchor="left"
                open={props.open}
            >
                <div className={classes.root}>
                    {props.isCandidateHeader
                        ? <QuestionListSummary />
                        : <>
                            <UserLearning
                                chevronLeft={chevronLeft}
                                type="tasks"
                                handleChangeDrawerOptionDisplay={handleChangeDrawerOptionDisplay}
                                groupSelected={groupSelected}
                                options={requiredUserLearningProps}
                                setGroupSelected={setGroupSelected}
                                setStudentSelected={setStudentSelected}
                                setType={() => {}}
                                onClickQuestionHistory={handleQuestionHistorySelect}
                            />
                        </>
                    }
                </div>
            </Drawer>
        </>
    );
}

TaskDrawer.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    isCandidateHeader: PropTypes.bool,
    isInteractiveQuiz: PropTypes.bool,
    open: PropTypes.bool
};

export default TaskDrawer;
