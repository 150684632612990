import { Collapse,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Paper,
    TextField,
    Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import colors from 'styles/colors.module.scss';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { displayAnswerHelperText } from '../../CreateEditQuestionBody.functions';
import './TrouCard.scss';
import { questionTypeEnum } from 'app/utils/QuestionTypeEnum';

const useStyles = makeStyles(() => ({
    clickable: {
        cursor: 'pointer'
    },
    clickableToggleCollapse: {
        cursor: 'pointer',
        paddingLeft: '0 !important'
    },
    subheaderText: {
        color: colors['grey-color-400'],
        fontStyle: 'italic',
        paddingTop: '15px'
    },
    toggleCollapse: {
        paddingTop: '15px'
    }
}));

export default function TrouCard({ trou, addAnswerAccepted, deleteAcceptedAnswers, deleteTrou, handleAcceptedAnswerTextChange }) {
    const classes = useStyles();
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCollapse = () => {
        setIsCollapsed((prev) => !prev);
    };


    return (
        <Paper className="trouCard">
            <Grid container className="trouHeader">
                <Grid id="clickable" className={classes.clickable} onClick={handleCollapse}>
                    <h3 className="groupSpacing">Trou {trou.ordinal}</h3>
                </Grid>
                <div className="action">
                    <Tooltip title="Supprimer ce trou">
                        <IconButton
                            onClick={() => deleteTrou(trou.ordinal)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Grid className={classes.clickableToggleCollapse} onClick={handleCollapse}>
                        <div className={classes.toggleCollapse}>
                            {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </div>
                    </Grid>
                </div>
            </Grid>
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit className={classes.collapse}>
                <div className="trouCardContainer">
                    {trou.acceptedAnswers?.map((answerAccepted, index) => <div className="answerAcceptedLine" key={index}>
                        <div className="text">
                            <TextField
                                multiline fullWidth
                                value={answerAccepted}
                                helperText={
                                    <>
                                        255 caractères maximum <br />
                                        {displayAnswerHelperText(answerAccepted, questionTypeEnum.QuestionTexteATrouType)}
                                    </>
                                }
                                variant="outlined"
                                onChange={(event) => handleAcceptedAnswerTextChange(event, trou.ordinal, index)}
                                // Doc https://mui.com/material-ui/api/text-field/
                                inputProps={{ maxLength: 255 }}
                                InputProps={{ endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            className="deleteAnswerAccepted"
                                            disabled={trou.acceptedAnswers.length < 2}
                                            onClick={() => deleteAcceptedAnswers(trou.ordinal, index)}
                                            aria-label="supprime la reponse"
                                            style={{ backgroundColor: 'transparent' }}
                                            disableRipple
                                            data-testid="remove-accepted-answer-button"
                                            cy-data="create-question-delete-answer">
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </InputAdornment> }}
                            />
                        </div>
                    </div>)}
                    <div className="addAnswerButtonContainer">
                        <IconButton
                            aria-label="ajoute une réponse"
                            disableRipple
                            onClick={() => addAnswerAccepted(trou.ordinal)}
                            style={{ backgroundColor: 'transparent' }}
                            cy-data="create-question-add-answer"
                            data-testid="add-accepted-answer">
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    </div>
                </div>
            </Collapse>
        </Paper>
    );
}

TrouCard.propTypes = {
    trou: PropTypes.object.isRequired,

    addAnswerAccepted: PropTypes.func.isRequired,
    deleteAcceptedAnswers: PropTypes.func.isRequired,
    deleteTrou: PropTypes.func.isRequired,
    handleAcceptedAnswerTextChange: PropTypes.func.isRequired
};
