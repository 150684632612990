/* eslint-disable prefer-regex-literals */
/* eslint-disable require-unicode-regexp */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createCandidate } from 'app/redux/actions/Candidate/candidate.actions';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { CANDIDATE_BAD_FORMAT } from 'app/Snackbar/NotificationMessages';


export const DIALOG_TITLE = 'Veuillez entrer les informations du candidat';

export default function CandidateDialog(props) {

    const dispatch = useDispatch();

    const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

    const emptyCandidate = {
        firstName: '',
        lastName: '',
        email: ''
    };

    const [candidate, setCandidate] = useState(emptyCandidate);

    const resetCandidate = () => {
        setCandidate(emptyCandidate);
    };

    const isValidForm = (candidate.firstName && candidate.lastName && candidate.email && emailRegex.test(candidate.email));

    const emailError = (candidate.email && !emailRegex.test(candidate.email));

    const handleSubmit = (event) => {
        event.preventDefault();
        if(isValidForm) {
            dispatch(createCandidate({
                ...candidate,
                recruiterId: props.currentUserId
            }));
        } else {
            dispatch(notificationError(CANDIDATE_BAD_FORMAT));
        }
        resetCandidate();
        props.onClose();
    };

    const handleCandidateChange = (event) => {
        const { name, value } = event.target;
        setCandidate((state) => ({
            ...state,
            [name]: value
        }));
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label="Prénom du candidat"
                    type="text"
                    fullWidth
                    autoFocus
                    required
                    variant="standard"
                    onChange={handleCandidateChange}
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label="Nom du candidat"
                    type="text"
                    fullWidth
                    required
                    variant="standard"
                    onChange={handleCandidateChange}
                />
                <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="Mail du candidat"
                    type="email"
                    error={emailError}
                    fullWidth
                    required
                    helperText = {emailError ? 'Email non valide.' : ''}
                    variant="standard"
                    onChange={handleCandidateChange}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onClose}
                >
                    Annuler
                </Button>
                <Button
                    disabled={!isValidForm}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CandidateDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentUserId: PropTypes.number.isRequired
};
