export const QuestionAskedEnum = {
    MultipleChoice: 'MultipleChoiceQuestionAsked',
    TexteATrou: 'QuestionTexteATrouAsked',
    QuestionOuverte: 'QuestionOuverteAsked'
};

export const QuestionEnum = {
    MultipleChoice: 'MultipleChoiceQuestion',
    TexteATrou: 'QuestionTexteATrou',
    QuestionOuverte: 'QuestionOuverte'
};

export const QuestionApiDtoEnum = {
    MultipleChoice: 'MultipleChoiceQuestionAnswerSelectedApiDto',
    TexteATrou: 'QuestionTexteATrouAnswerSelectedApiDto',
    QuestionOuverte: 'QuestionOuverteAnswerSelectedApiDto'
};

export const QuestionLayouts = {
    ANSWERS_COLUMN: 'ANSWERS_COLUMN',
    ANSWERS_GRID: 'ANSWERS_GRID',
    ANSWERS_RIGHT: 'ANSWERS_RIGHT',
};

export const QuestionAiGenerated = {
    WAITING_REVIEW: 'WAITING_REVIEW',
    VERIFIED: 'VERIFIED'
};

