import React from 'react';
import PropTypes from 'prop-types';
import './AddToGroupPanel.scss';
import AddToGroupTable from './AddToGroupTable/AddToGroupTable';
import CloseIcon from '@material-ui/icons/Close';


export default function AddToGroupPanel(props) {

    return (
        <div className="add-to-group-panel">
            <button
                className="close-panel-button"
                onClick={() => props.setTrigger()}>
                <CloseIcon className="close-btn"/>
            </button>
            <AddToGroupTable
                addToGroup={props.addToGroup}
                previouslyAdded={props.previouslyAdded}
                dispatchPersonGroup={ props.dispatchPersonGroup }
            />
            {props.children}
        </div>
    );
}

AddToGroupPanel.propTypes = {
    addToGroup: PropTypes.string,
    setTrigger: PropTypes.func,
    children: PropTypes.string,
    previouslyAdded: PropTypes.array,

    dispatchPersonGroup: PropTypes.func.isRequired
};


