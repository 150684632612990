/* eslint-disable max-lines */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { AssignTypes } from 'app/utils/functions';
import PlayChapterQuestionsRequest from 'app/skill/components/SkillContent/Chapter/PlayChapterQuestions/PlayChapterQuestionsRequest';
import { getRandomSerieByChapterIdApi, getSerieByIdWithQuestionReducedApi } from 'app/api/seriesApi';
import { addCustomTaskApi } from 'app/api/taskApi';
import { notificationError, notificationSuccess, notificationWarning } from 'app/redux/actions/Notification/notifications.actions';
import {
    POST_TASKS_TO_STUDENT_ERROR,
    POST_TASKS_TO_STUDENT_SUCCESS,
    ADD_TASK_TO_LIST_WARNING_DUPLICATE,
    SERIE_ADDED, ADD_SERIE_ERROR, SERIE_UPDATED, UPDATE_SERIE_ERROR
} from 'app/Snackbar/NotificationMessages';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';

import './DrawerHistoryTaskCreation.scss';
import Save from '@material-ui/icons/Save';
import { postSerieWithQuestionReducedApi, putSerieWithQuestionReducedApi } from 'app/api/seriesSetApi';
import { selectCurrentSkill } from 'app/redux/selectors/Skill/SkillSet/skillSet.selector';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { selectReducedChapters, selectStudent } from 'app/redux/selectors/Chapter/chapter.selector';
import { selectQuestionsChaptersFilter, selectQuestionsSkillsFilter } from 'app/redux/selectors/Question/QuestionsFilters/questionsFilters.selector';
import { Select, TreeSelect } from 'antd';
import { createChapterTree } from 'app/utils/treeStructure/TreeChapter.logic';
import { getChapterBySkillAndUserApi } from 'app/api/chapterApi';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { getSkillByChapterIdApi } from 'app/api/skillSetApi';
import { setOptionsChapterDisplay, setStudentSelectedStore } from 'app/redux/actions/Chapter/chapter.actions';
import { setQuestionSelectedByStudent } from 'app/redux/actions/Question/question.actions';
import { SKILL_PAGE_URL } from 'app/Routes';

function DrawerHistoryTaskCreation(props) {

    const getCurrentUserId = () => Number.parseInt(AuthenticationContainer.getCurrentUserId(), 10);

    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(selectCurrentUser);
    const currentSkill = useSelector(selectCurrentSkill);
    const currentStudentSelected = useSelector(selectStudent);

    const [questions, setQuestions] = useState([]);
    const [chapterId, setChapterId] = useState(0);
    const [expanded, setExpanded] = useState(false);

    const [nbQuestion, setNbQuestion] = useState('0');
    const [anchorEl, setAnchorEl] = useState(null);
    const [withChildren, setWithChildren] = useState(true);
    const [isRandomSerie, setIsRandomSerie] = useState(false);
    const [language, setLanguage] = useState('zza');

    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [serieTitle, setSerieTitle] = useState('');
    const [enableError, setEnableError] = useState(false);

    const [chapterTree, setChapterTree] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [serieCreatorId, setSerieCreatorId] = useState(null);
    const [serieId, setSerieId] = useState(null);

    const isValidSerieName = useMemo(
        () => serieTitle.trim() !== '' && serieTitle.charAt(0) === serieTitle.toLocaleUpperCase().charAt(0)
        , [serieTitle]
    );

    const getIdsRecipient = () => props.selectedGroup
        ? props.selectedGroup.students.map((student) => student.id)
        : [props.selectedStudentId];

    const getSingleUserId = () => props.selectedGroupId ? null : props.selectedStudentId;

    const getExcludedQuestions = () => questions.length ? questions.map((quest) => quest.id).join(',') : null;

    const handleClickHistory = (question) => {
        dispatch(setQuestionSelectedByStudent({ questionId: question.id, studentId: currentStudentSelected?.id }));
        if(currentSkill?.id !== question.skillId) {
            dispatch(setOptionsChapterDisplay(1));
            dispatch(setStudentSelectedStore(currentStudentSelected));
            const url = `${SKILL_PAGE_URL}/${question.skillName}`;
            history.push(url);
        }
    };


    const resetTaskCreation = () => {
        setSerieTitle('');
        setQuestions([]);
        setSelectedChapter(null);
        setSelectedSkill(null);
        setSerieId(null);
        setSerieCreatorId(null);
    };

    const setSerieTo = (localSerie) => {
        setSerieTitle(localSerie.title);
        setSerieId(localSerie.id);
    };


    const handleCreateSerie = () => {
        setEnableError(true);
        if(isValidSerieName) {
            const newSerie = {
                chapterId: selectedChapter?.content?.id,
                creator: {
                    id: getCurrentUserId()
                },
                description: '',
                maxDuration: '',
                questionReducedList: questions.map((question) => ({ id: question.id })),
                title: serieTitle
            };

            postSerieWithQuestionReducedApi(newSerie)
                .then((response) => {
                    setSerieTo(response.data);
                    setEnableError(false);
                    dispatch(notificationSuccess(SERIE_ADDED));
                })
                .catch(() => {
                    dispatch(notificationError(ADD_SERIE_ERROR));
                });
            setIsOpenDialog(false);
        }
    };

    const handleUpdateSerie = () => {
        setEnableError(true);
        if(isValidSerieName) {
            const newSerie = {
                id: serieId,
                chapterId: selectedChapter?.content?.id,
                creator: {
                    id: serieCreatorId
                },
                description: '',
                maxDuration: '',
                questionReducedList: questions.map((question) => ({ id: question.id })),
                title: serieTitle
            };

            putSerieWithQuestionReducedApi(newSerie)
                .then((response) => {
                    setSerieTo(response.data);
                    setEnableError(false);
                    dispatch(notificationSuccess(SERIE_UPDATED));
                })
                .catch(() => {
                    dispatch(notificationError(UPDATE_SERIE_ERROR));
                });
            setIsOpenDialog(false);
        }
    };

    const affectQuestions = (event) => {
        event.stopPropagation();
        const body = {
            creatorId: getCurrentUserId(),
            groupId: props.selectedGroupId,
            studentIds: getIdsRecipient(),
            customSerie: questions.map((question) => question.id),
            class: 'TaskCustomSerie'
        };
        addCustomTaskApi(body)
            .then((response) => {
                dispatch(notificationSuccess(POST_TASKS_TO_STUDENT_SUCCESS));
                props.addTaskToList(response.data);
                setQuestions([]);
            })
            .catch(() => {
                dispatch(notificationError(POST_TASKS_TO_STUDENT_ERROR));
            });
    };

    const getQuestionsFromChapterId = useCallback(() => {
        getRandomSerieByChapterIdApi(chapterId, { withChildren, isRandomSerie, nbQuestion, language, userId: getSingleUserId(), excludedQuestions: getExcludedQuestions() }).then((response) => {
            setQuestions((oldQuestions) => [
                ...oldQuestions, ...response.data.questionAskedList.map((question) => ({
                    id: question.id, name: `${question.skill?.name} #${question.id}`, type: AssignTypes.QUESTION, skillId: question.skill?.id, skillName: question.skill?.name, chapterId
                }))
            ]);
        });
    }, [questions, props.selectedGroupId, props.selectedStudentId, chapterId, withChildren, isRandomSerie, nbQuestion, language]);

    const toggleRandomised = (question) => {
        getRandomSerieByChapterIdApi(
            question.chapterId,
            { withChildren: true, isRandomSerie: false, nbQuestion: 1, language: 'zza',
                userId: getSingleUserId(), excludedQuestions: getExcludedQuestions() }
        ).then((response) => {
            const newQuestion = response.data.questionAskedList[0];
            if(newQuestion) {
                setQuestions((oldQuestions) => oldQuestions.map((el) => el.id === question.id
                    ? {
                        id: newQuestion.id,
                        name: `${newQuestion.skill.name} #${newQuestion.id}`,
                        type: AssignTypes.QUESTION,
                        chapterId: question.chapterId
                    }
                    : el));
            }
        });
    };

    const deleteQuestion = (id) => {
        setQuestions((prevState) => prevState.filter((question) => question.id !== id));
    };

    const getQuestionFromSerieId = (id) => {
        getSerieByIdWithQuestionReducedApi(id).then((response) => {
            const serie = response.data;
            setSerieTitle(serie.title);
            setSelectedChapter(serie.chapterId);
            setSerieCreatorId(serie.creator.id);
            if(serie.chapterId) {
                getSkillByChapterIdApi(serie.chapterId).then((skillResponse) => {
                    setSelectedSkill({ label: skillResponse.data.name, value: skillResponse.data.id });
                });
            }
            setQuestions((oldQuestions) => [
                ...oldQuestions, ...serie.questionReducedList.map((question) => ({
                    id: question.id, name: `${question.skill.name} #${question.id}`, type: AssignTypes.QUESTION, chapterId
                }))
            ]);
        });

    };

    const displayErrorMessage = () => serieTitle.trim() === ''
        ? 'Le titre ne doit pas être vide'
        : 'Le titre doit commencer par une majuscule';


    const allSkills = props.skills
        .map((skillReduced) => ({ label: skillReduced.name, value: skillReduced.id }))
        .sort((s1, s2) => s1.label.localeCompare(s2.label));

    const handleChangeSelectChapter = (newChapter) => {
        setSelectedChapter(chapterTree.find(newChapter));
    };

    const handleChangeSelectSkill = (_val, newSkill) => {
        setSelectedSkill(newSkill);
    };

    const [collectedProps, drop] = useDrop(
        () => ({
            accept: [AssignTypes.QUESTION, AssignTypes.CHAPTER, AssignTypes.SERIE],
            drop: (item) => {
                if(item.type === AssignTypes.QUESTION) {
                    if(questions.some((question) => question.id === item.id)) {
                        dispatch(notificationWarning(ADD_TASK_TO_LIST_WARNING_DUPLICATE));
                    } else {
                        setQuestions((prevState) => [...prevState, item]);
                    }
                } else if(item.type === AssignTypes.SERIE) {
                    setSerieId(item.serie.id);
                    getQuestionFromSerieId(item.serie.id);
                } else {
                    setChapterId(item.chapter.id);
                    setAnchorEl({ ...document.getElementById('history-accordion') });
                }
            },
            collect: (monitor) => ({
                isOver: Boolean(monitor.isOver()),
                canDrop: Boolean(monitor.canDrop())
            })
        }),
        [questions]
    );

    useEffect(() => {
        if(!props.isOnChapterContent) {
            if(selectedSkill) {
                getChapterBySkillAndUserApi(selectedSkill.value, currentUser.id).then((response) => {
                    if(response.data) {
                        setChapterTree(createChapterTree(response.data));
                    }
                });
            }
        }
    }, [selectedSkill]);

    useEffect(() => {
        setSelectedChapter(chapterTree?.root?.children?.find((chapter) => chapter.content.id === selectedChapter) ?? null);
    }, [chapterTree]);

    return (
        <>

            <PlayChapterQuestionsRequest
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                onValidate={() => getQuestionsFromChapterId(questions)}
                chapterId={chapterId}
                nbQuestions={nbQuestion}
                onChangeNbQuestions={(nb) => {
                    setNbQuestion(String(nb));
                }}
                withChildren={withChildren}
                onChangeWithChildren={(event) => {
                    setWithChildren(event.target.checked);
                }}
                isRandomSerie={isRandomSerie}
                setIsRandomSerie={(event) => {
                    setIsRandomSerie(event.target.checked);
                }}
                language={language}
                onChangeLanguage={(event) => {
                    setLanguage(event.target.checked);
                }}
            />
            <Dialog
                onClose={() => setIsOpenDialog(false)}
                open={isOpenDialog}
            >
                <DialogTitle>
                    Créer une série
                </DialogTitle>
                <DialogContent dividers id="addToProfileDialog">
                    <TextField
                        value={serieTitle}
                        label="Nom de la série"
                        autoFocus
                        inputProps={{ maxLength: 100 }}
                        error={ enableError && !isValidSerieName }
                        helperText={
                            enableError
                                ? isValidSerieName
                                    ? ' '
                                    : displayErrorMessage()
                                : ' '
                        }
                        onChange={(event) => setSerieTitle(event.target.value)}
                    />
                    {currentUser?.roles?.find((role) => role.name === 'ADMIN') && <>
                        <Select
                            onChange={handleChangeSelectSkill}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Compétences"
                            isClearable
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            name="compétences"
                            options={allSkills}
                            value={selectedSkill}
                            dropdownStyle={{ zIndex: 1300 }}
                        />
                        <TreeSelect
                            className="selectChapter"
                            showSearch
                            onChange={handleChangeSelectChapter}
                            placeholder="Chapitre"
                            name="chapitre"
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: 1300
                            }}
                            disabled={!selectedSkill}
                            treeData={chapterTree?.root?.children}
                            value={selectedChapter}
                        />
                    </>
                    }
                </DialogContent>
                <DialogActions
                    style={{
                        marginLeft: '4%'
                    }}
                >
                    <div className="list-buttons">
                        <Button
                            color="primary"
                            onClick={() => setIsOpenDialog(false)}
                        >
                            Annuler
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleCreateSerie}
                        >
                            Créer
                        </Button>
                        { serieId &&
                            <Button
                                color="primary"
                                onClick={handleUpdateSerie}
                            >
                                Modifier
                            </Button>
                        }
                    </div>
                </DialogActions>
            </Dialog>
            <Accordion
                className={`task-creation-zone ${collectedProps.isOver && collectedProps.canDrop ? 'hover-draggable' : ''}`}
                id="history-accordion"
                expanded={expanded}
                onChange={(_evt, exp) => {
                    setExpanded(exp);
                }}
                ref={drop}
                elevation={0}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="width-100">
                        <div className="drawer-title">
                            <span>
                                Tâche suivante ({`${questions.length} ${questions.length <= 1 ? 'question' : 'questions'}`})
                            </span>
                            <div className="customSerieActions">
                                <Tooltip title="Sauvegarder la série">
                                    <span>
                                        <IconButton onClick={() => setIsOpenDialog(true)} disabled={questions.length === 0}>
                                            <Save fontSize="inherit" className={`${questions.length === 0 ? 'color-grey' : 'color-blue'}`} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <div>
                                    <Button
                                        disabled={questions.length === 0}
                                        color="primary" size="small"
                                        variant="contained"
                                        data-testid="affect-button"
                                        onClick={affectQuestions}
                                    >
                                        Affecter
                                    </Button>
                                    <Button
                                        disabled={!(serieId || questions.length > 0)}
                                        color="primary" size="small"
                                        variant="contained"
                                        data-testid="cancel-button"
                                        onClick={resetTaskCreation}
                                        style={{ marginTop: '8px' }}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </div>
                        </div>
                        { (!expanded || !questions.length) && (<div className="drawer-put-content">Déposer du contenu ici</div>) }
                    </div>
                </AccordionSummary>
                <AccordionDetails className="history-accordion-items">
                    <div className="flex-column">
                        { questions.map((question, index) => <span className="question-row" key={index}>
                            <span className="clickable" onClick={() => {
                                handleClickHistory(question);
                            }}>
                                {question.name}
                            </span>
                            {question.chapterId
                                ? <Tooltip title="Remplacer par une question du même chapitre">
                                    <IconButton size="small" onClick={() => {
                                        toggleRandomised(question, questions);
                                    }}>
                                        <RefreshIcon fontSize="inherit" className="color-blue"/>
                                    </IconButton>
                                </Tooltip>
                                : <></>
                            }
                            <Tooltip title="Supprimer">
                                <IconButton size="small" onClick={() => {
                                    deleteQuestion(question.id);
                                }}>
                                    <DeleteIcon fontSize="inherit" className="color-red"/>
                                </IconButton>
                            </Tooltip>
                        </span>)}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );

}

DrawerHistoryTaskCreation.propTypes = {
    addTaskToList: PropTypes.func,
    chapter: PropTypes.bool,
    chapters: PropTypes.array,
    isOnChapterContent: PropTypes.bool,
    selectedGroup: PropTypes.object,
    selectedGroupId: PropTypes.number,
    selectedStudentId: PropTypes.number,
    skill: PropTypes.bool,
    skills: PropTypes.array,
    valueChapters: PropTypes.array

};

function mapStateToProps(state) {
    return {
        skills: selectAllReducedSkills(state).data,
        chapters: selectReducedChapters(state).data,
        valueSkills: selectQuestionsSkillsFilter(state),
        valueChapters: selectQuestionsChaptersFilter(state)
    };
}

export default connect(mapStateToProps)(DrawerHistoryTaskCreation);
