import React from 'react';
import PropTypes from 'prop-types';
import './Excilyen.scss';
import { withRouter } from 'react-router-dom';
import ProfileContent from './ProfileContent';

export function Excilyen(props) {

    return (
        <div className="excilyen" data-testid ="excilyen">
            <ProfileContent id={parseInt(props.match.params.id, 10)}/>
        </div>
    );

}

Excilyen.propTypes = {
    match: PropTypes.object
};

export default withRouter(Excilyen);
