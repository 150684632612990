import React from 'react';
import './RichTextEditor.scss';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export function RichTextEditor(props) {
    return (

        <CKEditor
            editor={ Editor }
            data={props.data ? props.data : ''}
            config={ {
                language: 'fr',
                placeholder: 'Description',
                removePlugins: [
                    'BlockQuote',
                    'ImageUpload',
                    'MediaEmbed'
                ],
                toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'underline',
                    'italic',
                    'code',
                    'codeBlock',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'link',
                    '|',
                    'insertTable',
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                    '|',
                    'undo',
                    'redo'
                ]
            } }
            onChange={props.onChange}
        />

    );
}

RichTextEditor.propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.string
};

export default RichTextEditor;
