/* eslint-disable max-lines */

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './SerieTable.scss';
import { Link } from 'react-router-dom';
import { QUESTIONS_PAGE_URL, SERIE_RESOLVE_URL } from 'app/Routes';
import { DescriptionPopup } from '../../project/components/Popup/DescriptionPopup';
import { sliceTitle } from './SerieTable.functions';
import TablePaginationActions from '../../utils/TablePaginationActions';
import { AssignTypes } from 'app/utils/functions';
import { useDrag } from 'react-dnd';

import MaterialTable from 'material-table';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/withWidth';
import {
    MuiThemeProvider,
    IconButton,
    TablePagination,
    MenuItem,
    Menu,
    Tooltip
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { useDispatch, useSelector } from 'react-redux';
import { selectSeriePageParams, selectSeriesToDisplay } from 'app/redux/selectors/Question/serie.selector';
import { getSeriesByCriteria, setExamMode } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { setPageParams } from 'app/redux/actions/Question/Serie/SerieSet/serieSet.actions';
import QuestionTableInSerie from './QuestionTableInSerie/QuestionTableInSerie';

export function SerieTable(props) {

    const dispatch = useDispatch();

    const pageWithSerieDisplay = useSelector(selectSeriesToDisplay);
    const pageParams = useSelector(selectSeriePageParams);

    const [dataTable, setDataTable] = useState();
    const [questionsCount, setQuestionCount] = useState(0);
    const [mobileMenu, setMobileMenu] = useState(null);
    const [criteria, setCriteria] = useState({
        orderBy: null,
        orderDirection: null
    });

    useEffect(() => {
        if(pageWithSerieDisplay) {
            setQuestionCount(pageWithSerieDisplay.totalNumberOfElements);
            setDataTable(pageWithSerieDisplay.listOfElements);
        }
    }, [pageWithSerieDisplay]);

    const handleClose = () => {
        setMobileMenu(null);
    };

    const handleClick = (event) => {
        setMobileMenu(event.currentTarget);
    };

    const columnsForMobile = [
        {
            title: 'Titre',
            field: 'title',
            render: (rowData) => <Fragment key={rowData.id}>
                <div className="columnTitle">
                    {rowData.title.length > 44 ? sliceTitle(rowData.title, 44) : rowData.title}
                </div>
                {rowData.title.length > 44 && <DescriptionPopup description={rowData.title} />}
            </Fragment>,
            cellStyle: {
                width: 1600,
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: 1600
            },
            sorting: true
        },
        {
            title: 'Auteur',
            field: 'creator',
            render: (rowData) => <Fragment key={rowData.id}>
                {rowData.creator.firstName} {rowData.creator.lastName}
            </Fragment>,
            cellStyle: {
                width: 400,
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: 400
            },
            sorting: true
        },
        {
            title: 'Action',
            field: 'action',
            render: (rowData) => <>
                <IconButton
                    id={rowData.id}
                    className="actionButton"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    elevation={1}
                    id="simple-menu"
                    anchorEl={mobileMenu}
                    keepMounted
                    open={Boolean(mobileMenu)}
                    onClose={handleClose}
                >
                    <MenuItem
                        className="columnEditButton"
                    >
                        <Link
                            to={`${QUESTIONS_PAGE_URL}/serie/edit?id=${mobileMenu?.id}`}
                        >
                            Edition
                        </Link>
                    </MenuItem>
                    <MenuItem
                        className="columnDuplicateButton"
                    >
                        <Link
                            to={`${QUESTIONS_PAGE_URL}/serie/create?id=${rowData.id}`}
                        >
                            Duplication
                        </Link>
                    </MenuItem>
                    <MenuItem className="columnPlaySerieButton">
                        <Link
                            to={`${SERIE_RESOLVE_URL}/${mobileMenu?.id}`}
                            style={{ color: 'inherit' }}
                        >
                            Lancer la série
                        </Link>
                    </MenuItem>
                </Menu>
            </>,
            cellStyle: {
                textAlign: 'center',
                width: 400,
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                textAlign: 'center',
                width: 400
            },
            sorting: false
        }
    ];

    const columnsForDesktop = [
        {
            title: 'Titre',
            field: 'title',
            render: (rowData) => (
                <SerieTitleDraggable
                    id={rowData.id}
                    title={rowData.title}
                    serie={rowData}
                    chapterId={rowData.chapterId}
                />
            ),
            cellStyle: {
                width: 1600,
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: 1600
            },
            sorting: true
        },
        {
            title: 'Auteur',
            field: 'creator',
            render: (rowData) => <Fragment key={rowData.id}>
                {rowData.creator.firstName} {rowData.creator.lastName}
            </Fragment>,
            cellStyle: {
                width: 400,
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: 400
            },
            sorting: true
        },
        {
            title: 'Edition',
            field: 'Edit',
            render: (rowData) => <Link
                to={`${QUESTIONS_PAGE_URL}/serie/edit?id=${rowData.id}`}
            >
                <IconButton
                    key={rowData.id}
                    className="columnEditButton"
                >
                    <EditIcon />
                </IconButton>
            </Link>,
            cellStyle: {
                width: '10%',
                padding: '2px 2px 2px 15px'
            },
            headerStyle: {
                width: '10%',
                padding: '2px 2px 2px 15px'
            },
            sorting: false
        },
        {
            title: 'Duplication',
            field: 'Duplicate',
            render: (rowData) => <Link
                to={`${QUESTIONS_PAGE_URL}/serie/create?id=${rowData.id}`}
            >
                <IconButton key={rowData.id}
                    className="columnDuplicateButton"
                >
                    <FileCopyIcon />
                </IconButton>
            </Link>,
            cellStyle: {
                width: '10%',
                padding: '2px 2px 2px 26px'
            },
            headerStyle: {
                width: '10%',
                padding: '2px 2px 2px 15px'
            },
            sorting: false
        },
        {
            title: 'Lancer la série',
            field: 'startQuiz',
            render: (rowData) => (
                <Tooltip title={`Lancer la série ${rowData.title}`}>
                    <Link to={`${SERIE_RESOLVE_URL}/${rowData.id}`}>
                        <IconButton key={rowData.id} onClick={() => {dispatch(setExamMode(false));}}>
                            <PlayCircleOutlineIcon fontSize="large" />
                        </IconButton>
                    </Link>
                </Tooltip>
            ),
            cellStyle: {
                textAlign: 'center',
                width: 400,
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                textAlign: 'center',
                width: 400
            },
            sorting: false
        }
    ];

    useEffect(() => {
        const localCriteria = {
            offset: pageParams.rowPerPage * pageParams.pageNum,
            step: pageParams.rowPerPage,
            sort: criteria.orderBy,
            order: criteria.orderDirection
        };
        dispatch(getSeriesByCriteria(localCriteria));
    }, [pageParams]);

    const sortBy = (orderedColumnId, orderDirection) => {
        if(orderedColumnId < 0) {
            return;
        }
        let fixedDirection = orderDirection;
        if(criteria.orderBy === columnsForDesktop[orderedColumnId].field) {
            fixedDirection = criteria.orderDirection === 'asc' ? 'desc' : 'asc';
        } else {
            fixedDirection = 'asc';
        }
        setCriteria({
            orderBy: columnsForDesktop[orderedColumnId].field,
            orderDirection: fixedDirection
        });
        const localCriteria = {
            offset: pageParams.rowPerPage * pageParams.pageNum,
            step: pageParams.rowPerPage,
            sort: columnsForDesktop[orderedColumnId].field,
            order: fixedDirection
        };
        dispatch(getSeriesByCriteria(localCriteria));
    };

    const columnControl = () => {
        if(isWidthUp('md', props.width)) {
            return columnsForDesktop;
        }
        return columnsForMobile;
    };

    const theme = createTheme({
        overrides: {
            MuiTableCell: {
                body: {
                    padding: '2px 8px'
                },
                paddingNone: {
                    padding: '2px 0px 2px 4px'
                }
            }
        }
    });

    const handleChangePage = (_event, pageNumber) => {
        dispatch(setPageParams({
            pageNum: pageNumber,
            rowPerPage: pageParams.rowPerPage
        }));
    };

    const handleRowsPerPage = (event) => {
        dispatch(setPageParams({
            pageNum: 0,
            rowPerPage: event.target.value
        }));
    };

    return (
        <div className="serie">
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    key={pageParams.rowPerPage}
                    className="Table"
                    columns={columnControl()}
                    data={dataTable}
                    title="Séries de questions"
                    isLoading={!dataTable}
                    options={{
                        search: true,
                        title: true,
                        toolbar: false,
                        pageSize: pageParams.rowPerPage,
                        pageSizeOptions: [
                            10,
                            20
                        ]
                    }}
                    localization={{
                        'header': {
                            'actions': ''
                        },
                        'body': {
                            'emptyDataSourceMessage': (dataTable)
                                ? 'Aucune serie trouvée'
                                : 'En attente de chargement...'
                        },
                        'pagination': {
                            'labelDisplayedRows': '{from}-{to} / {count}',
                            'labelRowsSelect': '',
                            'nextTooltip': 'Page suivante',
                            'previousTooltip': 'Page précédente',
                            'firstTooltip': 'Première page',
                            'lastTooltip': 'Dernière page'
                        },
                        'toolbar': {
                            'searchPlaceholder': 'Rechercher',
                            'searchTooltip': 'Rechercher'
                        }
                    }}
                    onOrderChange={(orderedColumnId, orderDirection) => sortBy(orderedColumnId, orderDirection)}
                    detailPanel={[
                        {
                            tooltip: 'Accéder aux questions',

                            render: (rowData) => <QuestionTableInSerie
                                serieId={rowData.id}
                            />
                        }
                    ]}
                    components={{
                        Pagination: () => (
                            <TablePagination className="pagination"
                                count={questionsCount ? questionsCount : 0}
                                page={pageParams.pageNum}
                                rowsPerPage={pageParams.rowPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleRowsPerPage}
                                rowsPerPageOptions={[10, 20]}
                                labelRowsPerPage={'Séries par page'}
                                backIconButtonText={'Page précédente'}
                                nextIconButtonText={'Page suivante'}
                                labelDisplayedRows={({ from, to, count }) => (`${from}-${to} / ${count}`)}
                                ActionsComponent={TablePaginationActions}
                            />
                        )
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
}

SerieTable.propTypes = {
    width: PropTypes.string
};

export function SerieTitleDraggable(props) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.SERIE,
        item: {
            type: AssignTypes.SERIE,
            id: props.id,
            name: props.title,
            serie: props.serie,
            chapterId: props.chapterId
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging())
        })

    }), [props.id]);

    return (
        <span
            key={props.id}
            ref={drag}
            style={
                {
                    textDecoration: 'none',
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move'
                }
            }
        >
            {props.title.length > 60 ? sliceTitle(props.title, 60) : props.title}
            {props.title.length > 60 && <DescriptionPopup description={props.title} />}
        </span>
    );
}

SerieTitleDraggable.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    serie: PropTypes.object.isRequired,
    name: PropTypes.string,
    chapterId: PropTypes.number
};

export default (withWidth()(SerieTable));
