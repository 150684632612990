import axiosClient from './utils/axiosClient';

export const SECTOR_URI = 'sectors';

export function getAllSectorsApi() {
    return axiosClient.get(SECTOR_URI);
}

export function getSectorByIdApi(id) {
    return axiosClient.get(`${SECTOR_URI}/${id}`);
}
