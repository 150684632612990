/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    createObjectAnswers,
    getUserAnswerForQuestionTexteATrouWithOrdinal,
    sortAnswerByLabel,
    toggleSelectedCheckbox,
    useStyles,
    validateQuestionResolverWithCourse,
    validateQuestionTexteATrou
} from './QuestionContainer.functions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { setQuestionsToDisplay } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';
import {
    PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_FAILURE, PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_REQUEST,
    PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS
} from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { Container, LinearProgress } from '@material-ui/core';
import QuestionFooter from './QuestionFooter/QuestionFooter';
import QuestionHeader from './QuestionHeader/QuestionHeader';
import QuestionBody from './QuestionBody/QuestionBody';
import { QuestionAskedEnum, QuestionEnum } from 'app/utils/QuestionEnum';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import {
    AI_CALL_ERROR,
    EXPIRED_SESSION,
    FILE_TOO_LARGE_ERROR,
    GET_TRADUCTION_REQUEST_ERROR,
    POST_ANSWER_RETRIEVE_QUESTION_ERROR,
    QUESTION_DELETED_ERROR,
    QUESTION_TYPE_UNKNOWN
} from 'app/Snackbar/NotificationMessages';
import { getTrouInputId } from 'app/utils/QuestionUtils';
import { shuffle } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { getQuestionsDoneAndGoodAnswers } from 'app/redux/actions/ChapterStats/chapterStats.actions';
import {
    putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi, putAnswerRetrieveQuestionHistoryByIdWithExplanationApi
} from 'app/api/multipleChoiceQuestionApi';
import {
    postInteractiveQuizCurrentQuestionApi,
    postUserAnsweredInteractiveQuizCurrentQuestionApi
} from 'app/api/interactiveQuizApi';
import { postQuestionOuverteAnswerApi } from 'app/api/multipleChoiceQuestionsSetApi';
import { localLlmServiceApi } from 'app/api/localLlmApi';
import { addAudioBlobUrl, addAudioTextAnswer } from 'app/redux/actions/Question/Serie/SerieSet/serieSet.actions';
import DeleteQuestionConfirmationDialog from './DeleteQuestionConfirmationDialog';
import { setQuestionToInactive } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQSet/MCQSet.actions';
import { selectQuestionSelectedByStudent, selectQuestionSelectedHistories, selectQuestionSelectedHistory } from 'app/redux/selectors/Question/question.selector';

export function QuestionContainer(props) {

    const getCurrentUserName = () => AuthenticationContainer.getEmail().split('@')[0];
    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const questionSelectedByStudent = useSelector(selectQuestionSelectedByStudent);
    const questionSelectedHistory = useSelector(selectQuestionSelectedHistory);
    const questionSelectedHistories = useSelector(selectQuestionSelectedHistories);

    const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const extractParamsUrl = useMemo(() => new URLSearchParams(search), [search]);
    const history = useHistory();

    const [isDeleteQuestionConfirmationOpen, setIsDeleteQuestionConfirmationOpen] = useState(props.savedState?.isDeleteQuestionConfirmationOpen || false);
    const [answers, setAnswers] = useState(props.savedState?.answers || []);
    const [explanation, setExplanation] = useState(props.questionWithExplanation?.explanation || props.savedState?.explanation);
    const [chapterIds, setChapterIds] = useState(props.questionWithExplanation?.chapterIds || props.savedState?.chapterIds);
    const [language, setLanguage] = useState(props.questionAsked.language || props.savedState?.language);
    const [hasAnsweredWithoutCourse, setHasAnsweredWithoutCourse] = useState(props.savedState?.hasAnsweredWithoutCourse || false);
    const [isDisplayingExplanation, setIsDisplayingExplanation] = useState(props.savedState?.isDisplayingExplanation || false);
    const [isDisplayingReview, setIsDisplayingReview] = useState(props.savedState?.isDisplayingReview || false);
    const [questionHistoryEntry, setQuestionHistoryEntry] = useState(props.savedState?.questionHistoryEntry || undefined);
    const [hasValidationFailed, setHasValidationFailed] = useState(props.savedState?.hasValidationFailed || false);
    const [reviewText, setReviewText] = useState(props.savedState?.reviewText || null);
    const [reviewGrade, setReviewGrade] = useState(props.savedState?.reviewGrade || null);
    const [isChapterHistoryView, setIsChapterHistoryView] = useState(props.savedState?.isChapterHistoryView || false);

    const [audioBlobUrl, setAudioBlobUrl] = useState(props.savedState?.audioBlobUrl || null);
    const [textAnswer, setTextAnswer] = useState(props.questionAsked.audioTextAnswer || props.savedState?.textAnswer || '');
    const [totalText, setTotalText] = useState(props.savedState?.totalText || '');
    const [isSendingFile, setIsSendingFile] = useState(props.savedState?.isSendingFile || false);

    const [isTranslationMode, setIsTranslationMode] = useState(props.savedState?.isTranslationMode || false);
    const [translatedQuestion, setTranslatedQuestion] = useState(props.savedState?.translatedQuestion || null);
    const [isLoading, setIsLoading] = useState(props.savedState?.isLoading || false);

    useEffect(() => {
        if(!props.saveCurrentState) { return; }
        props.saveCurrentState({
            isDeleteQuestionConfirmationOpen,
            answers,
            explanation,
            chapterIds,
            language,
            hasAnsweredWithoutCourse,
            isDisplayingExplanation,
            isDisplayingReview,
            questionHistoryEntry,
            hasValidationFailed,
            reviewText,
            reviewGrade,
            isChapterHistoryView,
            audioBlobUrl,
            textAnswer,
            totalText,
            isSendingFile,
            isTranslationMode,
            translatedQuestion,
            isLoading,
            initIndex: props.questionAsked.initIndex
        });
    }, [isDeleteQuestionConfirmationOpen, answers, explanation, chapterIds,
        language, hasAnsweredWithoutCourse, isDisplayingExplanation, isDisplayingReview,
        questionHistoryEntry, hasValidationFailed, reviewText, reviewGrade, isChapterHistoryView,
        audioBlobUrl, textAnswer, totalText, isSendingFile, isTranslationMode, translatedQuestion, isLoading]);

    const checkIfAnswerIsCorrectForMutlipleChoice = (questionWithExplanation) => {
        const trueAnswers = questionWithExplanation.answers.filter((answer) => answer.validAnswer === true);
        const userAnswers = answers.filter((answer) => answer.selected === true);
        const result = trueAnswers.filter((answer) => !userAnswers.some((answer2) => answer.id === answer2.id));
        return trueAnswers.length === userAnswers.length && result.length === 0;
    };

    const checkIfAnswerIsCorrectForTexteATrou = (questionWithExplanation) => {
        let isCorrect = true;
        questionWithExplanation.answers.forEach((answer) => {
            if(!answer.acceptedAnswers.includes(document.getElementById(getTrouInputId(answer.ordinal))?.value.trim())) {
                isCorrect = false;
            }
        });
        return isCorrect;
    };

    const handleOnDeleteQuestion = () => {
        setIsDeleteQuestionConfirmationOpen(!isDeleteQuestionConfirmationOpen);
    };

    const handleDeleteQuestionCancelClick = () => {
        setIsDeleteQuestionConfirmationOpen(false);
    };

    const handleDeleteQuestionConfirmClick = () => {
        setIsDeleteQuestionConfirmationOpen(false);

        if(props.questionAsked.status === 'INACTIVE') {
            dispatch(notificationError(QUESTION_DELETED_ERROR));
        } else {
            let typeClass = '';

            for(const keyEnum in QuestionAskedEnum) {
                if(QuestionAskedEnum[keyEnum] === props.questionAsked.class) {
                    typeClass = keyEnum;
                    break;
                }
            }

            if(typeClass === '') {
                for(const keyEnum in QuestionEnum) {
                    if(QuestionEnum[keyEnum] === props.questionAsked.class) {
                        typeClass = keyEnum;
                        break;
                    }
                }
            }

            const questionParam = {
                class: QuestionEnum[typeClass],
                creator: props.questionAsked.creator,
                id: props.questionAsked.id,
                skillId: props.questionAsked.skillId || props.questionAsked.skill.id

            };

            dispatch(setQuestionToInactive(questionParam, props.questionIndex, props.chapterId, () => history.replace('/questions')));
        }
    };

    const updateScore = (isInSerie, questionWithExplanation) => {
        let correctAnswer = null;
        switch (questionWithExplanation.class) {

            case QuestionEnum.MultipleChoice:
                correctAnswer = checkIfAnswerIsCorrectForMutlipleChoice(questionWithExplanation);
                break;

            case QuestionEnum.TexteATrou:
                correctAnswer = checkIfAnswerIsCorrectForTexteATrou(questionWithExplanation);
                break;
            case QuestionEnum.QuestionOuverte:
                correctAnswer = true;
                break;
            default:
                dispatch(notificationError(QUESTION_TYPE_UNKNOWN));
        }
        if(isInSerie) {
            if(correctAnswer) {
                props.onIncrGoodAnswers();
            }
        } else if(props.quizUuidFromPlayer) {
            const selectedAnswerIds = answers
                .filter((answer) => answer.selected)
                .map((selectedAnswer) => selectedAnswer.id);
            const selectedAnswersWithResult = {
                selectedAnswerIds,
                correctAnswer
            };
            postUserAnsweredInteractiveQuizCurrentQuestionApi(
                getCurrentUserId(),
                props.quizUuidFromPlayer,
                selectedAnswersWithResult
            );
        } else {
            dispatch(setQuestionsToDisplay({
                id: props.questionAsked.id,
                chapterIds: questionWithExplanation.chapterIds,
                result: String(correctAnswer).toUpperCase()
            }));
            if(questionWithExplanation.chapterIds.length) {
                dispatch(getQuestionsDoneAndGoodAnswers(questionWithExplanation.chapterIds, getCurrentUserId()));
            }
        }
    };

    const updateScoreWithQuestion = (questionWithExplanation) => {
        const newExplanation = questionWithExplanation?.explanation ?? props.questionAsked.explanation;
        const newChapterIds = questionWithExplanation?.chapterIds ?? props.questionAsked.chapterIds;
        setExplanation(newExplanation);
        setChapterIds(newChapterIds);
        if(questionWithExplanation?.answers && questionWithExplanation.id === props.questionAsked.id) {
            updateScore(Boolean(props.questionsNumber), questionWithExplanation);
            switch (questionWithExplanation.class) {
                case QuestionEnum.TexteATrou:
                    setAnswers(questionWithExplanation.answers.map((answer) => ({
                        ...answer,
                        value: getUserAnswerForQuestionTexteATrouWithOrdinal(answer.ordinal)
                    })));
                    validateQuestionTexteATrou(questionWithExplanation);
                    break;

                case QuestionEnum.MultipleChoice:
                    setAnswers((previousAnswer) => (validateQuestionResolverWithCourse([...previousAnswer], questionWithExplanation.answers, props.isExamMode || props.candidateUuid)));
                    break;
                case QuestionEnum.QuestionOuverte:
                    break;
                default:
                    dispatch(notificationError(QUESTION_TYPE_UNKNOWN));
            }
        }
    };

    const toggleCheckbox = (event) => {
        setAnswers(toggleSelectedCheckbox([...answers], event.target.id));
    };

    const convertUrlToBlob = async(url) => {
        const response = await fetch(url);
        return await response.blob();
    };

    const createAudioFile = async() => {
        const formData = new FormData();

        const blob = await convertUrlToBlob(audioBlobUrl);
        formData.append('responseFile', new File([blob], 'test.mp3'));
        formData.append('name', getCurrentUserName());
        return formData;
    };

    const getAnswerObjectForQuestionOuverte =  async() => {
        setIsSendingFile(true);
        const audioFile = await createAudioFile();

        const fileUrl = await postQuestionOuverteAnswerApi(audioFile)
            .then((response) => response.data.fileUrl)
            .catch((error) => {
                if(error.response.data.message === 'The uploaded file is too large') {
                    dispatch(notificationError(FILE_TOO_LARGE_ERROR));
                }
                return Promise.reject(new Error('Upload failed'));
            })
            .finally(() => setIsSendingFile(false));

        return createObjectAnswers(
            props.candidateUuid,
            props.questionAsked,
            undefined,
            Number(extractParamsUrl.get('taskId')),
            props.interactiveQuizSerieHistoryUpdate ? 
                props.interactiveQuizSerieHistoryUpdate :
                props.serieHistoryUpdate,
            props.interactiveQuizTotalNbQuestions ? 
                props.interactiveQuizTotalNbQuestions :
                props.questionsNumber,
            props.quizUuidFromLeader,
            fileUrl,
            textAnswer
        );
    };

    const getAnswerObjectForMultipleChoiceQuestion = () =>
        createObjectAnswers(
            props.candidateUuid,
            props.questionAsked,
            answers,
            Number(extractParamsUrl.get('taskId')),
            props.interactiveQuizSerieHistoryUpdate ? 
                props.interactiveQuizSerieHistoryUpdate :
                props.serieHistoryUpdate,
            props.interactiveQuizTotalNbQuestions ? 
                props.interactiveQuizTotalNbQuestions :
                props.questionsNumber,
            props.quizUuidFromLeader
        );

    const handleAnswered = (state) => {
        if(props.disableCourse) {
            setHasAnsweredWithoutCourse(state);
        } else {
            props.setHasAnsweredWithCourse(state);
        }
    };

    const getAndHandleAIReviewFromQuestionOuverteHistoryEntry = (historyEntry, serieHistoryId) => {
        const questionForAiReview = {
            id: historyEntry.id,
            date: null,
            result: 'PENDING',
            question: historyEntry.question,
            fileUrl: null,
            textAnswer,
            class: 'QuestionOuverteHistoryApiDto'
        };

        return localLlmServiceApi.generateReviewQuestionExplanation(questionForAiReview, serieHistoryId)
            .then((review) => {
                setIsDisplayingReview(true);
                setReviewText(review.data.reviewText);
                setReviewGrade(review.data.reviewGrade);
                return historyEntry.question;
            })
            .catch(() => {
                dispatch(notificationError(AI_CALL_ERROR));
                return Promise.reject(new Error('AI Call Failed'));
            });
    };

    const postQuestionOuverteAnswerAndGetQuestionWithExplanation = async() => {
        const answerObject = await getAnswerObjectForQuestionOuverte();
        let historyEntry = questionHistoryEntry;

        try {
            if(historyEntry === undefined) {
                historyEntry = await putAnswerRetrieveQuestionHistoryByIdWithExplanationApi(answerObject)
                    .then((response) => response.data);
                setQuestionHistoryEntry(historyEntry);
                handleAnswered(true);
                updateScoreWithQuestion(historyEntry.question);
            }
            await getAndHandleAIReviewFromQuestionOuverteHistoryEntry(historyEntry, answerObject.serieHistoryId);
            setHasValidationFailed(false);
            return historyEntry.question;
        } catch (error) {
            if(historyEntry !== undefined) {
                setHasValidationFailed(true);
                return historyEntry.question;
            }

            throw error;
        }
    };

    const postMultipleChoiceAnswerAndGetQuestionWithExplanation = () => {
        const answerObject = getAnswerObjectForMultipleChoiceQuestion();

        return putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi(answerObject, props.candidateUuid)
            .then((response) => {
                handleAnswered(true);
                updateScoreWithQuestion(response.data);
                return response.data;
            });
    };

    const validateQuestionInner = async() => {
        dispatch({
            type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_REQUEST
        });

        try {
            const question = props.questionAsked.class === QuestionAskedEnum.QuestionOuverte
                ? await postQuestionOuverteAnswerAndGetQuestionWithExplanation()
                : await postMultipleChoiceAnswerAndGetQuestionWithExplanation();

            dispatch({
                type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS, payload: question
            });
            return true;
        } catch (error) {
            setHasValidationFailed(true);
            dispatch(notificationError(POST_ANSWER_RETRIEVE_QUESTION_ERROR));
            dispatch({
                type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_FAILURE, payload: error
            });
            return false;
        }
    };

    const setupNewQuestion = () => {
        if(props.questionAsked.class === QuestionAskedEnum.MultipleChoice && props.onShowAiHelp) {
            props.onShowAiHelp(false);
        }
        setIsTranslationMode(false);
        setAudioBlobUrl(null);
        setTextAnswer('');
        setTotalText('');
        setChapterIds(undefined);
        setHasValidationFailed(false);
        setExplanation(undefined);
        setIsDisplayingExplanation(false);
        setReviewText(undefined);
        setReviewGrade(undefined);
        setIsDisplayingReview(false);
        setQuestionHistoryEntry(undefined);
        if(props.saveCurrentState) { props.saveCurrentState(undefined); }
    };

    const validateQuestion = async() => {
        if(props.questionsNumber) {
            props.saveDurationLeft();
        }

        // The function returns false if the validation failed.
        if(!await validateQuestionInner()) {
            return;
        }

        if(props.isExamMode) {
            setupNewQuestion();
        }

        if(props.onValidateCandidateQuestion) {
            props.onValidateCandidateQuestion();
        }
    };

    const toNextQuestion = () => {
        setupNewQuestion();
        props.onNextQuestion();
    };

    const toPreviousQuestion = () => {
        setupNewQuestion();
        props.onPreviousQuestion();
    };

    const handleTranslation = (mode) => {
        setIsTranslationMode(mode);
    };

    const saveAudioBlobUrl = (blobUrl) => {
        dispatch(addAudioBlobUrl(props.questionAsked.id, blobUrl));
    };

    const saveAudioTextAnswer = (audioTextAnswer) => {
        dispatch(addAudioTextAnswer(props.questionAsked.id, audioTextAnswer));
    };

    const updateSelectedAnswers = () => {
        const answerSelectedIds = questionSelectedHistory.answersSelected.map(answer => answer.id);
        const answersSelected = questionSelectedHistory.question.answers.map(answer => ({
            ...answer,
            selected: answerSelectedIds.includes(answer.id)
        }));
        validateQuestionResolverWithCourse(answersSelected, questionSelectedHistory.question.answers, false);
        setAnswers(answersSelected);
    };

    const MULTIPLE_CHOICES = [QuestionEnum.MultipleChoice, QuestionAskedEnum.MultipleChoice];
    const TEXTE_A_TROU = [QuestionEnum.TexteATrou, QuestionAskedEnum.TexteATrou];

    const showQuestionHistory = () => {
        const selectAttemptIndex = () =>
            props.setSelectedAttemptIndex(questionSelectedHistories.findIndex(qh => qh.id === questionSelectedHistory.id));

        const hasSelectedQuestion = questionSelectedHistories && 
            questionSelectedHistories.length > 0 &&
            questionSelectedHistory && 
            questionSelectedByStudent;
        if(questionSelectedHistory && questionSelectedHistory.result === 'NOT_DONE') {
            setIsChapterHistoryView(true);
            if(MULTIPLE_CHOICES.includes(questionSelectedHistory.question.class)) {
                updateSelectedAnswers();
            }
        } else if(hasSelectedQuestion && MULTIPLE_CHOICES.includes(questionSelectedHistory.question.class)) {
            setIsChapterHistoryView(true);
            updateSelectedAnswers();
            selectAttemptIndex();
        } else if(hasSelectedQuestion && TEXTE_A_TROU.includes(questionSelectedHistory.question.class)) {
            setIsChapterHistoryView(true);
            updateSelectedAnswers();
            setAnswers(questionSelectedHistory.question.answers.map((answer, index) => ({ ...answer, value: questionSelectedHistory.answersSelected[index] })));
            selectAttemptIndex();
        } else if(hasSelectedQuestion && questionSelectedHistory.result === 'GRADED') {
            setIsChapterHistoryView(true);
            selectAttemptIndex();
        } else if(!answers[0]?.formControlStyle) {
            setIsChapterHistoryView(false);
        }
    };

    useEffect(() => {
        if(props.imagesWithAnswersSelected?.length && props.quizUuidFromLeader) {
            setAnswers(props.interactiveQuizAnswers);
            validateQuestion();
        }
    }, [props.interactiveQuizAnswers]);

    const getQuestionAskedWithAnswers = () => {
        const questionFromHistory = questionSelectedHistory ?? (
            props.questionHistory ? props.questionHistory[props.selectedAttemptIndex] : null
        );
        if(!questionFromHistory) { return props.questionAsked; }
        if(questionFromHistory.result === 'GRADED') {
            return {
                ...questionFromHistory.question,
                fileUrl: questionFromHistory.fileUrl,
                textAnswer: questionFromHistory.textAnswer,
                reviewText: questionFromHistory.reviewText,
                reviewGrade: questionFromHistory.grade,
                questionStatus: questionFromHistory.result
            };
        }

        if(questionFromHistory.question.class === QuestionEnum.TexteATrou) {
            return {
                ...questionFromHistory.question,
                answers: questionFromHistory.question.answers.map((answer, index) => ({ ...answer, value: questionFromHistory.answersSelected[index] }))
            };
        }
        return questionFromHistory.question;
    };

    useEffect(() => {
        const shouldUseSavedState = props.savedState?.shouldUseSavedState;
        const questionAsked = getQuestionAskedWithAnswers();

        let initializeAnswers = [];
        if(shouldUseSavedState && props.savedState?.answers?.length > 0) {
            initializeAnswers = props.savedState?.answers;
        } else if(questionAsked?.answers && props.shuffleAnswers) {
            initializeAnswers = shuffle(questionAsked.answers);
        } else if(questionAsked?.answers) {
            initializeAnswers = sortAnswerByLabel(questionAsked.answers);
        }

        if(questionAsked.class === QuestionAskedEnum.MultipleChoice && props.onShowAiHelp) {
            props.onShowAiHelp(false);
        }

        setLanguage(questionAsked.language || (shouldUseSavedState && props.savedState?.language));
        setAnswers(initializeAnswers);
        setHasAnsweredWithoutCourse(props.savedState?.hasAnsweredWithoutCourse || false);
        setIsTranslationMode((shouldUseSavedState && props.savedState?.isTranslationMode) || false);
        setTranslatedQuestion((shouldUseSavedState && props.savedState?.translatedQuestion) || null);
        setAudioBlobUrl(questionAsked.fileUrl || questionAsked.audioBlobUrl || (shouldUseSavedState && props.savedState?.audioBlobUrl) || '');
        setTextAnswer(questionAsked.audioTextAnswer || questionAsked.textAnswer || (shouldUseSavedState && props.savedState?.textAnswer) || '');
        setTotalText(questionAsked.audioTextAnswer || questionAsked.textAnswer || (shouldUseSavedState && props.savedState?.totalText) || '');
        setExplanation(questionAsked.explanation || (shouldUseSavedState && props.savedState?.explanation) || null);
        setReviewText(questionAsked.reviewText || (shouldUseSavedState && props.savedState?.reviewText) || null);
        setReviewGrade(questionAsked.reviewGrade || (shouldUseSavedState && props.savedState?.reviewGrade));
        setHasValidationFailed((shouldUseSavedState && props.savedState?.hasValidationFailed) || false);
        setIsDisplayingExplanation((shouldUseSavedState && props.savedState?.isDisplayingExplanation) || false);
        if(props.saveCurrentState) {
            props.saveCurrentState({ ...props.savedState, shouldUseSavedState: false });
        }
    }, [props.questionAsked, questionSelectedHistory]);
    

    useEffect(() => {
        showQuestionHistory();
    }, [questionSelectedHistory, questionSelectedHistories]);

    useEffect(() => {

        if(isTranslationMode) {
            const statement = props.questionAsked.statement;
            const answersconcat = answers.map((answer) => answer.text).join('`');
            const mesConcat = (`\`${statement}\`${answersconcat}\``);

            setIsLoading(true);
            localLlmServiceApi.translate(props.questionAsked.id, mesConcat)
                .then((response) => {
                    const translatedData = response.data;
                    if(translatedData && typeof translatedData === 'string') {
                        const translatedParts = translatedData.split('`').slice(1)
                            .map((data) => data.trim())
                            .filter((answerText) => answerText !== '')
                            .slice(0, answers.length + 1);
                        if(translatedParts.length >= 1) {
                            const translatedStatement = translatedParts.shift();
                            const translatedAnswers = translatedParts
                                .filter((answerText) => answerText !== '')
                                .map((answerText) => ({
                                    text: answerText
                                }));
                            setTranslatedQuestion({
                                statement: translatedStatement,
                                answers: translatedAnswers
                            });
                        }
                    }
                })
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        dispatch(notificationError(EXPIRED_SESSION));
                    }
                    if(error.response && error.response.status === 500) {
                        dispatch(notificationError(GET_TRADUCTION_REQUEST_ERROR));
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isTranslationMode]);

    if(props.quizUuidFromLeader) {
        postInteractiveQuizCurrentQuestionApi(props.quizUuidFromLeader, props.questionAsked.id);
    }

    return (
        <><Container className={classes.questionContainer} label={`#${props.questionAsked.id}`}>
            <QuestionHeader
                question={props.questionAsked}
                questionHistory={props.questionHistory}
                selectedAttemptIndex={props.selectedAttemptIndex}

                skillName={props.questionAsked.skill?.name}
                moderators={props.questionAsked.moderators}
                userId={getCurrentUserId()}

                isCandidateSerie={props.candidateUuid}
                questionIndex={props.questionAsked.initIndex + 1}
                nbQuestions={props.questionsNumber}
                language={language}
                isOnChapterContent={props.isOnChapterContent}
                isQuestionMark={props.questionAsked.isMark}

                onQuestionMark={props.onQuestionMark}
                handleOnEditQuestion={props.handleOnEditQuestion}
                handleOnDeleteQuestion={handleOnDeleteQuestion}
                handleOnClickHistoryResult={props.handleOnClickHistoryResult}
                translation={handleTranslation}
                questionType={props.questionAsked.class} />

            <QuestionBody
                statement={props.questionAsked.statement}
                videoStatement={props.questionAsked.urlVideo}
                answers={answers}
                answered={isChapterHistoryView || props.isInHistory || (hasAnsweredWithoutCourse || props.hasAnsweredWithCourse) || questionSelectedHistory}
                isExamMode={props.isExamMode}
                isAnswersDisabled={Boolean(props.disableAnswers) && !props.isExamMode}
                isCandidateSerie={props.candidateUuid || props.isExamMode}
                hasCollapseAnswersButton={props.collapseAnswers}
                questionType={props.questionAsked.class}
                isDisplayingExplanation={isDisplayingExplanation}
                isInteractiveQuizQuestion={Boolean(props.quizUuidFromPlayer)}
                isCurrentInteractiveQuizQuestionValidated={props.isCurrentInteractiveQuizQuestionValidated}
                imagesWithAnswersSelected={props.imagesWithAnswersSelected}
                isSendingFile={isSendingFile}
                questionAsked={props.questionAsked}
                isHistory={props.isInHistory || questionSelectedHistory}
                onShowAiHelp={props.onShowAiHelp}
                setIsFetchingAiResponse={props.setIsFetchingAiResponse}
                isFetchingAiResponse={props.isFetchingAiResponse}
                disableCourse={props.disableCourse}

                audioBlobUrl={audioBlobUrl}
                setAudioBlobUrl={setAudioBlobUrl}
                textAnswer={textAnswer}
                setTextAnswer={setTextAnswer}
                totalText={totalText}
                setTotalText={setTotalText}
                onToggleCheckbox={toggleCheckbox}
                saveAudioBlobUrl={saveAudioBlobUrl}
                saveAudioTextAnswer={saveAudioTextAnswer} />
            {!props.hideFooter &&
                <QuestionFooter
                    redirect={props.redirect}
                    explanation={explanation || questionSelectedHistory?.question?.explanation}
                    reviewText={reviewText}
                    reviewGrade={reviewGrade}
                    questionState={props.questionAsked.questionStatus}
                    chapterIds={chapterIds}
                    isExamMode={props.isExamMode}

                    hasAnsweredWithoutCourse={props.isInHistory || hasAnsweredWithoutCourse}
                    hasAnsweredWithCourse={props.hasAnsweredWithCourse || isChapterHistoryView}
                    isInSerie={props.questionsNumber !== null && props.questionsNumber !== undefined}
                    isCandidateSerie={props.candidateUuid}
                    questionIndex={props.questionIndex}
                    questionId={props.questionAsked.id}
                    questionLanguage={language}
                    isLastQuestion={props.isLastQuestion}
                    hideReturnButton={props.hideReturnButton}
                    hideValidateButton={props.disableAnswers || isChapterHistoryView}
                    isOnChapterContent={props.isOnChapterContent}
                    disableCourse={props.disableCourse}
                    isShowChapterCourse={props.isShowChapterCourse}
                    isInteractiveQuizQuestion={Boolean(props.quizUuidFromPlayer)}
                    isInteractiveQuizLeader={Boolean(props.quizUuidFromLeader)}
                    isLeaderDisplayingAnswers={Boolean(props.imagesWithAnswersSelected?.length)}
                    onNextQuestion={toNextQuestion}
                    onPreviousQuestion={toPreviousQuestion}
                    onLanguageChange={setLanguage}
                    onValidateCandidateQuestion={validateQuestion}
                    hasValidationFailed={hasValidationFailed}
                    onShowChapterCourses={props.onShowChapterCourses}
                    onEndSerieClick={props.onEndSerieClick}
                    isDisplayingExplanation={isDisplayingExplanation}
                    setIsDisplayingExplanation={setIsDisplayingExplanation}

                    onShowAiHelp={props.onShowAiHelp}
                    setIsFetchingAiResponse={props.setIsFetchingAiResponse}
                    isFetchingAiResponse={props.isFetchingAiResponse}
                    isDisplayingReview={isDisplayingReview}
                    setIsDisplayingReview={setIsDisplayingReview}
                    audioBlobUrl={audioBlobUrl}
                    textAnswer={textAnswer}
                    questionType={props.questionAsked.class}
                />
            }
            <DeleteQuestionConfirmationDialog
                open={isDeleteQuestionConfirmationOpen}
                handleDeleteQuestionCancelClick={handleDeleteQuestionCancelClick}
                handleDeleteQuestionConfirmClick={handleDeleteQuestionConfirmClick}
            />
        </Container>
        {isLoading && <LinearProgress className={classes.loader}/>}
        {isTranslationMode &&
         translatedQuestion &&
        (<Container className={classes.questionContainer} label={`#${props.questionAsked.id}`}>
            <QuestionBody
                statement={translatedQuestion?.statement}
                answers={translatedQuestion?.answers}
                answered={props.isInHistory || (hasAnsweredWithoutCourse || props.hasAnsweredWithCourse)}
                isAnswersDisabled={Boolean(props.disableAnswers) && !props.isExamMode}
                isCandidateSerie={props.candidateUuid || props.isExamMode}
                hasCollapseAnswersButton={props.collapseAnswers}
                questionType={props.questionAsked.class}
                isDisplayingExplanation={isDisplayingExplanation}
                isInteractiveQuizQuestion={Boolean(props.quizUuidFromPlayer)}
                isCurrentInteractiveQuizQuestionValidated={props.isCurrentInteractiveQuizQuestionValidated}
                imagesWithAnswersSelected={props.imagesWithAnswersSelected}
                audioBlobUrl={audioBlobUrl}
                setAudioBlobUrl={setAudioBlobUrl}
                isSendingFile={isSendingFile}
                questionAsked={props.questionAsked}
                isHistory={props.isInHistory}
                textAnswer={props.questionAsked.textAnswer}
                setTextAnswer={setTextAnswer}
                boxChecked={true}
                onToggleCheckbox={toggleCheckbox}
                saveAudioBlobUrl={saveAudioBlobUrl}
                saveAudioTextAnswer={saveAudioTextAnswer}
                isTranslationMode={isTranslationMode} />
        </Container>)
        }
        </>
    );
}

QuestionContainer.propTypes = {
    savedState: PropTypes.object,
    saveCurrentState: PropTypes.func,

    // Global props
    questionAsked: PropTypes.object.isRequired,
    questionWithExplanation: PropTypes.object,
    questionHistory: PropTypes.array,
    selectedAttemptIndex: PropTypes.number,
    setSelectedAttemptIndex: PropTypes.func,
    redirect: PropTypes.string,
    interactiveQuizAnswers: PropTypes.array,
    imagesWithAnswersSelected: PropTypes.array,
    chapterId: PropTypes.number,

    // Serie props
    serieHistoryUpdate: PropTypes.object,
    questionIndex: PropTypes.number,
    questionsNumber: PropTypes.number,
    isLastQuestion: PropTypes.bool,
    candidateUuid: PropTypes.string,
    quizUuidFromLeader: PropTypes.string,
    quizUuidFromPlayer: PropTypes.string,
    hasAnsweredWithCourse: PropTypes.bool,
    setHasAnsweredWithCourse: PropTypes.func,
    interactiveQuizSerieHistoryUpdate: PropTypes.object,
    interactiveQuizTotalNbQuestions: PropTypes.number,

    // Display props
    shuffleAnswers: PropTypes.bool,
    collapseAnswers: PropTypes.bool,
    disableAnswers: PropTypes.bool,
    hideFooter: PropTypes.bool,
    hideReturnButton: PropTypes.bool,
    isOnChapterContent: PropTypes.bool,
    isShowChapterCourse: PropTypes.bool,
    disableCourse: PropTypes.bool,
    isInHistory: PropTypes.bool,
    isExamMode: PropTypes.bool,
    isCurrentInteractiveQuizQuestionValidated: PropTypes.bool,

    // Callbacks
    handleOnEditQuestion: PropTypes.func,
    handleOnClickHistoryResult: PropTypes.func,
    onQuestionMark: PropTypes.func,
    onEndSerieClick: PropTypes.func,
    onNextQuestion: PropTypes.func,
    onPreviousQuestion: PropTypes.func,
    onIncrGoodAnswers: PropTypes.func,
    onShowChapterCourses: PropTypes.func,
    onValidateCandidateQuestion: PropTypes.func,
    saveDurationLeft: PropTypes.func,
    onShowAiHelp: PropTypes.func,
    setIsFetchingAiResponse: PropTypes.func,
    isFetchingAiResponse: PropTypes.bool
};

export default QuestionContainer;
