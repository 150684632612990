import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { selectPromos } from '../../../redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import SidePanelExpertPromoEntry from './SidePanelExcilyensPromoEntry';
import { arrayOf, number } from 'prop-types';
import './SidePanelExcilyensPromo.scss';


export function SidePanelExcilyensPromo(props) {
    return (
        <div className="side-panel-promo-filter" data-testid="side-panel-excilyens-promo">
            <Typography className="sub-title">Promo</Typography>
            {
                props.promos.map((promo, index) =>
                    <SidePanelExpertPromoEntry key={index} promo={promo} />
                )
            }
        </div>
    );
}

SidePanelExcilyensPromo.propTypes = {
    promos: arrayOf(number)
};

function mapStateToProps(state) {
    return {
        promos: selectPromos(state)
    };
}

export default connect(mapStateToProps)(SidePanelExcilyensPromo);
