import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import './FirstConnection.scss';
import PropTypes from 'prop-types';
import FormCreateUser from './FormCreateUser';
import AddSkillTable from '../../common/addSkillTable/AddSkillTable';
import { notificationSuccess } from '../../redux/actions/Notification/notifications.actions';
import { SKILL_SET_URI } from '../../api/skillSetApi';
import { withRouter } from 'react-router-dom';
import { WELCOME_MESSAGE } from '../../Snackbar/NotificationMessages';
import DialogHelp from './DialogHelp';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import Login from '../../login/components/Login';

export class FirstConnection extends Component {

    STEP_CREATE_PROFILE = 0;

    STEP_CHOSE_SKILL = 1;

    state = {
        activeStep: 0
    };

    getSteps = () => [
        'Informations de profil',
        'Compétences'
    ];

    getStepsContent = (step) => {
        switch (step) {
            case this.STEP_CREATE_PROFILE:
                return <div className="container-create-user">
                    <FormCreateUser
                        handleNext={this.handleNext}/>
                </div>;
            case this.STEP_CHOSE_SKILL:
                return <>
                    <DialogHelp/>
                    <AddSkillTable/>
                </>;
            default:
                return 'Etape inconnue ...';
        }
    };

    handleNext = () => {

        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1
        });
        if(this.state.activeStep + 1 === this.getSteps().length) {
            this.props.welcomeMSG();
            this.props.history.push(SKILL_SET_URI);
        }
    };

    render() {
        const { activeStep } = this.state;
        const steps = this.getSteps();
        return (
            <Fragment>
                {AuthenticationContainer.isConnected()
                    ? <Fragment>
                        <div data-testid = "stepper">
                            <Stepper activeStep={activeStep}>
                                {steps.map((label) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>
                        <div className="body-first-connection" data-testid="first-connection">
                            <div className="container-step-content">
                                {this.getStepsContent(activeStep)}
                            </div>
                            <div className="container-step-button">
                                <div data-testid="next-button">
                                    {activeStep === steps.length - 1
                                        ? <Button
                                            data-testid="finish-button"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.handleNext();
                                            }}>
                                    Terminer
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    : <Login url="/chat"/>}
            </Fragment>
        );
    }
}

FirstConnection.propTypes = {
    history: PropTypes.object,
    welcomeMSG: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        welcomeMSG: () => dispatch(notificationSuccess(WELCOME_MESSAGE))
    };
}

export default connect(null, mapDispatchToProps)(withRouter(FirstConnection));
