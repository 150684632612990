import React, { useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';
import InteractiveQuizRoom from './InteractiveQuizRoom';
import { selectCurrentQuestionIdRoomByUuid,
    selectUsersRoomByUuid,
    selectHostIdRoomByUuid } from 'app/redux/selectors/InteractiveQuiz/interactiveQuizRoom.selector';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addParticipant, removeParticipant, resetRooms } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';
import { postEndOfQuizApi,
    postRoomExistsApi,
    postUserJoinedInteractiveQuizRoomApi,
    postUserLeftInteractiveQuizRoomApi } from 'app/api/interactiveQuizApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { WebSocketActionsEnum } from 'app/utils/WebsocketActionsEnum';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { selectSerieHistoryUpdate } from 'app/redux/selectors/Question/history.selector';
import { patchPendingSerieIsDoneApi } from 'app/api/historyApi';


export function InteractiveQuiz(props) {
    const uuidFromUrl = props.match.params.uuid;

    const dispatch = useDispatch();
    const currentUserId = AuthenticationContainer.getCurrentUserId();
    const currentQuestionId = useSelector(selectCurrentQuestionIdRoomByUuid(uuidFromUrl));
    const users = useSelector(selectUsersRoomByUuid(uuidFromUrl));
    const hostId = useSelector(selectHostIdRoomByUuid(uuidFromUrl));
    const serieHistoryUpdate = useSelector(selectSerieHistoryUpdate);

    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        let timeout = 0;
        if(!hostId) {
            timeout = setTimeout(() => {
                setShouldRedirect(true);
            }, 5000);
        }
        return () => clearTimeout(timeout);
    }, [hostId]);

    useEffect(() => () => {
        if(hostId === currentUserId) {
            postEndOfQuizApi(uuidFromUrl);
        } else {
            postUserLeftInteractiveQuizRoomApi(currentUserId, uuidFromUrl);
        }
        dispatch(resetRooms());
    }, []);

    if(shouldRedirect) {
        return <Redirect to="/skills" />;
    }

    const interactiveQuizClassNames = classNames({
        'interactive-quiz-loader-container': !hostId,
        'interactive-quiz': hostId
    });

    return (
        <div data-testid="interactive-quiz" className={interactiveQuizClassNames}>
            <SockJsClient url={`${process.env.REACT_APP_BACK_END}websocket`}
                topics={[`/quiz/${uuidFromUrl}`, `/quiz/${uuidFromUrl}/userId/${currentUserId}`]}
                onConnect={() => {
                    postUserJoinedInteractiveQuizRoomApi(currentUserId, uuidFromUrl);
                }}
                onDisconnect={() => {
                    if(hostId === currentUserId) {
                        postEndOfQuizApi(uuidFromUrl);
                    } else {
                        postUserLeftInteractiveQuizRoomApi(currentUserId, uuidFromUrl);
                    }
                }}
                onMessage={(msg) => {
                    const [action, rest] = msg.split('|');
                    switch (action) {
                        case WebSocketActionsEnum.ADD: {
                            const [userId, imageUrl, firstName, lastName] = rest.split(' ');
                            dispatch(addParticipant({ uuid: uuidFromUrl, id: userId, imageUrl, firstName, lastName }));
                            break;
                        }
                        case WebSocketActionsEnum.REMOVE: {
                            const userId = rest?.split(' ')[0];
                            dispatch(removeParticipant({ uuid: uuidFromUrl, id: userId }));
                            break;
                        }
                        case WebSocketActionsEnum.QUIZ_ENDED: {
                            patchPendingSerieIsDoneApi(serieHistoryUpdate.id, currentUserId);
                            setShouldRedirect(true);
                            break;
                        }
                        case WebSocketActionsEnum.CHECK: {
                            if(hostId !== '-1' && hostId === currentUserId) {
                                postRoomExistsApi(uuidFromUrl, users.flatMap((user) => user.id), currentQuestionId);
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }}
            />
            { hostId &&
                <InteractiveQuizRoom
                    uuid={uuidFromUrl}
                    users={users}
                    currentQuestionId={currentQuestionId}
                    hostId={hostId}
                />
            }

            { !hostId && <CircularProgress style={{ width: 50, height: 50 }}/> }
        </div>
    );
}

InteractiveQuiz.propTypes = {
    match: PropTypes.object
};

export default withRouter(InteractiveQuiz);
