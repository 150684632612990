import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import QuestionTable from '../QuestionTable/QuestionTable';
import SerieTable from 'app/serie/SerieTable/SerieTable';
import History from '../history/History';
import SidePanelQuestions from 'app/components/sidePanel/question/SidePanelQuestions';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth/withWidth';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Add } from '@material-ui/icons';
import {
    Grid, Icon, IconButton,
    SwipeableDrawer, Tabs, Tab, Hidden
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { selectTabIndex } from 'app/redux/selectors/Question/question.selector';
import { selectDisplayLeftDrawer } from 'app/redux/selectors/Display/displaySelector';
import { skillActions } from 'app/redux/slices/skills.slice';
import { getAllUsers } from 'app/redux/actions/User/user.actions';
import { setTabIndex } from 'app/redux/actions/Question/question.actions';
import { setDisplayLeftDrawer } from 'app/redux/actions/Display/display.actions';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import EntretienHistory from '../history/entretienHistory/EntretienHistory';

function QuestionTablePage(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const tabIndex = useSelector(selectTabIndex);
    const displayLeftDrawer = useSelector(selectDisplayLeftDrawer);

    const [isOpenCreateButton, setOpenCreateButton] = useState(false);

    useEffect(() => {
        dispatch(skillActions.getAllReducedSkills());
        dispatch(getAllUsers());
    }, []);

    const handleTabIndex = (event, newTabIndex) => {
        dispatch(setTabIndex(newTabIndex));
    };

    const handleToggleCreateButton = () => {
        setOpenCreateButton(!isOpenCreateButton);
    };

    const handleCloseCreateButton = (event, reason) => {
        if(reason && !reason.localeCompare('escapeKeyDown')) {
            setOpenCreateButton(false);
        }
    };

    const toggleCloseLeftDrawer = () => {
        dispatch(setDisplayLeftDrawer(false));
    };

    const toggleOpenLeftDrawer = () => {
        dispatch(setDisplayLeftDrawer(true));
    };

    const actions = [
        {
            icon: <FileCopyIcon />,
            name: 'Série',
            onClick: () => history.push(`${QUESTIONS_PAGE_URL}/serie/create`),
            className: 'addSerieAction',
            cyData: 'create-serie'
        },
        {
            icon: <Add />,
            name: 'Question',
            onClick: () => history.push(`${QUESTIONS_PAGE_URL}/create`),
            className: 'addQuestionAction',
            cyData: 'create-question'
        }

    ];

    return (
        <div>
            <div className="tabQuestions">
                <Tabs
                    value={tabIndex}
                    onChange={handleTabIndex}
                    indicatorColor="primary"
                >
                    <Tab label="Questions" id="0" />
                    <Tab label="Séries" id="1" />
                    <Tab label="Historique" id="2" />
                    <Tab label="Entretien" id="3" />
                </Tabs>
            </div>
            {tabIndex === 0 &&
                <div className="tabQuestionTab">
                    <Grid container>
                        <Grid item xs={12} md={3} lg={2} id="question-filter" className="desktop-side-panel-question">
                            {isWidthUp('md', props.width) &&
                                <div className="filter">
                                    <SidePanelQuestions />
                                </div>
                            }
                        </Grid>
                        <Grid item xs={12} md={9} lg={10} className="desktop-side-panel-question">
                            <QuestionTable className="desktop-table-question" />
                        </Grid>
                    </Grid>
                    <Hidden mdUp>
                        <SwipeableDrawer
                            className="questionsSwipeableDrawer"
                            open={displayLeftDrawer}
                            anchor="left"
                            onOpen={toggleOpenLeftDrawer}
                            onClose={toggleCloseLeftDrawer}
                        >
                            <div id="leftDrawer">
                                <IconButton onClick={toggleCloseLeftDrawer} className="drawer-icon">
                                    <Icon>chevron_left</Icon>
                                </IconButton>
                                <SidePanelQuestions />
                            </div>
                        </SwipeableDrawer>
                    </Hidden>
                </div>
            }

            {tabIndex === 1 &&
                <div className="tabSerieTable">
                    <SerieTable/>
                </div>
            }
            {tabIndex === 2 &&
                <div className="tabHistoryTable">
                    <History displayGraph={false}/>
                </div>
            }
            {tabIndex === 3 &&
                <div className="tabEntretienTable">
                    <EntretienHistory />
                </div>
            }

            <SpeedDial
                color="primary"
                icon={<Add />}
                onClose={handleCloseCreateButton}
                onClick={handleToggleCreateButton}
                ariaLabel="Créer une question ou une série"
                className="createButton"
                id="createButton"
                open={isOpenCreateButton}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        delay={1000}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={action.onClick}
                        className={action.className}
                        cy-data={action.cyData}
                    />
                ))}
            </SpeedDial>
        </div>
    );
}

QuestionTablePage.propTypes = {
    width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired
};

export default withWidth()(QuestionTablePage);
