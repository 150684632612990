import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, withRouter } from 'react-router-dom';
import ResolveSerieRequestBySerieId from './ResolveSerie/ResolveSerieRequestBySerieId';
import { QUESTIONS_PAGE_URL } from 'app/Routes';

function SerieResolveRoute(props) {

    const serieId = Number(props.match.params.id);

    return (
        <>
            {(serieId && serieId > 0)
                ? <ResolveSerieRequestBySerieId serieId={serieId} />
                : <Redirect to={QUESTIONS_PAGE_URL} />
            }
        </>
    );
}
SerieResolveRoute.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(SerieResolveRoute);
