import {
    postQuestionReportingApi,
    getQuestionsReportingApi,
    patchQuestionDeniedReportingApi,
    patchQuestionTreatedReportingApi
} from 'app/api/questionReportingApi';

import { notificationError, notificationSuccess } from '../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    GET_QUESTION_REPORTING_ERROR,
    PATCH_QUESTION_REPORTING_ERROR,
    QUESTION_REPORTING_CREATED,
    QUESTION_REPORTING_ERROR,
    QUESTION_REPORTING_ERROR_404
} from 'app/Snackbar/NotificationMessages';

export const POST_QUESTION_REPORTING_REQUEST = 'POST_QUESTION_REPORTING_REQUEST';
export const POST_QUESTION_REPORTING_SUCCESS = 'POST_QUESTION_REPORTING_SUCCESS';
export const POST_QUESTION_REPORTING_FAILURE = 'POST_QUESTION_REPORTING_FAILURE';

export const GET_QUESTION_REPORTING_BY_ID_REQUEST = 'GET_QUESTION_REPORTING_BY_ID_REQUEST';
export const GET_QUESTION_REPORTING_BY_ID_SUCCESS = 'GET_QUESTION_REPORTING_BY_ID_SUCCESS';
export const GET_QUESTION_REPORTING_BY_ID_FAILURE = 'GET_QUESTION_REPORTING_BY_ID_FAILURE';

export const RESET_QUESTION_REPORTING = 'RESET_QUESTION_REPORTING';

export const PATCH_QUESTION_REPORTING_DENIED_REQUEST = 'PATCH_QUESTION_REPORTING_DENIED_REQUEST';
export const PATCH_QUESTION_REPORTING_DENIED_SUCCESS = 'PATCH_QUESTION_REPORTING_DENIED_SUCCESS';
export const PATCH_QUESTION_REPORTING_DENIED_FAILURE = 'PATCH_QUESTION_REPORTING_DENIED_FAILURE';

export const PATCH_QUESTION_REPORTING_TRAITED_REQUEST = 'PATCH_QUESTION_REPORTING_TRAITED_REQUEST';
export const PATCH_QUESTION_REPORTING_TRAITED_SUCCESS = 'PATCH_QUESTION_REPORTING_TRAITED_SUCCESS';
export const PATCH_QUESTION_REPORTING_TRAITED_FAILURE = 'PATCH_QUESTION_REPORTING_TRAITED_FAILURE';

export const getQuestionReporting = (userId, questionId) => (dispatch) => {
    dispatch({ type: GET_QUESTION_REPORTING_BY_ID_REQUEST });

    return getQuestionsReportingApi(userId, questionId).then((response) => {
        dispatch({
            type: GET_QUESTION_REPORTING_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTION_REPORTING_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationSuccess(EXPIRED_SESSION));
        } else if(error.response && error.response.status !== 403) {
            dispatch(notificationError(GET_QUESTION_REPORTING_ERROR));
        }
    });
};

export const resetQuestionsReporting = () => (dispatch) => {
    dispatch({ type: RESET_QUESTION_REPORTING });
};

export const postQuestionReporting = (questionReporting) => (dispatch) => {
    dispatch({ type: POST_QUESTION_REPORTING_REQUEST });

    return postQuestionReportingApi(questionReporting).then((response) => {
        dispatch({
            type: POST_QUESTION_REPORTING_SUCCESS,
            payload: questionReporting
        });
        if(response && response.status === 201) {
            dispatch(notificationSuccess(QUESTION_REPORTING_CREATED));
        }
    }, (error) => {
        dispatch({
            type: POST_QUESTION_REPORTING_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationSuccess(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(QUESTION_REPORTING_ERROR_404));
        } else {
            dispatch(notificationError(QUESTION_REPORTING_ERROR));
        }
    });
};


export const patchDeniedQuestionReporting = (userId, questionId, reportingId) => (dispatch) => {

    dispatch({ type: PATCH_QUESTION_REPORTING_DENIED_REQUEST });

    return patchQuestionDeniedReportingApi(userId, questionId, reportingId).then((response) => {
        dispatch({
            type: PATCH_QUESTION_REPORTING_DENIED_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: PATCH_QUESTION_REPORTING_DENIED_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationSuccess(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(PATCH_QUESTION_REPORTING_ERROR));
        }
    });
};

export const patchTreatedQuestionReporting = (userId, questionId, reportingId) => (dispatch) => {
    dispatch({ type: PATCH_QUESTION_REPORTING_TRAITED_REQUEST });

    return patchQuestionTreatedReportingApi(userId, questionId, reportingId).then((response) => {
        dispatch({
            type: PATCH_QUESTION_REPORTING_TRAITED_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: PATCH_QUESTION_REPORTING_TRAITED_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationSuccess(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(PATCH_QUESTION_REPORTING_ERROR));
        }
    });
};
