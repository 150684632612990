import React from 'react';
import PropTypes from 'prop-types';

import { formatToDate, isBeforeToday } from 'app/utils/date.functions';
import { Redirect, withRouter } from 'react-router-dom';
import ResolveSerieRequestByUserId from 'app/serie/ResolveSerie/ResolveSerieRequestByUserId';
import { QUESTIONS_PAGE_URL } from 'app/Routes';

export function DailyQuestions(props) {

    const date = formatToDate(props.match.params.date);

    const validateUrl = () => (
        date && isBeforeToday(date)
    );

    return (
        <>
            {validateUrl()
                ? <ResolveSerieRequestByUserId date={date} />
                : <Redirect to={QUESTIONS_PAGE_URL} />
            }
        </>
    );
}


DailyQuestions.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(DailyQuestions);
