
import {
    GET_COACHES_BY_STUDENT_ID_SUCCESS,
    GET_STUDENTS_BY_COACH_ID_SUCCESS,
    GET_USER_ASKED_COACHES_SUCCESS,
    GET_STUDENTS_ASKING_RELATION_SUCCESS,
    POST_ASKING_COACHING_SUCCESS,
    PATCH_ACCEPT_COACHING_ASKED_RELATION_SUCCESS,
    DELETE_DENY_COACHING_ASKED_RELATION_SUCCESS
} from '../../actions/Coaching/coaching.actions';

export const students = 'students';
export const coaches = 'coaches';
export const userAskedCoaches = 'userAskedCoaches';
export const studentsAskingRelation = 'studentsAskingRelation';


export default function coachingReducer(state = {
    [coaches]: [],
    [students]: [],
    [userAskedCoaches]: [],
    [studentsAskingRelation]: []
}, action) {
    switch (action.type) {
        case GET_COACHES_BY_STUDENT_ID_SUCCESS:
            return {
                ...state,
                [coaches]: action.payload
            };
        case GET_STUDENTS_BY_COACH_ID_SUCCESS:
            return {
                ...state,
                [students]: action.payload
            };
        case GET_USER_ASKED_COACHES_SUCCESS:
            return {
                ...state,
                [userAskedCoaches]: action.payload
            };
        case GET_STUDENTS_ASKING_RELATION_SUCCESS:
            return {
                ...state,
                [studentsAskingRelation]: action.payload
            };
        case POST_ASKING_COACHING_SUCCESS:
            const newCoachsRequests = state[userAskedCoaches].slice();
            newCoachsRequests.push(action.payload.coach);
            return {
                ...state,
                [userAskedCoaches]: newCoachsRequests
            };
        case PATCH_ACCEPT_COACHING_ASKED_RELATION_SUCCESS:
            let oldStudentAskingRelationList = state[studentsAskingRelation].slice();
            oldStudentAskingRelationList = oldStudentAskingRelationList
                .filter((studentAskingRelation) => studentAskingRelation.id !== action.payload.student.id);
            const newStudentList = state[students].slice();
            newStudentList.push(action.payload.student);
            return {
                ...state,
                [studentsAskingRelation]: oldStudentAskingRelationList,
                [students]: newStudentList
            };
        case DELETE_DENY_COACHING_ASKED_RELATION_SUCCESS:
            const newStudentAskingRelationList = state[studentsAskingRelation].slice()
                .filter((studentAskingRelation) => studentAskingRelation.id !== action.payload);
            return {
                ...state,
                [studentsAskingRelation]: newStudentAskingRelationList
            };
        default:
            return state;
    }
}
