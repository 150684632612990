import {
    allClientSetStore,
    clientStore
} from '../../reducers/Client/clients.reducer';
import { clientsStore } from '../../rootReducer';

export function selectAllClients(state) {
    return state[clientsStore][allClientSetStore];
}

export function selectClient(state) {
    return state[clientsStore][clientStore];
}
