import {
    getRandomSerieByChapterIdApi,
    getSpacedRepetitionSerieByUserIdApi,
    getSerieBySerieIdApi,
    getSerieByIdWithQuestionReducedApi,
    getSeriesByCriteriaApi,
    getSerieBySerieHistoryIdApi
} from '../../../../../api/seriesApi';

import { notificationError } from '../../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_SERIES_ERROR,
    RETRIEVE_SERIE_ERROR,
    RETRIEVE_SERIE_ERROR_404
} from '../../../../../Snackbar/NotificationMessages';

export const GET_RANDOM_SERIE_BY_CHAPTER_ID = 'GET_RANDOM_SERIE_BY_CHAPTER_ID';
export const GET_RANDOM_SERIE_BY_CHAPTER_ID_REQUEST = 'GET_RANDOM_SERIE_BY_CHAPTER_ID_REQUEST';
export const GET_RANDOM_SERIE_BY_CHAPTER_ID_SUCCESS = 'GET_RANDOM_SERIE_BY_CHAPTER_ID_SUCCESS';
export const GET_RANDOM_SERIE_BY_CHAPTER_ID_FAILURE = 'GET_RANDOM_SERIE_BY_CHAPTER_ID_FAILURE';
export const GET_MODERATORS_BY_CHAPTER_ID = 'GET_MODERATORS_BY_CHAPTER_ID';
export const GET_MODERATORS_BY_CHAPTER_ID_REQUEST = 'GET_MODERATORS_BY_CHAPTER_ID_REQUEST';
export const GET_MODERATORS_BY_CHAPTER_ID_SUCCESS = 'GET_MODERATORS_BY_CHAPTER_ID_SUCCESS';
export const GET_MODERATORS_BY_CHAPTER_ID_FAILURE = 'GET_MODERATORS_BY_CHAPTER_ID_FAILURE';

export const GET_SPACED_REPETITION_SERIE_BY_USER_ID = 'GET_SPACED_REPETITION_SERIE_BY_USER_ID';
export const GET_SPACED_REPETITION_SERIE_BY_USER_ID_REQUEST = 'GET_SPACED_REPETITION_SERIE_BY_USER_ID_REQUEST';
export const GET_SPACED_REPETITION_SERIE_BY_USER_ID_SUCCESS = 'GET_SPACED_REPETITION_SERIE_BY_USER_ID_SUCCESS';
export const GET_SPACED_REPETITION_SERIE_BY_USER_ID_FAILURE = 'GET_SPACED_REPETITION_SERIE_BY_USER_ID_FAILURE';

export const GET_SERIE_BY_SERIE_ID_REQUEST = 'GET_SERIE_BY_SERIE_ID_REQUEST';
export const GET_SERIE_BY_SERIE_ID_SUCCESS = 'GET_SERIE_BY_SERIE_ID_SUCCESS';
export const GET_SERIE_BY_SERIE_ID_FAILURE = 'GET_SERIE_BY_SERIE_ID_FAILURE';

export const GET_SERIE_BY_SERIE_HISTORY_ID_REQUEST = 'GET_SERIE_BY_SERIE_HISTORY_ID_REQUEST';
export const GET_SERIE_BY_SERIE_HISTORY_ID_SUCCESS = 'GET_SERIE_BY_SERIE_HISTORY_ID_SUCCESS';
export const GET_SERIE_BY_SERIE_HISTORY_ID_FAILURE = 'GET_SERIE_BY_SERIE_HISTORY_ID_FAILURE';


export const SET_QUESTION_LIST_TO_RESOLVE = 'SET_QUESTION_LIST_TO_RESOLVE';
export const SET_QUESTION_INDEX_TO_RESOLVE = 'SET_QUESTION_INDEX_TO_RESOLVE';

export const GET_SERIE_BY_ID_WITH_QUESTION_REDUCED = 'GET_SERIE_BY_ID_WITH_QUESTION_REDUCED';
export const GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_REQUEST = 'GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_REQUEST';
export const GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_SUCCESS = 'GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_SUCCESS';
export const GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_FAILURE = 'GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_FAILURE';

export const GET_SERIES_BY_CRITERIA_TO_DISPLAY_REQUEST = 'GET_SERIES_BY_CRITERIA_TO_DISPLAY_REQUEST';
export const GET_SERIES_BY_CRITERIA_TO_DISPLAY_SUCCESS = 'GET_SERIES_BY_CRITERIA_TO_DISPLAY_SUCCESS';
export const GET_SERIES_BY_CRITERIA_TO_DISPLAY_FAILURE = 'GET_SERIES_BY_CRITERIA_TO_DISPLAY_FAILURE';

export const RESET_SERIE_WITH_QUESTION_REDUCED = 'RESET_SERIE_WITH_QUESTION_REDUCED';
export const RESET_SERIE_MODIFICATION_SAVED = 'RESET_SERIE_MODIFICATION_SAVED';
export const RESET_SERIE_TO_RESOLVE = 'RESET_SERIE_TO_RESOLVE';

export const SET_EXAM_MODE = 'SET_EXAM_MODE';

export const GET_SERIES_BY_CHAPTER_ID = 'GET_SERIES_BY_CHAPTER_ID';
export const GET_SERIES_BY_CHAPTER_ID_REQUEST = 'GET_SERIES_BY_CHAPTER_ID_REQUEST';
export const GET_SERIES_BY_CHAPTER_ID_SUCCESS = 'GET_SERIES_BY_CHAPTER_ID_SUCCESS';
export const GET_SERIES_BY_CHAPTER_ID_FAILURE = 'GET_SERIES_BY_CHAPTER_ID_FAILURE';

export const QUESTION_INACTIVE = 'QUESTION_INACTIVE';

export const getRandomSerieByChapterId = (chapterId, params) => (dispatch) => {
    dispatch({ type: GET_RANDOM_SERIE_BY_CHAPTER_ID_REQUEST });
    dispatch({ type: GET_MODERATORS_BY_CHAPTER_ID_REQUEST });

    return getRandomSerieByChapterIdApi(chapterId, params).then((response) => {
        dispatch({
            type: GET_RANDOM_SERIE_BY_CHAPTER_ID_SUCCESS,
            payload: response.data
        });
        dispatch({
            type: GET_MODERATORS_BY_CHAPTER_ID_SUCCESS,
            payload: response.data.questionAskedList
        });
    }, (error) => {
        dispatch({
            type: GET_RANDOM_SERIE_BY_CHAPTER_ID_FAILURE,
            payload: error,
            error: true
        });
        dispatch({
            type: GET_MODERATORS_BY_CHAPTER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};

export const getSpacedRepetitionSerieByUserId = (userId) => (dispatch) => {
    dispatch({ type: GET_SPACED_REPETITION_SERIE_BY_USER_ID_REQUEST });

    return getSpacedRepetitionSerieByUserIdApi(userId).then((response) => {

        const responseData = { ...response.data, type: 'DAILY' };

        dispatch({
            type: GET_SPACED_REPETITION_SERIE_BY_USER_ID_SUCCESS,
            payload: responseData
        });
    }, (error) => {
        dispatch({
            type: GET_SPACED_REPETITION_SERIE_BY_USER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};

export const getRandomSerieByUserIdApi = (userId) => (dispatch) => getRandomSerieByUserIdApi(userId).then((response) => {

    const responseData = { ...response.data, type: 'DAILY' };

    dispatch({
        type: GET_SPACED_REPETITION_SERIE_BY_USER_ID_SUCCESS,
        payload: responseData
    });
}, (error) => {
    dispatch({
        type: GET_SPACED_REPETITION_SERIE_BY_USER_ID_FAILURE,
        payload: error,
        error: true
    });
    if(error.response && error.response.status === 401) {
        dispatch(notificationError(EXPIRED_SESSION));
    } else if(error.response && error.response.status === 404) {
        dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
    } else {
        dispatch(notificationError(RETRIEVE_SERIE_ERROR));
    }
});

export const getSerieBySerieId = (serieId, candidateUuid) => (dispatch) => {
    dispatch({ type: GET_SERIE_BY_SERIE_ID_REQUEST });

    return getSerieBySerieIdApi(serieId, candidateUuid).then((response) => {
        dispatch({
            type: GET_SERIE_BY_SERIE_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_SERIE_BY_SERIE_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};

export const getSerieBySerieHistoryId = (serieHistoryId, userId, candidateUuid) => (dispatch) => {
    dispatch({ type: GET_SERIE_BY_SERIE_HISTORY_ID_REQUEST });

    return getSerieBySerieHistoryIdApi(serieHistoryId, userId, candidateUuid).then((response) => {
        dispatch({
            type: GET_SERIE_BY_SERIE_HISTORY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_SERIE_BY_SERIE_HISTORY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};

export const setQuestionListToResolve = (questionList) => (dispatch) => {
    dispatch({
        type: SET_QUESTION_LIST_TO_RESOLVE,
        payload: questionList
    });
};

export const setQuestionIndexToResolve = (index) => (dispatch) => {
    dispatch({
        type: SET_QUESTION_INDEX_TO_RESOLVE,
        payload: index
    });
};

export const getSeriesByCriteria = (criteria) => (dispatch) => {
    dispatch({ type: GET_SERIES_BY_CRITERIA_TO_DISPLAY_REQUEST });

    return getSeriesByCriteriaApi(criteria).then((response) => {
        dispatch({
            type: GET_SERIES_BY_CRITERIA_TO_DISPLAY_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_SERIES_BY_CRITERIA_TO_DISPLAY_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SERIES_ERROR));
        }
    });
};

export const getSerieByIdWithQuestionReduced = (serieId) => (dispatch) => {
    dispatch({ type: GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_REQUEST });

    return getSerieByIdWithQuestionReducedApi(serieId).then((response) => {
        dispatch({
            type: GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_SUCCESS,
            payload: {
                id: serieId,
                data: response.data
            }
        });
    }, (error) => {
        dispatch({
            type: GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};

export const resetSerieWithQuestionReduced = () => (dispatch) => {
    dispatch({ type: RESET_SERIE_WITH_QUESTION_REDUCED });
};

export const resetSerieModificationSaved = () => (dispatch) => {
    dispatch({ type: RESET_SERIE_MODIFICATION_SAVED });
};

export const resetSerieToResolve = () => (dispatch) => {
    dispatch({ type: RESET_SERIE_TO_RESOLVE });
};

export const setExamMode = (isExamMode) => (dispatch) => {
    dispatch({
        type: SET_EXAM_MODE,
        payload: isExamMode
    });
};

export const setQuestionStatusToInactive = (questionIndex) => (dispatch) => {
    dispatch({
        type: QUESTION_INACTIVE,
        payload: questionIndex
    });
};
