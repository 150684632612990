/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './CreateEditQuestionForm.scss';
import { executeCallbackOnQuestionType, setMcqWithIdKey } from './CreateEditQuestionForm.functions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { createChapterTree, findByChapterId } from 'app/utils/treeStructure/TreeChapter.logic';
import 'antd/dist/antd.min.css';

import {
    Button, useMediaQuery, useTheme,
    DialogTitle, Dialog
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { selectQuestionEdit, selectQuestionModificationIsSaved }
    from '../../../redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { selectAllReportByQuestion } from 'app/redux/selectors/Question/QuestionReporting/questionReporting.selector';
import {
    resetMcqModificationSaved
} from '../../../redux/actions/Question/MultipleChoiceQuestion/MCQSet/MCQSet.actions';
import { getQuestionByIdEdit, resetQuestionToEdit } from '../../../redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { skillActions } from 'app/redux/slices/skills.slice';
import { getQuestionReporting, resetQuestionsReporting } from '../../../redux/actions/Question/QuestionReporting/questionReporting.actions';
import { selectReducedChapters } from '../../../redux/selectors/Chapter/chapter.selector';
import { CreateEditQuestionHeader } from './header/CreateEditQuestionHeader';
import { CreateEditQuestionFooter } from './footer/CreateEditQuestionFooter';
import { CreateEditQuestionBody } from './body/CreateEditQuestionBody';
import QuestionReportingDisplay from '../../QuestionResolver/QuestionReportingDisplay/QuestionReportingDisplay';
import { questionTypeEnum } from 'app/utils/QuestionTypeEnum';
import { parseQuestionATrou } from './body/QuestionATrou/QuestionATrou.functions';
import { cloneDeep } from 'lodash';
import { QuestionAiGenerated, QuestionLayouts } from 'app/utils/QuestionEnum';
import { promptEnum } from 'app/utils/PromptEnum';
import { chapterActions } from 'app/redux/slices/chapters.slice';

const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

const noLanguage = 'zza';
const emptyMCQ = () => ({
    id: null,
    skillId: null,
    class: questionTypeEnum.MultipleChoiceQuestionType,
    layout: QuestionLayouts.ANSWERS_COLUMN,
    chapterIds: [],
    statement: '',
    explanation: '',
    nbAnswersDisplayed: 2,
    answers: [
        {
            id: undefined,
            key: 1,
            label: 'A',
            text: '',
            validAnswer: true
        },
        {
            id: undefined,
            key: 2,
            label: 'B',
            text: '',
            validAnswer: false
        }
    ],
    language: {
        acronym: noLanguage
    },
    creationDate: null,
    creator: {
        id: getCurrentUserId()
    },
    lastEditor: {
        id: getCurrentUserId()
    },
    aiGenerated: QuestionAiGenerated.VERIFIED
});

const emptyQAT = () => ({
    id: null,
    skillId: null,
    class: questionTypeEnum.QuestionTexteATrouType,
    chapterIds: [],
    statement: '',
    explanation: '',
    answers: [],
    creationDate: null,
    creator: {
        id: getCurrentUserId()
    },
    lastEditor: {
        id: getCurrentUserId()
    },
    language: {
        acronym: noLanguage
    }
});

const emptyQO = () => ({
    id: null,
    skillId: null,
    class: questionTypeEnum.QuestionOuverteType,
    chapterIds: [],
    statement: '',
    explanation: '',
    creationDate: null,
    creator: {
        id: getCurrentUserId()
    },
    lastEditor: {
        id: getCurrentUserId()
    },
    language: {
        acronym: noLanguage
    },
    urlVideo: ''
});

export function CreateEditQuestionForm(props) {

    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();

    /**
     * If there's no question given as props, then a blanck one is used (mcq)
     */
    const [question, setQuestion] = useState(emptyMCQ());
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isKeepingText, setIsKeepingText] = useState(false);
    const [skills, setSkills] = useState([]);
    const [chapterTree, setChapterTree] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedChapters, setSelectedChapters] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = React.useState(noLanguage);
    const [selectedPrompt, setSelectedPrompt] = React.useState(null);
    const [valueButton, setValueButton] = useState(questionTypeEnum.MultipleChoiceQuestionType);
    const [rootChapter] = useState(props.chapterSelected);

    const listAllSkill = useSelector(selectAllReducedSkills).data;
    const { data: chapters } = useSelector(selectReducedChapters);
    const questionProvided = useSelector(selectQuestionEdit);
    const questionModificationSaved = useSelector(selectQuestionModificationIsSaved);
    const questionsReporting = useSelector(selectAllReportByQuestion);


    function formNotEmpty() {
        if(props.skillLink && rootChapter) {
            setSelectedSkill(props.skillLink);
            const copyChapter = [rootChapter.id];
            selectedChapters.forEach((element) => !copyChapter.includes(element.content.id) && copyChapter.push(element.content.id));
            setQuestion((previousQuestion) => ({
                ...previousQuestion,
                skillId: props.skillLink.id,
                chapterIds: copyChapter
            }));
        }
    }

    useEffect(() => {
        formNotEmpty();
    }, []);

    function resetForm() {
        if(!isKeepingText) {
            setSelectedSkill(null);
            setSelectedPrompt(null);
            setSelectedLanguage(noLanguage);
            executeCallbackOnQuestionType(
                valueButton,
                {
                    mcqCallback: () => setQuestion(emptyMCQ()),
                    questionATrouCallback: () => setQuestion(emptyQAT()),
                    questionOuverteCallback: () => setQuestion(emptyQO())
                }
            );
            formNotEmpty();
        }
    }

    function resetBodyForm(questionType) {
        const questionBody = executeCallbackOnQuestionType(questionType, { mcqCallback: () => emptyMCQ(), questionATrouCallback: () => emptyQAT(), questionOuverteCallback: () => emptyQO() });
        const chapterIds = selectedChapters.map(node => node.content.id);

        setQuestion({
            ...questionBody,
            chapterIds,
            id: question.id,
            skillId: question.skillId
        });
    }

    function handleChangeButton(event, newValue) {
        if(newValue !== question.class) {
            setValueButton(newValue);
            resetBodyForm(newValue);
            if(newValue === 'MultipleChoiceQuestion') {
                setQuestion((prevMCQ) => ({
                    ...prevMCQ,
                    layout: QuestionLayouts.ANSWERS_COLUMN
                }));
            }
            if(newValue === 'QuestionOuverte') {
                setQuestion((prevMCQ) => ({
                    ...prevMCQ,
                    prompt: promptEnum.CLASSIQUE
                }));
            }
        }
    }

    const handleCloseCancel = () => {
        setDialogOpen(false);
    };

    const handleConfirmCancel = () => {
        resetForm();
        props.onCloseCreateEdit();
    };

    const handleChangeSelectSkill = (_val, newSkill) => {
        setSelectedSkill(newSkill);
        setQuestion((previousQuestion) => ({
            ...previousQuestion,
            skillId: newSkill ? newSkill.id : null,
            chapterIds: []
        }));
    };

    const handleChangeSelectChapter = (newValue) => {
        const newChapter = [];
        newValue.forEach((content) => {
            newChapter.push(chapterTree.find(content));
        });
        setSelectedChapters(newChapter);
        const chapterIdList = [];
        newChapter.forEach((chapter) => chapterIdList.push(chapter.content.id));
        setQuestion((prevMCQ) => ({
            ...prevMCQ,
            chapterIds: newChapter ? chapterIdList : []
        }));
    };

    const handleChangeLanguage = (language) => {
        setSelectedLanguage(language);
        setQuestion((prev) => ({
            ...prev,
            language: {
                acronym: language
            }
        }));
    };

    const handleChangePrompt = (prompt) => {
        setSelectedPrompt(prompt);
        setQuestion((prev) => ({
            ...prev,
            prompt
        }));
    };

    const cssControl = () => {
        if(sizeUpMd) {
            return 'createEditPageDesktop';
        }
        return 'createEditPageMobile';
    };

    useEffect(() => {
        dispatch(skillActions.getAllReducedSkills(props));
        if(props.idQuestion) {
            dispatch(getQuestionByIdEdit(props.idQuestion));
            dispatch(getQuestionReporting(getCurrentUserId(), props.idQuestion));
        }
        return () => {
            dispatch(resetQuestionToEdit());
            dispatch(resetQuestionsReporting());
            if(!props.skillLink && !props.isEditing) {
                dispatch(chapterActions.resetChaptersToDisplay());
            }
        };
    }, [props.idQuestion]);

    useEffect(() => {
        if(chapterTree && selectedChapters.length === 0 && props.chapterSelected) {
            setSelectedChapters([chapterTree.find(`${rootChapter.parentPath + rootChapter.name.toLowerCase()}/`)]);
        }
    }, [chapterTree]);

    useEffect(() => {
        if(listAllSkill.length > 0) {
            setSkills(listAllSkill.map((skill) => ({
                id: skill.id,
                value: skill.name,
                label: skill.name
            })).sort((s1, s2) => s1.label.localeCompare(s2.label)));
        }
    }, [listAllSkill]);

    useEffect(() => {
        if(!props.isOnChapterContent) {
            if(selectedSkill) {
                dispatch(chapterActions.getChaptersToDisplay({ skillId: selectedSkill.id, userId: getCurrentUserId() }));
            }
            setSelectedChapters([]);
        }
    }, [selectedSkill]);

    useEffect(() => {
        if(chapters) {
            setChapterTree(createChapterTree(chapters));
        }
    }, [chapters]);

    useEffect(() => {
        if(props.idQuestion && question.skillId && (skills.length > 0) && !selectedSkill) {
            setSelectedSkill(skills.find((currentSkill) => currentSkill.id === question.skillId));
        }
    }, [skills, question]);

    useEffect(() => {
        if(props.idQuestion && question.chapterIds && chapterTree && (chapterTree.root.children.length > 0)) {
            setSelectedChapters(question.chapterIds.map((chapterId) => findByChapterId(chapterTree, chapterId)));
        }
    }, [chapterTree, question]);

    useEffect(() => {
        if(props.idQuestion && questionProvided) {
            const newQuestion = executeCallbackOnQuestionType(
                questionProvided.class,
                {
                    mcqCallback: () => setMcqWithIdKey(cloneDeep(questionProvided)),
                    questionATrouCallback: () => parseQuestionATrou(questionProvided),
                    questionOuverteCallback: () => questionProvided
                }
            );
            setSelectedSkill(questionProvided.skill);
            setQuestion({
                ...newQuestion,
                aiGenerated: QuestionAiGenerated.VERIFIED
            });
            setSelectedLanguage(questionProvided.language.acronym);
            setSelectedPrompt(questionProvided.prompt);
        }
    }, [questionProvided]);

    useEffect(() => {
        if(questionModificationSaved) {
            resetForm();
            dispatch(resetMcqModificationSaved());
            if(props.isEditing) {
                if(props.onFinishEditing) {
                    props.onFinishEditing(props.idQuestion);
                }
                props.onCloseCreateEdit();
            }
        }
    }, [questionModificationSaved]);

    function handleQuestionGeneration(data) {
        setQuestion(data);
        setQuestion((prev) => ({
            ...prev,
            language: {
                acronym: noLanguage
            },
            creator: {
                id: getCurrentUserId()
            },
            layout: 'ANSWERS_COLUMN',
            aiGenerated: QuestionAiGenerated.VERIFIED
        }));
    }

    return (
        question &&
        <div className={cssControl()}>
            <div className="creationPageQuestionContainer">
                <CreateEditQuestionHeader
                    handleChangeSelectSkill={handleChangeSelectSkill}
                    skillLink={props.skillLink}
                    skills={skills}
                    selectedSkill={selectedSkill}
                    handleChangeSelectChapter={handleChangeSelectChapter}
                    chapters={chapterTree}
                    selectedChapters={selectedChapters}
                    valueButton={valueButton}
                    setValueButton={setValueButton}
                    handleChangeButton={handleChangeButton}
                    questionTypeLocked={Boolean(props.idQuestion)}
                    handleChangeLanguage={handleChangeLanguage}
                    handleChangePrompt={handleChangePrompt}
                    selectedLanguage={selectedLanguage}
                    selectedPrompt={selectedPrompt}
                    handleQuestionGeneration={handleQuestionGeneration}
                    mcq={question}
                    setMcq={setQuestion}
                />
                <CreateEditQuestionBody
                    question={question}
                    setQuestion={setQuestion}
                />
                <CreateEditQuestionFooter
                    question={question}
                    isEditing={props.isEditing}
                    isKeepingText={isKeepingText}
                    setIsKeepingText={setIsKeepingText}
                    idQuestion={props.idQuestion}
                    handleConfirmCancel={handleConfirmCancel}
                    onFinishEditing={props.onFinishEditing}
                    setDialogOpen={setDialogOpen}
                    emptyMCQ={emptyMCQ}
                    resetForm={resetForm}
                />
                <ValidateDialog open={dialogOpen} onClose={handleCloseCancel} onConfirm={handleConfirmCancel} />
            </div>
            <QuestionReportingDisplay questionsReporting={questionsReporting} />
        </div>
    );
}


CreateEditQuestionForm.propTypes = {

    /**
     * The id of the MCQ to edit, or none if it's a MCQ creation
     */
    idQuestion: PropTypes.number,
    isEditing: PropTypes.bool,
    isOnChapterContent: PropTypes.bool,
    skillLink: PropTypes.object,
    chapterSelected: PropTypes.object,
    onCloseCreateEdit: PropTypes.func.isRequired,
    onFinishEditing: PropTypes.func
};

function ValidateDialog(props) {
    const { onClose, open, onConfirm } = props;

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className="cancelButtonContainer">
                <DialogTitle id="simple-dialog-title">Voulez-vous annuler vos changements ?</DialogTitle>
                <Button className="closeDialogButton"
                    data-testid="create-edit-dialog-cancel-button"
                    onClick={() => handleClose()}>non</Button>
                <Button className="confirmCancelButton" onClick={() => handleConfirm()}>oui</Button>
            </div>
        </Dialog>
    );
}

ValidateDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool
};

export default CreateEditQuestionForm;
