

import React, { useEffect, useState } from 'react';
import SidePanelExpertPromo from './SidePanelExcilyensPromo';
import { useDispatch, useSelector } from 'react-redux';
import {
    setExcilyensSearchFilter,
    setExcilyensSkillsFilter,
    setPromoFilter
} from '../../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import SidePanelExcilyensSkillsEntry from './SidePanelExcilyensSkillsEntry';
import {
    selectExcilyenSearchString,
    selectExcilyensFilteredBySkillsAndPromosAndByName
} from '../../../redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import Search from '../../../common/search/Search';
import { Typography } from '@material-ui/core';

export default function SidePanelExcilyens() {

    const dispatch = useDispatch();

    const searchExperts = useSelector(selectExcilyenSearchString);
    const excilyensFilteredBySkillsAndPromosAndByName = useSelector(selectExcilyensFilteredBySkillsAndPromosAndByName);
    const [number, setNumber] = useState(0);
    const [excilyensSearchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        setNumber(excilyensFilteredBySkillsAndPromosAndByName.length);
    }, [excilyensFilteredBySkillsAndPromosAndByName]);

    useEffect(() => {
        dispatch(setExcilyensSearchFilter(excilyensSearchFilter));
    }, [excilyensSearchFilter]);

    const resetFilters = () => {
        dispatch(setExcilyensSearchFilter(''));
        dispatch(setPromoFilter([]));
        dispatch(setExcilyensSkillsFilter([]));
    };

    useEffect(() => {
        resetFilters();
    }, []);

    return (
        <div className="expert-side-panel-content" data-testid="side-panel-excilyens">
            <Typography className="sub-title">{number} Oxyliens</Typography>
            <span className="filter-title">Filtres</span>
            <span className="filter-reset-button" onClick={resetFilters}>
                Réinitialiser les filtres
            </span>
            <div className="side-panel-expert-filters">
                <Search value={searchExperts} onSearchSubmit={setSearchFilter}/>
                <SidePanelExcilyensSkillsEntry />
                <SidePanelExpertPromo />
            </div>
        </div>
    );
}

