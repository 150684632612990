import {
    postChapterReportingApi
} from 'app/api/chapterReportingApi';

import { notificationError, notificationSuccess } from '../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    CHAPTER_REPORTING_CREATED,
    CHAPTER_REPORTING_ERROR_404,
    CHAPTER_REPORTING_ERROR
} from 'app/Snackbar/NotificationMessages';


export const POST_CHAPTER_REPORTING_REQUEST = 'POST_CHAPTER_REPORTING_REQUEST';
export const POST_CHAPTER_REPORTING_SUCCESS = 'POST_CHAPTER_REPORTING_SUCCESS';
export const POST_CHAPTER_REPORTING_FAILURE = 'POST_CHAPTER_REPORTING_FAILURE';


export const postChapterReporting = (chapterReporting) => (dispatch) => {
    dispatch({ type: POST_CHAPTER_REPORTING_REQUEST });

    return postChapterReportingApi(chapterReporting).then((response) => {
        dispatch({
            type: POST_CHAPTER_REPORTING_SUCCESS,
            payload: chapterReporting
        });
        if(response && response.status === 200) {
            dispatch(notificationSuccess(CHAPTER_REPORTING_CREATED));
        }
    }, (error) => {
        dispatch({
            type: POST_CHAPTER_REPORTING_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationSuccess(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(CHAPTER_REPORTING_ERROR_404));
        } else {
            dispatch(notificationError(CHAPTER_REPORTING_ERROR));
        }
    });
};

