import { Skill, SkillReduced } from 'app/redux/models/Skill.model';
import axiosClient from './utils/axiosClient';

export const SKILL_SET_URI = 'skills';
export const ALL_CATEGORIES_URI = '/categories';
export const ALL_FAMILIES_URI = '/families';

export function postSkillApi(skill: Skill) {
    return axiosClient.post(SKILL_SET_URI, skill);
}

export function putSkillApi(skill: Skill) {
    return axiosClient.put(SKILL_SET_URI, skill);
}

export function getSkillSetApi() {
    return axiosClient.get<Skill[]>(SKILL_SET_URI);
}

export function getSkillByChapterIdApi(chapterId: number) {
    return axiosClient.get(`${SKILL_SET_URI}/chapter/${chapterId}`);
}

export function getAllReducedSkillsApi() {
    return axiosClient.get<SkillReduced[]>(`${SKILL_SET_URI}/reduced`);
}

export function getSkillBySkillIdApi(skillId: number) {
    return axiosClient.get(`${SKILL_SET_URI}/${skillId}`);
}

export function getSkillApi(skillName: string) {
    return axiosClient.get(`${SKILL_SET_URI}/skillName/${skillName}`);
}

export function getAllCategoriesApi() {
    return axiosClient.get(SKILL_SET_URI + ALL_CATEGORIES_URI);
}

export function getAllFamiliesApi() {
    return axiosClient.get(SKILL_SET_URI + ALL_FAMILIES_URI);
}

export function getAllSkillsStatsApi() {
    return axiosClient.get(`${SKILL_SET_URI}/stats`);
}

export function getSkillReducedByQuestionIdApi(questionId: number) {
    return axiosClient.get(`${SKILL_SET_URI}/question/${questionId}`);
}
