import React, { useEffect, useState } from 'react';
import './WorkToDo.scss';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import SockJsClient from 'react-stomp';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { addRoom } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';
import { setExamMode } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';

import { getRoomExistsApi } from 'app/api/interactiveQuizApi';

import DailyQuestionSettings from 'app/question/QuestionTablePage/DailyQuestionSettings';
import DialogQuizInteractifMenuSmallScreen from 'app/header/components/DialogQuizInteractifSmallScreen';

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(1, 1),
        justifyContent: 'center',
        columnGap: '8px'
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '12px',
        padding: '12px 0 6px'
    },
    startDailyQuestions: {
        borderRadius: '8px 0px 0px 8px',
        height: '36px'
    },
    startInteractiveQuiz: {
        borderRadius: '8px',
        width: 'auto'
    }
}));

function WorkToDo(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    

    const [enteredCode, setEnteredCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [roomExists, setRoomExists] = useState(false);

    useEffect(() => {
        setRoomExists(false);
        setErrorMessage('');
    }, [location]);

    useEffect(() => {
        if(roomExists) {
            // eslint-disable-next-line no-use-before-define
            redirectToQuiz();
        }
    }, [roomExists]);

    const closeDialog = () => {
        setEnteredCode('');
        setIsOpenDialog(false);
    };

    const formatDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const handleCodeChange = (event) => {
        setEnteredCode(event.target.value);
        setErrorMessage('');
    };

    const redirectToQuiz = () => {
        setErrorMessage('');
        closeDialog();
        history.push(`/quiz/${enteredCode}`);
    };

    const handleValidation = (event) => {
        event.preventDefault();
        getRoomExistsApi(enteredCode)
            .then(() => {
                if(roomExists) {
                    redirectToQuiz();
                }
            })
            .catch(() => {
                if(roomExists) {
                    setErrorMessage('Une erreur est survenue. Veuillez réessayer');
                } else {
                    setErrorMessage('Code incorrect. Veuillez réessayer');
                }
            });
    };

    const openDialog = () => {
        setIsOpenDialog(true);
    };

    return (
        <>
            {enteredCode &&
                <SockJsClient url={`${process.env.REACT_APP_BACK_END}websocket`}
                    topics={[`/quiz/${enteredCode}`]}
                    onMessage={(msg) => {
                        const [action, users, questionId] = msg.split('|');
                        if(action === 'exists') {
                            dispatch(addRoom({
                                uuid: enteredCode,
                                currentQuestionId: Number(questionId),
                                users: users?.split(', ').map((userString) => {
                                    const [id, imageUrl, firstName, lastName] = userString.split(' ');
                                    return { id, imageUrl, firstName, lastName };
                                }),
                                isQuizStarted: questionId !== '-1',
                                hostId: '-1'
                            }));
                            setRoomExists(true);
                        }
                    }}
                />
            }
            <div className={classes.drawerHeader}>
                <div className="daily-button">
                    <Link
                        className="dailyQuestionsLink"
                        to={`/questions/daily/${formatDate(new Date().toISOString().split('T')[0])}`}
                    >
                        <Button 
                            className={classes.startDailyQuestions}
                            color="primary"
                            variant="contained"
                            aria-label="Lancement série quotidienne"
                            onClick={() => dispatch(setExamMode(false))}
                        >
                            Lancer ma série quotidienne
                        </Button>
                    </Link>
                    <DailyQuestionSettings />
                </div>
                {!props.isInteractiveQuiz &&
                    <div className={classes.headerActions}>
                        <Button 
                            className={classes.startInteractiveQuiz}
                            color="primary"
                            variant="contained"
                            aria-label="Quiz Interactif"
                            onClick={openDialog}
                        >
                            🏆 Quiz Interactif 🏆
                        </Button>
                    </div>
                }
            </div>
            <DialogQuizInteractifMenuSmallScreen
                isOpenDialog={isOpenDialog}
                enteredCode={enteredCode}
                errorMessage={errorMessage}
                closeDialog={closeDialog}
                handleCodeChange={handleCodeChange}
                handleValidation={handleValidation}
            />
        </>
    );
}

WorkToDo.propTypes = {
    isInteractiveQuiz: PropTypes.bool
};

export default WorkToDo;
