import { questionSelectedByStudent, questionSelectedHistories, questionSelectedHistory, tabIndex } from '../../reducers/Question/question.reducer';

import { questionStore } from '../../rootReducer';

export function selectTabIndex(state) {
    return state[questionStore][tabIndex];
}

export function selectQuestionSelectedByStudent(state) {
    return state[questionStore][questionSelectedByStudent];
}

export function selectQuestionSelectedHistory(state) {
    return state[questionStore][questionSelectedHistory];
}

export function selectQuestionSelectedHistories(state) {
    return state[questionStore][questionSelectedHistories];
}
