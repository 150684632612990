import { usersStore } from '../../reducers/User/user.reducer';
import { RootState } from 'app/redux/rootReducer';
import { Skill } from 'app/redux/models/Skill.model';

export const selectCurrentUser = (state: RootState) => state.user.currentUser;

export const selectCurrentUserRegistered = (state: RootState) => state.user.registred;

export const selectDetailedCurrentUser = (state: RootState) => state.excilyens.excilyen;

export const selectCurrentUserSkillSet = (state: RootState) => state.user.currentUser.skillDetailedApiDtos;

export const selectSkillLevelOfCurrentUser = (state: RootState, skillId: number) => (
    selectCurrentUserSkillSet(state)?.find((skill: Skill) => skill.id === skillId)?.userLevel
);

export const selectIsSkillFavoriteOfCurrentUser = (state: RootState, skillId: number) => (
    selectCurrentUserSkillSet(state)?.find((skill: Skill) => skill.id === skillId)?.favorite
);

export const selectAllUser = (state: RootState) => state.user[usersStore];
