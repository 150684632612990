
export const placeholderConfig = 'Enoncé';

export const toolbarConfig = {
    viewportTopOffset: 64,
    items: [
        'texteatrou',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'link',
        'codeBlock',
        '|',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo'
    ]
};

export const codeBlockConfig = {
    languages: [
        { language: 'plaintext',
            label: 'Plain text' },
        { language: 'css',
            label: 'CSS' },
        { language: 'html',
            label: 'HTML' },
        { language: 'java',
            label: 'Java' },
        { language: 'javascript',
            label: 'JavaScript' },
        { language: 'typescript',
            label: 'TypeScript' },
        { language: 'xml',
            label: 'XML' }
    ]
};

export const textATrouEditorConfig = {
    placeholder: placeholderConfig,
    toolbar: toolbarConfig,
    codeBlock: codeBlockConfig
};
