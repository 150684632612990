import React from 'react';
import PropTypes from 'prop-types';

import './HistoryTable.scss';

import { Paper, Table,
    TableBody, TableCell,
    TableContainer, TableHead,
    TableRow } from '@material-ui/core';
import HistoryRowHead from './historyRowHead/HistoryRowHead';
import HistoryPagination from './historyPagination/HistoryPagination';
import { HistoryTypeEnum } from '../HistoryTypeEnum';


function HistoryTable(props) {

    const title = props.historyType === HistoryTypeEnum.QUESTION_OR_SERIE
        ? 'Questions / Séries'
        : props.historyType === HistoryTypeEnum.PARCOURS
            ? 'Parcours'
            : undefined;

    return (
        <Paper className="tablePaper" data-testid="history-table">
            {props.data.length === 0
                ? <div className="emptyListQuestions">
                    <strong>Aucun historique enregistr&eacute;</strong>
                </div>
                : <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow key={'header'}>
                                    <TableCell align="center" width={'100%'} colSpan={5} style={{ fontSize: '24px' }}>{title}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow key={'header'}>
                                    <TableCell width={'5%'}/>
                                    <TableCell width={'50%'}> Titre </TableCell>
                                    <TableCell align="center" width={'35%'}>{props.historyType === HistoryTypeEnum.PARCOURS ? 'Progression' : 'Détails'}</TableCell>
                                    <TableCell align="center" width={'5%'}>{props.historyType === HistoryTypeEnum.PARCOURS ? '' : 'Résultat'}</TableCell>
                                    <TableCell align="center" width={'5%'}>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.map((row) => (
                                    <HistoryRowHead
                                        key={row.studentTaskId || row.id}
                                        row={row}
                                        groupName={props.groupName}
                                        studentId={props.studentId || row.studentId}
                                        taskId={props.taskId}
                                        userId = {props.userId}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <HistoryPagination
                        totalElement={props.pageData.totalElement}
                        pageNumber={props.pageData.pageNumber}
                        rowPerPage={props.pageData.rowPerPage}
                        rowPerPageOptions={[10, 20]}
                        handleChangePage={props.handleChangePage}
                        handleRowsPerPage={props.handleRowsPerPage}
                    />
                </>
            }
        </Paper>
    );
}

HistoryTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageData: PropTypes.object.isRequired,
    groupName: PropTypes.string.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleRowsPerPage: PropTypes.func.isRequired,
    title: PropTypes.string,
    studentId: PropTypes.number,
    taskId: PropTypes.number,
    userId: PropTypes.number,
    historyType: PropTypes.string        
};

export default HistoryTable;
