import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import CompetenceChapterSelector from 'app/components/Selectors/CompetenceChapterSelector/CompetenceChapterSelector';
import { Button, DialogActions } from '@material-ui/core';
import GroupAndStudentSelector from 'app/coaching/GroupAndStudentSelector/GroupAndStudentSelector';

const TabViewEnum = {
    STUDENT_GROUP_SELECTOR: 'STUDENT_GROUP_SELECTOR',
    COMPETENCE_CHAPTERS_SELECTOR: 'COMPETENCE_CHAPTERS_SELECTOR'
};

export const DIALOG_TITLE = 'Veuillez choisir les élèves et les chapitres';
export const CHAPTERS_SELECTOR_TAB_LABEL = 'Chapitres';
export const GROUP_STUDENTS_SELECTOR_TAB_LABEL = 'Liste élèves/groupes';

export default function UserChapterDialog(props) {

    const [usersAndGroupsSelected, setUsersAndGroupsSelected] = useState([]);
    const [selectedChapters, setSelectedChapters] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [tabValue, setTabValue] = useState(TabViewEnum.STUDENT_GROUP_SELECTOR);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{DIALOG_TITLE}</DialogTitle>
            <TabContext value={tabValue}>
                <TabList onChange={(event, newValue) => setTabValue(newValue)}>
                    <Tab
                        label={GROUP_STUDENTS_SELECTOR_TAB_LABEL}
                        aria-label={GROUP_STUDENTS_SELECTOR_TAB_LABEL}
                        value={TabViewEnum.STUDENT_GROUP_SELECTOR}
                    />
                    <Tab
                        label={CHAPTERS_SELECTOR_TAB_LABEL}
                        aria-label={CHAPTERS_SELECTOR_TAB_LABEL}
                        value={TabViewEnum.COMPETENCE_CHAPTERS_SELECTOR}
                    />
                </TabList>
                <TabPanel value={TabViewEnum.STUDENT_GROUP_SELECTOR}>
                    <GroupAndStudentSelector
                        usersAndGroupsSelected={usersAndGroupsSelected}
                        onChangeUserAndGroup={setUsersAndGroupsSelected}
                    />
                </TabPanel>
                <TabPanel value={TabViewEnum.COMPETENCE_CHAPTERS_SELECTOR}>
                    <CompetenceChapterSelector
                        selectedSkill={selectedSkill}
                        setSelectedSkill={setSelectedSkill}
                        selectedChapters={selectedChapters}
                        setSelectedChapters={setSelectedChapters}
                    />
                </TabPanel>
            </TabContext>
            <DialogActions>
                <Button
                    disabled = {(!usersAndGroupsSelected || usersAndGroupsSelected.length === 0) ||
                        (!selectedChapters || selectedChapters.length === 0) || !selectedSkill}
                    variant="contained"
                    color="primary"
                    onClick={() => props.handleValidationUsersGroupsChapters(
                        usersAndGroupsSelected,
                        selectedChapters
                    )}
                >
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UserChapterDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleValidationUsersGroupsChapters: PropTypes.func.isRequired
};
