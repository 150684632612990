

//  Title validity

export function titleStartWithCapital(title) {
    if(title === undefined) {
        return false;
    }
    return Boolean(title.match('^[A-Z]'));
}

export function titleNotToLong(title) {
    if(title === undefined) {
        return false;
    }
    return title.length < 100;
}

export function titleIsValid(title) {
    if(title === undefined) {
        return false;
    }
    return Boolean(title.trim()) && titleStartWithCapital(title) && titleNotToLong(title);
}

export function displayTitleHelperText(title) {
    let helpText = '';
    if(!title || !title.trim()) {
        helpText = helpText.concat('Requis.');
    }
    if(!titleStartWithCapital(title)) {
        helpText = helpText.concat(' Commence par une majuscule.');
    }
    if(!titleNotToLong(title)) {
        helpText = helpText.concat(' Maximum 100 caractères.');
    }
    return helpText;
}

export function displayTitleTooltips(title) {
    let helperText = displayTitleHelperText(title);
    if(helperText) {
        helperText = 'Titre: '.concat(helperText);
    }
    return helperText;
}

// Select Validity

export function selectSkillIsValid(skillSelected) {
    return skillSelected !== null;
}

export function displaySelectSkillHelperText(skillSelected) {
    return selectSkillIsValid(skillSelected) ? '' : 'Requis. ';
}

export function displaySelectSkillTooltips(skillSelected) {
    let helperText = displaySelectSkillHelperText(skillSelected);
    if(helperText) {
        helperText = 'Compétence: '.concat(helperText);
    }
    return helperText;
}
