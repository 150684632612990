import axiosClient from './utils/axiosClient';

export const USER_COACHING_URI = 'users/coaching/relation';

export function getCoachesByStudentIdApi(studentId) {
    return axiosClient.get(`${USER_COACHING_URI}/student/${studentId}/coaches`);
}

export function getStudentsByCoachIdApi(coachId) {
    return axiosClient.get(`${USER_COACHING_URI}/coach/${coachId}/students`);
}

export function getAskedCoachesByStudentIdApi(studentId) {
    return axiosClient.get(`${USER_COACHING_URI}/student/${studentId}/pending/coaches`);
}

export function getStudentsAskingRelationByCoachIdApi(coachId) {
    return axiosClient.get(`${USER_COACHING_URI}/coach/${coachId}/pending/students`);
}

export function postAskCoachApi(relation) {
    return axiosClient.post(USER_COACHING_URI, relation);
}

export function patchAcceptCoachingAskedRelationApi(coachId, studentId) {
    return axiosClient.patch(`${USER_COACHING_URI}/accept/${coachId}/${studentId}`);
}

export function deleteDenyCoachingAskedRelationApi(coachId, studentId) {
    return axiosClient.delete(`${USER_COACHING_URI}/deny/${coachId}/${studentId}`);
}
