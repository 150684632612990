import { notificationError, notificationSuccess } from '../../../Notification/notifications.actions';
import { GET_QUESTION_TO_EDIT_BY_ID_SUCCESS } from '../MCQResolve/MCQResolve.actions';
import {
    ADD_QUESTION_ERROR,
    UPDATE_QUESTION_ERROR,
    EXPIRED_SESSION,
    QUESTION_ADDED,
    QUESTION_UPDATED,
    QUESTION_DELETED,
    DELETE_QUESTION_ERROR,
    QUESTION_MOVED,
    QUESTION_ALREADY_PRESENT_IN_SUBCHAPTER
} from '../../../../../Snackbar/NotificationMessages';

import {
    postMultipleChoiceQuestionApi,
    putMultipleChoiceQuestionApi,
    deleteMultipleChoiceQuestionApi,
    putReplaceChapterOfMultipleChoiceQuestionApi,
    setQuestionInactiveApi
} from '../../../../../api/multipleChoiceQuestionsSetApi';
import { setQuestionStatusToInactive } from '../../Serie/SerieGet/serieGet.actions';

export const ADD_MULTIPLE_CHOICE_QUESTION = 'ADD_MULTIPLE_CHOICE_QUESTION';
export const ADD_MULTIPLE_CHOICE_QUESTION_REQUEST = 'ADD_MULTIPLE_CHOICE_QUESTION_REQUEST';
export const ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS = 'ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS';
export const ADD_MULTIPLE_CHOICE_QUESTION_FAILURE = 'ADD_MULTIPLE_CHOICE_QUESTION_FAILURE';

export const EDIT_MULTIPLE_CHOICE_QUESTION = 'EDIT_MULTIPLE_CHOICE_QUESTION';
export const EDIT_MULTIPLE_CHOICE_QUESTION_REQUEST = 'EDIT_MULTIPLE_CHOICE_QUESTION_REQUEST';
export const EDIT_MULTIPLE_CHOICE_QUESTION_SUCCESS = 'EDIT_MULTIPLE_CHOICE_QUESTION_SUCCESS';
export const EDIT_MULTIPLE_CHOICE_QUESTION_FAILURE = 'EDIT_MULTIPLE_CHOICE_QUESTION_FAILURE';

export const REPLACE_CHAPTER_OF_QUESTION = 'REPLACE_CHAPTER_OF_QUESTION';
export const REPLACE_CHAPTER_OF_QUESTION_REQUEST = 'REPLACE_CHAPTER_OF_QUESTION_REQUEST';
export const REPLACE_CHAPTER_OF_QUESTION_SUCCESS = 'REPLACE_CHAPTER_OF_QUESTION_SUCCESS';
export const REPLACE_CHAPTER_OF_QUESTION_FAILURE = 'REPLACE_CHAPTER_OF_QUESTION_FAILURE';

export const DELETE_MULTIPLE_CHOICE_QUESTION = 'DELETE_MULTIPLE_CHOICE_QUESTION';
export const DELETE_MULTIPLE_CHOICE_QUESTION_REQUEST = 'DELETE_MULTIPLE_CHOICE_QUESTION_REQUEST';
export const DELETE_MULTIPLE_CHOICE_QUESTION_SUCCESS = 'DELETE_MULTIPLE_CHOICE_QUESTION_SUCCESS';
export const DELETE_MULTIPLE_CHOICE_QUESTION_FAILURE = 'DELETE_MULTIPLE_CHOICE_QUESTION_FAILURE';

export const SET_QUESTION_TO_INACTIVE_REQUEST = 'SET_QUESTION_TO_INACTIVE_REQUEST';
export const SET_QUESTION_TO_INACTIVE_SUCCESS = 'SET_QUESTION_TO_INACTIVE_SUCCESS';
export const SET_QUESTION_TO_INACTIVE_FAILURE = 'SET_QUESTION_TO_INACTIVE_FAILURE';

export const RESET_MCQ_MODIFICATION_SAVED = 'RESET_MCQ_MODIFICATION_SAVED';

export const ADD_QUESTIONS_TO_DISPLAY = 'ADD_QUESTIONS_TO_DISPLAY';



export const addMultipleChoiceQuestion = (question) => (dispatch) => {
    dispatch({ type: ADD_MULTIPLE_CHOICE_QUESTION_REQUEST });

    return postMultipleChoiceQuestionApi(question).then((response) => {
        dispatch({
            type: ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS,
            payload: question
        });
        dispatch(notificationSuccess(QUESTION_ADDED));
        dispatch({
            type: ADD_QUESTIONS_TO_DISPLAY,
            payload: response
        });
        dispatch({
            type: GET_QUESTION_TO_EDIT_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: ADD_MULTIPLE_CHOICE_QUESTION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(ADD_QUESTION_ERROR));
        }
    });
};


export const replaceChapterOfQuestion = (question, oldChapterId, newChapterId) => (dispatch) => {
    dispatch({ type: REPLACE_CHAPTER_OF_QUESTION_REQUEST });

    return putReplaceChapterOfMultipleChoiceQuestionApi(question.id, oldChapterId, newChapterId).then(() => {
        dispatch({
            type: REPLACE_CHAPTER_OF_QUESTION_SUCCESS,
            payload: {
                question,
                oldChapterId,
                newChapterId
            }
        });
        dispatch(notificationSuccess(QUESTION_MOVED));
    }, (error) => {
        dispatch({
            type: REPLACE_CHAPTER_OF_QUESTION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationError(QUESTION_ALREADY_PRESENT_IN_SUBCHAPTER));
        } else {
            dispatch(notificationError(UPDATE_QUESTION_ERROR));
        }
    });
};

export const editMultipleChoiceQuestion = (question, questionUserModeration) => (dispatch) => {
    dispatch({ type: EDIT_MULTIPLE_CHOICE_QUESTION_REQUEST });

    return putMultipleChoiceQuestionApi(question).then(() => {
        dispatch({
            type: EDIT_MULTIPLE_CHOICE_QUESTION_SUCCESS,
            payload: questionUserModeration
        });
        dispatch(notificationSuccess(QUESTION_UPDATED));
    }, (error) => {
        dispatch({
            type: EDIT_MULTIPLE_CHOICE_QUESTION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(UPDATE_QUESTION_ERROR));
        }
    });
};

export const setQuestionToInactive = (question, questionIndex, chapterId = null, onDelete = null) => (dispatch) => {
    dispatch({ type: SET_QUESTION_TO_INACTIVE_REQUEST });

    return setQuestionInactiveApi(question).then(() => {
        dispatch({
            type: SET_QUESTION_TO_INACTIVE_SUCCESS,
         
            payload: { 
                ...question,
                chapterId
            }
        });
        dispatch(setQuestionStatusToInactive(questionIndex));
        dispatch(notificationSuccess(QUESTION_DELETED));
        
        if(onDelete) {
            onDelete();
        }
    }, (error) => {
        dispatch({
            type: SET_QUESTION_TO_INACTIVE_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_QUESTION_ERROR));
        }
    });
};

export const deleteMultipleChoiceQuestion = (questionId, onDelete = null) => (dispatch) => {
    dispatch({ type: DELETE_MULTIPLE_CHOICE_QUESTION_REQUEST });

    return deleteMultipleChoiceQuestionApi(questionId).then(() => {
        dispatch({ type: DELETE_MULTIPLE_CHOICE_QUESTION_SUCCESS });
        dispatch(notificationSuccess(QUESTION_DELETED));

        if(onDelete) {
            onDelete();
        }
    }, (error) => {
        dispatch({
            type: DELETE_MULTIPLE_CHOICE_QUESTION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_QUESTION_ERROR));
        }
    });
};

export const resetMcqModificationSaved = () => (dispatch) => {
    dispatch({ type: RESET_MCQ_MODIFICATION_SAVED });
};
