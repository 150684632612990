import { getNotificationByDestinataireIdApi, deleteNotificationByIdApi, deleteAllNotificationByUserIdApi } from 'app/api/notificationApi';
import { EXPIRED_SESSION, RETRIEVE_NOTIFICATION_BY_DESTINATAIRE_ERROR, DELETE_NOTIFICATION_ERROR } from '../../../Snackbar/NotificationMessages';
import { notificationError } from '../Notification/notifications.actions';
import { resetDailyQuestions } from '../Question/DailyQuestions/DailyQuestionsGet/dailyQuestionsGet.actions';

export const GET_NOTIFICATIONS_BY_DESTINATAIRE_REQUEST = 'GET_NOTIFICATIONS_BY_DESTINATAIRE_REQUEST';
export const GET_NOTIFICATIONS_BY_DESTINATAIRE_SUCCESS = 'GET_NOTIFICATIONS_BY_DESTINATAIRE_SUCCESS';
export const GET_NOTIFICATIONS_BY_DESTINATAIRE_FAILURE = 'GET_NOTIFICATIONS_BY_DESTINATAIRE_FAILURE';

export const DELETE_NOTIFICATION_BY_ID_REQUEST = 'DELETE_NOTIFICATION_BY_ID_REQUEST';
export const DELETE_NOTIFICATION_BY_ID_SUCCESS = 'DELETE_NOTIFICATION_BY_ID_SUCCESS';
export const DELETE_NOTIFICATION_BY_ID_FAILURE = 'DELETE_NOTIFICATION_BY_ID_FAILURE';

export const DELETE_ALL_NOTIFICATION_BY_USER_ID_REQUEST = 'DELETE_ALL_NOTIFICATION_BY_USER_ID_REQUEST';
export const DELETE_ALL_NOTIFICATION_BY_USER_ID_SUCCESS = 'DELETE_ALL_NOTIFICATION_BY_USER_ID_SUCCESS';
export const DELETE_ALL_NOTIFICATION_BY_USER_ID_FAILURE = 'DELETE_ALL_NOTIFICATION_BY_USER_ID_FAILURE';

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';

export const getNotificationByDestinataireId = (userId) => (dispatch) => {
    dispatch({ type: GET_NOTIFICATIONS_BY_DESTINATAIRE_REQUEST });

    return getNotificationByDestinataireIdApi(userId).then((response) => {
        dispatch({ type: GET_NOTIFICATIONS_BY_DESTINATAIRE_SUCCESS,
            payload: response.data });
    }, (error) => {
        dispatch({
            type: GET_NOTIFICATIONS_BY_DESTINATAIRE_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_NOTIFICATION_BY_DESTINATAIRE_ERROR));
        }
    });
};

export const deleteNotificationById = (notificationId) => (dispatch) => {
    dispatch({ type: DELETE_NOTIFICATION_BY_ID_REQUEST });

    return deleteNotificationByIdApi(notificationId).then((response) => {
        dispatch({ type: DELETE_NOTIFICATION_BY_ID_SUCCESS,
            payload: response.data });
    }, (error) => {
        dispatch({
            type: DELETE_NOTIFICATION_BY_ID_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_NOTIFICATION_ERROR));
        }
    });
};

export const deleteAllNotificationByUserId = (userId) => (dispatch) => {
    dispatch({ type: DELETE_ALL_NOTIFICATION_BY_USER_ID_REQUEST });

    return deleteAllNotificationByUserIdApi(userId).then((response) => {
        dispatch({ type: DELETE_ALL_NOTIFICATION_BY_USER_ID_SUCCESS,
            payload: response.data });
        dispatch(resetDailyQuestions());
    }, (error) => {
        dispatch({
            type: DELETE_ALL_NOTIFICATION_BY_USER_ID_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_NOTIFICATION_ERROR));
        }
    });
};

export const addNotification = (notification) => (dispatch) => {
    dispatch({ type: NEW_NOTIFICATION,
        payload: notification });
};
