import { React, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import '../Message.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReplayIcon from '@material-ui/icons/Replay';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { IconButton } from '@material-ui/core';

import { getNextParcoursHistoryByUserId } from 'app/redux/actions/Parcours/parcours.actions';
import { selectParcours } from '../../../../redux/selectors/Parcours/parcours.selector';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import QuestionOuverteAnswers from 'app/question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionBody/QuestionOuverte/QuestionOuverteAnswers';
import { postQuestionOuverteAnswerApi } from 'app/api/multipleChoiceQuestionsSetApi';
import { putAnswerRetrieveQuestionHistoryByIdWithExplanationApi } from 'app/api/multipleChoiceQuestionApi';
import { createObjectAnswers } from 'app/question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer.functions.js';
import { PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { AI_REVIEW_ERROR, FILE_TOO_LARGE_ERROR, POST_ANSWER_RETRIEVE_QUESTION_ERROR } from 'app/Snackbar/NotificationMessages';
import { QuestionAskedEnum } from 'app/utils/QuestionEnum';
import { localLlmServiceApi } from 'app/api/localLlmApi';
import { addMessageToChat } from 'app/redux/actions/Chat/chat.actions';
import { addAudioTextAnswer } from 'app/redux/actions/Question/Serie/SerieSet/serieSet.actions';
import CourseUnderStandardQuestion from './CourseUnderStandardQuestion';


export function MessageQuestionOuverte(props) {

    const getCurrentUserName = () => AuthenticationContainer.getEmail().split('@')[0];

    const dispatch = useDispatch();

    const [videoCode, setVideoCode] = useState('');
    const [audioBlobUrl, setAudioBlobUrl] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [isSendingFile, setIsSendingFile] = useState(false);
    const [hasValidationFailed, setHasValidationFailed] = useState(false);
    const [totalText, setTotalText] = useState('');
    const [textAnswer, setTextAnswer] = useState('');
    const [answered, setAnswered] = useState(false);
    const [isCheckButtonVisible, setIsCheckButtonVisible] = useState(true);
    const [questionMicroskills, setQuestionMicroskills] = useState(null);
    const [isSkipButtonVisible, setIsSkipButtonVisible] = useState(false);

    const parcoursId = useSelector(selectParcours);

    const askedQuestionOuverte = {
        ...props.message.content,
        class: QuestionAskedEnum.QuestionOuverte
    };

    const getQuestionOuverteWithExplanation = (url) => (
        putAnswerRetrieveQuestionHistoryByIdWithExplanationApi(createObjectAnswers(
            null,
            askedQuestionOuverte,
            undefined,
            null,
            null,
            null,
            null,
            url,
            totalText,
            props.message.stepContentId
        ))
            .then((response) => {
                const explanation = response.data.question.explanation ? response.data.question.explanation : 'Aucune explication disponible pour le moment.';
                dispatch(addMessageToChat({ sender: 'user', content: `J'ai répondu: ${totalText}` }));
                dispatch(addMessageToChat({ sender: 'oxa', content: explanation }));
                const questionForAiReview = {
                    id: response.data.id,
                    date: null,
                    result: 'PENDING',
                    question: response.data.question,
                    fileUrl: null,
                    textAnswer: totalText,
                    class: 'QuestionOuverteHistoryApiDto'
                };

                if(questionForAiReview.textAnswer.trim().length === 0) {
                    dispatch({
                        type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS,
                        payload: response.data
                    });
                    dispatch(getNextParcoursHistoryByUserId(parcoursId));
                    return Promise.resolve(null);
                }

                props.setIsLoading(true);
                return localLlmServiceApi.generateReviewQuestionExplanation(questionForAiReview)
                    .then((review) => {
                        props.setIsLoading(false);
                        const aiReview = `Note de l'IA: ${review.data.reviewGrade}/100 </br> Commentaire: ${review.data.reviewText}`;
                        dispatch(addMessageToChat({ sender: 'oxa', content: aiReview }));
                    })
                    .catch(() => {
                        props.setIsLoading(false);
                        dispatch(addMessageToChat({ sender: 'oxa', content: AI_REVIEW_ERROR }));
                        dispatch(notificationError(AI_REVIEW_ERROR));
                        return Promise.reject(new Error('AI Call Failed.'));
                    })
                    .finally(() => {
                        dispatch({
                            type: PUT_ANSWER_RETRIEVE_QUESTION_BY_ID_SUCCESS,
                            payload: response.data
                        });
                        dispatch(getNextParcoursHistoryByUserId(parcoursId));
                    });
            })
            .catch((error) => {
                if(error.response && error.response.status === 500) {
                    dispatch(notificationError('Une erreur est survenue lors de l\'appel à l\'IA.'));
                }
                return Promise.reject(new Error('AI Call Failed.'));
            })
    );

    function sendResponse() {
        setIsSendingFile(true);
        setIsCheckButtonVisible(false);

        postQuestionOuverteAnswerApi(audioFile)
            .then((response) => getQuestionOuverteWithExplanation(response.data.fileUrl))
            .then(() => {
                setAudioFile(null);
                setAnswered(true);
            })
            .catch((error) => {
                setIsSendingFile(false);
                if(error.response && error.response.data.message === 'The uploaded file is too large') {
                    dispatch(notificationError(FILE_TOO_LARGE_ERROR));
                } else {
                    dispatch(notificationError(POST_ANSWER_RETRIEVE_QUESTION_ERROR));
                }
                if(hasValidationFailed) {
                    setIsSkipButtonVisible(true);
                }
                setIsCheckButtonVisible(true);
                setHasValidationFailed(true);
            })
            .finally(() => {
                setIsSendingFile(false);
            });
    }

    const addAudioElement = (blob) => {
        const formData = new FormData();
        formData.append('responseFile', new File([blob], 'test.mp3'));
        formData.append('name', getCurrentUserName());
        setAudioFile(formData);

        const url = URL.createObjectURL(blob);
        setAudioBlobUrl(url);
    };

    const saveAudioTextAnswer = (audioTextAnswer) => {
        dispatch(addAudioTextAnswer(props.message.content.id, audioTextAnswer));
    };

    function parseUrlParams(url, params) {
        const result = {};
        const _url = new URL(url);
        params.forEach((param) => {
            result[param] = _url.searchParams.get(param);
        });

        return result;
    }

    const nextQuestion = () => {
        dispatch(getNextParcoursHistoryByUserId(parcoursId));
    };

    useEffect(() => {
        if(!props.message.content.urlVideo) {
            return;
        }
        const urlParsed = parseUrlParams(props.message.content.urlVideo, ['v']);
        setVideoCode(urlParsed.v);
    }, []);

    return (
        <div className="message oxa">
            <div className="message-content">
                <div className="content bold">#{props.message.content.id}</div>
                <div className="message-content-text">
                    {props.message.content.urlVideo &&
                        <iframe
                            id="frameYoutube"
                            width="100%"
                            height="360"
                            src={`https://www.youtube.com/embed/${videoCode}?autoplay=1&mute=0&controls=0&modestbranding=1&rel=0&showinfo=0&disablekb=1`}
                            allow="autoplay">
                        </iframe>}
                    <div dangerouslySetInnerHTML={{ __html: props.message.content.statement }} className="content bold"/>
                </div>
                <QuestionOuverteAnswers
                    answered={answered}
                    audioBlobUrl={audioBlobUrl}
                    setAudioBlobUrl={setAudioBlobUrl}
                    addAudioElement={addAudioElement}
                    saveAudioTextAnswer={saveAudioTextAnswer}
                    isSendingFile={isSendingFile}
                    isHistory={false}
                    totalText={totalText}
                    setTotalText={setTotalText}
                    textAnswer={textAnswer}
                    setTextAnswer={setTextAnswer}
                    isQuestionOuverteRecording={props.isQuestionOuverteRecording}
                    setIsQuestionOuverteRecording={props.setIsQuestionOuverteRecording}
                />
                { !answered && audioBlobUrl &&
                    <div className="validation-button">
                        {isCheckButtonVisible && (
                            <IconButton
                                data-testid="validation-button"
                                onClick={sendResponse}
                                style={{
                                    bottom: '0',
                                    right: '0',
                                    position: 'absolute',
                                    color: '#0076ba',
                                    transform: 'translate(50%, 50%)'
                                }}
                            >
                                { hasValidationFailed
                                    ? <ReplayIcon style={{ fontSize: '2.3rem' }}/>
                                    : <CheckCircleIcon style={{ fontSize: '2.3rem' }}/>
                                }
                            </IconButton>
                        )}
                    </div>
                }
            </div>

            <CourseUnderStandardQuestion  
                className="course-under-question"
                questionId={props.questionId}
                questionMicroskills = {questionMicroskills}
                setQuestionMicroskills = {setQuestionMicroskills}
                displayCoursesNames = {() => props.setCoursesNames(props.questionId, setQuestionMicroskills)}
            />

            {isSkipButtonVisible && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'end'
                }}>
                    <IconButton
                        data-testid="force-next-button"
                        onClick={nextQuestion}
                        style={{
                            color: '#0076ba',
                            borderRadius: 15,
                            fontSize: '1rem',
                            paddingBlock: '4px'
                        }}
                    >
                        Passer à la suite
                        <SkipNextIcon style={{ fontSize: '2.3rem' }}/>
                    </IconButton>
                </div>
            )}
        </div>);
}

MessageQuestionOuverte.propTypes = {
    isQuestionOuverteRecording: PropTypes.bool,
    message: PropTypes.object,
    setIsLoading: PropTypes.func,
    setIsQuestionOuverteRecording: PropTypes.func,
    questionId: PropTypes.number,
    setCoursesNames: PropTypes.func
};

export default MessageQuestionOuverte;
