import { CREATE_CANDIDATE_SUCCESS, GET_ALL_CANDIDATES_SUCCESS, PATCH_STATUS_SUCCESS } from '../../actions/Candidate/candidate.actions';
export const candidatesStore = 'allCandidates';

export default function candidateReducer(
    state = {
        [candidatesStore]: []
    },
    action
) {
    switch (action.type) {
        case CREATE_CANDIDATE_SUCCESS:
            const updatedState = state[candidatesStore].concat(action.payload);
            return {
                ...state,
                [candidatesStore]: updatedState
            };
        case GET_ALL_CANDIDATES_SUCCESS:
            return {
                ...state,
                [candidatesStore]: action.payload
            };
        case PATCH_STATUS_SUCCESS:
            const updateArray = JSON.parse(JSON.stringify(state[candidatesStore]));
            const updateCandidate = updateArray
                .find((candidate) => candidate.uuid === action.payload.candidateUuid);
            updateCandidate.status = action.payload.candidateStatus;
            updateArray.map((candidate) => candidate.uuid === action.payload.candidateUuid
                ? updateCandidate
                : candidate);
            return {
                ...state,
                [candidatesStore]: updateArray
            };
        default:
            return state;
    }
}

