import React from 'react';
import PropTypes from 'prop-types';

import QuestionDisplayDraggable from './QuestionDisplayDraggable';

import { List } from '@material-ui/core';

export default function QuestionListDisplay(props) {
    return (
        props.data?.length
            ? <List dense>
                {props.data.map((question) => (
                    <QuestionDisplayDraggable
                        key={question.id}
                        skillName={props.skillName || question.skill?.name || ''}
                        question={question}
                        onClickQuestion={props.onClickQuestion}
                        selectedQuestion={props.selectedQuestion}
                        chapterId={props.chapterId}
                        moderators = {props.moderators}
                    />
                ))}
            </List>
            : <span>
                Aucune question liée à ce chapitre
            </span>
    );
}

QuestionListDisplay.propTypes = {
    data: PropTypes.array.isRequired,
    skillName: PropTypes.string.isRequired,
    onClickQuestion: PropTypes.func.isRequired,
    selectedQuestion: PropTypes.number,
    chapterId: PropTypes.number.isRequired,
    moderators: PropTypes.object
};
