import { Grid } from '@material-ui/core';
import SimplePagination from 'app/components/Pagination/SimplePagination';
import QuestionResultList from 'app/question/QuestionResolver/QuestionResult/QuestionResultList';
import PropTypes from 'prop-types';
import  React, { useEffect, useState } from 'react';

const NB_ELEMENTS_PER_PAGE = 10;

function QuestionsResult({ questionsStatList }) {

    const [page, setPage] = useState(1);
    const [nbPages, setNbPages] = useState(questionsStatList.length / NB_ELEMENTS_PER_PAGE);

    useEffect(() => {
        setNbPages(Math.ceil(questionsStatList.length / NB_ELEMENTS_PER_PAGE));
    }, [questionsStatList]);

    return (
        <Grid
            container
            display="flex"
            data-testid="grid-question-result"
            direction="column"
        >
            <Grid
                container
                display="flex"
                justifyContent="space-around"
            >
                <Grid>
                    <QuestionResultList
                        questionHistoryList={questionsStatList.slice(
                            (page - 1) * NB_ELEMENTS_PER_PAGE,
                            (page - 1) * NB_ELEMENTS_PER_PAGE + (NB_ELEMENTS_PER_PAGE / 2)
                        )}
                    />
                </Grid>
                <Grid>
                    <QuestionResultList
                        questionHistoryList={questionsStatList.slice(
                            (page - 1) * NB_ELEMENTS_PER_PAGE + (NB_ELEMENTS_PER_PAGE / 2),
                            page * NB_ELEMENTS_PER_PAGE
                        )}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
            >
                <SimplePagination
                    page={page}
                    setPage={setPage}
                    nbPages={nbPages}
                    nextPageMessage="Résultats suivants"
                    previousPageMessage="Résultats précédents"
                />
            </Grid>
        </Grid>
    );
}

QuestionsResult.propTypes = {
    questionsStatList: PropTypes.array.isRequired,
    isSerieFinished: PropTypes.bool
};

export default QuestionsResult;