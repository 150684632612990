import React, { useEffect } from 'react';

import ChapterDisplay from '../ChapterDisplay/ChapterDisplay';

import { useDispatch, useSelector } from 'react-redux';
import { selectReducedChapters } from 'app/redux/selectors/Chapter/chapter.selector';
import { resetCurrentSkill } from 'app/redux/actions/Skill/SkillSet/skillSet.actions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { Skill } from 'app/redux/models/Skill.model';
import { chapterActions } from 'app/redux/slices/chapters.slice';

interface ChapterRequestProps {
    skill: Skill;
    permission: boolean;
    isModerator: boolean;
}

export default function ChapterRequest({
    skill, permission, isModerator
}: ChapterRequestProps) {

    const dispatch = useDispatch();

    const chapters = useSelector(selectReducedChapters);

    const getCurrentUserId = () => Number.parseInt(AuthenticationContainer.getCurrentUserId() ?? '0', 10);

    useEffect(() => {
        dispatch(chapterActions.getChaptersToDisplay({ skillId: skill.id, userId: getCurrentUserId() }));
        return () => {
            dispatch(chapterActions.resetChaptersToDisplay());
            dispatch(resetCurrentSkill());
        };
    }, []);

    return (
        <ChapterDisplay
            chapters={chapters}
            skill={skill}
            permission={permission}
            isModerator={isModerator}
        />
    );
}
