export const ADD_ROOM = 'ADD_ROOM';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_QUIZ_STARTED = 'SET_QUIZ_STARTED';
export const RESET_ROOMS = 'RESET_ROOMS';

export const addRoom = (roomInfos) => ({
    type: ADD_ROOM,
    roomInfos
});

export const addParticipant = (participantInfos) => ({
    type: ADD_PARTICIPANT,
    participantInfos
});

export const removeParticipant = (participantInfos) => ({
    type: REMOVE_PARTICIPANT,
    participantInfos
});

export const setCurrentQuestionId = (currentQuestionInfos) => ({
    type: SET_CURRENT_QUESTION,
    currentQuestionInfos
});

export const setQuizStarted = (currentQuestionInfos) => ({
    type: SET_QUIZ_STARTED,
    currentQuestionInfos
});

export const resetRooms = () => ({
    type: RESET_ROOMS
});
