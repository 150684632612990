import { StateWithLoading } from '../redux.types';
import { notificationError } from '../actions/Notification/notifications.actions';
import { createAppSlice, customThunk } from '../redux.helper';
import { Skill, SkillReduced } from '../models/Skill.model';
import { getAllReducedSkillsApi, getSkillSetApi } from 'app/api/skillSetApi';
import { RETRIEVE_SKILLS_ERROR } from 'app/Snackbar/NotificationMessages';

export const skillsStoreNew = 'skillsNew';
export const allSkillsStore = 'allSkills';
export const allReducedSkillsStore = 'allReducedSkills';

interface SkillState {
    [allSkillsStore]: StateWithLoading<Skill[]>;
    [allReducedSkillsStore]: StateWithLoading<SkillReduced[]>;
}

const initialState: SkillState = {
    [allSkillsStore]: {
        data: [],
        loading: false,
        error: null
    },
    [allReducedSkillsStore]: {
        data: [],
        loading: false,
        error: null
    }
};

const skillSlice = createAppSlice({
    name: skillsStoreNew,
    initialState,
    reducers: (create) => ({
        reset: create.reducer((state) => {
            state[allSkillsStore] = { data: [], loading: false, error: null };
        }),
        getAllSkills: customThunk(
            create,
            skillsStoreNew,
            allSkillsStore,
            {
                request: getSkillSetApi,
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError(RETRIEVE_SKILLS_ERROR));
                },
                cacheData: true
            }
        ),
        getAllReducedSkills: customThunk(
            create,
            skillsStoreNew,
            allReducedSkillsStore,
            {
                request: getAllReducedSkillsApi,
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError(RETRIEVE_SKILLS_ERROR));
                },
                cacheData: true
            }
        )
    })
});

export const skillsReducer = skillSlice.reducer;

export const skillActions = skillSlice.actions;
