import { familiesCategoriesStore, skillsFilterStore } from '../../../rootReducer';
import { createSelector } from 'reselect';
import { selectAllSkills } from '../SkillSet/skill.selector';
import { categoryFilterStore, familyFilterStore, searchSkillStore } from '../../../reducers/Filter/filter.reducer';
import { arraysIntersection } from '../../../../utils/functions';

export const selectSearchString = (state) => state[skillsFilterStore][searchSkillStore];

export const selectFamilies = (state) => state[familiesCategoriesStore].allFamilies;
export const selectCheckedFamilies = (state) => state[skillsFilterStore][familyFilterStore];
export const selectFamilyByName = (state, familyName) => selectFamilies(state).find((family) => family.name === familyName);

export const selectCategories = (state) => state[familiesCategoriesStore].allCategories;
export const selectCheckedCategories = (state) => state[skillsFilterStore][categoryFilterStore];
export const selectCategoryByName = (state, categoryName) => selectCategories(state).find((category) => category.name === categoryName);

export const selectSkillsFilteredByCategories = createSelector(
    [
        selectCheckedCategories,
        selectAllSkills
    ],
    (checkedCategories, skills) => {
        if(Array.isArray(checkedCategories) && checkedCategories.length) {
            return skills.data.filter((skill) => skill.coveredByExcilys &&
                checkedCategories.includes(skill.categoryInternalDto && skill.categoryInternalDto.name));
        }
        return skills.data.filter((skill) => skill.coveredByExcilys);
    }
);

export const selectSkillsFilteredByFamilies = createSelector(
    [
        selectCheckedFamilies,
        selectAllSkills
    ],
    (checkedFamilies, skills) => {
        if(Array.isArray(checkedFamilies) && checkedFamilies.length) {
            return skills.data.filter((skill) => skill.coveredByExcilys && (
                checkedFamilies.includes(skill.familyInternalDto && skill.familyInternalDto.name) ||
                checkedFamilies.includes(skill.familyInternalDtoOptional && skill.familyInternalDtoOptional.name)));
        }
        return skills.data.filter((skill) => skill.coveredByExcilys);
    }
);

export const selectSkillsFilteredByName = createSelector(
    [
        selectSearchString,
        selectAllSkills
    ],
    (searchString, skills) => {
        if(searchString.length < process.env.REACT_APP_MINIMUM_AUTO_SEARCH_LENGTH) {
            return skills.data.filter((skill) => skill.coveredByExcilys);
        }
        return skills.data.filter((skill) => skill.coveredByExcilys && skill.skillName.toLowerCase()
            .includes(searchString.toLowerCase()));
    }
);

export const selectSkillsFilteredByFamiliesAndCategories = createSelector(
    [
        selectSkillsFilteredByFamilies,
        selectSkillsFilteredByCategories
    ],
    arraysIntersection
);

export const selectSkillsFilteredByFamiliesAndCategoriesAndByName = createSelector(
    [
        selectSkillsFilteredByFamiliesAndCategories,
        selectSkillsFilteredByName
    ],
    arraysIntersection
);
