import { studentTaskListStore, nonTreatedGroupTaskList, groupTaskListStoreDetails, allGroupTaskList } from '../../reducers/Tasks/Task.reducer';
import { taskStudentStore, taskGroupStore } from '../../rootReducer';

export function selectStudentTask(state) {
    return state[taskStudentStore][studentTaskListStore];
}

export function selectNonTreatedGroupTaskList(state) {
    return state[taskGroupStore][nonTreatedGroupTaskList];
}

export function selectAllGroupTaskList(state) {
    return state[taskGroupStore][allGroupTaskList];
}

export function selectGroupTaskDetails(state) {
    return state[taskGroupStore][groupTaskListStoreDetails];
}
