import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProjectsContent from './ProjectContent/ProjectsContent';
import { withRouter } from 'react-router-dom';

export class Projects extends Component {

    render() {
        return (
            <section data-testid = "projects">
                <ProjectsContent/>
            </section>
        );
    }
}

Projects.propTypes = {
    match: PropTypes.object
};

export default withRouter(Projects);
