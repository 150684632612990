import React from 'react';
import PropTypes from 'prop-types';

import { displayLabel, useStyles } from '../../QuestionContainer.functions';
import colors from 'styles/colors.module.scss';
import {
    createTheme, Checkbox, FormControl, FormGroup,
    FormControlLabel, Grid, MuiThemeProvider, Typography, Tooltip
} from '@material-ui/core';

export const returnCheckBoxStyle = (
    correctAnswer,
    isSelected,
    isCandidateSerie,
    isInteractiveQuizQuestion,
    isCurrentInteractiveQuizQuestionValidated
) => {
    if(isCandidateSerie || (isInteractiveQuizQuestion && !isCurrentInteractiveQuizQuestionValidated)) {
        return '';
    }

    const correctColor = colors['green-color-700'];
    const incorrectColor = colors['red-color-400'];

    switch (correctAnswer) {
        case false:
            return incorrectColor;
        case true:
            return isSelected ? correctColor : incorrectColor;
        default:
            return '';
    }
};

const theme = createTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                width: 'inherit'
            }
        }
    }
});

export function MultipleChoiceQuestionAnswers({
    answers,
    onToggleCheckbox,
    isCandidateSerie,
    isInteractiveQuizQuestion,
    isCurrentInteractiveQuizQuestionValidated,
    imagesWithAnswersSelected,
    boxChecked
}) {

    const classes = useStyles();

    const returnFormControlStyle = (correctAnswer) => {
        if(isCandidateSerie || (isInteractiveQuizQuestion && !isCurrentInteractiveQuizQuestionValidated)) {
            return '';
        }
        switch (correctAnswer) {
            case false:
                return classes.formError;
            case true:
                return classes.formValid;
            default:
                return '';
        }
    };

    const imageStyle = {
        borderRadius: '50%',
        height: '25px',
        width: '25px'
    };

    return (
        <FormControl component="fieldset" fullWidth>
            <FormGroup className={classes.formGroupStyle}>
                {answers?.map((elem) => (
                    <MuiThemeProvider key={elem.id} theme={theme}>
                        <FormControlLabel
                            disabled={elem.isDisabled}
                            classes={{
                                root: classes.formControlLabelStyle,
                                disabled: returnFormControlStyle(elem.formControlStyle)
                            }}
                            control={
                                <Checkbox
                                    key={`${elem.id}-${elem.selected}`}
                                    style={{
                                        color: returnCheckBoxStyle(
                                            elem.formControlStyle,
                                            elem.selected,
                                            isCandidateSerie,
                                            isInteractiveQuizQuestion,
                                            isCurrentInteractiveQuizQuestionValidated
                                        )
                                    }}
                                    color="primary"
                                    checked={elem.selected}
                                    onChange={onToggleCheckbox}
                                    id={elem.id?.toString()}
                                    disabled={boxChecked}
                                />
                            }
                            label={
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={10}>
                                        <Typography className={classes.labelStyle}>
                                            {displayLabel(elem)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}
                                        container justifyContent="flex-end" alignItems="center"
                                        className={classes.answerUsersIcons}
                                    >
                                        {imagesWithAnswersSelected
                                            ?.filter((imageInfo) => imageInfo?.selectedAnswerIds?.includes(elem.id))
                                            .map((imageInfo) => (
                                                <Grid item key={imageInfo.imageUrl}>
                                                    <Tooltip title={`${imageInfo.firstName} ${imageInfo.lastName}`}>
                                                        <img
                                                            src={imageInfo.imageUrl}
                                                            className={classes.answerImageStyle}
                                                            style={imageStyle}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            ))}
                                    </Grid>
                                </Grid>
                            }
                        />
                    </MuiThemeProvider>
                ))}
            </FormGroup>
        </FormControl>

    );
}

MultipleChoiceQuestionAnswers.propTypes = {
    answers: PropTypes.array.isRequired,
    onToggleCheckbox: PropTypes.func.isRequired,
    isCandidateSerie: PropTypes.bool,
    isInteractiveQuizQuestion: PropTypes.bool,
    isCurrentInteractiveQuizQuestionValidated: PropTypes.bool,
    imagesWithAnswersSelected: PropTypes.array,
    boxChecked: PropTypes.bool
};

export default MultipleChoiceQuestionAnswers;
