import {
    SET_EXCILYENS_PROMO_FILTER,
    SET_EXCILYENS_SEARCH_FILTER,
    SET_EXCILYENS_SKILLS_FILTER
} from '../../../actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';

export const promoFilterStore = 'promos';
export const excilyenSkillFilterStore = 'skills';
export const searchExcilyenStore = 'search';

export default function excilyensFiltersReducer(state = { [searchExcilyenStore]: '',
    [promoFilterStore]: [],
    [excilyenSkillFilterStore]: [] }, action) {
    switch (action.type) {
        case SET_EXCILYENS_SEARCH_FILTER:
            return { ...state,
                [searchExcilyenStore]: action.payload };
        case SET_EXCILYENS_PROMO_FILTER:
            return { ...state,
                [promoFilterStore]: action.payload };
        case SET_EXCILYENS_SKILLS_FILTER:
            return { ...state,
                [excilyenSkillFilterStore]: action.payload };
        default:
            return state;
    }
}
