import { Link } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { MaterialTableImpl } from '../MaterialTable/MaterialTableImpl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectDetailedMissions } from '../../redux/selectors/Mission/missions.selector';
import { Popup } from '../../project/components/Popup/Popup';

export class DetailedMissionsTable extends Component {
    displayDate = (dateParams) => {
        if(dateParams === null) {
            return <p>N/A</p>;
        }
        const date = new Date(dateParams);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        return <p>
            {day}/{month}/{date.getFullYear()}
        </p>;
    };

    displayStack = (stack) => {
        const stackCopy = [...stack];
        if(stackCopy.length > 4) {
            const others = stack.length - 3;
            stackCopy.splice(3);
            return (
                <p>
                    {
                        stackCopy.map((skill) => ((stackCopy.length - 1) === stackCopy.indexOf(skill))
                            ? (<Fragment key={skill.id}>
                                <Link href={`/skills/${skill.skillName}`}>{skill.skillName} </Link>
                                <Popup stack data={stack} number={others}/></Fragment>)
                            : (<Fragment key={skill.id}><Link href={`/skills/${skill.skillName}`}>{skill.skillName}</Link>, </Fragment>))
                    }
                </p>
            );
        }
        return (
            <p>
                {
                    stackCopy.map((skill) => ((stackCopy.length - 1) === stackCopy.indexOf(skill))
                        ? (<Link key={skill.id} href={`/skills/${skill.skillName}`}>{skill.skillName}</Link>)
                        : (<Fragment key={skill.id}><Link key={skill.id} href={`/skills/${skill.skillName}`}>{skill.skillName}</Link>, </Fragment>))
                }
            </p>
        );
    };

    render() {
        const columns = [
            {
                title: 'Début',
                field: 'startDate',
                defaultSort: 'desc',
                render: (rowData) => this.displayDate(rowData.startDate),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                }
            },
            {
                title: 'Fin',
                field: 'endDate',
                render: (rowData) => this.displayDate(rowData.endDate),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)'
                }
            },
            {
                title: 'Client',
                field: 'client.name',
                render: (rowData) => <p>{rowData.client && rowData.client.name}</p>,
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(120px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(120px)'
                }
            },
            {
                title: 'Projet',
                field: 'name',
                render: (rowData) => <Link href={`/projects/${rowData.projectId}`}>
                    {rowData.projectName}
                </Link>,
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(200px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(200px)'
                }
            },
            {
                title: 'Description',
                field: 'description',
                sorting: false,
                render: (rowData) => <p>{rowData.description}</p>,
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(300px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(300px)'
                }
            },
            {
                title: 'Stack',
                field: 'listSkill',
                sorting: false,
                render: (rowData) => this.displayStack(rowData.listSkill),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(300px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(300px)'
                }
            }
        ];
        const { data, isLoading } = this.props.detailedMissions;
        return <MaterialTableImpl title={''} isFetching={isLoading} data={data.map((row) => ({ ...row }))} columns={columns}/>;
    }
}

DetailedMissionsTable.propTypes = {
    detailedMissions: PropTypes.object
};

function mapStateToProps(state) {
    return {
        detailedMissions: selectDetailedMissions(state)
    };
}

export default connect(mapStateToProps)(DetailedMissionsTable);
