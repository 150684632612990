
import React, { useState } from 'react';

import { Table, TableBody,
    TableCell, TableContainer,
    TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

import './HistoryList.scss';
import HistorySerieOrParcoursStepQuestion from './HistorySerieOrParcoursStepQuestion';
import HistoryPagination from '../historyPagination/HistoryPagination';
import { HistoryDisplayEnum } from '../historyRowHead/HistoryDisplayEnum';
import HistoryParcoursStep from '../historyParcours/HistoryParcoursStep';

function HistoryList(props) {

    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(5);

    const handleChangePage = (event, number) => {
        setPage(number);
    };

    const handleRowsPerPage = (event) => {
        setRowPerPage(event.target.value);
    };

    return (
        props.listContent?.length === 0
            ? <div className="historyEmptyList">
                <strong>Aucun historique enregistr&eacute;</strong>
            </div>



            : <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow key={'header_questions'}>
                            <TableCell width={'5%'}/>
                            {props.historyDisplayType === HistoryDisplayEnum.QUESTION && 
                          <>
                              <TableCell width={'85%'}>Titre</TableCell>
                              <TableCell align="center" width={'5%'}>R&eacute;sultat</TableCell>
                              <TableCell align="center" width={'5%'}>Date</TableCell>
                          </>
                            } 
                            
                            {props.historyDisplayType === HistoryDisplayEnum.PARCOURSSTEP &&
                             <>
                                 <TableCell width={'90%'}>Titre</TableCell>
                                 <TableCell align="center" width={'5%'}>Date</TableCell>
                             </>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.listContent?.map((row, index) => (index + 1) > ((page + 1) * rowPerPage - rowPerPage) &&
                            (index + 1) <= ((page + 1) * rowPerPage) ? 
                            (props.historyDisplayType === HistoryDisplayEnum.QUESTION
                                ? <HistorySerieOrParcoursStepQuestion
                                    key={`question_${row.id}`}
                                    row={row}
                                />   
                                : (props.historyDisplayType === HistoryDisplayEnum.PARCOURSSTEP 
                                    ? <HistoryParcoursStep
                                        row = {row}
                                        parcoursId = {props.historyId}
                                        userId = {props.userId} /> 
                                    : <></> )) 
                            : <> </>  ) 
                        }
                    </TableBody>
                </Table>
                <HistoryPagination
                    totalElement={props.listContent?.length}
                    pageNumber={page}
                    rowPerPage={rowPerPage}
                    rowPerPageOptions={[5, 10, 20]}
                    handleChangePage={handleChangePage}
                    handleRowsPerPage={handleRowsPerPage}
                />
            </TableContainer>
    );
}

HistoryList.propTypes = {
    listContent: PropTypes.array.isRequired,
    historyDisplayType : PropTypes.string.isRequired,
    historyId : PropTypes.number,
    userId : PropTypes.number
};

export default HistoryList;
