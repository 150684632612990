/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './QuestionModeration.scss';
import { PolicyOutlined } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { addQuestionModeration, deleteQuestionModeration } from 'app/redux/actions/Question/QuestionModeration/questionModeration.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';

const ModerationTitleEnum = {
    VERIFIED_BY_ME: 'Question vérifiée par moi',
    VERIFIED_BY_OTHERS: 'Question vérifiée par ',
    NOT_VERIFIED: 'Personne n\'a vérifié cette question'
};

const ModerationColorEnum = {
    VERIFIED_BY_ME: 'green',
    VERIFIED_BY_OTHERS: 'blue',
    NOT_VERIFIED: 'grey'
};

function getModerationIconColor(moderators, currentUserId) {
    const mod = moderators?.find((moderator) => (
        Number(moderator?.userId) === Number(currentUserId)
    ));
    if(mod) {
        return ModerationColorEnum.VERIFIED_BY_ME;
    }
    if(moderators?.length) {
        return ModerationColorEnum.VERIFIED_BY_OTHERS;
    }
    return ModerationColorEnum.NOT_VERIFIED;
}

export function QuestionModeration(props) {
    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);

    const [moderationIconColor, setModerationIconColor] = useState('');

    useEffect(() => {
        setModerationIconColor(getModerationIconColor(props.moderators, currentUser.id));
    }, [props.moderators]);
    const getModeratorsName = (numberOfModerator) => {
        let index = 0;
        let result = '';
        let number = 0;
        while(number < numberOfModerator && index < props.moderators.length) {
            if(props.moderators[index]?.userId !== currentUser.id) {
                result += props.moderators[index]?.firstName + ' ' + props.moderators[index]?.lastName + ', ';
                number += 1;
            }
            index++;
        }
        return result.substring(0, result.length - 2);
    };

    const getNumberOfOtherModerator = (numberOfDisplayModerator) => {
        const numberOfOtherModerator = Math.max(props.moderators.length - numberOfDisplayModerator, 0);
        if(numberOfOtherModerator > 0) {
            return ' et ' + numberOfOtherModerator + ' autres';
        }
        return '';
    };

    const getTooltipTitle = () => {
        const mod = props.moderators?.find((moderator) => (
            moderator?.userId === currentUser.id
        ));
        if(mod) {
            if(props.moderators?.length === 1) {
                return ModerationTitleEnum.VERIFIED_BY_ME;
            }
            return ModerationTitleEnum.VERIFIED_BY_ME + ' et ' + getModeratorsName(2) + getNumberOfOtherModerator(3);
        }
        if(props.moderators?.length) {
            return ModerationTitleEnum.VERIFIED_BY_OTHERS + getModeratorsName(3) + getNumberOfOtherModerator(3);
        }
        return ModerationTitleEnum.NOT_VERIFIED;
    };

    const handleClick = () => {
        const questionUserModeration = {
            questionId: props.questionId,
            moderator: {
                userId: Number(currentUser.id),
                firstName: currentUser.firstName,
                lastName: currentUser.lastName
            }
        };
        if(moderationIconColor === ModerationColorEnum.VERIFIED_BY_ME) {
            dispatch(deleteQuestionModeration(questionUserModeration));
        } else {
            dispatch(addQuestionModeration(questionUserModeration));
        }
    };

    return <>
        <Tooltip title={getTooltipTitle()} className='moderation-icon' >
            <IconButton size="small" onClick={handleClick}>
                <PolicyOutlined style={{ color: moderationIconColor }}/>
            </IconButton>
        </Tooltip>
    </>;
}

QuestionModeration.propTypes = {
    questionId: PropTypes.number.isRequired,
    moderators: PropTypes.array
};
