import {
    GET_DAILY_QUESTIONS_SUCCESS,
    RESET_DAILY_QUESTIONS
} from '../../actions/Question/DailyQuestions/DailyQuestionsGet/dailyQuestionsGet.actions';
import {
    GET_DAILY_PREFERENCES_SUCCESS,
    POST_DAILY_PREFERENCES_SUCCESS
} from '../../actions/Question/DailyQuestions/DailyPreferences/dailyPreferences.actions';

export const dailyQuestionsToDo = 'dailyQuestionsToDo';
export const dailyPreferences = 'dailyPreferences';

export default function dailyQuestionsReducer(state = {
    [dailyQuestionsToDo]: null,
    [dailyPreferences]: null
}, action) {
    switch (action.type) {
        case GET_DAILY_QUESTIONS_SUCCESS:
            return {
                ...state,
                [dailyQuestionsToDo]: action.payload
            };
        case RESET_DAILY_QUESTIONS:
            return {
                ...state,
                [dailyQuestionsToDo]: []
            };
        case GET_DAILY_PREFERENCES_SUCCESS:
        case POST_DAILY_PREFERENCES_SUCCESS:
            return {
                ...state,
                [dailyPreferences]: action.payload
            };
        default:
            return state;
    }
}
