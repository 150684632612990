/* eslint-disable max-lines */
import {
    getQuestionHistoryByIdApi,
    postHistoryPaginatedApi,
    postSerieHistoryApi,
    getQuestionsHistoryBySerieHistoryIdApi,
    getQuestionsHistoryByQuestionIdAndUserIdApi,
    getQuestionsStatDisplayBySerieHistoryIdApi,
    postSerieHistoryApiCandidate,
    getListHistorySerieGroupedBySerieHistoryIdApi,
    getSerieHistoryCandidateByCandidateIdApi,
    postGraphicHistoryApi,
    postGroupGraphicHistoryApi,
    getParcoursStepHistoryByParcoursIdApi,
    getParcoursStepContentByParcoursStepIdApi,
    getHistoryByUserIdApi
} from '../../../../api/historyApi';


import { notificationError } from '../../Notification/notifications.actions';
import {
    ADD_SERIE_HISTORY_ERROR,
    EXPIRED_SESSION,
    GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_ERROR,
    GET_SERIE_HISTORY_CANDIDATE_ERROR,
    POST_HISTORY_ERROR,
    RETRIEVE_QUESTION_ERROR,
    RETRIEVE_QUESTION_ERROR_404,
    RETRIEVE_SERIE_ERROR
} from '../../../../Snackbar/NotificationMessages';
import { QuestionEnum } from 'app/utils/QuestionEnum';
import { createElement, invalidTrouColor, validTrouColor } from 'app/utils/QuestionUtils';


export const GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_REQUEST = 'GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_REQUEST';
export const GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_SUCCESS = 'GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_SUCCESS';
export const GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_FAILURE = 'GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_FAILURE';

export const GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_REQUEST = 'GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_REQUEST';
export const GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_SUCCESS = 'GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_SUCCESS';
export const GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_FAILURE = 'GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_FAILURE';

export const GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_SUCCESS = 'GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_SUCCESS ';
export const GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_FAILURE = 'GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_FAILURE';

export const GET_QUESTION_HISTORY_BY_ID_REQUEST = 'GET_QUESTION_HISTORY_BY_ID_REQUEST';
export const GET_QUESTION_HISTORY_BY_ID_SUCCESS = 'GET_QUESTION_HISTORY_BY_ID_SUCCESS';
export const GET_QUESTION_HISTORY_BY_ID_FAILURE = 'GET_QUESTION_HISTORY_BY_ID_FAILURE';

export const POST_HISTORY_PAGINATED_BY_USER_ID_REQUEST = 'POST_HISTORY_PAGINATED_BY_USER_ID_REQUEST';
export const POST_QUESTION_OR_SERIE_HISTORY_PAGINATED_BY_USER_ID_SUCCESS = 'POST_HISTORY_PAGINATED_BY_USER_ID_SUCCESS';
export const POST_HISTORY_PAGINATED_BY_USER_ID_FAILURE = 'POST_HISTORY_PAGINATED_BY_USER_ID_FAILURE';

export const POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_SUCCESS = 'POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_SUCCESS';
export const POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_FAILURE = 'POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_ERROR';

export const POST_PARCOURS_HISTORY_PAGINATED_BY_USER_ID_SUCCESS = 'POST_PARCOURS_HISTORY_PAGINATED_BY_USER_ID_SUCCESS';
export const POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_SUCCESS = 'POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_SUCCESS';
export const POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_FAILURE = 'POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_FAILURE';

export const POST_HISTORY_PAGINATED_BY_GROUP_ID_REQUEST = 'POST_HISTORY_PAGINATED_BY_GROUP_ID_REQUEST';
export const POST_HISTORY_PAGINATED_BY_GROUP_ID_SUCCESS = 'POST_HISTORY_PAGINATED_BY_GROUP_ID_SUCCESS';
export const POST_HISTORY_PAGINATED_BY_GROUP_ID_FAILURE = 'POST_HISTORY_PAGINATED_BY_GROUP_ID_FAILURE';

export const POST_SERIE_HISTORY_REQUEST = 'POST_SERIE_HISTORY_REQUEST';
export const POST_SERIE_HISTORY_SUCCESS = 'POST_SERIE_HISTORY_SUCCESS';
export const POST_SERIE_HISTORY_FAILURE = 'POST_SERIE_HISTORY_FAILURE';

export const RESET_SERIE_HISTORY = 'RESET_SERIE_HISTORY';
export const RESET_QUESTION_HISTORY = 'RESET_QUESION_HISTORY';
export const RESET_QUESTION_HISTORY_BY_QUESTION_ID = 'RESET_QUESTION_HISTORY_BY_QUESTION_ID';
export const RESET_HISTORY_PAGINATED = 'RESET_HISTORY_PAGINATED';
export const RESET_SERIE_HISTORY_ID = 'RESET_SERIE_HISTORY_ID';
export const RESET_HISTORY_SERIE_GROUPED = 'RESET_HISTORY_SERIE_GROUPED';
export const RESET_SERIE_HISTORY_CANDIDATE = 'RESET_SERIE_HISTORY_CANDIDATE';

export const GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID = 'GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID';
export const GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS = 'GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS';
export const GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_FAILURE = 'GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_FAILURE';

export const SET_QUESTIONS_STAT_FOR_CURRENT_DAILY = 'SET_QUESTIONS_STAT_FOR_CURRENT_DAILY';
export const RESET_DAILY_QUESTION_SERIE = 'RESET_DAILY_QUESTION_SERIE';
export const SET_DAILY_QUESTION_SERIE = 'SET_DAILY_QUESTION_SERIE';

export const GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID = 'GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID';
export const GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_REQUEST = 'GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_REQUEST';
export const GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_SUCCESS = 'GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_SUCCESS';
export const GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_FAILURE = 'GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_FAILURE';

export const GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_REQUEST = 'GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_REQUEST';
export const GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_SUCCESS = 'GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_SUCCESS';
export const GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_FAILURE = 'GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_FAILURE';

export const GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_REQUEST = 'GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_REQUEST';
export const GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_SUCCESS = 'GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_SUCCESS';
export const GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_FAILURE = 'GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_FAILURE';

function validateColor(index, data) {
    return data.question.answers[index].acceptedAnswers.includes(data.answersSelected[index]) ? validTrouColor : invalidTrouColor;
}
const inputRegex = /<input.*?\/>/ug;

function addValuesAndColorsToStatement(data) {
    let index = 0;
    return data.question.statement.replace(inputRegex, () => createElement('input', {
        class: 'champTrou',
        style: `background-color:${validateColor(index, data)}`,
        readonly: true,
        value: `${data.answersSelected[index] ? data.answersSelected[index++] : ''}`
    }).outerHTML);
}

function parsingOfQuestionTexteATrouOnly(data) {
    if(data.question.class === QuestionEnum.TexteATrou) {
        data.question.statement = addValuesAndColorsToStatement(data);
    }
}


export const getQuestionsHistoryBySerieHistoryId = (serieHistoryId) => (dispatch) => {
    dispatch({ type: GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_REQUEST });

    return getQuestionsHistoryBySerieHistoryIdApi(serieHistoryId).then((response) => {
        dispatch({
            type: GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_SUCCESS,
            payload: {
                id: serieHistoryId,
                data: response.data
            }
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTIONS_HISTORY_BY_SERIE_HISTORY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });
};

export const getParcoursStepHistoryByParcoursId  = (parcoursId, studentId) => (dispatch) =>{
    dispatch({ type: GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_REQUEST });

    return getParcoursStepHistoryByParcoursIdApi(parcoursId, studentId).then((response) => {

        dispatch({

            type: GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_SUCCESS,
            payload: {
                id: parcoursId,
                data: response.data
            }

        });
    }, (error) => {

        dispatch({
            type: GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_FAILURE,
            payload: error,
            error: true

        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });

};

export const getParcoursStepContentByParcoursStepId  = (parcoursId, parcoursStepId, userId) => (dispatch) =>{
    dispatch({ type: GET_PARCOURS_STEP_HISTORY_BY_PARCOURS_ID_REQUEST });

    return getParcoursStepContentByParcoursStepIdApi(parcoursStepId, userId).then((response) => {

        dispatch({

            type: GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_SUCCESS,
            payload: {
                parcoursId,
                parcoursStepId,
                data: response.data
            }

        });
    }, (error) => {

        dispatch({
            type: GET_PARCOURS_STEP_CONTENT_BY_PARCOURS_STEP_ID_FAILURE,
            payload: error,
            error: true

        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SERIE_ERROR));
        }
    });

};

export const resetSerieHistory = () => (dispatch) => {
    dispatch({ type: RESET_SERIE_HISTORY });
};

export const getQuestionHistoryById = (questionHistoryId) => (dispatch) => {
    dispatch({ type: GET_QUESTION_HISTORY_BY_ID_REQUEST });

    return getQuestionHistoryByIdApi(questionHistoryId).then((response) => {
        parsingOfQuestionTexteATrouOnly(response.data);
        dispatch({
            type: GET_QUESTION_HISTORY_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTION_HISTORY_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR));
        }
    });
};

export const resetQuestionHistory = () => (dispatch) => {
    dispatch({ type: RESET_QUESTION_HISTORY });
};

export const postHistoryPaginated = (page) => (dispatch) => {
    if(page?.criteria?.userId ) {
        dispatch({ type: POST_HISTORY_PAGINATED_BY_USER_ID_REQUEST });

        if(page.historyType === 'QUESTION') {
            return postHistoryPaginatedApi(page).then((response) => {
                dispatch({
                    type: POST_QUESTION_OR_SERIE_HISTORY_PAGINATED_BY_USER_ID_SUCCESS,
                    payload: response.data
                });
            });
        } else if(page.historyType === 'PARCOURS') {
            return getHistoryByUserIdApi(page.criteria.userId, page.criteria.step, page.criteria.offset).then((response) => {
                dispatch({
                    type: POST_PARCOURS_HISTORY_PAGINATED_BY_USER_ID_SUCCESS,
                    payload: response.data
                });
            }, (error) => {
                dispatch({
                    type: POST_HISTORY_PAGINATED_BY_USER_ID_FAILURE,
                    payload: error,
                    error: true
                });
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(POST_HISTORY_ERROR));
                }
            });
        }
    } else if(page?.criteria?.groupId) {
        dispatch({ type: POST_HISTORY_PAGINATED_BY_GROUP_ID_REQUEST });

        return postHistoryPaginatedApi(page).then((response) => {
            dispatch({
                type: POST_HISTORY_PAGINATED_BY_GROUP_ID_SUCCESS,
                payload: response.data
            });
        }, (error) => {
            dispatch({
                type: POST_HISTORY_PAGINATED_BY_GROUP_ID_FAILURE,
                payload: error,
                error: true
            });
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(POST_HISTORY_ERROR));
            }
        });
    }
    throw new Error(POST_HISTORY_ERROR);
};

export const postHistoryBetweenTwoDates = (searchCriteria) => (dispatch) => {
    if(searchCriteria && searchCriteria.userId) {
        return postGraphicHistoryApi(searchCriteria).then((response) => {
            dispatch({
                type: POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_SUCCESS,
                payload: response.data
            });
        }, (error) => {
            dispatch({
                type: POST_HISTORY_BETWEEN_TWO_DATES_BY_USER_ID_FAILURE,
                payload: error,
                error: true
            });
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(POST_HISTORY_ERROR));
            }
        });
    } 
    throw new Error(POST_HISTORY_ERROR);
};

export const postGroupHistoryBetweenTwoDates = (searchCriteria) => (dispatch) => {
    if(searchCriteria && searchCriteria.groupId) {
        return postGroupGraphicHistoryApi(searchCriteria).then((response) => {
            dispatch({
                type: POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_SUCCESS,
                payload: response.data
            });
        }, (error) => {
            dispatch({
                type: POST_HISTORY_BETWEEN_TWO_DATES_BY_GROUP_ID_FAILURE,
                payload: error,
                error: true
            });
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(POST_HISTORY_ERROR));
            }
        });
    }
    throw new Error(POST_HISTORY_ERROR);
};



export const resetHistoryPaginated = () => (dispatch) => {
    dispatch({ type: RESET_HISTORY_PAGINATED });
};

export const postSerieHistoryCandidate = (candidateUuid) => (dispatch) => {
    dispatch({ type: POST_SERIE_HISTORY_REQUEST });

    return postSerieHistoryApiCandidate(candidateUuid).then((response) => {
        dispatch({
            type: POST_SERIE_HISTORY_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: POST_SERIE_HISTORY_FAILURE,
            payload: error,
            error: true
        });
        dispatch(notificationError(ADD_SERIE_HISTORY_ERROR));
    });
};

export const postSerieHistory = (params) => (dispatch) => {
    dispatch({ type: POST_SERIE_HISTORY_REQUEST });

    return postSerieHistoryApi(params).then((response) => {
        dispatch({
            type: POST_SERIE_HISTORY_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: POST_SERIE_HISTORY_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(ADD_SERIE_HISTORY_ERROR));
        }
    });
};

export const resetSerieHistoryId = () => (dispatch) => {
    dispatch({ type: RESET_SERIE_HISTORY_ID });
};

export const getQuestionsHistoryByQuestionIdAndUserId = (questionId, userId) => (dispatch) => {
    dispatch({ type: GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID });

    return getQuestionsHistoryByQuestionIdAndUserIdApi(questionId, userId).then((response) => {
        response.data.forEach((elem) => {
            parsingOfQuestionTexteATrouOnly(elem);
        });
        dispatch({
            type: GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR));
        }
    });
};

export const resetQuestionsHistoryByQuestionId = () => ({
    type: RESET_QUESTION_HISTORY_BY_QUESTION_ID
});

export const setQuestionsStatForCurrentDaily = (initialQuestionList) => (dispatch) => {

    const formatedStats = initialQuestionList.map((question) => ({
        id: question.id,
        title: question.skill
            ? `${question.skill.name}#${question.id}`
            : `#${question.id}`,
        result: question.correctlyAnswered,
        class: question.class === 'QuestionOuverteAsked'
            ? 'QuestionOuverteStatDisplayApi'
            : 'QuestionStatDisplayApi'
    }));

    dispatch({
        type: SET_QUESTIONS_STAT_FOR_CURRENT_DAILY,
        payload: formatedStats
    });
};

export const resetDailySerieQuestions = () => ({
    type: RESET_DAILY_QUESTION_SERIE
});

export const setDailySerieQuestions = (initialQuestionList) => (dispatch) => {
    dispatch({
        type: SET_DAILY_QUESTION_SERIE,
        payload: initialQuestionList
    });
};

export const getQuestionsStatDisplayBySerieHistoryId = (serieHistoryId) => (dispatch) => {
    dispatch({ type: GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_REQUEST });

    return getQuestionsStatDisplayBySerieHistoryIdApi(serieHistoryId).then((response) => {
        dispatch({
            type: GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_QUESTIONS_STAT_DISPLAY_BY_SERIE_HISTORY_ID_ERROR));
        }
    });
};

export const getListHistorySerieGroupedBySerieHistoryId = (serieHistoryId, groupName) => (dispatch) => {
    dispatch({ type: GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_REQUEST });

    return getListHistorySerieGroupedBySerieHistoryIdApi(serieHistoryId, groupName).then((response) => {
        dispatch({
            type: GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_SUCCESS,
            payload: {
                id: serieHistoryId,
                data: response.data
            }
        });
    }, (error) => {
        dispatch({
            type: GET_LIST_HISTORY_SERIE_GROUPED_BY_SERIE_HISTORY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(POST_HISTORY_ERROR));
        }
    });
};

export const getSerieHistoryCandidateByCandidateId = (candidateId) => (dispatch) => {
    dispatch({ type: GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_REQUEST });

    return getSerieHistoryCandidateByCandidateIdApi(candidateId).then((response) => {
        dispatch({
            type: GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_SERIE_HISTORY_CANDIDATE_BY_CANDIDATE_UUID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_SERIE_HISTORY_CANDIDATE_ERROR));
        }
    });
};

export const resetSerieHistoryGroup = () => (dispatch) => {
    dispatch({ type: RESET_HISTORY_SERIE_GROUPED });
};

export const resetSerieHistoryCandidate = () => (dispatch) => {
    dispatch({ type: RESET_SERIE_HISTORY_CANDIDATE });
};
