import axiosClient from './utils/axiosClient';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const USER_URI = 'users';
export const USER_DETAILED_SKILL_URI = 'users/detailed/skill';
export const USER_SKILL_URI = 'users/skill';
export const USER_DETAILED_SKILLS_URI = 'detailedUsers/skills';

export function getCurrentUserByEmailApi() {
    return axiosClient.get(`${USER_URI}/email/${AuthenticationContainer.getEmail()}`);
}

export function getCurrentUserByIdApi() {
    return axiosClient.get(`${USER_URI}/${AuthenticationContainer.getCurrentUserId()}/detailed`);
}

export function postUserApi(user) {
    return axiosClient.post(USER_URI, user);
}

export function patchCurrentUserApi(id, updates) {
    return axiosClient.patch(`${USER_URI}/${id}`, updates);
}

export function getAllExcilyenApi() {
    return axiosClient.get(USER_URI);
}

export function getUsersNotEditorsForParcoursApi(parcoursId) {
    return axiosClient.get(`${USER_URI}/are-not-editors/parcours/${parcoursId}`);
}
