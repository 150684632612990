import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { selectStudents } from '../../../../redux/selectors/Group/group.selector';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStudentsOfGroupByGroupId } from '../../../../redux/actions/Group/group.actions';
import HistoryTable from '../../../../question/history/historyTable/HistoryTable';
import { DatatypeEnum } from '../../../../question/history/DatatypeEnum';
import { TaskStatusEnum } from '../TaskStatusEnum';

export default function CoachingGroupHistoriqueTable(props) {

    const students = useSelector(selectStudents);

    const dispatch = useDispatch();

    const [pageData, setPageData] = useState({
        pageNumber: 0,
        rowPerPage: 10,
        totalElement: 0
    });

    const handleChangePage = (event, number) => {
        setPageData((prevState) => ({
            ...prevState,
            pageNumber: number
        }));
    };

    const handleRowsPerPage = (event) => {
        setPageData((prevState) => ({
            ...prevState,
            pageNumber: 0,
            rowPerPage: event.target.value
        }));
    };

    function dispatchStudents(groupId) {
        if(groupId) {
            dispatch(getAllStudentsOfGroupByGroupId(groupId));
        }
    }

    useEffect(() => {
        dispatchStudents(props.groupId);
    }, [props.groupId]);

    const progress = (student) => {

        const studentHistory = props.selectedTask.statusByStudent.find((statusByStudent) => statusByStudent.studentId === student.id);

        let row = null;
        if(studentHistory?.serieHistoryApiDto) {
            row = studentHistory.serieHistoryApiDto;
        } else {
            row = studentHistory?.questionHistoryApiDto;
        }
        return { ...row,
            studentTaskId: student.id,
            dataType: studentHistory?.serieHistoryApiDto ? DatatypeEnum.SERIE : DatatypeEnum.QUESTION,
            title: `${student.firstName} ${student.lastName}`,
            done: studentHistory?.taskStatusStudent === TaskStatusEnum.TREATED };
    };

    const getProgress = () => (students.map((student) => progress(student)));

    return (
        <HistoryTable
            data={getProgress()}
            pageData={pageData}
            groupName="nofilter"
            handleChangePage={handleChangePage}
            handleRowsPerPage={handleRowsPerPage}
            title="Nom"
            taskId={props.selectedTask.id}
        />
    );
}

CoachingGroupHistoriqueTable.propTypes = {
    groupId: PropTypes.number.isRequired,
    selectedTask: PropTypes.object
};
