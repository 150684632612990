import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { arraysEqual } from 'app/utils/functions';
import { executeCallbackOnQuestionType, setMcqWithIdKey } from '../CreateEditQuestionForm.functions';

export function changeCreator(question) {
    return {
        ...question,
        creator: {
            id: AuthenticationContainer.getCurrentUserId(),
            email: AuthenticationContainer.getEmail(),
            firstName: AuthenticationContainer.getGivenName(),
            lastName: AuthenticationContainer.getFamilyName(),
            imageUrl: AuthenticationContainer.getProfilePicture()
        }
    };
}

export const getAnswerKey = (questionType, answer) => executeCallbackOnQuestionType(
    questionType,
    {
        mcqCallback: () => answer.key,
        questionATrouCallback: () => answer.position,
        questionOuverteCallback: () => answer.key
    }
);

export const equalAnwser = (answer1, answer2) => {
    if(!answer1 || !answer2) {
        return !answer1 && !answer2;
    }
    return (answer1.id === answer2.id) &&
    (answer1.key === answer2.key) &&
    (answer1.label === answer2.label) &&
    (answer1.text === answer2.text) &&
    (answer1.validAnswer === answer2.validAnswer);
};

export function equalQuestion(questionToCompare1, questionToCompare2) {
    if(!questionToCompare1 || !questionToCompare2) {
        return !questionToCompare1 && !questionToCompare2;
    }
    const question1 = setMcqWithIdKey(questionToCompare1);
    const question2 = setMcqWithIdKey(questionToCompare2);
    return (question1.statement === question2.statement) &&
    (question1.title === question2.title) &&
    (question1.skillId === question2.skillId) &&
    (question1.explanation === question2.explanation) &&
    (question1.creationDate === question2.creationDate) &&
    ((question1.answers && question2.answers)
        ? (arraysEqual(question1.answers, question2.answers, equalAnwser))
        : true);
}
