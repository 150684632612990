import React, { useEffect, useState } from 'react';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetHistoryPaginated,
    resetQuestionHistory,
    postHistoryPaginated,
    resetSerieHistory,
    resetSerieHistoryGroup
} from '../../redux/actions/Question/History/history.actions';
import PropTypes from 'prop-types';
import FilterPanelHistory from '../../components/sidePanel/history/FilterPanelHistory';
import { Checkbox, Grid, Hidden, Icon, IconButton, SwipeableDrawer, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth/withWidth';
import { selectQuestionOrSerieHistoryData, selectParcoursHistoryData } from '../../redux/selectors/Question/history.selector';
import { setDisplayLeftDrawer } from '../../redux/actions/Display/display.actions';
import { selectDisplayLeftDrawer } from '../../redux/selectors/Display/displaySelector';
import './History.scss';
import BarChart from 'app/components/graph/BarChart';

import BothHistoriesTables from './BothHistoriesTables';
export function History(props) {

    const dispatch = useDispatch();
    const questionOrSerieHistoryData = useSelector(selectQuestionOrSerieHistoryData);
    const parcoursHistoryData = useSelector(selectParcoursHistoryData);
    const displayLeftDrawer = useSelector(selectDisplayLeftDrawer);

    const [parcoursPageData, setParcoursPageData] = useState({
        pageNumber: 0,
        rowPerPage: 10,
        totalElement: 0
    });

    const [questionPageData, setQuestionPageData] = useState({
        pageNumber: 0,
        rowPerPage: 10,
        totalElement: 0
    });

    const [badAnswerFilter, setBadAnswerFilter] = useState(false);
    const [groupName, setGroupName] = useState('nofilter');
    const [isGroupSkill, setIsGroupSkill] = useState(false);
    const [isGroupChapter, setIsGroupChapter] = useState(false);


    const getUserId = (props.id) ? () => props.id : () => AuthenticationContainer.getCurrentUserId();

    useEffect(() => () => {
        dispatch(resetHistoryPaginated());
        dispatch(resetQuestionHistory());
        dispatch(resetSerieHistory());
    }, []);

    useEffect(() => {
        setQuestionPageData(() => ({
            pageNumber: 0,
            rowPerPage: 10,
            totalElement: 0
        }));

        setParcoursPageData(() => ({
            pageNumber: 0,
            rowPerPage: 10,
            totalElement: 0
        }));

    }, [props.id]);

    useEffect(() => {
        if(badAnswerFilter) {
            setQuestionPageData((prevState) => ({
                ...prevState,
                pageNumber: 0
            }));
        }
    }, [badAnswerFilter]);

    useEffect(() => {
        const pageQuestion = {
            criteria: {
                userId: getUserId()
            },
            offset: questionPageData.pageNumber * questionPageData.rowPerPage,
            step: questionPageData.rowPerPage,
            historyType: 'QUESTION'       
        };

        if(badAnswerFilter) {
            pageQuestion.criteria.badAnswer = badAnswerFilter;
        }
        dispatch(postHistoryPaginated(pageQuestion));
    }, [questionPageData.pageNumber, questionPageData.rowPerPage, props.id, badAnswerFilter]);

    useEffect(() => {
        const pageParcours = {
            criteria: {
                userId: getUserId()
            },
            offset: parcoursPageData.pageNumber * parcoursPageData.rowPerPage,
            step: parcoursPageData.rowPerPage,
            historyType: 'PARCOURS' 
        };

        dispatch(postHistoryPaginated(pageParcours));
    }, [parcoursPageData.pageNumber, parcoursPageData.rowPerPage, props.id]);

    useEffect(() => {
        setQuestionPageData((prevState) => ({
            ...prevState,
            totalElement: questionOrSerieHistoryData ? questionOrSerieHistoryData.totalNumberOfElements : 0
        }));
    }, [questionOrSerieHistoryData]);

    useEffect(() => {
        setParcoursPageData((prevState) => ({
            ...prevState,
            totalElement: parcoursHistoryData ? parcoursHistoryData.totalNumberOfElements : 0
        }));
    }, [parcoursHistoryData]);

    const handleChangeQuestionPage = (event, number) => {
        setQuestionPageData((prevState) => ({
            ...prevState,
            pageNumber: number
        }));
    };


    const handleChangeParcoursPage = (event, number) => {
        setParcoursPageData((prevState) => ({
            ...prevState,
            pageNumber: number
        }));
    };
    const handleRowsPerQuestionPage = (event) => {
        setQuestionPageData((prevState) => ({
            ...prevState,
            pageNumber: 0,
            rowPerPage: event.target.value
        }));
    };

    const handleRowsPerParcoursPage = (event) => {
        setParcoursPageData((prevState) => ({
            ...prevState,
            pageNumber: 0,
            rowPerPage: event.target.value
        }));
    };

    const toggleCloseLeftDrawer = () => {
        dispatch(setDisplayLeftDrawer(false));
    };

    const toggleOpenLeftDrawer = () => {
        dispatch(setDisplayLeftDrawer(true));
    };

    const changeToSkillGroup = () => {
        setIsGroupSkill(!isGroupSkill);
        if(isGroupSkill) {
            setGroupName('nofilter');
            dispatch(resetSerieHistoryGroup());
        } else {
            setGroupName('skill');
        }
    };

    const changeToChapterGroup = () => {
        setIsGroupChapter(!isGroupChapter);
        if(isGroupChapter) {
            setGroupName('nofilter');
            dispatch(resetSerieHistoryGroup());
        } else {
            setGroupName('chapter');
        }
    };

    return (
        <div data-testid="history">
            {props.displayGraph === false
                ? <Grid container direction="column" className="history">
                    <Grid item id="history-filter" className="desktop-side-panel-history">
                        {isWidthUp('md', props.width) &&
                    <div className="filter-history">
                        <FilterPanelHistory
                            badAnswerFilter={badAnswerFilter}
                            setBadAnswerFilter={setBadAnswerFilter}
                        />
                    </div>
                        }
                        <div className="checkbox-group">
                            <span>Grouper les questions d&apos;une série par : </span>
                            <Checkbox
                                color="default"
                                id="groupSkill"
                                className="checkbox"
                                checked={isGroupSkill}
                                onChange={changeToSkillGroup}
                                disabled={isGroupChapter}
                            />
                            <span>Compétence</span>
                            <Checkbox
                                color="default"
                                id="groupChapter"
                                className="checkbox"
                                checked={isGroupChapter}
                                onChange={changeToChapterGroup}
                                disabled={isGroupSkill}
                            />
                            <span>Chapitre</span>
                        </div>
                    </Grid>
                    <Grid item className="desktop-side-panel-history">
                        <BothHistoriesTables  
                            questionOrSerieHistoryData = {questionOrSerieHistoryData}
                            parcoursHistoryData = {parcoursHistoryData}
                            questionPageData = {questionPageData}
                            parcoursPageData = {parcoursPageData}
                            groupName = {groupName}
                            handleChangeQuestionPage = {handleChangeQuestionPage}
                            handleChangeParcoursPage = {handleChangeParcoursPage}
                            handleRowsPerQuestionPage = {handleRowsPerQuestionPage}
                            handleRowsPerParcoursPage = {handleRowsPerParcoursPage}
                            studentId={props.id}
                            userId = {Number(getUserId())}
                        />
                        
                    </Grid>
                </Grid>
                : <BarChart studentId={props.id} />
            }
            <Hidden mdUp>
                <SwipeableDrawer
                    open={displayLeftDrawer}
                    anchor="left"
                    onOpen={toggleOpenLeftDrawer}
                    onClose={toggleCloseLeftDrawer}
                >
                    <div id="leftDrawer">
                        <IconButton onClick={toggleCloseLeftDrawer} className="drawer-icon">
                            <Icon>chevron_left</Icon>
                        </IconButton>
                        <FilterPanelHistory />
                    </div>
                </SwipeableDrawer>
            </Hidden>
        </div>
    );
}

History.propTypes = {
    width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
    id: PropTypes.number,
    displayGraph: PropTypes.bool
};

export default withWidth()(History);
