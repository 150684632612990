import {
    Button, Typography, Switch, FormControlLabel, withStyles, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import React, { Fragment, useState } from 'react';


import PropTypes from 'prop-types';
import { theme } from 'app/common/profile/ProfileContentTheme';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import {
    addMultipleChoiceQuestion, deleteMultipleChoiceQuestion,
    editMultipleChoiceQuestion
} from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQSet/MCQSet.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestionEdit } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { executeCallbackOnQuestionType, formIsValid } from '../CreateEditQuestionForm.functions';
import { changeCreator, equalQuestion, getAnswerKey } from './CreateEditQuestionFooter.functions';
import { displaySelectSkillTooltips } from '../header/CreateEditQuestionHeader.functions';
import { displayAnswserTooltips, displayStatementTooltips, displayValidAnswersTooltips } from '../body/CreateEditQuestionBody.functions';
import { parseQuestionStatementSpanToInput } from '../body/QuestionATrou/QuestionATrou.functions';
import { useHistory } from 'react-router-dom';

export function CreateEditQuestionFooter(props) {
    const dispatch = useDispatch();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();
    const getGivenName = () => AuthenticationContainer.getGivenName();
    const getFamilyName = () => AuthenticationContainer.getFamilyName();

    const mcqProvided = useSelector(selectQuestionEdit);

    const [dialogOpen, setDialogOpen] = useState(false);
    const history = useHistory();

    const HtmlTooltip = withStyles(() => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9'
        }
    }))(Tooltip);

    const removeKey = (question, duplicate) => {
        if(question.answers) {
            question.answers.forEach((elem) => {
                delete elem.key;
            });
        }
        if((!props.isEditing && props.idQuestion && getCurrentUserId() !== question.creator.id) || duplicate) {
            return changeCreator(question);
        }
        return question;
    };

    const removeId = (question) => {
        if(question.answers) {
            question.answers.forEach((elem) => {
                delete elem.id;
            });
        }
        delete question.id;
        return question;
    };

    const getQuestionUserModeration = () => ({
        questionId: props.idQuestion,
        moderator: {
            userId: getCurrentUserId(),
            firstName: getGivenName(),
            lastName: getFamilyName()
        }
    });

    function dispatchMcq(duplicate) {
        if(props.isEditing && !duplicate) {
            dispatch(editMultipleChoiceQuestion({
                ...removeKey(props.question),
                lastEditor: { id: getCurrentUserId() }
            }, getQuestionUserModeration()));
        } else {
            dispatch(addMultipleChoiceQuestion(removeKey(removeId(props.question), duplicate)));
        }
    }

    function dispatchQuestionATrou(duplicate) {
        const newQuestion = { ...props.question, statement: parseQuestionStatementSpanToInput(props.question.statement), layout: 'ANSWERS_COLUMN' };
        if(props.isEditing && !duplicate) {
            dispatch(editMultipleChoiceQuestion({
                ...newQuestion,
                lastEditor: { id: getCurrentUserId() }
            }, getQuestionUserModeration()));
        } else {
            dispatch(addMultipleChoiceQuestion(removeId(newQuestion)));
        }
    }

    function dispatchQuestionOuverte(duplicate) {
        const newQuestion = { ...props.question, layout: 'ANSWERS_COLUMN' };
        if(props.isEditing && !duplicate) {
            dispatch(editMultipleChoiceQuestion({
                ...removeKey(newQuestion),
                lastEditor: { id: getCurrentUserId() }
            }, getQuestionUserModeration()));
        } else {
            dispatch(addMultipleChoiceQuestion(removeKey(removeId(newQuestion), duplicate)));
        }
    }

    function submitQuestion(duplicate) {
        if(formIsValid(props.question)) {
            executeCallbackOnQuestionType(
                props.question.class,
                {
                    mcqCallback: () => dispatchMcq(duplicate),
                    questionATrouCallback: () => dispatchQuestionATrou(duplicate),
                    questionOuverteCallback: () => dispatchQuestionOuverte(duplicate)
                }
            );
            if(!props.isEditing) {
                props.resetForm();
            }
        }
    }

    function handleDeleteMCQ() {
        dispatch(deleteMultipleChoiceQuestion(props.question, () => history.replace('/questions')));
    }

    const handleClickOpenCancel = () => {
        if(equalQuestion(props.question, mcqProvided) || equalQuestion(props.question, props.emptyMCQ())) {
            props.handleConfirmCancel();
            if(props.onFinishEditing) {
                props.onFinishEditing(props.idQuestion);
            }
        } else {
            props.setDialogOpen(true);
        }
    };

    const handleChangeSwitch = (event) => {
        props.setIsKeepingText(event.target.checked);
    };

    function getTooltipTitle() {
        if(formIsValid(props.question)) {
            return '';
        }
        return (
            <>
                <Typography>{displaySelectSkillTooltips(props.question.skillId)}</Typography>
                <Typography>{displayStatementTooltips(props.question.statement)}</Typography>
                <Typography>{displayValidAnswersTooltips(props.question)}</Typography>
                {props.question?.answers?.map((answer) => (
                    <Typography key={getAnswerKey(props.question.class, answer)}>{displayAnswserTooltips(answer, props.question.class)}</Typography>
                ))}
            </>);
    }

    return (
        <Fragment>
            <div className="buttonContainer">
                <div className="buttonsAddCancelDelete">
                    <HtmlTooltip className="addButtonTooltip" arrow title={getTooltipTitle()}>
                        <span>
                            <Button
                                className="addButton" color="primary" disabled={!formIsValid(props.question)}
                                startIcon={<DoneIcon />} onClick={props.isEditing ? () => setDialogOpen(true) : () => submitQuestion(false)}
                                cy-data="create-question-confirm" data-testid="add-button"
                            >
                                {props.isEditing ? 'Editer' : 'Ajouter'}
                            </Button>
                        </span>
                    </HtmlTooltip>
                    {
                        props.isEditing &&
                        <HtmlTooltip className="createButtonTooltip" arrow title={getTooltipTitle()}>
                            <span>
                                <Button
                                    className="createButton" color="primary" disabled={!formIsValid(props.question)}
                                    startIcon={<AddCircleOutlineIcon />} onClick={() => submitQuestion(true)}
                                >
                                    Créer
                                </Button>
                            </span>
                        </HtmlTooltip>
                    }
                    <Button
                        className="cancelButton" color="primary"
                        startIcon={<CancelIcon />} onClick={() => handleClickOpenCancel()}
                    >
                        Annuler
                    </Button>
                    {/*
                        Ce code ne sert à rien, ne pas hésiter à supprimer en cas de refacto après avoir vérifié avec Max
                        Ce code n'est donc pas testé
                    */}
                    {props.isEditing && <Button
                        disabled
                        className="deleteButton" color="primary"
                        startIcon={<DeleteIcon />} onClick={() => handleDeleteMCQ()}
                    >
                        Supprimer
                    </Button>}
                </div>
                {!props.isEditing && <FormControlLabel
                    className="keepTextSwitch"
                    control={<Switch
                        checked={props.isKeepingText}
                        onChange={handleChangeSwitch}
                        color="primary"
                        name="keepingText"
                        inputProps={{ 'aria-label': 'Conserver le contenu de la question après création' }}
                        cy-data="create-question-keep-content"
                    />
                    }
                    label="Conserver le contenu de la question après création"
                />
                }
            </div>
            <Dialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
            >
                <DialogTitle>
                    Êtes vous sûr de vouloir modifier cette question ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        Cela entrainera la modification de cette question dans les autres chapitres associés
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="closeDialogButton"
                        onClick={() => setDialogOpen(false)}
                    >
                        non
                    </Button>
                    <Button
                        className="confirmEditButton"
                        data-testid="confirm-edit-button"
                        onClick={() => submitQuestion()}
                    >
                        oui
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

CreateEditQuestionFooter.propTypes = {
    question: PropTypes.object,
    isEditing: PropTypes.bool,
    isKeepingText: PropTypes.bool,
    setIsKeepingText: PropTypes.func.isRequired,
    idQuestion: PropTypes.number,
    handleConfirmCancel: PropTypes.func.isRequired,
    onFinishEditing: PropTypes.func,
    setDialogOpen: PropTypes.func.isRequired,
    emptyMCQ: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired
};
