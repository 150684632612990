
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './SkillModerator.scss';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { selectExcilyensDetailedBySkill } from '../../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import { patchUserSkillModerator } from '../../redux/actions/User/UserSkills/userSkill.actions';
import { createLoadingSelectorForStoreValue } from '../../redux/selectors/APISelector/APISelector';
import { GET_ALL_EXCILYENS } from '../../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import { ModeratorLoader } from './ModeratorLoader';

export class SkillModerator extends Component {

    unameModerator = (user, skill) => {
        const map = {
            moderator: false
        };
        this.props.patchUserSkillModerator(user.id, skill.id, map);
    };

    render() {
        const users = this.props.allExcilyens;
        const moderators = [];

        if(users?.length > 0) {
            users.forEach((element) => {
                element.skillDetailedApiDtos.forEach((skill) => {
                    if(skill.skillName === this.props.skill.skillName) {
                        if(skill.moderator) {
                            moderators.push(element);
                        }
                    }
                });
            });
        }

        const items = [];

        // eslint-disable-next-line no-unused-vars
        for(const [index] of moderators.entries()) {
            items.push(<div className="thePaper" key={index}>
                <Paper className="paper">
                    {moderators[index].firstName} {moderators[index].lastName}
                </Paper>
                <Button color="primary" id="uname-moderator" onClick={() => {
                    this.unameModerator(moderators[index], this.props.skill);
                }}>
                    <Icon>indeterminate_check_box</Icon>Supprimer le role modérateur
                </Button>
            </div>);
        }

        let loader = null;
        switch (this.props.width) {
            case 'xl':
                loader = <ModeratorLoader column={6}/>;
                break;
            case 'lg':
                loader = <ModeratorLoader column={5}/>;
                break;
            case 'md':
                loader = <ModeratorLoader/>;
                break;
            case 'sm':
                loader = <ModeratorLoader column={3}/>;
                break;
            case 'xs':
                loader = <ModeratorLoader column={2} itemHeight={70}/>;
                break;
            default:
                loader = <ModeratorLoader/>;
        }

        return (
            <>
                {this.props.isFetching
                    ? loader
                    : (items.length > 0
                        ? <section className="skill-moderator">
                            <Grid container spacing={3} direction="row" alignItems="center" className="items" data-testid="moderator-container">
                                {items}
                            </Grid>
                        </section>
                        : <Typography id="no-moderator" variant="subtitle1" component="h3" data-testid="no-moderator" noWrap>
                            Aucun modérateur
                        </Typography>
                    )}
            </>
        );
    }
}

SkillModerator.propTypes = {
    skill: PropTypes.shape({
        skillName: PropTypes.string,
        familyName: PropTypes.string,
        familyNameOptional: PropTypes.string,
        categoryName: PropTypes.string,
        description: PropTypes.string,
        expertLevel: PropTypes.number,
        imageUrl: PropTypes.string
    }),
    patchUserSkillModerator: PropTypes.func,
    excilyensDetailedBySkill: PropTypes.array,
    allExcilyens: PropTypes.array,
    width: PropTypes.string,
    isFetching: PropTypes.bool
};

const mapStateToProps = (state) => ({
    allExcilyens: selectExcilyensDetailedBySkill(state),
    isFetching: createLoadingSelectorForStoreValue([GET_ALL_EXCILYENS], selectExcilyensDetailedBySkill)(state)
});

export function mapDispatchToProps(dispatch) {
    return {
        patchUserSkillModerator: (email, skillName, map) => dispatch(patchUserSkillModerator(email, skillName, map))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillModerator);
