import axiosClient from './utils/axiosClient';

export const TASK_URI = 'tasks';
export const TASK_GROUP_URI = 'group';


export function getTaskByStudentIdApi(studentId) {
    return axiosClient.get(`${TASK_URI}/student?studentId=${studentId}`);
}

export function getDetailedTasksByGroupIdApi(groupId, taskId) {
    return axiosClient.get(`${TASK_URI}/groupDetails?groupId=${groupId}${taskId ? `&taskId=${taskId}` : ''}`);
}

export function getAllTaskByGroupIdApi(groupId) {
    return axiosClient.get(`${TASK_URI}/allByGroup?groupId=${groupId}`);
}

export function getTaskByGroupIdApi(groupId, getTreated) {
    return axiosClient.get(`${TASK_URI}/groups/${groupId}?getTreated=${getTreated}`);
}

export function postTaskApi(task, sameQuestions) {
    return axiosClient.post(`${TASK_URI}?sameQuestions=${Boolean(sameQuestions)}`, task);
}

export function patchTaskApi(taskId) {
    return axiosClient.patch(`${TASK_URI}/${taskId}/treated`);
}

export function patchStudentTaskApi(taskId, studentId) {
    return axiosClient.patch(`${TASK_URI}/${taskId}/treated/${studentId}`);
}

export function deleteTaskApi(taskId) {
    return axiosClient.delete(`${TASK_URI}/${taskId}`);
}

export function addCustomTaskApi(body) {
    return axiosClient.post(`${TASK_URI}/custom`, body);
}

