import { notificationError, notificationSuccess, notificationWarning } from '../../../Notification/notifications.actions';
import {
    ADD_SERIE_ERROR,
    UPDATE_SERIE_ERROR,
    EXPIRED_SESSION,
    SERIE_ADDED,
    SERIE_ALREADY_EXISTS,
    SERIE_UPDATED
} from '../../../../../Snackbar/NotificationMessages';

import { postSerieWithQuestionReducedApi, putSerieWithQuestionReducedApi } from '../../../../../api/seriesSetApi';
import { getSerieByIdWithQuestionReduced } from '../SerieGet/serieGet.actions';

export const ADD_SERIE = 'ADD_SERIE';
export const ADD_SERIE_REQUEST = 'ADD_SERIE_REQUEST';
export const ADD_SERIE_SUCCESS = 'ADD_SERIE_SUCCESS';
export const ADD_SERIE_FAILURE = 'ADD_SERIE_FAILURE';

export const EDIT_SERIE = 'EDIT_SERIE';
export const EDIT_SERIE_REQUEST = 'EDIT_SERIE_REQUEST';
export const EDIT_SERIE_SUCCESS = 'EDIT_SERIE_SUCCESS';
export const EDIT_SERIE_FAILURE = 'EDIT_SERIE_FAILURE';

export const SET_PAGE_PARAMS = 'SET_PAGE_PARAMS';
export const RESET_PAGE_PARAMS = 'RESET_PAGE_PARAMS';

export const ADD_QUESTION_AUDIO_BLOB_URL = 'ADD_QUESTION_AUDIO_BLOB_URL';
export const ADD_QUESTION_AUDIO_TEXT_ANSWER = 'ADD_QUESTION_AUDIO_TEXT_ANSWER';

export const setPageParams = (pageParams) => (dispatch) => {
    dispatch({
        type: SET_PAGE_PARAMS,
        payload: pageParams
    });
};

export const resetPageParams = () => (dispatch) => {
    dispatch({ type: RESET_PAGE_PARAMS });
};

export const addSerie = (serie) => (dispatch) => {
    dispatch({ type: ADD_SERIE_REQUEST });

    return postSerieWithQuestionReducedApi(serie).then(() => {
        dispatch({ type: ADD_SERIE_SUCCESS });
        dispatch(notificationSuccess(SERIE_ADDED));
    }, (error) => {
        dispatch({
            type: ADD_SERIE_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(SERIE_ALREADY_EXISTS));
        } else {
            dispatch(notificationError(ADD_SERIE_ERROR));
        }
    });
};

export const editSerie = (question) => (dispatch) => {
    dispatch({ type: EDIT_SERIE_REQUEST });

    return putSerieWithQuestionReducedApi(question).then(() => {
        dispatch({ type: EDIT_SERIE_SUCCESS });
        dispatch(getSerieByIdWithQuestionReduced(question.id));
        dispatch(notificationSuccess(SERIE_UPDATED));
    }, (error) => {
        dispatch({
            type: EDIT_SERIE_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(SERIE_ALREADY_EXISTS));
        } else {
            dispatch(notificationError(UPDATE_SERIE_ERROR));
        }
    });
};

export const addAudioBlobUrl = (questionId, audioBlobUrl) => (dispatch) => {
    dispatch({
        type: ADD_QUESTION_AUDIO_BLOB_URL,
        payload: {
            questionId,
            audioBlobUrl
        }
    });
};

export const addAudioTextAnswer = (questionId, audioTextAnswer) => (dispatch) => {
    dispatch({
        type: ADD_QUESTION_AUDIO_TEXT_ANSWER,
        payload: {
            questionId,
            audioTextAnswer
        }
    });
};

