import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { Icon, TextField } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AssignTypes } from 'app/utils/functions';
import colors from 'styles/colors.module.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

function ParcoursStep({ parcoursStep, index, onDrop, handleParcoursStepName, expand, toggleExpand }) {
    const [examMode, setExamMode] = useState(false);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.PARCOURS_STEP,
        item: {
            type: AssignTypes.PARCOURS_STEP,
            parcoursStep,
            index,
        },
        collect: (monitor) => ({ 
            isDragging: monitor.isDragging(),
            dragItem: monitor.getItem()
        })
    }), [parcoursStep]);

    const [{ canDrop, isOver }, dropAfter] = useDrop(() => ({
        accept: [AssignTypes.CHAPTER, AssignTypes.QUESTION, AssignTypes.SERIE, AssignTypes.PARCOURS_STEP, AssignTypes.PARCOURS_STEP_CONTENT],
        drop: (item) => {
            onDrop(index, item);
        },
        canDrop: () => true,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
    }), [parcoursStep]);

    const changeExamMode = () => {
        setExamMode(!examMode);
        parcoursStep.examMode = !parcoursStep.examMode;
    };

    useEffect(()=>{
        setExamMode(parcoursStep.examMode);
    },[parcoursStep]);


    const draggingStyle = {
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? colors['drop-zone-parcours'] : 'white',
        cursor: 'move',
        border: `1px dashed ${canDrop ? 'green' : 'orange'}`,
        padding: '8px',
        marginBottom: '8px'
    };

    return (
        <div ref={(node) => drag(dropAfter(node))} style={{ ...draggingStyle }}>
            {isDragging
                ? <div style={{ padding: '5px', backgroundColor: 'lightgrey', borderRadius: '5px' }}>
                    {parcoursStep?.name}
                </div>
                :
                <div style={{ display: 'flex' , justifyContent:'space-between' }}>
                    {expand
                        ? <ExpandMoreIcon onClick={toggleExpand} style={{ cursor: 'pointer', fontSize: '2rem', alignSelf: 'center' }} />
                        : <ChevronRightIcon onClick={toggleExpand} style={{ cursor: 'pointer', fontSize: '2rem', alignSelf: 'center' }} />
                    }
                    <TextField
                        label="Nouvelle étape"
                        data-testid="parcours-step-creation-field"
                        name="create-parcours-step-textfield"
                        inputProps={{ maxLength: 100 }}
                        style={{
                            marginLeft: '20px',
                            backgroundColor: isOver && canDrop ? colors['drop-zone-parcours'] : 'inherit'
                        }}
                        required
                        value={parcoursStep.name}
                        onChange={(event) => handleParcoursStepName(index, event.target.value)}
                        autoComplete='off'
                    />
                    <Icon
                        color={examMode ? 'primary' : 'grey' }
                        style={{  alignSelf: 'center' }}
                        onClick={changeExamMode}
                    >school</Icon>
                    <MenuIcon
                        style={{ cursor: 'grab', alignSelf: 'center' }}
                    />
                </div>
            }
        </div>
    );
}

ParcoursStep.propTypes = {
    parcoursStep: PropTypes.object,
    index: PropTypes.number,
    onDrop: PropTypes.func.isRequired,
    handleParcoursStepName: PropTypes.func.isRequired,
    expand: PropTypes.bool,
    toggleExpand: PropTypes.func.isRequired
};

export default ParcoursStep;
