
/* eslint-disable id-length */
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { selectFamilies } from '../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { selectAllExcilyenSet } from '../../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import { connect } from 'react-redux';
import './UserStatistics.scss';
import UserStatistics from './UserStatistics';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

export class UserStatisticsContent extends Component {

    render() {
        let sortedSkillsByFamily = [];
        const skillsByFamily = [];
        const userSkills = this.props.userSkills || [];
        this.props.families.forEach((family) => {
            const skillFamily = {
                name: family.name,
                skills: []
            };
            userSkills.forEach((skill) => {
                if(skill && skill.familyName === family.name) {
                    skillFamily.skills.push(skill);
                }
            });
            skillsByFamily.push(skillFamily);
        });
        sortedSkillsByFamily = skillsByFamily;

        return (
            <Grid container data-testid="user-statistics-content">
                {sortedSkillsByFamily.map((family, index) => <Grid item xs={12} md={6} key={index}>
                    <UserStatistics familyName={family.name} user={this.props.user} userSkills={userSkills} />
                </Grid>)}
            </Grid>
        );
    }
}

UserStatisticsContent.propTypes = {
    userSkills: PropTypes.array,
    families: PropTypes.array,
    excilyens: PropTypes.array,
    allSkills: PropTypes.array,
    user: PropTypes.object
};

function mapStateToProps(state) {
    return {
        families: selectFamilies(state),
        excilyens: selectAllExcilyenSet(state),
        allSkills: selectAllSkills(state).data
    };
}

export default connect(mapStateToProps)(UserStatisticsContent);
