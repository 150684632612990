import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ProjectDetails.scss';
import ProjectDetailsContent from './ProjectDetailsContent/ProjectDetailsContent';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentUserById } from '../../../../redux/actions/User/user.actions';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';
import { selectCurrentProject } from '../../../../redux/selectors/Project/projects.selector';
import { notificationError } from '../../../../redux/actions/Notification/notifications.actions';
import { REDIRECT_EXCILYENS_ACCESS_PROJECT } from '../../../../Snackbar/NotificationMessages';
import { projectsActions } from 'app/redux/slices/projects.slice';

export class ProjectDetails extends Component {

    state = {
        redirect: false
    };

    componentDidMount() {
        this.getProjectAndCurrentUser();
    }

    async getProjectAndCurrentUser() {
        if(Object.keys(this.props.currentUser).length === 0) {
            await this.props.getCurrentUserById();
        }
        await this.props.getProjectById(this.props.match.params.id);

        this.isProjectNotValidatedAndUserNotAdmin();
    }

    isProjectNotValidatedAndUserNotAdmin() {
        const { project, currentUser } = this.props;
        const { redirect } = this.state;

        if(project && Object.keys(currentUser).length !== 0 && !redirect) {
            if(project.validated !== true && currentUser.roles && currentUser.roles.some((role) => role.name === 'ADMIN') !== true && !redirect) {
                this.props.notificationError();
                this.setState({
                    redirect: true
                });
            }
        } else if(!redirect) {
            this.setState({
                redirect: true
            });
        }
    }

    render() {
        const { project, currentUser } = this.props;
        const { redirect } = this.state;

        return (
            <section className="projects">
                <div className="wrapper">
                    {redirect && <Redirect to={'/projects'}/>}
                    {this.props.project &&
                        <ProjectDetailsContent project={project} user={currentUser}/>
                    }
                </div>
            </section>
        );
    }
}

ProjectDetails.propTypes = {
    match: PropTypes.object,
    project: PropTypes.object,
    currentUser: PropTypes.object,
    getProjectById: PropTypes.func,
    getCurrentUserById: PropTypes.func,
    isProjectNotValidatedAndUserNotAdmin: PropTypes.func,
    notificationError: PropTypes.func
};

function mapStateToProps(state) {
    return {
        project: selectCurrentProject(state).data,
        currentUser: selectCurrentUser(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectById: (projectId) => dispatch(projectsActions.getProjectById(projectId)),
        getCurrentUserById: () => dispatch(getCurrentUserById()),
        notificationError: () => dispatch(notificationError(REDIRECT_EXCILYENS_ACCESS_PROJECT))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectDetails));
