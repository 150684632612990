

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { selectSkillsNumberByFamily } from '../redux/selectors/Skill/SkillSet/skillSet.selector';
import { connect } from 'react-redux';
import { getAllFamilies } from '../redux/actions/FamilyCategory/familiesCategories.actions';
import { selectFamilies } from '../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { Paper } from '@material-ui/core';
import Chart from 'react-google-charts';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';


export class SkillNumberByFamilies extends Component {

    componentDidMount() {
        this.props.getAllFamilies();
    }

    render() {

        const data = [
            [
                'label',
                'number'
            ]
        ];
        this.props.families.forEach(((element) => data.push([
            element.name,
            this.props.selectSkillsNumberByFamily(element.name)
        ])));
        const COLORS = [
            '#B0A472',
            '#F5DF65',
            '#2B9464',
            '#59C8DF',
            '#D14D28',
            '#75EB00',
            '#53BBF4',
            '#FF85CB',
            '#FF432E',
            '#FFAC00',
            'red',
            'silver',
            'teal',
            'white'
        ];

        return (
            <Paper>
                <div className="titre"> Nombre de compétences réparties par familles </div>
                <Chart
                    width= "100%"
                    height={500}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={{
                        pieHole: 0.4,
                        colors: COLORS,
                        title: `${this.props.skillSet.filter((skill) => skill.coveredByExcilys).length} compétences`,
                        titleTextStyle: { fontSize: 18 },
                        pieSliceText: 'value'
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </Paper>
        );
    }
}


SkillNumberByFamilies.propTypes = {
    skillSet: PropTypes.array,
    selectExcilyensBySkill: PropTypes.func,
    selectExcilyensLevelAverageBySkill: PropTypes.func,
    getAllFamilies: PropTypes.func,
    selectSkillsNumberByFamily: PropTypes.func,
    families: PropTypes.array
};

const mapStateToProps = (state) => ({
    skillSet: selectAllSkills(state).data,
    selectSkillsNumberByFamily: (family) => selectSkillsNumberByFamily(state, family),
    families: selectFamilies(state)
});

const mapDispatchToProps = (dispatch) => ({
    getAllFamilies: () => dispatch(getAllFamilies())
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillNumberByFamilies);
