import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_QUESTIONS_ERROR,
    RETRIEVE_QUESTION_ERROR,
    RETRIEVE_QUESTION_ERROR_404
} from 'app/Snackbar/NotificationMessages';

import {
    getQuestionsDisplayPaginatedApi,
    getQuestionByIdWithoutExplanationApi,
    getQuestionsByChapterIdAndUserIdApi
} from 'app/api/multipleChoiceQuestionApi';

export const GET_QUESTION_TO_PREVIEW_BY_ID_REQUEST = 'GET_QUESTION_TO_PREVIEW_BY_ID_REQUEST';
export const GET_QUESTION_TO_PREVIEW_BY_ID_SUCCESS = 'GET_QUESTION_TO_PREVIEW_BY_ID_SUCCESS';
export const GET_QUESTION_TO_PREVIEW_BY_ID_FAILURE = 'GET_QUESTION_TO_PREVIEW_BY_ID_FAILURE';
export const RESET_QUESTION_TO_PREVIEW = 'RESET_QUESTION_TO_PREVIEW';

export const GET_QUESTIONS_PAGINATED = 'GET_QUESTIONS_PAGINATED';
export const GET_QUESTIONS_PAGINATED_REQUEST = 'GET_QUESTIONS_PAGINATED_REQUEST';
export const GET_QUESTIONS_PAGINATED_FAILURE = 'GET_QUESTIONS_PAGINATED_FAILURE';
export const GET_QUESTIONS_PAGINATED_SUCCESS = 'GET_QUESTIONS_PAGINATED_SUCCESS';
export const RESET_QUESTIONS_REDUCED = 'RESET_QUESTIONS_REDUCED';

export const GET_QUESTIONS_BY_CHAPTER_ID_REQUEST = 'GET_QUESTIONS_BY_CHAPTER_ID_REQUEST';
export const GET_QUESTIONS_BY_CHAPTER_ID_SUCCESS = 'GET_QUESTIONS_BY_CHAPTER_ID_SUCCESS';
export const GET_QUESTIONS_BY_CHAPTER_ID_FAILURE = 'GET_QUESTIONS_BY_CHAPTER_ID_FAILURE';
export const SET_QUESTIONS_TO_DISPLAY = 'SET_QUESTIONS_TO_DISPLAY';

export const GET_GENERATE_MULTIPLE_CHOICE_QUESTION = 'GET_GENERATE_MULTIPLE_CHOICE_QUESTION';
export const GET_GENERATE_MULTIPLE_CHOICE_QUESTION_REQUEST = 'GET_GENERATE_MULTIPLE_CHOICE_QUESTION_REQUEST';
export const GET_GENERATE_MULTIPLE_CHOICE_QUESTION_SUCCESS = 'GET_GENERATE_MULTIPLE_CHOICE_QUESTION_SUCCESS';
export const GET_GENERATE_MULTIPLE_CHOICE_QUESTION_FAILURE = 'GET_GENERATE_MULTIPLE_CHOICE_QUESTION_FAILURE';

export const getQuestionToPreviewById = (questionId) => (dispatch) => {
    dispatch({ type: GET_QUESTION_TO_PREVIEW_BY_ID_REQUEST });

    return getQuestionByIdWithoutExplanationApi(questionId).then((response) => {
        dispatch({
            type: GET_QUESTION_TO_PREVIEW_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTION_TO_PREVIEW_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR_404));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTION_ERROR));
        }
    });
};

export const resetQuestionToPreview = () => (dispatch) => {
    dispatch({ type: RESET_QUESTION_TO_PREVIEW });
};

export const getQuestionsDisplayPaginated = (paginationCriteria) => (dispatch) => {
    dispatch({ type: GET_QUESTIONS_PAGINATED_REQUEST });

    return getQuestionsDisplayPaginatedApi(paginationCriteria).then((response) => {
        dispatch({
            type: GET_QUESTIONS_PAGINATED_SUCCESS,
            payload: { ...paginationCriteria,
                listOfElements: response.data.listOfElements,
                totalNumberOfElements: response.data.totalNumberOfElements }
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTIONS_PAGINATED_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTIONS_ERROR));
        }
    });
};

export const resetQuestionsReduced = () => (dispatch) => {
    dispatch({ type: RESET_QUESTIONS_REDUCED });
};

export const getQuestionsByChapterIdAndUserId = (chapterId, userId, search) => (dispatch) => {
    dispatch({ type: GET_QUESTIONS_BY_CHAPTER_ID_REQUEST });

    return getQuestionsByChapterIdAndUserIdApi(chapterId, userId, search).then((response) => {
        dispatch({
            type: GET_QUESTIONS_BY_CHAPTER_ID_SUCCESS,
            payload: {
                id: chapterId,
                data: response.data
            }
        });
    }, (error) => {
        dispatch({
            type: GET_QUESTIONS_BY_CHAPTER_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_QUESTIONS_ERROR));
        }
    });
};

export const setQuestionsToDisplay = (question) => (dispatch) => {
    dispatch({
        type: SET_QUESTIONS_TO_DISPLAY,
        payload: question
    });
};
