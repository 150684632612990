import { postSkillProjectsApi, deleteSkillProjectsApi } from '../../../api/skillProjectsApi';
import { notificationError, notificationWarning, notificationSuccess } from '../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    SKILL_ALREADY_IN_PROJECT_STACK,
    SKILL_NOT_IN_PROJECT_STACK,
    ADD_SKILL_TO_PROJECT_SUCCESS,
    ADD_SKILL_TO_PROJECT_ERROR,
    DELETE_SKILL_FROM_PROJECT_SUCCESS,
    DELETE_SKILL_FROM_PROJECT_ERROR
} from '../../../Snackbar/NotificationMessages';

import { projectsActions } from 'app/redux/slices/projects.slice';

export const ADD_SKILL_PROJECT = 'ADD_SKILL_PROJECT';
export const ADD_SKILL_PROJECT_REQUEST = 'ADD_SKILL_PROJECT_REQUEST';
export const ADD_SKILL_PROJECT_SUCCESS = 'ADD_SKILL_PROJECT_SUCCESS';
export const ADD_SKILL_PROJECT_FAILURE = 'ADD_SKILL_PROJECT_FAILURE';

export const DELETE_SKILL_PROJECT = 'DELETE_SKILL_PROJECT';
export const DELETE_SKILL_PROJECT_REQUEST = 'DELETE_SKILL_PROJECT_REQUEST';
export const DELETE_SKILL_PROJECT_SUCCESS = 'DELETE_SKILL_PROJECT_SUCCESS';
export const DELETE_SKILL_PROJECT_FAILURE = 'DELETE_SKILL_PROJECT_FAILURE';

export const addSkillProject = (skillProject) => (dispatch) => {
    dispatch({ type: ADD_SKILL_PROJECT_REQUEST });

    return postSkillProjectsApi(skillProject).then((response) => {
        dispatch({
            type: ADD_SKILL_PROJECT_SUCCESS,
            payload: response.data
        });
        dispatch(projectsActions.getAllProjects());
        dispatch(projectsActions.getProjectById(skillProject.projectId));
        dispatch(notificationSuccess(ADD_SKILL_TO_PROJECT_SUCCESS));
    }, (error) => {
        dispatch({
            type: ADD_SKILL_PROJECT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(SKILL_ALREADY_IN_PROJECT_STACK));
        } else {
            dispatch(notificationError(ADD_SKILL_TO_PROJECT_ERROR));
        }
    });
};

export const deleteSkillProject = (skillProject) => (dispatch) => {
    dispatch({ type: DELETE_SKILL_PROJECT_REQUEST });

    return deleteSkillProjectsApi(skillProject).then((response) => {
        dispatch({
            type: DELETE_SKILL_PROJECT_SUCCESS,
            payload: response.data
        });
        dispatch(projectsActions.getAllProjects());
        dispatch(projectsActions.getProjectById(skillProject.projectId));
        dispatch(notificationSuccess(DELETE_SKILL_FROM_PROJECT_SUCCESS));
    }, (error) => {
        dispatch({
            type: DELETE_SKILL_PROJECT_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(SKILL_NOT_IN_PROJECT_STACK));
        } else {
            dispatch(notificationError(DELETE_SKILL_FROM_PROJECT_ERROR));
        }
    });
};
