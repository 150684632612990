import { deleteQuestionModerationApi, postQuestionModerationApi } from 'app/api/questionModerationApi';
import { ADD_QUESTION_MODERATION_ERROR, DELETE_QUESTION_MODERATION_ERROR, EXPIRED_SESSION } from 'app/Snackbar/NotificationMessages';
import { notificationError } from '../../Notification/notifications.actions';

export const ADD_QUESTION_MODERATION_REQUEST = 'ADD_QUESTION_MODERATION_REQUEST';
export const ADD_QUESTION_MODERATION_FAILURE = 'ADD_QUESTION_MODERATION_FAILURE';

export const ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS = 'ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS';
export const ADD_QUESTION_TABLE_MODERATOR_SUCCESS = 'ADD_QUESTION_TABLE_MODERATOR_SUCCESS';
export const ADD_MCQ_MODERATORS_SUCCESS = 'ADD_MCQ_MODERATORS_SUCCESS';
export const ADD_RESOLVE_SERIE_MODERATOR_SUCCESS = 'ADD_RESOLVE_SERIE_MODERATOR_SUCCESS';

export const DELETE_QUESTION_MODERATION_REQUEST = 'DELETE_QUESTION_MODERATION_REQUEST';
export const DELETE_QUESTION_MODERATION_FAILURE = 'DELETE_QUESTION_MODERATION_FAILURE';


export const DELETE_QUESTION_CHAPTER_MODERATORS_SUCCESS = 'DELETE_QUESTION_CHAPTER_MODERATORS_SUCCESS';
export const DELETE_MCQ_MODERATORS_SUCCESS = 'DELETE_MCQ_MODERATORS_SUCCESS';
export const DELETE_QUESTION_TABLE_MODERATOR_SUCCESS = 'DELETE_QUESTION_TABLE_MODERATOR_SUCCESS';
export const DELETE_RESOLVE_SERIE_MODERATOR_SUCCESS = 'DELETE_RESOLVE_SERIE_MODERATOR_SUCCESS';

function dispatchBuilder(action, questionUserModeration) {
    return {
        type: action,
        payload: questionUserModeration
    };
}

export const addQuestionModeration = (questionUserModeration) => (dispatch) => {
    dispatch({ type: ADD_QUESTION_MODERATION_REQUEST });
    return postQuestionModerationApi({
        questionId: questionUserModeration.questionId,
        userId: questionUserModeration.moderator.userId
    }).then(() => {
        dispatch(dispatchBuilder(ADD_MULTIPLE_CHOICE_QUESTION_SUCCESS, questionUserModeration));
    }, (error) => {
        dispatch({
            type: ADD_QUESTION_MODERATION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(!error.response || error.response.status !== 403) {
            dispatch(notificationError(ADD_QUESTION_MODERATION_ERROR));
        }
    });
};

export const deleteQuestionModeration = (questionUserModeration) => (dispatch) => {
    dispatch({ type: DELETE_QUESTION_MODERATION_REQUEST });
    return deleteQuestionModerationApi({
        questionId: questionUserModeration.questionId,
        userId: questionUserModeration.moderator.userId
    }).then(() => {
        dispatch(dispatchBuilder(DELETE_QUESTION_CHAPTER_MODERATORS_SUCCESS, questionUserModeration));
    }, (error) => {
        dispatch({
            type: DELETE_QUESTION_MODERATION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(!error.response || error.response.status !== 403) {
            dispatch(notificationError(DELETE_QUESTION_MODERATION_ERROR));
        }
    });
};

