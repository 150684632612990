import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import './QuestionResolverWithCourse.scss';
import ChapterCourseRequest from './ChapterCourse/ChapterCourseRequest';

import { useTheme, useMediaQuery, Container, Typography, LinearProgress, Button } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { deleteQuestionById } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions.js';
import { selectQuestionWithExplanation } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import QuestionContainer from './QuestionContainer/QuestionContainer';
import { isQuestionShuffled } from 'app/utils/QuestionUtils';
import { localLlmServiceApi } from 'app/api/localLlmApi';

export function QuestionResolverWithCourse(props) {

    const dispatch = useDispatch();
    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const questionWithExplanation = useSelector(selectQuestionWithExplanation);

    const updateContentWidth = useState(0)[1];
    const [showChapterCourse, setShowChapterCourse] = useState(false);
    const [showAiHelp, setShowAiHelp] = useState(false);

    const [aiHelpContent, setAiHelpContent] = useState('');
    const [aiExplanationContent, setAiExplanationContent] = useState('');
    const [visibleContent, setVisibleContent] = useState('');

    const [isFetchingAiResponse, setIsFetchingAiResponse] = useState(false);
    const [hasAnsweredWithCourse, setHasAnsweredWithCourse] = useState(false);

    const [savedQuestionContainerState, setSavedQuestionContainerState] = useState(undefined);

    useEffect(() => () => {
        dispatch(deleteQuestionById(props.questionAsked.id));
    }, []);

    useEffect(() => {
        setShowChapterCourse(false);
        setShowAiHelp(false);
        setHasAnsweredWithCourse(false);
    }, [props.questionAsked]);

    const handleShowChapterCourses = () => {
        setShowChapterCourse((state) => !state);
        setSavedQuestionContainerState({ ...savedQuestionContainerState, shouldUseSavedState: true });
    };

    const handleShowAiHelp = (status, selectedTextToSend) => {
        if(selectedTextToSend) {
            setShowAiHelp(status);
            setAiExplanationContent('');
            setAiHelpContent(selectedTextToSend);
            setSavedQuestionContainerState({ ...savedQuestionContainerState, shouldUseSavedState: true });

            if(status) {
                setShowChapterCourse(false);
            }

            const answersWithoutUnwantedValues = props.questionAsked.answers
            // eslint-disable-next-line no-unused-vars
                .map(({ isDisabled, selected, formControlStyle, ...rest }) => rest);

            const mcq = {
                statement: props.questionAsked.statement,
                answers: answersWithoutUnwantedValues,
                class: 'MultipleChoiceQuestion',
                skillId: props.questionAsked.skill?.id
            };

            localLlmServiceApi.generateMcqPartExplanation(JSON.stringify(mcq), selectedTextToSend)
                .then((response) => {
                    setAiExplanationContent(response.data);
                    setIsFetchingAiResponse(false);
                })
                .catch(() => {
                    setAiExplanationContent('Désolé, je ne suis pas disponible pour le moment. Veuillez réessayer.');
                    setIsFetchingAiResponse(false);
                });
        }
    };

    const hideAiHelp = () => {
        setShowAiHelp(false);
        setSavedQuestionContainerState({ ...savedQuestionContainerState, shouldUseSavedState: true });
    };

    useEffect(() => {
        const delay = 1;
        let currentIndex = 0;

        const intervalId = setInterval(() => {
            setVisibleContent(aiExplanationContent.substring(0, currentIndex));
            currentIndex++;

            if(currentIndex > aiExplanationContent.length) {
                clearInterval(intervalId);
            }
        }, delay);

        return () => clearInterval(intervalId);
    }, [aiExplanationContent]);

    if(props.disableCourse || props.hideFooter) {
        return <QuestionContainer
            {...props}
            onShowChapterCourses={handleShowChapterCourses}
            onEndSerieClick={props.onEndSerieClick}
            savedState={savedQuestionContainerState}
            saveCurrentState={setSavedQuestionContainerState}
        />;
    } else if(sizeUpMd && showChapterCourse) {
        return (
            <Allotment
                snap
                onChange={(sizes) => updateContentWidth(sizes[1])}
            >
                <Allotment.Pane>
                    <div className="max-size" style={{ overflowY: 'auto' }}>
                        <QuestionContainer
                            {...props}
                            shuffleAnswers={isQuestionShuffled(props.questionAsked)}
                            isShowChapterCourse={showChapterCourse}
                            onShowChapterCourses={handleShowChapterCourses}
                            onShowAiHelp={handleShowAiHelp}
                            setIsFetchingAiResponse={setIsFetchingAiResponse}
                            isFetchingAiResponse={isFetchingAiResponse}
                            onEndSerieClick={props.onEndSerieClick}
                            hasAnsweredWithCourse={hasAnsweredWithCourse}
                            setHasAnsweredWithCourse={setHasAnsweredWithCourse}
                            questionWithExplanation={questionWithExplanation}
                            savedState={savedQuestionContainerState}
                            saveCurrentState={setSavedQuestionContainerState}
                        />
                    </div>
                </Allotment.Pane>
                {showChapterCourse && questionWithExplanation?.chapterIds &&
                    <Allotment.Pane>
                        <div className="max-size" style={{ overflowY: 'auto' }}>
                            <ChapterCourseRequest
                                chapterIds={questionWithExplanation.chapterIds}
                            />
                        </div>
                    </Allotment.Pane>
                }
            </Allotment>
        );
    } else if(sizeUpMd && showAiHelp) {
        return (
            <Allotment
                snap
                onChange={(sizes) => updateContentWidth(sizes[1])}
            >
                <Allotment.Pane>
                    <div className="max-size" style={{ overflowY: 'scroll' }}>
                        <QuestionContainer
                            {...props}
                            shuffleAnswers={isQuestionShuffled(props.questionAsked)}
                            isShowChapterCourse={showChapterCourse}
                            onShowChapterCourses={handleShowChapterCourses}
                            onShowAiHelp={handleShowAiHelp}
                            setIsFetchingAiResponse={setIsFetchingAiResponse}
                            isFetchingAiResponse={isFetchingAiResponse}
                            onEndSerieClick={props.onEndSerieClick}
                            hasAnsweredWithCourse={hasAnsweredWithCourse}
                            setHasAnsweredWithCourse={setHasAnsweredWithCourse}
                            questionWithExplanation={questionWithExplanation}
                            savedState={savedQuestionContainerState}
                            saveCurrentState={setSavedQuestionContainerState}
                        />
                    </div>
                </Allotment.Pane>
                <Allotment.Pane>
                    <div className="max-size" style={{ overflowY: 'scroll' }}>
                        <Container className="chapterContainer">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={hideAiHelp}
                            >
                                Cacher l&lsquo;explication par l&lsquo;IA
                            </Button>
                            <Typography
                                color="primary"
                                variant="h5"
                                style={{ marginBottom: '15px', marginTop: '15px', width: '100%' }}
                            >
                                Vous avez besoin d&lsquo;aide sur
                            </Typography>
                            <p> {aiHelpContent} </p>
                            <Typography
                                color="primary"
                                variant="h5"
                                style={{ marginBottom: '15px', width: '100%' }}
                            >
                                Explication fournie par l&lsquo;IA
                            </Typography>
                            { isFetchingAiResponse && <LinearProgress/> }
                            <p> {visibleContent} </p>
                        </Container>
                    </div>
                </Allotment.Pane>
            </Allotment>
        );
    }

    return (
        <>
            <QuestionContainer
                {...props}
                shuffleAnswers={isQuestionShuffled(props.questionAsked)}
                isShowChapterCourse={showChapterCourse}
                onShowChapterCourses={handleShowChapterCourses}
                onEndSerieClick={props.onEndSerieClick}
                onShowAiHelp={handleShowAiHelp}
                setIsFetchingAiResponse={setIsFetchingAiResponse}
                isFetchingAiResponse={isFetchingAiResponse}
                hasAnsweredWithCourse={hasAnsweredWithCourse}
                setHasAnsweredWithCourse={setHasAnsweredWithCourse}
                questionWithExplanation={questionWithExplanation}
                savedState={savedQuestionContainerState}
                saveCurrentState={setSavedQuestionContainerState}
            />
            {showChapterCourse && questionWithExplanation?.chapterIds &&
                <ChapterCourseRequest
                    chapterIds={questionWithExplanation.chapterIds}
                />
            }
        </>
    );
}

QuestionResolverWithCourse.propTypes = {
    // Global props
    questionAsked: PropTypes.object.isRequired,

    // Display props
    hideFooter: PropTypes.bool,
    disableCourse: PropTypes.bool,
    isInHistory: PropTypes.bool,

    // Serie props
    interactiveQuizSerieHistoryUpdate: PropTypes.object,
    interactiveQuizTotalNbQuestions: PropTypes.number,

    // Callbacks
    onEndSerieClick: PropTypes.func
};

export default QuestionResolverWithCourse;
