import {
    getAllSectorsApi,
    getSectorByIdApi
} from '../../../api/sectorsApi';
import { notificationError } from '../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_SECTORS_ERROR,
    RETRIEVE_SECTOR_ERROR
} from '../../../Snackbar/NotificationMessages';


export const GET_SECTOR_BY_ID = 'GET_SECTOR';
export const GET_SECTOR_BY_ID_REQUEST = 'GET_SECTOR_REQUEST';
export const GET_SECTOR_BY_ID_SUCCESS = 'GET_SECTOR_SUCCESS';
export const GET_SECTOR_BY_ID_FAILURE = 'GET_SECTOR_FAILURE';

export const GET_ALL_SECTORS = 'GET_ALL_SECTORS';
export const GET_ALL_SECTORS_REQUEST = 'GET_ALL_SECTORS_REQUEST';
export const GET_ALL_SECTORS_SUCCESS = 'GET_ALL_SECTORS_SUCCESS';
export const GET_ALL_SECTORS_FAILURE = 'GET_ALL_SECTORS_FAILURE';

export const getAllSectors = () => (dispatch) => {
    dispatch({ type: GET_ALL_SECTORS_REQUEST });

    return getAllSectorsApi().then((response) => {
        dispatch({
            type: GET_ALL_SECTORS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_SECTORS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SECTORS_ERROR));
        }
    });
};

export const getSectorById = (id) => (dispatch) => {
    dispatch({ type: GET_SECTOR_BY_ID_REQUEST });

    return getSectorByIdApi(id).then((response) => {
        dispatch({
            type: GET_SECTOR_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_SECTOR_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_SECTOR_ERROR));
        }
    });
};

