import axiosClient from './utils/axiosClient';

export const SERIES_SET_URI = 'series';

export function postSerieWithQuestionReducedApi(serie) {
    return axiosClient.post(SERIES_SET_URI, serie);
}

export function putSerieWithQuestionReducedApi(serie) {
    return axiosClient.put(SERIES_SET_URI, serie);
}
