import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './ChapterDescription.scss';

import { Button, IconButton, LinearProgress, TextField, TextareaAutosize, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import RichTextEditor from 'app/common/richTextEditor/RichTextEditor';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { ChapterResourceTable } from '../../ChapterRessourceTable/ChapterRessourceTable';
import { getChapterResources } from 'app/redux/actions/Chapter/Resource/resource.actions';
import {
    closeChapterReporting,
    openChapterReporting,
    setSelectedReportedChapter,
    updateChapter,
    getChapterReportingByChapterId
} from 'app/redux/actions/Chapter/chapter.actions';
import { useDispatch, useSelector } from 'react-redux';
import { StyledText } from 'app/utils/StyledText';
import PlayChapterButton from '../../PlayChapterQuestions/PlayChapterButton';
import BookmarkButton from '../../BookmarkButton';
import ChapterReportingButton from '../../ChapterReportingButton/ChapterReportingButton';
import Badge from '@material-ui/core/Badge';
import ChapterReportingPane from '../../ChapterReportingPane/ChapterReportingPane';
import { selectReportedChapter } from '../../../../../../redux/selectors/Chapter/chapter.selector';
import { localLlmServiceApi } from 'app/api/localLlmApi';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, GENERATE_CHAPTER_STATEMENT } from 'app/Snackbar/NotificationMessages';

function ChapterDescription(props) {

    const dispatch = useDispatch();
    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const reportedChapter = useSelector(selectReportedChapter);
    const [generatedDescription, setGeneratedDescription] = useState(null);
    const [chapterDescription, setChapterDescription] = useState(props.chapter.header);

    const [isFetching, setIsFetching] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [chapterTitle, setChapterTitle] = useState(props.chapter.name);

    useEffect(() => {
        setChapterDescription(props.chapter.header);
    }, [props.chapter]);

    useEffect(() => {
        if(isEditing) {
            dispatch(getChapterReportingByChapterId(props.chapter.id === null ? 0 : props.chapter.id));
        }
    }, [isEditing]);

    const handleChangeDescription = (event, editor) => {
        setChapterDescription(editor.getData());
    };

    const handleClickSave = () => {
        setIsEditing(false);
        props.onEditionChange('');

        dispatch(updateChapter({ ...props.chapter, name: chapterTitle, header: chapterDescription }));
    };

    const handleClickRessources = (id) => {
        if(!props.showResources) {
            dispatch(getChapterResources(id));
        }
        props.setShowResources(!props.showResources);
    };


    const cssDescriptionControl = () => {
        if(sizeUpMd) {
            return 'chapter-description';
        }
        return 'chapter-description-mobile';
    };

    const cssDescriptionTitleControl = () => {
        if(sizeUpMd) {
            return 'chapter-description-title';
        }
        return 'chapter-description-title-mobile';
    };

    const handleChapterReportingPane = () => {
        if(isEditing && props.chapter.id === reportedChapter.id) {
            dispatch(closeChapterReporting());
        } else {
            dispatch(setSelectedReportedChapter(props.chapter));
            dispatch(openChapterReporting());
        }
    };

    const handleClickEdit = () => {
        setIsEditing(true);
        props.onEditionChange(props.chapter.name);
        handleChapterReportingPane();
    };

    const generateStatement = () => {
        setIsFetching(true);
        localLlmServiceApi.generateChapterStatement(props.chapter.name)
            .then((response) => {
                setIsFetching(false);
                setGeneratedDescription(response.data);
            })
            .catch((error) => {
                setIsFetching(false);
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(GENERATE_CHAPTER_STATEMENT));
                }
            });
    };

    return (
        <>
            <div className={cssDescriptionTitleControl()}>
                <div className="chapter-description-title-left">
                    {isEditing
                        ? <TextField onChange={(event) => setChapterTitle(event.target.value)} value={chapterTitle} />
                        : <Typography
                            color="primary"
                            variant="h5"
                        >
                            {props.chapter.name}
                        </Typography>
                    }

                    {props.permission && (props.editingChapter === props.chapter.name || props.editingChapter === '') &&
                        (isEditing
                            ? <IconButton
                                aria-label="Bouton sauvegarde chapitre header"
                                onClick={handleClickSave}
                            >
                                <SaveIcon />
                            </IconButton>
                            : <IconButton
                                aria-label="Bouton édition chapitre header"
                                onClick={handleClickEdit}
                                style={{ padding: 4 }}
                            >
                                <Badge badgeContent={props.reportCount} style={{ transform: 'translate(30px, -13px)' }} overlap="circular" color="secondary">
                                </Badge>
                                <EditIcon />
                            </IconButton>)
                    }
                    {sizeUpMd &&
                        <PlayChapterButton
                            chapterId={props.chapter.id}
                            chapterName={props.chapter.name}
                            skill={props.skill}
                        />
                    }
                    <BookmarkButton
                        chapterName={props.chapter?.name}
                        chapterId={props.chapter?.id}
                        skillId={props.skill.id}
                        isBookmarked={props.bookmark?.chapterId === props.chapter?.id}
                        bookmark={props.bookmark}
                    />
                </div>
                <div>
                    <ChapterReportingButton
                        chapterId={props.chapter.id}
                        skillName={props.skill?.name}
                    />
                    <IconButton aria-label="Show resources button"
                        onClick={() => handleClickRessources(props.chapter.id)}>
                        <FolderOpenIcon />
                    </IconButton>
                </div>
            </div>

            {props.showResources &&
                <div className="chapter-resource-table">
                    <ChapterResourceTable
                        chapterId={props.chapter.id}
                        chapterName={props.chapter.name}
                        resourceList={props.resourceList}
                        onChangeResourceIndex={props.onChangeResourceIndex}
                    />
                </div>}

            <div className={cssDescriptionControl()}>
                {isEditing
                    ? <div>
                        <RichTextEditor
                            data={chapterDescription}
                            onChange={handleChangeDescription}
                        />
                        <Button
                            onClick={generateStatement}
                            disabled={isFetching}
                            style={{ margin: '10px 0' }}
                        >Générer un cours</Button>
                        { isFetching && <LinearProgress/> }
                        { generatedDescription &&
                            <div>
                                <TextareaAutosize
                                    readOnly
                                    style={{ resize: 'none', width: '100%' }}
                                    value={generatedDescription}
                                />
                            </div>
                        }
                        <ChapterReportingPane chapterId={props.chapter.id}/>
                    </div>
                    : <StyledText
                        statement={props.chapter.header}
                        className="chapter-description-text"
                    />
                }
            </div>
        </>
    );
}

ChapterDescription.propTypes = {
    chapter: PropTypes.object,
    showResources: PropTypes.bool,
    setShowResources: PropTypes.func.isRequired,
    resourceList: PropTypes.array,
    onChangeResourceIndex: PropTypes.func,
    editingChapter: PropTypes.string.isRequired,
    onEditionChange: PropTypes.func.isRequired,
    user: PropTypes.object,
    skill: PropTypes.object.isRequired,
    permission: PropTypes.bool.isRequired,
    bookmark: PropTypes.object,
    reportCount: PropTypes.number
};


export default ChapterDescription;
