import axiosClient from './utils/axiosClient';

export const MULTIPLE_CHOICE_QUESTIONS_SET_URI = 'questions';

export function postMultipleChoiceQuestionApi(multipleChoiceQuestion) {
    return axiosClient.post(`${MULTIPLE_CHOICE_QUESTIONS_SET_URI}`, multipleChoiceQuestion);
}

export function postQuestionOuverteAnswerApi(audioFile) {
    return axiosClient.post(`${MULTIPLE_CHOICE_QUESTIONS_SET_URI}/questionOuverte/audioResponse`, audioFile, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function putReplaceChapterOfMultipleChoiceQuestionApi(questionId, oldChapterId, newChapterId) {
    const params = {
        questionId,
        oldChapterId,
        newChapterId
    };
    return axiosClient.put(`${MULTIPLE_CHOICE_QUESTIONS_SET_URI}/chapters`, undefined, { params });
}

export function putMultipleChoiceQuestionApi(multipleChoiceQuestion) {
    return axiosClient.put(MULTIPLE_CHOICE_QUESTIONS_SET_URI, multipleChoiceQuestion);
}

export function patchLanguageMultipleChoiceQuestion(questionId, languageAcronym) {
    return axiosClient.patch(`${MULTIPLE_CHOICE_QUESTIONS_SET_URI}/${questionId}`, languageAcronym);
}

export function setQuestionInactiveApi(question) {
    return axiosClient.patch(`${MULTIPLE_CHOICE_QUESTIONS_SET_URI}/inactive`, question);
}

export function deleteMultipleChoiceQuestionApi(multipleChoiceQuestionId) {
    return axiosClient.delete(`${MULTIPLE_CHOICE_QUESTIONS_SET_URI}/${multipleChoiceQuestionId}`);
}
