import { parcours, parcoursCreated, parcoursIdToDelete, parcoursIdToUnshare, parcoursList, parcoursShared, parcoursUpdated, statusParcours } from 'app/redux/reducers/Parcours/parcours.reducer';
import { parcoursStore } from 'app/redux/rootReducer';

export function selectParcours(state) {
    return state[parcoursStore][parcours];
}

export function selectParcoursList(state) {
    return state[parcoursStore][parcoursList];
}

export function selectParcoursIdToDelete(state) {
    return state[parcoursStore][parcoursIdToDelete];
}

export function selectParcoursCreated(state) {
    return state[parcoursStore][parcoursCreated];
}

export function selectParcoursUpdated(state) {
    return state[parcoursStore][parcoursUpdated];
}

export function selectParcoursShared(state) {
    return state[parcoursStore][parcoursShared];
}

export function selectParcoursIdToUnshare(state) {
    return state[parcoursStore][parcoursIdToUnshare];
}

export function selectStatusParcours(state) {
    return state[parcoursStore][statusParcours];
}
