import axiosClient from './utils/axiosClient';

const LOCAL_LLM_URI = 'local-llm';

export class localLlmServiceApi {

    static generateMcqExplanation(mcq: any) {
        return axiosClient.post(`${LOCAL_LLM_URI}/explanation`, mcq);
    }

    static generateMcqPartExplanation(mcq: any, partToExplain: string) {
        return axiosClient.post(`${LOCAL_LLM_URI}/help?partToExplain=${partToExplain}`, mcq);
    }

    static generateReviewQuestionExplanation(questionOuverteResponse: any, serieHistoryId: number) {
        const baseUrl = `${LOCAL_LLM_URI}/questionOuverte`;
        const url = serieHistoryId ? `${baseUrl}?serieHistoryId=${serieHistoryId}` : baseUrl;
        return axiosClient.post(url, questionOuverteResponse);
    }

    static translate(questionId: number, text: string) {
        return axiosClient.post(`${LOCAL_LLM_URI}/translate`, text, { params: { questionId } });
    }

    static generateMcqQuestionsByChapters(skillId: number, chapterList: any[]) {
        return axiosClient.post(`${LOCAL_LLM_URI}/questions/${skillId}`, chapterList);
    }

    static generateAnswers(mcq: any, goodAnswer: boolean[]) {
        return axiosClient.post(`${LOCAL_LLM_URI}/answers`, mcq, { params: { goodAnswer } });
    }

    static generateChapterStatement(chapterTitle: string) {
        return axiosClient.post(`${LOCAL_LLM_URI}/cours?chapterTitle=${chapterTitle}`);
    }
}
