import { Button, IconButton, LinearProgress, TextField, TextareaAutosize, Tooltip, makeStyles, useTheme, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Checkbox, Typography } from 'antd';
import { QuestionEditor } from 'app/common/richTextEditor/QuestionEditor';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formIsValid, newGreatestKey, questionIsValid } from '../CreateEditQuestionForm.functions';
import {
    addAnswer,
    atLeastOneMcqAnswerValid,
    deleteAnswer,
    handleAnswerValidityChange
} from './MultipleChoiceQuestionForm.functions';
import {
    displayAnswerHelperText,
    displayAnswserTooltips,
    displayStatementTooltips,
    displayValidAnswersTooltips
} from './CreateEditQuestionBody.functions';
import colors from 'styles/colors.module.scss';
import { localLlmServiceApi } from 'app/api/localLlmApi';
import { useDispatch } from 'react-redux';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, GET_EXPLANATION_ERROR } from 'app/Snackbar/NotificationMessages';
import { displaySelectSkillTooltips } from '../header/CreateEditQuestionHeader.functions';
import { getAnswerKey } from '../footer/CreateEditQuestionFooter.functions';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(() => ({
    chatGptButton: {
        marginBottom: '8px',
        marginTop: '8px',
        backgroundColor: colors['chat-gpt']
    }
}));

export function MultipleChoiceQuestionForm({ mcq, setMcq }) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const placeholderConfigExplanation = 'Insérez l\'explication';

    const [generatedExplanations, setGeneratedExplanations] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [selectGeneration, setSelectGeneration] = useState(-1);
    const [answersLoading, setAnswersLoading] = useState(Array(mcq.answers.length).fill(false));
    const [selectedButtons, setSelectedButtons] = useState(Array(mcq.answers.length).fill(false));

    const handleChangeGoodAnswer = (buttonIndex) => {
        setSelectedButtons((prevSelectedButtons) => {
            const newSelectedButtons = [...prevSelectedButtons];
            newSelectedButtons[buttonIndex] = !newSelectedButtons[buttonIndex];
            return newSelectedButtons;
        });
    };

    function handleChangeEditor(editor) {
        setMcq((prevMCQ) => ({
            ...prevMCQ,
            statement: editor.getData()
        }));
    }

    function handleAnswertextChange(event, answerTargeted) {
        const copy = { ...mcq };
        const answerToEdit = copy.answers.find((answer) => answer.label === answerTargeted.label);
        if(answerToEdit) {
            const newAnswer = {
                ...answerToEdit,
                text: event.target.value
            };
            copy.answers.splice(mcq.answers.indexOf(answerToEdit), 1, newAnswer);
        }
        setMcq(copy);
    }

    function handleChangeExplanation(event, editor) {
        setMcq((prevMCQ) => ({
            ...prevMCQ,
            explanation: editor.getData()
        }));
    }

    function generateExplanation() {
        setIsFetching(true);

        localLlmServiceApi.generateMcqExplanation(mcq)
            .then((response) => {
                setIsFetching(false);
                setGeneratedExplanations([...generatedExplanations, response?.data?.answer]);
                if(selectGeneration < 0) {
                    setSelectGeneration(0);
                } else {
                    setSelectGeneration(generatedExplanations.length);
                }
            }, (error) => {
                setIsFetching(false);
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(GET_EXPLANATION_ERROR));
                }
            });
    }

    function handleAnswerGeneration(index) {
        setAnswersLoading((prevSelectedButtons) => {
            const newAnswersLoading = [...prevSelectedButtons];
            newAnswersLoading[index] = true;
            return newAnswersLoading;
        });
        localLlmServiceApi.generateAnswers(mcq, selectedButtons[index])
            .then((response) => {
                setAnswersLoading((prevSelectedButtons) => {
                    const newAnswersLoading = [...prevSelectedButtons];
                    newAnswersLoading[index] = false;
                    return newAnswersLoading;
                });
                const content = response?.data;
                const newAnswers = [...mcq.answers];
                newAnswers[index].text = content;
                newAnswers[index].validAnswer = selectedButtons[index];
                setMcq({ ...mcq, answers: newAnswers });
            });
    }

    function getTooltipTitle() {
        if(formIsValid(mcq)) {
            return '';
        }
        return (
            <>
                <Typography>{displaySelectSkillTooltips(mcq.skillId)}</Typography>
                <Typography>{displayStatementTooltips(mcq.statement)}</Typography>
                <Typography>{displayValidAnswersTooltips(mcq)}</Typography>
                {mcq?.answers?.map((answer) => (
                    <Typography key={getAnswerKey(mcq.class, answer)}>{displayAnswserTooltips(answer, mcq.class)}</Typography>
                ))}
            </>);
    }

    const legendClass = classNames({
        'legendAnswers': true,
        'error': !atLeastOneMcqAnswerValid(mcq)
    });

    // Material UI style override
    const AnswerDeleteButton = withStyles({
        root: {
            backgroundColor: 'transparent',
            position: 'absolute',
            paddingTop: '0px',
            [theme.breakpoints.down('md')]: {
                right: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                right: '244px'
            },
            [theme.breakpoints.up('xl')]: {
                right: '502px'
            }
        }
    })(IconButton);

    const isNbAnswersDisplayedIncorrect = () => {
        const nbAnswersDisplayed = mcq.nbAnswersDisplayed;
        return nbAnswersDisplayed === undefined || nbAnswersDisplayed < 2 || nbAnswersDisplayed > mcq.answers.length;
    };

    function getLabelNbAnswersDisplayed() {
        let borne = '';
        if(mcq.answers.length !== 2) {
            borne = `(entre 2 et ${mcq.answers.length})`;
        }
        return `Nombre de réponses à afficher ${borne}`;
    }

    const HtmlTooltip = withStyles(() => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: '16px',
            border: '1px solid #dadde9'
        }
    }))(Tooltip);

    return (
        <>
            <QuestionEditor
                className="statementQuestionEditor"
                data={mcq.statement}
                onChange={(event, editor) => handleChangeEditor(editor)}
            />

            <TextField
                type="number"
                id="nbAnswersDisplayedTextField"
                name="Nombre de reponses à afficher"
                label={getLabelNbAnswersDisplayed()}
                aria-label={getLabelNbAnswersDisplayed()}
                value={mcq.nbAnswersDisplayed}
                InputProps={{ inputProps: { min: 2, max: mcq.answers.length } }}
                fullWidth
                error={isNbAnswersDisplayedIncorrect()}
                onChange={(event) => setMcq((prev) => ({
                    ...prev,
                    nbAnswersDisplayed: event.target.value
                }))}
                disabled={mcq.answers.length === 2}
            />

            <div className="MCQAnswerContainer">
                <p className={legendClass}>Bonne Réponse</p>
                {mcq.answers.map((answer, index) => {
                    const questionLabel = 'Réponse '.concat(answer.label);
                    if(!answer.key) {
                        answer.key = newGreatestKey(mcq);
                    }
                    return (
                        <div className="answerLine" key={index}>
                            <Checkbox
                                color="primary"
                                className="checkboxAnswer"
                                checked={answer.validAnswer}
                                onChange={() => handleAnswerValidityChange(answer, mcq, setMcq)}
                            />
                            <div className="text">
                                {answersLoading[index] && <LinearProgress />}
                                <TextField
                                    multiline fullWidth
                                    value={answer.text}
                                    helperText={
                                        <>
                                            255 caractères maximum <br />
                                            {displayAnswerHelperText(answer)}
                                        </>
                                    }
                                    label={questionLabel}
                                    variant="outlined"
                                    inputProps={{ maxLength: 255 }}
                                    onChange={(event) => handleAnswertextChange(event, answer)}
                                />
                                <AnswerDeleteButton
                                    className="deleteAnswer"
                                    disabled={mcq.answers.length <= 2}
                                    onClick={() => deleteAnswer(mcq, setMcq, answer)}
                                    aria-label="supprime la question"
                                    style={{ backgroundColor: 'transparent' }}
                                    disableRipple
                                    cy-data="create-question-delete-answer">
                                    <HighlightOffIcon />
                                </AnswerDeleteButton>
                            </div>
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <div className="valid" style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
                                    <Button
                                        variant={selectedButtons[index] ? 'contained' : 'text'}
                                        onClick={() => handleChangeGoodAnswer(index)}
                                    >
                                        <CheckCircleIcon color="primary"/>
                                    </Button>
                                    <Button
                                        variant={ selectedButtons[index] ? 'text' : 'contained'}
                                        onClick={() => handleChangeGoodAnswer(index)}
                                    >
                                        <CancelIcon color="primary" />
                                    </Button>
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!questionIsValid(mcq) || answersLoading[index]}
                                    onClick={() => handleAnswerGeneration(index)}
                                    style={{ height: '100%' }}
                                >
                                    Générer
                                </Button>
                            </div>
                        </div>
                    );
                })}
                {mcq.answers.length < 8 &&
                    <div className="addAnswerButtonContainer">
                        <IconButton
                            aria-label="ajoute une question"
                            disableRipple
                            onClick={() => addAnswer(mcq, setMcq, setAnswersLoading, setSelectedButtons)}
                            style={{ backgroundColor: 'transparent' }}
                            cy-data="create-question-add-answer">
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    </div>
                }
            </div>

            <QuestionEditor
                className="explanationEditor"
                data={mcq.explanation}
                onChange={(event, editor) => handleChangeExplanation(event, editor)}
                placeholder={placeholderConfigExplanation}
                disabled={isFetching}
            />

            <HtmlTooltip arrow title={getTooltipTitle()}>
                <span>
                    <Button
                        aria-label="chat gpt"
                        onClick={() => generateExplanation()}
                        disabled={!formIsValid(mcq) || isFetching}
                        className={classes.chatGptButton}
                        variant="contained"
                    >
                        Générer une explication
                    </Button>
                </span>
            </HtmlTooltip>
            { generatedExplanations.length === 0
                ? <>
                    { isFetching && <LinearProgress/> }
                </>
                : <>
                    <div>
                        G&eacute;n&eacute;ration
                        <IconButton
                            disabled={selectGeneration - 1 < 0}
                            onClick={() => setSelectGeneration(selectGeneration - 1)}
                        >
                            <ArrowBack />
                        </IconButton>
                        { selectGeneration + 1 } sur { generatedExplanations.length }
                        <IconButton
                            disabled={selectGeneration + 1 === generatedExplanations.length}
                            onClick={() => setSelectGeneration(selectGeneration + 1)}
                        >
                            <ArrowForward />
                        </IconButton>
                    </div>
                    { isFetching && <LinearProgress/> }
                    <div>
                        <TextareaAutosize
                            readOnly
                            style={{ resize: 'none', width: '100%' }}
                            value={generatedExplanations[selectGeneration]}
                        />
                    </div>
                </>
            }
        </>
    );
}

MultipleChoiceQuestionForm.propTypes = {
    mcq: PropTypes.object.isRequired,
    setMcq: PropTypes.func.isRequired,
    questionType: PropTypes.number
};
