import React from 'react';
import PropTypes from 'prop-types';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    buttonSize: {
        padding: 4
    }
}));

export default function SimplePagination({
    page,
    setPage,
    nbPages,
    nextPageMessage,
    previousPageMessage
}) {

    const classes = useStyles();

    const handlePrevious = () => {
        if(page > 1) {
            setPage((state) => state - 1);
        }
    };

    const handleNext = () => {
        if(page < nbPages) {
            setPage((state) => state + 1);
        }
    };

    return (
        <div>
            <Tooltip title={previousPageMessage}>
                <span>
                    <IconButton
                        className={classes.buttonSize}
                        aria-label={previousPageMessage}
                        disabled={page <= 1}
                        onClick={handlePrevious}
                    >
                        <NavigateBeforeIcon fontSize="medium" />
                    </IconButton>
                </span>
            </Tooltip>

            <span>{page}/{nbPages}</span>

            <Tooltip title={nextPageMessage}>
                <span>
                    <IconButton
                        className={classes.buttonSize}
                        aria-label={nextPageMessage}
                        disabled={page >= nbPages}
                        onClick={handleNext}
                    >
                        <NavigateNextIcon fontSize="medium" />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
}

SimplePagination.propTypes = {
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    nbPages: PropTypes.number.isRequired,
    nextPageMessage: PropTypes.string.isRequired,
    previousPageMessage: PropTypes.string.isRequired
};
