// From https://www.30secondsofcode.org/articles/s/js-data-structures-tree
import { Chapter } from 'app/redux/models/Chapter.model';
import TreeNode from './TreeNode';

export const treeChapterDefaultChapter: Chapter = {
    id: 0,
    name: '/',
    position: 0,
    parentPath: '',
    header: 'root',
    nbQuestionRequired: 0,
    skillId: 0,
    validationRate: 0
};

export default class TreeChapter {
    root: TreeNode;

    constructor(key: string) {
        this.root = new TreeNode(key, treeChapterDefaultChapter);
    }

    *preOrderTraversal(node = this.root): IterableIterator<TreeNode> {
        yield node;
        if(node.children.length) {
            for(const child of node.children) {
                yield* this.preOrderTraversal(child);
            }
        }
    }

    *postOrderTraversal(node = this.root): IterableIterator<TreeNode> {
        if(node.children.length) {
            for(const child of node.children) {
                yield* this.postOrderTraversal(child);
            }
        }
        yield node;
    }

    insert(parentNodeKey: string, key: string, content: Chapter) {
        for(const node of this.preOrderTraversal()) {
            if(node.key === parentNodeKey) {
                node.children.push(new TreeNode(key, content, node));
                return true;
            }
        }
        return false;
    }

    remove(key: string) {
        for(const node of this.preOrderTraversal()) {
            const filtered = node.children.filter((child) => child.key !== key);
            if(filtered.length !== node.children.length) {
                node.children = filtered;
                return true;
            }
        }
        return false;
    }

    find(key: string) {
        for(const node of this.preOrderTraversal()) {
            if(node.key === key) {
                return node;
            }
        }
        return undefined;
    }
}
