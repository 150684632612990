
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { getResultColor } from '../../utils';
import HistoryListQuestionGrouped from './HistoryListQuestionHistoryGrouped';

function HistoryRowGroupSerie(props) {

    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell
                    component={'th'}
                    scope="row"
                >
                    {props.row.name === null ? 'No chapters' : props.row.name}
                </TableCell>
                <TableCell align="center">{props.row.nbTotalQuestions}</TableCell>
                <TableCell align="center">
                    {
                        <div style={{ color: getResultColor((props.row.goodAnswers * 100) / props.row.nbTotalQuestions) }}>
                            {`${(props.row.goodAnswers * 100) / props.row.nbTotalQuestions}%`}
                        </div>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <HistoryListQuestionGrouped
                            serieHistoryId={props.serieHistoryId}
                            groupName={props.groupName}
                            id={props.row.id === null ? 0 : props.row.id}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

HistoryRowGroupSerie.propTypes = {
    serieHistoryId: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired
};

export default HistoryRowGroupSerie;
