import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './ParcoursDisplay.scss';
import chapitre from 'assets/images/chapitre.svg';
import question from 'assets/images/question.svg';
import serie from 'assets/images/serie.svg';
import sablier from 'assets/images/sablier.svg';
import valid from 'assets/images/valid.svg';
import cadenas from 'assets/images/cadenas.svg';
import { makeStyles } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import colors from 'styles/colors.module.scss';

export const useStyles = makeStyles(() => ({
    parcoursStepDrawerChat: {
        backgroundColor: colors['chat-back-drawer'],
        color: colors['chat-dark-blue'],
        fontFamily: 'Arial',
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '5px',
        padding: '5px'
    },
    parcoursStepContentDrawerChat: {
        color: colors['chat-dark-blue'],
        fontFamily: 'Arial',
        fontSize: '15px',
        margin: '5px',
        padding: '2px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    logoDrawerChat: {
        margin: '0 5px'
    }
}));

function ParcoursDisplay(props) {

    const [currentStepId, setCurrentStepId] = useState(0);
    const [currentStepContentId, setCurrentStepContentId] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        props.parcours.parcoursSteps.forEach((elem, index) => {
            if(elem.parcoursStepContents?.some(objet => objet?.id === props.currentStepContent?.stepContentId)) {
                setCurrentStepId(index);
                props.setExamMode(elem.examMode);
            }
            elem.parcoursStepContents.forEach((element, ind) => {
                if(element.id === props.currentStepContent?.stepContentId) {
                    setCurrentStepContentId(ind);
                }
                return 0;
            });
            return 0;
        });
    }, [props.currentStepContent]);


    return (
        <>
            {props.parcours.parcoursSteps.map((elem, index) => (
                <div key={index} className={classes.parcoursStepDrawerChat} style={{ borderLeft: index === currentStepId ? '8px solid white' : index < currentStepId ? '8px solid green' : '8px solid red' }}>
                    <div className="parcours-step">
                        {elem.name}
                        {elem.examMode &&
                            <SchoolIcon className="icon-exam-mode" />
                        }
                    </div>
                    {elem.parcoursStepContents?.some(objet => objet?.id === props.currentStepContent?.stepContentId) &&
                        elem.parcoursStepContents.map((element, ind) => (
                            <div 
                                key={ind} 
                                className={classes.parcoursStepContentDrawerChat} 
                                style={{ backgroundColor: (ind % 2 === 0) ? colors['chat-back-drawer-light'] : colors['chat-back-drawer'], border: (element.id === props.currentStepContent?.stepContentId) ? '1px solid black' : '' }}
                            >
                                <div>
                                    {element.type === 'QUESTION' && <img id="logo" src={question} className={classes.logoDrawerChat} />}
                                    {element.type === 'CHAPTER' && <img id="logo" src={chapitre} className={classes.logoDrawerChat} />}
                                    {element.type === 'SERIE' && <img id="logo" src={serie} className={classes.logoDrawerChat} />}
                                    {element.name}
                                </div>
                                {(element.id === props.currentStepContent?.stepContentId) && (props.currentStepContent?.content?.questionRemediationStatus) &&
                                    <div style={{ marginRight: ' 5px' }}>
                                        <img id="logo" src={sablier} />
                                    </div>
                                }
                                {(ind < currentStepContentId) && element.id !== props.currentStepContent?.stepContentId &&
                                    <img id="logo" src={valid} className={classes.logoDrawerChat} />
                                }
                                {(ind > currentStepContentId) && element.id !== props.currentStepContent?.stepContentId &&
                                    <img id="logo" src={cadenas} className={classes.logoDrawerChat} />
                                }
                            </div>
                        ))
                    }
                </div>)
            )
            }
        </>
    );
}

ParcoursDisplay.propTypes = {
    parcours: PropTypes.object.isRequired,
    currentStepContent: PropTypes.object,
    isExamMode: PropTypes.bool,
    setExamMode: PropTypes.func
};

export default ParcoursDisplay;
