import { notificationError, notificationSuccess, notificationWarning } from '../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    CREATE_CANDIDATE_ERROR,
    CREATE_CANDIDATE,
    CREATE_CANDIDATE_MAIL_ERROR,
    PATCH_CANDIDATE
} from '../../../Snackbar/NotificationMessages';
import {
    createCandidateApi,
    getAllCandidateApi,
    patchStatusApi
} from '../../../api/candidateApi';

export const CREATE_CANDIDATE_REQUEST = 'CREATE_CANDIDATE_REQUEST';
export const CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS';
export const CREATE_CANDIDATE_FAILURE = 'CREATE_CANDIDATE_FAILURE';

export const GET_CANDIDATES = 'GET_CANDIDATES';
export const GET_ALL_CANDIDATES_REQUEST = 'GET_ALL_CANDIDATES_REQUEST';
export const GET_ALL_CANDIDATES_SUCCESS = 'GET_ALL_CANDIDATES_SUCCESS';
export const GET_ALL_CANDIDATES_FAILURE = 'GET_ALL_CANDIDATES_FAILURE';

export const PATCH_STATUS_REQUEST = 'PATCH_STATUS_REQUEST';
export const PATCH_STATUS_SUCCESS = 'PATCH_STATUS_SUCCESS';
export const PATCH_STATUS_FAILURE = 'PATCH_STATUS_FAILURE';

export const createCandidate = (candidate) => (dispatch) => {
    dispatch({ type: CREATE_CANDIDATE_REQUEST });

    return createCandidateApi(candidate).then((response) => {
        dispatch({
            type: CREATE_CANDIDATE_SUCCESS,
            payload: response.data
        });
        if(response.data.status === 'ERROR') {
            dispatch(notificationError(CREATE_CANDIDATE_MAIL_ERROR));
        } else {
            dispatch(notificationSuccess(CREATE_CANDIDATE));
        }
    }, (error) => {
        dispatch({
            type: CREATE_CANDIDATE_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(CREATE_CANDIDATE_ERROR));
        }
    });
};

export const getAllCandidates = () => (dispatch) => {
    dispatch({ type: GET_ALL_CANDIDATES_REQUEST });

    return getAllCandidateApi().then((response) => {
        dispatch({
            type: GET_ALL_CANDIDATES_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_CANDIDATES_FAILURE,
            payload: error,
            error: true
        });
    });
};

export const patchStatus = (uuid, status) => (dispatch) => {
    dispatch({ type: PATCH_STATUS_REQUEST });

    return patchStatusApi(uuid, status).then((response) => {
        dispatch({
            type: PATCH_STATUS_SUCCESS,
            payload: {
                candidateUuid: uuid,
                candidateStatus: status
            }
        });
        if(response.data) {
            dispatch(notificationWarning(response.data));
        } else {
            dispatch(notificationSuccess(PATCH_CANDIDATE));
        }
    }, (error) => {
        dispatch({
            type: PATCH_STATUS_FAILURE,
            payload: error,
            error: true
        });
    });
};

