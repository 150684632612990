import { SET_QUESTIONS_CREATORS_FILTER, SET_QUESTIONS_SKILLS_FILTER, SET_QUESTIONS_CHAPTERS_FILTER,
    RESET_QUESTIONS_CHAPTERS_FILTER, SET_QUESTIONS_ID_FILTER, SET_QUESTIONS_AI_GENERATED_FILTER }
    from '../../../actions/Question/QuestionsFilters/questionsFilters.actions';

export const questionsSkillsFilterStore = 'skills';
export const questionsCreatorsFilterStore = 'creators';
export const questionsChaptersFilterStore = 'chaptors';
export const questionsIdFilterStore = 'id';
export const questionsAiGeneratedFilterStore = 'aiGenerated';

export default function questionsFiltersReducer(state = {
    [questionsIdFilterStore]: '',
    [questionsSkillsFilterStore]: [],
    [questionsCreatorsFilterStore]: [],
    [questionsChaptersFilterStore]: [],
    [questionsAiGeneratedFilterStore]: false
}, action) {
    switch (action.type) {
        case SET_QUESTIONS_SKILLS_FILTER:
            return { ...state,
                [questionsSkillsFilterStore]: action.payload };
        case SET_QUESTIONS_CREATORS_FILTER:
            return { ...state,
                [questionsCreatorsFilterStore]: action.payload };
        case SET_QUESTIONS_CHAPTERS_FILTER:
            return { ...state,
                [questionsChaptersFilterStore]: action.payload };
        case RESET_QUESTIONS_CHAPTERS_FILTER:
            return { ...state,
                [questionsChaptersFilterStore]: [] };
        case SET_QUESTIONS_ID_FILTER:
            return { ...state,
                [questionsIdFilterStore]: action.payload };
        case SET_QUESTIONS_AI_GENERATED_FILTER:
            return { ...state,
                [questionsAiGeneratedFilterStore]: action.payload };
        default:
            return state;
    }
}
