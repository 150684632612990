import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@material-ui/core';
import { Bookmark, BookmarkBorder } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { deleteBookmarkById, postBookmark, putBookmark } from 'app/redux/actions/Bookmark/bookmark.actions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

function BookmarkButton(props) {

    const dispatch = useDispatch();

    const userId = parseInt(AuthenticationContainer.getCurrentUserId(), 10);

    const handleClick = (event) => {
        event.preventDefault();
        if(props.isBookmarked) {
            dispatch(deleteBookmarkById(props.bookmark.id));
            return;
        }
        let newBookmark = {
            chapterId: props.chapterId,
            userId,
            skillId: props.skillId
        };
        if(props.bookmark) {
            newBookmark = { id: props.bookmark.id, ...newBookmark };
            dispatch(putBookmark({ ...newBookmark }));
        } else {
            dispatch(postBookmark(newBookmark));
        }
    };

    const tooltip = props.isBookmarked
        ? `Effacer le marque-page sur le chapitre ${props.chapterName}`
        : `Marquer le chapitre ${props.chapterName}`;

    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton
                    style={{ padding: 4 }}
                    onClick={handleClick}
                >
                    {props.isBookmarked ? <Bookmark style={{ color: 'gold' }}/> : <BookmarkBorder/>}
                </IconButton>
            </Tooltip>
        </>
    );
}

BookmarkButton.propTypes = {
    chapterName: PropTypes.string.isRequired,
    chapterId: PropTypes.number.isRequired,
    skillId: PropTypes.number.isRequired,
    isBookmarked: PropTypes.bool.isRequired,
    bookmark: PropTypes.object
};

export default BookmarkButton;
