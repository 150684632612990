import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { displaySerieDescriptionHelperText,
    displaySerieTitleHelperText,
    timerCantBeSetToZero } from '../../CreateEditSerie.functions';
import PropTypes from 'prop-types';
import { mapLocalTimeToDuration } from 'app/utils/date.functions';

export function CreateEditSerieFormHeader(props) {

    const withoutTimerHelperText = 'Pas de chronomètre. Entrez un temps pour configurer la durée de la série.';
    const withTimerHelperText = 'Série chronométrée. Videz le champ pour désactiver la durée de la série.';

    const [timerHelperText, setTimerHelperText] = useState('');

    const timerValidation = (timerValue) => {
        if(timerValue && timerValue !== '' && timerValue !== '00:00') {
            return mapLocalTimeToDuration(timerValue);
        }
        return null;
    };

    useEffect(() => {
        if(props.serie.maxDuration === '') {
            setTimerHelperText(withoutTimerHelperText);
        } else if(timerValidation(props.serie.maxDuration) === null) {
            setTimerHelperText(timerCantBeSetToZero);
        } else {
            setTimerHelperText(withTimerHelperText);
        }
    }, [props.serie.maxDuration]);

    return (
        <div className="createEditSerieFormHeader">
            <TextField
                data-testid="title-text-field"
                className="titleTextField"
                name="title"
                label="Titre"
                helperText={displaySerieTitleHelperText(props.serie.title)}
                required
                fullWidth
                value={props.serie.title}
                onChange={props.handleChange}
            />
            <TextField
                data-testid="description-text-field"
                className="descriptionTextField"
                name="description"
                label="Description"
                helperText={displaySerieDescriptionHelperText(props.serie.description)}
                variant="filled"
                margin="normal"
                required
                fullWidth
                multiline
                rows={4}
                value={props.serie.description}
                onChange={props.handleChange}
            />

            <TextField
                data-testid="timer-field"
                name="maxDuration"
                helperText={timerHelperText}
                type="time"
                margin="normal"
                required
                fullWidth
                value={props.serie.maxDuration}
                onChange={props.handleChange}
            />
        </div>
    );
}

CreateEditSerieFormHeader.propTypes = {
    handleChange: PropTypes.func.isRequired,
    serie: PropTypes.object.isRequired
};
