import React from 'react';
import PropTypes from 'prop-types';

import colors from 'styles/colors.module.scss';
import { useDrag } from 'react-dnd';
import { AssignTypes } from 'app/utils/functions';

import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { QuestionModeration } from 'app/question/QuestionModeration/QuestionModeration';
import { useSelector } from 'react-redux';
import { selectModerators } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';

const useStyles = makeStyles(() => ({
    item: {
        display: 'flex'
    },
    doneIconColor: {
        color: `${colors['green-color-700']}`
    },
    closeIconColor: {
        color: `${colors['red-color-400']}`
    },
    gradeLow: {
        color: `${colors['red-color-400']}`
    },
    gradeMedium: {
        color: `${colors['dark-orange']}`
    },
    gradeHigh: {
        color: `${colors['green-color-700']}`
    }
}));

export default function QuestionDisplayDraggable(props) {
    const moderators = useSelector(selectModerators);

    const classes = useStyles();

    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.QUESTION,
        item: {
            type: AssignTypes.QUESTION,
            id: props.question.id,
            question: props.question,
            name: `${props.skillName}#${props.question.id}`,
            chapterId: props.chapterId
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging())
        })

    }), [props.question.id]);

    const handleClickQuestion = () => {
        props.onClickQuestion(props.question.id);
    };

    const getQuestionIcon = () => {
        if(!props.question.result?.length) {
            return null;
        } else if(props.question.result === 'TRUE') {
            return <DoneIcon className={classes.doneIconColor} />;
        } else if(props.question.result === 'GRADED') {
            const grade = props.question.grade;
            let gradeClass = classes.gradeLow;

            if(grade >= 70) {
                gradeClass = classes.gradeHigh;
            } else if(grade >= 50) {
                gradeClass = classes.gradeMedium;
            } else {
                gradeClass = classes.gradeLow;
            }

            return <span className={gradeClass}>{`${grade}%`}</span>;
        }
        return <CloseIcon className={classes.closeIconColor} />;
    };

    return (
        <ListItem
            button
            disableRipple
            key={props.question.id}
            selected={props.selectedQuestion === props.question.id}
            ref={drag}
            onClick={handleClickQuestion}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            data-testid={`question-${props.question.id}`}
        >
            <ListItemIcon>
                {getQuestionIcon()}
            </ListItemIcon>
            <ListItemText primary={`${props.skillName}#${props.question.id}`}/>
            <ListItemSecondaryAction>
                <QuestionModeration
                    moderators={moderators ? moderators.get(props.question.id) : []}
                    questionId={props.question.id}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

QuestionDisplayDraggable.propTypes = {
    skillName: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    onClickQuestion: PropTypes.func.isRequired,
    selectedQuestion: PropTypes.number,
    chapterId: PropTypes.number.isRequired
};
