import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    overrides: {
        MuiCardContent: {
            root: {
                'paddingTop': '5px',
                'paddingRight': '10px',
                'paddingBottom': '5px',
                'paddingLeft': '10px',
                '&:last-child': {
                    paddingBottom: '10px'
                }
            }
        }
    }
});
