import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './QuestionHeader.scss';
import { format } from 'date-fns';
import { Avatar, IconButton, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import QuestionReportingButton from 'app/question/Buttons/QuestionReportingButton/QuestionReportingButton';
import { Link } from 'react-router-dom';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { QuestionModeration } from 'app/question/QuestionModeration/QuestionModeration';
import { selectModerators } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { isModeratorApi } from '../../../../../api/userSkillApi';
import flag from 'assets/images/angfr.jpg';
import { QuestionAskedEnum, QuestionEnum } from 'app/utils/QuestionEnum';

const getGrade = (result) => {
    if(result.type === 'TRUE') { return 100; }
    if(result.type === 'FALSE') { return 0; }
    return result.grade;
};

export function IconAnswer(props) {
    const formatDate = (dateStr) => format(new Date(dateStr), 'dd-MM-yyyy');
    const iconProps = {
        key: props.index,
        onClick: () => props.handleOnClickHistoryResult(props.index)
    };

    const grade = getGrade(props.result);

    const GreenIcon = () => <CheckCircleOutlineIcon {...iconProps} style={{ color: 'green' }}/>;
    const OrangeIcon = () => <HighlightOffIcon {...iconProps} style={{ color: 'orange' }}/>;
    const RedIcon = () => <HighlightOffIcon {...iconProps} style={{ color: 'red' }}/>;

    const GradeIcon = () => {
        if(grade >= 70) { return <GreenIcon/>; }
        if(grade >= 50) { return <OrangeIcon/>; }
        return <RedIcon/>;
    };

    return (
        <Tooltip title= {`Réponse du ${formatDate(props.date)}`} >
            <IconButton size="small">
                <GradeIcon/>
            </IconButton >
        </Tooltip>
    );
}
IconAnswer.propTypes = {
    index: PropTypes.number,
    date: PropTypes.string,
    result: PropTypes.string,

    handleOnClickHistoryResult: PropTypes.func

};

export function ChooseGoodIcon(props) {
    if(props.index === props.selectedAttemptIndex) {
        return (
            <Avatar>
                { props.children }
            </Avatar>
        );
    }
    return (props.children);
}
ChooseGoodIcon.propTypes = {
    index: PropTypes.number.isRequired,
    selectedAttemptIndex: PropTypes.number,
    children: PropTypes.node.isRequired
};

export default function QuestionHeader(props) {
    const [canEditOrDelete, setCanEditOrDelete] = useState(false);
    const currentUser = useSelector(selectCurrentUser);
    const moderatorMap = useSelector(selectModerators);

    const checkIsCreatorOrModeratorOrAdmin = (user, question) => {
        if(user.id === question.creator.id) {
            setCanEditOrDelete(true);
        } else {
            isModeratorApi(user.id, question.skill?.id || question.skillId).then((response) => {
                setCanEditOrDelete(response.data || user.roles?.some((role) => role.name === 'ADMIN'));
            });
        }
    };

    const handleTranslation = () => {
        props.translation((prevMode) => !prevMode);
    };

    useEffect(() => {
        if(currentUser.id) {
            checkIsCreatorOrModeratorOrAdmin(currentUser, props.question);
        }
    }, [currentUser]);

    const handleOnClickEdit = () => {
        props.handleOnEditQuestion(props.question.id);
    };
    const handleOnClickDelete = () => {
        props.handleOnDeleteQuestion(props.question.id);
    };

    const getGradeResult = (elem) => {
        if(elem.result === 'GRADED') {
            return {
                type: 'GRADED',
                grade: elem.grade
            };
        }
        return {
            type: elem.result
        };
    };


    return (
        <div className="header">
            <span className="left-part-header">
                <div className="question-info">
                    <strong>
                        {props.skillName}#{props.question.id}
                    </strong>
                </div>
                {[QuestionAskedEnum.MultipleChoice, QuestionEnum.MultipleChoice].includes(props.questionType) &&
                 (<Tooltip title={'Traduction : anglais-français'}>
                     <a href="#" onClick={handleTranslation} style={{ textDecoration: 'none' }}>
                         <img
                             src={flag}
                             alt="Traduction"
                             style={{
                                 height: '20%',
                                 width: '20%',
                                 cursor: 'pointer',
                                 marginLeft: '20px'
                             }}
                         />
                     </a>
                 </Tooltip>)}
                <div className="history-icons">
                    {props.questionHistory?.map((elem, index) => <ChooseGoodIcon
                        key={index}
                        index={index}
                        selectedAttemptIndex={props.selectedAttemptIndex}
                    >
                        <IconAnswer
                            result={getGradeResult(elem)}
                            date={elem.date}
                            index={index}
                            handleOnClickHistoryResult={props.handleOnClickHistoryResult}
                        />
                    </ChooseGoodIcon>)
                    }
                </div>
            </span>


            {props.nbQuestions > 1 &&
                    <span className="question-pagination">
                        {props.questionIndex}/{props.nbQuestions}
                    </span>
            }

            <span className="right-part-header">

                {!props.isCandidateSerie &&
                    <Tooltip title={`Langue : ${props.language?.name}`}>
                        <img
                            className="flag-language"
                            alt={props.language?.acronym}
                            src={props.language?.linkPictureFlag}
                            height= "30"
                            width= "40"
                        />
                    </Tooltip>
                }

                {props.nbQuestions &&
                    <IconButton onClick={props.onQuestionMark}>
                        {props.isQuestionMark
                            ? <BookmarkIcon color="primary" />
                            : <BookmarkBorderOutlinedIcon />
                        }
                    </IconButton>
                }

                {!props.isCandidateSerie &&
                    <QuestionModeration
                        questionId={props.question.id}
                        moderators={moderatorMap ? moderatorMap.get(props.question.id) : []}
                    />
                }

                {!props.isCandidateSerie && canEditOrDelete && (
                    props.isOnChapterContent
                        ? <IconButton onClick={handleOnClickEdit} >
                            <EditIcon />
                        </IconButton>
                        : <Link to={`${QUESTIONS_PAGE_URL}/edit?id=${props.question.id}`}>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Link>
                )
                }

                {!props.isCandidateSerie && canEditOrDelete &&
                        <IconButton onClick={handleOnClickDelete} >
                            <DeleteIcon/>
                        </IconButton>
                }

                {!props.isCandidateSerie &&
                    <QuestionReportingButton
                        questionId={props.question.id}
                    />
                }
            </span>

        </div>
    );
}

QuestionHeader.propTypes = {
    question: PropTypes.object.isRequired,
    questionHistory: PropTypes.array,
    selectedAttemptIndex: PropTypes.number,
    questionType: PropTypes.string,

    skillName: PropTypes.string,
    moderators: PropTypes.array,
    userId: PropTypes.string.isRequired,

    isCandidateSerie: PropTypes.bool,
    questionIndex: PropTypes.number,
    nbQuestions: PropTypes.number,
    isOnChapterContent: PropTypes.bool,
    isQuestionMark: PropTypes.bool,

    handleOnEditQuestion: PropTypes.func,
    handleOnDeleteQuestion: PropTypes.func,
    handleOnClickHistoryResult: PropTypes.func,
    onQuestionMark: PropTypes.func,
    language: PropTypes.object.isRequired,
    translation: PropTypes.func.isRequired
};
