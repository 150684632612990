import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './TableStats.scss';

import UserChapterDialog from './UserChapterDialog/UserChapterDialog';
import { oxyliensAndGroupsEnum } from 'app/coaching/GroupAndStudentSelector/GroupAndStudentSelector';

import SettingsIcon from '@material-ui/icons/Settings';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import Paper from '@material-ui/core/Paper';
import { Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroupStudentsMap } from 'app/redux/selectors/Group/group.selector';
import { getAllStudentsOfGroupByGroupId } from 'app/redux/actions/Group/group.actions';
import { selectChapterStats } from 'app/redux/selectors/ChapterStats/chapterStats.selector';
import { getChaptersStats } from 'app/redux/actions/ChapterStats/chapterStats.actions';

const useStyles = makeStyles(() => ({
    statisticsCellRoot: {
        color: 'white',
        border: '1px solid white'
    },
    separator: {
        backgroundColor: 'black',
        height: '2px'
    },
    tableContainerRoot: {
        overflowX: 'auto',
        maxHeight: '90%'
    },
    tableRoot: {
        tableLayout: 'fixed',
        overflowX: 'auto'
    },
    chapterColumnRoot: {
        backgroundColor: '#3F51B5',
        color: 'white'
    },
    groupColumnRoot: {
    },
    groupCellRoot: {
        textAlign: 'center',
        fontSize: '1.1rem'
    },
    studentCellRoot: {
    }
}));

export const STUDENT_COLUMN_TITLE = 'Eleves';
export const GROUP_COLUMN_TITLE = 'Groupe';

/**
 * Tableau calculant les statistiques d'une liste de groupe d'élèves sur un ensemble de chapitres.
 * @param {*} props : contient notamment la liste des chapitres et des groupes d'élèves.
 * @returns         : le tableau.
 */
function TableStats() {

    /*
     * Ces pourcentages fonctionnent comme des coefficients. C'est la seule façon que j'ai trouvé de fixer la taille des colonnes
     * du tableau.
     */
    const GROUP_COLUMN_WIDTH_PERCENTAGE = '2%';
    const STUDENT_COLUMN_WIDTH_PERCENTAGE = '3%';
    const CHAPTERS_COLUMNS_WIDTH_PERCENTAGE = '1%';

    const [dialogOpen, isDialogOpen] = useState(false);
    const [selectedChapters, setSelectedChapters] = React.useState([]);
    const [usersAndGroupsSelected, setUsersAndGroupsSelected] = useState([]);

    const classes = useStyles();
    const dispatch = useDispatch();

    const groupStudentMap = useSelector(selectGroupStudentsMap);
    const userStats = useSelector(selectChapterStats);

    const extractAllUsers = (usersAndGroups) => {
        const allUsers = new Set();
        for(const userOrGroup of usersAndGroups) {
            if(userOrGroup.type === oxyliensAndGroupsEnum.OXYLIENS) {
                allUsers.add(userOrGroup);
            } else if(userOrGroup.id in groupStudentMap) {
                for(const user of groupStudentMap[userOrGroup.id]) {
                    allUsers.add(user);
                }
            }
        }
        return [...allUsers];
    };

    useEffect(() => {
        if(usersAndGroupsSelected.length && selectedChapters.length) {
            const users = extractAllUsers(usersAndGroupsSelected).map((user) => user.id);
            dispatch(getChaptersStats(users, selectedChapters.map((chapter) => chapter.id)));
        }
    }, [usersAndGroupsSelected, selectedChapters, groupStudentMap]);


    const onClose = () => {
        isDialogOpen(false);
    };

    const handleValidationUsersGroupsChapters = (usersAndGroups, chapters) => {
        for(const userOrGroup of usersAndGroups) {
            if(userOrGroup.type === oxyliensAndGroupsEnum.GROUPS) {
                dispatch(getAllStudentsOfGroupByGroupId(userOrGroup.id));
            }
        }
        setUsersAndGroupsSelected(usersAndGroups);
        setSelectedChapters(chapters);
        onClose();
    };
    return (
        <>
            <TableContainer
                component={Paper}
                classes={{ root: classes.tableContainerRoot }}
            >
                <Table
                    aria-label="simple table"
                    classes={{ root: classes.tableRoot }}
                >
                    <colgroup>
                        <col style={{ width: GROUP_COLUMN_WIDTH_PERCENTAGE }} />
                        <col style={{ width: STUDENT_COLUMN_WIDTH_PERCENTAGE }} />
                        {selectedChapters.map((chapter) => (
                            <col key={chapter.id} style={{ width: CHAPTERS_COLUMNS_WIDTH_PERCENTAGE }} />
                        ))}
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                classes={{ root: classes.groupColumnRoot }}
                            >
                                {GROUP_COLUMN_TITLE}
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.studentColumnRoot }}
                            >
                                <div className="studentColumnRoot">
                                    <p>{STUDENT_COLUMN_TITLE}</p>
                                    <IconButton aria-label="Réglages" onClick={() => isDialogOpen(true)}>
                                        <SettingsIcon style={{ color: 'blue' }}/>
                                    </IconButton>
                                </div>
                            </TableCell>
                            {selectedChapters.map((chapter) => (
                                <TableCell
                                    key={chapter.id}
                                    classes={{ root: classes.chapterColumnRoot }}
                                    align="center"
                                >
                                    {chapter.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersAndGroupsSelected.map((userOrGroup) => (
                            <React.Fragment
                                key={`G${userOrGroup.id}`}
                            >
                                { userOrGroup.type === oxyliensAndGroupsEnum.GROUPS
                                    ? <>
                                        { groupStudentMap[userOrGroup.id] && groupStudentMap[userOrGroup.id].map((student, index) => (
                                            <TableRow key={`G${userOrGroup.id}E${student.id}`}>
                                                { index === 0 &&
                                                    <TableCell
                                                        key={student.id}
                                                        component="th"
                                                        rowSpan={groupStudentMap[userOrGroup.id].length}
                                                        classes={{ root: classes.groupCellRoot }}
                                                    >
                                                        {userOrGroup.name}
                                                    </TableCell>
                                                }
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: classes.studentCellRoot }}
                                                >
                                                    {`${student.firstName} ${student.lastName}`}
                                                </TableCell>
                                                { userStats?.get(student.id) && selectedChapters.map((chapter) => {
                                                    if(userStats.get(student.id).get(chapter.id)) {
                                                        return <StatisticCell
                                                            key={chapter.id}
                                                            chapterName={chapter.name}
                                                            student={student}
                                                            statistics={userStats.get(student.id).get(chapter.id)}
                                                        />;
                                                    }
                                                    return <></>;
                                                })}
                                            </TableRow>
                                        ))}
                                    </>
                                    : <>
                                        { userStats?.get(userOrGroup.id) &&
                                        <TableRow
                                            key={userOrGroup.id}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: classes.studentCellRoot }}
                                            >
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: classes.studentCellRoot }}
                                            >
                                                {`${userOrGroup.firstName} ${userOrGroup.lastName}`}
                                            </TableCell>
                                            {selectedChapters.map((chapter) => (
                                                userStats.get(userOrGroup.id).get(chapter.id)
                                                    ? <StatisticCell
                                                        key={chapter.id}
                                                        chapterName={chapter.name}
                                                        student={userOrGroup}
                                                        statistics={userStats.get(userOrGroup.id).get(chapter.id)}
                                                    />
                                                    : null
                                            ))
                                            }
                                        </TableRow>
                                        }
                                    </>
                                }

                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <UserChapterDialog
                open={dialogOpen}
                onClose={onClose}
                handleValidationUsersGroupsChapters={handleValidationUsersGroupsChapters}
            />
        </>
    );
}

function StatisticCell({ chapterName, student, statistics }) {
    const DELAY_ON_HOVER = 750;
    const VALUE_COLOR = 'white';
    const classes = useStyles();
    const pourcentage = statistics.questionsAnswered === 0 ? 0 : 100 * statistics.corrects / statistics.questionsAnswered;
    const statisticsToPrint = {
        'Questions correctes': statistics.corrects,
        'Questions totales distinctes réalisées': statistics.questionsAnswered,
        'Questions distinctes nécessaires pour valider le chapitre': statistics.nbQuestionRequired,
        'Seuil de validation du chapitre': statistics.validationRate
    };
    const style = {
        pourcentage: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        boldText: {
            fontWeight: 'bold'
        }
    };

    const chooseColor = (stats) => {
        if(stats.questionsAnswered === 0 || stats.questionsAnswered < stats.nbQuestionRequired) {
            return 'gray';
        } else if(!stats.validated) {
            return 'red';
        }
        return 'green';
    };

    const infobulleContent = () => (
        <>
            <Typography
                variant="h5"
                style={style.boldText}
            >
                {student.firstName} {student.lastName}
            </Typography>

            <Typography variant="h5">
                Chapitre : {chapterName}
            </Typography>

            {Object.keys(statisticsToPrint).map((field) => (
                <React.Fragment
                    key={field}
                >
                    <Typography
                        variant="body1"
                        style={{
                            color: VALUE_COLOR
                        }}
                    >
                        {field} :
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{
                            color: VALUE_COLOR,
                            fontWeight: 'bold'
                        }}
                    >
                        {statisticsToPrint[field]}
                    </Typography>
                </React.Fragment>
            ))}

            <Typography
                variant="body1"
                style={style.pourcentage}
            >
                Pourcentage : {Math.round(pourcentage)}%
            </Typography>
        </>
    );

    return (
        <Tooltip
            enterDelay={DELAY_ON_HOVER}
            enterNextDelay={DELAY_ON_HOVER}
            title={infobulleContent()}
        >
            <TableCell
                style={{ backgroundColor: chooseColor(statistics) }}
                classes={{
                    root: classes.statisticsCellRoot
                }}
                data-testid={`${student.firstName} ${student.lastName} ${chapterName}`}
            >
            </TableCell>
        </Tooltip>
    );
}

StatisticCell.propTypes = {
    chapterName: PropTypes.string.isRequired,
    student: PropTypes.object.isRequired,
    statistics: PropTypes.object.isRequired
};

export default TableStats;
