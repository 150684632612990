import { GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS } from 'app/redux/actions/Question/History/history.actions';
import { QUESTION_SELECTED, QUESTION_SELECTED_HISTORIES, QUESTION_SELECTED_HISTORY, RESET_QUESTION_SELECTED, TAB_INDEX } from '../../actions/Question/question.actions';

export const tabIndex = 'tabIndex';
export const questionSelectedByStudent = 'questionSelectedByStudent';
export const questionSelectedHistory = 'questionSelectedHistory';
export const questionSelectedHistories = 'questionSelectedHistories';

export default function questionReducer(state = {
    [tabIndex]: 0,
    [questionSelectedByStudent]: null,
    [questionSelectedHistory]: null,
    [questionSelectedHistories]: []
}, action) {
    switch (action.type) {
        case TAB_INDEX:
            return {
                ...state,
                [tabIndex]: action.payload
            };
        case QUESTION_SELECTED:
            return {
                ...state,
                [questionSelectedByStudent]: action.payload
            };
        case QUESTION_SELECTED_HISTORY:
            return {
                ...state,                    
                [questionSelectedHistory]: action.payload
            };
        case QUESTION_SELECTED_HISTORIES:
        case GET_QUESTION_HISTORY_BY_QUESTION_ID_AND_USER_ID_SUCCESS:
            return {
                ...state,
                [questionSelectedHistories]: action.payload
            };
        case RESET_QUESTION_SELECTED:
            return {
                ...state,
                [questionSelectedByStudent]: null,
                [questionSelectedHistory]: null,
                [questionSelectedHistories]: []
            };
        default:
            return state;
    }
}
