import {
    EXPIRED_SESSION,
    PATCH_TASK_BY_ID_ERROR,
    PATCH_TASK_BY_ID,
    GET_TASKS_BY_STUDENT_ID_ERROR,
    GET_TASKS_BY_GROUP_ID_ERROR
} from 'app/Snackbar/NotificationMessages';
import {
    getTaskByStudentIdApi,
    getDetailedTasksByGroupIdApi,
    patchStudentTaskApi,
    patchTaskApi
} from 'app/api/taskApi';
import { notificationError, notificationSuccess } from 'app/redux/actions/Notification/notifications.actions';
import { getTaskByGroupIdApi } from '../../../../api/taskApi';


export const GET_TASKS_BY_STUDENT_ID_REQUEST = 'GET_TASKS_BY_STUDENT_ID_REQUEST';
export const GET_TASKS_BY_STUDENT_ID_SUCCESS = 'GET_TASKS_BY_STUDENT_ID_SUCCESS';
export const GET_TASKS_BY_STUDENT_ID_FAILURE = 'GET_TASKS_BY_STUDENT_ID_FAILURE';

export const GET_TASKS_BY_GROUP_ID_REQUEST = 'GET_TASKS_BY_GROUP_ID_REQUEST';
export const GET_TASKS_BY_GROUP_ID_SUCCESS = 'GET_TASKS_BY_GROUP_ID_SUCCESS';
export const GET_TASKS_BY_GROUP_ID_FAILURE = 'GET_TASKS_BY_GROUP_ID_FAILURE';

export const GET_ALL_TASKS_BY_GROUP_ID_REQUEST = 'GET_ALL_TASKS_BY_GROUP_ID_REQUEST';
export const GET_ALL_TASKS_BY_GROUP_ID_SUCCESS = 'GET_ALL_TASKS_BY_GROUP_ID_SUCCESS';
export const GET_ALL_TASKS_BY_GROUP_ID_FAILURE = 'GET_ALL_TASKS_BY_GROUP_ID_FAILURE';

export const GET_TASKS_DETAILS_BY_GROUP_ID_REQUEST = 'GET_TASKS_DETAILS_BY_GROUP_ID_REQUEST';
export const GET_TASKS_DETAILS_BY_GROUP_ID_SUCCESS = 'GET_TASKS_DETAILS_BY_GROUP_ID_SUCCESS';
export const GET_TASKS_DETAILS_BY_GROUP_ID_FAILURE = 'GET_TASKS_DETAILS_BY_GROUP_ID_FAILURE';

export const PATCH_TASK_REQUEST = 'PATCH_TASK_REQUEST';
export const PATCH_TASK_SUCCESS = 'PATCH_TASK_SUCCESS';
export const PATCH_TASK_FAILURE = 'PATCH_TASK_FAILURE';

export const getTaskByStudentId = (studentId) => (dispatch) => {
    dispatch({ type: GET_TASKS_BY_STUDENT_ID_REQUEST });

    return getTaskByStudentIdApi(studentId).then((response) => {
        dispatch({
            type: GET_TASKS_BY_STUDENT_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_TASKS_BY_STUDENT_ID_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_TASKS_BY_STUDENT_ID_ERROR));
        }
    });
};

export const getTasksByGroupId = (groupId, getTreated) => (dispatch) => {
    dispatch({ type: GET_TASKS_BY_GROUP_ID_REQUEST });
    return getTaskByGroupIdApi(groupId, getTreated).then((response) => {
        dispatch({
            type: GET_TASKS_BY_GROUP_ID_SUCCESS,
            payload: {
                data: response.data,
                treated: getTreated
            }
        });
    }, (error) => {
        dispatch({
            type: GET_TASKS_BY_GROUP_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_TASKS_BY_GROUP_ID_ERROR));
        }
    });
};

export const getTasksDetailsByGroupId = (groupId) => (dispatch) => {
    dispatch({ type: GET_TASKS_DETAILS_BY_GROUP_ID_REQUEST });
    return getDetailedTasksByGroupIdApi(groupId).then((response) => {
        dispatch({
            type: GET_TASKS_DETAILS_BY_GROUP_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_TASKS_DETAILS_BY_GROUP_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_TASKS_BY_GROUP_ID_ERROR));
        }
    });
};

export const patchTask = (taskId) => (dispatch) => {
    dispatch({ type: PATCH_TASK_REQUEST });

    return patchTaskApi(taskId).then(() => {
        dispatch({ type: PATCH_TASK_SUCCESS });
        dispatch(notificationSuccess(PATCH_TASK_BY_ID));
    }, (error) => {
        dispatch({
            type: PATCH_TASK_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(PATCH_TASK_BY_ID_ERROR));
        }
    });
};

export const patchStudentTask = (taskId, studentId) => (dispatch) => {
    dispatch({ type: PATCH_TASK_REQUEST });

    return patchStudentTaskApi(taskId, studentId).then(() => {
        dispatch({
            type: PATCH_TASK_SUCCESS,
            payload: taskId
        });
        dispatch(notificationSuccess(PATCH_TASK_BY_ID));
    }, (error) => {
        dispatch({
            type: PATCH_TASK_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(PATCH_TASK_BY_ID_ERROR));
        }
    });
};

