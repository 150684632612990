import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogActions, DialogContent, TextField, Button as ButtonMaterial, DialogTitle, Grid } from '@material-ui/core';

export default function DialogQuizInteractifMenuSmallScreen(props) {
    return (
        <Dialog
            open={props.isOpenDialog}
            onClose={props.closeDialog}
            fullWidth
        >
            <DialogTitle className="dialogQuizInteractifTitle">
                <Grid container alignItems="center" justifyContent="center">
                    <Grid container item xs={1} sm={2} justifyContent="flex-end">🏆</Grid>
                    <Grid container item xs={10} sm={8} justifyContent="center">
                                    Rejoindre un salon de quiz interactif
                    </Grid>
                    <Grid container item xs={1} sm={2} justifyContent="flex-start">🏆</Grid>
                </Grid>
            </DialogTitle>
            <form onSubmit={props.handleValidation}>
                <DialogContent dividers id="addToGroupDialog">
                    <TextField
                        label="Code du Quiz"
                        autoFocus
                        fullWidth
                        value={props.enteredCode}
                        onChange={props.handleCodeChange}
                    />
                    {props.errorMessage && <p>{ `${props.errorMessage}` }</p>}
                </DialogContent>
                <DialogActions>
                    <ButtonMaterial
                        color="primary"
                        type = "submit"
                    >
                                    Valider
                    </ButtonMaterial>
                </DialogActions>
            </form>
        </Dialog>
    );

}

DialogQuizInteractifMenuSmallScreen.propTypes = {
    isOpenDialog: PropTypes.bool,
    enteredCode: PropTypes.string,
    errorMessage: PropTypes.string,
    handleCodeChange: PropTypes.func,
    handleValidation: PropTypes.func,
    closeDialog: PropTypes.func
};
