import axiosClient from './utils/axiosClient';

export const SERIES_URI = 'series';

export function getSeriesByCriteriaApi(criteria) {
    let criteriaUrl = '';
    if(criteria.offset !== null && criteria.step !== null) {
        criteriaUrl += `offset=${criteria.offset}&step=${criteria.step}`;
    }
    if(criteria.sort !== null && criteria.order !== null) {
        if(criteriaUrl.length > 0) {
            criteriaUrl += '&';
        }
        criteriaUrl += `sortColumn=${criteria.sort}&order=${criteria.order}`;
    }

    return axiosClient.get(`${SERIES_URI}/paginated?${criteriaUrl}`);
}

export function getSerieBySerieIdApi(serieParameter, candidateUuid) {
    if(candidateUuid) {
        return axiosClient.get(`${SERIES_URI}/candidate/${candidateUuid}`);
    }
    return axiosClient.get(`${SERIES_URI}/asked/${serieParameter}`);
}

export function getRandomSerieByChapterIdApi(chapterId, params) {
    return axiosClient.get(`${SERIES_URI}/asked/random/chapter/${chapterId}`, { params });
}

export function getSpacedRepetitionSerieByUserIdApi(userId) {
    return axiosClient.get(`${SERIES_URI}/asked/spacedRepetition/${userId}`);
}

export function getSerieBySerieHistoryIdApi(serieHistoryId, userId, candidateUuid) {
    if(candidateUuid) {
        return axiosClient.get(`${SERIES_URI}/candidate/pending/${candidateUuid}`);
    }
    return axiosClient.get(`${SERIES_URI}/pending/${serieHistoryId}/${userId}`);
}

export function getSerieByIdWithQuestionReducedApi(serieId) {
    return axiosClient.get(`${SERIES_URI}/reduced/${serieId}`);
}

export function getSeriesByChapterIdApi(chapterId) {
    return axiosClient.get(`${SERIES_URI}/chapter/${chapterId}`);
}
