export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_FAMILY_FILTER = 'SET_FAMILY_FILTER';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';

export function setSearchFilter(filter) {
    return {
        type: SET_SEARCH_FILTER,
        payload: filter
    };
}

export function setFamilyFilter(filter) {
    return {
        type: SET_FAMILY_FILTER,
        payload: filter
    };
}

export function setCategoryFilter(filter) {
    return {
        type: SET_CATEGORY_FILTER,
        payload: filter
    };
}

