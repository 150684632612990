/* eslint-disable max-lines */
/* eslint-disable testing-library/await-async-query */
import React, { MouseEvent, useEffect, useState } from 'react';
import './ChapterTreeDisplay.scss';

import { createChapterTree, findByChapterId } from 'app/utils/treeStructure/TreeChapter.logic';

import { TreeView } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useDispatch, useSelector } from 'react-redux';
import { selectChapterValidations } from 'app/redux/selectors/ChapterValidation/chapterValidation.selector';
import {
    deleteChapter,
    pasteChapter,
    setSelectedChapterId
} from 'app/redux/actions/Chapter/chapter.actions';

import { ContextMenuActionEnum } from './ContextMenu/ContextMenu.constants';

import { ContextMenu } from './ContextMenu/ContextMenu';
import { ChapterTreeItem } from './ChapterTreeItem';
import SimpleDialog from 'app/components/Dialog/SimpleDialog';
import { CHAPTER_RESOLVE_URL } from 'app/Routes';
import { useHistory } from 'react-router-dom';
import PlayChapterQuestionsRequest from '../PlayChapterQuestions/PlayChapterQuestionsRequest';
import { getQuestionsDoneAndGoodAnswers } from 'app/redux/actions/ChapterStats/chapterStats.actions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { setExamMode } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';

import { addRoom } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';
import { v4 as uuidv4 } from 'uuid';
import { mapLocalTimeToDuration } from 'app/utils/date.functions';
import { SkillReduced } from 'app/redux/models/Skill.model';
import { StateWithLoading } from 'app/redux/redux.types';
import { Chapter } from 'app/redux/models/Chapter.model';
import TreeChapter from 'app/utils/treeStructure/TreeChapter';
import TreeNode from 'app/utils/treeStructure/TreeNode';
import { ContextMenuAnchor } from './ContextMenu/ContextMenu.types';
import { selectChapterQuestionFilter, selectQuestionCountByChapter } from 'app/redux/selectors/Chapter/chapter.selector';
import { selectQuestionSelectedByStudent } from 'app/redux/selectors/Question/question.selector';

export const NO_CHAPTERS_FOUND = 'Aucun chapitre trouvé';

interface ChapterTreeDisplayProps {
    skill: SkillReduced;
    permission: boolean;
    userId: number;
    chapters: StateWithLoading<Chapter[]>;
    expanded: string[];
    setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
    onNodeSelect?: any;
    onClickQuestion?: any;
    openDialog?: any;
    handleCreateQuestion?: any;
    selectedGroup?: any;
    bookmark?: any;
    isInDrawer?: boolean;
    selectedUserId?: number;
}

interface ListIndex {
    id: number;
    idx: number
}

export const ChapterTreeDisplay = (props: ChapterTreeDisplayProps) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { data: chapters, loading: isFetching } = props.chapters;
    const chaptersValidation = useSelector(selectChapterValidations);
    const questionCountByChapter = useSelector(selectQuestionCountByChapter);
    const selectedQuestion = useSelector(selectQuestionSelectedByStudent)?.questionId ?? null;
    const filter = useSelector(selectChapterQuestionFilter);

    const [chapterTree, setChapterTree] = useState<TreeChapter | null>(null);
    const [contextMenu, setContextMenu] = useState<ContextMenuAnchor | null>(null);
    const [chapterToCopy, setChapterToCopy] = useState<Chapter | null>(null);
    const [disableDeleteChapter, setDisableDeleteChapter] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [chapterSelected, setChapterSelected] = useState<Chapter>();

    const [nbQuestions, setNbQuestions] = useState('0');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [withChildren, setWithChildren] = useState(true);
    const [isRandomSerie, setIsRandomSerie] = useState(false);
    const [language, setLanguage] = useState('zza');
    const [isExamMode, setIsExamMode] = useState(false);
    const [maxDuration, setMaxDuration] = useState('--:--');

    const [statsLoaded, setStatsLoaded] = useState(false);
    const [oldStateNodeIdsStats, setOldStateNodeIdsStats] = useState(0);

    function getParentIds(el: any): ListIndex[] {
        if(!el?.parent) {
            return [];
        }
        return [
            ...getParentIds(el.parent),
            {
                id: el.content.id,
                idx: el.parent.children.findIndex((elt: any) => el.content.id === elt.content.id)
            }
        ];
    }

    function compareListIndex(l1: ListIndex[], l2: ListIndex[]) {
        const len1 = l1.length;
        const len2 = l2.length;

        for(let index = 0; index < Math.min(len1, len2); index++) {
            if(l1[index].idx !== l2[index].idx) {
                return l1[index].idx - l2[index].idx;
            }
        }

        return len1 - len2;
    }

    useEffect(() => {
        setChapterTree(createChapterTree(chapters));
    }, [chapters]);

    useEffect(() => {
        const questionCount = chapterSelected && questionCountByChapter.data.get(chapterSelected?.id);
        setDisableDeleteChapter(Boolean(questionCount));
    }, [chapterSelected, questionCountByChapter]);

    useEffect(() => {
        if(!chapterTree || statsLoaded || props.isInDrawer) {
            return;
        }
        const rootChapterIds = chapterTree.root.children.map((chapter) => chapter.content.id);
        if(!props.isInDrawer && rootChapterIds.length) {
            dispatch(getQuestionsDoneAndGoodAnswers(rootChapterIds, props.selectedUserId ?? props.userId, filter));
            setStatsLoaded(true);
        }

        if(props.expanded.length === 0) {
            const savedChapter = Number(localStorage.getItem(`bookmark-${props.skill.id}`)) || undefined;
            const bookmarkIds = props.bookmark && props.bookmark.skillId === props.skill.id
                ? getParentIds(findByChapterId(chapterTree, props.bookmark.chapterId))
                : [];
            const savedIds = savedChapter
                ? getParentIds(findByChapterId(chapterTree, savedChapter))
                : [];

            if(props.bookmark && props.bookmark.skillId === props.skill.id &&
                compareListIndex(bookmarkIds, savedIds) >= 0) {
                dispatch(setSelectedChapterId({ chapterId: props.bookmark.chapterId, skillId: props.skill.id }));
                props.setExpanded(bookmarkIds.map((el) => String(el.id)));
            } else if(savedChapter) {
                dispatch(setSelectedChapterId({ chapterId: savedChapter, skillId: props.skill.id }));
                props.setExpanded(savedIds.map((el) => String(el.id)));
            }
        }
    }, [chapterTree]);

    useEffect(() => {
        if(!props.isInDrawer && !isFetching) {
            const expandedChapters = chapters.filter((chapterToFind) => props.expanded.includes(`${chapterToFind.id}`));
            const visibleChapters = chapters.filter((chapter) => chapter.parentPath === '/' ||
                expandedChapters.some((expChapter) => chapter.parentPath === `${expChapter.parentPath}${expChapter.name.toLowerCase()}/`));
            if(visibleChapters.length) {
                dispatch(getQuestionsDoneAndGoodAnswers(visibleChapters.map((chapter) => chapter.id), props.selectedUserId ?? props.userId, filter));
            }
        }
    }, [isFetching, props.selectedUserId]);

    const handleToggle = (_event: any, nodeIds: string[]) => {
        props.setExpanded(nodeIds);
        if(!props.isInDrawer && oldStateNodeIdsStats < nodeIds.length &&
            !nodeIds[0].startsWith('Questions') &&
            !nodeIds[0].startsWith('Series')) {
            const currentChapter = chapters.find((chapterToFind) => chapterToFind.id === parseInt(nodeIds[0], 10));
            const chapterChildren = currentChapter && chapters
                .filter((chapter) => chapter.parentPath === `${currentChapter.parentPath}${currentChapter.name.toLowerCase()}/`);
            if(chapterChildren?.length) {
                dispatch(getQuestionsDoneAndGoodAnswers(chapterChildren.map((chapter) => chapter.id), props.selectedUserId ?? props.userId, filter));
            }
        }
        setOldStateNodeIdsStats(nodeIds.length);
    };

    const handleContextMenu = (event: MouseEvent<Element>, node: TreeNode) => {
        event.stopPropagation();
        setChapterSelected(node.content);
        let newContextMenu: ContextMenuAnchor | null = null;
        if(event.type === 'click') {
            newContextMenu = {
                el: event.currentTarget,
                reference: 'anchorEl'
            };
        } else {
            newContextMenu = {
                position: {
                    left: event.clientX,
                    top: event.clientY
                },
                reference: 'anchorPosition'
            };
        }
        setContextMenu(newContextMenu);
    };

    const handleCloseMenu = () => {
        setContextMenu(null);
    };

    const resetChapterToCopy = () => {
        setChapterToCopy(null);
    };

    const pasteInChapter = () => {
        if(chapterSelected) {
            const updatedChapter = {
                ...chapterToCopy,
                parentPath: `${chapterSelected.parentPath}${chapterSelected.name.toLowerCase()}/`,
                position: 1
            };
            dispatch(pasteChapter(updatedChapter));
        }
    };

    const pasteNextToChapter = () => {
        if(chapterSelected) {
            const updatedChapter = {
                ...chapterToCopy,
                parentPath: chapterSelected.parentPath,
                position: chapterSelected.position + 1
            };

            dispatch(pasteChapter(updatedChapter));
        }
    };

    const handleOpen = () => {
        setAnchorEl(document.getElementById(`Chapitre ${chapterSelected?.id}`));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeWithChildren = (event: any) => {
        setWithChildren(event.target.checked);
    };

    const handleChangeIsRandomSerie = (event: any) => {
        setIsRandomSerie(event.target.checked);
    };

    const handleChangeNbQuestions = (value: any) => {
        setNbQuestions(String(value));
    };

    const handleChangeLanguage = (event: any) => {
        setLanguage(event.target.value);
    };

    const handleChangeExamMode = (event: any) => {
        setIsExamMode(event.target.checked);
    };

    const handleChangeMaxDuration = (value: any) => {
        setMaxDuration(String(value));
    };

    const handleValidate = () => {
        if(chapterSelected) {
            dispatch(setExamMode(isExamMode));
            const paramMaxDuration = maxDuration === '--:--' ? '' : `&maxDuration=${mapLocalTimeToDuration(maxDuration)}`;

            history.push(`${CHAPTER_RESOLVE_URL
            }/${props.skill.name
            }?skillId=${props.skill.id
            }&chapterId=${chapterSelected.id
            }&nbQuestions=${nbQuestions
            }&withChildren=${withChildren
            }&isRandomSerie=${isRandomSerie
            }&language=${language
            }${paramMaxDuration}`);
        }
    };

    const quizUuid = uuidv4().split('-')[0];

    const handleValidateInteractiveQuiz = () => {
        if(chapterSelected) {
            dispatch(addRoom({
                uuid: quizUuid,
                serieParams: {
                    skillName: props.skill.name,
                    skillId: props.skill.id,
                    chapterId: chapterSelected.id,
                    nbQuestions,
                    withChildren,
                    isRandomSerie,
                    language
                },
                currentQuestionId: -1,
                isQuizStarted: false,
                users: [],
                hostId: AuthenticationContainer.getCurrentUserId()
            }));
        }
    };

    const handleMenuAction = (action: ContextMenuActionEnum) => {
        if(chapterSelected) {
            switch (action) {
                case ContextMenuActionEnum.PLAY_CHAPTER_SERIE:
                    handleOpen();
                    break;
                case ContextMenuActionEnum.RENAME:
                    props.openDialog({
                        edition: true,
                        chapter: chapterSelected
                    });
                    break;
                case ContextMenuActionEnum.NEW_SUB_CHAPTER:
                    const parentPath = `${chapterSelected.parentPath + chapterSelected.name.toLowerCase()}/`;
                    const parent = chapterTree?.find(parentPath);
                    if(parent) {
                        props.openDialog({
                            skillId: props.skill.id,
                            chapter: chapterSelected,
                            position: parent.children.length + 1
                        });
                    }
                    break;
                case ContextMenuActionEnum.NEW_QUESTION:
                    props.handleCreateQuestion();
                    if(chapterSelected) {
                        dispatch(setSelectedChapterId({
                            chapterId: chapterSelected.id,
                            skillId: chapterSelected.skillId
                        }));
                    }
                    break;
                case ContextMenuActionEnum.COPY:
                    setChapterToCopy(chapterSelected);
                    break;
                case ContextMenuActionEnum.PASTE_IN:
                    pasteInChapter();
                    resetChapterToCopy();
                    break;
                case ContextMenuActionEnum.PASTE_NEXT_TO:
                    pasteNextToChapter();
                    resetChapterToCopy();
                    break;
                case ContextMenuActionEnum.REMOVE:
                    setOpenDeleteDialog(true);
                    break;
                default:
                    break;
            }
        }
        handleCloseMenu();
    };

    const closeDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleConfirmDeleteChapter = () => {
        dispatch(deleteChapter(chapterSelected));
        props.onNodeSelect?.(0);
        closeDialog();
    };

    return (
        <>
            <ContextMenu
                contextMenu={contextMenu}
                chapterToCopy={chapterToCopy}
                disableDialogActions={!props.openDialog}
                disableDeleteChapter={disableDeleteChapter}
                handleCloseMenu={handleCloseMenu}
                handleMenuAction={handleMenuAction}
                permission={props.permission}
            />
            <SimpleDialog
                open={openDeleteDialog}
                title="Suppression du chapitre"
                content={`Voulez-vous définitivement supprimer le chapitre : ${chapterSelected?.name}?`}
                handleConfirm={handleConfirmDeleteChapter}
                handleCancel={closeDialog}
            />
            {anchorEl &&
                <PlayChapterQuestionsRequest
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onValidate={handleValidate}
                    onValidateInteractiveQuiz={handleValidateInteractiveQuiz}
                    chapterId={chapterSelected?.id ?? 0}
                    nbQuestions={nbQuestions}
                    onChangeNbQuestions={handleChangeNbQuestions}
                    withChildren={withChildren}
                    onChangeWithChildren={handleChangeWithChildren}
                    isRandomSerie={isRandomSerie}
                    setIsRandomSerie={handleChangeIsRandomSerie}
                    language={language}
                    onChangeLanguage={handleChangeLanguage}
                    isExamMode={isExamMode}
                    onSetExamMode={handleChangeExamMode}
                    quizUuid={quizUuid}
                    onChangeMaxDuration={handleChangeMaxDuration}
                />
            }
            {isFetching
                ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
                : chapterTree
                    ? <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon style={{ fontSize: '1.5rem' }} />}
                        defaultExpandIcon={<ChevronRightIcon style={{ fontSize: '1.5rem' }} />}
                        expanded={props.expanded}
                        onNodeToggle={handleToggle}
                        onNodeSelect={props.onNodeSelect}
                    >
                        {chapterTree.root.children.map((node) => (
                            <ChapterTreeItem
                                key={node.content.id}
                                node={node}
                                isInDrawer={props.isInDrawer}
                                skill={props.skill}
                                depth={1}
                                permission={props.permission}
                                chaptersValidation={chaptersValidation}
                                onClickQuestion={props.onClickQuestion}
                                handleContextMenu={handleContextMenu}
                                setExpanded={props.setExpanded}
                                userId={props.selectedUserId ?? props.userId}
                                pathToBookmark={props.bookmark?.skillId === props.skill.id && chapters.find((elem) => elem.id === props.bookmark.chapterId)?.parentPath || ''}
                                bookmark={props.bookmark?.skillId === props.skill.id && props.bookmark || undefined}
                                selectedQuestion={selectedQuestion}
                                selectedGroup={props.selectedGroup}
                            />
                        ))}
                    </TreeView>
                    : <span className="center">
                        {NO_CHAPTERS_FOUND}
                    </span>
            }
        </>
    );
};
