import { React, useState } from 'react';
import '../Message.scss';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider, IconButton } from '@material-ui/core';

import PropTypes from 'prop-types';
import { selectMicroskills } from 'app/redux/selectors/Chat/chat.selector';
import { storeMicroskill } from 'app/redux/actions/Chat/chat.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getMicroskillIdByRemediationQuestionId, getMicroskillCourseById } from 'app/api/microskillApi';
import MicroskillReportingButton from 'app/microskill/MicroskillReportingButton';

export default function CourseUnderRemediationQuestion(props) {

    const dispatch = useDispatch();
    const microskills = useSelector(selectMicroskills);

    const [course, setCourse] = useState(null);
    const [isCourseContentVisible, setCourseContentVisible] = useState(false);

    const displayCourse = () => {

        getMicroskillIdByRemediationQuestionId(props.questionId).then(
            (responseMicroskillId) => {
                if(responseMicroskillId.data) {
                    const microskillId = responseMicroskillId.data;

                    let microskill = microskills?.find(micro => micro.id === microskillId);
                    if(microskill?.course) {
                        setCourse({
                            microskillId,
                            name: microskill.name,
                            content: microskill.course || 'Pas de cours disponible pour le moment'
                        });
                    } else {
                        getMicroskillCourseById(microskillId).then(
                            (responseMicroskillCourse) => {
                                microskill = responseMicroskillCourse.data;
                                microskill.id = microskillId;

                                dispatch(storeMicroskill(microskill));

                                setCourse({
                                    microskillId,
                                    name: microskill.name,
                                    content: microskill.course || 'Pas de cours disponible pour le moment'
                                });
                            }
                        );
                    }
                }
            }
        );


    };

    const toggleChapterContent = () => {
        if(!isCourseContentVisible && !course) {
            displayCourse();
        }
        setCourseContentVisible(!isCourseContentVisible);
    };

    return (
        <div className={'message oxa course-under-question'}>
            <div className="header" />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span className="content bold">
                    Afficher le cours
                </span>
                <IconButton color="primary" onClick={toggleChapterContent}>
                    {isCourseContentVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>
            {isCourseContentVisible && course &&
                <>
                    {course.name && <div>
                        <div className='microskill-title-container'>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: course.name }} className="content bold" />
                                <MicroskillReportingButton 
                                    microskillId={course.microskillId}
                                />
                            </div>
                        </div>
                        <Divider style={{ marginBottom: '8px' }} />
                    </div>}
                    <div dangerouslySetInnerHTML={{ __html: course.content }} className="content scrollable" />
                </>
            }
        </div >

    );
}

CourseUnderRemediationQuestion.propTypes = {
    questionId: PropTypes.number
};