import React, { useEffect, useState } from 'react';
import {
    Collapse,
    Grid,
    Paper,
} from '@material-ui/core';
import './CoachingGroup.scss';
import { useSelector } from 'react-redux';
import { selectGroup } from 'app/redux/selectors/Group/group.selector';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupBarChart from '../../../components/graph/GroupBarChat';

export default function CoachingGroupGraph() {
    const selectedGroup = useSelector(selectGroup);
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setIsCollapsed(true);
    }, [selectedGroup]);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Paper style={{ marginTop: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={11} sm={11} md={11} lg={11} id="clickable" className="clickable" onClick={handleCollapse}>
                    <h3 className="groupSpacing">Graphe</h3>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} className="clickableToggleCollapse" onClick={handleCollapse}>
                    <div className="toggleCollapse">
                        {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                    </div>
                </Grid>
            </Grid>
            <Grid>
                <Collapse  in={!isCollapsed} unmountOnExit timeout="auto" className="collapse">
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <GroupBarChart groupId={selectedGroup.id} forceWidth></GroupBarChart>
                    </div>
                </Collapse>
            </Grid>
        </Paper>
    );
}
