
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { selectParcoursHistory } from 'app/redux/selectors/Question/history.selector';
import { getParcoursStepContentByParcoursStepId } from 'app/redux/actions/Question/History/history.actions';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryDisplayEnum } from '../historyRowHead/HistoryDisplayEnum';
import HistoryList from '../historyList/HistoryList';


function ParcoursStepRequestByParcoursStepId(props) {

    const [listQuestions, setListQuestions] = useState([]);

    const dispatch = useDispatch();

    const parcoursHistory =  useSelector(selectParcoursHistory);

    useEffect(() => {

        dispatch(getParcoursStepContentByParcoursStepId(props.parcoursId, props.parcoursStepId, props.userId));
        
    }, []);

    useEffect(() => {
      
        setListQuestions(parcoursHistory.get(props.parcoursId).find(parcoursStep => parcoursStep.id === props.parcoursStepId).listQuestions);
        
    }, [parcoursHistory]);

    return (
        <HistoryList listContent = {listQuestions} historyDisplayType = {HistoryDisplayEnum.QUESTION}/>
    );
}

ParcoursStepRequestByParcoursStepId.propTypes = {
    parcoursStepId: PropTypes.object.isRequired,
    parcoursId : PropTypes.number.isRequired,
    userId : PropTypes.number
};

export default ParcoursStepRequestByParcoursStepId;
