import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import QuestionListDisplay from '../QuestionDisplay/QuestionListDisplay';

import { CircularProgress } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsByChapterIdAndUserId } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';
import { selectChapterQuestionFilter, selectStudent } from 'app/redux/selectors/Chapter/chapter.selector';
import { selectQuestionToDisplay } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { throttle } from 'lodash';

function QuestionRequestByChapterId(props) {

    const dispatch = useDispatch();

    const studentSelected = useSelector(selectStudent);
    const questionsToDisplay = useSelector(selectQuestionToDisplay);
    const filter = useSelector(selectChapterQuestionFilter);

    const throttledDispatch = throttle(dispatch, 500);

    useEffect(() => {
        if(studentSelected && props.chapterId) {
            throttledDispatch(getQuestionsByChapterIdAndUserId(props.chapterId, studentSelected.id, filter));
        }

    }, [props.chapterId, studentSelected, filter]);


    return (
        questionsToDisplay.get(props.chapterId)
            ? <QuestionListDisplay
                data={questionsToDisplay.get(props.chapterId)}
                skillName={props.skillName}
                onClickQuestion={props.onClickQuestion}
                selectedQuestion={props.selectedQuestion}
                chapterId={props.chapterId}
                moderators={props.moderators}
            />
            : <CircularProgress data-testid={`question-list-loader-${props.chapterId}`}/>
    );
}

QuestionRequestByChapterId.propTypes = {
    chapterId: PropTypes.number.isRequired,
    skillName: PropTypes.string.isRequired,
    onClickQuestion: PropTypes.func.isRequired,
    selectedQuestion: PropTypes.number,
    moderators: PropTypes.object
};

export default QuestionRequestByChapterId;
