import { projectsFilterStore } from '../../rootReducer';
import { clientFilterStore,
    projectSkillFilterStore,
    excilyenStore,
    sectorFilterStore } from '../../reducers/Project/ProjectFilter/projectsFilters.reducer';
import { createSelector } from 'reselect';
import { selectAllProjects } from './projects.selector';
import { arraysIntersection } from '../../../utils/functions';


export const selectProjectFilterClient = (state) => state[projectsFilterStore][clientFilterStore];

export const selectProjectFilterSkill = (state) => state[projectsFilterStore][projectSkillFilterStore];

export const selectProjectFilterUser = (state) => state[projectsFilterStore][excilyenStore];

export const selectProjectFilterSector = (state) => state[projectsFilterStore][sectorFilterStore];

export const selectProjectFilteredByClient = createSelector(
    [
        selectProjectFilterClient,
        selectAllProjects
    ],
    (searchClient, projects) => {

        if(searchClient) {
            return projects.data.filter((project) => project.client.id === searchClient.value);
        }
        return projects.data;
    }
);

export const selectProjectFilteredBySector = createSelector(
    [
        selectProjectFilterSector,
        selectAllProjects
    ],
    (searchSector, projects) => {

        if(searchSector) {
            return projects.data.filter((project) => project.client.sector && project.client.sector.id === searchSector.value);
        }
        return projects.data;
    }
);

export function hasRequiredSkill(project, skillFilters) {
    const requirements = skillFilters
        .map((filteredSkill) => project.listSkill
            .some((excilyenSkill) => excilyenSkill.id === filteredSkill.value));
    return requirements.every((requirement) => requirement === true);
}

export const selectProjectFilteredBySkill = createSelector(
    [
        selectProjectFilterSkill,
        selectAllProjects
    ],
    (skills, projects) => {
        if(Array.isArray(skills) && skills.length) {
            return projects.data.filter((project) => hasRequiredSkill(project, skills));
        }
        return projects.data;
    }
);

export function hasRequiredUser(project, userFilters) {
    const listExcilyensFromProject = project.listMission.flatMap((mission) => mission.listUser);
    const requirements = userFilters
        .map((filteredUser) => listExcilyensFromProject
            .some((user) => user.id === filteredUser.value));
    return requirements.every((requirement) => requirement === true);
}

export const selectProjectFilteredByUser = createSelector(
    [
        selectProjectFilterUser,
        selectAllProjects
    ],
    (users, projects) => {
        if(Array.isArray(users) && users.length) {
            return projects.data.filter((project) => hasRequiredUser(project, users));
        }
        return projects.data;
    }
);

export const selectProjectFilteredBySkillAndClient = createSelector(
    [
        selectProjectFilteredBySkill,
        selectProjectFilteredByClient
    ],
    arraysIntersection
);

export const selectProjectFilteredBySkillAndClientAndUser = createSelector(
    [
        selectProjectFilteredBySkillAndClient,
        selectProjectFilteredByUser
    ],
    arraysIntersection
);

export const selectProjectFilteredBySkillAndClientAndUserAndSector = createSelector(
    [
        selectProjectFilteredBySkillAndClientAndUser,
        selectProjectFilteredBySector
    ],
    arraysIntersection
);
