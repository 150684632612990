import { forwardRef, React } from 'react';

import PropTypes from 'prop-types';
import MessageContenu from './messageOxa/MessageContenu';
import MessageQuestion from './messageOxa/MessageQuestion';
import MessageQuestionOuverte from './messageOxa/MessageQuestionOuverte';
import './Message.scss';
import MessageQuestionTexteATrou from './messageOxa/MessageQuestionTexteATrou';
import MessageChapter from './messageOxa/MessageChapter';
import { useDispatch, useSelector } from 'react-redux';
import { selectMicroskills } from 'app/redux/selectors/Chat/chat.selector';
import { getMicroskillsIdsByQuestionId, getMicroskillsNames } from 'app/api/microskillApi';
import { storeMicroskill } from 'app/redux/actions/Chat/chat.actions';
import { RemediationChoice } from './messageOxa/RemediationChoice';

export const Message = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    const allMicroskills = useSelector(selectMicroskills);

    const isRemediation = Boolean(props.message?.content?.content?.questionRemediationStatus);

    let component = '';

    const setCoursesNames = (questionId, setQuestionMicroskills) => {
        getMicroskillsIdsByQuestionId(questionId).then(async(responseIds) => {

            const questionMicroskillsIds = responseIds.data;

            const microskillsIdsNotInStore = questionMicroskillsIds.filter((id) => !allMicroskills.some((mc) => mc.id === id));

            const copyQuestionMicroskills = allMicroskills.filter((mc) => questionMicroskillsIds.includes(mc.id));

            if((microskillsIdsNotInStore.length !== 0)) {

                await getMicroskillsNames(microskillsIdsNotInStore).then((responseMcs) => {

                    new Map(Object.entries(responseMcs.data)).forEach((name, id) => {
                        copyQuestionMicroskills.push({ name, id: parseInt(id, 10) });

                        dispatch(storeMicroskill({ name, id: parseInt(id, 10) }));

                    });
                });
            }

            setQuestionMicroskills(copyQuestionMicroskills);

        });


    };

    switch (true) {

        case props.message?.class === 'remediationChoice':
            component = <RemediationChoice questionMicroskills={props.message.microskills}
                parcoursId = {props.parcoursId}
                setRemediationOnGoing = {props.setRemediationOnGoing}/>;
            break;
        case props.message.content.content?.class === 'MultipleChoiceQuestionAsked':
            if(props.isExamMode) {
                component = <>
                    <MessageQuestion
                        ref={ref}
                        message={props.message.content}
                        setRemediationOnGoing = {props.setRemediationOnGoing}
                        isExamMode
                        nextMorningQuestion = {props.nextMorningQuestion}
                    />
                </>;
            } else {
                component =
                    <MessageQuestion ref={ref} message={props.message.content}
                        setRemediationOnGoing = {props.setRemediationOnGoing}
                        questionId = {props.message.content.content?.id}
                        nextMorningQuestion = {props.nextMorningQuestion}
                        isRemediation = {isRemediation}
                        setCoursesNames = {setCoursesNames}
                    />;

            }
            break;
        case props.message.content.content?.class === 'QuestionOuverteAsked':
            component = <>
                <MessageQuestionOuverte
                    message={props.message.content}
                    isLoading={props.isLoading}
                    setIsLoading={props.setIsLoading}
                    isQuestionOuverteRecording={props.isQuestionOuverteRecording}
                    setIsQuestionOuverteRecording={props.setIsQuestionOuverteRecording}
                    questionId = {props.message.content.content?.id}
                    setCoursesNames = {setCoursesNames}
                />
            </>;
            break;
        case props.message.content.content?.class === 'QuestionTexteATrouAsked':
            component = <>
                <MessageQuestionTexteATrou
                    message={props.message.content}
                    setCoursesNames = {setCoursesNames}
                    questionId = {props.message.content.content?.id}
                />
            </>;
            break;
        case props.message.content.content?.header !== null && props.message.content.content?.header !== undefined:
            component = <MessageChapter message={props.message.content} />;
            break;
        default:
            component = <MessageContenu
                message={props.message}
                parcoursStarted= {props.parcoursStarted}
                firstParcoursId = {props.firstParcoursId}
                questionId = {props.message.content.content?.id} />;
    }

    return (
        <>
            {component}
        </>
    );
});

Message.propTypes = {
    message: PropTypes.object,
    parcoursStarted: PropTypes.bool,
    firstParcoursId: PropTypes.number,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    listening: PropTypes.bool,
    isQuestionOuverteRecording: PropTypes.bool,
    setIsQuestionOuverteRecording: PropTypes.func,
    setRemediationOnGoing: PropTypes.func,
    isExamMode: PropTypes.bool,
    nextMorningQuestion: PropTypes.func,
    parcoursId: PropTypes.number
};

export default Message;
