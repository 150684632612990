import { selectSkillsFilteredByCategories, selectSkillsFilteredByFamilies } from '../SkillFilters/skillsFilters.selector';
import {
    allSkillStatsStore,
    skillStore
} from '../../../reducers/Skill/SkillSet/skillSet.reducer';
import { skillsStore } from '../../../rootReducer';
import { selectAllExcilyenSet } from '../../Excilyen/ExcilyenSet/excilyenSet.selector';

export function selectCurrentSkill(state) {
    return state[skillsStore][skillStore];
}

export const selectSkillStats = (state, skillId) => state[skillsStore][allSkillStatsStore].find((skillStats) => skillStats.skillId === skillId);

export const selectSkillsNumberByFamily = (state, familyName) => selectSkillsFilteredByCategories(state)
    .filter((skill) => (skill.familyInternalDto && skill.familyInternalDto.name) === familyName ||
        (skill.familyInternalDtoOptional && skill.familyInternalDtoOptional.name) === familyName).length;

export const selectSkillsNumberByCategory = (state, categoryName) => selectSkillsFilteredByFamilies(state)
    .filter((skill) => (skill.categoryInternalDto && skill.categoryInternalDto.name) === categoryName).length;

export const selectSkillUsersCount = (state, skillId) => {
    const skillStats = selectSkillStats(state, skillId);
    return skillStats ? skillStats.excilyensCount : 0;
};

export const selectSkillModeratorsCount = (state, skillId) => {
    const skillStats = selectSkillStats(state, skillId);
    return skillStats ? skillStats.moderatorsCount : 0;
};

export const selectExcilyenSkillCount = (state) => {
    const excilyensToSkillNb = [];
    const excilyensPerSkillNb = [];
    const allExcilyens = selectAllExcilyenSet(state);
    allExcilyens.forEach((excilyen) => excilyensToSkillNb.push(excilyen.skillDetailedApiDtos.length));
    const excilyensToSkillNbSet = new Set(excilyensToSkillNb);
    excilyensToSkillNbSet.forEach((skillNb) => excilyensPerSkillNb.push([
        skillNb,
        excilyensToSkillNb.filter((excilyenSkillNb) => excilyenSkillNb === skillNb).length
    ]));
    return excilyensPerSkillNb;
};
