

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

const ExcilyensLoader = ({
    width = 1366,
    heading = {
        width: 0,
        height: -20
    },
    row = 3,
    column = 6,
    padding = 10,
    ...props
}) => {
    const list = [];

    let height = 0;

    let index = 0;

    const itemWidth = (width - padding * (column + 1)) / column;

    const height1 = itemWidth;

    const upperReactHeight = 20;

    const lowerRectHeight = 20;

    const itemSpacing =
        padding + height1 + (padding / 2 + upperReactHeight) + lowerRectHeight + padding;

    for(let ii = 1; ii <= row; ii++) {
        const y1 = heading.height + padding * 2 + itemSpacing * (ii - 1);

        const upperReactY = y1 + height1;

        const lowerRectY = upperReactY + padding / 2 + upperReactHeight;

        for(let jj = 0; jj < column; jj++) {
            index += 1;

            const xx = padding + jj * (itemWidth + padding);

            list.push(<Fragment key={index}>
                <circle cx={xx + itemWidth / 2} cy={y1 + itemWidth / 2} r={itemWidth / 2 - padding}/>
                <rect x={xx} y={upperReactY} rx={0} ry={0} width={itemWidth} height={upperReactHeight}/>
                <rect
                    x={xx + itemWidth * 0.3}
                    y={lowerRectY}
                    rx={0}
                    ry={0}
                    width={itemWidth * 0.4}
                    height={lowerRectHeight}
                />
            </Fragment>);

            if(ii === row) {
                height = lowerRectY + lowerRectHeight;
            }
        }
    }

    return (
        <ContentLoader
            data-testid="content-loader"
            width={width}
            height={height}
            speed={2}
            primaryColor="#e4e1e1"
            secondaryColor="#d6d3d3"
            style={{
                flex: 1
            }}
            ariaLabel={false}
            {...props}
        >
            {list}
        </ContentLoader>
    );
};

ExcilyensLoader.propTypes = {
    width: PropTypes.string,
    heading: PropTypes.object,
    row: PropTypes.number,
    column: PropTypes.number,
    padding: PropTypes.number
};

export default ExcilyensLoader;
