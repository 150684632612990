import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QuestionListDisplay from '../QuestionDisplay/QuestionListDisplay';
import { getSerieByIdWithQuestionReducedApi } from 'app/api/seriesApi';

function QuestionRequestBySerieId(props) {
    const [questionList, setQuestionList] = useState([]);

    useEffect(() => {
        getSerieByIdWithQuestionReducedApi(props.serie.id).then((response) => {
            setQuestionList(response.data.questionReducedList);
        });
    }, [props.serie.id]);

    return (
        <QuestionListDisplay
            data={questionList}
            onClickQuestion={props.onClickQuestion}
            selectedQuestion={props.selectedQuestion}
        />
    );
}

QuestionRequestBySerieId.propTypes = {
    serie: PropTypes.object.isRequired,
    onClickQuestion: PropTypes.func.isRequired,
    selectedQuestion: PropTypes.number
};

export default QuestionRequestBySerieId;
