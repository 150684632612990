import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CreateEditQuestionForm from './createEditQuestionForm/CreateEditQuestionForm';
import { withWidth } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { createWaiterLoadingSelector } from 'app/redux/selectors/APISelector/APISelector';
import { getCurrentUser, GET_CURRENT_USER } from 'app/redux/actions/User/user.actions';
import { isModeratorApi } from 'app/api/userSkillApi';
import { getQuestionByIdApi } from 'app/api/multipleChoiceQuestionApi';

export function CreateEditQuestion(props) {

    const history = useHistory();

    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState(false);

    const currentUser = useSelector(selectCurrentUser);

    const isFetchingUser = useSelector(createWaiterLoadingSelector([GET_CURRENT_USER]));

    useEffect(() => {
        dispatch(getCurrentUser());
    }, []);

    function useQuery() {
        const { search, pathname } = useLocation();
        const path = `${pathname}${search}`;
        const params = path.substring(path.indexOf('?'));
        return useMemo(() => new URLSearchParams(params), [pathname, search]);
    }

    const query = useQuery();

    const questionId = Number(query.get('id'));
    const isEditing = props.match.params.mode.match('edit');

    const toQuestions = () => {
        history.push(QUESTIONS_PAGE_URL);
    };

    const checkIsCreatorOrModeratorOrAdmin = () => {
        if(questionId) {
            getQuestionByIdApi(questionId).then(async(response) => {
                const question = response.data;
                let canEdit = false;
                if(currentUser.id === question.creator.id) {
                    canEdit = true;
                } else {
                    await isModeratorApi(currentUser.id, question.skill?.id || question.skillId).then((responseMod) => {
                        canEdit = responseMod.data || currentUser.roles.some((role) => role.name === 'ADMIN');
                    });
                }
                setRedirect(!canEdit);
            });
        }
    };

    useEffect(() => {
        if(!isFetchingUser && currentUser) {
            checkIsCreatorOrModeratorOrAdmin(currentUser);
        }
    }, [currentUser, isFetchingUser]);

    return (
        <div data-testid="create-edit-question">
            {(currentUser.id && !redirect)
                ? <CreateEditQuestionForm
                    idQuestion={questionId}
                    isEditing={isEditing && isEditing.length > 0}
                    onCloseCreateEdit={toQuestions}
                />
                : <Redirect to={'/skills'} />
            }
        </div>
    );

}

CreateEditQuestion.propTypes = {
    match: PropTypes.object.isRequired
};


export default (withWidth()(CreateEditQuestion));
