import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import { AssignTypes } from 'app/utils/functions';
import { BookOutlined, HelpOutline, ListAlt } from '@material-ui/icons';
import colors from 'styles/colors.module.scss';

function ParcoursStepContent(props) {

    const dragRef = React.useRef(null);
    const dropRef = React.useRef(null);

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: AssignTypes.PARCOURS_STEP_CONTENT,
        item: {
            type: AssignTypes.PARCOURS_STEP_CONTENT,
            stepContent: props.parcoursStepContent,
            step: props.parcoursStep,
            stepContentIndex: props.stepContentIndex
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            dragItem: monitor.getItem()
        })
    }), [props.parcoursStepContent, props.parcoursStep, props.stepContentIndex]);

    function renderStepContentIcon(stepContent) {
        switch (stepContent.type) {
            case AssignTypes.QUESTION:
                return (
                    <div style={{ display: 'flex' }}>
                        <HelpOutline size="small" style={{ color: 'grey', marginRight:'10px' }}/>
                        <ListItemText primary={`${stepContent.name}`} />
                    </div>

                );
            case AssignTypes.CHAPTER:
                return (
                    <div style={{ display: 'flex' }}>
                        <BookOutlined size="small" style={{ color: 'grey', marginRight:'10px' }}/>
                        <ListItemText primary={`${stepContent.name}`} />
                    </div>
                );
            case AssignTypes.SERIE:
                return (
                    <div style={{ display: 'flex' }}>
                        <ListAlt size="small" style={{ color: 'grey', marginRight:'10px' }}/>
                        <ListItemText primary={`${stepContent.name}`} />
                    </div>
                );
            default:
                return null;
        }
    }

    const [{ isOver }, dropAfter] = useDrop(() => ({
        accept: [AssignTypes.CHAPTER, AssignTypes.QUESTION, AssignTypes.SERIE, AssignTypes.PARCOURS_STEP_CONTENT],
        drop: (item) => {
            props.onDrop(props.stepContentIndex, item, props.parcoursStep);
        },
        canDrop: () => true,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        }),
    }), [props.parcoursStepContent, props.parcoursStep, props.stepContentIndex]);


    preview(dropAfter(dropRef));
    drag(dragRef);

    const draggingStyle = {
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? colors['drop-zone-parcours'] : 'white',
        cursor: 'move'
    };

    return (
        <div ref={(node) => drag(dropAfter(node))} style={{ ...draggingStyle, padding: '6px', alignContent: 'center' }}>
            <div >
                {isDragging && (
                    <div style={{ padding: '5px', backgroundColor: 'lightgrey', borderRadius: '5px' }}>
                        {props.parcoursStepContent.name}
                    </div>
                )}
                {!isDragging && renderStepContentIcon(props.parcoursStepContent)}
            </div>
        </div>
    );
};

ParcoursStepContent.propTypes = {
    parcoursStep: PropTypes.object,
    parcoursStepContent: PropTypes.object,
    onDrop: PropTypes.func,
    stepContentIndex: PropTypes.number
};

export default ParcoursStepContent;
