import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './QuestionReportingButton.scss';

import ReportIcon from '@material-ui/icons/Report';
import { IconButton } from '@material-ui/core';
import QuestionReportingDialog from './QuestionReportingDialog';

function QuestionReportingButton(props) {

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    return (
        <>
            <IconButton
                className="reporting-button"
                aria-label="reporting"
                color="secondary"
                onClick={handleOpen}
            >
                <ReportIcon
                    fontSize="large"
                />
            </IconButton>
            <QuestionReportingDialog
                questionId={props.questionId}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </>
    );
}

QuestionReportingButton.propTypes = {
    questionId: PropTypes.number.isRequired
};

export default QuestionReportingButton;
