import { Button, Select, TreeSelect } from 'antd';
import React, { Fragment, useState, useEffect } from 'react';
import { Tabs, Tab, LinearProgress, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import './CreateEditQuestionHeader.scss';
import { languageOptions } from 'app/utils/LanguageUtils';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { localLlmServiceApi } from 'app/api/localLlmApi';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, GENERATE_QUESTION_ERROR } from 'app/Snackbar/NotificationMessages';
import { ReactComponent as ANSWERS_COLUMN } from 'assets/fonts/icons/layout/answersColumn.svg';
import { ReactComponent as ANSWERS_COLUMN_SELECTED } from 'assets/fonts/icons/layout/answersColumSelected.svg';
import { ReactComponent as ANSWERS_GRID } from 'assets/fonts/icons/layout/anwersGrid.svg';
import { ReactComponent as ANSWERS_GRID_SELECTED } from 'assets/fonts/icons/layout/answersGridSelected.svg';
import { ReactComponent as ANSWERS_RIGHT } from 'assets/fonts/icons/layout/answersRight.svg';
import { ReactComponent as ANSWERS_RIGHT_SELECTED } from 'assets/fonts/icons/layout/answersRightSelected.svg';

import { QuestionLayouts } from 'app/utils/QuestionEnum';
import { promptEnum } from 'app/utils/PromptEnum';
import { getAiReviewPromptMessageApi } from 'app/api/promptApi';

const promptOptions = [
    {
        id: 0,
        value: promptEnum.CLASSIQUE,
        label: promptEnum.CLASSIQUE
    }, {
        id: 1,
        value: promptEnum.TECHNIQUE,
        label: promptEnum.TECHNIQUE
    }
];

let promptMessage = '';
export function CreateEditQuestionHeader(props) {

    const dispatch = useDispatch();

    const [isFetching, setIsFetching] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const setLayout = (layoutSelected) => {
        props.setMcq((prevMCQ) => ({
            ...prevMCQ,
            layout: layoutSelected
        }));
    };

    useEffect(() => {
        if(!props.mcq.layout) {
            setLayout(QuestionLayouts.ANSWERS_COLUMN);
        }
    }, []);

    useEffect(() => {
        if(props.mcq.id) {
            props.setValueButton(props.mcq.class);
        }
    }, [props.mcq]);

    const handleOpenPromptDialog = async() => {
        if(!props.selectedPrompt?.length) {
            return '';
        }
        const resp = await getAiReviewPromptMessageApi(props.selectedPrompt);
        if(resp.status !== 200) {
            return '';
        }

        promptMessage = resp?.data;
        promptMessage = promptMessage.replace('SKILL_NAME', props.selectedSkill?.label);
        promptMessage = promptMessage.replace('QUESTION_STATEMENT', props.mcq?.statement);
        promptMessage = promptMessage.replace('QUESTION_EXPLANATION', props.mcq?.explanation);
        setDialogOpen(true);
        return `<pre>${promptMessage}</pre>`;
    };

    const generatedQuestion = () => {
        setIsFetching(true);

        const chapterContentArray = props.selectedChapters.map((chapter) => chapter.content);
        const chapterArray = chapterContentArray.map((chapter) => {
            const modifiedChapter = { ...chapter };
            delete modifiedChapter.nbQuestionWithChildren;
            return modifiedChapter;
        });

        localLlmServiceApi.generateMcqQuestionsByChapters(props.selectedSkill.id, chapterArray)
            .then((response) => {
                setIsFetching(false);
                props.handleQuestionGeneration(response.data);
            })
            .catch((error) => {
                setIsFetching(false);
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(GENERATE_QUESTION_ERROR));
                }
            });
    };

    return (
        <Fragment>
            <div className="skill-language">
                <div className="skillSelect" data-testid="skill-select">
                    <label className="selectSkillLabel">Lier à une compétence :</label>
                    <Select className="selectSkill"
                        onChange={props.handleChangeSelectSkill}
                        placeholder={'Compétence *'}
                        showSearch
                        name={'competence'}
                        disabled={props.skillLink}
                        options={props.skills}
                        popupClassName="selectSkill"
                        value={props.selectedSkill && props.selectedSkill.value}
                    >
                    </Select>
                </div>
                <div className="languageSelect" data-testid="language-select">
                    <label className="selectLanguageLabel">Langue :</label>
                    <Select className="selectLanguage"
                        onChange={props.handleChangeLanguage}
                        placeholder={'Langue'}
                        showSearch
                        name={'langue'}
                        options={languageOptions}
                        popupClassName="selectLanguage"
                        value={props.selectedLanguage}
                    >
                    </Select>
                </div>
                {props.valueButton === 'QuestionOuverte' &&
                    <>
                        <div className="languageSelect">
                            <label className="selectLanguageLabel">Type de prompt :</label>
                            <Select className="selectLanguage"
                                onChange={props.handleChangePrompt}
                                placeholder={'Type de prompt'}
                                showSearch
                                name={'promptType'}
                                options={promptOptions}
                                value={props.selectedPrompt}
                            >
                            </Select>
                        </div>
                        <div className="promptDiv">
                            <Button onClick={() => handleOpenPromptDialog()}>Voir Prompt</Button>
                        </div>
                        <Dialog fullWidth={true} maxWidth="xl" onClose={() => setDialogOpen(false)} open={dialogOpen}>
                            <DialogContent>
                                <div className="outer">
                                    <div className="content">
                                        { promptMessage }
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className="cancelButtonContainer">
                                    <Button onClick={() => setDialogOpen(false)}>FERMER</Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </div>

            <div className="chapterSelect" data-testid="chapter-select">
                <label className="selectChapterLabel">Lier à un chapitre :</label>
                <TreeSelect className="selectChapter"
                    showSearch
                    onChange={props.handleChangeSelectChapter}
                    placeholder={'Chapitre'}
                    name={'chapitre'}
                    multiple
                    allowClear
                    options={props.chapters}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto'
                    }}
                    disabled={!props.chapters}
                    treeData={props.chapters && props.chapters.root && props.chapters.root.children}
                    value={props.selectedChapters && props.selectedChapters.map((chapter) => chapter?.key)}
                />
                <Button className="generateQuestionButton"
                    onClick={generatedQuestion}
                    disabled={props.selectedChapters.length === 0 || isFetching}
                >Générer un QCM</Button>
                <div style={{ marginTop: '10px' }}>{ isFetching && <LinearProgress/> }</div>

            </div>
            <div className="tabsQuestionTypeAndLayout">
                {!props.questionTypeLocked &&
                    <div className="tabsQuestionType">
                        <Tabs value={props.valueButton} onChange={props.handleChangeButton} indicatorColor="primary">
                            <Tab label="QCM" value="MultipleChoiceQuestion" />
                            <Tab label="QUESTION À TROUS" value="QuestionTexteATrou"/>
                            <Tab label="QUESTION OUVERTE" value="QuestionOuverte"/>
                        </Tabs>
                    </div>
                }
                {props.valueButton === 'MultipleChoiceQuestion' &&
                    <div className="choiceLayout">
                        {props.mcq?.layout === QuestionLayouts.ANSWERS_COLUMN
                            ? <ANSWERS_COLUMN_SELECTED style={{ width: '40px', height: '40px' }}/>
                            : <ANSWERS_COLUMN style={{ width: '40px', height: '40px' }} onClick={() => setLayout(QuestionLayouts.ANSWERS_COLUMN)}/> }
                        {props.mcq?.layout === QuestionLayouts.ANSWERS_GRID
                            ? <ANSWERS_GRID_SELECTED style={{ width: '40px', height: '40px' }}/>
                            : <ANSWERS_GRID style={{ width: '40px', height: '40px' }} onClick={() => setLayout(QuestionLayouts.ANSWERS_GRID)}/> }
                        {props.mcq?.layout === QuestionLayouts.ANSWERS_RIGHT
                            ? <ANSWERS_RIGHT_SELECTED style={{ width: '40px', height: '40px' }}/>
                            : <ANSWERS_RIGHT style={{ width: '40px', height: '40px' }} onClick={() => setLayout(QuestionLayouts.ANSWERS_RIGHT)}/> }
                    </div>
                }
            </div>
        </Fragment>
    );
}

CreateEditQuestionHeader.propTypes = {
    skills: PropTypes.array,
    selectedChapters: PropTypes.array,
    questionTypeLocked: PropTypes.bool,
    valueButton: PropTypes.string,
    setValueButton: PropTypes.func,
    chapters: PropTypes.object,
    selectedSkill: PropTypes.object,
    skillLink: PropTypes.object,
    selectedLanguage: PropTypes.string,
    selectedPrompt: PropTypes.string,
    handleChangeButton: PropTypes.func,
    handleChangeSelectChapter: PropTypes.func.isRequired,
    handleChangeSelectSkill: PropTypes.func.isRequired,
    handleChangeLanguage: PropTypes.func.isRequired,
    handleChangePrompt: PropTypes.func,
    handleQuestionGeneration: PropTypes.func.isRequired,
    mcq: PropTypes.object,
    setMcq: PropTypes.func
};
export default withRouter(CreateEditQuestionHeader);
