import { ADD_MESSAGE_TO_CHAT, UPDATE_INPUT_TEXT, REMOVE_MESSAGE, STORE_MICROSKILL, ADD_REMEDIATION_CHOICE } from 'app/redux/actions/Chat/chat.actions';
import {
    GET_NEXT_PARCOURS_ELEMENT_SUCCESS, 
    GET_PARCOURS_HISTORY_BY_USER_ID_SUCCESS,
    UPDATE_PARCOURS_ID, 
    GET_REMEDIATION_ELEMENT_SUCCESS
} from 'app/redux/actions/Parcours/parcours.actions';

export const parcoursId = 'parcoursId';
export const parcoursCurrentStep = 'parcoursCurrentStep';
export const messages = 'messages';
export const inputText = 'inputText';
export const OXA = 'Oxa';
export const beginButton = 'beginButton';
export const microskills = 'microskills';

export default function chatReducer(state = {
    [parcoursId]: null,
    [parcoursCurrentStep]: null,
    [messages]: [],
    [microskills]: []
}, action) {
    switch (action.type) {
        case GET_PARCOURS_HISTORY_BY_USER_ID_SUCCESS:
            return {
                ...state,
                [parcoursCurrentStep]: action.payload,
                [messages]: [...state[messages], { sender: OXA, content: action.payload }]
            };
        case GET_NEXT_PARCOURS_ELEMENT_SUCCESS:
        case GET_REMEDIATION_ELEMENT_SUCCESS:
            return {
                ...state,
                [parcoursCurrentStep]: action.payload,
                [messages]: [...state[messages], { sender: OXA, content: action.payload, isDone: typeof action.payload === 'string' }]
            };
        case ADD_MESSAGE_TO_CHAT:
            return {
                ...state,
                [messages]: [...state[messages], action.payload]
            };
        case UPDATE_INPUT_TEXT:
            return {
                ...state,
                [inputText]: action.payload
            };
        case UPDATE_PARCOURS_ID:
            return {
                ...state,
                [parcoursId]: action.payload
            };
        case REMOVE_MESSAGE:
            return {
                ...state,
                [messages]: action.payload
            };
        case STORE_MICROSKILL:
            if(!state[microskills].some(microskill => microskill.id === action.payload.id)) {

                return {
                    ...state,
                    [microskills]: [...state[microskills], action.payload]
                };
            }

            const copyMicroskills = [... state.microskills];
            copyMicroskills[copyMicroskills.findIndex(mc => mc.id === action.payload.id)] = action.payload;

            return {
                ...state,
                [microskills]: copyMicroskills
            };
        case ADD_REMEDIATION_CHOICE:
            
            const messageQuestionMicroskills = { class  : 'remediationChoice', microskills: action.payload };

            return {
                ...state,
                [messages]: [...state[messages], messageQuestionMicroskills ]
            };
    
      
        default:
            return state;
    }
}
