import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTaskDrawer } from 'app/redux/selectors/Drawer/drawer.selector';

import { CANDIDAT_WELCOME_PAGE_URL, QUIZ_URL, routesConfig } from 'app/Routes';
import { useLocation, useRouteMatch, matchPath } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';

import TasksDrawer from 'app/header/components/TasksDrawer';
import Header from 'app/header/containers/Header';

import './Layout.scss';
import { useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';

export const drawerWidth = 380;

function Layout(props) {

    const theme = useTheme();

    const taskDrawer = useSelector(selectTaskDrawer);

    const location = useLocation();

    const isCandidate = Boolean(useRouteMatch(CANDIDAT_WELCOME_PAGE_URL));

    const isInteractiveQuiz = Boolean(useRouteMatch(QUIZ_URL));

    const sizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    const [
        headerParams,
        setHeaderParams
    ] = useState([]);

    const [
        displayLayout,
        setDisplayLayout
    ] = useState(false);

    useEffect(() => {
        if(matchPath(location.pathname, { path: '/login' })) {
            setDisplayLayout(false);
        } else {
            routesConfig.forEach((route) => {
                if(matchPath(location.pathname, { path: route.path,
                    exact: route.exact })) {
                    if(route.headerParams) {
                        setHeaderParams(route.headerParams);
                        setDisplayLayout(true);
                    } else {
                        setDisplayLayout(false);
                    }
                }
            });
        }
    }, [location]);

    const legendClass = classNames({
        'layout': true,
        'drawer-open': taskDrawer
    });

    return (
        <div id="Layout" className="layout-container" data-testid="layout">
            {displayLayout &&
            <TasksDrawer
                open={taskDrawer}
                drawerWidth={drawerWidth}
                isCandidateHeader={isCandidate}
                isInteractiveQuiz={isInteractiveQuiz}
            />}
            <div className={legendClass}>
                {displayLayout && <Header params={headerParams} open={taskDrawer} isCandidateHeader={isCandidate} isBigScreen={sizeUpLg}/>}
                <div id="layout-modal-container" className="layout-content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default Layout;
