import { getQuestionsDoneAndGoodAnswersApi, getRichChaptersStatsApi } from 'app/api/chapterStatsApi';
import { EXPIRED_SESSION, GET_CHAPTERS_STATS_TO_DISPLAY_ERROR } from 'app/Snackbar/NotificationMessages';
import { notificationError } from '../Notification/notifications.actions';
import { chapterActions } from 'app/redux/slices/chapters.slice';

export const GET_CHAPTERS_STATS = 'GET_CHAPTERS_STATS';
export const GET_CHAPTERS_STATS_REQUEST = 'GET_CHAPTERS_STATS_REQUEST';
export const GET_CHAPTERS_STATS_SUCCESS = 'GET_CHAPTERS_STATS_SUCCESS';
export const GET_CHAPTERS_STATS_FAILURE = 'GET_CHAPTERS_STATS_FAILURE';

export const GET_USER_STATS_BY_CHAPTER_REQUEST = 'GET_USER_STATS_BY_CHAPTER';
export const GET_USER_STATS_BY_CHAPTER_SUCCESS = 'GET_USER_STATS_BY_CHAPTER_SUCCESS';
export const GET_USER_STATS_BY_CHAPTER_FAILURE = 'GET_USER_STATS_BY_CHAPTER_FAILURE';

export const getChaptersStats = (usersList, chaptersList) => (dispatch) => {
    dispatch({ type: GET_CHAPTERS_STATS_REQUEST });

    return getRichChaptersStatsApi(usersList, chaptersList).then((response) => {
        dispatch({
            type: GET_CHAPTERS_STATS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CHAPTERS_STATS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_CHAPTERS_STATS_TO_DISPLAY_ERROR));
        }
    });
};

export const getQuestionsDoneAndGoodAnswers = (chapterIds, userId, search = '') => (dispatch) => {
    dispatch({ type: GET_USER_STATS_BY_CHAPTER_REQUEST });

    dispatch(chapterActions.getQuestionCountByChapterIds({ chapterIds, search }));

    return getQuestionsDoneAndGoodAnswersApi(chapterIds, userId).then((response) => dispatch({
        type: GET_USER_STATS_BY_CHAPTER_SUCCESS,
        payload: response.data
    }), (error) => {
        dispatch({
            type: GET_USER_STATS_BY_CHAPTER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_CHAPTERS_STATS_TO_DISPLAY_ERROR));
        }
    });
};
