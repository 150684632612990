
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import ParcoursStepRequestByParcoursStepId from './ParcoursStepRequestByParcoursStepId';
import { dateDisplay } from '../../History.functions';

function HistoryParcoursStep(props) {

    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>{props.row.name}</TableCell>
                <TableCell>{dateDisplay(props.row?.date)}</TableCell>

            </TableRow>

            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ParcoursStepRequestByParcoursStepId parcoursStepId={props.row.id} parcoursId = {props.parcoursId} userId = {props.userId} />
                    </Collapse>
                </TableCell>
            </TableRow>
            
        </Fragment>
    );
}

HistoryParcoursStep.propTypes = {
    row: PropTypes.object.isRequired,
    parcoursId : PropTypes.number.isRequired,
    userId : PropTypes.number
};

export default HistoryParcoursStep;
