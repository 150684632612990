
import {
    getCoachesByStudentIdApi,
    getStudentsByCoachIdApi,
    getAskedCoachesByStudentIdApi,
    getStudentsAskingRelationByCoachIdApi,
    postAskCoachApi,
    patchAcceptCoachingAskedRelationApi,
    deleteDenyCoachingAskedRelationApi
} from '../../../api/coachingApi';
import { notificationError, notificationSuccess } from '../Notification/notifications.actions';
import {
    studentAddedSuccess, ADDED_REQUESTED_COACH,
    ASKING_COACHING_ERROR,
    GET_STUDENTS_ASKING_RELATION_ERROR, GET_COACHES_BY_STUDENT_ID_ERROR,
    GET_USER_ASKED_COACHES_ERROR, GET_STUDENTS_BY_COACH_ID_ERROR,
    UPDATE_COACHING_ASKED_RELATION_ERROR,
    EXPIRED_SESSION
} from '../../../Snackbar/NotificationMessages';

export const GET_COACHES_BY_STUDENT_ID = 'GET_COACHES_BY_STUDENT_ID';
export const GET_COACHES_BY_STUDENT_ID_REQUEST = 'GET_COACHES_BY_STUDENT_ID_REQUEST';
export const GET_COACHES_BY_STUDENT_ID_SUCCESS = 'GET_COACHES_BY_STUDENT_ID_SUCCESS';
export const GET_COACHES_BY_STUDENT_ID_FAILURE = 'GET_COACHES_BY_STUDENT_ID_FAILURE';

export const GET_STUDENTS_BY_COACH_ID_REQUEST = 'GET_STUDENTS_BY_COACH_ID_REQUEST';
export const GET_STUDENTS_BY_COACH_ID_SUCCESS = 'GET_STUDENTS_BY_COACH_ID_SUCCESS';
export const GET_STUDENTS_BY_COACH_ID_FAILURE = 'GET_STUDENTS_BY_COACH_ID_FAILURE';

export const GET_USER_ASKED_COACHES = 'GET_USER_ASKED_COACHES';
export const GET_USER_ASKED_COACHES_REQUEST = 'GET_USER_ASKED_COACHES_REQUEST';
export const GET_USER_ASKED_COACHES_SUCCESS = 'GET_USER_ASKED_COACHES_SUCCESS';
export const GET_USER_ASKED_COACHES_FAILURE = 'GET_USER_ASKED_COACHES_FAILURE';

export const GET_STUDENTS_ASKING_RELATION_REQUEST = 'GET_STUDENTS_ASKING_RELATION_REQUEST';
export const GET_STUDENTS_ASKING_RELATION_SUCCESS = 'GET_STUDENTS_ASKING_RELATION_SUCCESS';
export const GET_STUDENTS_ASKING_RELATION_FAILURE = 'GET_STUDENTS_ASKING_RELATION_FAILURE';

export const POST_ASKING_COACHING_REQUEST = 'POST_ASKING_COACHING_REQUEST';
export const POST_ASKING_COACHING_SUCCESS = 'POST_ASKING_COACHING_SUCCESS';
export const POST_ASKING_COACHING_FAILURE = 'POST_ASKING_COACHING_FAILURE';

export const PATCH_ACCEPT_COACHING_ASKED_RELATION_REQUEST = 'PATCH_ACCEPT_COACHING_ASKED_RELATION_REQUEST';
export const PATCH_ACCEPT_COACHING_ASKED_RELATION_SUCCESS = 'PATCH_ACCEPT_COACHING_ASKED_RELATION_SUCCESS';
export const PATCH_ACCEPT_COACHING_ASKED_RELATION_FAILURE = 'PATCH_ACCEPT_COACHING_ASKED_RELATION_FAILURE';

export const DELETE_DENY_COACHING_ASKED_RELATION_REQUEST = 'DELETE_DENY_COACHING_ASKED_RELATION_REQUEST';
export const DELETE_DENY_COACHING_ASKED_RELATION_SUCCESS = 'DELETE_DENY_COACHING_ASKED_RELATION_SUCCESS';
export const DELETE_DENY_COACHING_ASKED_RELATION_FAILURE = 'DELETE_DENY_COACHING_ASKED_RELATION_FAILURE';

export const getCoachesByStudentId = (studentId) => (dispatch) => {
    dispatch({ type: GET_COACHES_BY_STUDENT_ID_REQUEST });

    return getCoachesByStudentIdApi(studentId).then((response) => {
        dispatch({
            type: GET_COACHES_BY_STUDENT_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_COACHES_BY_STUDENT_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_COACHES_BY_STUDENT_ID_ERROR));
        }
    });
};

export const getStudentsByCoachId = (coachId) => (dispatch) => {
    dispatch({ type: GET_STUDENTS_BY_COACH_ID_REQUEST });

    return getStudentsByCoachIdApi(coachId).then((response) => {
        dispatch({
            type: GET_STUDENTS_BY_COACH_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_STUDENTS_BY_COACH_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_STUDENTS_BY_COACH_ID_ERROR));
        }
    });
};

export const getAskedCoachesByStudentId = (studentId) => (dispatch) => {
    dispatch({ type: GET_USER_ASKED_COACHES_REQUEST });
    return getAskedCoachesByStudentIdApi(studentId).then((response) => {
        dispatch({
            type: GET_USER_ASKED_COACHES_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_USER_ASKED_COACHES_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_USER_ASKED_COACHES_ERROR));
        }
    });
};

export const getStudentsAskingRelationByCoachId = (coachId) => (dispatch) => {
    dispatch({ type: GET_STUDENTS_ASKING_RELATION_REQUEST });

    return getStudentsAskingRelationByCoachIdApi(coachId).then((response) => {
        dispatch({
            type: GET_STUDENTS_ASKING_RELATION_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_STUDENTS_ASKING_RELATION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_STUDENTS_ASKING_RELATION_ERROR));
        }
    });
};

export const postAskCoach = (relation) => (dispatch) => {
    dispatch({ type: POST_ASKING_COACHING_REQUEST });

    return postAskCoachApi(relation).then((response) => {
        dispatch({
            type: POST_ASKING_COACHING_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(ADDED_REQUESTED_COACH));
    }, (error) => {
        dispatch({
            type: POST_ASKING_COACHING_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(ASKING_COACHING_ERROR));
        }
    });
};

export const patchAcceptCoachingAskedRelation = (coachId, studentId, studentFullName) => (dispatch) => {
    dispatch({ type: PATCH_ACCEPT_COACHING_ASKED_RELATION_REQUEST });

    return patchAcceptCoachingAskedRelationApi(coachId, studentId).then((response) => {
        dispatch({
            type: PATCH_ACCEPT_COACHING_ASKED_RELATION_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(studentAddedSuccess(studentFullName)));
    }, (error) => {
        dispatch({
            type: PATCH_ACCEPT_COACHING_ASKED_RELATION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(UPDATE_COACHING_ASKED_RELATION_ERROR));
        }
    });
};

export const deleteDenyCoachingAskedRelation = (coachId, studentId) => (dispatch) => {
    dispatch({ type: DELETE_DENY_COACHING_ASKED_RELATION_REQUEST });

    return deleteDenyCoachingAskedRelationApi(coachId, studentId).then(() => {
        dispatch({
            type: DELETE_DENY_COACHING_ASKED_RELATION_SUCCESS,
            payload: studentId
        });
    }, (error) => {
        dispatch({
            type: DELETE_DENY_COACHING_ASKED_RELATION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(UPDATE_COACHING_ASKED_RELATION_ERROR));
        }
    });
};
