import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import ResolveSerieDisplay from './ResolveSerieDisplay';
import { SKILL_PAGE_URL } from 'app/Routes';
import { Redirect, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { selectIsExamMode, selectSerieToResolve } from 'app/redux/selectors/Question/serie.selector';
import { selectSerieHistoryUpdate } from 'app/redux/selectors/Question/history.selector';
import { selectLoading } from 'app/redux/selectors/Loading/loading.selector';
import { postSerieHistory, resetSerieHistoryId } from 'app/redux/actions/Question/History/history.actions';
import {
    getRandomSerieByChapterId,
    GET_RANDOM_SERIE_BY_CHAPTER_ID,
    resetSerieToResolve
} from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { GET_SERIE_CHAPTER_ERROR } from 'app/Snackbar/NotificationMessages';
import { mapDurationToObject } from '../../utils/date.functions';
import { postInteractiveQuizParamsApi } from 'app/api/interactiveQuizApi';

function ResolveSerieRequestByChapterId(props) {

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const dispatch = useDispatch();
    const { search } = useLocation();
    const extractParamsUrl = useMemo(() => new URLSearchParams(search), [search]);

    const serieToResolve = useSelector(selectSerieToResolve);
    const serieHistoryUpdate = useSelector(selectSerieHistoryUpdate);
    const isLoading = useSelector(selectLoading)[GET_RANDOM_SERIE_BY_CHAPTER_ID];
    const examMode = useSelector(selectIsExamMode);

    useEffect(() => {
        if(props.maxDuration === '--:--') {
            const params = {
                withChildren: props.withChildren,
                isRandomSerie: props.isRandomSerie,
                nbQuestion: props.nbQuestions,
                language: props.language,
                userId: getCurrentUserId()
            };
            dispatch(getRandomSerieByChapterId(props.chapterId, params));
        } else {
            const params = {
                withChildren: props.withChildren,
                isRandomSerie: props.isRandomSerie,
                nbQuestion: props.nbQuestions,
                language: props.language,
                userId: getCurrentUserId(),
                maxDuration: props.maxDuration
            };
            dispatch(getRandomSerieByChapterId(props.chapterId, params));
        }

        return () => {
            dispatch(resetSerieToResolve());
            dispatch(resetSerieHistoryId());
        };
    }, [props.chapterId]);

    useEffect(() => {
        if(serieToResolve?.questionAskedList?.length) {
            if(props.quizUuidFromLeader) {
                postInteractiveQuizParamsApi(
                    props.quizUuidFromLeader, props.skillId, props.chapterId,
                    serieToResolve.questionAskedList.map((question) => question.id)
                );
            } else {
                const params = {
                    userId: getCurrentUserId(),
                    skillId: props.skillId,
                    chapterId: props.chapterId,
                    taskId: Number(extractParamsUrl.get('taskId')),
                    questionsId: serieToResolve.questionAskedList.map((question) => question.id),
                    examMode
                };
                dispatch(postSerieHistory(params));
            }
        }
    }, [serieToResolve]);

    return (
        <div data-testid="resolve-serie-request" style={{ height: '100%' }}>

            {(serieHistoryUpdate || (props.quizUuidFromLeader && serieToResolve)) &&
                <ResolveSerieDisplay
                    chapterId={props.chapterId}
                    questionsList={serieToResolve.questionAskedList}
                    nbTotalQuestions={serieToResolve.questionAskedList.length}
                    serieHistoryUpdate={serieHistoryUpdate}
                    redirect={`${SKILL_PAGE_URL}/${props.skillName}`}
                    quizUuidFromLeader={props.quizUuidFromLeader}
                    usersInRoom={props.usersInRoom}
                    maxDuration={mapDurationToObject(serieToResolve.maxDuration)}
                />
            }
            {!isLoading && serieToResolve?.questionAskedList && !serieToResolve.questionAskedList.length &&
                dispatch(notificationError(GET_SERIE_CHAPTER_ERROR)) &&
                <Redirect to={`${SKILL_PAGE_URL}/${props.skillName}`} />
            }
        </div >
    );
}

ResolveSerieRequestByChapterId.propTypes = {
    skillName: PropTypes.string.isRequired,
    skillId: PropTypes.number.isRequired,
    chapterId: PropTypes.number.isRequired,
    nbQuestions: PropTypes.number.isRequired,
    withChildren: PropTypes.bool.isRequired,
    isRandomSerie: PropTypes.bool.isRequired,
    language: PropTypes.string,
    quizUuidFromLeader: PropTypes.string,
    usersInRoom: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string
    })),
    maxDuration: PropTypes.string
};

export default ResolveSerieRequestByChapterId;
