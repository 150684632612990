import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Select, TablePagination, Toolbar, useMediaQuery } from '@material-ui/core';
import { Pagination } from 'antd';
import TablePaginationActions from 'app/utils/TablePaginationActions';

function HistoryPagination(props) {

    const minWidth = 560;
    const widthMatches = useMediaQuery(`(min-width:${minWidth}px)`);

    const handleChangePageResponsive = (page) => {
        props.handleChangePage(page, page - 1);
    };

    return (
        <>
            {widthMatches
                ? <TablePagination
                    count={props.totalElement}
                    page={props.pageNumber}
                    rowsPerPage={props.rowPerPage}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleRowsPerPage}
                    rowsPerPageOptions={props.rowPerPageOptions}
                    labelRowsPerPage={'Lignes par page'}
                    backIconButtonText={'Page précédente'}
                    nextIconButtonText={'Page suivante'}
                    labelDisplayedRows={({ from, to, count }) => (`${from}-${to} / ${count}`)}
                    ActionsComponent={TablePaginationActions}
                    component={'div'}
                />
                : <Toolbar className="toolbarResponsive">
                    <Select
                        value={props.rowPerPage}
                        onChange={props.handleRowsPerPage}
                    >
                        {props.rowPerPageOptions?.map((row) => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                    </Select>
                    <Pagination
                        simple
                        total={props.totalElement}
                        onChange={handleChangePageResponsive}
                        pageSize={props.rowPerPage}
                        current={props.pageNumber + 1}
                    />
                </Toolbar>
            }
        </>
    );
}

HistoryPagination.propTypes = {
    totalElement: PropTypes.number,
    pageNumber: PropTypes.number.isRequired,
    rowPerPage: PropTypes.number.isRequired,
    rowPerPageOptions: PropTypes.array.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleRowsPerPage: PropTypes.func.isRequired
};

export default HistoryPagination;
