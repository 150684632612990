import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { getAllCandidates, GET_CANDIDATES } from 'app/redux/actions/Candidate/candidate.actions';
import { selectAllCandidates } from 'app/redux/selectors/Candidate/candidate.selector';
import { enumStatus } from 'app/utils/CandidateStatusEnum';
import { optionsTooltip } from 'app/utils/OptionsTooltipEnum';
import MaterialTable from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../redux/selectors/APISelector/APISelector';
import CandidateResult from '../CandidateResult/CandidateResult';
import './CandidateTable.scss';


export function CandidateTable(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCandidates());
    }, []);

    const allCandidates = useSelector(selectAllCandidates);

    const isFetchingCandidates = useSelector(createLoadingSelector([GET_CANDIDATES]));

    const [showResultCandidate, setShowResultCandidate] = useState(Boolean(props.candidate));
    const [actualCandidate, setActualCandidate] = useState(props.candidate);

    const translateEnum = (status) => Object.entries(enumStatus).find(([key]) => {
        if(key === status) {
            return true;
        }
        return false;
    })[1];

    const onCloseDialog = () => {
        setShowResultCandidate(false);
        setActualCandidate(null);
    };

    const columns = [
        {
            title: 'Nom',
            field: 'name',
            render: (rowData) => <Fragment key={rowData.id}>
                {`${rowData.firstName} ${rowData.lastName}`}
            </Fragment>,
            cellStyle: {
                width: '25%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            customFilterAndSearch: (term, rowData) => (`${rowData.firstName} ${rowData.lastName}`).toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1,
            headerStyle: {
                width: '25%'
            },
            sorting: false
        },
        {
            title: 'Email',
            field: 'email',
            render: (rowData) => <>
                <Fragment key={rowData.id}>
                    {rowData.email}
                </Fragment>
            </>,
            cellStyle: {
                width: '30%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '30%'
            },
            sorting: false
        },
        {
            title: 'Invitation envoyée le',
            field: 'creationDate',

            render: (rowData) => <>
                <Fragment key={rowData.id}>
                    <Tooltip
                        title={new Intl.DateTimeFormat('fr-FR', optionsTooltip).format(new Date(rowData.creationDate))}
                        placement="left-start"
                        className="displayStatData">
                        <div><i>{new Intl.DateTimeFormat('fr-FR').format(new Date(rowData.creationDate))}</i> </div>
                    </Tooltip>
                </Fragment>
            </>,
            cellStyle: {
                width: '20%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            headerStyle: {
                width: '20%'
            }
        },
        {
            title: 'Statut',
            field: 'status',

            render: (rowData) => <Fragment key={rowData.id} >
                <div className={ translateEnum(rowData.status) === enumStatus.ERROR ? 'error-status' : '' }>
                    { translateEnum(rowData.status) }
                    {
                        (
                            rowData.status === 'DONE' ||
                            rowData.status === 'REJECTED' ||
                            rowData.status === 'NEXT_STEP'
                        ) &&
                        <IconButton
                            onClick={() => {
                                setShowResultCandidate(true);
                                setActualCandidate(rowData);
                            }}
                        >
                            <VisibilityOutlinedIcon/>
                        </IconButton>
                    }
                </div>
            </Fragment>,
            cellStyle: {
                width: '15%',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                wordSpacing: 'normal'
            },
            customFilterAndSearch: (term, rowData) => (translateEnum(rowData.status).toLocaleLowerCase()
                .indexOf(term.toLocaleLowerCase()) !== -1),
            headerStyle: {
                width: '15%'
            }
        }
    ];

    return (
        <section>
            <div>
                <MaterialTable className="Table"
                    columns={columns}
                    data={allCandidates.sort((elem1, elem2) => (elem1.creationDate < elem2.creationDate) ? 1 : -1)}
                    title="Liste des Candidats"
                    isLoading={isFetchingCandidates && !allCandidates}
                    localization={{
                        'header': {
                            'actions': ''
                        },
                        'body': {
                            'emptyDataSourceMessage': (isFetchingCandidates && !allCandidates)
                                ? 'En attente de chargement...'
                                : 'Aucun candidat trouvé'
                        },
                        'pagination': {
                            'labelDisplayedRows': '{from}-{to} / {count}',
                            'labelRowsSelect': '',
                            'nextTooltip': 'Page suivante',
                            'previousTooltip': 'Page précédente',
                            'firstTooltip': 'Première page',
                            'lastTooltip': 'Dernière page'
                        },
                        'toolbar': {
                            'searchPlaceholder': 'Rechercher',
                            'searchTooltip': 'Rechercher'
                        }
                    }}
                />
                {showResultCandidate &&
                    <CandidateResult
                        open={showResultCandidate}
                        onClose={onCloseDialog}
                        candidate={actualCandidate}
                    />
                }
            </div>
        </section>
    );
}

CandidateTable.propTypes = {
    candidate: PropTypes.object
};
