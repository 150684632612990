export const WebSocketActionsEnum = {
    ADD: 'add',
    REMOVE: 'remove',
    QUIZ_ENDED: 'quizEnded',
    CHECK: 'check',
    QUIZ_STARTED: 'quizStarted',
    CURRENT_QUESTION_ID: 'currentQuestionId',
    CURRENT_QUESTION_VALIDATED: 'currentQuestionValidated',
    SEND_SERIE_SCORE: 'sendSerieScore',
    USER_ANSWERED_CURRENT_QUESTION: 'userAnsweredCurrentQuestion',
    SERIE_PARAMS: 'params'
};
