import {
    GET_CHAPTER_RESOURCES_SUCCESS,
    POST_CREATE_RESOURCE_SUCCESS
} from '../../../actions/Chapter/Resource/resource.actions';

export const chapterResources = 'chapterResources';

export default function chapterResourcesReducer(state = {
    [chapterResources]: []
}, action) {
    switch (action.type) {

        case GET_CHAPTER_RESOURCES_SUCCESS:
            return {
                ...state,
                [chapterResources]: [].concat(state.chapterResources, action.payload.filter((item) => {
                    for(const value of state.chapterResources) {
                        if(item.id === value.id) {
                            return false;
                        }
                    }
                    return true;
                }))
            };
        case POST_CREATE_RESOURCE_SUCCESS:
            return {
                ...state,
                [chapterResources]: [
                    ...state[chapterResources],
                    action.payload
                ]
            };
        default: return state;
    }
}
