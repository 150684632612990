import React from 'react';
import PropTypes from 'prop-types';

import { useDrag } from 'react-dnd';
import { AssignTypes } from 'app/utils/functions';

import { ListItem } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import QuestionRequestBySerieId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestBySerieId';
import { useDispatch } from 'react-redux';
import { getSerieByIdWithQuestionReduced } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';

export default function SerieDisplayDraggable(props) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.SERIE,
        item: {
            type: AssignTypes.SERIE,
            id: props.serie.id,
            serie: props.serie,
            name: props.serie.title,
            chapterId: props.chapterId
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging())
        })
    }), [props.serie.id]);

    const dispatch = useDispatch();

    const handleClickSerie = () => {
        dispatch(getSerieByIdWithQuestionReduced(props.serie.id));
    };

    return (
        <ListItem
            button
            disableRiple
            key={props.serie.id}
            ref={drag}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            data-testid={`serie-${props.serie.id}`}
            onClick={handleClickSerie}
        >
            <TreeItem
                label={props.serie.title}
                nodeId={`Series${props.node.content.id}-${props.serie.id}`}>
                <QuestionRequestBySerieId
                    serie={props.serie}
                    onClickQuestion={props.onClickQuestion}
                    selectedQuestion={props.selectedQuestion}
                />
            </TreeItem>
        </ListItem>

    );
}

SerieDisplayDraggable.propTypes = {
    node: PropTypes.object.isRequired,
    serie: PropTypes.object.isRequired,
    chapterId: PropTypes.number.isRequired,
    onClickQuestion: PropTypes.func.isRequired,
    selectedQuestion: PropTypes.number
};
