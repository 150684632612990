import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { CircularProgress, makeStyles } from '@material-ui/core';

import { resetStoredDataOnChange } from 'app/redux/actions/Group/group.actions';

import { selectGroups } from 'app/redux/selectors/Group/group.selector';
import { selectUserStudents } from 'app/redux/selectors/Coaching/coaching.selector';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import DrawerHistory from 'app/assignment/drawerHistory/DrawerHistory';
import QuestionListSummary from 'app/serie/ResolveSerie/QuestionListSummary/QuestionListSummary';
import TasksList from 'app/assignment/assignmentPanel/TasksList';
import WorkToDo from './options/WorkToDo';

import { OPTIONS } from './constants';
import { getAllStudentsOfGroupByGroupIdApi } from 'app/api/groupApi';
import ParcoursPanel from 'app/assignment/parcoursPanel/ParcoursPanel';
import { getQuestionHistoryByIdApi } from 'app/api/historyApi';
const DrawerSkills = lazy(() => import('./options/DrawerSkills/DrawerSkills'));
import { setQuestionSelectedByStudent, setQuestionSelectedHistory } from 'app/redux/actions/Question/question.actions';
import { chapterActions } from 'app/redux/slices/chapters.slice';

const useStyles = makeStyles(() => ({
    select: {
        width: '50%'
    },
    selectDiv: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 8px'
    }
}));

function UserLearning(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const studentList = useSelector(selectUserStudents);
    const groupList = useSelector(selectGroups);

    const [selectStudentList, setSelectStudentList] = useState([]);
    const [selectGroupList, setSelectGroupList] = useState([]);
    const [studentsByGroupId, setStudentsByGroupId] = useState(undefined);

    const getCurrentUserFirstName = () => AuthenticationContainer.getGivenName();
    const getCurrentUserLastName = () => AuthenticationContainer.getFamilyName();

    const disabledIfOptionSelected = () => props.options.optionSelected.label === OPTIONS.LISTE_DES_COMPETENCES ||
        props.options.optionSelected.label === OPTIONS.SERIE_EN_COURS;

    useEffect(() => {
        if(!groupList) {
            return;
        }
        const list = [...groupList];
        setSelectGroupList(list.map((group) => ({
            name: group.name,
            id: group.id,
            type: 'group'
        })));
    }, [groupList]);

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        selectedGroupWithStudent();
    }, [props.groupSelected]);

    useEffect(() => {
        const list = [
            ...studentList,
            {
                firstName: getCurrentUserFirstName(),
                lastName: getCurrentUserLastName(),
                id: props.options.userId
            }
        ];
        setSelectStudentList(list.map((student) => ({
            name: `${student.firstName} ${student.lastName}`,
            id: student.id,
            type: 'student'
        })));
    }, [studentList]);

    useEffect(() => {
        if(disabledIfOptionSelected()) {
            props.setGroupSelected(null);
            const studentUser = selectStudentList.find((student) => student.id === props.options.userId);
            props.setStudentSelected(studentUser);
        }
        dispatch(chapterActions.resetQuestionCount());
        dispatch(chapterActions.resetFilter());
    }, [props.options.optionSelected]);

    const groupedOptions = [
        {
            label: 'Mes groupes',
            options: [...selectGroupList]
        },
        {
            label: 'Mes élèves',
            options: [...selectStudentList]
        }
    ];

    const selectedGroupWithStudent = () => {
        if(props.groupSelected) {
            getAllStudentsOfGroupByGroupIdApi(props.groupSelected.id).then((response) => {
                setStudentsByGroupId({
                    ...props.groupSelected,
                    students: [...response.data]
                });
            });
        } else {
            setStudentsByGroupId(undefined);
        }
    };

    const handleOnClickHistoryQuestion = async(questionHistoryId) => {
        const questionHistory = await getQuestionHistoryByIdApi(questionHistoryId);
        const question = questionHistory?.data?.question;
        if(question) {
            dispatch(setQuestionSelectedByStudent({ questionId: question.id, studentId: props.options?.studentSelected?.id }));
            dispatch(setQuestionSelectedHistory(questionHistory?.data ?? null));
            props.onClickQuestionHistory(question);
        }
    };

    const taskList =
        <TasksList
            selectedStudentId={props.options.studentSelected?.id}
            selectedGroupId={props.groupSelected?.id}
            selectedGroup={studentsByGroupId}
        />;

    const switchOption = (option) => {
        switch (option.label) {
            case OPTIONS.TRAVAIL_A_FAIRE_DE:
                return <>
                    { props.options.studentSelected?.id === props.options.userId &&
                        <WorkToDo
                            isInteractiveQuiz={props.options.isInteractiveQuiz}
                        />
                    }
                    { taskList }
                </>;
            case OPTIONS.TRAVAIL_FAIT_PAR:
                return <DrawerHistory
                    selectedStudentId={props.options.studentSelected?.id}
                    selectedGroupId={props.groupSelected?.id}
                    selectedGroup={studentsByGroupId}
                    onClickQuestion={handleOnClickHistoryQuestion}
                    handleDateUpdated={props.handleDateUpdated}
                    handleFilterBySkill={props.handleFilterBySkill}
                    skillId={props.skillId}
                />;
            case OPTIONS.LISTE_DES_COMPETENCES:
                return <DrawerSkills />;
            case OPTIONS.SERIE_EN_COURS:
                return <QuestionListSummary
                    isInteractiveQuiz={props.options.isInteractiveQuiz}
                />;
            case OPTIONS.CHAPTERS_STUDENT_GROUP:
                return <>
                    {props.chapterCoaching}
                </>;
            case OPTIONS.PARCOURS:
                return <ParcoursPanel
                    selectedStudentId={props.options.studentSelected?.id}
                    selectedGroupId={props.groupSelected?.id}
                />;
            default:
                return <>{taskList}</>;
        }
    };

    const getHintSelect = () => props.groupSelected ? props.groupSelected : props.options.studentSelected;

    const handleChange = (selectedToken) => {
        if(selectedToken.type === 'student') {
            props.setStudentSelected(selectedToken);
            props.setGroupSelected(undefined);
        } else if(selectedToken.type === 'group') {
            props.setStudentSelected(null);
            props.setGroupSelected(selectedToken);
        }
        props.setType(selectedToken.type);
        dispatch(resetStoredDataOnChange());
    };

    return (
        <>
            <div className={classes.selectDiv}>
                <Select
                    aria-label="task select"
                    className={classes.select}
                    options={props.options.optionList}
                    onChange={props.handleChangeDrawerOptionDisplay}
                    value={props.options.optionSelected}
                />
                <Select
                    aria-label="recipient select"
                    className={classes.select}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isDisabled={disabledIfOptionSelected()}
                    onChange={handleChange}
                    options={groupedOptions}
                    placeholder="Assigner à"
                    value={getHintSelect()}
                />
                { props?.chevronLeft }
            </div>
            {(props.options.studentSelected || props.groupSelected) && (
                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>
                }>
                    {switchOption(props.options.optionSelected)}
                </Suspense>
            )}
        </>
    );
}

UserLearning.propTypes = {
    options: PropTypes.object.isRequired,
    chapterCoaching: PropTypes.element,
    chevronLeft: PropTypes.element,
    groupSelected: PropTypes.object,
    handleChangeDrawerOptionDisplay: PropTypes.func,
    setGroupSelected: PropTypes.func,
    setStudentSelected: PropTypes.func,
    setType: PropTypes.func,
    onClickQuestionHistory: PropTypes.func,
    handleDateUpdated: PropTypes.func,
    handleFilterBySkill: PropTypes.func,
    skillId: PropTypes.number
};

export default UserLearning;
