import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Grid, Paper, Tooltip, Typography, DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import './Popup.scss';

export class Popup extends Component {

    state = {
        openModal: false
    };

    openModal = () => {
        this.setState({ openModal: true });
    };

    closeModal = () => {
        this.setState({
            openModal: false
        });
    };

    render() {
        return (
            <>
                <span className="pointer" onClick={this.openModal}> et {this.props.number} autre(s)</span>

                <Dialog onClose={this.closeModal} aria-labelledby="simple-dialog-title" open={this.state.openModal}
                    scroll={'paper'}>
                    <DialogTitle id="simple-dialog-title">
                        {this.props.stack
                            ? `Stack (${this.props.data.length})`
                            : `Intervenants (${this.props.data.length})` }
                    </DialogTitle>
                    <Grid id="grid-container-popup" container spacing={2}>
                        {this.props.data.map((item, index) => this.props.stack
                            ? <Grid key={index} item
                                xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Paper id="grid-popup" elevation={4}>
                                    <Tooltip id="popup-tooltip" title={item.skillName}>
                                        <Typography noWrap><Link style={{ cursor: 'pointer',
                                            color: 'black',
                                            textDecoration: 'none' }} to={`/skills/${item.skillName}`}>
                                            {item.skillName} </Link></Typography>
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            : <Grid key={index} item
                                xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Paper id="grid-popup" elevation={4}>
                                    <Tooltip id="popup-tooltip" title={`${item.firstName} ${item.lastName}`}>
                                        <Typography noWrap><Link style={{ cursor: 'pointer',
                                            color: 'black',
                                            textDecoration: 'none' }} to={`/excilyens/${item.id}`}>
                                            {`${item.firstName} ${item.lastName}`} </Link></Typography>
                                    </Tooltip>
                                </Paper>
                            </Grid>)}
                    </Grid>
                    <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

Popup.propTypes = {
    data: PropTypes.array,
    number: PropTypes.number,
    stack: PropTypes.bool
};
