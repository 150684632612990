import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './PlayChapterQuestionsDisplay.scss';

import {
    Checkbox, FormControlLabel, InputAdornment, Select,
    TextField, Button, makeStyles, Paper, Popover, MenuItem
} from '@material-ui/core';
import { languageOptions } from 'app/utils/LanguageUtils';
import { QUIZ_URL } from 'app/Routes';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { mapLocalTimeToDuration } from '../../../../../utils/date.functions';
import { timerCantBeSetToZero } from '../../../../../question/createEditSerie/CreateEditSerie.functions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    menuPlay: {
        padding: '14px 16px 6px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    checkboxPadding: {
        padding: '8px !important'
    },
    displayFlexColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px'
    }
}));

function PlayChapterQuestionsDisplay(props) {

    const defaultNumberQuestion = 10;

    const classes = useStyles();

    const history = useHistory();

    const withoutTimerHelperText = 'Pas de chronomètre. (--:-- pour infini)';
    const withTimerHelperText = 'Série chronométrée. (--:-- pour infini)';

    const [isQuiz, setIsQuiz] = useState(false);
    const [timerHelperText, setTimerHelperText] = useState('');

    const fillNbQuestions = () => {
        if(defaultNumberQuestion > props.nbMaxQuestions) {
            props.onChangeNbQuestions(props.nbMaxQuestions);
        } else {
            props.onChangeNbQuestions(defaultNumberQuestion);
        }
    };

    useEffect(() => {
        fillNbQuestions();
    }, [props.nbMaxQuestions]);

    const handleClose = () => {
        fillNbQuestions();
        props.onClose();
    };

    const handleValidate = () => {
        props.onValidate();
        props.onClose();
    };

    const handleValidateInteractiveQuiz = () => {
        if(props.onValidateInteractiveQuiz) {
            props.onValidateInteractiveQuiz();
        }
        props.onClose();
        history.push(`${QUIZ_URL}/${props.quizUuid}`);
    };

    const validateInput = () => (
        Number(props.nbQuestions) &&
        props.nbQuestions > 0 &&
        props.nbQuestions <= props.nbMaxQuestions
    );

    const timerValidation = (timerValue) => {
        if(timerValue && timerValue !== '' && timerValue !== '00:00') {
            return mapLocalTimeToDuration(timerValue);
        }
        return null;
    };

    useEffect(() => {
        if(props.maxDuration === '') {
            setTimerHelperText(withoutTimerHelperText);
        } else if(timerValidation(props.maxDuration) === null) {
            setTimerHelperText(timerCantBeSetToZero);
        } else {
            setTimerHelperText(withTimerHelperText);
        }
    }, [props.maxDuration]);

    return (
        <Popover
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={handleClose}
            disableScrollLock
        >
            <Paper className={classes.menuPlay}>
                <h3>{ props.headerTitle || 'Démarrer une série de questions'}</h3>
                <div>
                    <label>Langue :</label>
                    <Select
                        value={props.language}
                        onChange={props.onChangeLanguage}
                        className="languageSelect"
                    >
                        {languageOptions.map((option) => <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>)}
                    </Select>
                </div>
                <div className={classes.displayFlexColumn}>
                    <FormControlLabel
                        label="Inclure les sous-chapitres"
                        control={
                            <Checkbox
                                className={classes.checkboxPadding}
                                color="primary"
                                checked={props.withChildren}
                                onChange={props.onChangeWithChildren}
                            />
                        }
                    />
                    { props.setSameQuestions &&
                        <FormControlLabel
                            label="Même questions pour tous"
                            control={
                                <Checkbox
                                    className={classes.checkboxPadding}
                                    color="primary"
                                    checked={props.sameQuestions}
                                    onChange={() => props.setSameQuestions((prev) => !prev)}
                                />
                            }
                        />
                    }
                    <FormControlLabel
                        label="Série Aléatoire"
                        control={
                            <Checkbox
                                className={classes.checkboxPadding}
                                color="primary"
                                checked={props.isRandomSerie}
                                onChange={props.setIsRandomSerie}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Mode Examen"
                        control={
                            <Checkbox
                                className={classes.checkboxPadding}
                                color="primary"
                                checked={props.isExamMode}
                                onChange={props.onSetExamMode}
                            />
                        }
                    />
                    {<FormControlLabel
                        label="Quiz Interactif"
                        control={
                            <Checkbox
                                className={classes.checkboxPadding}
                                color="primary"
                                checked={isQuiz}
                                onChange={() => setIsQuiz((prev) => !prev)}
                            />
                        }
                    />}
                </div>

                <TextField
                    data-testid="timer-field"
                    name="maxDuration"
                    helperText={timerHelperText}
                    type="time"
                    margin="normal"
                    required
                    value={props.maxDuration}
                    onChange={(event) => props.onChangeMaxDuration(event.target.value)}
                />

                <TextField
                    data-testid="input-nb-questions"
                    variant="outlined"
                    autoFocus
                    error={!validateInput()}
                    value={props.nbQuestions}
                    onChange={(event) => props.onChangeNbQuestions(event.target.value)}
                    onKeyDown={(event) => {
                        event.stopPropagation();
                        if(event.key === 'Enter' && validateInput()) {
                            if(isQuiz) {
                                handleValidateInteractiveQuiz();
                            } else {
                                handleValidate();
                            }
                        }
                    }}
                    InputProps={{
                        startAdornment: <></>,
                        endAdornment: <InputAdornment position="end"> /{props.nbMaxQuestions} Questions</InputAdornment>
                    }}
                />
                <div className="button-container">
                    <Button
                        color="primary"
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    {isQuiz
                        ? (
                            <Link to={`${QUIZ_URL}/${props.quizUuid}`}>
                                <Button
                                    color="primary"
                                    disabled={!validateInput()}
                                    onClick={handleValidateInteractiveQuiz}
                                > Valider </Button>
                            </Link>
                        )
                        : (
                            <Button
                                color="primary"
                                onClick={handleValidate}
                                disabled={!validateInput()}
                            > Valider </Button>
                        )}
                </div>
            </Paper>
        </Popover>
    );
}

PlayChapterQuestionsDisplay.propTypes = {
    headerTitle: PropTypes.string,
    anchorEl: PropTypes.object,
    nbQuestions: PropTypes.string.isRequired,
    withChildren: PropTypes.bool.isRequired,
    nbMaxQuestions: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValidateInteractiveQuiz: PropTypes.func,
    onChangeWithChildren: PropTypes.func.isRequired,
    onChangeNbQuestions: PropTypes.func.isRequired,
    sameQuestions: PropTypes.bool,
    setSameQuestions: PropTypes.func,
    isRandomSerie: PropTypes.bool,
    setIsRandomSerie: PropTypes.func,
    language: PropTypes.string,
    onChangeLanguage: PropTypes.func,
    isExamMode: PropTypes.bool,
    onSetExamMode: PropTypes.func,
    quizUuid: PropTypes.string,
    maxDuration: PropTypes.string,
    onChangeMaxDuration: PropTypes.func
};

export default PlayChapterQuestionsDisplay;
