import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './QuestionReportingButton.scss';

import ReportIcon from '@material-ui/icons/Report';
import {
    RadioGroup, Radio,
    BottomNavigation, BottomNavigationAction,
    Button, Dialog, DialogContent, DialogTitle,
    TextField, FormLabel, makeStyles, FormControl,
    FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { postQuestionReporting } from 'app/redux/actions/Question/QuestionReporting/questionReporting.actions';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { getCurrentUser } from 'app/redux/actions/User/user.actions';

const useStyles = makeStyles(() => ({
    textFieldContainerWithOption: {
        width: '50%'
    },
    textFieldContainerWithoutOption: {
        width: '100%'
    }
}));

function QuestionReportingDialog(props) {

    const errorEnum = {
        INVALID: 0,
        BAD_DISPLAY: 1,
        BAD_ASSIGN: 2,
        OTHER: 3
    };

    const errorType = [
        'Question non valide',
        'Question mal affichée',
        'Question mal assignée',
        'Autre problème'
    ];

    const questionInvalidOption = [
        'la formulation de la question',
        'le code lié à la question',
        'une ou plusieurs réponses',
        'l\'explication de la question'
    ];

    const questionDisplayedOption = [
        'Problème dans l\'affichage',
        'Faute d\'orthographe dans l\'intitulé de la question',
        'Faute d\'orthographe dans les réponses de la question',
        'Faute d\'orthographe dans l\'explication de la question'
    ];

    const questionAssignedOption = [
        'Question assignée à la mauvaise compétence',
        'Question assignée à un mauvais chapitre'
    ];

    const classes = useStyles();

    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);

    const [errorValue, setErrorValue] = useState(null);
    const [optionSelected, setOptionSelected] = useState(null);
    const [commentary, setCommentary] = useState('');

    useEffect(() => {
        if(currentUser.id === null || currentUser.id === undefined) {
            dispatch(getCurrentUser());
        }
    }, []);

    const handleClose = () => {
        props.setIsOpen(false);
        setErrorValue(null);
        setOptionSelected(null);
        setCommentary('');
    };

    const handleChangeErrorType = (event, newValue) => {
        setErrorValue(newValue);
        setOptionSelected(null);
    };

    const handleChangeRadio = (event) => {
        setOptionSelected(event.currentTarget.value);
    };

    const handleChangeCommentary = (event) => {
        setCommentary(event.target.value);
    };

    const validReport = () => {
        let description = null;
        if(optionSelected) {
            if(errorValue === errorEnum.INVALID) {
                description = `Erreur dans ${optionSelected}`;
            } else {
                description = optionSelected;
            }
        }
        if(commentary) {
            if(description) {
                description += `\n${commentary.trim()}`;
            } else {
                description = commentary.trim();
            }
        }
        const questionReporting = {
            description,
            questionId: props.questionId,
            reporterId: currentUser.id,
            status: 'PENDING'
        };
        dispatch(postQuestionReporting(questionReporting));
        handleClose();
    };

    const getOptions = () => {
        switch (errorValue) {
            case errorEnum.INVALID:
                return questionInvalidOption;
            case errorEnum.BAD_DISPLAY:
                return questionDisplayedOption;
            case errorEnum.BAD_ASSIGN:
                return questionAssignedOption;
            default:
                return [];
        }
    };

    const isValidCommentaryForOtherError = () => (
        commentary && commentary.trim()
    );

    const isValidCommentaryForOption = () => (
        !commentary || commentary.trim()
    );

    const isDisabled = () => (
        (errorValue !== errorEnum.OTHER &&
            (optionSelected === null || !isValidCommentaryForOption())) ||
        (errorValue === errorEnum.OTHER && !isValidCommentaryForOtherError())
    );

    return (
        <Dialog
            className="reporting-dialog"
            maxWidth="md"
            fullWidth
            fullScreen={false}
            onClose={handleClose}
            aria-labelledby="simple-dialog-reporting"
            open={props.isOpen}
            scroll={'body'}
            PaperProps={{ style: { overflowY: 'visible' } }}
        >
            <DialogTitle
                id="simple-dialog-title">
                    Reporter un problème
            </DialogTitle>
            <DialogContent
                dividers
                style={{ overflowY: 'visible' }}
            >
                <BottomNavigation
                    value={errorValue}
                    showLabels
                    onChange={handleChangeErrorType}
                    aria-labelledby="testError"
                >
                    {errorType.map((error, index) => (
                        <BottomNavigationAction
                            key={index}
                            label={error}
                            icon={<ReportIcon />}
                        />
                    ))}
                </BottomNavigation>
                {errorValue !== null &&
                        <div className="container">
                            {errorValue !== errorEnum.OTHER &&
                                <FormControl className={classes.textFieldContainerWithOption}>
                                    {errorValue === errorEnum.INVALID &&
                                        <FormLabel>Erreur dans</FormLabel>
                                    }
                                    <RadioGroup
                                        value={optionSelected}
                                        onChange={handleChangeRadio}
                                    >
                                        {getOptions().map((option, index) => (
                                            <FormControlLabel
                                                key={index}
                                                label={option}
                                                value={option}
                                                control={<Radio color="primary"
                                                    aria-labelledby="optionError" />}
                                            />
                                        ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            }
                            <TextField
                                className={
                                    errorValue === errorEnum.OTHER
                                        ? classes.textFieldContainerWithoutOption
                                        : classes.textFieldContainerWithOption
                                }
                                fullWidth
                                multiline
                                label="Commentaire"
                                helperText={
                                    `Commentaire ${errorValue === errorEnum.OTHER ? 'obligatoire' : 'optionnel'}`
                                }
                                variant="filled"
                                minRows="6"
                                onChange={handleChangeCommentary}
                            />
                        </div>
                }
                <div className="buttonDialogContainer">
                    <Button
                        color="primary"
                        onClick={validReport}
                        disabled={isDisabled()}
                        aria-label="valid"
                    >
                            Valider
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleClose}
                    >
                            Annuler
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

QuestionReportingDialog.propTypes = {
    questionId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func
};

export default QuestionReportingDialog;
