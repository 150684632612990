import { Chapter } from 'app/redux/models/Chapter.model';

// From https://www.30secondsofcode.org/articles/s/js-data-structures-tree
interface Node {
    key: string;
    value: string;
    label: string;
    content: Chapter;
    parent: Node | null;
    children: Node[];
    isLeaf: boolean;
    hasChildren: boolean;
}

export default class TreeNode implements Node {
    key: string;

    value: string;

    label: string;

    content: Chapter;

    parent: Node | null;

    children: Node[];

    constructor(key: string, content: Chapter, parent: TreeNode | null = null) {
        this.key = key;
        this.value = key;
        this.label = content.name;
        this.content = content;
        this.parent = parent;
        this.children = [];
    }

    get isLeaf() {
        return this.children.length === 0;
    }

    get hasChildren() {
        return !this.isLeaf;
    }
}
