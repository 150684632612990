import Dialog from '@material-ui/core/Dialog/Dialog';
import React, { Component } from 'react';
import List from '@material-ui/core/List/List';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ReactStars from 'react-stars';
import './SkillLevelHelpDialog.scss';
import { Tooltip, IconButton, Icon } from '@material-ui/core';

const levels = [
    {
        range: 1,
        label: 'Théorie',
        description: 'Vous débutez sur le sujet; votre connaissance est faible.'
    },
    {
        range: 2,
        label: 'Connaissances',
        description: 'Vous maîtrisez les bases et vous pouvez poser une question pertinente sur le sujet.'
    },
    {
        range: 3,
        label: 'Pratiqué',
        description: 'Vous pouvez travailler seul. Vous pouvez répondre à des questions, savoir où trouver des ressources, etc.'
    },
    {
        range: 4,
        label: 'Maîtrisé',
        description: 'Vous pouvez donner des conseils à quelqu\'un ou enseigner.'
    },
    {
        range: 5,
        label: 'Expert',
        description: 'Vous connaissez l\'essentiel de ce qu\'il y a à savoir sur le domaine.'
    }
];

export class SkillLevelHelpDialog extends Component {

    state = {
        openDialog: false
    };

    handleOpen = () => {
        this.setState({
            ...this.state,
            openDialog: true
        });
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            openDialog: false
        });
    };

    render() {

        return <div data-testid="level-help">
            <Tooltip title="Aide" aria-label="aide">
                <IconButton id="button-help-skill-level" data-testid="icon-help" onClick={this.handleOpen}>
                    <Icon>help_outline</Icon>
                </IconButton>
            </Tooltip>

            <Dialog aria-labelledby="dialog-title" open={this.state.openDialog} onClose={this.handleClose}>
                <DialogTitle data-testid="dialog" id="dialog-title">Que signifie le niveau de compétences ?</DialogTitle>
                <List>
                    {levels.map((level) => <ListItem style={{ 'display': 'block' }} key={level.range}>
                        <ListItemAvatar>
                            <ReactStars count={5} value={level.range} size={24} edit={false} color2={'#ffd700'}/>
                        </ListItemAvatar>
                        <ListItemText className="skill-level-description" primary={level.label} secondary={level.description} />
                    </ListItem>)}
                </List>
                <DialogActions>
                    <Button onClick={this.handleClose} data-testid="button-close" color="primary" className="close-button">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;

    }
}

export default SkillLevelHelpDialog;
