import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './Popup.scss';
import './MissionDetailsPopup.scss';

export class MissionDetailsPopup extends Component {
    state = {
        open: false
    };

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    displayDate = (dateParams) => {
        const date = new Date(dateParams);
        return (
            <>
                {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </>
        );
    };

    render() {
        const { mission } = this.props;

        return (
            <>
                <span
                    className="pointer button-see-more"
                    id="color"
                    onClick={this.openModal}
                >
                    <i>Voir plus</i>
                </span>
                <Dialog
                    onClose={this.closeModal}
                    aria-labelledby="simple-dialog-title"
                    open={this.state.open}
                    scroll={'paper'}
                >
                    <DialogContent>
                        <Grid container direction="row" justifyContent="center" alignItems="center"><h2>Détails de la mission</h2></Grid>
                        <br/>
                        <Grid className="mission-details-popup" container direction="row">
                            <Grid
                                item
                                xs
                                container
                                direction="column"
                                spacing={1}>
                                <Grid item container direction="row">
                                    <h3 className="field">Description :</h3>
                                    <Grid item container direction="row">
                                        <div
                                            className="description-mission"
                                            dangerouslySetInnerHTML={{ __html: mission.description }}/>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row">
                                    <h3 className="field">Date de début</h3>
                                    <Chip
                                        className="date-chip"
                                        size="small"
                                        label={mission.startDate
                                            ? this.displayDate(mission.startDate)
                                            : <i>Non renseignée</i>}/>
                                </Grid>
                                <br/>
                                <Grid item container direction="row">
                                    <h3 className="field">Date de fin</h3>
                                    <Chip
                                        className="date-chip"
                                        size="small"
                                        label={mission.endDate
                                            ? this.displayDate(mission.endDate)
                                            : <i>Non renseignée</i>}/>
                                </Grid>
                                <Grid item container direction="row">
                                    <h3 className="stack-users-field">Intervenants</h3>
                                    <Grid item container direction="row">
                                        {mission.listUser &&
                                        mission.listUser.map((user, index) => (
                                            <Grid className="skill-user-field"
                                                key={index}
                                                item
                                                xs={mission.listUser.length === 1 ? 12 : 6}
                                                sm={mission.listUser.length === 1 ? 12 : 6}>
                                                <Paper id="grid-popup" elevation={4}>
                                                    <Tooltip
                                                        id="popup-tooltip"
                                                        title={`${user.firstName} ${user.lastName}`}>
                                                        <Typography noWrap>
                                                            <Link
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: 'black',
                                                                    textDecoration: 'none'
                                                                }}
                                                                to={`/excilyens/${user.id}`}
                                                            >
                                                                {`${user.firstName} ${user.lastName}`}{' '}
                                                            </Link>
                                                        </Typography>
                                                    </Tooltip>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row">
                                    <h3 className="stack-users-field">Stack de mission</h3>
                                    <Grid item container direction="row">
                                        {mission.listSkill &&
                                        mission.listSkill.map((skill, index) => (
                                            <Grid className="skill-user-field"
                                                key={index}
                                                item
                                                xs={mission.listSkill.length === 1 ? 12 : 6}
                                                sm={mission.listSkill.length === 1 ? 12 : 6}>
                                                <Paper id="grid-popup" elevation={4}>
                                                    <Tooltip
                                                        id="popup-tooltip"
                                                        title={`${skill.firstName} ${skill.lastName}`}
                                                    >
                                                        <Typography noWrap>
                                                            <Link
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: 'black',
                                                                    textDecoration: 'none'
                                                                }}
                                                                to={`/skills/${skill.skillName}`}
                                                            >
                                                                {skill.skillName}{' '}
                                                            </Link>
                                                        </Typography>
                                                    </Tooltip>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button className="close-button" onClick={this.closeModal} color="primary">
                        Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

MissionDetailsPopup.propTypes = {
    mission: PropTypes.object,
    listUser: PropTypes.array
};
