import axiosClient from './utils/axiosClient';

export const CANDIDATE_URI = 'candidates';


export function createCandidateApi(candidate) {
    return axiosClient.put(CANDIDATE_URI, candidate);
}

export function getAllCandidateApi() {
    return axiosClient.get(CANDIDATE_URI);
}

export function getCandidateByUuidApi(uuid) {
    return axiosClient.get(`${CANDIDATE_URI}/uuid/${uuid}`);
}

export function patchStatusApi(uuid, status) {
    return axiosClient.patch(`${CANDIDATE_URI}/status/${uuid}?status=${status}`);
}

export function patchCandidateByUuidApi(uuid) {
    return axiosClient.patch(`${CANDIDATE_URI}/uuid/${uuid}`);
}
