
export const HeaderParams = {
    DRAWER: 'drawer',
    TITLE: 'title',
    CLOCHE: 'cloche',
    FILTER: 'filter',
    SEARCH: 'search',
    RIGHT_NAV_BAR: 'rightNavBar',
    MENU: 'menu'
};

export const HeaderParamsPresets = {
    searchPage: [
        HeaderParams.DRAWER,
        HeaderParams.TITLE,
        HeaderParams.CLOCHE,
        HeaderParams.FILTER,
        HeaderParams.SEARCH,
        HeaderParams.RIGHT_NAV_BAR,
        HeaderParams.MENU
    ],
    filterPage: [
        HeaderParams.DRAWER,
        HeaderParams.TITLE,
        HeaderParams.CLOCHE,
        HeaderParams.FILTER,
        HeaderParams.RIGHT_NAV_BAR,
        HeaderParams.MENU
    ],
    defaultPage: [
        HeaderParams.DRAWER,
        HeaderParams.TITLE,
        HeaderParams.CLOCHE,
        HeaderParams.RIGHT_NAV_BAR,
        HeaderParams.MENU
    ],
    candidatePage: [
        HeaderParams.DRAWER,
        HeaderParams.TITLE
    ]
};
