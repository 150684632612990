import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './User.scss';
import ProfileContent from './ProfileContent';

export class User extends Component {

    render() {
        return (
            <section className="user" data-testid="user">
                <ProfileContent/>
            </section>
        );
    }
}

User.propTypes = {
    match: PropTypes.object
};

export default withRouter(User);
