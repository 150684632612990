import { getDailyQuestionsApi } from '../../../../../api/dailyQuestionsApi';

import { notificationError } from '../../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_DAILY_QUESTIONS_ERROR
} from '../../../../../Snackbar/NotificationMessages';

export const GET_DAILY_QUESTIONS = 'GET_DAILY_QUESTIONS';
export const GET_DAILY_QUESTIONS_REQUEST = 'GET_DAILY_QUESTIONS_REQUEST';
export const GET_DAILY_QUESTIONS_FAILURE = 'GET_DAILY_QUESTIONS_FAILURE';
export const GET_DAILY_QUESTIONS_SUCCESS = 'GET_DAILY_QUESTIONS_SUCCESS';

export const RESET_DAILY_QUESTIONS = 'RESET_DAILY_QUESTIONS';

export const getDailyQuestionsToDo = (userId) => (dispatch) => {
    dispatch({ type: GET_DAILY_QUESTIONS_REQUEST });

    return getDailyQuestionsApi(userId).then((response) => {
        dispatch({
            type: GET_DAILY_QUESTIONS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_DAILY_QUESTIONS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_DAILY_QUESTIONS_ERROR));
        }
    });
};

export const resetDailyQuestions = () => (dispatch) => {
    dispatch({ type: RESET_DAILY_QUESTIONS });
};
