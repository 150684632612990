import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, withRouter } from 'react-router-dom';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import ResolveSerieRequestBySerieHistoryId from './ResolveSerie/ResolveSerieRequestBySerieHistoryId';

function SerieNotDoneResolveRoute(props) {

    const serieHistoryId = Number(props.match.params.historyId);
    const serieId = Number(props.match.params.id);

    return (
        <>
            {(serieHistoryId > 0)
                ? <ResolveSerieRequestBySerieHistoryId
                    serieHistoryId={serieHistoryId}
                    serieId={serieId}
                />
                : <Redirect to={QUESTIONS_PAGE_URL} />
            }
        </>
    );
}

SerieNotDoneResolveRoute.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(SerieNotDoneResolveRoute);
