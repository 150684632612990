
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth/withWidth';
import ProjectsTable from '../ProjectTable/ProjectsTable';
import ClientsTable from '../../Client/ClientTable/ClientsTable';
import ClientsMap from '../../Client/ClientMap/ClientsMap';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { getAllClients } from '../../../../redux/actions/Client/clients.actions';
import { getAllSectors } from '../../../../redux/actions/Sector/sector.actions';
import SidePanelProjects from '../../../../components/sidePanel/projects/SidePanelProjects';
import SidePanelClients from '../../../../components/sidePanel/clients/SidePanelClients';
import { selectDisplayLeftDrawer } from '../../../../redux/selectors/Display/displaySelector';
import { setDisplayLeftDrawer } from '../../../../redux/actions/Display/display.actions';
import './ProjectsContent.scss';
import '../Projects.scss';
import '../../Client/ClientMap/ClientsMap.scss';
import AddEditProject from '../AddEditProject/AddEditProject';
import { getAllUsers } from '../../../../redux/actions/User/user.actions';
import { skillActions } from 'app/redux/slices/skills.slice';
import { Tabs, Tab } from '@material-ui/core';
import AddEditClient from '../../Client/AddEditClient/AddEditClient';
import grey from '@material-ui/core/colors/grey';
import ListIcon from '@material-ui/icons/List';
import ExploreIcon from '@material-ui/icons/Explore';
import { smallScreenBreakpoints } from 'app/utils/smallScreenBreakpoints';


export class ProjectsContent extends Component {

    state = {
        value: 0,
        view: 0
    };

    componentDidMount() {
        this.props.getAllClients();
        this.props.getAllUsers();
        this.props.getAllSkillSet();
        this.props.getAllSectors();
    }

    toggleCloseLeftDrawer = () => {
        this.props.setDisplayLeftDrawer(false);
    };

    toggleOpenLeftDrawer = () => {
        this.props.setDisplayLeftDrawer(true);
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChange2 = (event, newValue) => {
        this.setState({ view: newValue });
    };


    render() {

        return (
            <div >
                <div className="tab-projects" >
                    <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="primary">
                        <Tab label="Projets" id="0"/>
                        <Tab label="Clients" id="1"/>
                    </Tabs>
                    {this.state.value === 1 &&
                    <div className="tab-listMap">
                        <Tabs value={this.state.view} onChange={this.handleChange2} indicatorColor="secondary"
                            style={{ height: '10px' }}>
                            <Tab icon={<ListIcon/>} tabIndex="0" style={{
                                color: grey[100],
                                minWidth: '50%'
                            }}/>
                            <Tab icon={<ExploreIcon/>} tabIndex="1" style={{
                                color: grey[100],
                                minWidth: '50%'
                            }}/>
                        </Tabs>
                    </div>
                    }
                </div>
                <div hidden={this.state.value !== 0}>
                    <div className="project-table">
                        <Grid container>
                            <Grid container item md={3} lg={2} id="project-filter" className="desktop-side-panel-project"
                                justifyContent="flex-start" alignItems="flex-start">
                                <Hidden only={smallScreenBreakpoints}>
                                    <div className="filter">
                                        <SidePanelProjects/>
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid container xs={12} md={9} lg={10} item className="desktop-side-panel-project">
                                <Grid
                                    xs={12} sm={12} lg={12} item>
                                    <AddEditProject trueIfAddAndFalseIfEdit={true} width={this.props.width}/>
                                    <ProjectsTable/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <SwipeableDrawer
                            open={this.props.displayLeftDrawer}
                            anchor="left"
                            onOpen={this.toggleOpenLeftDrawer}
                            onClose={this.toggleCloseLeftDrawer}
                        >
                            <div id="leftDrawer">
                                <IconButton onClick={this.toggleCloseLeftDrawer} className="drawer-icon">
                                    <Icon>chevron_right</Icon>
                                </IconButton>
                                <SidePanelProjects/>
                            </div>
                        </SwipeableDrawer>
                    </div>
                </div>
                <div hidden={this.state.value !== 1}>
                    <div className="project-table">
                        <Grid container>
                            <Grid container item md={3} lg={2} id="client-filter" className="desktop-side-panel-project">
                                <Hidden only={smallScreenBreakpoints}>
                                    <SidePanelClients />
                                </Hidden>
                            </Grid>
                            <Grid container xs={12} md={9} lg={10} item className="desktop-side-panel-project">
                                <Grid xs={12} sm={12} lg={12} item>
                                    {this.state.view === 0 &&
                                    <AddEditClient trueIfAddAndFalseIfEdit={true} width={this.props.width} />}
                                    {this.state.view === 0
                                        ? <ClientsTable />
                                        : <ClientsMap />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden mdUp>
                            <SwipeableDrawer
                                open={this.props.displayLeftDrawer}
                                anchor="left"
                                onOpen={this.toggleOpenLeftDrawer}
                                onClose={this.toggleCloseLeftDrawer}
                            >
                                <div id="leftDrawer">
                                    <IconButton onClick={this.toggleCloseLeftDrawer} className="drawer-icon">
                                        <Icon>chevron_left</Icon>
                                    </IconButton>
                                    <SidePanelProjects/>
                                </div>
                            </SwipeableDrawer>
                        </Hidden>
                    </div>
                </div>
            </div>
        );
    }
}

ProjectsContent.propTypes = {
    displayLeftDrawer: PropTypes.bool,
    width: PropTypes.string,
    setDisplayLeftDrawer: PropTypes.func,
    getAllClients: PropTypes.func,
    getAllUsers: PropTypes.func,
    getAllSkillSet: PropTypes.func,
    getAllSectors: PropTypes.func
};

function mapStateToProps(state) {
    return {
        displayLeftDrawer: selectDisplayLeftDrawer(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setDisplayLeftDrawer: (display) => dispatch(setDisplayLeftDrawer(display)),
        getAllClients: () => dispatch(getAllClients()),
        getAllUsers: () => dispatch(getAllUsers()),
        getAllSkillSet: () => dispatch(skillActions.getAllSkills()),
        getAllSectors: () => dispatch(getAllSectors())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(withRouter(ProjectsContent)));
