import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TreeItem } from '@material-ui/lab';
import { List, ListItemText, makeStyles } from '@material-ui/core';
import { BookOutlined, HelpOutline, ListAlt } from '@material-ui/icons';
import { AssignTypes } from 'app/utils/functions';
import { setSelectedChapterId } from 'app/redux/actions/Chapter/chapter.actions';
import {
    QUESTIONS_PAGE_URL,
    SERIE_RESOLVE_URL,
    SKILL_PAGE_URL
} from 'app/Routes';
import colors from 'styles/colors.module.scss';
import SchoolIcon from '@material-ui/icons/School';
import { getStepContentByStepIdApi } from 'app/api/parcoursApi';
import valid from 'assets/images/valid.svg';
import cadenas from 'assets/images/cadenas.svg';
import sablier from 'assets/images/sablier.svg';

const useStyles = makeStyles(() => ({
    examMode: {
        height: '0.9em',
        marginLeft: '8px',
        verticalAlign: 'text-bottom', 
    },
    step: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '25px'
    },
    stepContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '25px'
    }
}));

function ParcoursStepTreeItem(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const handleClickStep = (stepId) => {
        if(props.stepContent === undefined || props.needRefresh) {
            getStepContentByStepIdApi(stepId).then((response) => {
                props.setStepContent((prevStepContent) => new Map(prevStepContent.set(stepId, response.data)));
                props.setNeedRefresh(false);
            });
        }
    };

    const handleClickStepContent = (parcoursId, content) => {
        let url = '';
        switch ((content.type).toLowerCase()) {
            case AssignTypes.QUESTION:
                url = `${QUESTIONS_PAGE_URL}/resolve/${content.contentId}?parcoursId=${parcoursId}`;
                break;
            case AssignTypes.SERIE:
                url = `${SERIE_RESOLVE_URL}/${content.contentId}?parcoursId=${parcoursId}`;
                break;
            case AssignTypes.CHAPTER:
                dispatch(setSelectedChapterId({ chapterId: content.contentId, skillId: content.skillId }));
                url = `${SKILL_PAGE_URL}/${content.skillName}`;
                break;
            default:
                url = '';
        }
        history.push(url);
    };

    const renderContent = (parcoursId, content) => {
        switch ((content.type).toLowerCase()) {
            case AssignTypes.QUESTION:
                return (
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleClickStepContent(parcoursId, content)}>
                        <HelpOutline size="small" style={{ color: 'grey', marginLeft: '10px', marginRight: '10px' }} />
                        <ListItemText primary={`${content.skillName}#${content.contentId}`} />
                    </div>
                );
            case AssignTypes.CHAPTER:
                return (
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleClickStepContent(parcoursId, content)}>
                        <BookOutlined size="small" style={{ color: 'grey', marginLeft: '10px', marginRight: '10px' }} />
                        <ListItemText primary={`${content.name}`} />
                    </div>
                );
            case AssignTypes.SERIE:
                return (
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleClickStepContent(parcoursId, content)}>
                        <ListAlt size="small" style={{ color: 'grey', marginLeft: '10px', marginRight: '10px' }} />
                        <ListItemText primary={`${content.name}`} />
                    </div>
                );
            default:
                return null;
        }
    };

    const isAssignedParcours = () => props.parcoursType === 'ASSIGNED' && props.statusParcours;

    const renderStatus = (content, index) => {
        if(props.statusParcours.currentStepId === props.step?.id && content.id === props.statusParcours?.currentStepContentId && !props.statusParcours.done) {
            return <img id="logo" src={sablier} className={classes.stepContent} />;
        }
        if(props.statusParcours.done 
            || (props.statusParcours.currentStepId === props.step?.id && (index < props.currentStepContent?.position)) || props.step?.position < props.currentStep?.position ) {
            return <img id="logo" src={valid} className={classes.stepContent} />;
        }
        return <img id="logo" src={cadenas} className={classes.stepContent} />;
    };

    useEffect(() => {
        if(isAssignedParcours()) {
            if(props.statusParcours.currentStepId === props.step?.id) {
                props.setCurrentStep(props.step);
            }
        }
    }, [props.step, props.statusParcours]);

    useEffect(() => {
        if(isAssignedParcours()) {
            if(props.stepContent && props.statusParcours.currentStepId === props.step?.id) {
                const stepContent = props.stepContent.find((content) => content.id === props.statusParcours?.currentStepContentId);
                props.setCurrentStepContent(stepContent);
            }
        }
    }, [props.stepContent, props.statusParcours]);

    return (
        <div>
            <TreeItem
                nodeId={`step ${props.step?.id.toString()}`}
                label={<div className={classes.step}>
                    {props.step?.name}
                    {props.step?.examMode && <SchoolIcon className={classes.examMode} />}
                    {   isAssignedParcours() && (
                        props.statusParcours.currentStepId === props.step?.id && !props.statusParcours.done
                            ? <img id="logo" src={sablier} />
                            : props.step?.position < props.currentStep?.position || props.statusParcours.done
                                ? <img id="logo" src={valid} />
                                : <img id="logo" src={cadenas} />)
                    }
                </div>}
                onClick={() => handleClickStep(props.step?.id)}
            >
                <List dense>
                    {props.stepContent && props.stepContent.length > 0
                        ? props.stepContent.map((content, index) => (
                            <div key={content.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {renderContent(props.parcoursId, content)}
                                { isAssignedParcours() && renderStatus(content, index)}
                            </div>
                        ))
                        : <p style={{ color: colors['grey-color-500'] }}>Cette étape est vide</p>
                    }

                </List>
            </TreeItem>
        </div>
    );
}

ParcoursStepTreeItem.propTypes = {
    parcoursId: PropTypes.number,
    step: PropTypes.object.isRequired,
    stepContent: PropTypes.array,
    setStepContent: PropTypes.func,
    statusParcours: PropTypes.object,
    currentStep: PropTypes.object,
    setCurrentStep: PropTypes.func,
    currentStepContent: PropTypes.object,
    setCurrentStepContent: PropTypes.func,
    needRefresh: PropTypes.bool,
    setNeedRefresh : PropTypes.func,
    parcoursType: PropTypes.string
};

export default ParcoursStepTreeItem;
