import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getParcoursStepHistoryByParcoursId, getQuestionsHistoryBySerieHistoryId } from 'app/redux/actions/Question/History/history.actions';
import { selectParcoursHistory, selectSerieHistory } from 'app/redux/selectors/Question/history.selector';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryDisplayEnum } from '../historyRowHead/HistoryDisplayEnum';
import HistoryList from '../historyList/HistoryList';

function HistoryRow(props) {

    const dispatch = useDispatch();

    const seriesHistory =  useSelector(selectSerieHistory);

    const parcoursHistory =  useSelector(selectParcoursHistory);

    const history = props.historyDisplayType === HistoryDisplayEnum.QUESTION
        ? seriesHistory 
        : props.historyDisplayType === HistoryDisplayEnum.PARCOURSSTEP
            ? parcoursHistory
            : undefined;

    const getHistoryById = (id) => {
        if(props.historyDisplayType === HistoryDisplayEnum.QUESTION) {
            return getQuestionsHistoryBySerieHistoryId(id);
        } else if(props.historyDisplayType === HistoryDisplayEnum.PARCOURSSTEP) {
            return getParcoursStepHistoryByParcoursId(id, props.userId);
        } 
        return undefined;
    };
            
            

    useEffect(() => {
        if(!history?.get(props.historyId)) {
            dispatch(getHistoryById(props.historyId));
        }
    }, []);

    return (
        <HistoryList 
            listContent ={history?.get(props.historyId)} 
            historyDisplayType = {props.historyDisplayType} 
            historyId = {props.historyId} 
            userId = {props.userId} /> 
    );
}

HistoryRow.propTypes = {
    historyId: PropTypes.number,
    historyDisplayType : PropTypes.string.isRequired,
    userId : PropTypes.number
};

export default HistoryRow;
