
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RichTextEditor from 'app/common/richTextEditor/RichTextEditor';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { IconButton, Typography } from '@material-ui/core';

import { patchChapterHeader } from 'app/redux/actions/Chapter/chapter.actions';
import { useDispatch } from 'react-redux';

function ChapterCourseDisplay({ chapterCourse }) {

    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [chapterHeader, setChapterHeader] = useState(chapterCourse?.header);
    useEffect(() => {
        setChapterHeader(chapterCourse?.header);
    }, [chapterCourse]);

    const handleChangeDescription = (event, editor) => {
        setChapterHeader(editor.getData());
    };

    const handleClickEdit = () => {
        setIsEditing(true);
    };

    const handleClickSave = () => {
        setIsEditing(false);
        dispatch(patchChapterHeader(chapterCourse.id, chapterHeader));
    };

    return (
        <>
            <div
                className="chapter-description-title"
                aria-label="chapter-description-title"
            >
                <Typography
                    color="primary"
                    variant="h5"
                >
                    Synthèse du chapitre: {chapterCourse?.name}
                </Typography>
                {isEditing
                    ? <IconButton
                        aria-label="Bouton savegarde chapitre header"
                        onClick={handleClickSave}
                    >
                        <SaveIcon />
                    </IconButton>
                    : <IconButton
                        aria-label="Bouton édition chapitre header"
                        onClick={handleClickEdit}
                    >
                        <EditIcon />
                    </IconButton>
                }
            </div>
            <div
                className="chapter-description"
                aria-label="chapter-description"
            >
                {isEditing
                    ? <RichTextEditor
                        data={chapterHeader}
                        onChange={handleChangeDescription}
                    />
                    : <p
                        className="chapter-description-text"
                        dangerouslySetInnerHTML={{ __html: chapterCourse?.header }}
                    />
                }
            </div>
        </>
    );
}

ChapterCourseDisplay.propTypes = {
    chapterCourse: PropTypes.object.isRequired
};

export default ChapterCourseDisplay;
