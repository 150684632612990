import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../Message.scss';
import {
    useStyles
} from '../../../../question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer.functions';
import PropTypes from 'prop-types';
import tradIcon from 'assets/images/traduction-icon.svg';
import attentionIcon from 'assets/images/attention.svg';
import QuestionReportingDialog from 'app/question/Buttons/QuestionReportingButton/QuestionReportingDialog';
import { Tooltip } from '@material-ui/core';
import ReportButton from './ReportButton';
import aiGeneratedIcon from 'assets/images/Question_genere_ia.svg';

export default function QuestionDisplay({
    answerRef,
    answers,
    isLoading,
    onToggleTranslation,
    statement,
    questionId,
    setAnswers,
    aiGenerated,
    isReported,
    handleReportAI

}) {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    function updateResult(id) {
        setAnswers((prevAnswers) => {
            const updatedAnswers = new Map(prevAnswers);
            const answer = updatedAnswers.get(id);

            if(!answer) {
                return prevAnswers;
            }
            if(answer.statusSelected === 'disabled') {
                return prevAnswers;
            } else if(answer.statusSelected === 'selected') {
                answer.statusSelected = 'not-selected';
            } else if(answer.statusSelected === 'not-selected') {
                answer.statusSelected = 'selected';
            }
            return updatedAnswers;
        });
    }

    const handleOpen = () => {
        setIsOpen(true);
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }} className={isLoading ? 'blurEffect' : ''}>
                <div className="content bold" style={{ position: 'relative' }}> #{questionId}
                    {aiGenerated === 'WAITING_REVIEW' &&
                        <Tooltip title="Généré par IA" placement="bottom">
                            <img src={aiGeneratedIcon} alt='Custom Icon' className='aiGeneratedIcon' />
                        </Tooltip>
                    }
                </div>
                <div dangerouslySetInnerHTML={{ __html: statement }} className="content bold" />
                <ReportButton 
                    isReported={isReported}
                    setIsReported={handleReportAI} 
                />
                {( isReported )&& (
                    <IconButton id="iconHeaderRight" style={{ marginRight: '100px' }} color="primary" onClick={handleOpen}>
                        <img src={attentionIcon} alt="Custom Icon" />
                    </IconButton>
                )}
                <IconButton id="iconHeaderRight" color="primary" onClick={onToggleTranslation}>
                    <img src={tradIcon} alt="Custom Icon" />
                </IconButton>
            </div>
            <QuestionReportingDialog questionId={questionId} isOpen={isOpen} setIsOpen={setIsOpen} />
            {isLoading &&
                <div className="loaderContainer">
                    <CircularProgress className={classes.loader} />
                </div>
            }
            <ul className={`message-oxa-ul ${isLoading ? 'blurEffect' : ''}`} ref={answerRef}>
                {Array.from(answers.values()).map((answer, index) => (
                    <li className="message-oxa-li" key={answer.id}>
                        <button className={`answers-oxa ${answer.statusSelected} ${answer.statusAnswer}`} onClick={() => updateResult(answer.id)}>
                            {answer.label && <span
                                className={`circle-oxa ${answer.statusSelected} ${answer.statusAnswer}`}
                                data-testid={`circle-oxa-${index}`}
                            >
                                {answer.label}
                            </span>}
                            <div style={{ marginLeft: '10px' }}>{answer.text}</div>
                        </button>
                    </li>
                ))}
            </ul>
        </>
    );
}

QuestionDisplay.propTypes = {
    answerRef: PropTypes.object,
    answers: PropTypes.object,
    isLoading: PropTypes.bool,
    onToggleTranslation: PropTypes.func,
    statement: PropTypes.string,
    questionId: PropTypes.number,
    setAnswers: PropTypes.func,
    aiGenerated: PropTypes.string,
    isReported: PropTypes.bool,
    handleReportAI: PropTypes.func
};
