/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './TasksList.scss';
import { useDrop } from 'react-dnd';
import { AssignTypes } from 'app/utils/functions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import {
    QUESTIONS_PAGE_URL,
    SERIE_RESOLVE_URL,
    CHAPTER_RESOLVE_URL,
    SERIE_NOT_DONE_RESOLVE_URL,
    SKILL_PAGE_URL
} from 'app/Routes';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { HelpOutline, BookOutlined, ListAlt } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import PlayChapterQuestionsRequest from 'app/skill/components/SkillContent/Chapter/PlayChapterQuestions/PlayChapterQuestionsRequest';
import { ListItemAvatar, Avatar, ListItemSecondaryAction, ListItemText, Tooltip, ListSubheader, FormControlLabel, Checkbox } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { getUnfinishedSeries } from 'app/api/historyApi';
import { notificationError, notificationSuccess } from 'app/redux/actions/Notification/notifications.actions';
import { DELETE_TASK_BY_ID, DELETE_TASK_BY_ID_ERROR, DELETE_TASK_BY_ID_ERROR_ALREADY_SUPPRESSED, EXPIRED_SESSION, POST_TASKS_TO_STUDENT_ERROR, RETRIEVE_SERIES_ERROR } from 'app/Snackbar/NotificationMessages';
import { Divider } from 'antd';
import { selectAllReducedSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { TaskClassEnum } from 'app/utils/TaskClassEnum';
import { setExamMode } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { getTaskCreationDate, mapLocalTimeToDuration } from '../../utils/date.functions';
import DrawerHistoryTaskCreation from '../drawerHistory/DrawerHistoryTaskCreation';
import { deleteTaskApi, getTaskByGroupIdApi, getTaskByStudentIdApi, postTaskApi } from 'app/api/taskApi';
import { deleteTaskFromChapterReporting } from 'app/redux/actions/Chapter/chapter.actions';

const useStyles = makeStyles(() => ({
    noPadding: {
        paddingTop: '0',
        paddingBottom: '0'
    }
}));

export default function TasksList(props) {

    const getCurrentUserId = () => Number.parseInt(AuthenticationContainer.getCurrentUserId(), 10);

    const dispatch = useDispatch();
    const classes = useStyles();

    const history = useHistory();

    const allReducedSkills = useSelector(selectAllReducedSkills).data;

    const [anchorEl, setAnchorEl] = useState(null);
    const [itemDrop, setItemDrop] = useState(null);
    const [nbQuestions, setNbQuestions] = useState('0');
    const [withChildren, setWithChildren] = useState(true);
    const [isRandomSerie, setIsRandomSerie] = useState(false);
    const [language, setLanguage] = useState('zza');
    const [seriesNotDone, setSeriesNotDone] = useState([]);
    const [questionChecked, setQuestionChecked] = useState(true);
    const [reportingChecked, setReportingChecked] = useState(true);
    const [isExamMode, setIsExamMode] = useState(false);
    const [maxDuration, setMaxDuration] = useState('--:--');

    const [sameQuestions, setSameQuestions] = useState(false);
    const [taskList, setTaskList] = useState([]);

    useEffect(() => {
        if(props.selectedStudentId) {
            const currentUserId = getCurrentUserId();
            getUnfinishedSeries(props.selectedStudentId === currentUserId ? currentUserId : props.selectedStudentId)
                .then((response) => {
                    setSeriesNotDone(response.data);
                }, (error) => {
                    if(error.response && error.response.status === 401) {
                        dispatch(notificationError(EXPIRED_SESSION));
                    } else {
                        dispatch(notificationError(RETRIEVE_SERIES_ERROR));
                    }
                });
            getTaskByStudentIdApi(props.selectedStudentId)
                .then((response) => setTaskList(response.data));
        }
    }, [props.selectedStudentId]);

    useEffect(() => {
        if(props.selectedGroup?.id) {
            getTaskByGroupIdApi(props.selectedGroup.id, false)
                .then((response) => setTaskList(response.data));
        }
    }, [props.selectedGroup]);

    const isStudentSelectedAndNotGroup = () => !props.selectedGroup;

    const handleDeleteTask = (taskId, taskClass) => {
        deleteTaskApi(taskId).then((response) => {
            setTaskList((oldTaskList) => [...oldTaskList.filter((task) => task.id !== taskId)]);
            if(response && response.status === 204) {
                dispatch(notificationSuccess(DELETE_TASK_BY_ID));
                if(taskClass === 'TaskChapterReporting' ) {
                    dispatch(deleteTaskFromChapterReporting(taskId));
                }
            }
        }, (error) => {

            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));

            } else if(error.response && error.response.status === 404) {
                setTaskList((oldTaskList) => [...oldTaskList.filter((task) => task.id !== taskId)]);
                dispatch(notificationError(DELETE_TASK_BY_ID_ERROR_ALREADY_SUPPRESSED));
            }
            else {
                dispatch(notificationError(DELETE_TASK_BY_ID_ERROR));
            }
        });
    };

    const addTaskToList = (task, skill) => {
        postTaskApi(task, sameQuestions).then((response) => {
            const newTask = {
                ...response.data,
                skillId: skill?.id,
                skillName: skill?.name
            };
            setTaskList((oldTaskList) => [...oldTaskList, newTask]);
        }, (error) => {
            if(error.response && error.response.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                dispatch(notificationError(POST_TASKS_TO_STUDENT_ERROR));
            }
        });
    };

    const createTaskQuestion = (item, ids) => ({
        name: item.name,
        class: 'TaskQuestion',
        creatorId: getCurrentUserId(),
        questionId: item.id,
        studentIds: ids,
        groupId: item?.groupId
    });

    const createTaskChapter = (item, ids) => ({
        name: item.name,
        class: 'TaskRandomSerie',
        creatorId: getCurrentUserId(),
        chapterId: item.id,
        nbQuestions,
        withChildren,
        isRandomSerie,
        studentIds: ids,
        language,
        maxDuration: maxDuration === '--:--' ? null : mapLocalTimeToDuration(maxDuration),
        examMode: isExamMode,
        groupId: item?.groupId
    });

    const createTaskSerie = (item, ids) => ({
        name: item.name,
        class: 'TaskSerie',
        creatorId: getCurrentUserId(),
        serieId: item.id,
        studentIds: ids,
        examMode: isExamMode,
        groupId: item?.groupId
    });

    const getIdsRecipient = () => props.selectedGroup
        ? props.selectedGroup.students.map((student) => student.id)
        : [props.selectedStudentId];

    const setNewTaskToRecipient = (item, ids) => {
        let itemWithGroupId = item;
        if(!isStudentSelectedAndNotGroup()) {
            itemWithGroupId = { ...item,
                groupId: props.selectedGroup.id };
        }
        switch (itemWithGroupId.type) {
            case AssignTypes.QUESTION:
                addTaskToList(createTaskQuestion(itemWithGroupId, ids));
                break;
            case AssignTypes.SERIE:
                addTaskToList(createTaskSerie(itemWithGroupId, ids));
                break;
            case AssignTypes.CHAPTER:
                setItemDrop(item.chapter);
                setAnchorEl({ ...document.getElementById(`Chapitre ${item.chapter.id}`) });
                break;
            default:
        }
    };

    const [, drop] = useDrop(
        () => ({
            accept: [AssignTypes.QUESTION, AssignTypes.SERIE, AssignTypes.CHAPTER],
            drop: (item) => setNewTaskToRecipient(item, getIdsRecipient()),
            collect: (monitor) => ({
                isOver: Boolean(monitor.isOver())
            })
        }),
        [props.selectedStudentId, props.selectedGroup]
    );

    const getTaskUrl = (task) => {
        switch (task.class) {
            case TaskClassEnum.TaskQuestion:
                return `${QUESTIONS_PAGE_URL}/resolve/${task.questionId}?taskId=${task.id}`;
            case TaskClassEnum.TaskSerie:
                return `${SERIE_RESOLVE_URL}/${task.serieId}?taskId=${task.id}`;
            case TaskClassEnum.TaskRandomSerie:
                const paramMaxDuration = task.maxDuration === null ? '' : `&maxDuration=${mapLocalTimeToDuration(task.maxDuration)}`;
                return `${CHAPTER_RESOLVE_URL
                }/${task.skillName
                }?skillId=${task.skillId
                }&chapterId=${task.chapterId
                }&nbQuestions=${task.nbQuestions
                }&withChildren=${task.withChildren
                }&isRandomSerie=${task.isRandomSerie
                }&language=${task.language
                }&taskId=${task.id
                }${paramMaxDuration}`;
            case TaskClassEnum.TaskQuestionReporting:
                return `${QUESTIONS_PAGE_URL}/edit?id=${task.questionReportedId}`;
            case TaskClassEnum.TaskChapterReporting:
                return `${SKILL_PAGE_URL}/${task.skillName}`;
            default:
                return '';
        }
    };

    const getTaskAvatar = (task) => {
        switch (task.class) {
            case TaskClassEnum.TaskSerie:
                return <ListAlt/>;
            case TaskClassEnum.TaskRandomSerie:
                return <BookOutlined/>;
            default:
                return <HelpOutline/>;
        }
    };

    const getTaskTooltip = (task) => {
        switch (task.class) {
            case TaskClassEnum.TaskSerie:
                return `Série : ${task.name}`;
            case TaskClassEnum.TaskRandomSerie:
                return `Chapitre : ${task.name} (${task.nbQuestions} questions)`;
            default:
                return `Question : ${task.name}`;
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleValidate = () => {
        const skill = allReducedSkills.find((reduced) => reduced.id === itemDrop.skillId);
        if(isStudentSelectedAndNotGroup()) {
            const task = createTaskChapter(itemDrop, getIdsRecipient());
            addTaskToList(task, skill);
        } else {
            const task = createTaskChapter(
                { ...itemDrop, groupId: props.selectedGroup.id },
                getIdsRecipient()
            );
            addTaskToList(task, skill);
        }
    };

    const isDisplayed = useCallback((task) => {

        if(!(questionChecked || reportingChecked)) {
            return false;
        }
        switch (task.class) {
            case TaskClassEnum.TaskSerie:
            case TaskClassEnum.TaskRandomSerie:
            case TaskClassEnum.TaskQuestion:
                return questionChecked;
            case TaskClassEnum.TaskQuestionReporting:
            case TaskClassEnum.TaskChapterReporting:
            case TaskClassEnum.TaskMicroskillReporting:
                return reportingChecked;
            default:
                return true;
        }
    }, [questionChecked, reportingChecked]);

    const handleChangeWithChildren = (event) => {
        setWithChildren(event.target.checked);
    };

    const handleChangeIsRandomSerie = (event) => {
        setIsRandomSerie(event.target.checked);
    };

    const handleChangeNbQuestions = (value) => {
        setNbQuestions(String(value));
    };

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
    };

    const handleChangeExamMode = (event) => {
        setIsExamMode(event.target.checked);
    };

    const updateExamMode = (useExamMode) => {
        dispatch(setExamMode(useExamMode));
    };

    const handleClickTask = (task) => {
        if(task.class !== TaskClassEnum.TaskMicroskillReporting) {
            updateExamMode(task.examMode ?? false);
            history.push(getTaskUrl(task));
        }
    };

    const handleQuestionChanged = (event) => {
        setQuestionChecked(event.target.checked);
    };

    const handleReportingChanged = (event) => {
        setReportingChecked(event.target.checked);
    };

    const handleChangeMaxDuration = (value) => {
        setMaxDuration(String(value));
    };

    return (
        <>
            <DrawerHistoryTaskCreation
                {...props}
                addTaskToList={(task) => setTaskList((oldTaskList) => [...oldTaskList, task])}
            />
            <div className="tasksList" ref={drop}>
                {anchorEl &&
                    <PlayChapterQuestionsRequest
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        onValidate={handleValidate}
                        chapterId={itemDrop.id}
                        nbQuestions={nbQuestions}
                        onChangeNbQuestions={handleChangeNbQuestions}
                        withChildren={withChildren}
                        onChangeWithChildren={handleChangeWithChildren}
                        isRandomSerie={isRandomSerie}
                        setIsRandomSerie={handleChangeIsRandomSerie}
                        sameQuestions={sameQuestions}
                        setSameQuestions={props.selectedGroup ? setSameQuestions : undefined}
                        language={language}
                        onChangeLanguage={handleChangeLanguage}
                        isExamMode={isExamMode}
                        onSetExamMode={handleChangeExamMode}
                        onChangeMaxDuration={handleChangeMaxDuration}
                    />
                }
                <div style={{ display: 'flex', color: 'primary', justifyContent: 'center' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked = {questionChecked}
                                onChange={handleQuestionChanged}
                                color="primary"
                            />
                        }
                        label="Questions"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked = {reportingChecked}
                                onChange={handleReportingChanged}
                                color="primary"
                            />
                        }
                        label="Reporting"
                    />
                </div>
                <List>
                    {isStudentSelectedAndNotGroup() && taskList.length
                        ? taskList.filter((task) => isDisplayed(task)).reverse()
                            .map((task) => (
                                <ListItem
                                    button
                                    className={classes.noPadding}
                                    data-testid="one-task-from-list"
                                    key={task.id}
                                    onClick={() => handleClickTask(task)}
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            {getTaskAvatar(task)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Tooltip placement="top-start" title={getTaskTooltip(task)}>
                                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={task.name} secondary={getTaskCreationDate(task)}/>
                                    </Tooltip>
                                    {(props.selectedStudentId !== getCurrentUserId() || task.creatorId === getCurrentUserId()) &&
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            data-testid={task.id}
                                            edge="end"
                                            onClick={() => handleDeleteTask(task.id, task.class)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            ))
                        : !isStudentSelectedAndNotGroup() && taskList.length
                            ? taskList.filter((task) => isDisplayed(task)).reverse()
                                .map((task) => (
                                    <ListItem
                                        button
                                        className={classes.noPadding}
                                        data-testid="one-task-from-list"
                                        key={task.id}
                                        onClick={() => handleClickTask(task)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                {getTaskAvatar(task)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Tooltip placement="top-start" title={getTaskTooltip(task)}>
                                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={task.name} secondary={getTaskCreationDate(task)}/>
                                        </Tooltip>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                data-testid={task.id}
                                                edge="end"
                                                onClick={() => handleDeleteTask(task.id, task.class)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            : <ListSubheader className="emptyList">
                                Déposer du contenu ici
                            </ListSubheader>
                    }
                </List>
                {
                    seriesNotDone?.length
                        ? <>
                            <Divider>Séries non terminées</Divider>
                            <List>
                                {seriesNotDone.map((serie) => (
                                    <ListItem key={serie.serieHistoryId}>
                                        {props.selectedStudentId === getCurrentUserId()
                                            ? <Link
                                                onClick={() => updateExamMode(serie.examMode ?? false)}
                                                to={`${SERIE_NOT_DONE_RESOLVE_URL}/serieResume/${serie.serieId}/history/${serie.serieHistoryId}`}>
                                                {`${serie.name} (${serie.questionsNotDone} questions)`}
                                            </Link>
                                            : (`${serie.name} (${serie.questionsNotDone} questions)`)
                                        }
                                    </ListItem>
                                ))}
                            </List>
                        </>
                        : <></>
                }
            </div>
        </>
    );
}

TasksList.propTypes = {
    selectedGroup: PropTypes.object,
    selectedStudentId: PropTypes.number,
    selectedGroupId: PropTypes.number
};
