import React from 'react';
import PropTypes from 'prop-types';
import colors from 'styles/colors.module.scss';

import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { CheckCircleRounded, CloseRounded } from '@material-ui/icons';
import { AssignTypes } from 'app/utils/functions';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { selectQuestionSelectedHistory } from 'app/redux/selectors/Question/question.selector';

const useStyles = makeStyles(() => ({
    question: {
        marginLeft: 5
    },
    serieQuestion: {
        marginLeft: 65
    }
}));

function DrawerHistoryQuestionDisplay(props) {

    const classes = useStyles();

    const questionIdFromStore = useSelector(selectQuestionSelectedHistory)?.id;
    const questionId = props.questionHistory?.id;

    const handleClickHistory = () => {
        props.onClickQuestion(props.questionHistory.id);
    };

    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.QUESTION,
        item: {
            type: AssignTypes.QUESTION,
            id: questionId,
            chapterId: props.questionHistory.chapterId,
            name: props.questionHistory.title
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging())
        })

    }), [props.questionHistory]);

    return (
        <ListItem
            className={props.fromSerie ? classes.serieQuestion : classes.question}
            style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
            selected={questionIdFromStore === questionId}
            button
            ref={drag}
            data-testid="drawer-history-question-display"
            onClick={handleClickHistory}
        >
            <ListItemIcon>
                {props.questionHistory.result > 0 && props.questionHistory.result < 101
                    ? <span style={{ color: colors[
                        props.questionHistory.result < 50 ? 'red-color-400' : props.questionHistory.result < 70 ? 'dark-orange' : 'green-color-700'
                    ] }}>{props.questionHistory.result}%</span>
                    : props.questionHistory.result > 0
                        ? <CheckCircleRounded style={{ color: colors['green-color-700'] }}/>
                        : <CloseRounded style={{ color: colors['red-color-400'] }} />
                }
            </ListItemIcon>
            <ListItemText primary={`${props.questionHistory.title}`} />
        </ListItem>
    );
}

DrawerHistoryQuestionDisplay.propTypes = {
    questionHistory: PropTypes.object.isRequired,
    fromSerie: PropTypes.bool,
    onClickQuestion: PropTypes.func.isRequired
};

export default DrawerHistoryQuestionDisplay;
