import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../SidePanel.scss';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import SidePanelClientsFilterContainer from './SidePanelClientsFilterContainer';
import { setClientsExcilyenFilter, setClientsSectorFilter } from '../../../redux/actions/Client/ClientFilter/clientsFilters.actions';
import { selectClientFilteredBySectorAndUser } from '../../../redux/selectors/Client/ClientFilter/clientsFilter.selector';

export function SidePanelClients(props) {

    return (
        <Grid container spacing={1} className="side-panel" data-testid="side-panel-clients">
            <Grid item container spacing={1} className="top">
                <Grid item xs={12}>
                    <Typography className="sub-title-numbers"> {props.number} Clients</Typography>
                    <span className="title">Filtres</span>
                </Grid>
                <Grid item xs={12}>
                    <span className="reset-filters-button" onClick={props.resetFilters} data-testid="reset-filters">
                    Réinitialiser les filtres
                    </span>
                </Grid>
            </Grid>
            <div className="filters-project">
                <Grid item xs={12}>
                    <SidePanelClientsFilterContainer user/>
                </Grid>
                <Grid item xs={12}>
                    <SidePanelClientsFilterContainer sector/>
                </Grid>
            </div>
        </Grid>
    );
}

SidePanelClients.propTypes = {
    resetFilters: PropTypes.func,
    number: PropTypes.number
};

function mapStateToProps(state) {
    return {
        number: selectClientFilteredBySectorAndUser(state).length
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        resetFilters: () => {
            dispatch(setClientsSectorFilter(null));
            dispatch(setClientsExcilyenFilter(null));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelClients);
