import React from 'react';
import PropTypes from 'prop-types';
import { AssignTypes } from 'app/utils/functions';
import { useDrop } from 'react-dnd';
import DeleteIcon from '@material-ui/icons/Delete';


function DeleteZone(props) {

    const [{ isOver }, drop] = useDrop(() => ({
        accept: [AssignTypes.PARCOURS, AssignTypes.PARCOURS_STEP, AssignTypes.PARCOURS_STEP_CONTENT],
        drop: (item) => {
            props.handleDropDelete(item);
        },
        canDrop: () => true,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        }),
    }));

    const trashStyle = {
        opacity: isOver ? 0.5 : 1,
        backgroundColor: isOver ? 'red' : 'white',
        border: '1px dashed red',
        padding: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <div ref={(node) => drop(node)} style={{ ...trashStyle }}>
            <DeleteIcon
                color="secondary"
                aria-label="close"
            />
        </div>
    );
}

DeleteZone.propTypes = {
    handleDropDelete: PropTypes.func
};

export default DeleteZone;
