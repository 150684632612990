import clientsReducer from './reducers/Client/clients.reducer';
import skillSetReducer from './reducers/Skill/SkillSet/skillSet.reducer';
import { skillsReducer, skillsStoreNew } from './slices/skills.slice';
import userReducer from './reducers/User/user.reducer';
import filtersReducer from './reducers/Filter/filter.reducer';
import notificationsReducer from './reducers/Notification/notifications.reducer';
import userNotificationsReducer from './reducers/Notification/userNotifications.reducer';
import excilyenReducer from './reducers/Excilyen/ExcilyenSet/excilyenSet.reducer';
import displayReducer from './reducers/Display/display.reducer';
import excilyensFiltersReducer from './reducers/Excilyen/ExcilyenFilter/excilyensFilters.reducer';
import { loadingReducer } from './reducers/Loading/loading.reducer';
import { errorReducer } from './reducers/Error/error.reducer';
import familiesCategoriesReducer from './reducers/FamilyCategory/familiesCategories.reducer';
import { projectsReducer, projectsStore } from './slices/projects.slice';
import projectsFiltersReducer from './reducers/Project/ProjectFilter/projectsFilters.reducer';
import sectorReducer from './reducers/Sector/sector.reducer';
import clientsFilterReducer from './reducers/Client/ClientFilter/clientsFilter.reducer';
import { missionsReducer, missionsStore } from './slices/missions.slice';
import questionReducer from './reducers/Question/question.reducer';
import multipleChoiceQuestionReducer from './reducers/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.reducer';
import questionsFiltersReducer from './reducers/Question/QuestionsFilters/questionsFilters.reducer';
import questionsReportingReducer from './reducers/Question/QuestionReporting/questionReporting.reducer';
import seriesReducer from './reducers/Question/serie.reducer';
import historyReducer from './reducers/Question/history.reducer';
import paginationReducer from './reducers/Pagination/pagination.reducer';
import dailyQuestionsReducer from './reducers/Question/dailyQuestions.reducer';
import chapterReducer from './reducers/Chapter/chapter.reducer';
import chapterResourcesReducer from './reducers/Chapter/Resource/resource.reducer';
import coachingReducer from './reducers/Coaching/coaching.reducer';
import userSkillReducer from './reducers/UserSkill/userSkill.reducer';
import taskReducer from './reducers/Tasks/Task.reducer';
import parcoursReducer from './reducers/Parcours/parcours.reducer';
import drawerReducer from './reducers/Drawer/drawer.reducer';
import chapterStatsReducer from './reducers/ChapterStats/chapterStats.reducer';
import chapterValidationReducer from './reducers/ChapterValidation/chapterValidation.reducer';
import candidateReducer from './reducers/Candidate/candidate.reducer';
import groupReducer from './reducers/Group/group.reducer';
import bookmarkReducer from './reducers/Bookmark/bookmark.reducer';
import interactiveQuizRoomReducer from './reducers/InteractiveQuiz/interactiveQuizRoom.reducer';
import chatReducer from './reducers/Chat/chat.reducer.js';
import morningQuestionsReducer from './reducers/Question/morningQuestion.reducer';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { chaptersReducer, chaptersStoreNew } from './slices/chapters.slice';
import { enableMapSet } from 'immer';

export const userStore = 'user';
export const excilyensStore = 'excilyens';
export const userSkillsStore = 'userSkills';
export const familiesCategoriesStore = 'familiesCategories';
export const excilyensFilterStore = 'excilyenFilters';
export const skillsFilterStore = 'skillFilter';
export const skillsStore = 'skills';
export const projectsFilterStore = 'projectFilters';
export const clientsFilterStore = 'clientFilters';
export const clientsStore = 'clients';
export const loadingStore = 'loading';
export const errorStore = 'error';
export const sectorsStore = 'sectors';
export const questionStore = 'question';
export const questionsStore = 'questions';
export const questionsFiltersStore = 'questionsFilters';
export const questionsReportingStore = 'questionsReportingStore';
export const seriesStore = 'series';
export const historyStore = 'history';
export const paginationStore = 'pagination';
export const dailyQuestionsStore = 'dailyQuestions';
export const chaptersStore = 'chapters';
export const chapterResourcesStore = 'chapterResources';
export const coachingStore = 'coaching';
export const taskStudentStore = 'taskStudent';
export const taskGroupStore = 'taskGroup';
export const parcoursStore = 'parcoursStore';
export const groupsStore = 'groupsStore';
export const drawerStore = 'drawerStore';
export const chaptersStatsStore = 'chaptersStatsStore';
export const chapterValidationStore = 'chapterValidationStore';
export const userNotificationStore = 'userNotifications';
export const candidatesStore = 'candidatesStore';
export const groupStore = 'group';
export const bookmarkStore = 'bookmark';
export const interactiveQuizRoomStore = 'interactiveQuizRoom';
export const chatStore = 'chat';
export const morningQuestionsStore = 'morningQuestions';

const user = { [userStore]: userReducer };
const skillSet = { [skillsStore]: skillSetReducer };
const filters = { [skillsFilterStore]: filtersReducer };
const notifications = { notifications: notificationsReducer };
const userNotifications = { [userNotificationStore]: userNotificationsReducer };
const excilyenSet = { [excilyensStore]: excilyenReducer };
const userSkills = { [userSkillsStore]: userSkillReducer };
const display = { display: displayReducer };
const expertFilters = { [excilyensFilterStore]: excilyensFiltersReducer };
const loading = { [loadingStore]: loadingReducer };
const error = { [errorStore]: errorReducer };
const familiesCategories = { [familiesCategoriesStore]: familiesCategoriesReducer };
const projectFilters = { [projectsFilterStore]: projectsFiltersReducer };
const clientFilters = { [clientsFilterStore]: clientsFilterReducer };
const clients = { [clientsStore]: clientsReducer };
const sectors = { [sectorsStore]: sectorReducer };
const question = { [questionStore]: questionReducer };
const questions = { [questionsStore]: multipleChoiceQuestionReducer };
const questionsFilters = { [questionsFiltersStore]: questionsFiltersReducer };
const questionsReporting = { [questionsReportingStore]: questionsReportingReducer };
const series = { [seriesStore]: seriesReducer };
const history = { [historyStore]: historyReducer };
const pagination = { [paginationStore]: paginationReducer };
const dailyQuestions = { [dailyQuestionsStore]: dailyQuestionsReducer };
const chapters = { [chaptersStore]: chapterReducer,
    [chapterResourcesStore]: chapterResourcesReducer };
const coaching = { [coachingStore]: coachingReducer };
const taskStudent = { [taskStudentStore]: taskReducer };
const taskGroup = { [taskGroupStore]: taskReducer };
const parcours = { [parcoursStore]: parcoursReducer };
const drawer = { [drawerStore]: drawerReducer };
const chapterValidation = { [chapterValidationStore]: chapterValidationReducer };
const chapterStats = { [chaptersStatsStore]: chapterStatsReducer };
const candidate = { candidate: candidateReducer };
const group = { [groupStore]: groupReducer };
const bookmark = { [bookmarkStore]: bookmarkReducer };
const interactiveQuizRoom = { [interactiveQuizRoomStore]: interactiveQuizRoomReducer };
const chat = { [chatStore]: chatReducer };
const morningQuestions = { [morningQuestionsStore]: morningQuestionsReducer };

enableMapSet();

export const rootReducer = combineReducers({
    ...user,
    ...userSkills,
    ...skillSet,
    [skillsStoreNew]: skillsReducer,
    ...filters,
    ...notifications,
    ...userNotifications,
    ...excilyenSet,
    ...display,
    ...expertFilters,
    ...error,
    ...loading,
    ...familiesCategories,
    [projectsStore]: projectsReducer,
    ...clientFilters,
    ...projectFilters,
    [missionsStore]: missionsReducer,
    ...clients,
    ...sectors,
    ...question,
    ...questions,
    ...questionsFilters,
    ...questionsReporting,
    ...series,
    ...history,
    ...pagination,
    ...dailyQuestions,
    ...chapters,
    [chaptersStoreNew]: chaptersReducer,
    ...coaching,
    ...taskStudent,
    ...taskGroup,
    ...parcours,
    ...drawer,
    ...chapterStats,
    ...chapterValidation,
    ...candidate,
    ...group,
    ...taskGroup,
    ...bookmark,
    ...interactiveQuizRoom,
    ...chat,
    ...morningQuestions
});

// Change preloadedState type to Partial<RootState> when all reducers are slices
export const setupStore = (preloadedState?: any) => configureStore({
    reducer: rootReducer,
    // redux-thunk is added by default by RTK
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }),
    devTools: process.env.NODE_ENV !== 'production' &&
        { serialize: { options: true } },
    preloadedState
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
