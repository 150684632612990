import axiosClient from './utils/axiosClient';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const QUIZ_URI = 'quiz';

export function postUserJoinedInteractiveQuizRoomApi(userId, quizId) {
    return axiosClient.post(`${QUIZ_URI}/addUser/${userId}/${quizId}`);
}

export function postUserLeftInteractiveQuizRoomApi(userId, quizId) {
    return axiosClient.post(`${QUIZ_URI}/removeUser/${userId}/${quizId}`);
}

export function getRoomExistsApi(quizId) {
    return axiosClient.get(`${QUIZ_URI}/checkRoomExists/${quizId}`);
}

export function postRoomExistsApi(quizId, users, questionId) {
    return axiosClient.post(`${QUIZ_URI}/sendRoomExists/${quizId}`, { users, questionId });
}

export function postInteractiveQuizStartedApi(quizId, questionId) {
    return axiosClient.post(`${QUIZ_URI}/sendQuizStarted/${quizId}`, null, { params: { questionId } });
}

export function postInteractiveQuizCurrentQuestionApi(quizId, questionId) {
    return axiosClient.post(`${QUIZ_URI}/sendCurrentQuestion/${quizId}`, null, { params: { questionId } });
}

export function postInteractiveQuizCurrentQuestionValidatedByLeaderApi(quizId, questionId) {
    return axiosClient.post(`${QUIZ_URI}/sendCurrentQuestionValidatedByLeader/${quizId}`, null, { params: { questionId } });
}

export function postUserAnsweredInteractiveQuizCurrentQuestionApi(userId, quizId, selectedAnswersWithResult) {
    return axiosClient.post(`${QUIZ_URI}/sendUserAnsweredCurrentQuestion/${AuthenticationContainer.getCurrentUserId()}/${quizId}`, selectedAnswersWithResult);
}

export function postEndOfQuizApi(quizId) {
    return axiosClient.post(`${QUIZ_URI}/sendQuizEnded/${quizId}`);
}

export function postSendSerieScoreApi(quizId, scoreData) {
    return axiosClient.post(`${QUIZ_URI}/sendSerieScore/${quizId}`, scoreData);
}

export function postInteractiveQuizParamsApi(uuid, skillId, chapterId, questionIds) {
    return axiosClient.post(`${QUIZ_URI}/sendParams/${uuid}/${skillId}/${chapterId}`, questionIds);
}
