import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { QuestionResolverWithCourse } from '../QuestionResolverWithCourse/QuestionResolverWithCourse';
import { QUESTIONS_PAGE_URL } from 'app/Routes';

import { useDispatch, useSelector } from 'react-redux';
import { getQuestionByIdWithoutExplanation } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { selectQuestionWithoutExplanation } from 'app/redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import { selectStudentTask } from 'app/redux/selectors/Task/task.selector';
import { patchStudentTask } from 'app/redux/actions/Coaching/Tasks/Task.action';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { selectStudent } from '../../../redux/selectors/Chapter/chapter.selector';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function QuestionRequestByQuestionId(props) {

    const dispatch = useDispatch();
    const questionWithoutExplanation = useSelector(selectQuestionWithoutExplanation);
    const listTaskStudent = useSelector(selectStudentTask);
    const studentSelected = useSelector(selectStudent);
    const history = useHistory();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    useEffect(() => {
        if(props.questionId) {
            getQuestionByIdWithoutExplanation(props.questionId);
            dispatch(getQuestionByIdWithoutExplanation(props.questionId));
        }
    }, [props.questionId]);

    const handleCloseQuiz = () => {
        if(studentSelected && listTaskStudent) {
            const taskFound = listTaskStudent.find((element) => (element.questionId === props.questionId));
            if(taskFound) {
                dispatch(patchStudentTask(taskFound.id, getCurrentUserId()));
            }
        }
        history.push(QUESTIONS_PAGE_URL);
    };

    return (
        <div data-testid="question-request-by-question-id" style={{ height: 'inherit', overflowY: 'auto' }}>
            {questionWithoutExplanation.findIndex((question) => question.id === props.questionId) !== -1 &&

                <QuestionResolverWithCourse
                    {...props}
                    questionAsked={questionWithoutExplanation[questionWithoutExplanation.findIndex((question) => question.id === props.questionId)]}
                    onEndSerieClick={handleCloseQuiz}
                    redirect={QUESTIONS_PAGE_URL}
                />
            }
        </div>
    );
}

QuestionRequestByQuestionId.propTypes = {
    questionId: PropTypes.number.isRequired,
    interactiveQuizSerieHistoryUpdate: PropTypes.object,
    interactiveQuizTotalNbQuestions: PropTypes.number
};

export default QuestionRequestByQuestionId;
