import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { initPdfWorker } from '../../utils/pdfjs.functions';
import './PdfViewer.scss';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

initPdfWorker();
export default function PdfViewer(props) {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [minPage, setMinPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [isPdfLoadingSuccess, setPdfLoadingSuccess] = useState();
    const [file, setFile] = useState({ url: props.url });
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [isDisplayedFullWidth, setDisplayedFullWidth] = useState(true);

    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const ref = useRef(null);

    useEffect(() => {
        setHeight(ref.current.clientHeight * 0.94);
        setWidth(ref.current.clientWidth);
    });

    useEffect(() => {
        const newMinPage = Math.max(props.startPage || 1, 1);
        setMinPage(newMinPage);
        setMaxPage(Math.min(props.endPage || numPages, numPages));
        setPageNumber(newMinPage);
    }, [numPages, props]);

    useEffect(() => {
        setFile({ url: props.url });
    }, [props.url]);

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
        setPdfLoadingSuccess(true);
    };

    const onDocumentLoadError = () => {
        setPdfLoadingSuccess(false);
    };

    const previousPage = () => {
        if(pageNumber > minPage) {
            setPageNumber(pageNumber - 1);
        }
    };

    const nextPage = () => {
        if(pageNumber < maxPage) {
            setPageNumber(pageNumber + 1);
        }
    };

    const switchFullScreen = () => {
        if(document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            ref.current.requestFullscreen();
        }
    };

    const fullScreenLoc = () => document.fullscreenElement ? 'Réduire' : 'Plein écran';
    const fullWidthLoc = () => isDisplayedFullWidth ? 'Adapter la hauteur' : 'Adapter la largeur';

    const toolBar = () => <div className={document.fullscreenElement ? 'pdf-toolbar fullscreen-pdf-toolbar' : 'pdf-toolbar'} >
        <Tooltip title={fullScreenLoc()} aria-label={fullScreenLoc}>
            <span>
                <IconButton id="fullscreenButton"
                    onClick={switchFullScreen}
                    aria-label={fullScreenLoc}>
                    {document.fullscreenElement
                        ? <FullscreenExitIcon fontSize="large" />
                        : <FullscreenIcon fontSize="large" />}
                </IconButton>
            </span>
        </Tooltip>
        <Tooltip title={fullWidthLoc()} aria-label={fullWidthLoc()}>
            <span>
                <IconButton id="extendPageButton"
                    onClick={() => setDisplayedFullWidth(!isDisplayedFullWidth)}
                    aria-label={fullWidthLoc()}>
                    {isDisplayedFullWidth
                        ? <CropPortraitIcon fontSize="large" />
                        : <CropLandscapeIcon fontSize="large" />}
                </IconButton>
            </span>
        </Tooltip>
    </div>;

    const navigationBar = () => <div className={document.fullscreenElement ? 'pdf-nav-bar fullscreen' : 'pdf-nav-bar'}>
        <Tooltip title="Page précédente" aria-label="Page précédente">
            <span>
                <IconButton id="previousPageButton"
                    disabled={pageNumber <= minPage}
                    onClick={previousPage}
                    aria-label="Page précédente">
                    <NavigateBeforeIcon fontSize="large" />
                </IconButton>
            </span>
        </Tooltip>
        <span>Page {pageNumber - minPage + 1} sur {maxPage - minPage + 1}</span>
        <Tooltip title="Page suivante" aria-label="Page suivante">
            <span>
                <IconButton id="nextPageButton"
                    disabled={pageNumber >= maxPage}
                    onClick={nextPage}
                    aria-label="Page suivante">
                    <NavigateNextIcon fontSize="large" />
                </IconButton>
            </span>
        </Tooltip>
    </div>;

    return (
        <div className="pdf-viewer" data-testid="pdf-viewer">
            <Document className={sizeUpMd ? 'pdf-document' : 'pdf-document pdf-document-mobile'}
                inputRef={ref}
                file={file}
                loading="Chargement en cours..."
                error="Echec du chargement du fichier PDF"
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
            >
                {isPdfLoadingSuccess && toolBar()}
                {isDisplayedFullWidth
                    ? <Page key={`${pageNumber}_${width}`} pageNumber={pageNumber} width={width} />
                    : <Page className="pdf-canvas-height" key={`${pageNumber}_${height}`}
                        pageNumber={pageNumber} height={height} renderAnnotationLayer={false} />
                }
                {isPdfLoadingSuccess && navigationBar()}
            </Document>
        </div>
    );
}

PdfViewer.propTypes = {
    url: PropTypes.string.isRequired,
    startPage: PropTypes.number,
    endPage: PropTypes.number
};
