import {
    getAllClientsApi,
    getClientByIdApi
} from '../../../api/clientsApi';
import { notificationError } from '../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_CLIENTS_ERROR,
    RETRIEVE_CLIENT_ERROR
} from '../../../Snackbar/NotificationMessages';

export const GET_CLIENT_BY_ID = 'GET_CLIENT';
export const GET_CLIENT_BY_ID_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_BY_ID_FAILURE = 'GET_CLIENT_FAILURE';

export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export function setCurrentClient(client) {
    return {
        type: GET_CLIENT_BY_ID_SUCCESS,
        payload: client
    };
}

export const getAllClients = () => (dispatch) => {
    dispatch({ type: GET_ALL_CLIENTS_REQUEST });

    return getAllClientsApi().then((response) => {
        dispatch({
            type: GET_ALL_CLIENTS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_CLIENTS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_CLIENTS_ERROR));
        }
    });
};

export const getClientById = (id) => (dispatch) => {
    dispatch({ type: GET_CLIENT_BY_ID_REQUEST });

    return getClientByIdApi(id).then((response) => {
        dispatch({
            type: GET_CLIENT_BY_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CLIENT_BY_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_CLIENT_ERROR));
        }
    });
};
