import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectQuestionsReducedPage } from '../../../redux/selectors/Question/MultipleChoiceQuestion/MultipleChoiceQuestion.selector';
import {
    getQuestionsDisplayPaginated,
    resetQuestionsReduced,
    resetQuestionToPreview
} from '../../../redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';
import './CreateEditSerieForm.scss';
import AuthenticationContainer from '../../../login/containers/AuthenticationContainer';
import { selectSerieModificationIsSaved } from '../../../redux/selectors/Question/serie.selector';
import { resetSerieModificationSaved } from '../../../redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { CreateEditSerieFormHeader } from './CreateEditSerieFormHeader/CreateEditSerieFormHeader';
import { CreateEditSerieFormFooter } from './CreateEditSerieFormFooter/CreateEditSerieFormFooter';
import { CreateEditSerieFormQuestionsAvailable } from './CreateEditSerieFormQuestionsAvailable/CreateEditSerieFormQuestionsAvailable';
import { CreateEditSerieFormSelectedQuestions } from './CreateEditSerieFormSelectedQuestions/CreateEditSerieFormSelectedQuestions';

export function CreateEditSerieForm(props) {

    const dispatch = useDispatch();

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();
    const getEmail = () => AuthenticationContainer.getEmail();
    const getGivenName = () => AuthenticationContainer.getGivenName();
    const getFamilyName = () => AuthenticationContainer.getFamilyName();
    const getProfilePicture = () => AuthenticationContainer.getProfilePicture();

    const emptySerie = () => ({
        id: null,
        title: '',
        description: '',
        maxDuration: '',
        creator: {
            id: getCurrentUserId()
        },
        questionReducedList: []
    });

    const serieModificationSaved = useSelector(selectSerieModificationIsSaved);
    const allQuestions = useSelector(selectQuestionsReducedPage);

    const [questions, setQuestions] = useState();
    const [serie, setSerie] = useState(props.serie);
    const [pagesData, setPagesData] = useState({
        offset: 0,
        pageNumber: 0,
        questionsCount: 0,
        rowsPerPage: 10,
        pageNumberSelected: 0
    });
    const [openSelected, setOpenSelected] = useState(new Map());

    useEffect(() => {
        setSerie(props.serie);
        return () => {
            setSerie(emptySerie());
            setQuestions([]);
            dispatch(resetQuestionsReduced());
            dispatch(resetQuestionToPreview());
        };
    }, []);

    useEffect(() => {
        if(allQuestions) {
            setPagesData({ ...pagesData,
                questionsCount: allQuestions.totalNumberOfElements,
                rowsPerPage: allQuestions.step });
            setQuestions(allQuestions.listOfElements);
        }
    }, [allQuestions]);

    useEffect(() => {
        if(serieModificationSaved) {
            dispatch(resetSerieModificationSaved());
        }
    }, [serieModificationSaved]);

    useEffect(() => {
        if(pagesData.pageNumberSelected > 0 && pagesData.pageNumberSelected * pagesData.rowsPerPage >= serie.questionReducedList.length) {
            setPagesData({
                ...pagesData,
                pageNumberSelected: pagesData.pageNumberSelected - 1
            });
        }
    }, [serie]);

    useEffect(() => {
        dispatch(getQuestionsDisplayPaginated({
            column: null,
            order: 'asc',
            offset: pagesData && pagesData.offset ? pagesData.offset : 0,
            step: pagesData && pagesData.rowsPerPage ? pagesData.rowsPerPage : 10,
            criteria: {
                authorIds: [],
                skillIds: [],
                userId: getCurrentUserId(),
                aiGenerated: false
            },
            listOfElements: null,
            totalNumberOfElements: null, 
        }));
    }, [pagesData.offset, pagesData.rowsPerPage]);

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const newSerie = {
            ...serie,
            [name]: value
        };
        setSerie(newSerie);
    }

    const removeTableData = (completedSerie) => {
        const copy = JSON.parse(JSON.stringify(completedSerie));
        if(copy) {
            copy.questionReducedList.forEach((elem) => {
                delete elem.tableData;
            });
        }
        return copy;
    };

    function changeCreator() {
        serie.creator.id = getCurrentUserId();
        serie.creator.email = getEmail();
        serie.creator.firstName = getGivenName();
        serie.creator.lastName = getFamilyName();
        serie.creator.imageUrl = getProfilePicture();
    }

    const handleChangePage = (event, number) => {
        setPagesData({
            ...pagesData,
            offset: number * pagesData.rowsPerPage,
            pageNumber: number
        });
    };

    const handleRowsPerPage = (event) => {
        setPagesData({
            ...pagesData,
            pageNumber: 0,
            pageNumberSelected: 0,
            offset: 0,
            rowsPerPage: event.target.value
        });
    };

    function attributeQuestion(questionReduced) {
        const copy = removeTableData({ ...serie });
        if(questionReduced.tableData &&
            questionReduced.tableData.checked === true &&
            copy.questionReducedList.filter((elem) => elem.id === questionReduced.id).length <= 0) {
            copy.questionReducedList.push(questionReduced);
        } else if(copy.questionReducedList) {
            copy.questionReducedList = copy.questionReducedList.filter((question) => question.id !== questionReduced.id);
        }
        setSerie({ ...copy });
    }

    function removeSelectedQuestion(rowData) {
        if(rowData.tableData) {
            rowData.tableData.checked = false;
        }
        const copy = removeTableData({ ...serie });
        copy.questionReducedList = copy.questionReducedList.filter((question) => question.id !== rowData.id);
        setSerie({ ...copy });
    }

    function setOpenSelectedPreview(questionId) {
        const newMap = new Map(openSelected);
        newMap.set(questionId, !newMap.get(questionId));
        setOpenSelected(newMap);
    }

    return (
        <div className="createEditSerieFormContainer">
            <CreateEditSerieFormHeader
                handleChange = {handleChange}
                serie = {serie}/>
            <div className="serieQuestionsContainer">
                <CreateEditSerieFormQuestionsAvailable
                    serie = {serie}
                    pagesData = {pagesData}
                    questions = {questions}
                    handleChangePage = {handleChangePage}
                    handleRowsPerPage = {handleRowsPerPage}
                    attributeQuestion = {attributeQuestion}/>
                <CreateEditSerieFormSelectedQuestions
                    serie = {serie}
                    openSelected = {openSelected}
                    setSerie = {setSerie}
                    removeSelectedQuestion = {removeSelectedQuestion}
                    setOpenSelectedPreview = {setOpenSelectedPreview}/>
            </div>
            <CreateEditSerieFormFooter
                serie = {serie}
                isEditing = {props.isEditing}
                changeCreator = {changeCreator}
                setSerie = {setSerie}/>
        </div>
    );
}

CreateEditSerieForm.propTypes = {
    serie: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        maxDuration: PropTypes.string,
        creator: PropTypes.object,
        randomSerie: PropTypes.number,
        questionReducedList: PropTypes.array
    }).isRequired,
    isEditing: PropTypes.bool.isRequired
};

export default CreateEditSerieForm;
