import { createTheme } from '@material-ui/core/styles';

export const collapsibleTheme = createTheme({
    overrides: {
        MuiAccordionDetails: {
            root: {
                display: 'flex',
                flexDirection: 'column',
                padding: '0 3% 15px 3%'
            }
        }
    }
});

export const collapsibleExpertTheme = createTheme({
    overrides: {
        MuiAccordionDetails: {
            root: {
                display: 'flex',
                flexDirection: 'column',
                padding: '0'
            }
        }
    }
});

export const buttonsTheme = createTheme({
    overrides: {
        MuiButton: {
            text: {
                padding: '3px 0',
                minWidth: '40px'
            }
        }
    }
});
