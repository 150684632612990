import React, { Component } from 'react';
import { setCategoryFilter, setFamilyFilter } from '../../../redux/actions/Filter/filter.actions';
import '../SidePanel.scss';
import { Checkbox } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectSkillsNumberByCategory, selectSkillsNumberByFamily } from '../../../redux/selectors/Skill/SkillSet/skillSet.selector';
import { selectCheckedCategories, selectCheckedFamilies } from '../../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';

export class SidePanelSkillEntry extends Component {

    changeHandler = (event) => {
        const { family, category } = this.props;
        let filters = this.props.areChecked;

        filters = event.target.checked
            ? filters.concat(this.props.label)
            : filters.filter((oldFilter) => oldFilter !== this.props.label);

        if(category) {
            this.props.setCategoryFilter(filters);
        }
        if(family) {
            this.props.setFamilyFilter(filters);
        }
    };

    render() {
        return (
            <div className="checkbox-container" data-testid="side-panel-skill-entry">
                <label>
                    <Checkbox
                        color="default"
                        className="checkbox"
                        checked={this.props.isChecked}
                        onChange={this.changeHandler}
                        data-testid="side-panel-skill-entry-checkbox"
                    />
                    <span>{this.props.label}
                        <Chip size="small" style={{
                            marginLeft: '2px',
                            fontSize: '10px',
                            height: '15px'
                        }} label={this.props.number}/>
                    </span>
                </label>
            </div>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { family, category } = props;

    if(family) {
        return {
            areChecked: selectCheckedFamilies(state),
            isChecked: selectCheckedFamilies(state).includes(props.label),
            number: selectSkillsNumberByFamily(state, props.label)
        };
    }
    if(category) {
        return {
            areChecked: selectCheckedCategories(state),
            isChecked: selectCheckedCategories(state).includes(props.label),
            number: selectSkillsNumberByCategory(state, props.label)
        };
    }

    return {};
};

export const mapDispatchToProps = (dispatch) => ({
    setCategoryFilter: (filters) => dispatch(setCategoryFilter(filters)),
    setFamilyFilter: (filters) => dispatch(setFamilyFilter(filters))
});

SidePanelSkillEntry.propTypes = {
    label: PropTypes.string.isRequired,
    family: PropTypes.bool,
    category: PropTypes.bool,
    isChecked: PropTypes.bool,
    areChecked: PropTypes.array,
    setCategoryFilter: PropTypes.func,
    setFamilyFilter: PropTypes.func,
    number: PropTypes.number
};

SidePanelSkillEntry.defaultProps = {
    family: false,
    category: false
};

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelSkillEntry);
