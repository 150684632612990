import { SET_DISPLAY_LEFT_DRAWER } from '../../actions/Display/display.actions';

export default function displayReducer(state = { displayLeftDrawer: false }, action) {
    switch (action.type) {
        case SET_DISPLAY_LEFT_DRAWER:
            return { ...state,
                displayLeftDrawer: action.payload };
        default:
            return state;
    }
}
