/* eslint-disable complexity */
import {
    SET_STUDENT_SELECTED,
    PATCH_CHAPTER_HEADER_SUCCESS,
    GET_CHAPTER_COURSES_BY_IDS_SUCCESS,
    RESET_CHAPTER_COURSES,

    SET_SELECTED_CHAPTER,
    REMOVE_ALL_CHAPTERS_IDS,
    ADD_SELECTED_CHAPTER_SKILL_VIEW_ID,
    ADD_SELECTED_CHAPTER_SKILL_VIEW_LIST,
    SET_DRAWER_SELECTED_CHAPTER,
    OPEN_CHAPTER_TREE,
    CLOSE_CHAPTER_TREE,
    OPEN_CHAPTER_REPORTING,
    CLOSE_CHAPTER_REPORTING,
    GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_SUCCESS,
    SET_SELECTED_REPORTED_CHAPTER,
    PATCH_CHAPTER_REPORTING_DENIED_SUCCESS,
    PATCH_CHAPTER_REPORTING_TREATED_SUCCESS,
    SET_SELECTED_OPTIONS_CHAPTER_DISPLAY,
    DELETE_TASK_FROM_CHAPTER_REPORTING
} from 'app/redux/actions/Chapter/chapter.actions';

export const chapterCourses = 'chapterCourses';
export const studentSelected = 'studentSelected';
export const nbQuestion = 'nbQuestion';
export const nbQuestionWithChildren = 'nbQuestionWithChildren';
export const selectedChapterId = 'selectedChapterId';
export const selectedChaptersSkillView = 'selectedChaptersSkillView';
export const drawerSelectedChapterId = 'drawerSelectedChapterId';
export const isChapterTreeOpen = 'isChapterTreeOpen';
export const isChapterReportingOpen = 'isChapterReportingOpen';
export const chapterReporting = 'chapterReporting';
export const selectedReportedChapter = 'selectedReportedChapter';
export const optionsChapterDisplay = 'optionsChapterDisplay';

export default function chapterReducer(state = {
    [chapterCourses]: [],
    [studentSelected]: null,
    [selectedChapterId]: 0,
    [selectedChaptersSkillView]: [],
    [drawerSelectedChapterId]: 0,
    [isChapterTreeOpen]: true,
    [isChapterReportingOpen]: false,
    [chapterReporting]: [],
    [selectedReportedChapter]: null,
    [optionsChapterDisplay]: 0
}, action) {
    switch (action.type) {
        case GET_CHAPTER_COURSES_BY_IDS_SUCCESS:
            return {
                ...state,
                [chapterCourses]: action.payload
            };
        case RESET_CHAPTER_COURSES:
            return {
                ...state,
                [chapterCourses]: []
            };
        case PATCH_CHAPTER_HEADER_SUCCESS:
            const chapterCoursesCopyToPatch = JSON.parse(JSON.stringify(state.chapterCourses));
            const chapterCoursesIndexToPatch = chapterCoursesCopyToPatch.findIndex((chapterCourse) => chapterCourse.id === action.payload.id);
            if(chapterCoursesIndexToPatch !== -1) {
                chapterCoursesCopyToPatch[chapterCoursesIndexToPatch] = action.payload;
            }
            return {
                ...state,
                [chapterCourses]: chapterCoursesCopyToPatch
            };
        case SET_STUDENT_SELECTED:
            return {
                ...state,
                [studentSelected]: action.payload
            };
        case SET_SELECTED_CHAPTER:
            return {
                ...state,
                [selectedChapterId]: action.payload
            };
        case ADD_SELECTED_CHAPTER_SKILL_VIEW_ID:
            let alreadyViewedChaptersUpdated = state[selectedChaptersSkillView].slice();
            if(alreadyViewedChaptersUpdated.find((chapter) => action.payload.id === chapter.id)) {
                alreadyViewedChaptersUpdated = alreadyViewedChaptersUpdated.filter((chapter) => action.payload.id !== chapter.id);
            } else {
                alreadyViewedChaptersUpdated = [...alreadyViewedChaptersUpdated, action.payload];
            }
            return {
                ...state,
                [selectedChaptersSkillView]: alreadyViewedChaptersUpdated
            };
        case ADD_SELECTED_CHAPTER_SKILL_VIEW_LIST:
            return {
                ...state,
                [selectedChaptersSkillView]: action.payload
            };
        case REMOVE_ALL_CHAPTERS_IDS:
            return {
                ...state,
                [selectedChaptersSkillView]: []
            };
        case SET_DRAWER_SELECTED_CHAPTER:
            return {
                ...state,
                [drawerSelectedChapterId]: action.payload
            };
        case OPEN_CHAPTER_TREE:
            return {
                ...state,
                [isChapterTreeOpen]: true,
                [isChapterReportingOpen]: false
            };
        case CLOSE_CHAPTER_TREE:
            return {
                ...state,
                [isChapterTreeOpen]: false
            };
        case OPEN_CHAPTER_REPORTING:
            return {
                ...state,
                [isChapterReportingOpen]: true,
                [isChapterTreeOpen]: state.chapterReporting.length > 0 ? false : state.isChapterTreeOpen
            };
        case CLOSE_CHAPTER_REPORTING:
            return {
                ...state,
                [isChapterReportingOpen]: false,
                [isChapterTreeOpen]: true
            };
        case GET_CHAPTERS_REPORTING_BY_CHAPTER_ID_SUCCESS:
            return {
                ...state,
                [chapterReporting]: action.payload
            };
        case SET_SELECTED_REPORTED_CHAPTER:
            return {
                ...state,
                [selectedReportedChapter]: action.payload
            };
        case PATCH_CHAPTER_REPORTING_DENIED_SUCCESS:
        case PATCH_CHAPTER_REPORTING_TREATED_SUCCESS:
            const reportingCopy = state[chapterReporting].slice();
            return {
                ...state,
                [chapterReporting]: reportingCopy.filter((report) => report.id !== action.payload)
            };
        case SET_SELECTED_OPTIONS_CHAPTER_DISPLAY:
            return {
                ...state,
                [optionsChapterDisplay]: action.payload
            };
        case DELETE_TASK_FROM_CHAPTER_REPORTING:

            const chapterReportingCopy = state[chapterReporting].slice();

            const report = chapterReportingCopy.find((report) => report.taskId === action.payload);
            
            if(report) {
                report.taskId = null;
            } 

            return {
                ...state,
                [chapterReporting]: chapterReportingCopy
            };
        default:
            return state;
    }
}

