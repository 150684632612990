/* eslint-disable max-lines */
import React, { useEffect, useState, useRef } from 'react';
import logoMNF from 'assets/images/logomnf.svg';

import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { OXA } from 'app/redux/reducers/Chat/chat.reducer';
import {
    getParcoursHistoryByUserId,
    updateParcoursId,
    getStatusByUserId
} from 'app/redux/actions/Parcours/parcours.actions';

import {
    getMorningQuestionToDo,
    postMorningQuestionsDone
} from 'app/redux/actions/Question/MorningQuestions/morningQuestion.actions';

import { addMessageToChat, clearMessage, resetChat } from 'app/redux/actions/Chat/chat.actions';
import { selectMessages, selectParcoursId } from 'app/redux/selectors/Chat/chat.selector';

import MenuLargeScreen from 'app/header/components/MenuLargeScreen';
import { Message } from 'app/chat/components/Message/Message';
import { getParcoursByIdApi } from 'app/api/parcoursApi';

import arrowRight from 'assets/images/arrow-right.png';
import arrowLeft from 'assets/images/arrow-left.png';
import './ChatContent.scss';
import { RETRIEVE_PARCOURS_ERROR } from 'app/Snackbar/NotificationMessages';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { selectStatusParcours } from '../../../redux/selectors/Parcours/parcours.selector';
import {
    selectMorningQuestion
} from 'app/redux/selectors/Question/morningQuestions.selector';
import {
    useStyles
} from '../../../question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer.functions';
import CircularProgressWithLabel from 'app/components/ProgressBar/CircularProgressWithLabel';
import ParcoursDisplay from '../Parcours/ParcoursDisplay';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import colors from 'styles/colors.module.scss';
import { closeDrawer } from 'app/redux/actions/Drawer/drawer.actions';

export function ChatContent() {

    const dispatch = useDispatch();
    const lastQuestionRef = useRef(null);
    const classes = useStyles();

    const messages = useSelector(selectMessages);
    const parcoursId = useSelector(selectParcoursId);
    const statusParcours = useSelector(selectStatusParcours);
    const morningQuestions = useSelector(selectMorningQuestion);

    const [parcoursStarted, setParcoursStarted] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(true);
    const [firstParcoursId, setFirstParcoursId] = useState();
    const [isViewLastQuestion, setViewLastQuestion] = useState(false);
    const [isQuestionOuverteRecording, setIsQuestionOuverteRecording] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [remediationOnGoing, setRemediationOnGoing] = useState(false);
    const [openParcours, setOpenParcours] = useState(-1);
    const [parcoursSelected, setParcoursSelected] = useState(null);
    const [isExamMode, setExamMode] = useState(false);

    const [hasMorningQuestion, setHasMorningQuestion] = useState(false);
    const [deadLine, setDeadLine] = useState(null);
    
    const userId = Number(AuthenticationContainer.getCurrentUserId());

    function scrollToLastMessage() {
        if(lastQuestionRef.current) {
            lastQuestionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    function isQuestion(message) {
        return message?.content?.content?.class === 'MultipleChoiceQuestionAsked' ||
            message?.content?.content?.class === 'QuestionOuverteAsked';
    }

    function getColor(value) {
        if(value >= 75) {
            return colors['chat-dark-blue'];
        } else if(value >= 50) {
            return colors['chat-blue'];
        }
        return colors['chat-light-blue'];
    }

    function handleClickParcours(id, index) {

        if(statusParcours[index].done) {
            return;
        }

        dispatch(updateParcoursId(id));
        getParcoursByIdApi(id).then((response) => {
            setParcoursSelected(response.data);
            setOpenParcours(id);
            dispatch(getParcoursHistoryByUserId(id));
            setParcoursStarted(true);
        })
            .catch(() => {
                dispatch(notificationError(RETRIEVE_PARCOURS_ERROR));
            });
    }

    function nextMorningQuestion() {

        if(deadLine > new Date()) {
            dispatch(getMorningQuestionToDo(userId));
        } else {
            dispatch(postMorningQuestionsDone(userId));
            dispatch(addMessageToChat({ sender: OXA,
                content: 'c\'est la fin de ces questions, vous pouvez poursuivre votre progression' }));
        }
    }

    useEffect(() => {
        dispatch(getStatusByUserId(userId));
    }, [messages]);

    useEffect(() => {
        dispatch(getMorningQuestionToDo(userId));
    }, []);

    useEffect(() => {
        if(morningQuestions && hasMorningQuestion !== true) {
            dispatch(addMessageToChat({ sender: OXA,
                content: 'Pour vous aider dans votre apprentissage,' +
            ' commençons la journée avec des questions sur les chapitres qui vous ont posé problème la veille' +
            ' ainsi que des compétences qui pourraient avoir besoin d\'un petit rappel' }));
            setHasMorningQuestion(true);
            const time = new Date();
            time.setMinutes(time.getMinutes() + 10);
            setDeadLine(time);
        }

        if((morningQuestions === null || morningQuestions === undefined ||
            morningQuestions === '') && hasMorningQuestion === true) {
            dispatch(addMessageToChat({ sender: OXA,
                content: 'c\'est la fin de ces questions, vous pouvez poursuivre votre progression' }));
        }

        if(morningQuestions && morningQuestions !== undefined) {
            const message = {};
            message.content = morningQuestions;
            dispatch(addMessageToChat({ sender: OXA, content: message }));
        }
    }, [morningQuestions]);


    useEffect(() => {
        scrollToLastMessage();
    }, [isViewLastQuestion, remediationOnGoing]);

    useEffect(() => {
        dispatch(closeDrawer());

        const discussionContainer = document.getElementById('discussion-container');
        const hiddenDiv = document.getElementById('historique');
        let isSwiped = false;

        discussionContainer.addEventListener('touchstart', () => {
            isSwiped = true;
            hiddenDiv.style.right = '40%';
        });

        discussionContainer.addEventListener('touchend', () => {
            if(isSwiped) {
                hiddenDiv.style.right = '100%';
                isSwiped = false;
            }
        });
        if(statusParcours[0]) {
            setFirstParcoursId(statusParcours[0].parcoursId);
        }
    }, []);

    useEffect(() => {
        if(parcoursStarted) {
            dispatch(clearMessage());
        }
    }, [parcoursId]);

    useEffect(() => {
        document.getElementById('discussion').scrollTo({
            top: document.getElementById('discussion').scrollHeight,
            behavior: 'smooth'
        });
    }, [messages]);

    useEffect(() => {
        setParcoursStarted(false);
        dispatch(resetChat());
    }, []);

    useEffect(() => {
        if(document.querySelectorAll('.message').length > 0) {
            document.getElementById('discussion').lastChild.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);


    return (
        <div id="flex-container">
            <div className="process-drawer-container">
                { isDrawerVisible && (
                    <div className="process-drawer">
                        <div className="process-drawer-title">
                            <img id="logoMNF" src = {logoMNF}/>
                            PARCOURS
                            {messages[0]?.content.id}
                        </div>
                        <div id="parcoursList">
                            {statusParcours?.map((elem, index) => <div key={index} style={{ backgroundColor: getColor((statusParcours[index]?.stepContent) / statusParcours[index]?.nbStepContent * 100), paddingBottom: '0.5px' }}>
                                <div className="return-div parcours"
                                    id={elem.id}
                                    onClick={() => handleClickParcours(elem.id, index)}
                                    style={{ display: 'flex', alignItems: 'center', cursor: statusParcours[index]?.done ? 'auto' : 'pointer' }}>
                                    <div style={{ marginLeft: '10px' }}>
                                        {elem.name}
                                    </div>
                                    <CircularProgressWithLabel
                                        value= {((statusParcours[index]?.stepContent) / statusParcours[index]?.nbStepContent * 100) > 100 ? 100 : ((statusParcours[index]?.stepContent) / statusParcours[index]?.nbStepContent * 100)}
                                        size={40}
                                        fontSize="0.6em"
                                    />
                                </div>
                                {openParcours === elem.id &&
                                    <ParcoursDisplay
                                        parcours={parcoursSelected}
                                        currentStepContent = {messages[messages.length - 1]?.content}
                                        isExamMode={isExamMode}
                                        setExamMode={setExamMode}/>
                                }
                            </div>)}
                        </div>
                        <div id="return-div">
                            <MenuLargeScreen />
                            {localStorage.getItem('givenName')}
                        </div>
                    </div>
                )}
                <div className="process-drawer-arrow-container">
                    <img className="process-drawer-arrow" src={isDrawerVisible ? arrowLeft : arrowRight} onClick={() => setIsDrawerVisible(!isDrawerVisible)} />
                </div>
            </div>
            <div id="discussion-container">
                <div id="discussion" className="flex full-width" style={{ position: 'relative' }}>
                    {isExamMode && <div className="diagonal"> Mode examen </div>}
                    {messages.map((message, index) => (
                        <Message
                            message={message}
                            key={index}
                            ref={isQuestion(message) ? lastQuestionRef : null}
                            parcoursStarted = {parcoursStarted}
                            firstParcoursId = {firstParcoursId}
                            isQuestionOuverteRecording={isQuestionOuverteRecording}
                            setIsQuestionOuverteRecording={setIsQuestionOuverteRecording}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setRemediationOnGoing = {setRemediationOnGoing}
                            isExamMode={isExamMode}
                            nextMorningQuestion = {nextMorningQuestion}
                            parcoursId = {parcoursId}
                        />
                    ))}
                    <div className="bouton-scroll-top" >
                        <IconButton onClick={() => setViewLastQuestion(!isViewLastQuestion)} size="small" style={{ color: 'white' }} >
                            <ExpandLessIcon/>
                        </IconButton>
                    </div>
                </div>
                {
                    (isLoading || remediationOnGoing) &&
                        <div id="bottom-container" className="flex-row full-width" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <CircularProgress className={classes.loader}/>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}
