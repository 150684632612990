import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Warning, CheckBox, Error } from '@material-ui/icons';
import { CardActionArea } from '@material-ui/core';
import './CustomSnackbar.scss';

export const green = '#43a047';
export const red = '#d32f2f';
export const yellow = '#ffa000';


const CustomSnackbar = React.forwardRef((props, ref) => {

    function icon() {
        const { variant } = props;
        switch (variant) {
            case 'success':
                return <CheckBox id="checkbox-icon" data-testid="checkbox" className="snackbar-icon" />;
            case 'warning':
                return <Warning id="warning-icon" data-testid="warning" className="snackbar-icon" />;
            case 'error':
                return <Error id="error-icon" data-testid="error" className="snackbar-icon" />;
            default:
                return null;
        }
    }

    function bgColor() {
        const { variant } = props;
        switch (variant) {
            case 'success':
                return green;
            case 'warning':
                return yellow;
            case 'error':
                return red;
            default:
                return null;
        }
    }

    const { closeSnackbar } = useSnackbar();
    const { message, id } = props;

    return (
        <Card className="snackbar-card" data-testid="custom-snackbar" ref={ref}>
            <CardActionArea className="snackbar-cardActionArea" data-testid="snackbar-card-action-area" onClick={() => closeSnackbar(id)}>
                <div className="snackbar-container" data-testid="snackbar-container" style={{ backgroundColor: bgColor() }}>
                    {icon()}
                    <Typography variant="subtitle2" className="snackbar-typography">{message}</Typography>
                </div>
            </CardActionArea>
        </Card>
    );
});

CustomSnackbar.propTypes = {
    id: PropTypes.number,
    message: PropTypes.string,
    variant: PropTypes.string
};

export default CustomSnackbar;
