import { newGreatestKey } from '../CreateEditQuestionForm.functions';

const letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export function deleteAnswer(mcq, setMcq, answerToDel) {
    const copy = { ...mcq };
    const answerToDelete = copy.answers.find((answer) => answer.label === answerToDel.label);
    if(answerToDelete !== undefined) {
        copy.answers.splice(mcq.answers.indexOf(answerToDelete), 1);
        copy.answers = copy.answers.map((answer, index) => (
            {
                ...answer,
                label: letter[index]
            }
        ));
    }
    setMcq(copy);
}

export function addAnswer(mcq, setMcq, setAnswersLoading, setSelectedButtons) {
    const numberOfAnswer = mcq.answers.length;
    setAnswersLoading((prevAnswersLoading) => [
        ...prevAnswersLoading,
        false,
    ]);
    setSelectedButtons((prevSelectedButtons) => [
        ...prevSelectedButtons,
        false,
    ]);
    if(numberOfAnswer < 8) {
        const copy = { ...mcq };
        const newAnswer = {
            id: undefined,
            key: newGreatestKey(mcq),
            label: letter[numberOfAnswer],
            text: '',
            validAnswer: false
        };
        copy.answers.push(newAnswer);
        setMcq(copy);
    }
}

export function handleAnswerValidityChange(answerTargeted, mcq, setMcq) {
    const copy = { ...mcq };
    const answerToEdit = copy.answers.find((answer) => answer.label === answerTargeted.label);
    if(answerToEdit) {
        const newAnswer = {
            ...answerToEdit,
            validAnswer: !answerToEdit.validAnswer
        };
        copy.answers.splice(mcq.answers.indexOf(answerToEdit), 1, newAnswer);
    }
    setMcq(copy);
}

// Answer Validity
function mcqAnswerExist(answerTargeted) {
    return answerTargeted?.text.length > 0;
}

export function mcqAnswerIsValid(answerTargeted) {
    return Boolean(answerTargeted) && mcqAnswerExist(answerTargeted);
}

export function atLeastOneMcqAnswerValid(mcq) {
    if(mcq) {
        return mcq.answers.filter((answer) => answer.validAnswer).length >= 1;
    }
    return false;
}

