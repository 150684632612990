import { createSelector } from 'reselect';
import { clientSectorFilterStore, clientUserFilterStore } from '../../../reducers/Client/ClientFilter/clientsFilter.reducer';
import { clientsFilterStore } from '../../../rootReducer';
import { selectAllClients } from '../clients.selector';
import { selectAllProjects } from '../../Project/projects.selector';
import { arraysIntersection } from '../../../../utils/functions';

export const selectClientFilterSector = (state) => state[clientsFilterStore][clientSectorFilterStore];
export const selectClientFilterUser = (state) => state[clientsFilterStore][clientUserFilterStore];
export const selectClientsFilteredBySector = createSelector(
    [
        selectClientFilterSector,
        selectAllClients
    ],
    (searchSector, clients) => {
        if(searchSector) {
            return clients.filter((client) => client.sector && client.sector.id === searchSector.value);
        }
        return clients;
    }
);


export function hasRequiredUserClient(client, usersId, projects) {
    const allProjectsOfClient = projects.filter((project) => project.client.id === client.id);
    const allMissionsOfClient = allProjectsOfClient.flatMap((project) => project.listMission);
    const allExcilyens = allMissionsOfClient.flatMap((mission) => mission.listUser);
    const allExcilyensId = allExcilyens.map((excilyen) => excilyen.id);
    return allExcilyensId.some((id) => usersId.includes(id));
}

export const selectClientFilteredByUser = createSelector(
    [
        selectClientFilterUser,
        selectAllClients,
        selectAllProjects
    ],
    (users, clients, projects) => {
        if(Array.isArray(users) && users.length && Array.isArray(projects.data) && projects.data.length) {
            const usersId = users.map((user) => user.value);
            return clients.filter((client) => hasRequiredUserClient(client, usersId, projects.data));
        }
        return clients;
    }
);

export const selectClientFilteredBySectorAndUser = createSelector(
    [
        selectClientsFilteredBySector,
        selectClientFilteredByUser
    ],
    arraysIntersection
);
