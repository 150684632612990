import React, { useState } from 'react';
import PropTypes from 'prop-types';
import colors from 'styles/colors.module.scss';

import { Collapse, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { CheckCircleRounded, CloseRounded, ExpandLess, ExpandMore } from '@material-ui/icons';
import { postHistoryPaginatedApi } from 'app/api/historyApi';
import DrawerHistorySerieDisplay from './DrawerHistorySerieDisplay';
import DrawerHistoryQuestionDisplay from './DrawerHistoryQuestionDisplay';
import { formatTime } from 'app/utils/date.functions';

export default function DrawerHistoryGroupDisplay(props) {

    const [showHistory, setShowHistory] = useState(false);
    const [history, setHistory] = useState();
    const [hasFetchedHistory, setHasFetchedHistory] = useState(false);

    const pageData = {
        pageNumber: 0,
        rowPerPage: 300,
        totalElement: 0
    };

    const buildPage = () => {
        const date = new Date(props.groupHistory.date);
        const tomorrow = new Date(props.groupHistory.date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const page = {
            criteria: {
                fromWhenDate: date,
                toWhenDate: tomorrow,
            },
            offset: 0,
            step: pageData.rowPerPage,
            historyType: 'QUESTION'
        };

        return page;
    };

    const fetchHistory = () => {
        const page = buildPage();
        page.criteria.userId = props.groupHistory.userId;
        postHistoryPaginatedApi(page).then((response) => {
            setHistory(response.data);
        });
    };

    const handleCollapse = () => {
        if(!hasFetchedHistory) {
            fetchHistory();
            setHasFetchedHistory(true);
        }
        setShowHistory(!showHistory);
    };
    return (
        <div>
            {(props.groupHistory.goodAnswers || props.groupHistory.badAnswers)
                ? <>
                    <ListItem>
                        <ListItemText 
                            primary={`${props.groupHistory.firstName} ${props.groupHistory.lastName}
                            lancé à ${formatTime(props.groupHistory.timeFirstQuestionAnswered)}`}
                            style={{ display: 'box', alignItems: 'center' }} 
                        />
                        <ListItemIcon style={{ minWidth: 'auto' }}>
                            <CheckCircleRounded style={{ color: colors['green-color-700'] }} />
                        </ListItemIcon>
                        <ListItemText primary={`${props.groupHistory.goodAnswers}`} />
                        &nbsp;
                        <ListItemIcon style={{ minWidth: 'auto' }}>
                            <CloseRounded style={{ color: colors['red-color-400'] }} />
                        </ListItemIcon>
                        <ListItemText primary={`${props.groupHistory.badAnswers}`} />
                        <IconButton onClick={handleCollapse}>
                            {showHistory ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </ListItem>
                </>
                : <ListItem>
                    <ListItemText 
                        primary={`${props.groupHistory.firstName} ${props.groupHistory.lastName} : aucune activité`} 
                    />
                </ListItem>
            }
            <Collapse in={showHistory}>
                {history && history.listOfElements.length > 0
                    ? history.listOfElements?.map((rowData) => (
                        rowData.dataType === 'SERIE'
                            ? <DrawerHistorySerieDisplay
                                key={`serie${rowData.id}:${rowData.date}`}
                                serieHistory={rowData}
                                onClickQuestion={props.onClickQuestion}
                            />
                            : <DrawerHistoryQuestionDisplay
                                key={`question${rowData.id}:${rowData.date}`}
                                questionHistory={rowData}
                                onClickQuestion={props.onClickQuestion}
                            />
                    ))
                    : 'Aucun historique' }
            </Collapse>
        </div>
    );    
}

DrawerHistoryGroupDisplay.propTypes = {
    groupHistory: PropTypes.object.isRequired,
    onClickQuestion: PropTypes.func.isRequired
};
