import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { openDrawer } from 'app/redux/actions/Drawer/drawer.actions';


import './Tasks.scss';

const useStyles = makeStyles(() => ({
    hide: {
        display: 'none'
    }
}));

function Tasks(props) {

    const classes = useStyles();

    return (
        <IconButton
            color="inherit"
            className={clsx(props.open && classes.hide)}
            data-testid="drawer-button"
            onClick={props.handleTaskDrawerOpen}
        >
            <LibraryBooksIcon />
        </IconButton>
    );
}

Tasks.propTypes = {
    open: PropTypes.bool.isRequired,
    handleTaskDrawerOpen: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        handleTaskDrawerOpen: () => {
            dispatch(openDrawer());
        }
    };
}

export default connect(null, mapDispatchToProps)(Tasks);
