import { getDailyPreferencesApi, postDailyPreferencesApi } from '../../../../../api/dailyPreferencesApi';
import { notificationError, notificationSuccess } from '../../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    GET_DAILY_PREFERENCES_ERROR,
    POST_DAILY_PREFERENCES_ERROR,
    DAILY_PREFERENCES_UPDATED
} from '../../../../../Snackbar/NotificationMessages';

export const GET_DAILY_PREFERENCES_REQUEST = 'GET_DAILY_PREFERENCES_REQUEST';
export const GET_DAILY_PREFERENCES_FAILURE = 'GET_DAILY_PREFERENCES_FAILURE';
export const GET_DAILY_PREFERENCES_SUCCESS = 'GET_DAILY_PREFERENCES_SUCCESS';

export const POST_DAILY_PREFERENCES_REQUEST = 'POST_DAILY_PREFERENCES_REQUEST';
export const POST_DAILY_PREFERENCES_FAILURE = 'POST_DAILY_PREFERENCES_FAILURE';
export const POST_DAILY_PREFERENCES_SUCCESS = 'POST_DAILY_PREFERENCES_SUCCESS';

export const getDailyPreferences = (userId) => (dispatch) => {
    dispatch({ type: GET_DAILY_PREFERENCES_REQUEST });

    return getDailyPreferencesApi(userId).then((response) => {
        dispatch({
            type: GET_DAILY_PREFERENCES_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_DAILY_PREFERENCES_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_DAILY_PREFERENCES_ERROR));
        }
    });
};

export const postDailyPreferences = (dailyPreference) => (dispatch) => {
    dispatch({ type: POST_DAILY_PREFERENCES_REQUEST });

    return postDailyPreferencesApi(dailyPreference).then((response) => {
        dispatch({
            type: POST_DAILY_PREFERENCES_SUCCESS,
            payload: response.data
        });
        dispatch(notificationSuccess(DAILY_PREFERENCES_UPDATED));
    }, (error) => {
        dispatch({
            type: POST_DAILY_PREFERENCES_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError((POST_DAILY_PREFERENCES_ERROR)));
        }
    });
};
