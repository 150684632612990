
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, createTheme, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import './CandidateResult.scss';

import { selectSerieHistoryCandidate } from 'app/redux/selectors/Question/history.selector';
import { getSerieHistoryCandidateByCandidateId, resetSerieHistoryCandidate } from 'app/redux/actions/Question/History/history.actions';
import { optionsTooltipHours } from 'app/utils/OptionsTooltipEnum';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { patchStatus } from 'app/redux/actions/Candidate/candidate.actions';

export default function CandidateResult(props) {

    const dispatch = useDispatch();

    const resultCandidate = useSelector(selectSerieHistoryCandidate);
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(getSerieHistoryCandidateByCandidateId(props.candidate.uuid));
    }, [props.candidate.uuid]);

    const onCloseDialog = () => {
        dispatch(resetSerieHistoryCandidate());
        props.onClose();
    };

    const mapDateToString = (date) => {
        if(date) {
            const dateFormat = new Date(date);
            const dateString = `${new Intl.DateTimeFormat('fr-FR').format(dateFormat)} à ${new Intl.DateTimeFormat('fr-FR', optionsTooltipHours).format(dateFormat)}`;
            return dateString;
        }
        return '';
    };

    const clickButtons = (status) => {
        dispatch(patchStatus(props.candidate.uuid, status));
        props.onClose();
    };

    const themeButtons = createTheme({
        palette: {
            primary: {
                main: '#d00000'
            },
            secondary: {
                main: '#009000'
            }
        }
    });

    return (
        <Dialog
            open={props.open}
            onClose={onCloseDialog}
            maxWidth="xl"
        >
            {
                (
                    currentUser?.roles?.some((role) => role.name === 'VALIDATEUR_CANDIDAT') &&
                    props.candidate.status === 'DONE'
                ) &&
                <div className="buttons">
                    <ThemeProvider theme={themeButtons}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                clickButtons('REJECTED');
                            }}
                        >
                            <CloseIcon/> disqualifie
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                clickButtons('NEXT_STEP');
                            }}
                        >
                            <CheckIcon/> etape suivante
                        </Button>
                    </ThemeProvider>
                </div>
            }
            <TableContainer >
                <Table className="tableResult" style={{ width: 800 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Test passé le {mapDateToString(resultCandidate?.date)}
                            </TableCell>
                            <TableCell> Nombre de réponses correctes </TableCell>
                            <TableCell> Nombre de réponses fausses </TableCell>
                            <TableCell> Nombre de réponses non traités </TableCell>
                            <TableCell> Succès(%) </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={'total'}>
                            <TableCell><strong> Total </strong></TableCell>
                            <TableCell> <strong>{resultCandidate?.goodAnswers}</strong> </TableCell>
                            <TableCell> <strong>{resultCandidate?.badAnswers}</strong> </TableCell>
                            <TableCell> <strong>{resultCandidate?.questionsNotDone}</strong> </TableCell>
                            <TableCell> <strong>{resultCandidate?.resultRate}</strong> </TableCell>
                        </TableRow>
                        {resultCandidate?.historySerieGrouped.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell> {row.name} </TableCell>
                                <TableCell> {row.goodAnswers} </TableCell>
                                <TableCell> {row.badAnswers} </TableCell>
                                <TableCell> {row.questionsNotDone} </TableCell>
                                <TableCell> {row.resultRate} </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Dialog>
    );

}

CandidateResult.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired
};
