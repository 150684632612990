import { students, coaches, studentsAskingRelation, userAskedCoaches } from '../../reducers/Coaching/coaching.reducer';
import { coachingStore } from '../../rootReducer';

export function selectUserCoaches(state) {
    return state[coachingStore][coaches];
}

export function selectUserStudents(state) {
    return state[coachingStore][students];
}

export function selectUserAskedCoaches(state) {
    return state[coachingStore][userAskedCoaches];
}

export function selectStudentsAskingRelation(state) {
    return state[coachingStore][studentsAskingRelation];
}


