import React from 'react';
import PropTypes from 'prop-types';

import colors from 'styles/colors.module.scss';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, ListItem } from '@material-ui/core';

function getQuestionIcon(questionHistory) {
    if(questionHistory.result === null) {
        return null;
    } else if(questionHistory.result === 'TRUE' || (questionHistory.result === 'GRADED' && questionHistory.grade >= 70)) {
        return <DoneIcon style={{ color: colors['green-color-700'] }} data-testid="valid"/>;
    }
    return <CloseIcon style={{ color: colors['red-color-400'] }} data-testid="incorrect"/>;

}

export function QuestionResult({ questionHistory }) {
    return (
        <ListItem>
            <Grid
                container
                direction="row"
            >
                <Grid>
                    {getQuestionIcon(questionHistory)}
                </Grid>
                <Grid>
                    {questionHistory.title}
                </Grid>
            </Grid>
        </ListItem>
    );
}

QuestionResult.propTypes = {
    questionHistory: PropTypes.object.isRequired
};

export default QuestionResult;
