

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Title.scss';

import Typography from '@material-ui/core/Typography';

import { setCategoryFilter, setFamilyFilter, setSearchFilter } from '../../redux/actions/Filter/filter.actions';
import { connect } from 'react-redux';
import {
    setExcilyensSearchFilter,
    setExcilyensSkillsFilter,
    setPromoFilter
} from '../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import logoExciskillsWhite from 'assets/images/logo-exciskills-white.svg';

export function Title(props) {
    return (
        <Typography variant="h6" className="header-title">
            {props.isCandidateHeader
                ? <MnfTitle />
                : <NavLink to="/skills" onClick={props.resetSearchAndFilters}>
                    <MnfTitle isDrawerOpen={props.isDrawerOpen} />
                </NavLink>
            }
        </Typography>
    );
}

Title.propTypes = {
    resetSearchAndFilters: PropTypes.func,
    isCandidateHeader: PropTypes.bool,
    isDrawerOpen: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
    return {
        resetSearchAndFilters: () => {
            dispatch(setSearchFilter(''));
            dispatch(setCategoryFilter([]));
            dispatch(setFamilyFilter([]));
            dispatch(setPromoFilter([]));
            dispatch(setExcilyensSearchFilter(''));
            dispatch(setExcilyensSkillsFilter([]));
        }
    };
}

export default connect(null, mapDispatchToProps)(Title);

function MnfTitle({ isDrawerOpen }) {

    const [classes, setClasses] = useState('title-text');

    useEffect(() => {
        if(isDrawerOpen) {
            setClasses('title-text drawer-open');
        } else {
            setClasses('title-text');
        }
    }, [isDrawerOpen]);

    return (
        <div className="title-nav-link">
            <img
                className="logo-MyNeuroFactory"
                alt="My Neuro Factory logo"
                src={logoExciskillsWhite} />
            <span className={classes}>
                MyNeuroFactory
            </span>
        </div>
    );
}

MnfTitle.propTypes = {
    isDrawerOpen: PropTypes.bool
};
