import React from 'react';
import PropTypes from 'prop-types';

import QuestionContainer from 'app/question/QuestionResolver/QuestionResolverWithCourse/QuestionContainer/QuestionContainer';
import QuestionRequestByQuestionId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByQuestionId';
import CreateEditQuestionForm from 'app/question/createEditQuestion/createEditQuestionForm/CreateEditQuestionForm';
import ChapterResourcesSummaryDisplay from '../ChapterResourcesSummary/ChapterResourcesSummaryDisplay';
import SkillHeader from '../../SkillHeader';

function ChapterContent(props) {

    const toggleIsAddingQuestion = () => {
        props.setIsAddingQuestion(!props.isAddingQuestion);
    };

    const closeEditQuestion = () => {
        props.setShowEditQuestion(!props.showEditQuestion);
    };

    if(props.selectedQuestion) {
        if(props.questionAnswer) {
            return <QuestionContainer
                questionAsked={props.questionAnswer}
                questionHistory={props.questionHistory}
                selectedAttemptIndex={props.selectedAttemptIndex}
                setSelectedAttemptIndex={props.setSelectedAttemptIndex}
                disableAnswers
                disableCourse
                isOnChapterContent
                handleOnEditQuestion={props.handleOnEditQuestion}
                handleOnClickHistoryResult={props.handleOnClickHistoryResult}
                onCloseQuiz={props.handleOnClickBackButton}
                isInHistory={props.questionHistory && props.questionHistory.length !== 0}
            />;
        }
        return <QuestionRequestByQuestionId
            questionId={props.selectedQuestion}
            questionHistory={props.questionHistory}
            selectedAttemptIndex={props.selectedAttemptIndex}
            setSelectedAttemptIndex={props.setSelectedAttemptIndex}
            hideReturnButton
            disableCourse
            isOnChapterContent
            handleOnEditQuestion={props.handleOnEditQuestion}
            handleOnClickHistoryResult={props.handleOnClickHistoryResult}
            chapterId = {props.chapterSelected?.id}
        />;
    } else if(props.showEditQuestion) {
        return <CreateEditQuestionForm
            idQuestion={props.showEditQuestion}
            isEditing
            isOnChapterContent
            onCloseCreateEdit={closeEditQuestion}
            onFinishEditing={props.onFinishEditing}
        />;
    } else if(props.isAddingQuestion) {
        return <CreateEditQuestionForm
            onCloseCreateEdit={toggleIsAddingQuestion}
            isOnChapterContent
            skillLink={props.skill}
            chapterSelected={props.chapterSelected}
        />;
    } else if(props.chapterSelected) {
        return <ChapterResourcesSummaryDisplay
            chapter={props.chapterSelected}
            skill={props.skill}
            permission={props.permission}
            bookmark={props.bookmark}
            isModerator={props.isModerator}
            sizeUpMd={props.sizeUpMd}
            reportNumber={props.reportNumber}
        />;
    }

    if(props.sizeUpMd) {
        return <SkillHeader
            skill={props.skill}
            isModerator={props.isModerator}
            sizeUpMd={props.sizeUpMd}
        />;
    }

    if(props.sizeUpMd) {
        return <CreateEditQuestionForm
            skillLink={props.skill}
            chapterSelected={props.chapterSelected}
        />;
    }

    return null;
}

ChapterContent.propTypes = {
    isAddingQuestion: PropTypes.bool,
    setIsAddingQuestion: PropTypes.func,
    showEditQuestion: PropTypes.number,
    setShowEditQuestion: PropTypes.func,
    skill: PropTypes.object,
    chapterSelected: PropTypes.object,
    permission: PropTypes.bool,
    selectedQuestion: PropTypes.number,
    handleOnEditQuestion: PropTypes.func.isRequired,
    onFinishEditing: PropTypes.func,
    questionAnswer: PropTypes.object,
    questionHistory: PropTypes.array,
    handleOnClickHistoryResult: PropTypes.func,
    handleOnClickBackButton: PropTypes.func,
    selectedAttemptIndex: PropTypes.number,
    setSelectedAttemptIndex: PropTypes.func,
    bookmark: PropTypes.object,
    isModerator: PropTypes.bool,
    sizeUpMd: PropTypes.bool,
    reportNumber: PropTypes.object,
    handleQuestionGeneration: PropTypes.object
};

export default ChapterContent;
