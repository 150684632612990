import { React, useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { getNextParcoursHistoryByUserId, getRemediationByMicroskillsIds } from 'app/redux/actions/Parcours/parcours.actions';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import oxa from 'assets/images/Logochatbot.svg';
import '../Message.scss';

export function RemediationChoice(props) {

    const dispatch = useDispatch();

    const content = 'Pour quels microskills veux tu de la remédiation ?';

    const [validationButton, setValidationButton] = useState(true);


    const [microskillsChecked, setMicroskillsChecked] = useState(new Array(props.questionMicroskills.length).fill(false));

    const handleChangeMicroskilslChecked = (event, index) => {
        
        const copyMicroskillsChecked = [...microskillsChecked];

        copyMicroskillsChecked[index] = !microskillsChecked[index];

        setMicroskillsChecked(copyMicroskillsChecked);

    };

    const handleChangeAllMicroskilslChecked = () => {
        
        if(microskillsChecked.every(mc => mc === true)) {
            setMicroskillsChecked([...microskillsChecked].map(() => false ));
        } else {
            setMicroskillsChecked([...microskillsChecked].map(() => true ));
        }
    };

    const sendMicroskills = () => {
        props.setRemediationOnGoing(true);

        if(microskillsChecked.every(mc => mc === false)) {
            dispatch(getNextParcoursHistoryByUserId(props.parcoursId));
        } else {
            const microskillsIds = props.questionMicroskills.map( mc => mc.id);
            dispatch(getRemediationByMicroskillsIds(props.parcoursId, microskillsIds.filter((id, index) => microskillsChecked[index]) ));
        }
        props.setRemediationOnGoing(false);

        setValidationButton(false);

    };

    return (

        <div className="message oxa">
            <img id="avatar" src={oxa} />
            <div className="header-chat-bot" />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div dangerouslySetInnerHTML={{ __html: content.replace(/\n/gu, '<br>') }} className="content bold" />
            </div>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={microskillsChecked.every(mc => mc === true)}
                        indeterminate={!microskillsChecked.every(mc => mc === true)}
                        onChange={handleChangeAllMicroskilslChecked}
                        className="remediation-choice"
                    />
                }
            />

            <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(2, 1fr)', 
                gap: 2,
                ml: 3 
            }}>

                {props.questionMicroskills.map((mc, key) =>
                    <FormControlLabel style = {{ fontSize: '200px' }}
                        label={mc.name}
                        key = {key}
                        control={
                            <Checkbox checked={microskillsChecked[key]} 
                                onChange={(event) => handleChangeMicroskilslChecked(event, key) }
                                className="remediation-choice"
                            />}      
                    />
                    
                )}

                {validationButton && <div className="validation-button">
                    <IconButton
                        style={{
                            bottom: '0',
                            right: '0',
                            position: 'absolute',
                            color: '#0076ba',
                            transform: 'translate(50%, 50%)'
                        }}
                        onClick={sendMicroskills}>
                        <CheckCircleIcon style={{ fontSize: '2.3rem' }}/>
                    </IconButton>
                </div> }
    
            </Box>

        </div>
    );
}

RemediationChoice.propTypes = {
    questionMicroskills: PropTypes.object,
    parcoursId: PropTypes.number,
    setRemediationOnGoing: PropTypes.func

};