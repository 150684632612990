/* eslint-disable complexity */
import {
    GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_SUCCESS,
    GET_SERIE_BY_SERIE_ID_SUCCESS,
    RESET_SERIE_WITH_QUESTION_REDUCED,
    RESET_SERIE_MODIFICATION_SAVED,
    RESET_SERIE_TO_RESOLVE,
    GET_RANDOM_SERIE_BY_CHAPTER_ID_SUCCESS,
    GET_SPACED_REPETITION_SERIE_BY_USER_ID_SUCCESS,
    GET_SERIES_BY_CRITERIA_TO_DISPLAY_SUCCESS,
    SET_QUESTION_LIST_TO_RESOLVE,
    SET_QUESTION_INDEX_TO_RESOLVE,
    GET_SERIE_BY_SERIE_HISTORY_ID_SUCCESS,
    SET_EXAM_MODE,
    QUESTION_INACTIVE
} from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';

import {
    ADD_SERIE_FAILURE, ADD_SERIE_SUCCESS,
    EDIT_SERIE_FAILURE, EDIT_SERIE_SUCCESS,
    SET_PAGE_PARAMS,
    RESET_PAGE_PARAMS,
    ADD_QUESTION_AUDIO_BLOB_URL,
    ADD_QUESTION_AUDIO_TEXT_ANSWER
} from 'app/redux/actions/Question/Serie/SerieSet/serieSet.actions';

export const seriesToDisplayStore = 'seriesToDisplayStore';
export const serieToResolve = 'serieToResolve';
export const questionListToResolve = 'questionListToResolve';
export const questionIndexToResolve = 'questionIndexToResolve';
export const serieWithQuestionReducedStore = 'serieWithQuestionReduced';
export const serieModificationSavedStore = 'serieModificationSaved';
export const seriePageParams = 'seriePageParams';
export const isExamMode = 'isExamMode';

const getDefaultPageParam = () => ({
    pageNum: 0,
    rowPerPage: 10
});

export default function seriesReducer(state = {
    [seriesToDisplayStore]: [],
    [serieToResolve]: null,
    [questionListToResolve]: [],
    [questionIndexToResolve]: 0,
    [serieWithQuestionReducedStore]: new Map(),
    [serieModificationSavedStore]: null,
    [seriePageParams]: getDefaultPageParam(),
    [isExamMode]: false
}, action) {
    switch (action.type) {
        case GET_RANDOM_SERIE_BY_CHAPTER_ID_SUCCESS:
        case GET_SPACED_REPETITION_SERIE_BY_USER_ID_SUCCESS:
        case GET_SERIE_BY_SERIE_ID_SUCCESS:
            const questionList = action.payload.questionAskedList.map((question, index) => (
                {
                    ...question,
                    initIndex: index,
                    isMark: false
                }
            ));
            return {
                ...state,
                [serieToResolve]: action.payload,
                [questionListToResolve]: questionList
            };
        case SET_QUESTION_LIST_TO_RESOLVE:
            return {
                ...state,
                [questionListToResolve]: action.payload
            };
        case SET_QUESTION_INDEX_TO_RESOLVE:
            return {
                ...state,
                [questionIndexToResolve]: action.payload
            };
        case RESET_SERIE_TO_RESOLVE:
            return {
                ...state,
                [serieToResolve]: null,
                [questionListToResolve]: [],
                [questionIndexToResolve]: 0
            };
        case GET_SERIES_BY_CRITERIA_TO_DISPLAY_SUCCESS:
            return {
                ...state,
                [seriesToDisplayStore]: action.payload
            };
        case GET_SERIE_BY_ID_WITH_QUESTION_REDUCED_SUCCESS:
            const newMap = new Map(state[serieWithQuestionReducedStore]);
            newMap.set(action.payload.id, action.payload.data);
            return {
                ...state,
                [serieWithQuestionReducedStore]: newMap
            };
        case RESET_SERIE_WITH_QUESTION_REDUCED:
            state[serieWithQuestionReducedStore].clear();
            return {
                ...state,
                [serieWithQuestionReducedStore]: new Map(state[serieWithQuestionReducedStore])
            };
        case ADD_SERIE_SUCCESS:
        case EDIT_SERIE_SUCCESS:
            return {
                ...state,
                [serieModificationSavedStore]: true
            };
        case ADD_SERIE_FAILURE:
        case EDIT_SERIE_FAILURE:
            return {
                ...state,
                [serieModificationSavedStore]: false
            };
        case RESET_SERIE_MODIFICATION_SAVED:
            return {
                ...state,
                [serieModificationSavedStore]: null
            };
        case SET_PAGE_PARAMS:
            return {
                ...state,
                [seriePageParams]: action.payload
            };
        case RESET_PAGE_PARAMS:
            return {
                ...state,
                [seriePageParams]: getDefaultPageParam()
            };
        case GET_SERIE_BY_SERIE_HISTORY_ID_SUCCESS:
            const questionNotDoneList = action.payload.questionsNotDone.map((questionResume, index) => (
                {
                    ...questionResume.question,
                    initIndex: index,
                    isMark: false
                }
            ));
            return {
                ...state,
                [serieToResolve]: action.payload,
                [questionListToResolve]: questionNotDoneList
            };
        case SET_EXAM_MODE:
            return {
                ...state,
                [isExamMode]: action.payload
            };
        case ADD_QUESTION_AUDIO_BLOB_URL:
        case ADD_QUESTION_AUDIO_TEXT_ANSWER: {
            const { questionId } = action.payload;
            const questionIndex = state[questionListToResolve].findIndex(question => question.id === questionId);

            if(questionIndex !== -1) {
                const updatedQuestions = state[questionListToResolve].map((question, index) => {
                    if(index !== questionIndex) {
                        return question;
                    }

                    const update = action.type === ADD_QUESTION_AUDIO_BLOB_URL
                        ? { audioBlobUrl: action.payload.audioBlobUrl }
                        : { audioTextAnswer: action.payload.audioTextAnswer };

                    return { ...question, ...update };
                });

                return {
                    ...state,
                    [questionListToResolve]: updatedQuestions
                };
            }

            return state;
        };
        case QUESTION_INACTIVE:
            const questionIndex = action.payload;

            const newQuestionListToResolve = state[questionListToResolve];

            if( newQuestionListToResolve.length !==0){
            
                newQuestionListToResolve[questionIndex].status = 'INACTIVE'; }

            return {
                ...state,
                [questionListToResolve] : newQuestionListToResolve
            };
        default:
            return state;
    }
}
