import { React, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import '../Message.scss';
import oxa from 'assets/images/Logochatbot.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getNextParcoursHistoryByUserId } from 'app/redux/actions/Parcours/parcours.actions';
import { selectParcours } from 'app/redux/selectors/Parcours/parcours.selector';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton } from '@material-ui/core';
import { putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi } from 'app/api/multipleChoiceQuestionApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { inputRegex } from 'app/question/createEditQuestion/createEditQuestionForm/body/QuestionATrou/QuestionATrou.functions';
import { createElement, getTrouInputId, invalidTrouColor, validTrouColor } from 'app/utils/QuestionUtils';
import QuestionATrouDisplay from './QuestionATrouDisplay';
import { addMessageToChat } from 'app/redux/actions/Chat/chat.actions';
import CourseUnderStandardQuestion from './CourseUnderStandardQuestion';

export function MessageQuestionTexteATrou(props) {

    const dispatch = useDispatch();
    const parcoursId = useSelector(selectParcours);


    const questionDisplayRef = useRef(null);
    const [displayedStatement, setDisplayedStatement] = useState(props.message.content.statement);
    const [questionDone, setQuestionDone] = useState(false);
    const [borderStatus, setBorderStatus] = useState('');
    const [isCheckButtonVisible, setIsCheckButtonVisible] = useState(true);

    const [questionMicroskills, setQuestionMicroskills] = useState(null);

    const getCurrentUserId = () => Number(AuthenticationContainer.getCurrentUserId());

    const questionWithAnswers = (answers) => ({
        'id': props.message.content.id,
        'class': 'QuestionTexteATrouAnswerSelectedApiDto',
        'userId': getCurrentUserId(),
        'answersSelected': answers,
        'taskId': 0,
        'isInteractiveQuizLeader': false,
        'parcoursStepContentId': props.message.stepContentId
    });

    function getReplacementForInput(acceptedAnswers, userAnswer, index) {
        const isValid = acceptedAnswers.includes(userAnswer);
        const childComponent = createElement('input', {
            class: 'champTrou',
            readonly: true,
            id: `answered_${getTrouInputId(index)}`,
            style: `background-color:${isValid ? validTrouColor : invalidTrouColor}`,
            value: `${userAnswer}`
        }).outerHTML;
        if(isValid) {
            setBorderStatus('good-answer');
            return childComponent;
        }
        setBorderStatus('bad-answer');
        const acceptedAnswersString = acceptedAnswers.join('&#13;');
        const parentComponent = `<Tooltip title=${acceptedAnswersString}> ${childComponent} </Tooltip>`;
        return parentComponent;
    }

    function saveAnsweredStatement(statement, answers, userAnswers) {
        let newStatement = statement;
        let index = 0;
        newStatement = newStatement.replace(
            inputRegex,
            () => getReplacementForInput(answers[index].acceptedAnswers, userAnswers[index], ++index)
        );
        setDisplayedStatement(newStatement);
    }

    function sendResponse() {
        const answers = questionDisplayRef.current.getAnswers();
        setIsCheckButtonVisible(false);
        putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi(questionWithAnswers(answers))
            .then((response) => {
                dispatch(addMessageToChat({ sender: 'oxa', content: response.data.explanation ? response.data.explanation : 'Aucune explication disponible pour le moment.' }));
                saveAnsweredStatement(response.data.statement, response.data.answers, answers);
                dispatch(getNextParcoursHistoryByUserId(parcoursId));
            });
        setQuestionDone(true);
    }
    return (
        <div className={`message oxa ${borderStatus}`}>
            <img id="avatar" src={oxa} />
            <div className="header" />
            <QuestionATrouDisplay
                question={props.message.content}
                questionStatement={displayedStatement}
                isLoading={false}
                answerRef={questionDisplayRef}
                isAnswered={questionDone}
            />
            {!questionDone &&
                <div className="validation-button">
                    { isCheckButtonVisible && (
                        <IconButton
                            style={{
                                bottom: '0',
                                right: '0',
                                position: 'absolute',
                                color: '#0076ba',
                                transform: 'translate(50%, 50%)'
                            }}
                            onClick={sendResponse}>
                            <CheckCircleIcon style={{ fontSize: '2.3rem' }}/>
                        </IconButton>
                    )}
                </div>
            }

            
            <CourseUnderStandardQuestion  
                className="course-under-question"
                questionId={props.questionId}
                questionMicroskills = {questionMicroskills}
                setQuestionMicroskills = {setQuestionMicroskills}
                displayCoursesNames = {() => props.setCoursesNames(props.questionId, setQuestionMicroskills)}
            />

        </div>
    );
}

MessageQuestionTexteATrou.propTypes = {
    message: PropTypes.object,
    setCoursesNames: PropTypes.func,
    questionId: PropTypes.number
};

export default MessageQuestionTexteATrou;
