import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    selectParcoursDesktop: {
        margin: '30px',
        width: '20%'
    },
    selectParcoursMobile: {
        margin: '10px'
    },
    selectGroupsAndStudentDesktop: {
        margin: '30px',
        width: '30%'
    },
    selectGroupsAndStudentMobile: {
        margin: '10px'
    },
    tabsPosition: {
        display: 'flex',
        justifyContent: 'end'
    }
}));
