

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from 'app/redux/actions/User/user.actions';
import { selectAllUser } from 'app/redux/selectors/User/user.selector';
import { selectGroups } from 'app/redux/selectors/Group/group.selector';
import { getGroupsByCoachId } from 'app/redux/actions/Group/group.actions';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export const oxyliensAndGroupsEnum = {
    OXYLIENS: 'Oxyliens',
    GROUPS: 'Mes groupes'
};

function GroupAndStudentSelector(props) {

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const dispatch = useDispatch();

    const oxyliensList = useSelector(selectAllUser);
    const groupsList = useSelector(selectGroups);

    const [oxyliensAndGroupsSelectList, setOxyliensAndGroupsSelectList] = useState([]);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getGroupsByCoachId(getCurrentUserId()));
    }, []);

    useEffect(() => {
        const oxyliensSelectList = oxyliensList
            .map((user) => ({
                ...user,
                type: oxyliensAndGroupsEnum.OXYLIENS
            }))
            .sort((user1, user2) => (
                user1.firstName.localeCompare(user2.firstName)
            ));
        const groupsSelectList = groupsList
            .map((group) => ({
                ...group,
                type: oxyliensAndGroupsEnum.GROUPS
            }))
            .sort((g1, g2) => (
                g1.name.localeCompare(g2.name)
            ));
        setOxyliensAndGroupsSelectList(groupsSelectList.concat(oxyliensSelectList));
    }, [oxyliensList, groupsList]);

    const handleChangeUsersSelected = (event, newValue) => {
        props.onChangeUserAndGroup(newValue);
    };

    const getOptionLabel = (option) => {
        switch (option.type) {
            case oxyliensAndGroupsEnum.OXYLIENS:
                return `${option.firstName} ${option.lastName}`;
            case oxyliensAndGroupsEnum.GROUPS:
                return option.name;
            default:
                return null;
        }
    };

    const groupBy = (option) => (option.type);

    return (
        <Autocomplete
            data-testid="group-and-student-selector"
            className={props.className}
            noOptionsText="Aucun Résultat"
            multiple
            filterSelectedOptions
            options={oxyliensAndGroupsSelectList}
            getOptionLabel={getOptionLabel}
            groupBy={groupBy}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Oxyliens"
                />
            )}
            value={props.usersAndGroupsSelected}
            onChange={handleChangeUsersSelected}
        />
    );
}

GroupAndStudentSelector.propTypes = {
    className: PropTypes.object,
    usersAndGroupsSelected: PropTypes.array.isRequired,
    onChangeUserAndGroup: PropTypes.func.isRequired
};

export default GroupAndStudentSelector;
