import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { DescriptionPopup } from '../../../../project/components/Popup/DescriptionPopup';
import { sliceTitle } from '../../../QuestionTable/QuestionTable.functions';

import { Collapse, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Progress } from 'antd';
import QuestionRequestByQuestionHistoryId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByQuestionHistoryId';
import HistoryRow from '../historyRow/HistoryRow';
import HistoryListGroupSerie from '../historyListGroupSerie/HistoryListGroupSerie';
import { getResultColor } from '../utils';
import { Link } from 'react-router-dom';
import { SERIE_NOT_DONE_RESOLVE_URL } from 'app/Routes';
import { useDispatch } from 'react-redux';
import { setExamMode } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { dateDisplay, resultDisplay } from '../../History.functions';
import { HistoryDisplayEnum } from './HistoryDisplayEnum';


function HistoryRowHead(props) {

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const showMaxLetters = 44;
    const rowQuestion = 'QUESTION';
    const rowSerie = 'SERIE';
    const rowParcours = 'PARCOURS';
    const noFilterName = 'nofilter';

    const resultGlobal = () => props.row.result;

    const details = () => (
        <TableCell align="center">
            {props.row.dataType === rowSerie &&
                (props.row.result || !props.taskId
                    ? <Tooltip title={`${props.row.goodAnswers} correctes / ${props.row.badAnswers} incorrectes / ${props.row.questionsNotDone} non faites`}>
                        <div>
                            <Progress
                                percent={(props.row.goodAnswers + props.row.badAnswers) /
                                (props.row.goodAnswers + props.row.badAnswers + props.row.questionsNotDone) * 100}
                                success={{ percent: resultGlobal() }}
                                trailColor="#A9A9A9"
                                strokeColor="#FF0000"
                                showInfo={false} />
                        </div>
                    </Tooltip>
                    : <div>Tache non commencée</div>
                )
            }
            {   props.row.dataType === rowParcours && props.row.progress >= 0 &&
                <Progress
                    percent={props.row.progress}
                    success={{ percent: props.row.progress }}
                    trailColor="#A9A9A9"
                    strokeColor="#FF0000"
                    showInfo={false}
                />
            }
        </TableCell>);

    const collapse = () => (
        <TableCell>
            {((props.row.done || props.studentId || props.row.dataType === 'PARCOURS') ) && 
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {setOpen(!open);}}
                >
                    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                </IconButton>
            }
        </TableCell>
    );

    const pendingSerie = () => (
        <div>{props.studentId
            ? <div>Série en cours</div>
            : <Tooltip aria-label="buttonSerieNotDone" title={'Reprendre la série'}>
                <Link to={`${SERIE_NOT_DONE_RESOLVE_URL}/serieResume/null/history/${props.row.id}`}>
                    <IconButton
                        key={props.row.id}
                        onClick={() => {
                            dispatch(setExamMode(props.row.examMode));
                        }}
                    >
                        <PlayCircleOutlineIcon fontSize="large"/>
                    </IconButton>
                </Link>
            </Tooltip>
        }
        </div>
    );

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                { collapse() }
                <TableCell component={'th'} scope="row">
                    { props.row.title.length > showMaxLetters
                        ? <>
                            { sliceTitle(props.row.title, showMaxLetters) }
                            <DescriptionPopup description={ props.row.title }/>
                        </>
                        : props.row.title
                    }
                </TableCell>
                { details() }
                <TableCell align="center">
                    {props.row.dataType === rowQuestion &&
                        (props.row.question || !props.taskId
                            ? resultDisplay(props.row.result)
                            : <div>Tache non commencée</div>
                        )
                    }
                    {props.row.dataType === rowSerie && (props.row.done
                        ? <div style={{ color: getResultColor(resultGlobal()) }}>
                            {`${resultGlobal()}%`}
                        </div>
                        : <div>
                            {props.taskId
                                ? <></>
                                : pendingSerie()
                            }
                        </div>)
                    }
                    {   props.row.dataType === rowParcours && props.row.progress >= 0 &&
                        <div style={{ color: getResultColor(props.row.progress) }}>
                            {props.row.progress.toFixed(1)}%
                        </div>
                    }
                </TableCell>
                <TableCell align="center">
                    {dateDisplay(props.row?.date)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div aria-label="expandSerie">
                            {props.row.dataType === rowQuestion &&
                                <QuestionRequestByQuestionHistoryId questionHistoryId={props.row.id}/>
                            }
                            {props.row.dataType === rowSerie && props.groupName === noFilterName &&
                                <HistoryRow historyId={props.row.id}
                                    historyDisplayType = {HistoryDisplayEnum.QUESTION}
                                />
                            }

                            {props.row.dataType === rowSerie && props.groupName !== noFilterName &&
                                <div aria-label="listGroupSerie">
                                    <HistoryListGroupSerie
                                        serieHistoryId={props.row.id}
                                        groupName={props.groupName}
                                    />
                                </div>
                            }

                            {props.row.dataType === rowParcours && 

                                    <HistoryRow historyId={props.row.id}
                                        historyDisplayType = {HistoryDisplayEnum.PARCOURSSTEP}
                                        userId = {props.userId}
                                    />
                            }
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

HistoryRowHead.propTypes = {
    row: PropTypes.object.isRequired,
    groupName: PropTypes.string.isRequired,
    studentId: PropTypes.number,
    taskId: PropTypes.number,
    userId: PropTypes.number
};

export default HistoryRowHead;
