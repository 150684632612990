import {
    GET_MORNING_QUESTION_SUCCESS,
} from '../../actions/Question/MorningQuestions/morningQuestion.actions';

export const morningQuestionsToDo = 'morningQuestionsToDo';

export default function morningQuestionsReducer(state = {
    [morningQuestionsToDo]: null
}, action) {
    switch (action.type) {
        case GET_MORNING_QUESTION_SUCCESS:
            return {
                ...state,
                [morningQuestionsToDo]: action.payload
            };
        default:
            return state;
    }
}
