
import React, { useEffect, useMemo } from 'react';
import AuthenticationContainer from '../../../login/containers/AuthenticationContainer';
import './AddToGroupTable.scss';
import '../AddToGroupPanel.scss';
import { useDispatch, useSelector } from 'react-redux';
import ExcilyenCard from 'app/common/excilyenCard/ExcilyenCard';
import PropTypes from 'prop-types';
import { selectExcilyensFilteredBySkillsAndPromosAndByName } from 'app/redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import { selectGroup } from 'app/redux/selectors/Group/group.selector';
import { getDetailedExcilyenSkills, getAllExcilyenSet } from 'app/redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import SidePanelExcilyens from 'app/components/sidePanel/excilyen/SidePanelExcilyens';

export default function AddToGroupTable(props) {

    const excilyensSet = useSelector(selectExcilyensFilteredBySkillsAndPromosAndByName);
    const selectedGroup = useSelector(selectGroup);

    const filteredExcilyensSet = useMemo(
        () => excilyensSet.sort((excilyen1, excilyen2) => excilyen1.firstName
            .localeCompare(excilyen2.firstName) || excilyen1.lastName
            .localeCompare(excilyen2.lastName)),
        [excilyensSet]
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDetailedExcilyenSkills());
        dispatch(getAllExcilyenSet());
    }, []);

    return (
        <div className="add-to-group-table">
            <div className="expert-side-panel">
                <span className="expert-title">{selectedGroup.name}</span>
                <SidePanelExcilyens />
            </div>
            <div className="expert-content">
                <GridExcilyens
                    addToGroup={props.addToGroup}
                    filteredExcilyensSet={filteredExcilyensSet}
                    group={selectedGroup}
                    studentAlreadyInGroup={props.previouslyAdded}
                    dispatchPersonGroup={ props.dispatchPersonGroup }
                />
            </div>
        </div>
    );

}

AddToGroupTable.propTypes = {
    addToGroup: PropTypes.string,
    previouslyAdded: PropTypes.array,

    dispatchPersonGroup: PropTypes.func.isRequired
};

function GridExcilyens(props) {

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    function isAlreadyInTheGroup(studentId) {
        const found = props.studentAlreadyInGroup.find((element) => element.id === studentId);
        return found !== undefined;
    }

    return (
        <>
            { props.filteredExcilyensSet
                .map((excilyen, index) =>
                    <ExcilyenCard
                        addToGroup={props.addToGroup}
                        excilyen={excilyen}
                        key={index}
                        isForGroupView={true}
                        group={props.group}
                        isAlreadyInGroup= {isAlreadyInTheGroup(excilyen.id)}
                        userId={getCurrentUserId()}
                        dispatchPersonGroup={ props.dispatchPersonGroup }
                    />)
            }
        </>
    );
}


GridExcilyens.propTypes = {
    addToGroup: PropTypes.string,
    filteredExcilyensSet: PropTypes.func,
    group: PropTypes.object,
    studentAlreadyInGroup: PropTypes.array,

    dispatchPersonGroup: PropTypes.func.isRequired
};
