import React, { useEffect, useState } from 'react';
import './ChapterDesktop.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getChapterReportingByChapterId } from 'app/redux/actions/Chapter/chapter.actions';

import {
    selectIsChapterReportingOpen,
    selectIsChapterTreeOpen,
    selectReportedChapter
} from 'app/redux/selectors/Chapter/chapter.selector';

import { Divider, IconButton, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Allotment } from 'allotment';
import ChapterReportingPane from '../ChapterReportingPane/ChapterReportingPane';
import GroupBarChart from 'app/components/graph/GroupBarChat';
import BarChart from '../../../../../components/graph/BarChart';

function ChapterDesktop(props) {

    const dispatch = useDispatch();

    const isChapterReportingOpen = useSelector(selectIsChapterReportingOpen);
    const isChapterTreeOpen = useSelector(selectIsChapterTreeOpen);
    const reportedChapter = useSelector(selectReportedChapter);

    const [opened, setOpened] = useState(true);
    const [visible, setVisible] = useState(true);

    const skillId = props.filterBySkill ? props.skillId : undefined;

    useEffect(() => {
        setVisible(isChapterTreeOpen);
    }, [isChapterTreeOpen]);

    useEffect(() => {
        setOpened(true);
    }, [visible]);

    useEffect(() => {
        dispatch(getChapterReportingByChapterId(reportedChapter === null ? 0 : reportedChapter.id));
    }, [isChapterReportingOpen, reportedChapter]);

    const handleTreeClose = () => {
        if(isChapterTreeOpen) {
            props.handleChapterTreeClose();
        } else {
            props.handleChapterTreeOpen();
        }
        setOpened(false);
        setVisible((prev) => !prev);
    };


    return (
        <Allotment>
            <Allotment.Pane
                visible={visible}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <div className="chapter-display">
                    {props.chapterHeader}
                    <Divider/>
                    { props.isChapterSelected &&
                        <div className="chapter-tree-desktop">
                            {props.chapterTreeDisplay}
                        </div>
                    }
                </div>
            </Allotment.Pane>
            <Allotment.Pane>
                <div className="chapters">
                    { (props.isChapterSelected || props.selectedQuestion) &&
                        <>
                            {opened &&
                                <Tooltip
                                    title = {`${visible ? 'Cacher' : 'Afficher'} l'arbre des chapitres`}
                                >
                                    <IconButton
                                        onClick={handleTreeClose}
                                        data-testid="collapser"
                                        style={{
                                            position: 'absolute',
                                            zIndex: '1'
                                        }}
                                    >
                                        {visible ? <ChevronLeft/> : <ChevronRight/>}
                                    </IconButton>
                                </Tooltip>
                            }
                            { (props.isChapterSelected || props.selectedQuestion) &&
                                <>
                                    {(props.displayGroupTab && props.groupSelected)
                                        ? <>
                                            {props.chapterGroupStats}
                                        </>
                                        : <>
                                            {props.chapterContent}
                                        </>
                                    }
                                </>
                            }
                        </>
                    }

                    { props.displayGraph && !(props.isChapterSelected || props.selectedQuestion) && (props.groupSelected && props.mondayDate && props.sundayDate) &&
                        <GroupBarChart groupId={props.groupSelected?.id} forceMondayDate={props.mondayDate} forceSundayDate={props.sundayDate} skillId={skillId} />
                    }
                    { props.displayGraph && !(props.isChapterSelected || props.selectedQuestion) && (props.studentSelected && props.mondayDate && props.sundayDate) &&
                        <BarChart studentId={props.studentSelected?.id} forceMondayDate={props.mondayDate} forceSundayDate={props.sundayDate} skillId={skillId}/>
                    }
                </div>
            </Allotment.Pane>
            <Allotment.Pane
                visible={props.reportingList > 0}
                minSize="300"
                style= {{ display: 'flex', flexDirection: 'column' }}
            >
                <ChapterReportingPane
                    reportingList={props.reportingList}
                />
            </Allotment.Pane>
        </Allotment>
    );
}

ChapterDesktop.propTypes = {
    chapterContent: PropTypes.element,
    chapterGroupStats: PropTypes.element,
    chapterHeader: PropTypes.element,
    chapterTreeDisplay: PropTypes.element,
    displayGroupTab: PropTypes.bool,
    groupSelected: PropTypes.object,
    studentSelected: PropTypes.object,
    handleChapterTreeClose: PropTypes.func,
    handleChapterTreeOpen: PropTypes.func,
    isChapterSelected: PropTypes.bool,
    selectedQuestion: PropTypes.number,
    reportingList: PropTypes.array,
    mondayDate: PropTypes.object,
    sundayDate: PropTypes.object,
    skillId: PropTypes.number,
    filterBySkill: PropTypes.bool,
    displayGraph: PropTypes.bool
};

export default ChapterDesktop;
