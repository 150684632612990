
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Divider } from 'antd';
import { scroller } from 'react-scroll';
// https://www.npmjs.com/package/react-scroll
export function StudentListItem(props) {


    const scrollToSection = () => {
        scroller.scrollTo('detailsPaperCard', {
            duration: 1100,
            delay: 50,
            isDynamic: true,
            offset: -76,
            smooth: 'easeInOutQuart',
            to: 'detailsPaperCard'
        });
    };

    const displayStudentStats = () => {
        scrollToSection();
        props.onDisplayStats(props.student);
    };

    return (
        <div data-testid="student-list-item">
            <ListItem id="studentCollapsible" button onClick={displayStudentStats}>
                <ListItemAvatar >
                    <Avatar src={`${props.student.imageUrl}`}/>
                </ListItemAvatar>
                <ListItemText primary={`${props.student.firstName} ${props.student.lastName}`} secondary={`${props.student.promo}`}/>
            </ListItem>
            <Divider style={{ margin: '0px' }}/>
        </div>
    );
}

StudentListItem.propTypes = {
    student: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        promo: PropTypes.number.isRequired,
        imageUrl: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
    }),
    onDisplayStats: PropTypes.func.isRequired
};
