import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BASE_VALUE_LV0 } from 'assets/fonts/icons/lvl-0.svg';
import { ReactComponent as BASE_VALUE_LV1 } from 'assets/fonts/icons/lvl-1.svg';
import { ReactComponent as BASE_VALUE_LV2 } from 'assets/fonts/icons/lvl-2.svg';
import { ReactComponent as BASE_VALUE_LV3 } from 'assets/fonts/icons/lvl-3.svg';
import { ReactComponent as BASE_VALUE_LV4 } from 'assets/fonts/icons/lvl-4.svg';


export const enumValidation = {
    NO_BAR: 'NO_BAR',
    ONE_BAR: 'ONE_BAR',
    TWO_BAR: 'TWO_BAR',
    THREE_BAR: 'THREE_BAR',
    FULL_BAR: 'FULL_BAR'
};

export function getValidationIcon(validation) {
    switch (validation) {
        case enumValidation.NO_BAR:
            return <BASE_VALUE_LV0/>;
        case enumValidation.ONE_BAR:
            return <BASE_VALUE_LV1/>;
        case enumValidation.TWO_BAR:
            return <BASE_VALUE_LV2/>;
        case enumValidation.THREE_BAR:
            return <BASE_VALUE_LV3/>;
        case enumValidation.FULL_BAR:
            return <BASE_VALUE_LV4/>;
        default:
            return <BASE_VALUE_LV0/>;
    }
}

function ChapterValidationState(props) {

    return (getValidationIcon(props.validation));
}

ChapterValidationState.propTypes = {
    validation: PropTypes.string
};

export default ChapterValidationState;
