import { detailedFormatDate } from 'app/utils/date.functions';

export function sortHistoryByDate(history) {
    const sortedHistory = new Map();
    history.forEach((element) => {
        const date = detailedFormatDate(new Date(element.date));
        if(sortedHistory.has(date)) {
            sortedHistory.get(date).push(element);
        } else {
            sortedHistory.set(date, [element]);
        }
    });
    return sortedHistory;
}