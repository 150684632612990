

import React, { Component } from 'react';
import './AddEditClient.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { GET_ALL_CLIENTS, GET_CLIENT_BY_ID_SUCCESS } from '../../../../redux/actions/Client/clients.actions';
import { addClient, editClient, ADD_CLIENT, EDIT_CLIENT } from '../../../../redux/actions/Client/ClientSet/clientsSet.actions';
import { Fab, Dialog, DialogTitle, Grid } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import TextField from '@material-ui/core/TextField';
import RichTextEditor from '../../../../common/richTextEditor/RichTextEditor';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';
import { createErrorMessageSelector, createLoadingSelector } from '../../../../redux/selectors/APISelector/APISelector';
import { isWidthUp } from '@material-ui/core/withWidth';
import Select from 'react-select';
import { selectAllSectors } from '../../../../redux/selectors/Sector/sector.selector';
import { getAllSectors } from '../../../../redux/actions/Sector/sector.actions';

export class AddEditClient extends Component {

    constructor() {
        super();
        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.props.getAllSectors();
    }

    componentDidUpdate(prevProps) {
        if(!this.props.isFetchingAddClient && this.props.isFetchingAddClient !== prevProps.isFetchingAddClient && !this.props.errorAdd) {
            this.closeModal();
        }
        if(!this.props.isFetchingEditClient && this.props.isFetchingEditClient !== prevProps.isFetchingEditClient && !this.props.errorEdit) {
            this.closeModal();
        }
    }

    // Rajouter un champ secteur quand il sera implémenté dans le back
    getInitialState = () => {
        const initialState = {
            open: false,
            imageUrl: '',
            name: '',
            address: '',
            latitude: '',
            longitude: '',
            description: '',
            sectorId: 0,
            disabledByName: true,
            disabledByNameLength: false,
            disabledByLatNumber: false,
            disabledByLongNumber: false,
            disabledByLatNumberLength: false,
            disabledByLongNumberLength: false,
            disabledByImage: false,
            disabledByAdress: false,
            disabledByDescription: false,
            nameMaxLength: 50,
            urlMaxLength: 255,
            addressMaxLength: 255,
            descriptionMaxLength: 65500
        };
        return initialState;
    };

    submitAdd = () => {

        const sector = this.getSectorById(this.state.sectorId, this.props.sectors);

        const client = {
            name: this.state.name,
            address: this.state.address,
            imageUrl: this.state.imageUrl,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            description: this.state.description,
            sector
        };
        this.setState(this.getInitialState());
        this.props.addClient(client);
    };

    submitEdit = () => {

        const sector = this.getSectorById(this.state.sectorId, this.props.sectors);

        const client = {
            id: this.state.id,
            name: this.state.name,
            address: this.state.address,
            imageUrl: this.state.imageUrl,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            description: this.state.description,
            sector
        };
        this.setState(this.getInitialState());
        this.props.editClient(client);
    };

    openModal = () => {
        this.setState({
            ...this.state,
            open: true
        });
        const initialState = {
            id: this.props.clientToEdit && this.props.clientToEdit.id,
            currentUser: this.props.currentUser,
            imageUrl: this.props.clientToEdit && this.props.clientToEdit.imageUrl,
            latitude: this.props.clientToEdit && this.props.clientToEdit.latitude,
            longitude: this.props.clientToEdit && this.props.clientToEdit.longitude,
            name: this.props.clientToEdit && this.props.clientToEdit.name,
            address: this.props.clientToEdit && this.props.clientToEdit.address,
            description: this.props.clientToEdit && this.props.clientToEdit.description,
            sectorId: this.props.clientToEdit && this.props.clientToEdit.sector && this.props.clientToEdit.sector.id,
            disabledByName: !(this.props.clientToEdit && this.props.clientToEdit.name),
            disabledByNameLength: false,
            disabledByImage: false,
            disabledByAdress: false,
            disabledByDescription: false,
            disabledByLength: false
        };
        this.setState(initialState);
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    onChangeClientName = (event) => {
        let disabled = true;
        const disabledByLength = this.checkNameLength(event.target.value);
        if(event.target.value && event.target.value !== '') {
            disabled = false;
        }
        this.setState({
            ...this.state,
            name: event.target.value,
            disabledByName: disabled,
            disabledByNameLength: disabledByLength
        });
    };

    onChangeLong = (event) => {
        const disabled = !this.checkLongNumber(event.target.value);
        this.setState({
            ...this.state,
            longitude: event.target.value,
            disabledByLongNumber: disabled
        });
    };

    onChangeLat = (event) => {
        const disabled = !this.checkLatNumber(event.target.value);
        this.setState({
            ...this.state,
            latitude: event.target.value,
            disabledByLatNumber: disabled
        });
    };

    checkLongNumber = (longitude) => {
        const regExp = /^$|^[-+]?(180(\.0+)?|(\d?\d(\.\d+)?|1[0-7]\d(\.\d+)?))$/u;
        return regExp.test(longitude);
    };

    checkLatNumber = (latitude) => {
        const regExp = /^$|^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/u;
        return regExp.test(latitude);
    };

    onChangeAddress = (event) => {
        const disabled = this.checkAddrLength(event.target.value);
        this.setState({
            ...this.state,
            address: event.target.value,
            disabledByAdress: disabled
        });
    };

    onChangeDescription = (event, editor) => {
        const disabled = this.checkDescriptionLength(editor.getData());
        this.setState({
            ...this.state,
            description: editor.getData(),
            disabledByDescription: disabled
        });
    };

    onChangeImage = (event) => {
        const disabled = this.checkUrlLength(event.target.value);
        this.setState({
            ...this.state,
            imageUrl: event.target.value,
            disabledByImage: disabled
        });
    };

    checkNameLength = (name) => name.length > this.state.nameMaxLength;

    onChangeSector = (sector) => {
        this.setState({
            ...this.state,
            sectorId: sector && sector.value
        });
    };

    getSectorById = (sectorId, sectors) => sectors.filter((sector) => sector.id === sectorId)[0];

    checkUrlLength = (url) => url.length > this.state.urlMaxLength;

    checkAddrLength = (address) => address.length > this.state.addressMaxLength;

    // On garde une marge pour les balises générées automatiquement (gras, italique, souligné, ...)
    checkDescriptionLength = (description) => description.length > this.state.descriptionMaxLength;

    errorMessageForLatNumber = (disabledByLatNumber) => {
        let errorMessage = '';
        if(disabledByLatNumber) {
            errorMessage += 'La latitude renseignée est incorrecte';
        }
        return errorMessage;
    };

    errorMessageForLongNumber = (disabledByLongNumber) => {
        let errorMessage = '';
        if(disabledByLongNumber) {
            errorMessage += 'La longitude renseignée est incorrecte';
        }
        return errorMessage;
    };

    errorMessageForName = (disabledByName, disabledByNameLength) => {
        if(disabledByName) {
            return 'Le nom est obligatoire';
        }
        if(disabledByNameLength) {
            return `Le nom est trop long (max ${this.state.nameMaxLength} caractères)`;
        }
        return '';
    };

    // Rajouter le sélecteur pour les secteurs quand ils seront implémentés dans le back
    render() {

        const allSectors = this.props.sectors.map((sector) => ({ value: sector.id,
            label: sector.description }));

        const trueIfAddAndFalseIfEdit = this.props.trueIfAddAndFalseIfEdit;

        return (
            <div>
                { this.props.currentUser.roles &&
                this.props.currentUser.roles.some((role) => role.name === 'ADMIN')
                    ? trueIfAddAndFalseIfEdit
                        ? <Fab color="primary" size="small" aria-label="add" onClick={this.openModal} id="add-project-button">
                            <Add/>
                        </Fab>
                        : <Fab color="primary" aria-label="edit" onClick={this.openModal} id="edit-project-button">
                            <Edit/>
                        </Fab>
                    : <></>
                }
                <Dialog
                    disableEnforceFocus={true} maxWidth="md" fullWidth={true}
                    fullScreen={!isWidthUp('sm', this.props.width)}
                    open={this.state.open}
                    onClose={this.closeModal} aria-labelledby="form-dialog-title"
                    PaperProps={{ style: { overflowY: 'auto' } }}>
                    <DialogTitle id="form-dialog-title">{trueIfAddAndFalseIfEdit ? 'Ajouter un nouveau client' : 'Modifier le client'}</DialogTitle>
                    <Grid item xs={12} sm={12}>
                        <Divider variant="middle" light={true}/>
                    </Grid>
                    <DialogContent style={{ overflowY: 'visible' }}>
                        <Grid container className="padd" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" id="clientName" name="clientName" label="Nom du client" type="text"
                                    error={this.state.disabledByName || this.state.disabledByNameLength}
                                    helperText={this.errorMessageForName(this.state.disabledByName, this.state.disabledByNameLength)}
                                    required fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    defaultValue={this.props.clientToEdit && this.props.clientToEdit.name} onChange={this.onChangeClientName}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" id="logoClient" name="logoClient" label="Logo du client" type="text"
                                    error={this.state.disabledByImage}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={this.state.disabledByImage && `L'URL donnée est trop longue (max ${this.state.urlMaxLength} caractères)`}
                                    defaultValue={this.props.clientToEdit && this.props.clientToEdit.imageUrl} onChange={this.onChangeImage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={this.state.disabledByLongNumber}
                                    id="Longitude"
                                    fullWidth
                                    margin="dense"
                                    label="Longitude"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.onChangeLong}
                                    defaultValue={this.props.clientToEdit && this.props.clientToEdit.longitude}
                                    helperText={this.errorMessageForLongNumber(this.state.disabledByLongNumber)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={this.state.disabledByLatNumber}
                                    id="Latitude"
                                    fullWidth
                                    margin="dense"
                                    label="Latitude"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.onChangeLat}
                                    defaultValue={this.props.clientToEdit && this.props.clientToEdit.latitude}
                                    helperText={this.errorMessageForLatNumber(this.state.disabledByLatNumber)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={this.state.disabledByAdress}
                                    id="address"
                                    label="Adresse"
                                    margin="dense"
                                    fullWidth
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.onChangeAddress}
                                    helperText={this.state.disabledByAdress && `L'adresse est trop longue (max ${this.state.addressMaxLength} caractères)`}
                                    defaultValue={this.props.clientToEdit && this.props.clientToEdit.address}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange= {this.onChangeSector}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Secteurs d'activité"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="secteurs d\'activité"
                                    options={allSectors}
                                    isMulti={false}
                                    defaultValue={this.props.clientToEdit && this.props.clientToEdit.sector
                                        ? { value: this.props.clientToEdit.sector.id,
                                            label: this.props.clientToEdit.sector.name }
                                        : undefined}
                                />

                            </Grid>
                        </Grid>
                        <div className="rich-text-editor-add-client">
                            <RichTextEditor data={this.props.clientToEdit && this.props.clientToEdit.description}
                                onChange={this.onChangeDescription}/>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                            Annuler
                        </Button>
                        <Button className="submit-button" onClick={trueIfAddAndFalseIfEdit ? this.submitAdd : this.submitEdit}
                            disabled={ this.props.isFetchingAddClient ||
                                this.props.isFetchingEditClient ||
                                this.state.disabledByName ||
                                this.state.disabledByNameLength ||
                                this.state.disabledBySector ||
                                this.state.disabledByImage ||
                                this.state.disabledByAdress ||
                                this.state.disabledByDescription}
                            color="primary">
                            {trueIfAddAndFalseIfEdit ? 'Ajouter' : 'Modifier'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

AddEditClient.propTypes = {
    clientToEdit: PropTypes.object,
    width: PropTypes.string,
    addClient: PropTypes.func,
    isFetchingAddClient: PropTypes.bool,
    isFetchingEditClient: PropTypes.bool,
    trueIfAddAndFalseIfEdit: PropTypes.bool,
    editClient: PropTypes.func,
    errorAdd: PropTypes.object,
    errorEdit: PropTypes.object,
    currentUser: PropTypes.object,
    sector: PropTypes.bool,
    sectors: PropTypes.array,
    valueSector: PropTypes.array,
    setProjectSectorFilter: PropTypes.func,
    getAllSectors: PropTypes.func
};

function mapStateToProps(state) {
    return {
        isFetchingAddClient: createLoadingSelector([
            ADD_CLIENT,
            GET_ALL_CLIENTS
        ])(state),
        errorAdd: createErrorMessageSelector([ADD_CLIENT])(state),
        isFetchingEditClient: createLoadingSelector([
            ADD_CLIENT,
            GET_CLIENT_BY_ID_SUCCESS
        ])(state),
        errorEdit: createErrorMessageSelector([EDIT_CLIENT])(state),
        currentUser: selectCurrentUser(state),
        sectors: selectAllSectors(state)

    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addClient: (client) => dispatch(addClient(client)),
        editClient: (client) => dispatch(editClient(client)),
        getAllSectors: () => dispatch(getAllSectors())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withWidth()(AddEditClient));
