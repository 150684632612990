/* eslint-disable complexity */

import {
    GET_STUDENTS_BY_GROUP_ID_SUCCESS,
    PUT_GROUP_SUCCESS,
    DELETE_GROUP_SUCCESS,
    GET_GROUP_BY_STUDENT_ID_SUCCESS,
    SET_SELECTED_GROUP,
    GET_ALL_STUDENTS_OF_GROUP_SUCCESS,
    GET_ALL_COACHES_OF_GROUP_SUCCESS,
    GET_GROUP_BY_COACH_ID_SUCCESS,
    GET_GROUP_CHAPTER_STATS_SUCCESS,
    DELETE_STORED_DATA,
    GET_COMPLETE_GROUP_CHAPTER_STATS_SUCCESS,
    RESET_GROUP_STUDENT,
    GET_ALL_STUDENTS_OF_GROUPS_SUCCESS,
    PATCH_ARCHIVED_GROUP_BY_COACH_ID_SUCCESS
} from '../../actions/Group/group.actions';

import {
    DELETE_STUDENT_IN_GROUP_SUCCESS,
    POST_STUDENT_IN_GROUP_SUCCESS,
    POST_COACH_IN_GROUP_SUCCESS,
    DELETE_COACH_IN_GROUP_SUCCESS
} from '../../actions/Group/RelationGroup/groupRelation.actions';

export const groups = 'groups';
export const students = 'students';
export const coaches = 'coaches';
export const group = 'group';
export const selectedGroupStudentsMap = 'selectedGroupStudentsMap';
export const selectedGroupStudents = 'selectedGroupStudents';
export const filterGroup = 'filterGroup';
export const groupStatsByChapter = 'groupStatsByChapter';

export default function groupReducer(state = {
    [groups]: [],
    [students]: [],
    [coaches]: [],
    [group]: null,
    [selectedGroupStudentsMap]: [],
    [selectedGroupStudents]: [],
    [groupStatsByChapter]: new Map()
}, action) {
    switch (action.type) {
        case GET_STUDENTS_BY_GROUP_ID_SUCCESS:
            return {
                ...state,
                [students]: action.payload
            };
        case GET_ALL_COACHES_OF_GROUP_SUCCESS:
            return {
                ...state,
                [coaches]: action.payload
            };
        case PUT_GROUP_SUCCESS:
            return {
                ...state,
                [groups]:
                [
                    ...state.groups,
                    action.payload
                ]
            };
        case DELETE_GROUP_SUCCESS:
            const findGroupIndexForDelete = state[groups].findIndex((el) => el === state[group]);
            return { ...state,
                [groups]: state[groups].filter((studentGroup) => studentGroup.id !== action.payload),
                [group]: findGroupIndexForDelete === 0
                    ? state[groups][1]
                    : state[groups][findGroupIndexForDelete - 1] };
        case GET_GROUP_BY_STUDENT_ID_SUCCESS:
            return {
                ...state,
                [groups]: action.payload
            };
        case GET_GROUP_BY_COACH_ID_SUCCESS:
            return {
                ...state,
                [groups]: action.payload
            };
        case SET_SELECTED_GROUP:
            return {
                ...state,
                [group]: action.payload
            };
        case POST_STUDENT_IN_GROUP_SUCCESS:
            return {
                ...state,
                [students]:
                [
                    ...state.students,
                    action.payload
                ]
            };
        case POST_COACH_IN_GROUP_SUCCESS:
            return {
                ...state,
                [coaches]:
                [
                    ...state.coaches,
                    action.payload
                ]
            };
        case GET_ALL_STUDENTS_OF_GROUP_SUCCESS:
            return {
                ...state,
                [selectedGroupStudentsMap]: {
                    ...state[selectedGroupStudentsMap],
                    [action.payload.groupId]: action.payload.students
                }
            };
        case GET_ALL_STUDENTS_OF_GROUPS_SUCCESS:
            return {
                ...state,
                [selectedGroupStudents]: action.payload
            };
        case RESET_GROUP_STUDENT:
            return {
                ...state,
                [selectedGroupStudents]: null
            };
        case DELETE_STUDENT_IN_GROUP_SUCCESS:
            return {
                ...state,
                [students]: state[students].filter((student) => student.id !== action.payload)
            };
        case GET_GROUP_CHAPTER_STATS_SUCCESS:
            const groupStatCopy = state[groupStatsByChapter];
            groupStatCopy.set(action.payload.chapterId, action.payload.data);
            return {
                ...state,
                [groupStatsByChapter]: new Map(groupStatCopy)
            };
        case GET_COMPLETE_GROUP_CHAPTER_STATS_SUCCESS:
            const groupStatsTemp = Object.entries(action.payload);
            const groupStats = new Map();
            groupStatsTemp.forEach((couple) => groupStats.set(parseInt(couple[0], 10), couple[1]));
            return {
                ...state,
                [groupStatsByChapter]: groupStats
            };
        case DELETE_STORED_DATA:
            return {
                ...state,
                [groupStatsByChapter]: new Map()
            };
        case DELETE_COACH_IN_GROUP_SUCCESS:
            return {
                ...state,
                [coaches]: state[coaches].filter((coach) => coach.id !== action.payload)
            };
        case PATCH_ARCHIVED_GROUP_BY_COACH_ID_SUCCESS:
            const findGroupIndex = state[groups].findIndex((el) => el === state[group]);
            return { ...state,
                [groups]: state[groups].filter((studentGroup) => studentGroup.id !== action.payload),
                [group]: findGroupIndex === 0
                    ? state[groups][1]
                    : state[groups][findGroupIndex - 1] };
        default:
            return state;
    }
}
