import { notificationError, notificationSuccess, notificationWarning } from '../Notification/notifications.actions';
import {
    ALREADY_REGISTERED,
    EXPIRED_SESSION,
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    REGISTRATION_NEEDED,
    RETRIEVE_USER_INFOS_ERROR,
    SKILLS_NEED_TO_BE_SET
} from '../../../Snackbar/NotificationMessages';
import {
    getCurrentUserByEmailApi,
    patchCurrentUserApi,
    postUserApi,
    getCurrentUserByIdApi,
    getAllExcilyenApi
} from '../../../api/userApi';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';
export const NOT_REGISTERED = 'NOT_REGISTERED';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';


export function resetCurrentUser() {
    return {
        type: RESET_CURRENT_USER
    };
}

export function setCurrentUser() {
    return {
        type: RESET_CURRENT_USER
    };
}


export const getCurrentUser = () => (dispatch) => {
    dispatch({ type: GET_CURRENT_USER_REQUEST });

    return getCurrentUserByEmailApi().then((response) => {
        dispatch({
            type: GET_CURRENT_USER_SUCCESS,
            payload: response.data
        });
        localStorage.setItem('userId', response.data.id);

    }, (error) => {
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch({ type: NOT_REGISTERED });
            dispatch(notificationWarning(REGISTRATION_NEEDED));
        } else {
            dispatch(notificationError(RETRIEVE_USER_INFOS_ERROR));
        }
        dispatch({
            type: GET_CURRENT_USER_FAILURE,
            payload: error,
            error: true
        });
    });
};

export const getCurrentUserById = () => (dispatch) => {
    dispatch({ type: GET_CURRENT_USER_REQUEST });

    return getCurrentUserByIdApi().then((response) => {
        dispatch({
            type: GET_CURRENT_USER_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_CURRENT_USER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationWarning(REGISTRATION_NEEDED));
        } else {
            dispatch(notificationError(RETRIEVE_USER_INFOS_ERROR));
        }
    });
};

export const postUser = (user) => (dispatch) => {
    dispatch({ type: ADD_USER_REQUEST });

    return postUserApi(user).then((response) => {
        localStorage.setItem('userId', response.data.id);
        dispatch({
            type: ADD_USER_SUCCESS,
            payload: response.data
        });
        dispatch(getCurrentUserById());
        dispatch(notificationSuccess(REGISTER_SUCCESS));
        dispatch(notificationWarning(SKILLS_NEED_TO_BE_SET));
    }, (error) => {
        dispatch({
            type: ADD_USER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 409) {
            dispatch(notificationWarning(ALREADY_REGISTERED));
        } else {
            dispatch(notificationError(REGISTER_ERROR));
        }
    });
};

export const patchUser = (id, updates) => (dispatch) => {
    dispatch({ type: EDIT_USER_REQUEST });

    return patchCurrentUserApi(id, updates).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS
        });
        dispatch(getCurrentUserById());
    }, (error) => {
        dispatch({
            type: EDIT_USER_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else if(error.response && error.response.status === 404) {
            dispatch(notificationWarning(REGISTRATION_NEEDED));
        } else {
            dispatch(notificationError(RETRIEVE_USER_INFOS_ERROR));
        }
    });
};

export const getAllUsers = () => (dispatch) => {
    dispatch({ type: GET_ALL_USERS_REQUEST });

    return getAllExcilyenApi().then((response) => {
        dispatch({
            type: GET_ALL_USERS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_USERS_FAILURE,
            payload: error,
            error: true
        });
    });
};
