

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CandidateTablePage.scss';
import { SpeedDial } from '@material-ui/lab';
import { Add } from '@material-ui/icons';
import { CandidateTable } from '../CandidateTable/CandidateTable';
import CandidateDialog from '../CandidateDialog/CandidateDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'app/redux/actions/User/user.actions';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { Redirect } from 'react-router-dom';
import { REDIRECT_EXCILYENS_ACCESS } from 'app/Snackbar/NotificationMessages';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';

export function CandidateTablePage(props) {
    const dispatch = useDispatch();

    const [dialogOpen, isDialogOpen] = useState(false);

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        dispatch(getCurrentUser());
    }, []);

    const currentUser = useSelector(selectCurrentUser);

    const checkForRecruiter = (user) => {
        const isRecruiter = (user.roles && user.roles.some((role) => role.name === 'RECRUTEUR'));
        if(!isRecruiter) {
            dispatch(notificationError(REDIRECT_EXCILYENS_ACCESS));
        }
        setRedirect(!isRecruiter);
    };

    useEffect(() => {
        checkForRecruiter(currentUser);
    }, [currentUser.id]);

    const onClose = () => {
        isDialogOpen(false);
    };

    return (
        <>
            {redirect ? <Redirect to={'/skills'} /> : <></>}
            {currentUser && <>
                <div className="tabCandidateTab">
                    <CandidateTable candidate={props.candidate} />
                    <CandidateDialog
                        open={dialogOpen}
                        onClose={onClose}
                        currentUserId={currentUser.id}
                    />
                </div>
                <SpeedDial
                    color="primary"
                    key={'Candidat'}
                    icon={<Add />}
                    ariaLabel="Créer un candidat"
                    tooltipTitle={'Candidat'}
                    tooltipOpen
                    id="createButton"
                    onClick={() => isDialogOpen(true)}
                />
            </>}
        </>
    );
}

CandidateTablePage.propTypes = {
    candidate: PropTypes.object
};
