import {
    GET_ALL_SECTORS_SUCCESS,
    GET_SECTOR_BY_ID_SUCCESS
} from '../../actions/Sector/sector.actions';

export const allSectorsStore = 'allSectorsStore';
export const sectorStore = 'sectorStore';

export default function sectorReducer(state = {
    [sectorStore]: null,
    [allSectorsStore]: []
}, action) {
    switch (action.type) {
        case GET_ALL_SECTORS_SUCCESS:
            return {
                ...state,
                [allSectorsStore]: action.payload
            };
        case GET_SECTOR_BY_ID_SUCCESS:
            return {
                ...state,
                [sectorStore]: action.payload
            };
        default:
            return state;
    }
}
