import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ProjectDetailsContent.scss';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { Hidden, MuiThemeProvider, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { collapsibleTheme } from './ProjectDetailsContentTheme';
import MissionsTable from '../../../Mission/MissionsTable/MissionsTable';
import ProjectStack from '../../ProjectStack/ProjectStack';
import { selectCurrentProject } from '../../../../../redux/selectors/Project/projects.selector';
import { selectCurrentUser } from '../../../../../redux/selectors/User/user.selector';
import AddEditProject from '../../AddEditProject/AddEditProject';
import { deleteProject, editProject } from '../../../../../redux/actions/Project/ProjectSet/projectsSet.actions';
import { projectsActions } from 'app/redux/slices/projects.slice';
import { skillActions } from 'app/redux/slices/skills.slice';
import noImgFound from 'assets/images/no-img-found.png';


export class ProjectDetailsContent extends Component {

    state = {
        intervenantsDisplay: localStorage.displayIntervenantsSection === undefined ? true : localStorage.displayIntervenantsSection === 'true',
        stackDisplay: localStorage.displayStackSection === undefined ? true : localStorage.displayStackSection === 'true',
        redirect: false
    };

    componentDidMount() {
        this.props.getAllSkillSet();
        this.props.setCurrentProject(this.props.project);
    }

    intervenantsDisplayStateChange = (state) => {
        const newState = !state;
        localStorage.setItem('displayIntervenantsSection', newState);
        this.setState({
            intervenantsDisplay: newState
        });
    };

    displayDate = (dateParams) => {
        if(dateParams === null) {
            return <></>;
        }
        const date = new Date(dateParams);
        return <>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
        </>;
    };

    deleteOneProject = () => {
        const { project } = this.props;
        this.props.deleteProject(project.id);
    };

    validateOneProject = () => {
        const { project } = this.props;
        const listMission = this.cleanedListMission(project);
        const projectBeingValidated = {
            id: project.id,
            client: project.client,
            name: project.name,
            startDate: project.startDate,
            endDate: project.endDate,
            description: project.description,
            listSkill: project.listSkill,
            validated: true,
            creator: project.creator,
            listMission
        };
        this.props.editProject(projectBeingValidated);
    };

    cleanedListMission = (project) => {
        const listMission = [];
        if(project) {
            project.listMission.forEach((mission) => {
                const copy = {
                    id: mission.id,
                    projectId: mission.projectId,
                    description: mission.description,
                    listSkill: mission.listSkill,
                    listUser: mission.listUser,
                    startDate: mission.startDate,
                    endDate: mission.endDate
                };
                listMission.push(copy);
            });
        }
        return listMission;
    };

    getAllSkillsFromMissions = (listMission) => {
        let allSkill = [];
        if(listMission) {
            listMission.forEach((mission) => {
                allSkill = allSkill.concat(mission.listSkill);
            });
        }
        const allSkillId = allSkill.map((skill) => skill.id);
        const technicalContext = [];
        for(let index = 0; index < allSkill.length; index++) {
            const id = allSkill[index].id;
            if(allSkillId.indexOf(id) === index) {
                technicalContext.push(allSkill[index]);
            }
        }
        return technicalContext;
    };

    getAllSkillsFromAnnexStack = () => {
        const skillsFromMissions = this.getAllSkillsFromMissions(this.props.project.listMission).map((skill) => skill.id);
        const annexContext = [];
        if(this.props.project && this.props.project.listSkill) {
            this.props.project.listSkill.forEach((skill) => {
                if(!skillsFromMissions.includes(skill.id)) {
                    annexContext.push(skill);
                }
            });
        }
        return annexContext;
    };

    render() {

        const { currentUser, project } = this.props;
        const technicalContext = this.getAllSkillsFromMissions(this.props.project.listMission);
        const annexContext = this.getAllSkillsFromAnnexStack();
        return (
            <>
                <div className="project-details-content">
                    <Paper className="paper-info">
                        {currentUser.roles && currentUser.roles.some((role) => role.name === 'ADMIN')
                            ? <AddEditProject trueIfAddAndFalseIfEdit={false} projectToEdit={project}/>
                            : null}

                        <Hidden only={[
                            'xs',
                            'sm'
                        ]}>
                            <Grid container direction="row">
                                <img className="resize" alt={project.client.name}
                                    src={project.client.imageUrl
                                        ? project.client.imageUrl
                                        : noImgFound}/>

                                <Grid item xs container direction="column" spacing={1} className="img-title-buttons">
                                    <Grid item className="header">
                                        <h1 className="title-project-desktop">
                                            {project.name}
                                        </h1>
                                        <Grid container justifyContent="flex-end" spacing={2}
                                            style={{ margin: '0 1%' }}
                                        >
                                            {(currentUser.roles &&
                                            currentUser.roles.some((role) => role.name === 'ADMIN') && !project.validated) &&
                                            <Grid item>
                                                <Button color="primary" variant="outlined"
                                                    onClick={() => {
                                                        if(window.confirm('Êtes-vous sûr de vouloir valider ce projet?')) {
                                                            this.validateOneProject();
                                                        }
                                                    }}>
                                                    Valider ce projet
                                                </Button>
                                            </Grid>
                                            }
                                            {(currentUser.roles &&
                                            currentUser.roles.some((role) => role.name === 'ADMIN') &&
                                            project.listMission && project.listMission.length <= 0) &&
                                            <Grid item>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        if(window.confirm('Êtes-vous sûr de vouloir supprimer ce projet?')) {
                                                            this.deleteOneProject();
                                                        }
                                                    }}>
                                                    Supprimer ce projet
                                                </Button>
                                            </Grid>}
                                            <Grid item container justifyContent="flex-end" direction="row" className="creator">
                                                {`Projet créé par : ${this.props.project.creator.firstName}  
                                            ${this.props.project.creator.lastName}`}
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="row">
                                        <h3 className="field">Client</h3>
                                        {project.client &&
                                            <Chip className="client-chip" size="small" label={project.client.name}/>
                                        }
                                    </Grid>

                                    <Grid item container direction="row">
                                        <h3 className="field">Date de début</h3>
                                        {project.startDate &&
                                            <Chip className="start-date-chip" size="small" label={this.displayDate(project.startDate)}/>
                                        }
                                        <h3 className="field">Date de fin</h3>
                                        {project.endDate &&
                                            <Chip className="end-date-chip" size="small" label={this.displayDate(project.endDate)}/>
                                        }


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden only={[
                            'md',
                            'lg',
                            'xl'
                        ]}>
                            <Grid className="project-details-mobile" container direction="row">

                                <Grid item className="info-mobile-title">
                                    <h1 className="title-project-mobile">
                                        {project.name}
                                    </h1>
                                    <Grid container justifyContent="center" spacing={2} className="Boutons" >
                                        {(currentUser.roles &&
                                        currentUser.roles.some((role) => role.name === 'ADMIN') && !project.validated) &&
                                            <Grid item className="BoutonValider">
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => {
                                                        if(window.confirm('Êtes-vous sûr de vouloir valider ce projet?')) {
                                                            this.validateOneProject();
                                                        }
                                                    }}>
                                                    Valider ce projet
                                                </Button>
                                            </Grid>
                                        }
                                        {(currentUser.roles &&
                                        currentUser.roles.some((role) => role.name === 'ADMIN') &&
                                        project.listMission && project.listMission.length <= 0) &&
                                            <Grid item className="BoutonSupprimer">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        if(window.confirm('Êtes-vous sûr de vouloir supprimer ce projet?')) {
                                                            this.deleteOneProject();
                                                        }
                                                    }}>
                                                    Supprimer ce projet
                                                </Button>
                                            </Grid>
                                        }
                                        <Grid item container justifyContent="center" direction="row" className="creator">
                                            {`Projet créé par : ${this.props.project.creator.firstName}  
                                            ${this.props.project.creator.lastName}`}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className="info-mobile-other" container direction="row">
                                    <h3 className="field">Client</h3>
                                    {project.client &&
                                        <Chip className="client-chip" size="small" label={project.client.name}/>
                                    }
                                </Grid>

                                <Grid item className="info-mobile-other" container direction="row">
                                    <h3 className="field">Date de début</h3>
                                    {project.startDate &&
                                            <Chip className="start-date-chip" size="small" label={this.displayDate(project.startDate)}/>
                                    }
                                </Grid>
                                <Grid item className="info-mobile-other" container direction="row">
                                    <h3 className="field">Date de fin</h3>
                                    {project.endDate &&
                                            <Chip className="end-date-chip" size="small" label={this.displayDate(project.endDate)}/>
                                    }
                                </Grid>

                            </Grid>
                        </Hidden>
                        <Grid className="info-mobile-other" >
                            <h3 className="field">Contexte et objectifs :</h3>
                            <Grid item container direction="row">
                                <div className="description-project" dangerouslySetInnerHTML = {{ __html: project.description }}/>
                            </Grid>
                        </Grid>
                    </Paper>

                    <div className="div-accordion">
                        <Paper className="stack-paper-section">
                            <Grid item container direction="column" alignItems="flex-start">
                                <Grid item className="section">
                                    <Icon className="icon">developer_mode</Icon>
                                    <Typography className="label">Stack technique (couverte par Oxyl) : </Typography>
                                </Grid>
                                <ProjectStack listSkill={technicalContext} listSkillCovered={technicalContext} editable={false}/>
                                <Grid item className="section">
                                    <Icon className="icon">developer_mode</Icon>
                                    <Typography className="label">Stack annexe du projet (non couverte par Oxyl) : </Typography>
                                </Grid>
                                <ProjectStack listSkill={annexContext} listSkillCovered={technicalContext} editable={true}/>
                            </Grid>
                        </Paper>
                    </div>

                    <div className="div-accordion">
                        <MuiThemeProvider theme={collapsibleTheme}>
                            <Accordion className="not-expanded" expanded={this.state.intervenantsDisplay}
                                onChange={() => this.intervenantsDisplayStateChange(
                                    this.state.intervenantsDisplay,
                                    'displayIntervenantsSection'
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid item container direction="row">
                                        <Grid item className="section">
                                            <Icon className="icon">supervised_user_circle</Icon>
                                            <Typography className="label">Missions</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MissionsTable/>
                                </AccordionDetails>
                            </Accordion>
                        </MuiThemeProvider>
                    </div>
                </div>
            </>
        );
    }
}

ProjectDetailsContent.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        validated: PropTypes.bool,
        client: PropTypes.object,
        listMission: PropTypes.array,
        listSkill: PropTypes.array,
        creator: PropTypes.object
    }),
    currentUser: PropTypes.object,
    setCurrentProject: PropTypes.func,
    getAllSkillSet: PropTypes.func,
    deleteProject: PropTypes.func,
    editProject: PropTypes.func
};

function mapStateToProps(state) {
    return {
        project: selectCurrentProject(state).data,
        currentUser: selectCurrentUser(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setCurrentProject: (project) => dispatch(projectsActions.setCurrentProject(project)),
        getAllSkillSet: () => dispatch(skillActions.getAllSkills()),
        deleteProject: (projectId) => dispatch(deleteProject(projectId)),
        editProject: (project) => dispatch(editProject(project))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ProjectDetailsContent));
