import axiosClient from './utils/axiosClient';

export const QUESTION_URI = 'questions';

export function getDailyQuestionsApi(userId) {
    return axiosClient.get(`${QUESTION_URI}/daily/${userId}`);
}

export function putDailyQuestionsApi(userId, date) {
    return axiosClient.put(`${QUESTION_URI}/daily/${userId}`, `"${date}"`);
}
