import React from 'react';
import { AssignTypes } from 'app/utils/functions';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';

export default function EntretienTitleDraggable(props) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: AssignTypes.QUESTION,
        item: {
            type: AssignTypes.QUESTION,
            id: props.id,
            name: props.name
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging())
        })

    }), [props.id]);

    return (
        <Link
            className="columnIdLink"
            key={props.id}
            to={`${QUESTIONS_PAGE_URL}/resolve/${props.id}`}
            ref={drag}
            style={
                {
                    textDecoration: 'none',
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move'
                }
            }
        >
            {props.name}
        </Link>
    );
}

EntretienTitleDraggable.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
};
