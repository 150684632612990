import { arraysEqual } from '../../utils/functions';
import { displayStatementHelperText, displayStatementTooltips, statementExist } from '../createEditQuestion/createEditQuestionForm/body/CreateEditQuestionBody.functions';
import { displayTitleHelperText, displayTitleTooltips, titleIsValid } from '../createEditQuestion/createEditQuestionForm/header/CreateEditQuestionHeader.functions';


//  Title validity

export function serieTitleIsValid(title) {
    return titleIsValid(title);
}

export function displaySerieTitleHelperText(title) {
    return displayTitleHelperText(title);
}

export function displaySerieTitleTooltips(title) {
    return displayTitleTooltips(title);
}

//  Description validity

export function serieDescriptionIsValid(description) {
    return statementExist(description);
}

export function displaySerieDescriptionHelperText(description) {
    return displayStatementHelperText(description);
}

export function displaySerieDescriptionTooltips(description) {
    return displayStatementTooltips(description);
}

export const timerCantBeSetToZero = 'Ne peut pas être égal à 00:00.';

// Timer Validity
function timerIsNotSetToZero(timer) {
    return timer !== '00:00';
}

export function timerIsValid(timer) {
    return timerIsNotSetToZero(timer);
}

export function displayTimerHelperText(timer) {
    let helpText = '';
    if(!timerIsValid(timer)) {
        helpText = helpText.concat(timerCantBeSetToZero);
    }
    return helpText;
}

export function displayTimerTooltips(timer) {
    let helperText = displayTimerHelperText(timer);
    if(helperText) {
        helperText = 'Chronomètre: '.concat(helperText);
    }
    return helperText;
}

// Question list

export function serieHasEnoughQuestions(serie) {
    if(!serie.questionReducedList) {
        return false;
    }
    return serie.questionReducedList.length > 0;
}

export function serieQuestionListIsValid(serie) {
    return serieHasEnoughQuestions(serie);
}

export function displaySerieQuestionListHelpertext(serie) {
    let helpText = '';
    if(!serieQuestionListIsValid(serie)) {
        helpText = helpText.concat('Il faut au minimum une question dans la série.');
    }
    return helpText;
}

export function displaySerieQuestionListTooltips(timer) {
    let helperText = displaySerieQuestionListHelpertext(timer);
    if(helperText) {
        helperText = 'Liste de questions: '.concat(helperText);
    }
    return helperText;
}

export function formIsValid(serie) {
    return serieHasEnoughQuestions(serie) && serieTitleIsValid(serie.title) &&
        serieDescriptionIsValid(serie.description) &&
        timerIsValid(serie.maxDuration);
}

export function serieIsEqualTo(serie1, serie2) {
    if(!serie1 || !serie2) {
        if(!serie1 && !serie2) {
            return true;
        }
        return false;
    }
    return (serie1.id === serie2.id) &&
    (serie1.title === serie2.title) &&
    (serie1.description === serie2.description) &&
    (serie1.maxDuration === serie2.maxDuration) &&
    (arraysEqual(serie1.questionReducedList, serie2.questionReducedList));
}
