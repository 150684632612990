import React, { useEffect } from 'react';
import './Skill.scss';
import SkillContent from './SkillContent/SkillContent';
import { getSkill } from 'app/redux/actions/Skill/SkillSet/skillSet.actions';
import { selectCurrentSkill } from 'app/redux/selectors/Skill/SkillSet/skillSet.selector';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export default function Skill() {

    const dispatch = useDispatch();
    const params = useParams();

    const skill = useSelector(selectCurrentSkill);

    useEffect(() => {
        dispatch(getSkill(params.skillName, false));
    }, [params.skillName]);

    return (
        <section className="skill">
            <div className="wrapper">
                {skill && <SkillContent skill={skill}/>}
            </div>
        </section>
    );
}
