import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSeriesByChapterIdApi } from 'app/api/seriesApi';
import SerieListDisplay from './SerieListDisplay';
import { selectStudent } from 'app/redux/selectors/Chapter/chapter.selector';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { RETRIEVE_SERIE_ERROR } from 'app/Snackbar/NotificationMessages';
import TreeNode from 'app/utils/treeStructure/TreeNode';

interface SerieRequestByChapterIdProps {
    node: TreeNode;
    chapterId: number;
    onClickQuestion(): void;
    selectedQuestion: number | null;
}

export const SerieRequestByChapterId = (props: SerieRequestByChapterIdProps) => {
    const dispatch = useDispatch();

    const studentSelected = useSelector(selectStudent);

    const [seriesData, setSeriesData] = useState([]);

    useEffect(() => {
        if(studentSelected) {
            getSeriesByChapterIdApi(props.chapterId)
                .then(
                    (response) => {
                        setSeriesData(response.data);
                    },
                    (_error) => {
                        dispatch(notificationError(RETRIEVE_SERIE_ERROR));
                    }
                );
        }
    }, [props.chapterId, studentSelected]);

    return (
        <SerieListDisplay
            node={props.node}
            data={seriesData}
            chapterId={props.chapterId}
            onClickQuestion={props.onClickQuestion}
            selectedQuestion={props.selectedQuestion}
        />
    );
};
