/* eslint-disable class-methods-use-this */
/* eslint-disable complexity */
import React, { Component } from 'react';
import './ProfileContent.scss';
import PropTypes from 'prop-types';
import {
    Card, CardContent, CardMedia, Tabs, Tab, Dialog, Grid, IconButton,
    Icon, Button, Menu, MenuItem, DialogContent, DialogActions, Tooltip,
    Divider, withWidth
} from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectExcilyen } from '../../redux/selectors/Excilyen/ExcilyenSet/excilyenSet.selector';
import { selectCurrentUser, selectCurrentUserSkillSet } from '../../redux/selectors/User/user.selector';
import RichTextEditor from '../richTextEditor/RichTextEditor';
import UserSkills from '../userSkillCard/UserSkills';
import UserStatisticsContent from './UserStatisticsContent';
import { getAllFamilies } from '../../redux/actions/FamilyCategory/familiesCategories.actions';
import { skillActions } from 'app/redux/slices/skills.slice';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';
import { getAllExcilyenSet, getDetailedExcilyenById } from '../../redux/actions/Excilyen/ExcilyenSet/excilyenSet.actions';
import { getCurrentUserById, patchUser } from '../../redux/actions/User/user.actions';
import DetailedMissionsTable from './DetailedMissionsTable';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { withStyles } from '@material-ui/core/styles';
import { selectUserAskedCoaches, selectUserCoaches } from '../../redux/selectors/Coaching/coaching.selector';
import {
    getAskedCoachesByStudentId, getCoachesByStudentId,
    GET_USER_ASKED_COACHES, GET_COACHES_BY_STUDENT_ID
} from '../../redux/actions/Coaching/coaching.actions';
import RequestCoachButton from './RequestCoachButton';
import { selectLoading } from '../../redux/selectors/Loading/loading.selector';
import { missionActions } from 'app/redux/slices/missions.slice';

const useStyles = () => ({
    requestCoachButton: {
        position: 'absolute',
        top: '10px',
        right: '10px'
    }
});

export class ProfileContent extends Component {

    constructor() {
        super();
        this.state = {
            openDropdown: false,
            openDropdownEdit: false,
            value: 0,
            prevScrollpos: window.pageYOffset,
            hasScrolled: false,
            editDescription: false
        };
    }

    currentUserId = () => AuthenticationContainer.getCurrentUserId();

    componentDidMount() {
        if(this.props.id === undefined) {
            this.props.getCurrentUserById();
            this.props.getAllDetailedMissionsByUserId(AuthenticationContainer.getCurrentUserId());
        } else {
            this.props.getExcilyenById(this.props.id);
            this.props.getAllDetailedMissionsByUserId(this.props.id);
            this.props.getSkillSet();
            this.props.getAllFamilies();
            this.props.getAllExcilyenSet();
            window.addEventListener('scroll', this.handleScroll);
        }
        this.props.getAskedCoachesByStudentId(this.currentUserId());
        this.props.getCoachesByStudentId(this.currentUserId());
    }

    handleScroll = () => {
        this.setState({
            hasScrolled: window.pageYOffset > 0 && !this.state.editDescription
        });
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    editDescriptionDesktop = () => {
        this.setState({
            editDescription: true,
            hasScrolled: false
        });
    };

    editDescriptionMobile = () => {
        this.setState({
            openDropdown: false,
            openDropdownEdit: true,
            editDescription: true,
            hasScrolled: false
        });
    };

    handleOpen = (event) => {
        this.setState({
            openDropdown: true,
            anchor: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({ openDropdown: false });
    };

    closeModalEdit = () => {
        this.setState({
            openDropdownEdit: false,
            editDescription: false,
            hasScrolled: window.pageYOffset > 0
        });
    };

    onChangeDescription = (event, editor) => {
        this.setState({
            ...this.state,
            changes: {
                description: editor.getData()
            },
            changedDescription: true
        });
    };

    saveDescription = () => {
        if(this.state.changedDescription) {
            this.props.patchUser(this.props.user.id, this.state.changes);
            this.setState({
                editDescription: false,
                openDropdownEdit: false,
                hasScrolled: window.pageYOffset > 0
            });
        }
    };

    displayDate = (dateParams) => {
        if(dateParams === null) {
            return <p>Date de dernière connexion non renseignée</p>;
        }
        const date = new Date(dateParams);
        return <p>
            Dernière connexion le {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
        </p>;
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    displayTab = () => <div className="array-add-skills">
        <>Compétences</>
        <div className="button-add-skills">
            <IconButton component={Link} to="/add-skills">
                <Icon>add_circle</Icon>
            </IconButton>
        </div>
    </div>;

    render() {
        const { user, excilyen, id, askedCoachs, userCoachs, classes } = this.props;
        return (
            <div data-testid="profile-content">
                {!this.props.isFetchingSkills &&
                    <div className="user-content">
                        <div className={this.state.hasScrolled ? 'to-be-fixed' : ''}>
                            <div className={this.state.hasScrolled ? 'to-be-relative' : ''}>
                                <div className="div-miroir" />
                                <Card className="card-user-profile">
                                    <CardMedia
                                        component="img"
                                        className="image-user"
                                        image={id && excilyen ? excilyen.imageUrl : user.imageUrl}
                                        style={{ objectFit: 'contain' }}
                                        referrerPolicy="no-referrer"
                                    />
                                    {!this.state.hasScrolled && (isWidthUp('sm', this.props.width)
                                        ? <>
                                            {id && (id !== this.currentUserId()) && excilyen
                                                ? <div>
                                                    {this.props.isFetchingAskedCoachs || this.props.isFetchingCoachs
                                                        ? <></>
                                                        : <RequestCoachButton
                                                            coachId={id}
                                                            className={classes.requestCoachButton}
                                                            isAlreadyAskedAsCoach={Boolean(askedCoachs) &&
                                                            askedCoachs.filter((askedCoach) => askedCoach.id === id).length > 0}
                                                            isAlreadyCoach={Boolean(userCoachs) &&
                                                            userCoachs.filter((coach) => coach.id === id).length > 0} /> }
                                                </div>
                                                : this.state.editDescription
                                                    ? <Tooltip title="Enregistrer" aria-label="save">
                                                        <IconButton onClick={this.saveDescription} className="button-edit-icon"
                                                            aria-label="save" data-testid="save-description-desktop">
                                                            <Icon>save</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    : <Tooltip title="Éditer ma description" aria-label="edit">
                                                        <IconButton onClick={this.editDescriptionDesktop} className="button-edit-icon"
                                                            aria-label="edit" data-testid="edit-description-desktop">
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                        </>
                                        : <>
                                            <Button aria-controls="simple-menu" onClick={this.handleOpen}
                                                className="button-edit-icon-sm"
                                                aria-label="more"
                                                data-testid="more-menu">
                                                <Icon>more_vert</Icon>
                                            </Button>
                                            <Menu id="simple-menu" className="menu" keepMounted anchorEl={this.state.anchor}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center'
                                                }}
                                                open={this.state.openDropdown} onClose={this.handleClose}>
                                                {id && (id !== this.currentUserId()) && excilyen
                                                    ? <RequestCoachButton
                                                        coachId={id}
                                                        className="nav-link"
                                                        displayAsMenuItem={true}
                                                        isAlreadyAskedAsCoach={Boolean(askedCoachs) &&
                                                                askedCoachs.filter((askedCoach) => askedCoach.id === id).length > 0}
                                                        isAlreadyCoach={Boolean(userCoachs) &&
                                                                userCoachs.filter((coach) => coach.id === id).length > 0} />
                                                    : <MenuItem className="nav-link" onClick={this.editDescriptionMobile} data-testid="edit-description-mobile">
                                                            Éditer ma description
                                                    </MenuItem>
                                                }
                                            </Menu>
                                            <Dialog disableEnforceFocus={true} maxWidth="md" fullWidth={true}
                                                fullScreen={!isWidthUp('sm', this.props.width)} open={this.state.openDropdownEdit}
                                                onClose={this.closeModalEdit} style={{ overflowY: 'scroll' }}
                                                aria-labelledby="form-dialog-title">
                                                <h1 id="form-dialog-title">Éditer ma description</h1>
                                                <DialogContent id="RichTextEditorContainer">
                                                    <div className="rich-text-editor" data-testid="rich-text-editor-mobile">
                                                        <RichTextEditor
                                                            data={
                                                                user.description
                                                            }
                                                            onChange={this.onChangeDescription} />
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.closeModalEdit} color="primary" data-testid="cancel-description-mobile">
                                                            Annuler
                                                    </Button>
                                                    <Button onClick={this.saveDescription} color="primary" data-testid="save-description-mobile">
                                                            Enregistrer
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    )}
                                    <CardContent className="card-user-content">
                                        <h3>
                                            {id && excilyen ? `${excilyen.firstName} ${excilyen.lastName}` : `${user.firstName} ${user.lastName}`}
                                        </h3>
                                        <p>{id && excilyen ? excilyen.email : user.email}</p>
                                        <p>Promo {id && excilyen ? excilyen.promo : user.promo}</p>
                                        {this.displayDate(id && excilyen ? excilyen.lastConnectionDateTime : user.lastConnectionDateTime)}
                                        {!this.state.hasScrolled &&
                                            <>
                                                {(id && (id !== this.currentUserId()) && excilyen)
                                                    ? <div className="description-user" dangerouslySetInnerHTML={{ __html: excilyen.description }} />
                                                    : this.state.editDescription
                                                        ? <div className="rich-text-editor" data-testid="rich-text-editor-desktop">
                                                            <RichTextEditor
                                                                data={
                                                                    user.description
                                                                }
                                                                onChange={this.onChangeDescription} />
                                                        </div>
                                                        : <div className="description-user" dangerouslySetInnerHTML={{ __html: user.description }} />
                                                }
                                            </>
                                        }
                                    </CardContent>
                                </Card>
                                {!this.state.hasScrolled &&
                                    <Divider variant="middle" id="divider" light={true} />
                                }
                                <div className="tab-user">
                                    <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="primary">
                                        {id && excilyen
                                            ? <Tab label="Compétences" id="0" />
                                            : <Tab label={this.displayTab()} id="0" />}
                                        <Tab label="Graphiques" id="1" />
                                        <Tab label="Missions" data-testid="tab-missions" id="2" />
                                        <Tab hidden label="Stats" id="3" />
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        {this.state.hasScrolled &&
                            <div style={{ height: '240px' }} />
                        }
                        <div hidden={this.state.value !== 0} >
                            <Grid container spacing={3} direction="row" alignItems="center">
                                {id && excilyen
                                    ? excilyen.skillDetailedApiDtos
                                        ? <UserSkills skills={excilyen.skillDetailedApiDtos} />
                                        : 'Pas de compétence'
                                    : this.props.userSkillSet
                                        ? <UserSkills skills={this.props.userSkillSet} />
                                        : 'Pas de compétence'
                                }
                            </Grid>
                        </div>
                        <div hidden={this.state.value !== 1}>
                            <UserStatisticsContent userSkills={id && (id !== this.currentUserId()) && excilyen
                                ? excilyen.skillDetailedApiDtos
                                : this.props.userSkillSet}
                            user={id && excilyen ? excilyen : user} />
                        </div>

                        <div hidden={this.state.value !== 2}>
                            <Grid container xs={12} md={12} lg={12} item className="project-table" data-testid="missions">
                                <Grid xs={12} sm={12} lg={12} item>
                                    <DetailedMissionsTable />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

ProfileContent.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object,
    excilyen: PropTypes.object,
    id: PropTypes.number,
    userSkillSet: PropTypes.array,
    userCoachs: PropTypes.array,
    askedCoachs: PropTypes.array,
    isFetchingSkills: PropTypes.bool,
    isFetchingAskedCoachs: PropTypes.bool,
    isFetchingCoachs: PropTypes.bool,
    width: PropTypes.string,
    getCurrentUserById: PropTypes.func,
    getSkillSet: PropTypes.func,
    patchUser: PropTypes.func,
    value: PropTypes.number,
    getAllExcilyenSet: PropTypes.func,
    getAllFamilies: PropTypes.func,
    getExcilyenById: PropTypes.func,
    getAllDetailedMissionsByUserId: PropTypes.func,
    getCoachesByStudentId: PropTypes.func,
    getAskedCoachesByStudentId: PropTypes.func
};

const mapStateToProps = (state) => ({
    user: selectCurrentUser(state),
    excilyen: selectExcilyen(state),
    userSkillSet: selectCurrentUserSkillSet(state),
    userCoachs: selectUserCoaches(state),
    askedCoachs: selectUserAskedCoaches(state),
    isFetchingSkills: selectAllSkills(state).loading,
    isFetchingAskedCoachs: selectLoading(state)[GET_USER_ASKED_COACHES],
    isFetchingCoachs: selectLoading(state)[GET_COACHES_BY_STUDENT_ID]
});

export const mapDispatchToProps = (dispatch) => ({
    getExcilyenById: (id) => dispatch(getDetailedExcilyenById(id)),
    getAllFamilies: () => dispatch(getAllFamilies()),
    getCurrentUserById: () => dispatch(getCurrentUserById()),
    getSkillSet: () => dispatch(skillActions.getAllSkills()),
    patchUser: (userId, changes) => dispatch(patchUser(userId, changes)),
    getAllExcilyenSet: () => dispatch(getAllExcilyenSet()),
    getAllDetailedMissionsByUserId: (userId) => dispatch(missionActions.getAllDetailedMissionsByUserId(userId)),
    getCoachesByStudentId: (userId) => dispatch(getCoachesByStudentId(userId)),
    getAskedCoachesByStudentId: (userId) => dispatch(getAskedCoachesByStudentId(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(useStyles)(ProfileContent)));
