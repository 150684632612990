import {
    notificationError
} from '../../Notification/notifications.actions';
import {
    getExcilyenSetApi,
    getExcilyenDetailedBySkillNameApi,
    getDetailedExcilyenByIdApi,
    getExcilyenDetailedBySkillIdApi,
    getDetailedExcilyenSkillsApi
} from '../../../../api/excilyenApi';
import {
    EXPIRED_SESSION,
    RETRIEVE_EXCILIENS_ERROR,
    RETRIEVE_EXCILYENS_ERROR,
    RETRIEVE_USER_INFOS_ERROR
} from '../../../../Snackbar/NotificationMessages';

export const GET_ALL_EXCILYENS = 'GET_ALL_EXCILYENS';
export const GET_ALL_EXCILYENS_REQUEST = 'GET_ALL_EXCILYENS_REQUEST';
export const GET_ALL_EXCILYENS_SUCCESS = 'GET_ALL_EXCILYENS_SUCCESS';
export const GET_ALL_EXCILYENS_FAILURE = 'GET_ALL_EXCILYENS_FAILURE';

export const GET_EXCILYEN = 'GET_EXCILYEN';
export const GET_EXCILYEN_SUCCESS = 'GET_EXCILYEN_SUCCESS';
export const GET_EXCILYEN_REQUEST = 'GET_EXCILYEN_REQUEST';
export const GET_EXCILYEN_FAILURE = 'GET_EXCILYEN_FAILURE';

export const GET_DETAILED_EXCILYENS_SKILLS_ = 'GET_DETAILED_EXCILYENS_SKILLS_';
export const GET_DETAILED_EXCILYENS_SKILLS_SUCCESS = 'GET_DETAILED_EXCILYENS_SKILLS_SUCCESS';
export const GET_DETAILED_EXCILYENS_SKILLS_REQUEST = 'GET_DETAILED_EXCILYENS_SKILLS_REQUEST';
export const GET_DETAILED_EXCILYENS_SKILLS_FAILURE = 'GET_DETAILED_EXCILYENS_SKILLS_FAILURE';

export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME';
export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_REQUEST = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_REQUEST';
export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_SUCCESS = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_SUCCESS';
export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_FAILURE = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_FAILURE';

export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID';
export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_SUCCESS = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_SUCCESS';
export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_REQUEST = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_REQUEST';
export const GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_FAILURE = 'GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_FAILURE';

export function setAllExilyenSet(expertSet) {
    return {
        type: GET_ALL_EXCILYENS_SUCCESS,
        payload: expertSet
    };
}

export function setExcilyen(user) {
    return {
        type: GET_EXCILYEN_SUCCESS,
        payload: user
    };
}

export const getAllExcilyenSet = () => (dispatch) => {
    dispatch({ type: GET_ALL_EXCILYENS_REQUEST });

    return getExcilyenSetApi().then((response) => {
        dispatch({
            type: GET_ALL_EXCILYENS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_EXCILYENS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_EXCILYENS_ERROR));
        }
    });
};

export const getDetailedExcilyenSkills = () => (dispatch) => {
    dispatch({ type: GET_DETAILED_EXCILYENS_SKILLS_REQUEST });

    return getDetailedExcilyenSkillsApi().then((response) => {
        dispatch({
            type: GET_DETAILED_EXCILYENS_SKILLS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_DETAILED_EXCILYENS_SKILLS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_EXCILYENS_ERROR));
        }
    });
};

export const getDetailedExcilyenById = (id) => (dispatch) => {
    dispatch({ type: GET_EXCILYEN_REQUEST });

    return getDetailedExcilyenByIdApi(id).then((response) => {
        dispatch({
            type: GET_EXCILYEN_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_EXCILYEN_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_USER_INFOS_ERROR));
        }
    });
};

export const getExcilyensDetailedBySkillName = (skillName) => (dispatch) => {
    dispatch({ type: GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_REQUEST });

    return getExcilyenDetailedBySkillNameApi(skillName).then((response) => {
        dispatch({
            type: GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_EXCILYENS_DETAILED_BY_SKILL_NAME_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_EXCILIENS_ERROR));
        }
    });
};

export const getExcilyensDetailedBySkillId = (skillId) => (dispatch) => {
    dispatch({ type: GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_REQUEST });

    return getExcilyenDetailedBySkillIdApi(skillId).then((response) => {
        dispatch({
            type: GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_EXCILYENS_DETAILED_BY_SKILL_ID_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_EXCILIENS_ERROR));
        }
    });
};
