import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { selectSerieToResolve } from 'app/redux/selectors/Question/serie.selector';
import { useSelector, useDispatch } from 'react-redux';
import { getSerieBySerieHistoryId, resetSerieToResolve } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import ResolveSerieDisplay from './ResolveSerieDisplay';
import { QUESTIONS_PAGE_URL } from 'app/Routes';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { mapDurationToObject } from 'app/utils/date.functions';

const useStyles = makeStyles(() => ({
    circularProgress: {
        marginLeft: '47%',
        marginTop: '23%'
    }
}));

function ResolveSerieRequestBySerieHistoryId(props) {

    const classes = useStyles();

    const getCurrentUserId = () => props.candidateUuid
        ? undefined
        : AuthenticationContainer.getCurrentUserId();

    const dispatch = useDispatch();
    const serieToResolve = useSelector(selectSerieToResolve);

    useEffect(() => {
        dispatch(getSerieBySerieHistoryId(props.serieHistoryId, getCurrentUserId(), props.candidateUuid));
        return () => {
            dispatch(resetSerieToResolve());
        };
    }, [props.serieHistoryId]);

    return (
        <>
            <div data-testid = "resolve-serie" style={{ height: '100%' }}>
                {serieToResolve?.serieHistoryId
                    ? <ResolveSerieDisplay
                        maxDuration={mapDurationToObject(serieToResolve.durationLeft)}
                        questionsList={serieToResolve.questionsNotDone.map((questionResume) => questionResume.question)}
                        nbTotalQuestions={serieToResolve.nbTotalQuestions}
                        serieId={props.serieId}
                        serieHistoryUpdate={{
                            id: serieToResolve?.serieHistoryId,
                            questions: serieToResolve?.questionsNotDone.map((questionResume) => ({
                                id: questionResume.questionHistoryId,
                                question: questionResume.question
                            }))
                        }}
                        redirect={!props.candidateUuid && QUESTIONS_PAGE_URL}
                        candidateUuid={props.candidateUuid}
                        goodAnswers={serieToResolve.goodAnswers}
                        onFinishCandidateSerie={props.onFinishCandidateSerie}
                    />
                    : <CircularProgress
                        className={classes.circularProgress}
                        size={80}
                    />
                }
            </div>
        </>
    );
}

ResolveSerieRequestBySerieHistoryId.propTypes = {
    serieId: PropTypes.number,
    serieHistoryId: PropTypes.number,
    onFinishCandidateSerie: PropTypes.func.isRequired,
    candidateUuid: PropTypes.string
};

export default ResolveSerieRequestBySerieHistoryId;
