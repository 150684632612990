import axiosClient from './utils/axiosClient';

export const BOOKMARK_URI = 'bookmark';


export function putBookmarkApi(bookmark) {
    return axiosClient.put(BOOKMARK_URI, bookmark);
}

export function postBookmarkApi(bookmark) {
    return axiosClient.post(BOOKMARK_URI, bookmark);
}

export function getBookmarkByUserAndSkillApi(userId, skillId) {
    return axiosClient.get(`${BOOKMARK_URI}/user/${userId}/skill/${skillId}`);
}

export function deleteBookmarkByIdApi(id) {
    return axiosClient.delete(`${BOOKMARK_URI}/${id}`);
}
