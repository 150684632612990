import { Popup } from 'react-leaflet';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { selectClientFilteredBySectorAndUser } from '../../../../redux/selectors/Client/ClientFilter/clientsFilter.selector';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';
import { selectAllProjects } from '../../../../redux/selectors/Project/projects.selector';
import { getAllClients } from '../../../../redux/actions/Client/clients.actions';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import ExcilyenCard from '../../../../common/excilyenCard/ExcilyenCard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { projectsActions } from 'app/redux/slices/projects.slice';

export class ClientsMapMarkerPopup extends Component {

    componentDidMount() {
        this.props.getAllClients();
        this.props.getAllProjects();
    }

    getAllActualExcilyenOfClient = (listProjects) => {
        const now = new Date();
        const nowISO = now.toISOString();
        const momentDate = moment(nowISO).format('YYYY-MM-DDThh:mm:ss');
        const allMissionsOfClient = listProjects && listProjects.flatMap((project) => project.listMission);
        const projectFilteredByMissionNotFinished = allMissionsOfClient.filter((mission) => ((mission.endDate === null) ||
            (mission.endDate >= momentDate)));
        const allExcilyens = projectFilteredByMissionNotFinished.flatMap((mission) => mission.listUser);
        const allExcilyensId = allExcilyens.map((excilyen) => excilyen.id);
        return allExcilyens.filter((excilyen, index) => allExcilyensId.indexOf(excilyen.id) === index);
    };

    render() {
        const { data } = this.props;
        const projectsFilteredByClient = this.props.allProjects.data.filter((project) => (project.client.id === data.id));
        const allExcilyenOfClient = this.getAllActualExcilyenOfClient(projectsFilteredByClient);
        const excilyensCards = allExcilyenOfClient
            .sort((excilyen1, excilyen2) => excilyen1.firstName
                .localeCompare(excilyen2.firstName) || excilyen1.lastName
                .localeCompare(excilyen2.lastName))
            .map((excilyen, index) => <Grid className="excilyen-cards-map" key={index} item xs={6} sm={3} md={3} lg={2} xl={2}><ExcilyenCard
                excilyen={excilyen} key={index}/></Grid>);
        const { name, address } = this.props.data;

        return (<Popup>
            <div className="popup-text"><div className="popup-client-name"><Link to={`/clients/${data.id}`}>{name}</Link></div>
                <div className="popup-client-address">{address}</div>
                <Grid container direction="row" justifyContent="center" spacing={0} alignItems="center" className="home-excilyen-cards-map" item
                    xs={12} sm={12} lg={12}>
                    {
                        allExcilyenOfClient.length <= 0
                            ? 'Aucun excilyen n\'a encore travaillé avec ce client'
                            : excilyensCards
                    }
                </Grid>
            </div>
        </Popup>);
    }
}

ClientsMapMarkerPopup.propTypes = {
    data: PropTypes.object,
    allClients: PropTypes.array,
    getAllClients: PropTypes.func,
    currentUser: PropTypes.object,
    getAllProjects: PropTypes.func,
    allProjects: PropTypes.array,
    width: PropTypes.string
};

function mapStateToProps(state) {
    return {
        allClients: selectClientFilteredBySectorAndUser(state),
        currentUser: selectCurrentUser(state),
        allProjects: selectAllProjects(state).data
    };
}
export function mapDispatchToProps(dispatch) {
    return {
        getAllClients: () => dispatch(getAllClients()),
        getAllProjects: () => dispatch(projectsActions.getAllProjects())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ClientsMapMarkerPopup));
