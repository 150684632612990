import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SockJsClient from 'react-stomp';
import './QuestionResolver.scss';
import QuestionRequestByQuestionId from './QuestionRequest/QuestionRequestByQuestionId';
import { withRouter } from 'react-router-dom';
import { Badge, Grid, Tooltip, makeStyles } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { WebSocketActionsEnum } from 'app/utils/WebsocketActionsEnum';
import SerieScore from 'app/serie/ResolveSerie/SerieScore';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';

export function QuestionResolver(props) {
    const useStyles = makeStyles(() => ({
        serieToResolve: {
            display: 'flex',
            padding: '20px 0',
            height: '100%'
        },
        title: {
            paddingTop: '20px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '15px'
        },
        quizContainer: {
            margin: 0
        },
        leaderSidebar: {
            rowGap: '16px'
        },
        playerIcon: {
            minWidth: 'fit-content'
        },
        playersIcons: {
            rowGap: '16px',
            columnGap: '16px'
        },
        userIcon: {
            borderRadius: '50%',
            width: '40px',
            height: '40px'
        }
    }));

    const getCurrentUserId = () => Number.parseInt(AuthenticationContainer.getCurrentUserId(), 10);

    const classes = useStyles();
    const [usersInRoom, setUsersInRoom] = useState(props.usersInRoom ?? []);
    const [serieScoreData, setSerieScoreData] = useState();
    const [goodAnswers, setGoodAnswers] = useState(0);

    useEffect(() => {
        setUsersInRoom(props.usersInRoom?.map((user) => ({
            ...user,
            answers: [],
            validatedQuestion: Boolean(usersInRoom.find((userInRoom) => userInRoom.id === user.id)?.validatedQuestion)
        })));
    }, [props.usersInRoom]);

    useEffect(() => {
        if(props.isCurrentInteractiveQuizQuestionValidated === true) {
            setUsersInRoom(props.usersInRoom?.map((user) => ({
                ...user,
                answers: [],
                validatedQuestion: false
            })));
        }
    }, [props.isCurrentInteractiveQuizQuestionValidated]);

    useEffect(() => {
        if(serieScoreData) {
            const lengthScore = serieScoreData.quizResults.filter((object) => object.correctAnswer === true)
                .filter((object) => object.userId === String(getCurrentUserId())).length;
            setGoodAnswers(lengthScore);
        }
    }, [serieScoreData]);

    return (<>
        <SockJsClient
            url={`${process.env.REACT_APP_BACK_END}websocket`}
            topics={[`/quiz/${props.quizUuidFromPlayer}`]}
            onMessage={(msg) => {
                const [action, userId, selectedAnswerIdsString, correctAnswer] = msg.split('|');

                const selectedAnswerIds = selectedAnswerIdsString?.split(',').map((id) => parseInt(id, 10));
                if(action === WebSocketActionsEnum.USER_ANSWERED_CURRENT_QUESTION) {
                    setUsersInRoom((prevUsers) => prevUsers.map((user) => {
                        if(user.id === userId) {
                            if(correctAnswer === 'true') {
                                setGoodAnswers((prevState) => prevState + 1);
                            }
                            const updatedAnswers = user.answers.map((answer) => {
                                if(selectedAnswerIds.includes(answer.id)) {
                                    return { ...answer, selected: true };
                                }
                                return answer;
                            });
                            return {
                                ...user,
                                answers: updatedAnswers,
                                validatedQuestion: true,
                                correctAnswer: JSON.parse(correctAnswer)
                            };
                        }
                        return user;
                    }));
                }
                if(action === WebSocketActionsEnum.SEND_SERIE_SCORE) {
                    const scoreData = msg.split('|')[1];
                    setSerieScoreData(JSON.parse(scoreData));
                }
            }}
        />

        {serieScoreData?.open &&
            <SerieScore
                averageScore={goodAnswers}
                nbTotalQuestions={serieScoreData.nbTotalQuestions}
                quizResults={serieScoreData.quizResults}
                quizUuidFromLeader={serieScoreData.quizUuidFromPlayer}
                redirect={serieScoreData.redirect}
                serieHistoryId={serieScoreData.serieHistoryId}
                usersInRoom={usersInRoom}
            />
        }

        <Grid container spacing={1} justifyContent="center" className={classes.quizContainer} style={{ height:'100%' }}>
            <Grid item xs="auto" sm={10}>

                <div className="multiple-choice-question">
                    {props.id
                        ? <QuestionRequestByQuestionId
                            questionId={Number(props.id)}
                            quizUuidFromPlayer={props.quizUuidFromPlayer}
                            isCurrentInteractiveQuizQuestionValidated={props.isCurrentInteractiveQuizQuestionValidated}
                            interactiveQuizSerieHistoryUpdate={props.interactiveQuizSerieHistoryUpdate}
                            interactiveQuizTotalNbQuestions={props.interactiveQuizTotalNbQuestions}
                        />
                        : <QuestionRequestByQuestionId
                            questionId={Number(props.match?.params.id)}
                        />}
                </div>
            </Grid>
            {props.quizUuidFromPlayer && (
                <Grid item className={classes.leaderSidebar}
                    container direction="column" alignItems="center" alignContent="flex-start"
                    wrap="nowrap"
                    xs="auto" sm={2}
                >
                    <Grid className={classes.title}>
                    Participants
                    </Grid>

                    <Grid item className={classes.playersIcons}
                        container direction={usersInRoom?.length > 12 ? 'row' : 'column' }
                        alignItems="center" alignContent="center" justifyContent="center"
                    >
                        {usersInRoom?.map((user) => (
                            <Grid item key={user.id} className={classes.playerIcon}>
                                <Tooltip title={`${user.firstName} ${user.lastName}`}>
                                    <Badge overlap="rectangular" badgeContent={user.validatedQuestion
                                        ? (
                                            <div className="checkmark">
                                                <CheckCircleIcon style={{ color: 'blue' }} />
                                            </div>
                                        )
                                        : (
                                            <div className="waiting">
                                                <HourglassEmptyIcon color="primary"/>
                                            </div>
                                        )}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}>
                                        <img
                                            className={classes.userIcon}
                                            src={user.imageUrl}
                                            alt={`User ${user.id}`}
                                            data-testid={`test-open-${user.id}`}
                                        />
                                    </Badge>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    </>);
}

QuestionResolver.propTypes = {
    match: PropTypes.object,
    id: PropTypes.number,
    isCurrentInteractiveQuizQuestionValidated: PropTypes.bool,
    quizUuidFromPlayer: PropTypes.string,
    interactiveQuizSerieHistoryUpdate: PropTypes.object,
    interactiveQuizTotalNbQuestions: PropTypes.number,
    usersInRoom: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string
    }))
};

export default (withRouter(QuestionResolver));
