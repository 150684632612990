import {
    ReducerCreators,
    ThunkDispatch,
    UnknownAction,
    asyncThunkCreator,
    buildCreateSlice
} from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { notificationError } from './actions/Notification/notifications.actions';
import { EXPIRED_SESSION } from 'app/Snackbar/NotificationMessages';
import { StateWithLoading } from './redux.types';
import { AsyncThunkSliceReducerConfig } from '@reduxjs/toolkit/dist/createSlice';
import { IfVoid } from '@reduxjs/toolkit/dist/tsHelpers';

export const createAppSlice = buildCreateSlice({
    creators: { asyncThunk: asyncThunkCreator }
});

export interface RequestOptions<Payload = void> {
    payload: IfVoid<Payload, void, Payload>;
}

interface Options<State, Payload = void> extends AsyncThunkSliceReducerConfig<State, Payload> {
    request: (options: Payload) => Promise<AxiosResponse>;
    handleNotification?: (error: AxiosResponse, dispatch: ThunkDispatch<unknown, unknown, UnknownAction>) => void;
    cacheData?: boolean;
}

export const customThunk = <State, Key extends keyof State, Payload = void>(
    create: ReducerCreators<State>,
    statePath: string,
    dataPath: Key,
    options: Options<State, Payload>
) => create.asyncThunk<State, Payload>(async(payload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const store = thunkAPI.getState() as any;
    const state = store[statePath][dataPath] as StateWithLoading<any>;
    if(
        !options.cacheData ||
        (Array.isArray(state.data) ? state.data.length === 0 : !state.data)
    ) {
        try {
            return (await options.request(payload)).data;
        } catch (error: any) {
            const response = (error as AxiosError).response;
            if(response?.status === 401) {
                dispatch(notificationError(EXPIRED_SESSION));
            } else {
                options.handleNotification?.(error, dispatch);
            }
            throw thunkAPI.rejectWithValue(error as AxiosError);
        }
    } else {
        return state.data;
    }
}, {
    pending: (state, action) => {
        (state as Record<Key, StateWithLoading<any>>)[dataPath].loading = true;
        (state as Record<Key, StateWithLoading<any>>)[dataPath].error = null;
        options.pending?.(state, action);
    },
    rejected: (state, action) => {
        (state as Record<Key, StateWithLoading<any>>)[dataPath].loading = false;
        options.rejected?.(state, action);
    },
    fulfilled: (state, action) => {
        (state as Record<Key, StateWithLoading<any>>)[dataPath].loading = false;
        if(options.fulfilled) {
            options.fulfilled(state, action as any);
        } else {
            (state as Record<Key, StateWithLoading<any>>)[dataPath].data = action.payload;
        }
    }
});
