import React, { useState } from 'react';
import { Button, Container, Input, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { patchQuestionOuverteHistoryReviewApi } from 'app/api/historyApi';
import RichTextEditor from 'app/common/richTextEditor/RichTextEditor';
import { useDispatch } from 'react-redux';
import { notificationError, notificationSuccess } from 'app/redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, PATCH_QUESTION_OUVERTE_HISTORY_REVIEW_ERROR, PATCH_QUESTION_OUVERTE_HISTORY_REVIEW_SUCCES } from 'app/Snackbar/NotificationMessages';

const useStyle = makeStyles(() => ({
    questionContainer: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '20px',
        border: '2px solid #ababab',
        minWidth: '360px',
        padding: '20px',
        width: '100%',
        justifyContent: 'space-between'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 8,
        width: '10%'
    }
}));

export default function QuestionOuverteReview(props) {

    const style = useStyle();
    const dispatch = useDispatch();

    const [text, setText] = useState(props.reviewText);
    const [grade, setGrade] = useState(props.reviewGrade);

    const handleClick = () => {
        patchQuestionOuverteHistoryReviewApi({
            questionHistoryId: props.questionHistoryId,
            reviewText: text,
            reviewGrade: grade
        })
            .then(() => {
                props.refreshEntretiens();
                dispatch(notificationSuccess(PATCH_QUESTION_OUVERTE_HISTORY_REVIEW_SUCCES));
            })
            .catch((error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(PATCH_QUESTION_OUVERTE_HISTORY_REVIEW_ERROR));
                }
            });
    };

    const handleGrade = event => {
        const result = event.target.value.replace(/\D/gu, '');
        if(result > 100) {
            setGrade(100);
        } else {
            setGrade(result);
        }
    };

    return <Container className={style.questionContainer} label={`History#${props.questionHistoryId}`}>
        <RichTextEditor
            styles={{
                width: 'inherit'
            }}
            data={text}
            onChange={(_event, editor) => setText(editor.getData())} />
        <div className={style.buttons}>
            <Input
                type="text"
                placeholder="La note"
                value={grade ?? ''}
                onChange={handleGrade}
            />
            <Button
                type="button"
                onClick={handleClick}
                color="primary"
                variant="contained"
            >
                Valider
            </Button>
        </div>
    </Container>;
}

QuestionOuverteReview.propTypes = {
    questionHistoryId: PropTypes.number.isRequired,
    reviewText: PropTypes.string,
    reviewGrade: PropTypes.number,
    refreshEntretiens: PropTypes.func
};
