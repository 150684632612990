import React from 'react';
import './ChapterMobile.scss';
import PropTypes from 'prop-types';

import SkillHeader from '../../SkillHeader';

import { Grid } from '@material-ui/core';

function ChapterMobile(props) {

    return (
        <Grid container>
            <Grid 
                item
                xs={12}
                sm={12}
            >
                <div className="skill-header-mobile">
                    <SkillHeader
                        skill={props.skill}
                        isModerator={props.isModerator}
                    />
                </div>
                {props.chapterHeader}
                <div className="chapter-tree-mobile">
                    {props.chapterTreeDisplay}
                </div>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
            >
                {
                    (props.displayGroupTab && props.groupSelected)
                        ? <>
                            {props.chapterGroupStats}
                        </>
                        : <>
                            {props.chapterContent}
                        </>
                }
            </Grid>
        </Grid>
    );

}

ChapterMobile.propTypes = {
    chapterContent: PropTypes.element,
    chapterGroupStats: PropTypes.element,
    chapterHeader: PropTypes.element,
    chapterTreeDisplay: PropTypes.element,
    displayGroupTab: PropTypes.bool,
    groupSelected: PropTypes.object,
    isModerator: PropTypes.bool,
    skill: PropTypes.object
};

export default ChapterMobile;
