import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { setCategoryFilter, setFamilyFilter, setSearchFilter } from '../../redux/actions/Filter/filter.actions';
import { projectsActions } from 'app/redux/slices/projects.slice';
import { resetCurrentUser } from '../../redux/actions/User/user.actions';
import { clearAllNotifications } from '../../redux/actions/Notification/notifications.actions';
import { useDispatch } from 'react-redux';
import './MenuLargeScreen.scss';
import {
    setExcilyensSearchFilter,
    setExcilyensSkillsFilter,
    setPromoFilter
} from '../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import { skillActions } from 'app/redux/slices/skills.slice';

const ProfileLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} to="/users" {...props}/>);

export default function MenuLargeScreen() {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const resetSearchAndFilters = () => {
        dispatch(setSearchFilter(''));
        dispatch(setCategoryFilter([]));
        dispatch(setFamilyFilter([]));
        dispatch(setPromoFilter([]));
        dispatch(setExcilyensSearchFilter(''));
        dispatch(setExcilyensSkillsFilter([]));
    };

    const clearStoreData = () => {
        dispatch(setSearchFilter(''));
        dispatch(setCategoryFilter([]));
        dispatch(setFamilyFilter([]));
        dispatch(setPromoFilter([]));
        dispatch(setExcilyensSearchFilter(''));
        dispatch(setExcilyensSkillsFilter([]));
        dispatch(skillActions.reset());
        dispatch(resetCurrentUser());
        dispatch(projectsActions.setCurrentProject(null));
        dispatch(clearAllNotifications());
    };

    const [anchor, setAnchor] = useState(null);

    const disconnect = () => {
        AuthenticationContainer.disconnect();
        history.push('/');
        clearStoreData();
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const handleOpen = (event) => {
        setAnchor(event.currentTarget);
    };

    const goTo = () => {
        history.push(location.pathname === '/chat' ? '/skills' : '/chat');
    };

    return (
        <>
            <img src={AuthenticationContainer.getProfilePicture()} alt=""
                className="avatar" onClick={handleOpen}
                data-testid="test-open"
            />
            <Menu className="menu"
                open={anchor !== null}
                anchorEl={anchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onClose={handleClose}
            >
                <MenuItem className="nav-link"
                    component={ProfileLink}
                    onClick={resetSearchAndFilters}
                    data-testid="test-reset"
                    style={{ color: 'black' }}
                >
                    Profil
                </MenuItem>
                <MenuItem className="nav-link"
                    onClick={goTo}
                >
                    {location.pathname === '/chat' ? 'MyNeuroFactory' : 'Chat'}
                </MenuItem>
                <MenuItem className="nav-link"
                    to="#"
                    onClick={disconnect}
                    data-testid="test-disconnect"
                >
                    Déconnexion
                </MenuItem>
            </Menu>
        </>

    );
}
