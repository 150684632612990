
export const texteATrouPattern = {
	name: 'texteatrou',
	attributes: [ 'acceptedAnswers' ]
};

export default class CustomModelMatcher {
	constructor( ...pattern ) {
		this._patterns = [];

		this.add( ...pattern );
	}

	add( ...pattern ) {
		for ( const item of pattern ) {
			this._patterns.push( item );
		}
	}
	match( ...element ) {
		const results = [];
		for ( const singleElement of element ) {
			this.matchSingleElement( singleElement, results );
		}

		return results;
	}
	matchSingleElement( singleElement, results ) {
		for ( const pattern of this._patterns ) {
			const match = isElementMatching( singleElement, pattern );
			if ( match ) {
				results.push( {
					element: singleElement,
					pattern,
					match
				} );
			}
		}
		if ( singleElement.getChildren ) {
			for ( const child of singleElement.getChildren() ) {
				this.matchSingleElement( child, results );
			}
		}
	}
}

function isElementMatching( element, pattern ) {
	const match = {};
	// Check element's name.
	if ( pattern.name ) {
		match.name = ( pattern.name === element.name );

		if ( !match.name ) {
			return null;
		}
	}

	// Check element's attributes.
	if ( pattern.attributes ) {
		match.attributes = matchAttributes( pattern.attributes, element );

		if ( !match.attributes ) {
			return null;
		}
	}

	return match;
}

function matchAttributes( patternAttributes, element ) {
	const attributeKeys = new Set( element.getAttributeKeys() );
	const match = [];

	patternAttributes.forEach( patternAttribute => {
		attributeKeys.forEach( elementAttribute => {
			if ( patternAttribute === elementAttribute ) {
				match.push( patternAttribute );
			}
		} );
	} );

	return match.length > 0 ? match : null;
}
