import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import SerieDisplayDraggable from './SerieDisplayDraggable';

export default function SerieListDisplay(props) {
    return (
        props.data && props.data.length > 0
            ? <List dense>
                {props.data.map((serie) => (
                    <SerieDisplayDraggable
                        node={props.node}
                        key={serie.id}
                        serie={serie}
                        chapterId={props.chapterId}
                        onClickQuestion={props.onClickQuestion}
                        selectedQuestion={props.selectedQuestion}
                    />
                ))}
            </List>
            : <span>
                Aucune série liée à ce chapitre
            </span>
    );
}

SerieListDisplay.propTypes = {
    node: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    chapterId: PropTypes.number.isRequired,
    onClickQuestion: PropTypes.func.isRequired,
    selectedQuestion: PropTypes.number
};
