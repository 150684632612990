import React from 'react';
import {
    makeStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
}));

function DeleteQuestionConfirmationDialog(props) {
    const classes = useStyles();

    return (
        <div data-testid="delete-question-confimation-dialog">
            <Dialog
                open = {props.open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <span className="dialog-title">
                        Supprimer la question
                    </span>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    Vous êtes sur le point de supprimer cette question.
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={props.handleDeleteQuestionCancelClick}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={props.handleDeleteQuestionConfirmClick}
                    >
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

DeleteQuestionConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleDeleteQuestionCancelClick: PropTypes.func.isRequired,
    handleDeleteQuestionConfirmClick: PropTypes.func.isRequired,
};

export default DeleteQuestionConfirmationDialog;