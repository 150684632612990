import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CoachingGroup.scss';

import { deleteGroupById, getAllCoachesOfGroupByGroupId, patchArchivedGroupByCoachId } from 'app/redux/actions/Group/group.actions';

import { Avatar, IconButton, Grid, ListItem, ListItemAvatar, ListItemText, Paper, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { postCoachInGroup, deleteCoachInGroupById } from 'app/redux/actions/Group/RelationGroup/groupRelation.actions';
import { isMainCoachApi } from 'app/api/groupApi';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { selectGroup, selectCoaches } from 'app/redux/selectors/Group/group.selector';

import AddToGroupPanel from 'app/coaching/AddToGroupPanel/AddToGroupPanel';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { coachEnum } from 'app/utils/CoachEnum';
import LayoutModal from 'app/layout/LayoutModal';

export default function CoachingGroupHeader() {

    const dispatch = useDispatch();

    const group = useSelector(selectGroup);
    const coaches = useSelector(selectCoaches);

    const user = useSelector(selectCurrentUser);
    const [isMainCoach, setIsMainCoach] = useState(false);
    const [isDialogArchivedOpen, setIsDialogArchivedOpen] = useState(false);
    const [isDialogDeletedOpen, setIsDialogDeletedOpen] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const closeDialog = () => {
        setModalOpened(false);
        dispatch(getAllCoachesOfGroupByGroupId(group.id));
    };

    const openDialog = () => {
        setModalOpened(true);
    };

    function deleteCoachInGroup(coachId) {
        dispatch(deleteCoachInGroupById(group.id, coachId));
    }

    useEffect(() => {
        dispatch(getAllCoachesOfGroupByGroupId(group.id));

        isMainCoachApi(group.id, user.id).then((response) => {
            setIsMainCoach(response.data === coachEnum.SUPER_COACH);
        });
    }, [group]);

    const handleConfirmArchive = () => {
        dispatch(patchArchivedGroupByCoachId(group.id, user.id));
        setIsDialogArchivedOpen(false);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteGroupById(group.id));
        setIsDialogDeletedOpen(false);
    };

    return (
        <Paper>
            <div className="groupContainer">
                <h2 className="groupSpacing">{group.name}</h2>
                <div className="buttonContainer">
                    {isMainCoach &&
                    <>
                        <IconButton className="archiveButton" onClick={() => setIsDialogArchivedOpen(true)}>
                            <ArchiveIcon color="primary" />
                        </IconButton>
                        <IconButton className="archiveButton" onClick={() => setIsDialogDeletedOpen(true)}>
                            <DeleteIcon color="primary"/>
                        </IconButton>
                    </>
                    }
                </div>
                <Dialog open={isDialogArchivedOpen} onClose={() => setIsDialogArchivedOpen(false)}>
                    <DialogTitle>Archiver</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Voulez-vous vraiment archiver le groupe {group.name} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogArchivedOpen(false)} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={handleConfirmArchive} color="primary" autoFocus>
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={isDialogDeletedOpen} onClose={() => setIsDialogDeletedOpen(false)}>
                    <DialogTitle>Supprimer</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Voulez-vous vraiment supprimer le groupe {group.name} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogDeletedOpen(false)} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Grid container>
                <Grid item>
                    <div className="coachLabel">
                        <h2 className="groupSpacing"> Coachs : </h2>
                        {isMainCoach &&
                            <Tooltip title="Ajouter un coach">
                                <IconButton>
                                    <AddIcon onClick={openDialog}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </Grid>
                {coaches && coaches.map((coach) => <Grid item key={coach.id}>
                    <ListItem>
                        <ListItemAvatar >
                            <Avatar src={`${coach.imageUrl}`}/>
                        </ListItemAvatar>
                        <ListItemText primary={`${coach.firstName} ${coach.lastName}`} secondary={`${coach.promo}`}/>
                        { (isMainCoach && coach.id !== user.id) &&
                            <IconButton>
                                <RemoveCircleOutlineOutlinedIcon style={{ color: '#FF0000' }} onClick= {() => deleteCoachInGroup(coach.id)} />
                            </IconButton>
                        }
                    </ListItem>
                </Grid>)}
            </Grid>
            <LayoutModal opened={modalOpened} closeAction={closeDialog}>
                <AddToGroupPanel
                    addToGroup="Ajouter en coach"
                    setTrigger = {closeDialog}
                    previouslyAdded={coaches}
                    dispatchPersonGroup={ postCoachInGroup }
                />
            </LayoutModal>
        </Paper>
    );
}
