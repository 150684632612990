
import PropTypes from 'prop-types';
import './ClientDetailsContent.scss';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { MuiThemeProvider, Accordion, AccordionSummary, Typography, AccordionDetails, Hidden } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import RoomIcon from '@material-ui/icons/Room';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { selectCurrentUser } from '../../../../../redux/selectors/User/user.selector';
import { setCurrentClient } from '../../../../../redux/actions/Client/clients.actions';
import ProjectsTable from '../../../Project/ProjectTable/ProjectsTable';
import ExcilyenCard from '../../../../../common/excilyenCard/ExcilyenCard';
import { collapsibleTheme } from './ClientDetailsContentTheme';
import { selectAllProjects } from '../../../../../redux/selectors/Project/projects.selector';
import AddEditClient from '../../AddEditClient/AddEditClient';
import building from 'assets/images/building.png';
import { projectsActions } from 'app/redux/slices/projects.slice';

export class ClientDetailsContent extends Component {

    state = {
        projectsDisplayed: localStorage.displayProjectsSection === undefined ? true : localStorage.displayProjectsSection === 'true',
        excilyensDisplayed: localStorage.displayExcilyensSection === undefined ? true : localStorage.displayExcilyensSection === 'true'
    };

    onChangeProjectDisplayed = (state) => {
        const newState = !state;
        localStorage.setItem('displayProjectsSection', newState);
        this.setState({
            projectsDisplayed: newState
        });
    };

    onChangeExcilyenDisplayed = (state) => {
        const newState = !state;
        localStorage.setItem('displayExcilyensSection', newState);
        this.setState({
            excilyensDisplayed: newState
        });
    };

    componentDidMount() {
        this.props.getAllProjects();
        this.props.setCurrentClient(this.props.client);
    }

    getAllExcilyenOfClient = (listProjects) => {
        const allMissionsOfClient = listProjects.flatMap((project) => project.listMission);
        const allExcilyens = allMissionsOfClient.flatMap((mission) => mission.listUser);
        const allExcilyensId = allExcilyens.map((excilyen) => excilyen.id);
        return allExcilyens.filter((excilyen, index) => allExcilyensId.indexOf(excilyen.id) === index);
    };

    render() {
        const { currentUser, client } = this.props;
        const projectsFilteredByClient = this.props.allProjects.data.filter((project) => project.client.id === client.id);
        const allExcilyenOfClient = this.getAllExcilyenOfClient(projectsFilteredByClient);
        const excilyensCards = allExcilyenOfClient
            .sort((excilyen1, excilyen2) => excilyen1.firstName
                .localeCompare(excilyen2.firstName) || excilyen1.lastName
                .localeCompare(excilyen2.lastName))
            .map((excilyen, index) => <Grid key={index} item xs={6} sm={3} md={3} lg={2} xl={2}><ExcilyenCard
                excilyen={excilyen} key={index}/></Grid>);

        return (
            <div className="client-details-content">
                <Paper className="paper-info-client">
                    {currentUser.roles &&
                    currentUser.roles.some((role) => role.name === 'ADMIN')
                        ? <AddEditClient trueIfAddAndFalseIfEdit={false} clientToEdit={client}/>
                        : null}

                    <Hidden only={[
                        'xs',
                        'sm'
                    ]}>
                        <Grid container direction="row">
                            <img className="resize" alt={client.name}
                                src={client.imageUrl
                                    ? client.imageUrl
                                    : building}/>

                            <Grid item xs container direction="column" spacing={1} className="img-title-buttons">
                                <Grid item className="header">
                                    <h1 className="title-client-desktop">
                                        {client.name}
                                    </h1>
                                </Grid>
                                <Grid item container direction="row">
                                    {client.address &&
                                    <a href={`https://www.google.com/maps/search/?api=1&query=${client.address}`}
                                        target="_blank" rel="noopener noreferrer">
                                        <div className="client-chip" size="small" dangerouslySetInnerHTML = {{ __html: client.address }} />
                                    </a>
                                    }
                                    {client.address &&
                                    <a href={`https://www.google.com/maps/search/?api=1&query=${client.address}`}
                                        target="_blank" rel="noopener noreferrer">
                                        <RoomIcon className="icon-room" fontSize="inherit" titleAccess={client.address} />
                                    </a>
                                    }
                                </Grid>

                                <Grid>
                                    <Grid item container direction="row">
                                        <div className="description-client" dangerouslySetInnerHTML = {{ __html: client.description }}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Paper>
                <div className="div-accordion">
                    <MuiThemeProvider theme={collapsibleTheme}>
                        <Accordion className="not-expanded" expanded={this.state.projectsDisplayed}
                            onChange={() => this.onChangeProjectDisplayed(
                                this.state.projectsDisplayed,
                                'displayProjectsSection'
                            ) }>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid item container direction="row">
                                    <Grid item className="section">
                                        <Icon className="icon">supervised_user_circle</Icon>
                                        <Typography className="label">Projets</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProjectsTable projectsFromClientDetails={projectsFilteredByClient}/>
                            </AccordionDetails>
                        </Accordion>
                    </MuiThemeProvider>
                </div>
                <div className="div-accordion">
                    <MuiThemeProvider theme={collapsibleTheme}>
                        <Accordion className="not-expanded"
                            expanded={this.state.excilyensDisplayed}
                            onChange={() => this.onChangeExcilyenDisplayed(
                                this.state.excilyensDisplayed,
                                'displayExcilyensSection'
                            )}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panella-content"
                                id="panella-header"
                            >
                                <Grid item container direction="row">
                                    <Grid item className="section">
                                        <Icon className="icon">supervised_user_circle</Icon>
                                        <Typography className="label">Excilyens</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" alignItems="flex-start" className="home-excilyen-cards" item
                                    xs={12} sm={12} lg={12}>
                                    {
                                        allExcilyenOfClient.length <= 0
                                            ? 'Aucun excilyen n\'a encore travaillé avec ce client'
                                            : excilyensCards
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }

}

ClientDetailsContent.propTypes = {
    client: PropTypes.object,
    currentUser: PropTypes.object,
    setCurrentClient: PropTypes.func,
    getAllProjects: PropTypes.func,
    allProjects: PropTypes.array,
    width: PropTypes.string
};

function mapStateToProps(state) {
    return {
        allProjects: selectAllProjects(state),
        currentUser: selectCurrentUser(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setCurrentClient: (client) => dispatch(setCurrentClient(client)),
        getAllProjects: () => dispatch(projectsActions.getAllProjects())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ClientDetailsContent));
