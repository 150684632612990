import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import HistoryTable from './historyTable/HistoryTable';
import { HistoryTypeEnum } from './HistoryTypeEnum';

export function BothHistoriesTables(props) {  

    const historyData =  [{ history : props.questionOrSerieHistoryData, page : props.questionPageData, handleChangePage: props.handleChangeQuestionPage, handleRowsPerPage : props.handleRowsPerQuestionPage, 
        historyType : HistoryTypeEnum.QUESTION_OR_SERIE  } , 
    { history : props.parcoursHistoryData, page : props.parcoursPageData, handleChangePage: props.handleChangeParcoursPage, handleRowsPerPage : props.handleRowsPerParcoursPage,
        historyType : HistoryTypeEnum.PARCOURS }];

    return (
        <>

            {historyData.map((data, index) => (

                <Grid item className="desktop-side-panel-history" key={index}>
                    {data.history && data.history.listOfElements &&
                <div>
                    <HistoryTable
                        className="historyTable"
                        data={data.history.listOfElements}
                        pageData={data.page}
                        groupName={props.groupName}
                        handleChangePage={data.handleChangePage}
                        handleRowsPerPage={data.handleRowsPerPage}
                        studentId={props.studentId}
                        userId = {props.userId}
                        historyType={data.historyType}                
                    />
                    <br/>
                </div>}
                    
                </Grid>
                
            ))}

        </>
    );  
}

BothHistoriesTables.propTypes = {
    questionOrSerieHistoryData: PropTypes.object,
    parcoursHistoryData: PropTypes.object,
    questionPageData: PropTypes.object.isRequired,
    parcoursPageData: PropTypes.object.isRequired,
    groupName: PropTypes.string.isRequired,
    handleChangeQuestionPage : PropTypes.func.isRequired,
    handleChangeParcoursPage : PropTypes.func.isRequired,
    handleRowsPerQuestionPage : PropTypes.func.isRequired,
    handleRowsPerParcoursPage : PropTypes.func.isRequired,
    studentId: PropTypes.number,
    userId: PropTypes.number.isRequired
};

export default BothHistoriesTables;
