/* eslint-disable max-lines */
/* eslint-disable no-lonely-if */
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import '../Message.scss';
import oxa from 'assets/images/Logochatbot.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getNextParcoursHistoryByUserId } from 'app/redux/actions/Parcours/parcours.actions';
import { addMessageToChat, addRemediationChoiceToChat, storeMicroskill } from 'app/redux/actions/Chat/chat.actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { selectParcours } from '../../../../redux/selectors/Parcours/parcours.selector';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import hljs from 'highlight.js';
import 'highlight.js/styles/dark.css';
import { localLlmServiceApi } from '../../../../api/localLlmApi';
import { notificationError } from '../../../../redux/actions/Notification/notifications.actions';
import { EXPIRED_SESSION, GET_TRADUCTION_REQUEST_ERROR } from '../../../../Snackbar/NotificationMessages';
import QuestionDisplay from './QuestionDisplay';
import { putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi } from 'app/api/multipleChoiceQuestionApi';
import { selectCurrentUser } from 'app/redux/selectors/User/user.selector';
import { getCurrentUser } from 'app/redux/actions/User/user.actions';
import { postQuestionReporting } from 'app/redux/actions/Question/QuestionReporting/questionReporting.actions';
import { getMicroskillChildrenNamesByMicroskillId, getMicroskillsIdsByQuestionId } from 'app/api/microskillApi';
import CourseUnderStandardQuestion from './CourseUnderStandardQuestion';
import CourseUnderRemediationQuestion from './CourseUnderRemediationQuestion';
import { OXA } from 'app/redux/reducers/Chat/chat.reducer';

export const MessageQuestion = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    const [questionMicroskills, setQuestionMicroskills] = useState(null);

    const answerRef = useRef();
    const parcoursId = useSelector(selectParcours);
    const currentUser = useSelector(selectCurrentUser);

    const [translatedStatement, setTranslatedStatement] = useState('');
    const [translatedAnswers, setTranslatedAnswers] = useState(new Map());
    const [isLoading, setIsLoading] = useState(false);
    const [isTranslationMode, setIsTranslationMode] = useState(false);

    const [answers, setAnswers] = useState(new Map());
    const [borderStatus, setBorderStatus] = useState('');

    const [isReported, setIsReported] = useState(false);

    const [isCheckButtonVisible, setIsCheckButtonVisible] = useState(true);
    const [remediationChoosing, setRemediationChoosing] = useState(false);

    const [microskillsChildren, setMicroskillsChildren] = useState();

    const questionReporting = {
        description: '',
        questionId: -1,
        reporterId: -1,
        status: ''
    };

    const handleReportAI = () => {
        if(isReported) {
            return;
        }

        if(currentUser.id === null || currentUser.id === undefined) {
            dispatch(getCurrentUser());
        }

        questionReporting.description = 'Erreur dans la question.';
        questionReporting.questionId = props.message.content.id;
        questionReporting.reporterId = currentUser.id;
        questionReporting.status = 'PENDING';

        dispatch(postQuestionReporting(questionReporting));
    };

    const toggleReportAI = () => {
        setIsReported(true);
        handleReportAI();
    };

    const getCurrentUserId = () => Number(AuthenticationContainer.getCurrentUserId());

    function changeTrad() {
        setIsTranslationMode(!isTranslationMode);
    }

    const setMicroskillsChilds = () => {

        getMicroskillsIdsByQuestionId(props.questionId).then( async(responseIds) => {
            
            const copyMicroskillsChildren = [];

            await getMicroskillChildrenNamesByMicroskillId(responseIds.data[0]).then( (response) => {

                const mapMicroskillsChildren = new Map(Object.entries(response.data));

                if(mapMicroskillsChildren.size > 0){
                    for(const [id, name] of mapMicroskillsChildren ) {
                        dispatch(storeMicroskill( { name, id: parseInt(id, 10) } ));
                        copyMicroskillsChildren.push({ id: parseInt(id, 10), name });
                    }
                }
                setMicroskillsChildren(copyMicroskillsChildren);
                
            });

        });};
    
            
  

    function sendResponse(elem) {

        setIsCheckButtonVisible(false);

        elem.target.remove();
        const mcq = props.message.content;
        const selectedAnswer = Array.from(answers.values()).filter((answer) => answer.statusSelected === 'selected');
        const parcoursStepContentId = props.message.stepContentId;
        const answersSelected = mcq.answers.map((answer) => {
            if(selectedAnswer.map((answer1) => answer1.id).includes(answer.id)) {
                return {
                    id: answer.id,
                    selected: true
                };
            }

            return {
                id: answer.id,
                selected: false
            };
        });

        const mcqWithAnswers = {
            'id': props.message.content.id,
            'class': 'MultipleChoiceQuestionAnswerSelectedApiDto',
            'userId': getCurrentUserId(),
            answersSelected,
            'taskId': 0,
            'isInteractiveQuizLeader': false,
            parcoursStepContentId
        };

        putAnswerRetrieveMultipleChoiceQuestionByIdWithExplanationApi(mcqWithAnswers).then((response) => {
            let content = '';
            if(selectedAnswer.length > 0) {
                if(selectedAnswer.length === 1) {
                    content = 'J\'ai choisi la réponse suivante: ';
                } else {
                    content = 'J\'ai choisi les réponses suivantes: ';
                }
                selectedAnswer.forEach((answer) => {
                    content = `${content} " ${answer.text} "`;
                });
            } else {
                content = 'Je n\'ai pas choisi de réponse';
            }

            answerRef.current?.querySelectorAll('.answers-oxa').forEach((answer) => {
                answer.setAttribute('disabled', true);
            });
            dispatch(addMessageToChat({ sender: 'user', content }));
            if(props.isExamMode) {
                dispatch(getNextParcoursHistoryByUserId(parcoursId));
            } else {
                dispatch(addMessageToChat({ sender: 'oxa', content: response.data.explanation ? response.data.explanation : 'Aucune explication disponible pour le moment.' }));
                let userGoodAnswer = true;
                let answerNotMissing = true;
                response.data.answers.forEach((answer) => {
                    setAnswers((prevAnswers) => {
                        const updatedAnswers = new Map(prevAnswers);
                        const answerTemp = updatedAnswers.get(answer.id);
                        answerTemp.label = answer.label;
                        if(answer.validAnswer) {
                            if(answers.get(answer.id)?.statusSelected === 'not-selected') {
                                answerNotMissing = false;
                            }
                            answerTemp.statusAnswer = 'good-answer';
                        } else if(answers.get(answer.id)?.statusSelected === 'selected') {
                            userGoodAnswer &&= false;
                            answerTemp.statusAnswer = 'bad-answer';
                        }
                        return updatedAnswers;
                    });
                    if(translatedAnswers.size > 0) {
                        setTranslatedAnswers((prevAnswers) => {
                            const updatedAnswers = new Map(prevAnswers);
                            const answerTemp = updatedAnswers.get(answer.id);
                            answerTemp.label = answer.label;
                            if(answer.validAnswer) {
                                if(translatedAnswers.get(answer.id)?.statusSelected === 'not-selected') {
                                    answerNotMissing = false;
                                }
                                answerTemp.statusAnswer = 'good-answer';
                            } else if(translatedAnswers.get(answer.id)?.statusSelected === 'selected') {
                                userGoodAnswer &&= false;
                                answerTemp.statusAnswer = 'bad-answer';
                            }
                            return updatedAnswers;
                        });
                    }
                });

                if(userGoodAnswer && answerNotMissing) {
                    setBorderStatus('good-answer');
                    if(props.message.content.morningQuestion) {
                        props.nextMorningQuestion();
                    } else {
                        dispatch(getNextParcoursHistoryByUserId(parcoursId));
                    }
                } else {
                    setBorderStatus('bad-answer');
                    if(props.message.content.morningQuestion) {
                        props.nextMorningQuestion();
                    } else {

                        setRemediationChoosing(true);

                        if(props.isRemediation) {
                            setMicroskillsChilds();
                        } else {
                            // eslint-disable-next-line max-depth
                            if(!questionMicroskills) {               
                                props.setCoursesNames(props.questionId, setQuestionMicroskills);
                            }
                        }
                      
                    }
                         
                }
            }
        }
        );};
    

    useEffect( () => {

        if(remediationChoosing && questionMicroskills && !props.isRemediation){
            if(questionMicroskills?.length > 0){
                dispatch(addRemediationChoiceToChat(questionMicroskills));
            } else {
                dispatch(addMessageToChat({ sender: OXA,
                    content: 'Pas de remédiation disponible pour cette question' } ));
                dispatch(getNextParcoursHistoryByUserId(parcoursId));
            }
            setRemediationChoosing(false);
        }
    }, [questionMicroskills,remediationChoosing]);


    useEffect( () => {
        if(remediationChoosing && microskillsChildren && props.isRemediation) {
            if(microskillsChildren?.length > 0) {
                dispatch(addRemediationChoiceToChat(microskillsChildren));
            } else {
                dispatch(addMessageToChat({ sender: OXA,
                    content: 'Pas de remédiation disponible pour cette question' } ));
                dispatch(getNextParcoursHistoryByUserId(parcoursId));
            }
            setRemediationChoosing(false);
        }
        
    }, [microskillsChildren,remediationChoosing]);

    useEffect(() => {
        if(translatedAnswers.size > 1) {
            const oldAnswers = answers;
            setAnswers(new Map(Array.from(translatedAnswers.values()).map((answer) => [answer.id, { ...answer, text: oldAnswers.get(answer.id).text }])));
        } else {
            setAnswers(new Map(props.message.content.answers.map((answer) => [answer.id, { ...answer, statusSelected: 'not-selected', statusAnswer: '' }])));
        }
    }, [props.message.content, translatedAnswers]);

    useEffect(() => {
        hljs.highlightAll();
    }, []);

    useEffect(() => {
        hljs.configure({ ignoreUnescapedHTML: true });
        document.querySelectorAll('pre code').forEach((elt) => {
            if(!elt.getElementsByTagName('input').length) {
                hljs.highlightElement(elt);
            }
        });
    }, [translatedStatement]);

    useEffect(() => {
        if(!isTranslationMode || translatedStatement) {
            hljs.configure({ ignoreUnescapedHTML: true });
            document.querySelectorAll('pre code').forEach((elt) => {
                if(!elt.getElementsByTagName('input').length) {
                    hljs.highlightElement(elt);
                }
            });
            return;
        }
        setIsLoading(true);
        const statement = props.message.content.statement;
        const answersText = props.message.content.answers.map((answer) => answer.text).join('`');
        const mesConcat = `\`${statement}\`${answersText}\``;

        localLlmServiceApi.translate(props.message.content.id, mesConcat)
            .then((response) => {
                const translatedData = response.data;
                if(translatedData && typeof translatedData === 'string') {
                    const translatedParts = translatedData.split('`').slice(1)
                        .map((data) => data.trim())
                        .filter((answerText) => answerText !== '')
                        .slice(0, props.message.content.answers.length + 1);
                    if(translatedParts.length > 1) {
                        const statementTranslated = translatedParts.shift().replaceAll('\\n', '<br/>');
                        const answersTranslated = translatedParts
                            .map((translatedText, index) => {
                                const label = props.message.content.answers[index].label;
                                const id = props.message.content.answers[index].id;
                                return {
                                    text: translatedText,
                                    label,
                                    id
                                };
                            });
                        setTranslatedStatement(statementTranslated);
                        const translatedAnswersMap = new Map();
                        Array.from(answers.values()).forEach((answer) => {
                            const translatedText = answersTranslated.find((ans) => ans.id === answer.id).text;
                            const updatedAnswer = { ...answer, text: translatedText };
                            translatedAnswersMap.set(answer.id, updatedAnswer);
                        });
                        setTranslatedAnswers(translatedAnswersMap);
                    }
                }
            })
            .catch((error) => {
                if(error.response && error.response.status === 401) {
                    dispatch(notificationError(EXPIRED_SESSION));
                } else {
                    dispatch(notificationError(GET_TRADUCTION_REQUEST_ERROR));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isTranslationMode]);

    return (
        <div className={`message oxa ${borderStatus}`} ref={ref}>
            <img id="avatar" src={oxa}/>
            <div className="header-chat-bot"/>
            { props.message.content.questionRemediationStatus &&
                <Alert severity="info" className="remediation">
                    <AlertTitle>
                        {props.message.content.questionRemediationStatus.questionsRemaining === 0
                            ? 'Dernière question de remédiation'
                            : `${props.message.content.questionRemediationStatus.questionsRemaining} question${props.message.content.questionRemediationStatus.questionsRemaining > 1 ? 's' : ''} 
                                de remédiation restante${props.message.content.questionRemediationStatus.questionsRemaining > 1 ? 's' : ''}`}
                    </AlertTitle>
                    <div>Ceci est une question de remédiation.
                        En cas de réponse incorrecte, je sélectionnerai des questions supplémentaires pour renforcer votre compréhension avant de passer à l&lsquo;étape suivante.
                    </div>
                </Alert>
            }
            { props.message.content.morningQuestion &&
                <Alert severity="info" className="remediation">
                    <div>Ceci est une question du matin.
                        En cas de réponse incorrecte, je sélectionnerai des questions supplémentaires pour renforcer votre compréhension avant de passer à l&lsquo;étape suivante.
                    </div>
                </Alert>
            }
            <QuestionDisplay
                statement={isTranslationMode && translatedStatement ? translatedStatement : props.message.content.statement}
                questionId={props.message.content.id}
                onToggleTranslation={changeTrad}
                isLoading={isLoading}
                answerRef={answerRef}
                answers={isTranslationMode && translatedAnswers.size > 0 ? translatedAnswers : answers}
                setAnswers={isTranslationMode && translatedAnswers.size > 0 ? setTranslatedAnswers : setAnswers}
                class="MultipleChoiceQuestion"
                aiGenerated={props.message.content.aiGenerated}
                isReported={isReported}
                handleReportAI={toggleReportAI}
            />
            <div className="validation-button">
                {isCheckButtonVisible && (
                    <div>
                        <IconButton
                            style={{
                                bottom: '0',
                                right: '0',
                                position: 'absolute',
                                color: '#0076ba',
                                transform: 'translate(50%, 50%)'
                            }}
                            onClick={sendResponse}>
                            <CheckCircleIcon style={{ fontSize: '2.3rem' }}/>
                        </IconButton>
                    </div>
                )}
            </div>
            {!props.isExamMode && (props.isRemediation ? 

                <CourseUnderRemediationQuestion 
                    questionId={props.questionId}
                /> :
                <CourseUnderStandardQuestion  
                    className="course-under-question"
                    questionId={props.questionId}
                    questionMicroskills = {questionMicroskills}
                    setQuestionMicroskills = {setQuestionMicroskills}
                    displayCoursesNames = {props.setCoursesNames}
                />) }

        </div>
    );
});

MessageQuestion.propTypes = {
    message: PropTypes.object,
    setRemediationOnGoing: PropTypes.func,
    questionId: PropTypes.number,
    isExamMode: PropTypes.bool,
    nextMorningQuestion: PropTypes.func,
    isRemediation: PropTypes.bool,
    setCoursesNames: PropTypes.func
};

export default MessageQuestion;
