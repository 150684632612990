import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button as ButtonMaterial } from '@material-ui/core';

export default function ChapterCoachProfileDialog(props) {

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();

    const [profileName, setProfileName] = useState('');
    const [enableError, setEnableError] = useState(false);


    const isValidProfileName = useMemo(
        () => !props.profileList.some((element) => element.name === profileName) && profileName.trim() !== ''
        , [props.profileList, profileName]
    );

    const handleOnClick = () => {
        setEnableError(true);
        if(isValidProfileName) {
            const newProfile = {
                name: profileName.trim(),
                coachId: getCurrentUserId(),
                skillId: props.skillId,
                chapters: props.chapters
            };
            props.addNewProfile(newProfile);
            props.onClose();
        }
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
        >
            <DialogTitle>
                Créer un profil
            </DialogTitle>
            <DialogContent dividers id = "addToProfileDialog">
                <TextField
                    label="Nom du profil"
                    autoFocus
                    fullWidth
                    error={ enableError && !isValidProfileName }
                    helperText={
                        enableError ? isValidProfileName ? ' ' : 'Nom de profil invalide' : ' '
                    }
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) => setProfileName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <ButtonMaterial
                    color="primary"
                    onClick={props.onClose}
                >
                    Annuler
                </ButtonMaterial>
                <ButtonMaterial
                    color="primary"
                    onClick={handleOnClick}
                >
                    Valider
                </ButtonMaterial>
            </DialogActions>
        </Dialog>
    );
}

ChapterCoachProfileDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    profileList: PropTypes.array.isRequired,
    chapters: PropTypes.array.isRequired,
    skillId: PropTypes.number.isRequired,
    addNewProfile: PropTypes.func.isRequired
};
