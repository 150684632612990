import { PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../models/Project.model';
import { getAllProjectsApi, getProjectByIdApi } from 'app/api/projectsApi';
import { StateWithLoading } from '../redux.types';
import { notificationError } from '../actions/Notification/notifications.actions';
import { RETRIEVE_PROJECTS_ERROR, RETRIEVE_PROJECT_ERROR, RETRIEVE_PROJECT_NOT_EXIST } from 'app/Snackbar/NotificationMessages';
import { createAppSlice, customThunk } from '../redux.helper';

export const projectsStore = 'projects';
export const allProjectsStore = 'allProjects';
export const currentProjectStore = 'currentProject';

interface ProjectState {
    [allProjectsStore]: StateWithLoading<Project[]>,
    [currentProjectStore]: StateWithLoading<Project | null>
}

const initialState: ProjectState = {
    [allProjectsStore]: {
        data: [],
        loading: false,
        error: null
    },
    [currentProjectStore]: {
        data: null,
        loading: false,
        error: null
    }
};

const projectSlice = createAppSlice({
    name: projectsStore,
    initialState,
    reducers: (create) => ({
        setCurrentProject: create.reducer((state, action: PayloadAction<Project | null>) => {
            state[currentProjectStore].data = action.payload;
            state[currentProjectStore].loading = false;
            state[currentProjectStore].error = null;
        }),
        getAllProjects: customThunk(
            create,
            projectsStore,
            allProjectsStore,
            {
                request: getAllProjectsApi,
                handleNotification: (_error, dispatch) => {
                    dispatch(notificationError(RETRIEVE_PROJECTS_ERROR));
                }
            }
        ),
        getProjectById: customThunk(
            create,
            projectsStore,
            currentProjectStore,
            {
                request: (id: number) => getProjectByIdApi(id),
                handleNotification: (error, dispatch) => {
                    if(error?.status === 404) {
                        dispatch(notificationError(RETRIEVE_PROJECT_NOT_EXIST));
                    } else {
                        dispatch(notificationError(RETRIEVE_PROJECT_ERROR));
                    }
                }
            }
        )
    })
});

export const projectsReducer = projectSlice.reducer;

export const projectsActions = projectSlice.actions;
