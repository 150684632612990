import { selectExcilyensFilteredBySkill } from '../ExcilyenFilter/excilyensFilters.selector';
import { excilyensStore } from '../../../rootReducer';
import {
    allExcilyensSetStore,
    detailedExcilyensSkillsStore,
    excilyensSetBySkillStore
} from '../../../reducers/Excilyen/ExcilyenSet/excilyenSet.reducer';
import { createSelector } from 'reselect';

export function selectAllExcilyenSet(state) {
    return state[excilyensStore][allExcilyensSetStore];
}

export function selectDetailedExcilyensSkillsSet(state) {
    return state[excilyensStore][detailedExcilyensSkillsStore];
}

export function selectAllExcilyensNumber(state) {
    return state[excilyensStore][allExcilyensSetStore].length;
}

export const selectExcilyensNumberByPromo = (promo) => createSelector(
    selectExcilyensFilteredBySkill,
    (excilyens) => excilyens.filter((excilyen) => excilyen.promo === promo)
);

export function selectExcilyen(state) {
    return state[excilyensStore].excilyen;
}

export const selectExcilyensDetailedBySkill = (state) => state[excilyensStore][excilyensSetBySkillStore];


export const selectExcilyensBySkill = (skill) => createSelector(
    selectAllExcilyenSet,
    (excilyens) => excilyens.filter((excilyen) => excilyen.skillDetailedApiDtos.map((element) => element.skillName).includes(skill.skillName))
);

export const reducer = (skill) => (accumulator, currentValue) => currentValue.skillDetailedApiDtos
    ? (accumulator + currentValue.skillDetailedApiDtos.find((element) => element.skillName === skill.skillName).userLevel) / 2
    : 0;

export const selectExcilyensLevelAverageBySkill = (skill) => createSelector(
    selectExcilyensBySkill(skill),
    (excilyens) => excilyens
        .reduce(reducer(skill), 0)
);

export const selectExcilyensModeratorBySkill = (skill) => createSelector(
    selectExcilyensBySkill(skill),
    (excilyens) => excilyens
        .filter((excilyen) => excilyen.skillDetailedApiDtos
            .find((element) => element.skillName === skill.skillName).moderator)
);
