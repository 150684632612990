import { sectorStore,
    allSectorsStore } from '../../reducers/Sector/sector.reducer';

import { sectorsStore } from '../../rootReducer';

export function selectAllSectors(state) {
    return state[sectorsStore][allSectorsStore];
}

export function selectSector(state) {
    return state[sectorsStore][sectorStore];
}

