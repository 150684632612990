import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import colors from 'styles/colors.module.scss';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    greenCheck: {
        color: colors['green-color-700'],
        cursor: 'pointer'
    },
    redCross: {
        color: colors['red-color-400'],
        cursor: 'pointer',
        marginLeft: '8px'
    },
    gridAlignItems: {
        alignItems: 'center',
        display: 'flex'
    },
    requestAnchorRight: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'end'
    },
    subheaderText: {
        color: colors['grey-color-400'],
        fontStyle: 'italic'
    }
}));

export function CoachingRelationListItem(props) {

    const classes = useStyles();

    const handleAcceptCoachingRelation = () => {
        props.handleCoachingRequest(true, props.student.id, `${props.student.firstName} ${props.student.lastName}`);
    };

    const handleRefuseCoachingRelation = () => {
        props.handleCoachingRequest(false, props.student.id);
    };

    return (
        <div data-testid="coaching-relation-list-item">
            <ListItem>
                <Grid container spacing={2}>
                    <Grid item xs={7} sm={6} md={7} lg={6} className={classes.gridAlignItems}>
                        <ListItemAvatar >
                            <Avatar src={`${props.student.imageUrl}`}
                                component={Link}
                                to={`/excilyens/${props.student.id}`} style={{ color: 'inherit',
                                    textDecoration: 'none' }}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Link to={`/excilyens/${props.student.id}`} style={{ color: 'inherit',
                                    textDecoration: 'none' }}>
                                    {props.student.firstName} {props.student.lastName}
                                </Link>
                            }
                            secondary={`${props.student.promo}`}/>
                    </Grid>
                    <Grid item xs={5} sm={6} md={5} lg={6} className={classes.requestAnchorRight}>
                        {props.isAskingToBeCoached
                            ? <>
                                <CheckCircleOutlineIcon
                                    id="greenCheck"
                                    data-testid="green-check"
                                    aria-label="accept"
                                    className={classes.greenCheck}
                                    onClick={handleAcceptCoachingRelation}
                                    fontSize="large"/>
                                <CancelOutlinedIcon
                                    id="redCross"
                                    data-testid="red-cross"
                                    aria-label="refuse"
                                    className={classes.redCross}
                                    onClick={handleRefuseCoachingRelation}
                                    fontSize="large"/>
                            </>
                            : <span className={classes.subheaderText}>En attente de confirmation</span>
                        }
                    </Grid>
                </Grid>
            </ListItem>
        </div>
    );
}

CoachingRelationListItem.propTypes = {
    student: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        promo: PropTypes.number.isRequired,
        imageUrl: PropTypes.string.isRequired
    }),
    isAskingToBeCoached: PropTypes.bool,
    handleCoachingRequest: PropTypes.func
};

