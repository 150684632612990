import { Chart } from 'react-google-charts';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './SkillPerExcilyen.scss';
import { selectExcilyenSkillCount } from '../redux/selectors/Skill/SkillSet/skillSet.selector';

export class SkillPerExcilyen extends Component {

    render() {

        const excilyens = this.props.selectExcilyenSkillCount();
        const data = [
            [
                'Compétence(s)',
                'Excilyen(s)'
            ]
        ];
        if(excilyens) {
            excilyens.forEach((excilyen) => data.push(excilyen));
        }

        return (
            <div className="chart">
                <div className="titre"> Nombre d&apos;Excilyens par compétence</div>
                <Chart
                    width= "100%"
                    height={500}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={{
                        hAxis: {
                            title: 'Nb de compétences'
                        },
                        vAxis: {
                            title: 'Nb d\'Excilyens'
                        }
                    }}

                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        );
    }
}


SkillPerExcilyen.propTypes = {
    selectExcilyenSkillCount: PropTypes.func
};

function mapStateToProps(state) {
    return {
        selectExcilyenSkillCount: () => selectExcilyenSkillCount(state)
    };
}

export default connect(mapStateToProps)(SkillPerExcilyen);
