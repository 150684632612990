import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PlayChapterQuestionsRequest from './PlayChapterQuestionsRequest';
import { useHistory } from 'react-router-dom';
import { CHAPTER_RESOLVE_URL } from 'app/Routes';

import { IconButton, Tooltip } from '@material-ui/core';
import { PlayCircleOutline } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setExamMode } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { addRoom } from 'app/redux/actions/InteractiveQuiz/interactiveQuizRoom.actions';
import { v4 as uuidv4 } from 'uuid';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { mapLocalTimeToDuration } from 'app/utils/date.functions';

function PlayChapterButton(props) {

    const history = useHistory();
    const dispatch = useDispatch();

    const [nbQuestions, setNbQuestions] = useState('0');
    const [anchorEl, setAnchorEl] = useState(null);
    const [withChildren, setWithChildren] = useState(true);
    const [isRandomSerie, setIsRandomSerie] = useState(false);
    const [language, setLanguage] = useState('zza');
    const [isExamMode, setIsExamMode] = useState(false);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [maxDuration, setMaxDuration] = useState('--:--');

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDialogOpened(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDialogOpened(false);
    };

    const handleChangeWithChildren = (event) => {
        setWithChildren(event.target.checked);
    };

    const handleChangeIsRandomSerie = (event) => {
        setIsRandomSerie(event.target.checked);
    };

    const handleChangeExamMode = (event) => {
        setIsExamMode(event.target.checked);
    };

    const handleChangeNbQuestions = (value) => {
        setNbQuestions(String(value));
    };

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
    };

    const handleChangeMaxDuration = (value) => {
        setMaxDuration(String(value));
    };

    const handleValidate = () => {
        dispatch(setExamMode(isExamMode));
        const paramMaxDuration = maxDuration === '--:--' ? '' : `&maxDuration=${mapLocalTimeToDuration(maxDuration)}`;
        history.push(`${CHAPTER_RESOLVE_URL
        }/${props.skill.name
        }?skillId=${props.skill.id
        }&chapterId=${props.chapterId
        }&nbQuestions=${nbQuestions
        }&withChildren=${withChildren
        }&isRandomSerie=${isRandomSerie
        }&language=${language
        }${paramMaxDuration}`);
    };

    const quizUuid = uuidv4().split('-')[0];

    const handleValidateInteractiveQuiz = () => {
        dispatch(addRoom({
            uuid: quizUuid,
            serieParams: {
                skillName: props.skill.name,
                skillId: props.skill.id,
                chapterId: props.chapterId,
                nbQuestions,
                withChildren,
                isRandomSerie,
                language
            },
            currentQuestionId: -1,
            isQuizStarted: false,
            users: [],
            hostId: AuthenticationContainer.getCurrentUserId()
        }));
    };

    return (
        <>
            <Tooltip title={`Démarrer les questions de ${props.chapterName}`}>
                <span>
                    <IconButton
                        aria-label={`Démarrer les questions de ${props.chapterName}`}
                        onClick={handleOpen}
                        disabled={!props.chapterId}
                    >
                        <PlayCircleOutline />
                    </IconButton>
                </span>
            </Tooltip>
            {dialogOpened && <PlayChapterQuestionsRequest
                anchorEl={anchorEl}
                onClose={handleClose}
                onValidate={handleValidate}
                onValidateInteractiveQuiz={handleValidateInteractiveQuiz}
                chapterId={props.chapterId}
                nbQuestions={nbQuestions}
                onChangeNbQuestions={handleChangeNbQuestions}
                withChildren={withChildren}
                onChangeWithChildren={handleChangeWithChildren}
                isRandomSerie={isRandomSerie}
                setIsRandomSerie={handleChangeIsRandomSerie}
                language={language}
                onChangeLanguage={handleChangeLanguage}
                isExamMode={isExamMode}
                onSetExamMode={handleChangeExamMode}
                quizUuid={quizUuid}
                maxDuration={maxDuration}
                onChangeMaxDuration={handleChangeMaxDuration}
            />}
        </>
    );
}

PlayChapterButton.propTypes = {
    chapterId: PropTypes.number.isRequired,
    chapterName: PropTypes.string.isRequired,
    skill: PropTypes.object.isRequired
};

export default PlayChapterButton;
