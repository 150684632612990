import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as LogOutIcon } from 'assets/images/log-out-icon.svg';

import AuthenticationContainer from '../../login/containers/AuthenticationContainer';

import {
    setExcilyensSearchFilter,
    setExcilyensSkillsFilter,
    setPromoFilter
} from '../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import {
    setCategoryFilter,
    setFamilyFilter,
    setSearchFilter
} from '../../redux/actions/Filter/filter.actions';
import { clearAllNotifications } from '../../redux/actions/Notification/notifications.actions';
import { projectsActions } from 'app/redux/slices/projects.slice';
import { resetCurrentUser } from '../../redux/actions/User/user.actions';
import { selectCurrentUser } from '../../redux/selectors/User/user.selector';

import {
    Button,
    // Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    SwipeableDrawer,
    Typography,
    makeStyles
} from '@material-ui/core';

import './MenuSmallScreen.scss';
import '../containers/Header.scss';
import colors from '../../../styles/colors.module.scss';
import { skillActions } from 'app/redux/slices/skills.slice';

const useStyles = makeStyles((theme) => ({
    '@keyframes zoom-in-zoom-out': {
        '0%': {
            transformOrigin: '-400px 0'
        },
        '50%': {
            transform: 'scale(1.1, 1.1)'
        },
        '100%': {
            transform: 'scale(1, 1)'
        }
    },
    'divider': {
        backgroundColor: colors.white
    },
    'logOutIconHeight': {
        width: '38px',
        height: '42px'
    },
    'navlinkProfile': {
        color: colors.white
    },
    'navlinkTabItem': {
        color: colors.white,
        textTransform: 'uppercase',
        paddingLeft: '4px'
    },
    'quizButtonPadding': {
        paddingBottom: '8px'
    },
    'startInteractiveQuiz': {
        margin: '0 8px',
        background: colors['grey-color-200'],
        borderRadius: '24px',
        fontWeight: 600
    },
    'textAlignCenter': {
        textAlign: 'center'
    },
    'textAnimation': {
        color: colors.white,
        textTransform: 'uppercase',
        fontSize: '18px',
        fontWeight: 600,
        paddingLeft: '4px',
        animation: `$zoom-in-zoom-out 1s ${theme.transitions.easing.easeOut}`
    },
    'hover': {
        '&:hover': {
            fontWeight: 500,
            color: colors.white,
            border: '1px solid white'
        }
    }
}));

export default function MenuSmallScreen(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const user = useSelector(selectCurrentUser);

    const currentTab = window.location.pathname;

    const usersRoute = '/users';
    const recrutementRoute = '/recrutement';
    const statsRoute = '/stats';
    const coachingRoute = '/coaching';
    const questionsRoute = '/questions';
    const projectsRoute = '/projects';
    const skillsRoute = '/skills';
    const excilyensRoute = '/excilyens';
    const chatRoute = '/chat';

    const resetSearchAndFilters = () => {
        dispatch(setSearchFilter(''));
        dispatch(setCategoryFilter([]));
        dispatch(setFamilyFilter([]));
        dispatch(setPromoFilter([]));
        dispatch(setExcilyensSearchFilter(''));
        dispatch(setExcilyensSkillsFilter([]));
    };

    const clearStoreData = () => {
        dispatch(setSearchFilter(''));
        dispatch(setCategoryFilter([]));
        dispatch(setFamilyFilter([]));
        dispatch(setPromoFilter([]));
        dispatch(setExcilyensSearchFilter(''));
        dispatch(setExcilyensSkillsFilter([]));
        dispatch(skillActions.reset());
        dispatch(resetCurrentUser());
        dispatch(projectsActions.setCurrentProject(null));
        dispatch(clearAllNotifications());
    };

    const disconnect = () => {
        AuthenticationContainer.disconnect();
        history.push('/');
        clearStoreData();
    };

    const setIsOpenDialog = () => {
        props.onChangeIsOpenDialog();
    };

    const sideList = () => (
        <div className="side-list" role="presentation"
            onClick={props.onChange} onKeyDown={props.onChange}>
            <List>
                <ListItem button>
                    <NavLink className="nav-link" to={usersRoute} onClick={resetSearchAndFilters} data-testid="test-user">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item><img src={AuthenticationContainer.getProfilePicture()} alt="" className="drawer-avatar"/></Grid>
                            <Grid item><ListItemText primary={'Voir mon profil'} className={classes.navlinkProfile} /></Grid>
                        </Grid>
                    </NavLink>
                </ListItem>
            </List>
            <div className={classes.textAlignCenter}>
                <Button className={`${classes.startInteractiveQuiz} ${classes.hover}`}
                    color="primary"
                    variant="outlined"
                    aria-label="Quiz Interactif"
                    startIcon="🏆"
                    endIcon="🏆"
                    onClick={setIsOpenDialog}
                >
                        Quiz Interactif
                </Button>
            </div>
            <List>
                {
                    user.roles &&
                    user.roles.some((role) => role.name === 'RECRUTEUR') &&
                    <NavLink className="nav-link" to={recrutementRoute} onClick={resetSearchAndFilters} data-testid="test-recruteur">
                        <ListItem button>
                            <ListItemText primary={
                                <Typography className={currentTab.includes(recrutementRoute)
                                    ? classes.textAnimation
                                    : classes.navlinkTabItem}>
                                        Recrutement
                                </Typography>}
                            />
                        </ListItem>
                    </NavLink>
                }
                {
                    user.roles &&
                    user.roles.some((role) => role.name === 'ADMIN') &&
                    <NavLink className="nav-link" to={statsRoute} onClick={resetSearchAndFilters} data-testid="test-stats">
                        <ListItem button>
                            <ListItemText primary={
                                <Typography className={currentTab.includes(statsRoute)
                                    ? classes.textAnimation
                                    : classes.navlinkTabItem}>
                                        Statistiques
                                </Typography>}
                            />
                        </ListItem>
                    </NavLink>
                }
                <NavLink className="nav-link" to={coachingRoute} onClick={resetSearchAndFilters} data-testid="test-coaching">
                    <ListItem button>
                        <ListItemText primary={
                            <Typography className={currentTab.includes(coachingRoute)
                                ? classes.textAnimation
                                : classes.navlinkTabItem}>
                                    Coaching
                            </Typography>}
                        />
                    </ListItem>
                </NavLink>
                <NavLink className="nav-link" to={questionsRoute} onClick={resetSearchAndFilters} data-testid="test-question">
                    <ListItem button>
                        <ListItemText primary={
                            <Typography className={currentTab.includes(questionsRoute)
                                ? classes.textAnimation
                                : classes.navlinkTabItem}>
                                    Questions
                            </Typography>}
                        />
                    </ListItem>
                </NavLink>
                <NavLink className="nav-link" to={projectsRoute} onClick={resetSearchAndFilters} data-testid="test-project">
                    <ListItem button>
                        <ListItemText primary={
                            <Typography className={currentTab.includes(projectsRoute)
                                ? classes.textAnimation
                                : classes.navlinkTabItem}>
                                    Projets
                            </Typography>}
                        />
                    </ListItem>
                </NavLink>
                <NavLink className="nav-link" to={skillsRoute} onClick={resetSearchAndFilters} data-testid="test-skills">
                    <ListItem button>
                        <ListItemText primary={
                            <Typography className={currentTab.includes(skillsRoute)
                                ? classes.textAnimation
                                : classes.navlinkTabItem}>
                                    Compétences
                            </Typography>}
                        />
                    </ListItem>
                </NavLink>
                <NavLink className="nav-link" to={excilyensRoute} onClick={resetSearchAndFilters} data-testid="test-excilyens">
                    <ListItem button>
                        <ListItemText primary={
                            <Typography className={currentTab.includes(excilyensRoute)
                                ? classes.textAnimation
                                : classes.navlinkTabItem}>
                                    Oxyliens
                            </Typography>}
                        />
                    </ListItem>
                </NavLink>
                <NavLink className="nav-link" to={chatRoute} onClick={resetSearchAndFilters}>
                    <ListItem button>
                        <ListItemText primary={
                            <Typography className={currentTab.includes(chatRoute)
                                ? classes.textAnimation
                                : classes.navlinkTabItem}>
                                    Chat
                            </Typography>}
                        />
                    </ListItem>
                </NavLink>
                <Divider variant="middle" className={classes.divider} />
                <ListItem className="disconnect-button" button onClick={disconnect} data-testid="test-disconnect">
                    <ListItemText className={classes.navlinkTabItem}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>Se déconnecter</Grid>
                            <Grid item className={classes.logOutIconHeight}>
                                <LogOutIcon />
                            </Grid>
                        </Grid>
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    );

    return (
        <SwipeableDrawer
            data-testid="drawer"
            open={props.open} onClose={props.onChange} anchor="right"
            onOpen={props.onChange}>
            {sideList()}
        </SwipeableDrawer>
    );
}

MenuSmallScreen.propTypes = {
    onChange: PropTypes.func,
    open: PropTypes.bool,
    onChangeIsOpenDialog: PropTypes.func
};
