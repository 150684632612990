import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { connect } from 'react-redux';
import SkillLevelRating from '../skillLevelRating/SkillLevelRating';
import './AddSkillTable.scss';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { getCurrentUserById } from '../../redux/actions/User/user.actions';
import { skillActions } from 'app/redux/slices/skills.slice';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

export class AddSkillTable extends Component {

    componentDidMount() {
        this.props.getSkillSet();
        this.props.getCurrentUserById();
    }

    onChangeRowsPerPage = (pageSize) => localStorage.setItem('displayMaterialTableSkillsPageSize', pageSize);

    render() {

        const columnsForMobile = [
            {
                title: 'Compétence',
                field: 'skillName',
                editable: 'never',
                headerStyle: {
                    padding: '5px'
                },
                cellStyle: {
                    padding: '5px'
                }
            },
            {
                title: 'Mon niveau',
                field: '',
                render: (rowData) => <SkillLevelRating starSize={15} skillName={rowData.skillName} skillId={rowData.id}/>
            }
        ];

        const columns = [
            {
                title: '',
                field: 'imageUrl',
                editable: 'never',
                sorting: false,
                render: (rowData) => (rowData.imageUrl
                    ? <img className="row-image" data-testid="image" src={rowData.imageUrl} alt={rowData.skillName}/>
                    : <div className="row-no-image" data-testid="no-image"/>),
                cellStyle: {
                    textAlign: 'center',
                    padding: '5px'
                }
            },
            {
                title: 'Compétence',
                field: 'skillName',
                editable: 'never'
            },
            {
                title: 'Famille',
                field: 'familyName',
                editable: 'never'
            },
            {
                title: 'Catégorie',
                field: 'categoryName',
                editable: 'never'
            },
            {
                title: 'Mon niveau',
                field: '',
                sorting: false,
                render: (rowData) => <SkillLevelRating starSize={25} skillName={rowData.skillName} skillId={rowData.id}/>
            }
        ];

        const theme = createTheme({
            overrides: {
                MuiTableSortLabel: {
                    icon: {
                        width: '25px',
                        height: '25px'
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: '0'
                    },
                    sizeSmall: {
                        padding: '0'
                    }
                }
            }
        });

        const data = this.props.allSkills.data
            ? Object.values(this.props.allSkills.data).filter((skill) => skill.coveredByExcilys)
            : [];

        return <MuiThemeProvider theme={theme}>
            <MaterialTable
                components={{
                    Toolbar: (props) => <div className="array-header">
                        <MTableToolbar {...props} />
                    </div>
                }}
                title={isWidthUp('sm', this.props.width) ? 'Vos compétences ' : ''}
                columns={isWidthUp('sm', this.props.width) ? columns : columnsForMobile}
                data={data.map(it => ({ ...it }))}
                isLoading={this.props.allSkills.loading}
                options={{
                    pageSize: localStorage.displayMaterialTableSkillsPageSize === undefined
                        ? 5
                        : Number.parseInt(localStorage.displayMaterialTableSkillsPageSize, 10)
                }}
                localization={{
                    'body': {
                        'emptyDataSourceMessage': 'Pas de résultats'
                    },
                    'header': {
                        'actions': ''
                    },
                    'pagination': {
                        'labelDisplayedRows': '{from}-{to} / {count}',
                        'labelRowsSelect': '',
                        'nextTooltip': 'Page suivante',
                        'previousTooltip': 'Page précédente',
                        'firstTooltip': 'Première page',
                        'lastTooltip': 'Dernière page'
                    },
                    'toolbar': {
                        'searchPlaceholder': 'Rechercher',
                        'searchTooltip': 'Rechercher'
                    }
                }}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
        </MuiThemeProvider>;
    }
}

AddSkillTable.propTypes = {
    allSkills: PropTypes.object,
    width: PropTypes.string,
    getCurrentUserById: PropTypes.func,
    getSkillSet: PropTypes.func
};

export const mapStateToProps = (state) => ({
    allSkills: selectAllSkills(state)
});

export const mapDispatchToProps = (dispatch) => ({
    getSkillSet: () => dispatch(skillActions.getAllSkills()),
    getCurrentUserById: () => dispatch(getCurrentUserById())
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(AddSkillTable));
