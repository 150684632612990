import React from 'react';
import { MdContentCopy, MdContentPaste, MdDeleteForever } from 'react-icons/md';
import { AddCircleOutline, Edit, PlayCircleOutline } from '@material-ui/icons';
import { ContextMenuAction, ContextMenuProps } from './ContextMenu.types';

export enum ContextMenuActionEnum {
    RENAME = 'Éditer le chapitre',
    NEW_SUB_CHAPTER = 'Ajouter un sous chapitre',
    NEW_QUESTION = 'Ajouter une question',
    COPY = 'Copier',
    PASTE_IN = 'Coller dans le chapitre',
    PASTE_NEXT_TO = 'Coller à côté du chapitre',
    REMOVE = 'Supprimer le chapitre',
    PLAY_CHAPTER_SERIE = 'Lancer la série'
}

export const CONTEXT_MENU_OPTIONS_WITHOUT_PERMISSION = new Map<ContextMenuActionEnum, ContextMenuAction>([
    [
        ContextMenuActionEnum.PLAY_CHAPTER_SERIE,
        {
            text: ContextMenuActionEnum.PLAY_CHAPTER_SERIE,
            icon: <PlayCircleOutline />
        }
    ]
]);

export const CONTEXT_MENU_OPTIONS_WITH_PERMISSION = new Map<ContextMenuActionEnum, ContextMenuAction>([
    [
        ContextMenuActionEnum.RENAME,
        {
            text: ContextMenuActionEnum.RENAME,
            icon: <Edit/>,
            disable: (props: ContextMenuProps) => props.disableDialogActions
        }
    ], [
        ContextMenuActionEnum.NEW_SUB_CHAPTER,
        {
            text: ContextMenuActionEnum.NEW_SUB_CHAPTER,
            icon: <AddCircleOutline/>,
            disable: (props: ContextMenuProps) => props.disableDialogActions
        }
    ], [
        ContextMenuActionEnum.NEW_QUESTION,
        {
            text: ContextMenuActionEnum.NEW_QUESTION,
            icon: <AddCircleOutline/>,
            disable: (props: ContextMenuProps) => props.disableDialogActions
        }
    ], [
        ContextMenuActionEnum.COPY,
        {
            text: ContextMenuActionEnum.COPY,
            icon: <MdContentCopy fontSize="1.5rem"/>
        }
    ], [
        ContextMenuActionEnum.PASTE_IN,
        {
            text: ContextMenuActionEnum.PASTE_IN,
            icon: <MdContentPaste fontSize="1.5rem"/>,
            disable: (props: ContextMenuProps) => !props.chapterToCopy
        }
    ], [
        ContextMenuActionEnum.PASTE_NEXT_TO,
        {
            text: ContextMenuActionEnum.PASTE_NEXT_TO,
            icon: <MdContentPaste fontSize="1.5rem"/>,
            disable: (props: ContextMenuProps) => !props.chapterToCopy
        }
    ], [
        ContextMenuActionEnum.REMOVE,
        {
            text: ContextMenuActionEnum.REMOVE,
            icon: <MdDeleteForever fontSize="1.5rem"/>,
            disable: (props: ContextMenuProps) => props.disableDeleteChapter,
            tooltip: (props: ContextMenuProps) => props.disableDeleteChapter
                ? 'Veuillez déplacer les questions du chapitre avant de le supprimer'
                : 'Supprime ce chapitre (et ses sous-chapitres)'
        }
    ]
]);
