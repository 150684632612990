
import React from 'react';
import PropTypes from 'prop-types';

import './QuestionListSummary.scss';

import BookmarkIcon from '@material-ui/icons/Bookmark';
import { List, ListItem, makeStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import {
    selectQuestionIndexToResolve,
    selectQuestionListToResolve
} from 'app/redux/selectors/Question/serie.selector';
import { resetQuestionsResolve } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { setQuestionIndexToResolve, setQuestionListToResolve } from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';

const useStyles = makeStyles(() => ({
    item: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

function QuestionListSummary(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const questionListToResolve = useSelector(selectQuestionListToResolve);
    const questionIndexToResolve = useSelector(selectQuestionIndexToResolve);

    const removeQuestionFromList = () => {
        const copy = [...questionListToResolve];
        copy.splice(questionIndexToResolve, 1);
        dispatch(setQuestionListToResolve(copy));
    };

    const handleQuestionClick = (questionId) => () => {
        if(!props.isInteractiveQuiz) {
            let newIndex = questionListToResolve.findIndex((question) => (
                question.id === questionId
            ));
            if(newIndex !== questionIndexToResolve) {
                dispatch(resetQuestionsResolve());
                if(questionListToResolve[questionIndexToResolve].isAnswered) {
                    removeQuestionFromList();
                    if(newIndex > questionIndexToResolve) {
                        newIndex -= 1;
                    }
                }
                dispatch(setQuestionIndexToResolve(newIndex));
            }
        }
    };

    return (
        <List className="tasksList">
            {questionListToResolve.map((question) => (
                <ListItem
                    button
                    key={question.id}
                    className={classes.item}
                    selected={question.id === questionListToResolve[questionIndexToResolve].id}
                    onClick={handleQuestionClick(question.id)}
                    disabled={props.isInteractiveQuiz}
                >
                    {question.skill?.name}#{question.id}
                    {question.isMark &&
                        <div className="mark-icon">
                            <BookmarkIcon color="primary" />
                        </div>
                    }
                </ListItem>
            ))}
        </List>
    );
}

QuestionListSummary.propTypes = {
    isInteractiveQuiz: PropTypes.bool
};

export default QuestionListSummary;
