import {
    EXPIRED_SESSION,
    GET_STUDENT_BY_GROUP_ID_ERROR,
    DELETE_GROUP_ERROR,
    DELETE_GROUP,
    GET_ALL_STUDENTS_OF_GROUP_ERROR,
    GET_ALL_COACHES_OF_GROUP_ERROR,
    GET_GROUP_BY_COACH_ID_ERROR,
    PATCH_ARCHIVED_GROUP_BY_COACH_ID_ERROR,
    UNAUTHORIZED
} from 'app/Snackbar/NotificationMessages';
import {
    putGroupsApi,
    deleteGroupByIdApi,
    getAllStudentsOfGroupByGroupIdApi,
    getAllCoachesOfGroupByGroupIdApi,
    getGroupsByCoachIdApi,
    getAllStudentsOfGroupByGroupIdsApi,
    updateArchivedgroupsApi
} from 'app/api/groupApi';
import { notificationError, notificationSuccess } from 'app/redux/actions/Notification/notifications.actions';
import { getRichChaptersStatsApiByChapterId, getRichChaptersStatsApiByChaptersAndUsers } from 'app/api/chapterStatsApi';

export const GET_STUDENTS_BY_GROUP_ID_REQUEST = 'GET_STUDENTS_BY_GROUP_ID_REQUEST';
export const GET_STUDENTS_BY_GROUP_ID_SUCCESS = 'GET_STUDENTS_BY_GROUP_ID_SUCCESS';
export const GET_STUDENTS_BY_GROUP_ID_FAILURE = 'GET_STUDENTS_BY_GROUP_ID_FAILURE';

export const GET_GROUP_BY_STUDENT_ID_REQUEST = 'GET_GROUP_BY_STUDENT_ID_REQUEST';
export const GET_GROUP_BY_STUDENT_ID_SUCCESS = 'GET_GROUP_BY_STUDENT_ID_SUCCESS';
export const GET_GROUP_BY_STUDENT_ID_FAILURE = 'GET_GROUP_BY_STUDENT_ID_FAILURE';

export const GET_GROUP_BY_COACH_ID_REQUEST = 'GET_GROUP_BY_COACHES_ID_REQUEST';
export const GET_GROUP_BY_COACH_ID_SUCCESS = 'GET_GROUP_BY_COACHES_ID_SUCCESS';
export const GET_GROUP_BY_COACH_ID_FAILURE = 'GET_GROUP_BY_COACHES_ID_FAILURE';

export const PUT_GROUP_REQUEST = 'PUT_GROUP_REQUEST';
export const PUT_GROUP_SUCCESS = 'PUT_GROUP_SUCCESS';
export const PUT_GROUP_FAILURE = 'PUT_GROUP_FAILURE';
export const PUT_GROUP_ERROR = 'PUT_GROUP_ERROR';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';

export const GET_ALL_STUDENTS_OF_GROUP_REQUEST = 'GET_ALL_STUDENTS_OF_GROUP_REQUEST';
export const GET_ALL_STUDENTS_OF_GROUP_SUCCESS = 'GET_ALL_STUDENTS_OF_GROUP_SUCCESS';
export const GET_ALL_STUDENTS_OF_GROUP_FAILURE = 'GET_ALL_STUDENTS_OF_GROUP_FAILURE';

export const GET_ALL_STUDENTS_OF_GROUPS_REQUEST = 'GET_ALL_STUDENTS_OF_GROUPS_REQUEST';
export const GET_ALL_STUDENTS_OF_GROUPS_SUCCESS = 'GET_ALL_STUDENTS_OF_GROUPS_SUCCESS';
export const GET_ALL_STUDENTS_OF_GROUPS_FAILURE = 'GET_ALL_STUDENTS_OF_GROUPS_FAILURE';

export const GET_ALL_COACHES_OF_GROUP_REQUEST = 'GET_ALL_COACHES_OF_GROUP_REQUEST';
export const GET_ALL_COACHES_OF_GROUP_SUCCESS = 'GET_ALL_COACHES_OF_GROUP_SUCCESS';
export const GET_ALL_COACHES_OF_GROUP_FAILURE = 'GET_ALL_COACHES_OF_GROUP_FAILURE';

export const GET_GROUP_CHAPTER_STATS_REQUEST = 'GET_GROUP_CHAPTER_STATS_REQUEST';
export const GET_GROUP_CHAPTER_STATS_SUCCESS = 'GET_GROUP_CHAPTER_STATS_SUCCESS';
export const GET_GROUP_CHAPTER_STATS_FAILURE = 'GET_GROUP_CHAPTER_STATS_FAILURE';

export const GET_COMPLETE_GROUP_CHAPTER_STATS_REQUEST = 'GET_COMPLETE_GROUP_CHAPTER_STATS_REQUEST';
export const GET_COMPLETE_GROUP_CHAPTER_STATS_SUCCESS = 'GET_COMPLETE_GROUP_CHAPTER_STATS_SUCCESS';
export const GET_COMPLETE_GROUP_CHAPTER_STATS_FAILURE = 'GET_COMPLETE_GROUP_CHAPTER_STATS_FAILURE';

export const DELETE_STORED_DATA = 'DELETE_STORED_DATA';

export const RESET_GROUP_STUDENT = 'RESET_GROUP_STUDENT';

export const PATCH_ARCHIVED_GROUP_BY_COACH_ID_REQUEST = 'PATCH_ARCHIVED_GROUP_BY_COACHES_ID_REQUEST';
export const PATCH_ARCHIVED_GROUP_BY_COACH_ID_SUCCESS = 'PATCH_ARCHIVED_GROUP_BY_COACHES_ID_SUCCESS';
export const PATCH_ARCHIVED_GROUP_BY_COACH_ID_FAILURE = 'PATCH_ARCHIVED_GROUP_BY_COACHES_ID_FAILURE';

export const putGroup = (group, coachId) => (dispatch) => {
    dispatch({ type: PUT_GROUP_REQUEST });

    return putGroupsApi(group, coachId).then((response) => {
        dispatch({
            type: PUT_GROUP_SUCCESS,
            payload: response.data
        });
        dispatch({
            type: SET_SELECTED_GROUP,
            payload: response.data
        });

    }, (error) => {
        dispatch({
            type: PUT_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(PUT_GROUP_ERROR));
        }
    });

};

export const deleteGroupById = (groupId) => (dispatch) => {
    dispatch({ type: DELETE_GROUP_REQUEST });

    return deleteGroupByIdApi(groupId).then(() => {
        dispatch({
            type: DELETE_GROUP_SUCCESS,
            payload: groupId
        });
        dispatch(notificationSuccess(DELETE_GROUP));
    }, (error) => {
        dispatch({
            type: DELETE_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_GROUP_ERROR));
        }
    });
};

export const getStudentByGroupsId = (group) => (dispatch) => {
    dispatch({ type: GET_STUDENTS_BY_GROUP_ID_REQUEST });

    return getAllStudentsOfGroupByGroupIdApi(group).then((response) => {
        dispatch({
            type: GET_STUDENTS_BY_GROUP_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_STUDENTS_BY_GROUP_ID_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_STUDENT_BY_GROUP_ID_ERROR));
        }
    });
};

export const setSelectedGroup = (group) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_GROUP,
        payload: group
    });
};

export const getAllStudentsOfGroupByGroupIds = (ids) => (dispatch) => {
    dispatch({ type: GET_ALL_STUDENTS_OF_GROUPS_REQUEST });

    return getAllStudentsOfGroupByGroupIdsApi(ids).then((response) => {
        dispatch({
            type: GET_ALL_STUDENTS_OF_GROUPS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_STUDENTS_OF_GROUPS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_ALL_STUDENTS_OF_GROUP_ERROR));
        }
    });
};

export const getAllStudentsOfGroupByGroupId = (id) => (dispatch) => {
    dispatch({ type: GET_ALL_STUDENTS_OF_GROUP_REQUEST });

    return getAllStudentsOfGroupByGroupIdApi(id).then((response) => {
        dispatch({
            type: GET_ALL_STUDENTS_OF_GROUP_SUCCESS,
            payload: {
                groupId: id,
                students: response.data
            }
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_STUDENTS_OF_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_ALL_STUDENTS_OF_GROUP_ERROR));
        }
    });
};


export const resetGroupStudent = () => (dispatch) => {
    dispatch({ type: RESET_GROUP_STUDENT });
};

export const getAllCoachesOfGroupByGroupId = (id) => (dispatch) => {
    dispatch({ type: GET_ALL_COACHES_OF_GROUP_REQUEST });

    return getAllCoachesOfGroupByGroupIdApi(id).then((response) => {
        dispatch({
            type: GET_ALL_COACHES_OF_GROUP_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_ALL_COACHES_OF_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_ALL_COACHES_OF_GROUP_ERROR));
        }
    });
};

export const getGroupsByCoachId = (coach) => (dispatch) => {
    dispatch({ type: GET_GROUP_BY_COACH_ID_REQUEST });

    return getGroupsByCoachIdApi(coach).then((response) => {
        dispatch({
            type: GET_GROUP_BY_COACH_ID_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_GROUP_BY_COACH_ID_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(GET_GROUP_BY_COACH_ID_ERROR));
        }
    });
};

export const getGroupsStatsByChapterId = (userIds, chapterId) => (dispatch) => {
    dispatch({ type: GET_GROUP_CHAPTER_STATS_REQUEST });

    return getRichChaptersStatsApiByChapterId(userIds, chapterId).then((response) => {
        dispatch({
            type: GET_GROUP_CHAPTER_STATS_SUCCESS,
            payload: {
                chapterId,
                data: response.data
            }
        });
    }, (error) => {
        dispatch({
            type: GET_GROUP_CHAPTER_STATS_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        }
    });
};

export const getGroupsStatsByProfile = (userIds, chapterIds) => (dispatch) => {
    dispatch({ type: GET_COMPLETE_GROUP_CHAPTER_STATS_REQUEST });

    return getRichChaptersStatsApiByChaptersAndUsers(userIds, chapterIds).then((response) => {
        dispatch({
            type: GET_COMPLETE_GROUP_CHAPTER_STATS_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_COMPLETE_GROUP_CHAPTER_STATS_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        }
    });
};

export const resetStoredDataOnChange = () => ({
    type: DELETE_STORED_DATA
});

export const patchArchivedGroupByCoachId = (groupId, coachId) => (dispatch) => {
    dispatch({ type: PATCH_ARCHIVED_GROUP_BY_COACH_ID_REQUEST });

    return updateArchivedgroupsApi(groupId, coachId).then(() => {
        dispatch({
            type: PATCH_ARCHIVED_GROUP_BY_COACH_ID_SUCCESS,
            payload: groupId
        });

    }, (error) => {
        dispatch({
            type: PATCH_ARCHIVED_GROUP_BY_COACH_ID_FAILURE,
            payload: error,
            error: true
        });

        if(error.response && error.response.status === 401) {
            dispatch(notificationError(UNAUTHORIZED));
        } else {
            dispatch(notificationError(PATCH_ARCHIVED_GROUP_BY_COACH_ID_ERROR));
        }
    });
};
