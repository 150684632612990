import { Chapter } from 'app/redux/models/Chapter.model';
import TreeChapter from './TreeChapter';


export function createChapterTree(chapters: Chapter[]) {
    let tree: TreeChapter | null = null;
    if(chapters.length > 0) {
        tree = new TreeChapter('/');
        const sortedChapters = [...chapters].sort((chapter1, chapter2) => {
            if(chapter1.parentPath === chapter2.parentPath) {
                return chapter1.position - chapter2.position;
            }
            return chapter1.parentPath.localeCompare(chapter2.parentPath);
        });
        for(const chapter of sortedChapters) {
            tree.insert(
                chapter.parentPath,
                `${chapter.parentPath}${chapter.name.toLowerCase()}/`,
                chapter
            );
        }
    }
    return tree;
}

export function findByChapterId(tree: TreeChapter, id: number) {
    for(const node of tree.preOrderTraversal()) {
        if(node.content.id === id) {
            return node;
        }
    }
    return undefined;
}
