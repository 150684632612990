
import React from 'react';
import AuthenticationContainer from '../../login/containers/AuthenticationContainer';
import { postAskCoach } from '../../redux/actions/Coaching/coaching.actions';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

function RequestCoachButton(props) {

    const dispatch = useDispatch();

    const currentUserId = () => parseInt(AuthenticationContainer.getCurrentUserId(), 10);

    const requestCoach = () => {
        const newRelation = {
            accepted: false,
            coach: {
                id: props.coachId
            },
            student: {
                id: currentUserId()
            }
        };
        dispatch(postAskCoach(newRelation));
    };

    return (<div>
        {props.coachId && props.coachId !== currentUserId()
            ? <Button id="requestCoachButton"
                data-testid="request-coach-button"
                variant={props.displayAsMenuItem ? 'text' : 'outlined'}
                color={props.displayAsMenuItem ? 'default' : 'primary'}
                onClick={requestCoach}
                aria-label={props.isAlreadyAskedAsCoach ? 'Demande en cours' : props.isAlreadyCoach ? 'Votre coach' : 'Demander en Coach'}
                className={props.className}
                disabled={props.isAlreadyAskedAsCoach || props.isAlreadyCoach}>
                {props.isAlreadyAskedAsCoach ? 'Demande en cours' : props.isAlreadyCoach ? 'Votre coach' : 'Demander en Coach'}
            </Button>
            : <></>
        }
    </div>
    );

}

RequestCoachButton.propTypes = {
    coachId: PropTypes.number.isRequired,
    className: PropTypes.string,
    displayAsMenuItem: PropTypes.bool,
    isAlreadyAskedAsCoach: PropTypes.bool.isRequired,
    isAlreadyCoach: PropTypes.bool.isRequired
};

export default RequestCoachButton;
