import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleClientId = '729076081721-lbcpra451loguclrl09lfn0uhukrls0v.apps.googleusercontent.com';
ReactDOM.render(<GoogleOAuthProvider clientId={ googleClientId }><App/></GoogleOAuthProvider>, document.getElementById('root'));

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
