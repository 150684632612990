import moment from 'moment';

const formats = {
    MINUTES: 'minute',
    HOURS: 'heure',
    DAYS: 'jour',
    WEEKS: 'semaine',
    MONTHS: 'mois',
    YEARS: 'année'
};

const days = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi'
];
export const months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre'
];

export function setFormat(date) {
    if(typeof (date) !== 'number' || date === undefined) {
        return null;
    }
    if(date / (1000 * 60 * 60 * 24 * 30 * 12) > 1) {
        return formats.YEARS;
    }
    if(date / (1000 * 60 * 60 * 24 * 30) > 1) {
        return formats.MONTHS;
    }
    if(date / (1000 * 60 * 60 * 24 * 7) > 1) {
        return formats.WEEKS;
    }
    if(date / (1000 * 60 * 60 * 24) > 1) {
        return formats.DAYS;
    }
    if(date / (1000 * 60 * 60) > 1) {
        return formats.HOURS;
    }
    return formats.MINUTES;

}


export function calculateTime(format, date) {
    if(typeof (format) !== 'string' || format === undefined ||
     typeof (date) !== 'number' || date === undefined) {
        return null;
    }
    switch (format) {
        case formats.HOURS:
            return Math.ceil(date / (1000 * 60 * 60));
        default:
            return Math.ceil(date / (1000 * 60));
    }
}

export function formatDate(format, date, dateDifference) {
    if(typeof (format) !== 'string' || format === undefined ||
     !(date instanceof Date) || date === undefined) {
        return null;
    }
    switch (format) {
        case formats.YEARS:
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        case formats.MONTHS:
            return `${date.getDate()} ${months[date.getMonth()]}`;
        case formats.WEEKS:
            return `${days[date.getDay()]} ${date.getDate()}`;
        case formats.DAYS:
            return `${days[date.getDay()]}`;
        case formats.HOURS:
            return `Il y a ${calculateTime(format, dateDifference)}h`;
        default:
            const displayMinutes = calculateTime(format, dateDifference) === 1 ? 'Il y a 1 minute' : `Il y a ${calculateTime(format, dateDifference)} minutes`;
            return displayMinutes;
    }
}

export function formatTime(time) {
    if(!time) {
        return '';
    }

    const offset = new Date().getTimezoneOffset();
    const [hours, minutes] = time.split(':');
    const formattedHours = String(parseInt(hours, 10) - offset / 60);
    const formattedMinutes = String(parseInt(minutes, 10) - offset % 60).padStart(2, '0');

    return `${formattedHours}h${formattedMinutes}`;
}

export function getTaskCreationDate(task) {
    if(task.creationDate) {
        return new Date(`${task.creationDate}Z`).toLocaleString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
    }
    return null;
}

export function getDateFormated(serieDate) {
    const compareDate = new Date();
    const dateDifference = Math.abs(compareDate - serieDate);
    const format = setFormat(dateDifference);
    return formatDate(format, serieDate, dateDifference);
}

export function dateFormatter(dateString) {
    const date = new Date(dateString);
    const string = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()} ${formatTime(`${date.getHours()}:${date.getMinutes()}`)}`;
    return string;
}

// Regexp qui match les dates au format dd-MM-yyyy

const dateRegExp = /^(0?[1-9]|[12][0-9]|3[0-1])(-)(0?[1-9]|1[0-2])(-)(\d{4})$/u;

// Convertit une date du format dd-MM-yyyy au format yyyy-MM-dd

export function formatToDate(dateStr) {
    if(dateStr) {
        const array = dateStr.match(dateRegExp);
        if(array) {
            return array.slice(1).reverse()
                .join('');
        }
    }
    return null;
}

// Renvoie true si la dateStr au format yyyy-MM-dd est avant la date courante

export function isBeforeToday(dateStr) {
    if(dateStr) {
        return new Date(dateStr) <= new Date();
    }
    return false;
}

// Renvoie un nombre en deux chiffres

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

// Renvoie une date au format dd/MM/yyyy

export function numericFormatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear()
    ].join('/');
}

// Renvoie une date au format dd/MM/yyyy

export function detailedFormatDate(date) {
    return [
        days[date.getDay()],
        padTo2Digits(date.getDate()),
        months[date.getMonth()]
    ].join(' ');
}

// Map 03:30 to PT1H30M

export function mapLocalTimeToDuration(timeStr) {
    if(timeStr) {
        return moment.duration(timeStr).toISOString();
    }
    return null;
}

// Map PT1H30M to 03:30

export function mapDurationToLocalTime(durationStr) {
    if(durationStr === null) {
        return null;
    }
    const durationObject = moment.duration(durationStr);
    return moment({
        minutes: durationObject.minutes(),
        hours: durationObject.hours()
    }).format('HH:mm');
}

// Map PT1H30M0S to {seconds: 0, minutes: 30, hours: 1}

export function mapDurationToObject(durationStr) {
    if(durationStr === null) {
        return null;
    }
    const durationObject = moment.duration(durationStr);
    return {
        seconds: durationObject.seconds(),
        minutes: durationObject.minutes(),
        hours: durationObject.hours()
    };
}

// Map {seconds: 0, minutes: 30, hours: 1} to PT1H30M0S

export function mapObjectToDuration(durationObject) {
    if(durationObject) {
        return `PT${durationObject?.hours}H${durationObject?.minutes}M${durationObject?.seconds}S`;
    }
    return null;
}

export const getDayFromDate = (dateString) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const dayName = daysOfWeek[dayOfWeek];
    return dayName;
};
