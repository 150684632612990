/* eslint-disable react/prop-types */
import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Experts from './excilyens/components/Excilyens';
import Home from './home/components/Home';
import Skill from './skill/components/Skill';
import User from './common/profile/User';
import FirstConnection from './firstConnection/components/FirstConnection';
import Excilyen from './common/profile/Excilyen';
import UserAddSkill from './common/profile/UserAddSkill';
import Statistics from './stats/Statistics';
import ProjectDetails from './project/components/Project/ProjectDetails/ProjectDetails';
import Projects from './project/components/Project/Projects';
import ClientDetails from './project/components/Client/ClientDetails/ClientDetails';
import Questions from './question/Questions';
import QuestionResolver from './question/QuestionResolver/QuestionResolver';
import DailyQuestions from './question/DailyQuestions/DailyQuestions';
import Coaching from './coaching/Coaching';
import SerieResolveRoute from './serie/SerieResolveRoute';
import ChapterResolveRoute from './serie/ChapterResolveRoute';
import Candidate from './candidate/Candidate';
import CandidateWelcome from './candidate/CandidateWelcome/CandidateWelcome';
import SerieNotDoneResolveRoute from './serie/SerieNotDoneResolveRoute';
import CreateEditSerie from './question/createEditSerie/CreateEditSerie';
import CreateEditQuestion from './question/createEditQuestion/CreateEditQuestion';
import QuestionHistory from './question/history/QuestionHistory';
import InteractiveQuiz from './components/InteractiveQuiz/InteractiveQuiz';

import { HeaderParamsPresets } from 'app/utils/HeaderEnum';
import Chat from './chat/components/Chat/Chat';

export const CHAT_PAGE_URL = '/chat';
export const SKILL_PAGE_URL = '/skills';
export const CHAPTER_RESOLVE_URL = '/chapters/resolve';
export const ADD_SKILLS_PAGE_URL = '/add-skills';
export const COACHING_PAGE_URL = '/coaching';

export const USERS_PAGE_URL = '/users';
export const EXPERT_PAGE_URL = '/excilyens';
export const STATS_PAGE_URL = '/stats';

export const PROJECTS_PAGE_URL = '/projects';
export const CLIENTS_PAGE_URL = '/clients';

export const QUESTIONS_PAGE_URL = '/questions';
export const SERIE_RESOLVE_URL = '/series/resolve';
export const HISTORY_URL = '/history';

export const CANDIDATE_PAGE_URL = '/recrutement';
export const CANDIDAT_WELCOME_PAGE_URL = '/candidat';
export const SERIE_NOT_DONE_RESOLVE_URL = '/serieResume/resolve';

export const QUIZ_URL = '/quiz';


export const routesConfig = [
    { path: '/first-connection', exact: true, component: FirstConnection, headerParams: null },
    { path: CHAT_PAGE_URL, exact: true, component: Chat, headerParams: null },
    { path: SKILL_PAGE_URL, exact: true, component: Home, headerParams: HeaderParamsPresets.searchPage },
    { path: ADD_SKILLS_PAGE_URL, exact: true, component: UserAddSkill, headerParams: HeaderParamsPresets.defaultPage },
    { path: COACHING_PAGE_URL, exact: true, component: Coaching, headerParams: HeaderParamsPresets.defaultPage },

    { path: USERS_PAGE_URL, component: User, headerParams: HeaderParamsPresets.defaultPage },
    { path: EXPERT_PAGE_URL, exact: true, component: Experts, headerParams: HeaderParamsPresets.searchPage },
    { path: STATS_PAGE_URL, exact: true, component: Statistics, headerParams: HeaderParamsPresets.defaultPage },

    { path: PROJECTS_PAGE_URL, exact: true, component: Projects, headerParams: HeaderParamsPresets.filterPage },
    { path: QUESTIONS_PAGE_URL, exact: true, component: Questions, headerParams: HeaderParamsPresets.filterPage },

    { path: CANDIDATE_PAGE_URL, exact: true, component: Candidate, headerParams: HeaderParamsPresets.defaultPage },

    { path: `${SKILL_PAGE_URL}/:skillName`, exact: false, component: Skill, headerParams: HeaderParamsPresets.defaultPage },
    { path: `${CHAPTER_RESOLVE_URL}/:skillName`, exact: false, component: ChapterResolveRoute, headerParams: HeaderParamsPresets.filterPage },

    { path: `${EXPERT_PAGE_URL}/:id`, exact: false, component: Excilyen, headerParams: HeaderParamsPresets.defaultPage },

    { path: `${CLIENTS_PAGE_URL}/:id`, exact: false, component: ClientDetails, headerParams: HeaderParamsPresets.defaultPage },
    { path: `${PROJECTS_PAGE_URL}/:id`, exact: false, component: ProjectDetails, headerParams: HeaderParamsPresets.defaultPage },

    { path: `${QUESTIONS_PAGE_URL}/resolve/:id`, exact: false, component: QuestionResolver, headerParams: HeaderParamsPresets.filterPage },
    { path: `${QUESTIONS_PAGE_URL}/daily/:date`, exact: false, component: DailyQuestions, headerParams: HeaderParamsPresets.filterPage },
    { path: `${QUESTIONS_PAGE_URL}/serie/:mode`, exact: false, component: CreateEditSerie, headerParams: HeaderParamsPresets.filterPage },
    { path: `${QUESTIONS_PAGE_URL}/:mode`, exact: false, component: CreateEditQuestion, headerParams: HeaderParamsPresets.filterPage },
    { path: `${SERIE_RESOLVE_URL}/:id`, exact: false, component: SerieResolveRoute, headerParams: HeaderParamsPresets.filterPage },
    { path: `${SERIE_NOT_DONE_RESOLVE_URL}/serieResume/:id/history/:historyId`, exact: false, component: SerieNotDoneResolveRoute, headerParams: HeaderParamsPresets.filterPage },
    { path: `${HISTORY_URL}/:id`, exact: false, component: QuestionHistory, headerParams: HeaderParamsPresets.defaultPage },

    { path: `${CANDIDAT_WELCOME_PAGE_URL}/:uuid`, exact: false, component: CandidateWelcome, headerParams: HeaderParamsPresets.candidatePage },
    { path: `${QUIZ_URL}/:uuid`, exact: false, component: InteractiveQuiz, headerParams: HeaderParamsPresets.defaultPage }
];

const Routes = () => <>
    <Switch>
        {routesConfig.map((route, id) => (
            <Route key={id} path={route.path} exact={route.exact} component={route.component} />
        ))}
        <Redirect to={CHAT_PAGE_URL} />
    </Switch>
</>;

export default Routes;
