import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { selectNotifications } from '../redux/selectors/Notification/notifications';
import { clearNotification } from '../redux/actions/Notification/notifications.actions';
import CustomSnackbar from './CustomSnackbar';
import PropTypes from 'prop-types';

export function SnackbarManager(props) {

    const [shown, setShown] = useState([]);

    const displaySnackbar = () => {
        const { notifications } = props;
        notifications.forEach(({ key, message, options = {} }) => {
            if(!shown.includes(key)) {
                props.enqueueSnackbar(
                    '',
                    {
                        autoHideDuration: 8000,
                        children: (keyParam) => <CustomSnackbar id={keyParam} message={message} variant={options.variant} />
                    }
                );
                setShown((prev) => [...prev, key]);
            }
        });
    };

    useEffect(() => {
        displaySnackbar(props);
    }, [shown, props]);


    return <></>;

}

SnackbarManager.propTypes = {
    notifications: PropTypes.array,
    enqueueSnackbar: PropTypes.func,
    clearNotification: PropTypes.func
};


const mapStateToProps = (state) => ({
    notifications: selectNotifications(state)
});

export const mapDispatchToProps = (dispatch) => ({
    clearNotification: (key) => dispatch(clearNotification(key))
});

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SnackbarManager));
