import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { CandidateTablePage } from './CandidateTablePage/CandidateTablePage';

export function Candidate() {

    const location = useLocation();
    const candidate = location?.candidate;

    return (
        <section data-testid="candidate">
            <CandidateTablePage candidate={candidate} />
        </section>
    );
}

export default withRouter(Candidate);
