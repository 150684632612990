import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';
import { ReactCodeJar } from 'react-codejar';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { runJavaCode } from 'app/api/runCodeApi';
import { notificationError } from 'app/redux/actions/Notification/notifications.actions';
import { RUN_CODE_QUESTION_ERROR } from 'app/Snackbar/NotificationMessages';

import './QuestionEditCode.scss';

const useStyles = makeStyles(() => ({
    codejar: {
        backgroundColor: 'black',
        color: 'white',
        padding: '4px'
    }
}));
export function QuestionEditCode(props) {

    const dispatch = useDispatch();
    const classes = useStyles();

    const [code, setCode] = useState(props.code);
    const [result, setResult] = useState('');

    const testCode = () => {
        runJavaCode({ code, args: [] }).then(setResult)
            .catch(() => {
                dispatch(notificationError(RUN_CODE_QUESTION_ERROR));
            });
    };

    const highlight = (editor) => {
        editor.innerHTML = hljs.highlight(editor.textContent, { language: 'java' }).value;
    };

    return (
        <div className="edit-code">
            <Card className={classes.codejar}>
                <ReactCodeJar
                    code={code}
                    highlight={highlight}
                    lineNumbers={true}
                    onUpdate={setCode}
                />
            </Card>
            <Card className="result-card">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={testCode}
                >
                    Tester
                </Button>
                { result }
            </Card>
        </div>
    );
}


QuestionEditCode.propTypes = {
    code: PropTypes.string.isRequired
};

export default QuestionEditCode;
