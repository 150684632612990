import { notificationError, notificationSuccess } from '../../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    DAILY_QUESTIONS_ENDED,
    PUT_DAILY_QUESTIONS_ERROR
} from '../../../../../Snackbar/NotificationMessages';

import { putDailyQuestionsApi } from '../../../../../api/dailyQuestionsApi';
import { getDailyQuestionsToDo } from '../DailyQuestionsGet/dailyQuestionsGet.actions';

export const PUT_DAILY_QUESTIONS_REQUEST = 'PUT_DAILY_QUESTIONS_REQUEST';
export const PUT_DAILY_QUESTIONS_SUCCESS = 'PUT_DAILY_QUESTIONS_SUCCESS';
export const PUT_DAILY_QUESTIONS_FAILURE = 'PUT_DAILY_QUESTIONS_FAILURE';

export const putDailyQuestionsDone = (userId, date) => (dispatch) => {
    dispatch({ type: PUT_DAILY_QUESTIONS_REQUEST });

    return putDailyQuestionsApi(userId, date).then((response) => {
        if(response.status === 201 || response.status === 204) {
            dispatch({ type: PUT_DAILY_QUESTIONS_SUCCESS });
            dispatch(notificationSuccess(DAILY_QUESTIONS_ENDED));
            dispatch(getDailyQuestionsToDo(userId));
        }
    }, (error) => {
        dispatch({
            type: PUT_DAILY_QUESTIONS_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(PUT_DAILY_QUESTIONS_ERROR));
        }
    });
};
