import {
    EXPIRED_SESSION,
    DELETE_STUDENT_IN_GROUP_ERROR,
    DELETE_STUDENT_IN_GROUP,
    POST_STUDENT_IN_GROUP_ERROR,
    POST_COACH_IN_GROUP_ERROR,
    DELETE_COACH_IN_GROUP,
    DELETE_COACH_IN_GROUP_ERROR
} from 'app/Snackbar/NotificationMessages';
import {
    postStudentInStudentGroupsApi,
    deleteStudentInStudentGroupsApi,
    postCoachInStudentGroupsApi,
    deleteCoachInStudentGroupsApi
} from 'app/api/groupApi';
import { notificationError, notificationSuccess } from 'app/redux/actions/Notification/notifications.actions';


export const POST_STUDENT_IN_GROUP_REQUEST = 'POST_STUDENT_IN_GROUP_REQUEST';
export const POST_STUDENT_IN_GROUP_SUCCESS = 'POST_STUDENT_IN_GROUP_SUCCESS';
export const POST_STUDENT_IN_GROUP_FAILURE = 'POST_STUDENT_IN_GROUP_FAILURE';

export const POST_COACH_IN_GROUP_REQUEST = 'POST_COACH_IN_GROUP_REQUEST';
export const POST_COACH_IN_GROUP_SUCCESS = 'POST_COACH_IN_GROUP_SUCCESS';
export const POST_COACH_IN_GROUP_FAILURE = 'POST_COACH_IN_GROUP_FAILURE';

export const DELETE_STUDENT_IN_GROUP_REQUEST = 'DELETE_STUDENT_IN_GROUP_REQUEST';
export const DELETE_STUDENT_IN_GROUP_SUCCESS = 'DELETE_STUDENT_IN_GROUP_SUCCESS';
export const DELETE_STUDENT_IN_GROUP_FAILURE = 'DELETE_STUDENT_IN_GROUP_FAILURE';

export const DELETE_COACH_IN_GROUP_REQUEST = 'DELETE_COACH_IN_GROUP_REQUEST';
export const DELETE_COACH_IN_GROUP_SUCCESS = 'DELETE_COACH_IN_GROUP_SUCCESS';
export const DELETE_COACH_IN_GROUP_FAILURE = 'DELETE_COACH_IN_GROUP_FAILURE';


export const postStudentInGroup = (studentId, groupId) => (dispatch) => {
    dispatch({ type: POST_STUDENT_IN_GROUP_REQUEST });

    return postStudentInStudentGroupsApi(studentId, groupId).then((response) => {
        dispatch({
            type: POST_STUDENT_IN_GROUP_SUCCESS,
            payload: response.data
        });

    }, (error) => {
        dispatch({
            type: POST_STUDENT_IN_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(POST_STUDENT_IN_GROUP_ERROR));
        }
    });

};

export const postCoachInGroup = (coachId, groupId, userId) => (dispatch) => {
    dispatch({ type: POST_COACH_IN_GROUP_REQUEST });

    return postCoachInStudentGroupsApi(coachId, groupId, userId).then((response) => {
        dispatch({
            type: POST_COACH_IN_GROUP_SUCCESS,
            payload: response.data
        });

    }, (error) => {
        dispatch({
            type: POST_COACH_IN_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(POST_COACH_IN_GROUP_ERROR));
        }
    });

};

export const deleteStudentInGroupById = (groupId, studentId) => (dispatch) => {
    dispatch({ type: DELETE_STUDENT_IN_GROUP_REQUEST });

    return deleteStudentInStudentGroupsApi(groupId, studentId).then(() => {
        dispatch({
            type: DELETE_STUDENT_IN_GROUP_SUCCESS,
            payload: studentId
        });
        dispatch(notificationSuccess(DELETE_STUDENT_IN_GROUP));
    }, (error) => {
        dispatch({
            type: DELETE_STUDENT_IN_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_STUDENT_IN_GROUP_ERROR));
        }
    });
};

export const deleteCoachInGroupById = (groupId, coachId) => (dispatch) => {
    dispatch({ type: DELETE_COACH_IN_GROUP_REQUEST });

    return deleteCoachInStudentGroupsApi(groupId, coachId).then(() => {
        dispatch({
            type: DELETE_COACH_IN_GROUP_SUCCESS,
            payload: coachId
        });
        dispatch(notificationSuccess(DELETE_COACH_IN_GROUP));
    }, (error) => {
        dispatch({
            type: DELETE_COACH_IN_GROUP_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(DELETE_COACH_IN_GROUP_ERROR));
        }
    });
};
