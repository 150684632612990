
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ClientsTable.scss';
import PropTypes from 'prop-types';
import { getAllClients } from '../../../../redux/actions/Client/clients.actions';
import { MaterialTableImpl } from '../../../../common/MaterialTable/MaterialTableImpl';
import { selectCurrentUser } from '../../../../redux/selectors/User/user.selector';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { selectClientFilteredBySectorAndUser } from '../../../../redux/selectors/Client/ClientFilter/clientsFilter.selector';
import building from 'assets/images/building.png';

export class ClientsTable extends Component {

    componentDidMount() {
        this.props.getAllClients();
    }

    displayAddress = (addressParam) => {
        if(addressParam === null) {
            return <p>Non renseignée</p>;
        }
        return <p>{addressParam}</p>;

    };

    displaySector = (sectorParam) => {
        if(sectorParam === null) {
            return <p>Non renseigné</p>;
        }
        return <p>{sectorParam.description}</p>;
    };

    sortSector = (dataA, dataB) => {
        if(dataA.sector === null) {
            return -1;
        }
        if(dataB.sector === null) {
            return 1;
        }
        return dataA.sector.description > dataB.sector.description ? 1 : -1;
    };

    render() {

        const isFetching = false;

        const columns = [
            {
                title: 'Logo',
                field: 'logo',
                sorting: false,
                render: (rowData) => <img className="logoClient"
                    src={rowData.imageUrl ? rowData.imageUrl : building} alt=""/>,
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(15px)'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(15px)'
                }
            },
            {
                title: 'Client',
                field: 'name',
                render: (rowData) => <Link
                    key={rowData.id}
                    to={`/clients/${rowData.id}`}
                    style={{ textDecoration: 'none' }}
                >
                    {rowData.name}
                </Link>,
                cellStyle: {
                    width: 'calc(110px)',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal',
                    maxWidth: '20%'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(110px)',
                    padding: '5',
                    maxWidth: '20%'
                }
            },
            {
                title: 'Secteur',
                field: 'secteur',
                customSort: (dataA, dataB) => this.sortSector(dataA, dataB),
                render: (rowData) => this.displaySector(rowData.sector),
                cellStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)',
                    maxWidth: '30%'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(100px)',
                    maxWidth: '30%'
                }
            },
            {
                title: 'Adresse',
                field: 'adresse',
                sorting: false,
                render: (rowData) => this.displayAddress(rowData.address),
                cellStyle: {
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    width: 'calc(400px)',
                    maxWidth: '40%'
                },
                headerStyle: {
                    textAlign: 'left',
                    width: 'calc(400px)',
                    maxWidth: '40%'
                }
            }
        ];

        const columnsForMobile = [
            {
                title: 'Logo',
                field: 'logo',
                sorting: false,
                render: (rowData) => <img className="logoClient"
                    src={rowData.imageUrl ? rowData.imageUrl : building} alt=""/>
            },
            {
                title: 'Client',
                field: 'name',
                render: (rowData) => <Link
                    key={rowData.id}
                    to={`/clients/${rowData.id}`}
                    style={{ textDecoration: 'none' }}
                >
                    {rowData.name}
                </Link>,
                cellStyle: {
                    width: 'calc(150px)',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    textAlign: 'left',
                    padding: '10'
                }
            },
            {
                title: 'Adresse',
                field: 'adresse',
                sorting: false,
                render: (rowData) => this.displayAddress(rowData.address),
                cellStyle: {
                    width: 'calc(200px)',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    wordSpacing: 'normal'
                },
                headerStyle: {
                    textAlign: 'left',
                    padding: '10'
                }
            }
        ];

        return (
            <>
                <MaterialTableImpl
                    title={''}
                    isFetching={isFetching}
                    data={this.props.allClients}
                    columns={isWidthUp('sm', this.props.width) ? columns : columnsForMobile}
                    width={this.props.width}
                />
            </>
        );
    }

}
ClientsTable.propTypes = {
    allClients: PropTypes.array,
    getAllClients: PropTypes.func,
    width: PropTypes.string,
    currentUser: PropTypes.object
};

function mapStateToProps(state) {
    return {
        allClients: selectClientFilteredBySectorAndUser(state),
        currentUser: selectCurrentUser(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getAllClients: () => dispatch(getAllClients())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ClientsTable));
