import React, { useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    Collapse,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField,
    Tooltip
} from '@material-ui/core';
import './CoachingGroup.scss';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { getStudentByGroupsId, getAllCoachesOfGroupByGroupId } from 'app/redux/actions/Group/group.actions';
import { deleteStudentInGroupById, postStudentInGroup } from 'app/redux/actions/Group/RelationGroup/groupRelation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroup, selectStudents } from 'app/redux/selectors/Group/group.selector';
import AddIcon from '@material-ui/icons/Add';
import AddToGroupPanel from 'app/coaching/AddToGroupPanel/AddToGroupPanel';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LayoutModal from 'app/layout/LayoutModal';


export default function CoachingGroupStudents() {
    const dispatch = useDispatch();

    const selectedGroup = useSelector(selectGroup);
    const students = useSelector(selectStudents);

    const [filteredValue, setFilteredValue] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [modalOpened, setModalOpened] = useState(false);

    const closeDialog = () => {
        setModalOpened(false);
        dispatch(getStudentByGroupsId(selectedGroup.id));
    };

    const openDialog = () => {
        setModalOpened(true);
    };

    useEffect(() => {
        dispatch(getStudentByGroupsId(selectedGroup.id));
        dispatch(getAllCoachesOfGroupByGroupId(selectedGroup.id));
    }, [selectedGroup]);

    useEffect(() => {
        setIsCollapsed(true);
    }, [selectedGroup]);

    const filteredStudents = useMemo(() => filteredValue === '' || !students
        ? students
        : students.filter(
            (str) => `${str.firstName} ${str.lastName}`.toLowerCase().includes(filteredValue.toLowerCase())
            , [students, filteredValue]
        ));

    function deleteStudentInGroup(studentId) {
        dispatch(deleteStudentInGroupById(selectedGroup.id, studentId));
    }

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={8} lg={8} id="clickable" className="clickable" onClick={handleCollapse}>
                    <h3 className="groupSpacing">Liste des élèves</h3>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} id="clickable" className="clickable" onClick={handleCollapse}>
                    <h4 className="subheaderText">{ students ? students.length : 0 } élèves</h4>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} className="clickableToggleCollapse" onClick={handleCollapse}>
                    <div className="toggleCollapse">
                        {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                    </div>
                </Grid>
            </Grid>
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit className="collapse">
                <Grid container spacing={2}>
                    <Grid item xs ={ 12 }>
                        <ListItem>
                            <TextField value={filteredValue} id="filterBar" name="filterBar" className="filterBar" placeholder="Rechercher"
                                onChange={(event) => setFilteredValue(event.target.value)} />
                            <IconButton
                                onClick={() => {
                                    setFilteredValue('');
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                            <Tooltip title = "Ajouter des élèves au groupe">
                                <IconButton
                                    onClick= {openDialog}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItem>
                    </Grid>
                    {filteredStudents && filteredStudents.map((student) => <Grid item xs = { 3 } key={student.id}>
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar src={`${student.imageUrl}`}/>
                            </ListItemAvatar>
                            <ListItemText primary={`${student.firstName} ${student.lastName}`} secondary={`${student.promo}`}/>
                            <IconButton>
                                <RemoveCircleOutlineOutlinedIcon style={{ color: '#FF0000' }} onClick= {() => deleteStudentInGroup(student.id)} />
                            </IconButton>
                        </ListItem>
                    </Grid>)}
                </Grid>
            </Collapse>
            <LayoutModal opened={modalOpened} closeAction={closeDialog}>
                <AddToGroupPanel
                    addToGroup="Ajouter au groupe"
                    setTrigger = {closeDialog}
                    previouslyAdded={ students }
                    dispatchPersonGroup={ postStudentInGroup }
                />
            </LayoutModal>
        </Paper>
    );
}
