import axiosClient from './utils/axiosClient';

export const CHAPTER_STATS_URI = 'stats/users';

const mapToArrayParams = (key, arr) => arr && arr.length && arr.map((value) => `${key}=${value}`).reduce((prev, curr) => `${prev}&${curr}`);

export function getRichChaptersStatsApi(usersList, chaptersList) {
    const usersParams = mapToArrayParams('userIds', usersList);
    const chaptersParams = mapToArrayParams('chapterIds', chaptersList);
    return axiosClient.get(`${CHAPTER_STATS_URI}/rich?${usersParams}&${chaptersParams}`);
}

export function getQuestionsDoneAndGoodAnswersApi(chapterIds, userId) {
    return axiosClient.get(`${CHAPTER_STATS_URI}/questionsDoneAndGoodAnswers?chapterIds=${chapterIds}&userId=${userId}`);
}

export function getRichChaptersStatsApiByChapterId(usersList, chapterId) {
    const usersParams = mapToArrayParams('userIds', usersList);
    return axiosClient.get(`${CHAPTER_STATS_URI}/rich/chapter/${chapterId}?${usersParams}`);
}

export function getRichChaptersStatsApiByChaptersAndUsers(usersList, chapterList) {
    const usersParams = mapToArrayParams('userIds', usersList);
    const chaptersParams = mapToArrayParams('chapterIds', chapterList);
    return axiosClient.get(`${CHAPTER_STATS_URI}/rich/chapters?${chaptersParams}&${usersParams}`);
}
