export const SET_EXCILYENS_SEARCH_FILTER = 'SET_EXCILYENS_SEARCH_FILTER';
export const SET_EXCILYENS_PROMO_FILTER = 'SET_EXCILYENS_PROMO_FILTER';
export const SET_EXCILYENS_SKILLS_FILTER = 'SET_EXCILYENS_SKILLS_FILTER';

export function setExcilyensSearchFilter(filter) {
    return {
        type: SET_EXCILYENS_SEARCH_FILTER,
        payload: filter
    };
}

export function setExcilyensSkillsFilter(filter) {
    return {
        type: SET_EXCILYENS_SKILLS_FILTER,
        payload: filter
    };
}

export function setPromoFilter(filter) {
    return {
        type: SET_EXCILYENS_PROMO_FILTER,
        payload: filter
    };
}
