import { 
    getMorningQuestionsApi,
    postMorningQuestionsDoneApi
} from '../../../../api/morningQuestionsApi';

import { notificationError } from '../../Notification/notifications.actions';
import {
    EXPIRED_SESSION,
    RETRIEVE_MORNING_QUESTIONS_ERROR,
    RETRIEVE_MORNING_QUESTIONS_DONE_ERROR
} from '../../../../Snackbar/NotificationMessages';

export const GET_MORNING_QUESTION = 'GET_MORNING_QUESTION';
export const GET_MORNING_QUESTION_REQUEST = 'GET_MORNING_QUESTION_REQUEST';
export const GET_MORNING_QUESTION_FAILURE = 'GET_MORNING_QUESTION_FAILURE';
export const GET_MORNING_QUESTION_SUCCESS = 'GET_MORNING_QUESTION_SUCCESS';

export const POST_MORNING_QUESTIONS_DONE = 'POST_MORNING_QUESTIONS_DONE';
export const POST_MORNING_QUESTIONS_DONE_REQUEST = 'POST_MORNING_QUESTIONS_DONE_REQUEST';
export const POST_MORNING_QUESTIONS_DONE_FAILURE = 'POST_MORNING_QUESTIONS_DONE_FAILURE';
export const POST_MORNING_QUESTIONS_DONE_SUCCESS = 'POST_MORNING_QUESTIONS_DONE_SUCCESS';

export const getMorningQuestionToDo = (userId) => (dispatch) => {
    dispatch({ type: GET_MORNING_QUESTION_REQUEST });

    return getMorningQuestionsApi(userId).then((response) => {
        dispatch({
            type: GET_MORNING_QUESTION_SUCCESS,
            payload: response.data
        });
    }, (error) => {
        dispatch({
            type: GET_MORNING_QUESTION_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_MORNING_QUESTIONS_ERROR));
        }
    });
};

export const postMorningQuestionsDone = (userId) => (dispatch) => {
    dispatch({ type: POST_MORNING_QUESTIONS_DONE_REQUEST });

    return postMorningQuestionsDoneApi(userId).then((response) => {
        dispatch({
            type: POST_MORNING_QUESTIONS_DONE_SUCCESS,
            payload: response
        });
    }, (error) => {
        dispatch({
            type: POST_MORNING_QUESTIONS_DONE_FAILURE,
            payload: error,
            error: true
        });
        if(error.response && error.response.status === 401) {
            dispatch(notificationError(EXPIRED_SESSION));
        } else {
            dispatch(notificationError(RETRIEVE_MORNING_QUESTIONS_DONE_ERROR));
        }
    });
};