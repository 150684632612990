import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, FormControl, makeStyles } from '@material-ui/core';
import Parcours from './Parcours';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { ParcoursTypeEnum } from 'app/utils/ParcoursTypeEnum';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.5)
    },
    selectedChip: {
        color: '#0057D2',
        margin: theme.spacing(0.5),
        borderColor: '#EBF4FE',
        backgroundColor: '#EBF4FE',
        borderStyle: 'solid',
        fontWeight: 500
    }
}));
  
function ParcoursPanel(props) {
    const classes = useStyles();
    const [parcoursType, setParcoursType] = useState(ParcoursTypeEnum.INITIAL);

    const getCurrentUserId = () => parseInt(AuthenticationContainer.getCurrentUserId(),10);

    const handleChipClick = (type) => {
        setParcoursType(type);
    };

    const parcoursTypeChips = [
        { key: ParcoursTypeEnum.ASSIGNED, label: 'Suivis' },
        { key: ParcoursTypeEnum.CREATED, label: 'Créés par moi' },
        { key: ParcoursTypeEnum.SHARED, label: 'Partagés avec moi' },
    ];

    return (
        <div>
            {(props.selectedStudentId === getCurrentUserId()) &&
                <div>
                    <FormControl style={{ margin: '10px 10px' }}>
                        <div>
                            {parcoursTypeChips.map(data => (
                                <Chip
                                    key={data.key}
                                    label={data.label}
                                    onClick={() => handleChipClick(data.key)}
                                    className={parcoursType === data.key ? classes.selectedChip : classes.chip}
                                    variant="outlined"
                                />
                            ))}
                        </div>
                    </FormControl>
                </div>
            }
            <Parcours
                parcoursType={parcoursType}
                setParcoursType={setParcoursType}
                selectedStudentId={props.selectedStudentId}
                selectedGroupId={props.selectedGroupId}
            />
        </div>
    );
}

ParcoursPanel.propTypes = {
    selectedStudentId: PropTypes.number,
    selectedGroupId: PropTypes.object
};

export default ParcoursPanel;
