import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './CandidateWelcome.scss';
import { withRouter } from 'react-router-dom';
import logo from 'assets/images/oxyl-digital-makers.png';
import { getCandidateByUuidApi, patchCandidateByUuidApi } from '../../api/candidateApi';

import { Button } from '@material-ui/core';
import CandidateSerie from '../CandidateSerie/CandidateSerie';
import { getPendingSerieCandidateExistsApi } from 'app/api/historyApi';


const errorMessage = 'Une erreur est survenue, merci de contacter recrutement@oxyl.fr';
const finishedMessage = <p><span>Merci d&apos;avoir participé à ce test.</span><br/> Nous vous contacterons dans les plus brefs délais.</p>;
const welcomeMessage = (candidate) => (
    <>
            Bonjour <span>{candidate.firstName} {candidate.lastName}</span>, <br />
            Vous allez passer le test de recrutement Oxyl.<br />
            Vous aurez 1h à partir du moment où vous appuyez sur le bouton
            Démarrer pour répondre à un maximum de questions sur les technologies utilisées chez Oxyl.<br />
            Vous pouvez à tout moment passer à la questions suivante sans y répondre pour y revenir ensuite
            en utilisant les boutons précédent/suivant.<br />
            Bon courage!
    </>
);

export function CandidateWelcome(props) {

    const [isSerieStarted, setIsSerieStarted] = useState(false);
    const [candidate, setCandidate] = useState(null);
    const [message, setMessage] = useState(null);
    const [firstConnection, setFirstConnection] = useState(true);

    const getCandidateByUuid = async() => {
        try {
            const response = await getCandidateByUuidApi(props.match.params.uuid);
            setCandidate(response.data);
            if(response.data.status === 'DONE' ||
            response.data.status === 'NEXT_STEP' ||
            response.data.status === 'REJECTED') {
                setMessage(finishedMessage);
            }
        } catch {
            setMessage(errorMessage);
        }
    };

    const patchCandidateByUuid = () => {
        patchCandidateByUuidApi(props.match.params.uuid)
            .then(() => {
                setMessage(finishedMessage);
            })
            .catch(() => {
                setMessage(errorMessage);
            });
    };

    useEffect(() => {
        getCandidateByUuid();
    }, []);

    useEffect(() => {
        if(candidate?.uuid) {
            getPendingSerieCandidateExistsApi(candidate.uuid).then((response) => {
                setFirstConnection(!response.data);
            });
        }
    }, [candidate?.uuid]);

    const handleStartSerie = () => {
        setIsSerieStarted(true);
    };

    const handleFinishSerie = () => {
        setIsSerieStarted(false);
        setCandidate(null);
        patchCandidateByUuid();
    };

    return (
        isSerieStarted && candidate.status !== 'DONE' &&
        candidate.status !== 'NEXT_STEP' &&
        candidate.status !== 'REJECTED'
            ? <CandidateSerie
                onFinishCandidateSerie={handleFinishSerie}
                uuid={props.match.params.uuid}
                firstConnection={firstConnection}
            />
            : <CandidateMessagePage
                candidate={candidate}
                message={message}
                onStartCandidateSerie={handleStartSerie}
                firstConnection={firstConnection}
            />
    );

}

CandidateWelcome.propTypes = {
    match: PropTypes.object
};


export default withRouter(CandidateWelcome);

function CandidateMessagePage({
    candidate,
    message,
    onStartCandidateSerie,
    firstConnection
}) {

    return (
        <div className="welcome-wrapper">
            <div className="welcome-content">
                <img className="logo-oxyl"
                    src={logo}
                    alt="logo-oxyl" />
                <span className="welcome-message">
                    {message}
                </span>
                {candidate && candidate.status !== 'DONE' &&
        candidate.status !== 'NEXT_STEP' &&
        candidate.status !== 'REJECTED' &&
                    <>
                        <div className="welcome-message">
                            {welcomeMessage(candidate)}
                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onStartCandidateSerie}
                        >
                            {firstConnection ? 'Démarrer' : 'Reprendre la série'}
                        </Button>
                    </>
                }
            </div>
        </div>
    );
}

CandidateMessagePage.propTypes = {
    candidate: PropTypes.object,
    message: PropTypes.string.isRequired,
    onStartCandidateSerie: PropTypes.func.isRequired,
    firstConnection: PropTypes.bool.isRequired
};
