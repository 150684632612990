import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, MuiThemeProvider, Tooltip, Icon, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ReactStars from 'react-stars';
import './UserSkills.scss';
import { connect, useSelector } from 'react-redux';
import { theme } from '../profile/ProfileContentTheme';
import { Link } from 'react-router-dom';
import { selectFamilies } from '../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';
import { selectSkillsNumberByFamily } from '../../redux/selectors/Skill/SkillSet/skillSet.selector';
import { getAllFamilies } from 'app/redux/actions/FamilyCategory/familiesCategories.actions';

export function UserSkills(props) {

    const families = useSelector(selectFamilies);

    const [sortedSkillsByFamily, setSortedSkillsByFamily] = useState([]);

    useEffect(() => {
        const skillsByFamily = [];
        families.forEach((family) => {
            const skillFamily = {
                name: family.name,
                skills: []
            };
            props.skills.forEach((skill) => {
                if(skill.familyName === family.name && skill.userLevel > 0) {
                    skillFamily.skills.push(skill);
                }
            });
            skillFamily.skills.sort((aa, bb) => (aa.userLevel > bb.userLevel ? -1 : 1));
            skillsByFamily.push(skillFamily);
            skillsByFamily.sort((aa, bb) => (aa.skills.length > bb.skills.length ? -1 : 1));
        });
        const skillWithoutFamily = {
            name: 'Autres compétences',
            skills: []
        };
        props.skills.forEach((skill) => {
            if(skill.familyName === null && skill.userLevel > 0) {
                skillWithoutFamily.skills.push(skill);
            }
        });
        if(skillWithoutFamily.skills.length !== 0) {
            skillsByFamily.push(skillWithoutFamily);
        }

        setSortedSkillsByFamily(skillsByFamily);
    }, [families]);

    useEffect(() => {
        props.getAllFamilies();
    }, []);

    return (
        sortedSkillsByFamily.map((family, index) => <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index} className="grid-card">
            <Card className="card-container">
                {family.name === 'Autres compétences'
                    ? <div className="title-family">
                        {`${family.name} (${family.skills.length})`}
                    </div>
                    : <div className="title-family">
                        {`${family.name} (${family.skills.length}/${props.selectSkillsNumberByFamily(family.name)})`}
                    </div>
                }
                <div className="card-action-area" data-testid="card-action-area">
                    <Grid container spacing={1} direction="column">
                        <div className="skills-by-family">
                            {family.skills.length === 0
                                ? <div className="no-skill">Il n&prime;y a pas de compétence enregistrée dans cette famille</div>
                                : family.skills.map((skill, index2) => <MuiThemeProvider theme={theme} key={index2}>
                                    <CardContent>
                                        <Grid item className="skill">
                                            <span className="skill-name">
                                                <Link to={`/skills/${skill.skillName}`}>
                                                    <Typography variant="inherit" noWrap>
                                                        {skill.skillName}
                                                        {skill.moderator &&
                                                        <Tooltip title="Modérateur sur la compétence">
                                                            <Icon id="icon-moderator" className="icon">verified_user</Icon>
                                                        </Tooltip>
                                                        }
                                                    </Typography>
                                                </Link>
                                            </span>
                                            <ReactStars
                                                className="stars"
                                                count={5}
                                                selectFamilies size={24}
                                                color2={'#ffd700'}
                                                half={false}
                                                value={skill.userLevel}
                                                edit={false}
                                            />
                                        </Grid>
                                    </CardContent>
                                </MuiThemeProvider>)
                            }
                        </div>

                    </Grid>
                </div>
            </Card>
        </Grid>)
    );
}

UserSkills.propTypes = {
    skills: PropTypes.array,
    families: PropTypes.array,
    selectSkillsNumberByFamily: PropTypes.func
};

function mapStateToProps(state) {
    return {
        selectSkillsNumberByFamily: (family) => selectSkillsNumberByFamily(state, family)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getAllFamilies: () => dispatch(getAllFamilies())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSkills);
