import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { selectStudents } from '../../../../redux/selectors/Group/group.selector';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStudentsOfGroupByGroupId } from '../../../../redux/actions/Group/group.actions';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import HistoryPagination from 'app/question/history/historyTable/historyPagination/HistoryPagination';
import GroupHistoriqueQuestionRow from './GroupHistoriqueQuestionRow';
import { getHistoryGroupByTaskIdApi } from 'app/api/historyApi';

export default function CoachingGroupHistoriqueTable(props) {

    const students = useSelector(selectStudents);

    const dispatch = useDispatch();

    const [pageData, setPageData] = useState({
        pageNumber: 0,
        rowPerPage: 10,
        totalElement: 0
    });

    const handleChangePage = (event, number) => {
        setPageData((prevState) => ({
            ...prevState,
            pageNumber: number
        }));
    };

    const handleRowsPerPage = (event) => {
        setPageData((prevState) => ({
            ...prevState,
            pageNumber: 0,
            rowPerPage: event.target.value
        }));
    };

    function dispatchStudents(groupId) {
        if(groupId) {
            dispatch(getAllStudentsOfGroupByGroupId(groupId));
        }
    }

    useEffect(() => {
        getHistoryGroupByTaskIdApi(props.selectedTask.id)
            .then((response) => {
                props.setStatsGroupByQuestion(response.data);
                setPageData((prevState) => ({
                    ...prevState,
                    totalElement: response.data?.length
                }));
            });
    }, []);

    useEffect(() => {
        dispatchStudents(props.groupId);
    }, [props.groupId]);

    const progress = (question) => {
        const result = {
            title: `${question.skillName} # ${question.questionId}`,
            questionId: question.questionId,
            goodAnswers: question.goodAnswers,
            badAnswers: question.badAnswers,
            questionsNotDone: students.length - (question.goodAnswers + question.badAnswers)
        };
        return result;
    };

    const getProgress = () => (props.statsGroupByQuestion.map((question) => progress(question)));

    return (
        <Paper className="tablePaper" data-testid="history-table">
            {props.statsGroupByQuestion.length === 0
                ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <strong>Aucun historique enregistr&eacute;</strong>
                </div>
                : <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow key={'header'}>
                                    <TableCell width={'5%'}/>
                                    <TableCell width={'50%'}>Question</TableCell>
                                    <TableCell align="center" width={'45%'}>D&eacute;tails</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getProgress().map((row) => (
                                    <GroupHistoriqueQuestionRow
                                        key={row.questionId}
                                        row={row}
                                        taskId={props.selectedTask.id}
                                        students={students}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <HistoryPagination
                        totalElement={pageData.totalElement}
                        pageNumber={pageData.pageNumber}
                        rowPerPage={pageData.rowPerPage}
                        rowPerPageOptions={[10, 20]}
                        handleChangePage={handleChangePage}
                        handleRowsPerPage={handleRowsPerPage}
                    />
                </>
            }
        </Paper>
    );
}

CoachingGroupHistoriqueTable.propTypes = {
    groupId: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired,
    selectedTask: PropTypes.object,
    statsGroupByQuestion: PropTypes.array,
    setStatsGroupByQuestion: PropTypes.func
};
