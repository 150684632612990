import {
    GET_CURRENT_USER_SUCCESS,
    NOT_REGISTERED,
    ADD_USER_SUCCESS,
    GET_ALL_USERS_SUCCESS,
    RESET_CURRENT_USER
} from '../../actions/User/user.actions';
import {
    PATCH_USER_SKILL_FAVORITE_SUCCESS,
    ADD_USER_SKILL_SUCCESS,
    PATCH_USER_SKILL_LEVEL_SUCCESS,
    REMOVE_USER_SKILL_SUCCESS
} from '../../actions/User/UserSkills/userSkill.actions';


export const usersStore = 'allUsers';

export default function userReducer(
    state = {
        currentUser: {},
        [usersStore]: [],
        registred: undefined
    },
    action
) {
    switch (action.type) {
        case RESET_CURRENT_USER:
            return {
                ...state,
                currentUser: {},
                registred: undefined
            };
        case GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                registred: true
            };
        case NOT_REGISTERED:
            return {
                ...state,
                registred: false
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                registred: true
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                [usersStore]: action.payload
            };
        case PATCH_USER_SKILL_LEVEL_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    skillDetailedApiDtos:
                        state.currentUser.skillDetailedApiDtos
                            .map((skill) => skill.id === action.payload.skillId
                                ? { ...skill, userLevel: action.payload.level }
                                : skill)
                }
            };
        case PATCH_USER_SKILL_FAVORITE_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    skillDetailedApiDtos:
                        state.currentUser.skillDetailedApiDtos
                            .map((skill) => skill.id === action.payload.skillId
                                ? { ...skill, favorite: action.payload.favorite }
                                : skill)
                }
            };
        case ADD_USER_SKILL_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    skillDetailedApiDtos: [
                        ...state.currentUser.skillDetailedApiDtos, {
                            id: action.payload.skillId,
                            userLevel: action.payload.level,
                            moderator: action.payload.moderator,
                            favorite: action.payload.favorite
                        }
                    ]
                }
            };
        case REMOVE_USER_SKILL_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    skillDetailedApiDtos:
                        state.currentUser.skillDetailedApiDtos
                            .filter((skill) => skill.id !== action.payload.skillId)
                }
            };
        default:
            return state;
    }
}

