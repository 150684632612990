import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { setSearchFilter } from '../../redux/actions/Filter/filter.actions';
import Search from '../../common/search/Search';
import { EXPERT_PAGE_URL, SKILL_PAGE_URL } from '../../Routes';
import { setExcilyensSearchFilter } from '../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectExcilyenSearchString } from '../../redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import { selectSearchString } from '../../redux/selectors/Skill/SkillFilters/skillsFilters.selector';

export class SearchContainer extends Component {

    render() {
        const { search, searchExperts } = this.props;

        if(this.props.location.pathname === SKILL_PAGE_URL) {
            return (
                <div data-testid ="search-container-skill">
                    <Search value={search} onSearchSubmit={this.props.setSearchFilter}/>
                </div>
            );
        }

        if(this.props.location.pathname === EXPERT_PAGE_URL) {
            return (
                <div data-testid = "search-container-expert">
                    <Search value={searchExperts} onSearchSubmit={this.props.setExcilyensSearchFilter}/>
                </div>
            );
        }

        return null;
    }
}

export function mapStateToProps(state) {
    return {
        search: selectSearchString(state),
        searchExperts: selectExcilyenSearchString(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter)),
        setExcilyensSearchFilter: (searchFilter) => dispatch(setExcilyensSearchFilter(searchFilter))
    };
}

SearchContainer
    .propTypes = {
        search: PropTypes.string,
        searchExperts: PropTypes.string,
        setSearchFilter: PropTypes.func,
        setExcilyensSearchFilter: PropTypes.func,
        location: PropTypes.object
    };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchContainer));

