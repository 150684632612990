
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilterSkills } from '../../../redux/selectors/Excilyen/ExcilyenFilter/excilyensFilters.selector';
import './SidePanelExcilyensSkillsEntry.scss';
import { Typography } from '@material-ui/core';
import ReactStars from 'react-stars';
import Icon from '@material-ui/core/Icon';
import { setExcilyensSkillsFilter } from '../../../redux/actions/Excilyen/ExcilyenFilter/excilyenFilters.actions';
import { skillActions } from 'app/redux/slices/skills.slice';
import { selectAllSkills } from 'app/redux/selectors/Skill/SkillSet/skill.selector';

export default function SidePanelExcilyensSkillsEntry() {

    const dispatch = useDispatch();

    const { data: allSkills, loading: isFetching } = useSelector(selectAllSkills);
    const filterSkill = useSelector(selectFilterSkills);

    const customStyles = {
        multiValue: (provided, __) => ({
            ...provided,
            display: 'none'
        }),
        control: (base, __) => ({
            ...base,
            boxShadow: 'none'
        })
    };

    const [skills, setSkills] = useState(allSkills.filter((skill) => skill.coveredByExcilys));
    const [skillFilters, setSkillFilters] = useState(filterSkill);
    const [defaultSkillSelect, setDefaultSkillSelect] = useState([]);

    const dispatchExcilyensSkillFilter = (filter) => dispatch(setExcilyensSkillsFilter(filter));

    useEffect(() => {
        dispatch(skillActions.getAllSkills());
    }, []);

    useEffect(() => {
        if(!isFetching) {
            setSkills(allSkills.map((skill) => ({
                id: skill.id,
                value: skill.skillName || skill.value,
                label: skill.skillName || skill.label,
                level: 3
            })).sort((s1, s2) => (s1.label).localeCompare(s2.label)));
            setDefaultSkillSelect(allSkills.map((skill) => ({
                id: skill.id,
                value: skill.skillName,
                label: skill.skillName,
                level: 3
            })).sort((s1, s2) => s1.label.localeCompare(s2.label)));
            setSkillFilters(filterSkill);
        }
        if(filterSkill) {
            setSkillFilters(filterSkill);
        }
    }, [isFetching, filterSkill]);

    useEffect(() => {
        setSkillFilters(filterSkill);
    }, [filterSkill]);

    const handleLevelChange = (value, label) => {
        const currentList = skillFilters;
        currentList.find((skill) => skill.label === label).level = value;
        const checkedSkills = currentList.map((skill) => ({ id: skill.id,
            value: skill.label,
            label: skill.label,
            level: skill.level }));
        dispatchExcilyensSkillFilter(checkedSkills);
    };

    const handleChangeMulti = (value) => {
        if(value) {
            const checkedSkills = value.map((skill) => ({ id: skill.id,
                value: skill.label,
                label: skill.label,
                level: skill.level }));
            dispatchExcilyensSkillFilter(checkedSkills);
            setSkillFilters(value);
        } else {
            dispatchExcilyensSkillFilter([]);
        }
    };

    const removeSkillFromFilter = (skill) => {
        const filters = skillFilters;
        const result = filters.filter((filter) => filter !== skill);
        const remainingFilters = result.map((skillFilter) => ({ id: skill.id,
            value: skillFilter.value,
            label: skillFilter.label,
            level: skillFilter.level }));

        dispatchExcilyensSkillFilter(remainingFilters);
        const selectSkills = defaultSkillSelect.filter((item) => !remainingFilters.includes(item));
        setSkillFilters(remainingFilters);
        setSkills(selectSkills);
    };

    return (
        <div className="expert-skill-filter" data-testid="side-panel-excilyens-skills-entry">
            <Typography className="sub-title">Compétences</Typography>
            <div data-testid="side-panel-excilyens-skills-entry-select">
                <Select
                    name="side-panel-excilyens-skills-entry-select"
                    classeName="expert-skill-select"
                    classNamePrefix="skill-select"
                    placeholder="Compétences"
                    styles={customStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: '#3f51b5'
                        }
                    })}
                    options={skills}
                    value={skillFilters}
                    onChange={handleChangeMulti}
                    isMulti
                />
            </div>
            <div className="selected-skills">
                {skillFilters && skillFilters.map((skill, index) => <div key={index} className="selected-skill">
                    <div className="selected-skill-content">
                        <Typography className="selected-skill-name" noWrap> {skill.label}</Typography>
                        <div data-testid={`skill-star-filter-${index}`}>
                            <ReactStars
                                count={5}
                                size={20}
                                color2={'#ffd700'}
                                half={false}
                                edit={true}
                                value={skill.level}
                                onChange={(rateValue) => handleLevelChange(rateValue, skill.label)}
                            />
                        </div>
                    </div>
                    <div className="selected-skill-remove-button">
                        <Icon onClick={() => removeSkillFromFilter(skill)} data-testid={ `skill-panel-excilyen-${skill.label}` }>close</Icon>
                    </div>
                </div>)}
            </div>
        </div>
    );
}
