import QuestionEditor from 'app/common/richTextEditor/QuestionEditor';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './QuestionATrouForm.scss';
import TrouCard from './TrouCard/TrouCard';
import TextATrouEditor from 'app/common/richTextEditor/TextATrouEditor';
import { Grid } from '@material-ui/core';
import { mapTrouWithNewOrdinal } from './QuestionATrou.functions';

export function QuestionATrouForm({ questionATrou, setQuestionATrou }) {

    const placeholderConfigExplanation = 'Insérez l\'explication';
    const [emitter, setEmitter] = useState(null);

    function modification({ ordinal, newAcceptedAnswers }) {
        emitter.fire('modification', { ordinal, newAcceptedAnswers });
    }
    function suppression(ordinal) {
        emitter.fire('suppression', { ordinal });
    }

    function handleChangeStatementEditor(event, editor) {
        setQuestionATrou((prevState) => ({
            ...prevState,
            statement: editor.getData()
        }));
    }

    function handleChangeExplanation(event, editor) {
        setQuestionATrou((prevState) => ({
            ...prevState,
            explanation: editor.getData()
        }));
    }

    function addAnswerAccepted(trouOrdinal) {
        const trouSelected = questionATrou.answers.find((trou) => trou.ordinal === trouOrdinal);
        const newAcceptedAnswers = trouSelected.acceptedAnswers.slice();
        newAcceptedAnswers.push('');

        const newtrouSelected = { ...trouSelected, acceptedAnswers: newAcceptedAnswers };
        const newTrouList = questionATrou.answers.map((trou) => trou.ordinal === trouOrdinal
            ? newtrouSelected
            : trou);

        setQuestionATrou({ ...questionATrou, answers: newTrouList });
        modification({ ordinal: trouOrdinal, newAcceptedAnswers });
    }

    function deleteAcceptedAnswers(trouOrdinal, indexAnswer) {
        const newQuestionATrou = { ...questionATrou };
        const trouSelected = newQuestionATrou.answers.find((trou) => trou.ordinal === trouOrdinal);
        trouSelected.acceptedAnswers.splice(indexAnswer, 1);

        // On fait une deep copie des string pour render chaque composant textfield
        trouSelected.acceptedAnswers = trouSelected.acceptedAnswers.map((answer) => answer.slice());
        setQuestionATrou(newQuestionATrou);
        modification({ ordinal: trouOrdinal, newAcceptedAnswers: trouSelected.acceptedAnswers });
    }

    // A déplacer dans .functions.js
    function getNewTrouList(trouElements) {
        let ordinal = 1;
        return trouElements.map((element) => ({
            ordinal: ordinal++,
            acceptedAnswers: element.getAttribute('acceptedAnswers')
        }));
    }

    /*
     * Attention, si jamais setQuestionATrou change lors de la vie du composant, ce sera pas prit en compte ici.
     * Si il y en a besoin, il faudra modifier textatrou.js dans le ckeditor pour qu'il prenne setQuestionATrou en param
     */
    function refresh(trouElements) {
        setQuestionATrou((oldQuestionATrou) => ({
            ...oldQuestionATrou,
            answers: trouElements?.length ? getNewTrouList(trouElements) : []
        }));
    }
    function addEmitter(emitterForm) {
        setEmitter((_oldEmitter) => emitterForm);
    }

    function handleAcceptedAnswerTextChange(event, trouOrdinal, acceptedAnswerIndex) {
        const trou = questionATrou.answers.find((answer) => answer.ordinal === trouOrdinal);
        trou.acceptedAnswers[acceptedAnswerIndex] = event.target.value;

        setQuestionATrou((oldQuestionATrou) => ({ ...oldQuestionATrou }));
        modification({ ordinal: trouOrdinal, newAcceptedAnswers: trou.acceptedAnswers });
    }

    function deleteTrou(trouOrdinal) {
        const newTrouList = questionATrou.answers
            .filter((trou) => trou.ordinal !== trouOrdinal)
            .map(mapTrouWithNewOrdinal);

        setQuestionATrou((oldQuestionATrou) => ({
            ...oldQuestionATrou,
            answers: newTrouList
        }));
        suppression(trouOrdinal);
    }
    return (
        <div data-testid="question-texte-a-trou-form">
            <TextATrouEditor
                className="statementQuestionEditor"
                data={questionATrou.statement}
                answerList={questionATrou.answers}
                onChange={handleChangeStatementEditor}
                refreshCallback={refresh}
                addEmitterCallback={addEmitter}
            />
            <div className="trou-list">
                {questionATrou.answers.map((trou) => <Grid direction="row" item xs={12} sm={6} md={4} lg={3} xl={2} key={trou.position} data-testid="trou-card">
                    <TrouCard
                        trou={trou}
                        handleAcceptedAnswerTextChange={handleAcceptedAnswerTextChange}
                        addAnswerAccepted={addAnswerAccepted}
                        deleteTrou={deleteTrou}
                        deleteAcceptedAnswers={deleteAcceptedAnswers}
                    />
                </Grid>)}
            </div>
            <QuestionEditor
                className="explanationEditor"
                data={questionATrou.explanation}
                onChange={handleChangeExplanation}
                placeholder={placeholderConfigExplanation}
            />
        </div>
    );
}

QuestionATrouForm.propTypes = {
    questionATrou: PropTypes.object.isRequired,

    setQuestionATrou: PropTypes.func.isRequired
};
