import React, { Fragment } from 'react';
import { TablePagination, useMediaQuery, useTheme } from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';
import { createLoadingSelector } from 'app/redux/selectors/APISelector/APISelector';
import { GET_QUESTIONS_PAGINATED } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQGet/MCQGet.actions';
import PropTypes from 'prop-types';
import QuestionRequestByQuestionId from 'app/question/QuestionResolver/QuestionRequest/QuestionRequestByQuestionId';

export function CreateEditSerieFormQuestionsAvailable(props) {

    const theme = useTheme();
    const sizeUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const cssControlTable = () => {
        if(sizeUpMd) {
            return 'table-desktop';
        }
        return 'table-mobile';
    };

    const isFetchingQuestionReduced = useSelector(createLoadingSelector([GET_QUESTIONS_PAGINATED]));

    const columnControl = (name) => [
        {
            title: 'Id',
            field: 'id',

            render: (rowData) => <Fragment key={rowData.id + name}>
                {rowData.id}
            </Fragment>
        },
        {
            title: 'Compétence',
            field: 'skill',

            render: (rowData) => (
                <Fragment key={rowData.id + name}>
                    { rowData.skillReduced
                        ? rowData.skillReduced.name
                        : (rowData.skill
                            ? rowData.skill.name
                            : undefined
                        )
                    }
                </Fragment>
            )
        }
    ];
    return (
        <div className={cssControlTable()}>
            <MaterialTable
                className="Table"
                key={props.pagesData.rowsPerPage}
                columns={columnControl('table')}
                data={props.questions}
                title="Liste des QCM"
                isLoading={isFetchingQuestionReduced && !props.questions}
                options={{
                    search: true,
                    title: true,
                    toolbar: false,
                    selection: true,
                    showSelectAllCheckbox: false,
                    selectionProps: (rowData) => props.serie.questionReducedList.length !== 0 && {
                        checked: props.serie.questionReducedList.some((question) => question.id === rowData.id)
                    },
                    pageSize: props.pagesData.rowsPerPage,
                    pageSizeOptions: [
                        5,
                        10,
                        20
                    ]
                }}
                detailPanel={[
                    {
                        tooltip: 'Accéder aux questions',
                        render: (rowData) => <QuestionRequestByQuestionId
                            key={`render${rowData.id}`}
                            questionId={rowData.id}
                            collapseAnswers
                            disableAnswers
                            hideFooter
                        />
                    }
                ]}
                onRowClick={(event, rowData) => {
                    if(rowData) {
                        rowData.tableData.checked = !rowData.tableData.checked;
                        props.attributeQuestion(rowData);
                    }
                }}
                onSelectionChange={(data, rowData) => props.attributeQuestion(rowData)}
                localization={{
                    'header': {
                        'actions': ''
                    },
                    'body': {
                        'emptyDataSourceMessage': (isFetchingQuestionReduced && !props.questions)
                            ? 'En attente de chargement...'
                            : 'Aucune question trouvée'
                    },
                    'pagination': {
                        'labelDisplayedRows': '{from}-{to} / {count}',
                        'labelRowsSelect': '',
                        'nextTooltip': 'Page suivante',
                        'previousTooltip': 'Page précédente',
                        'firstTooltip': 'Première page',
                        'lastTooltip': 'Dernière page'
                    },
                    'toolbar': {
                        'searchPlaceholder': 'Rechercher',
                        'searchTooltip': 'Rechercher'
                    }
                }}
                components={{
                    Pagination: () => (
                        <TablePagination
                            className="pagination"
                            data-testid="pagination"
                            count={props.pagesData.questionsCount}
                            page={props.pagesData.pageNumber}
                            rowsPerPage={props.pagesData.rowsPerPage}
                            onPageChange={props.handleChangePage}
                            onRowsPerPageChange={props.handleRowsPerPage}
                            rowsPerPageOptions={[5, 10, 20]}
                            labelRowsPerPage={'Questions par page'}
                            backIconButtonText={'Page précédente'}
                            nextIconButtonText={'Page suivante'}
                            labelDisplayedRows={({ from, to, count }) => (`${from}-${to} / ${count}`)}
                            ActionsComponent={TablePaginationActions}
                        />
                    )
                }}
            />
        </div>
    );
}

CreateEditSerieFormQuestionsAvailable.propTypes = {
    serie: PropTypes.object.isRequired,
    pagesData: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleRowsPerPage: PropTypes.func.isRequired,
    attributeQuestion: PropTypes.func.isRequired
};
