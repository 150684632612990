import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import '../projects/SidePanelProjectsFilterContainer.scss';
import { setClientsExcilyenFilter, setClientsSectorFilter } from '../../../redux/actions/Client/ClientFilter/clientsFilters.actions';
import { selectAllSectors } from '../../../redux/selectors/Sector/sector.selector';
import { selectClientFilterSector, selectClientFilterUser } from '../../../redux/selectors/Client/ClientFilter/clientsFilter.selector';
import { selectAllUser } from '../../../redux/selectors/User/user.selector';


export class SidePanelClientsFilterContainer extends Component {


    handleChange = (value) => {
        if(this.props.sector) {
            this.props.setClientsSectorFilter(value);
        } else if(this.props.user) {
            this.props.setClientsExcilyenFilter(value);
        }
    };


    render() {

        const allSectors = this.props.sectors.map((sector) => ({
            value: sector.id,
            label: sector.description
        }));

        const allUsers = this.props.excilyens.map((excilyen) => ({ value: excilyen.id,
            label: `${excilyen.firstName} ${excilyen.lastName}` }));

        let className = '';
        let placeHolder = '';
        let name = '';
        let isMulti = false;
        let options = [];
        let selectValue = '';

        const { sector, user } = this.props;
        if(sector) {
            className = 'basic-single';
            placeHolder = 'Secteurs d\'activité';
            name = 'secteurs d\'activité';
            isMulti = false;
            options = allSectors;
            selectValue = this.props.valueSector;
        }

        if(user) {
            className = 'basic-multi-select';
            placeHolder = 'Intervenants';
            name = 'Intervenants';
            isMulti = true;
            options = allUsers;
            selectValue = this.props.valueUser;
        }

        const customStyles = {
            control: (base, __) => ({
                ...base,
                boxShadow: 'none'
            })
        };


        return (
            <div className="project-filter" data-testid="side-panel-clients-filter-container">
                <Typography className="sub-title-project">
                    {sector && 'Secteurs d\'activité'}
                    {user && 'Intervenants'}
                </Typography>
                <Select
                    onChange= {this.handleChange}
                    className={className}
                    classNamePrefix="select"
                    placeholder={placeHolder}
                    styles={customStyles}
                    isClearable={true}
                    isSearchable={true}
                    name={name}
                    options={options}
                    isMulti={isMulti}
                    value={selectValue}
                />
            </div>
        );
    }
}

SidePanelClientsFilterContainer.propTypes = {
    sector: PropTypes.bool,
    user: PropTypes.bool,

    sectors: PropTypes.array,
    excilyens: PropTypes.array,

    valueSector: PropTypes.object,
    valueUser: PropTypes.array,
    setClientsSectorFilter: PropTypes.func,
    setClientsExcilyenFilter: PropTypes.func
};

function mapStateToProps(state) {
    return {
        sectors: selectAllSectors(state),
        excilyens: selectAllUser(state),
        valueSector: selectClientFilterSector(state),
        valueUser: selectClientFilterUser(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        setClientsSectorFilter: (filter) => dispatch(setClientsSectorFilter(filter)),
        setClientsExcilyenFilter: (filter) => dispatch(setClientsExcilyenFilter(filter))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelClientsFilterContainer);

